import { Transfer as AntDesignTransfer } from 'antd';
import { ListStyle, TransferItem } from 'antd/lib/transfer';
import { TransferListBodyProps } from 'antd/lib/transfer/ListBody';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import ReorderableList from 'components/molecules/ReorderableList/ReorderableList';
import { STANDARD_SPACING } from 'constants/styles';
import usePrevious from 'hooks/usePrevious';
import { ITransferItem } from 'interfaces/Component';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type TTransferItem<T> = T & ITransferItem & TransferItem;

const TransferContainer = styled.div`
  .ant-transfer-list-body {
    overflow: auto;
  }

  .ant-transfer-list-body-customize-wrapper {
    padding: 0;
  }
`;

const ItemRenderContainer = styled.span`
  padding-left: ${STANDARD_SPACING};
  width: 100%;
`;

export interface IToEntityTransferProps<T> {
  data: TTransferItem<T>[];
  height: number;
  isDisabled?: boolean;
  leftListRender: (transferItem: TransferItem) => JSX.Element;
  leftWidth: number;
  onReorder: (dragIndex: number, hoverIndex: number) => void;
  onTransfer: (direction: string, moveKeys: string[]) => void;
  rightListRender: (item: T) => JSX.Element;
  rightWidth: number;
  targetKeys: string[];
  titles?: [string, string];
}

const ToEntityTransfer = <T extends any>(
  props: IToEntityTransferProps<T>,
): JSX.Element => {
  const transferContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const {
    data,
    height,
    isDisabled,
    leftWidth,
    leftListRender,
    onReorder,
    onTransfer,
    rightWidth,
    rightListRender,
    targetKeys,
    titles,
  } = props;
  const previousTargetKeys: string[] | undefined = usePrevious(targetKeys);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (shouldScroll) {
      if (transferContainerRef.current !== undefined) {
        // There are two lists available, index 0 is the left list and index 1 is the right list
        const listDivElements: HTMLCollectionOf<Element> =
          transferContainerRef.current.getElementsByClassName(
            'ant-transfer-list-body',
          );

        if (listDivElements.length === 2) {
          // We guarantee the bottom of the list by using the largest possible
          // scrollTop value.
          listDivElements[1].scrollTop = Number.MAX_SAFE_INTEGER;

          setShouldScroll(false);
        } else {
          throw new Error('Invalid number of list elements found');
        }
      }
    }
  }, [shouldScroll, transferContainerRef]);

  useEffect(() => {
    // As a UX improvement, whenever the right list increases in size, we auto
    // scroll to the bottom of the list to better indicate the newly added
    // items. We do this by setting a shouldScroll flag to true and ensuring
    // that shouldScroll effect only happens AFTER this flag is set (by
    // putting its useEffect BEFORE this useEffect), thus causing a frame
    // delay. This delay is important, since it ensures that the list has been
    // updated with the new items, is rendered and can therefore be scrolled to
    // the bottom of this new list and not the bottom of the previous list.
    if (
      previousTargetKeys !== undefined &&
      previousTargetKeys.length < targetKeys.length
    ) {
      setShouldScroll(true);
    }
  }, [previousTargetKeys, targetKeys]);

  const transferListRender = (
    props: TransferListBodyProps<TTransferItem<T>>,
  ): JSX.Element | undefined => {
    const { direction, onItemSelect, selectedKeys } = props;

    if (direction === 'right') {
      const itemRenderer = (item: TTransferItem<T>): JSX.Element => {
        const { disabled, key } = item;
        const checked: boolean =
          key === undefined ? false : selectedKeys.includes(key);

        const handleChange = (checked: boolean) => {
          if (key !== undefined) {
            onItemSelect(key, checked);
          }
        };

        return (
          <>
            <Checkbox
              checked={checked}
              isDisabled={disabled || isDisabled}
              onChange={handleChange}
            />
            <ItemRenderContainer>{rightListRender(item)}</ItemRenderContainer>
          </>
        );
      };

      return (
        <ReorderableList<TTransferItem<T>>
          data={
            targetKeys
              .map((key: string): TTransferItem<T> | undefined =>
                data.find((value: TTransferItem<T>) => value.key === key),
              )
              .filter(
                (item: TTransferItem<T> | undefined) => item !== undefined,
              ) as TTransferItem<T>[]
          }
          itemRenderer={itemRenderer}
          onMoveRow={onReorder}
        />
      );
    }
  };

  const handleTransfer = (
    targetKeys: string[],
    direction: string,
    moveKeys: string[],
  ) => {
    onTransfer(direction, moveKeys);
  };

  const handleSelect = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[],
  ) => {
    const updatedSelectedKeys: string[] =
      sourceSelectedKeys.concat(targetSelectedKeys);

    sourceSelectedKeys.forEach((key: string) => {
      const item: TTransferItem<T> | undefined = data.find(
        (value: TTransferItem<T>) => value.key === key,
      );

      if (item === undefined) {
        return;
      }

      if (
        item.dependantDataIndex !== undefined &&
        !targetKeys.includes(item.dependantDataIndex) &&
        !updatedSelectedKeys.includes(item.dependantDataIndex)
      ) {
        updatedSelectedKeys.unshift(item.dependantDataIndex);
      }
    });

    targetSelectedKeys.forEach((key: string) => {
      targetKeys.forEach((otherKey: string) => {
        const item: TTransferItem<T> | undefined = data.find(
          (value: TTransferItem<T>) => value.key === otherKey,
        );

        if (item === undefined) {
          return;
        }

        if (
          item.dependantDataIndex === key &&
          !updatedSelectedKeys.includes(otherKey)
        ) {
          updatedSelectedKeys.push(otherKey);
        }
      });
    });

    setSelectedKeys(updatedSelectedKeys);
  };

  return (
    <TransferContainer ref={transferContainerRef}>
      <AntDesignTransfer
        dataSource={data}
        disabled={isDisabled}
        listStyle={({ direction }: ListStyle) => ({
          height,
          maxWidth: direction === 'left' ? leftWidth : rightWidth,
        })}
        onChange={handleTransfer}
        onSelectChange={handleSelect}
        render={leftListRender}
        selectedKeys={selectedKeys}
        targetKeys={targetKeys}
        titles={titles}
      >
        {transferListRender}
      </AntDesignTransfer>
    </TransferContainer>
  );
};

export default ToEntityTransfer;
