import { EDetailIdType } from 'enums/Detail';
import { EErrorType } from 'enums/Error';
import {
  IDistributedTagItemDetailStateLoadTransform,
  IDistributedTagItemDetailStateTransform,
  IFailedDistributedTagItem,
} from 'interfaces/Detail';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ActionCreators } from 'redux-undo';
import {
  EDetailAction,
  IDetailState,
  IETagDetail,
  IETagDetailFailureReply,
  IETagDetailInitialParameters,
  IETagDetailIsDetailDeleted,
  IETagDetailIsDetailValidating,
  IETagDetailProductProfilesApplied,
  IETagDetailRequest,
  IETagDetailSelectedPlotTime,
  IETagDetailSelectedProfileFormat,
  IETagDetailSelectedRequestType,
  IETagDetailSuccessReply,
  IETagDetailsUpdateFailureReply,
  IETagDetailUseUniqueProfiles,
  IETagDetailValidation,
  IETagDetailValidations,
  IETagDetailViewMode,
  IETagDistributedTagItemsFailureReply,
  IETagDistributedTagItemsRequest,
  IETagDistributedTagItemsSuccessReply,
  IETagProfilesFailureReply,
  IETagProfilesRequest,
  IETagProfilesSuccessReply,
  IETagSnapshotsFailureReply,
  IETagSnapshotsRequest,
  IETagSnapshotsSuccessReply,
  TDetailAction,
} from 'reduxes/Detail/types';
import {
  TStateLoadTransform,
  TStateTransform,
  TStateTransformUpdate,
} from 'types/General';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import {
  retrieveAndTransformDistributedTagEnergyProfileSnapshots,
  retrieveAndTransformDistributedTagPhysicalSegmentsProfiles,
} from 'utils/detail';
import { captureError, NotFoundError } from 'utils/error';
import { isEmptyValue } from 'utils/general';
import { checkToEntity } from 'utils/toEntity';

const setETagDetailInitialParameters = (
  eTagDetailInitialParameters: IETagDetailInitialParameters,
): TDetailAction => ({
  payload: eTagDetailInitialParameters,
  type: EDetailAction.SetETagDetailInitialParameters,
});

export const detailSetETagDetailInitialParameters = (
  eTagDetailInitialParameters: IETagDetailInitialParameters,
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction | Action>,
  ): Promise<void> => {
    dispatch(setETagDetailInitialParameters(eTagDetailInitialParameters));

    dispatch(ActionCreators.clearHistory());
  };
};

const setETagDetailViewMode = (
  eTagDetailViewMode: IETagDetailViewMode,
): TDetailAction => ({
  payload: eTagDetailViewMode,
  type: EDetailAction.SetETagDetailViewMode,
});

export const detailSetETagDetailViewMode = (
  eTagDetailViewMode: IETagDetailViewMode,
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction | Action>,
  ): Promise<void> => {
    dispatch(setETagDetailViewMode(eTagDetailViewMode));

    dispatch(ActionCreators.clearHistory());
  };
};

export const detailRetrieveETagDetailStart = (
  eTagDetailRequest: IETagDetailRequest,
): TDetailAction => ({
  payload: eTagDetailRequest,
  type: EDetailAction.RetrieveETagDetailStart,
});

export const detailRetrieveETagDetailFailure = (
  eTagDetailFailureReply: IETagDetailFailureReply,
): TDetailAction => ({
  payload: eTagDetailFailureReply,
  type: EDetailAction.RetrieveETagDetailFailure,
});

export const detailRetrieveETagDetailSuccess = (
  eTagDetailSuccessReply: IETagDetailSuccessReply,
): TDetailAction => ({
  payload: eTagDetailSuccessReply,
  type: EDetailAction.RetrieveETagDetailSuccess,
});

export const detailRetrieveETagDetail = (
  detailIdType: EDetailIdType,
  detailStateLoadTransforms: TStateLoadTransform<IDetailState>[],
  shouldCaptureError: boolean = false,
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  const eTagDetailRequest: IETagDetailRequest = {};

  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction | Action>,
  ): Promise<void> => {
    dispatch(detailRetrieveETagDetailStart(eTagDetailRequest));

    try {
      dispatch(
        detailRetrieveETagDetailSuccess({
          stateTransforms: await Promise.all(
            detailStateLoadTransforms.map(
              (detailStateLoadTransform: TStateLoadTransform<IDetailState>) =>
                detailStateLoadTransform(),
            ),
          ),
        }),
      );

      dispatch(ActionCreators.clearHistory());
    } catch (error: any) {
      dispatch(
        detailRetrieveETagDetailFailure({
          detailIdType,
          errorMessage: error.message,
          isNotFound: error instanceof NotFoundError,
        }),
      );

      if (shouldCaptureError) {
        captureError(error, 'Load Failed');
      }
    }
  };
};

export const detailRetrieveETagDistributedTagItemsStart = (
  eTagDistributedTagItemsRequest: IETagDistributedTagItemsRequest,
): TDetailAction => ({
  payload: eTagDistributedTagItemsRequest,
  type: EDetailAction.RetrieveETagDistributedTagItemsStart,
});

export const detailRetrieveETagDistributedTagItemsFailure = (
  eTagDistributedTagItemsFailureReply: IETagDistributedTagItemsFailureReply,
): TDetailAction => ({
  payload: eTagDistributedTagItemsFailureReply,
  type: EDetailAction.RetrieveETagDistributedTagItemsFailure,
});

export const detailRetrieveETagDistributedTagItemsSuccess = (
  eTagDistributedTagItemsSuccessReply: IETagDistributedTagItemsSuccessReply,
): TDetailAction => ({
  payload: eTagDistributedTagItemsSuccessReply,
  type: EDetailAction.RetrieveETagDistributedTagItemsSuccess,
});

export const detailRetrieveETagDistributedTagItems = (
  distributedTagItemDetailStateLoadTransforms: IDistributedTagItemDetailStateLoadTransform[],
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  const eTagDistributedTagItemRequest: IETagDistributedTagItemsRequest = {
    distributedTagItemDetailStateLoadTransforms,
  };

  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction | Action>,
  ): Promise<void> => {
    dispatch(
      detailRetrieveETagDistributedTagItemsStart(eTagDistributedTagItemRequest),
    );

    try {
      const stateTransformReponses: PromiseSettledResult<
        TStateTransform<IDetailState>
      >[] = await Promise.allSettled(
        distributedTagItemDetailStateLoadTransforms.map(
          (
            distributedTagItemDetailStateLoadTransform: IDistributedTagItemDetailStateLoadTransform,
          ) =>
            distributedTagItemDetailStateLoadTransform.detailStateLoadTransform(),
        ),
      );

      const distributedTagItemDetailStateTransforms: IDistributedTagItemDetailStateTransform[] =
        [];
      const failedDistributedTagItems: IFailedDistributedTagItem[] = [];

      stateTransformReponses.forEach(
        (
          stateTransformReponse: PromiseSettledResult<
            TStateTransform<IDetailState>
          >,
          index: number,
        ) => {
          if (stateTransformReponse.status === 'fulfilled') {
            distributedTagItemDetailStateTransforms.push({
              detailStateTransform: stateTransformReponse.value,
              distributedTagItem:
                distributedTagItemDetailStateLoadTransforms[index]
                  .distributedTagItem,
            });
          } else if (stateTransformReponse.status === 'rejected') {
            failedDistributedTagItems.push({
              distributedTagItem:
                distributedTagItemDetailStateLoadTransforms[index]
                  .distributedTagItem,
              errorMessage: stateTransformReponse.reason,
              isNotFound: stateTransformReponse.reason instanceof NotFoundError,
            });

            captureError(
              new Error(stateTransformReponse.reason),
              'Load Failed',
            );
          }
        },
      );

      dispatch(
        detailRetrieveETagDistributedTagItemsSuccess({
          distributedTagItemDetailStateTransforms,
        }),
      );

      if (failedDistributedTagItems.length > 0) {
        dispatch(
          detailRetrieveETagDistributedTagItemsFailure({
            failedDistributedTagItems,
          }),
        );
      }

      dispatch(ActionCreators.clearHistory());
    } catch (error: any) {
      captureError(error);

      dispatch(
        detailRetrieveETagDistributedTagItemsFailure({
          failedDistributedTagItems: [],
        }),
      );
    }
  };
};

export const detailRetrieveETagProfilesStart = (
  eTagProfilesRequest: IETagProfilesRequest,
): TDetailAction => ({
  payload: eTagProfilesRequest,
  type: EDetailAction.RetrieveETagProfilesStart,
});

export const detailRetrieveETagProfilesFailure = (
  eTagProfilesFailureReply: IETagProfilesFailureReply,
): TDetailAction => ({
  payload: eTagProfilesFailureReply,
  type: EDetailAction.RetrieveETagProfilesFailure,
});

export const detailRetrieveETagProfilesSuccess = (
  eTagProfilesSuccessReply: IETagProfilesSuccessReply,
): TDetailAction => ({
  payload: eTagProfilesSuccessReply,
  type: EDetailAction.RetrieveETagProfilesSuccess,
});

export const detailSetETagDetailSelectedRequestKey = (
  selectedRequestKey: string,
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  const eTagProfilesRequest: IETagProfilesRequest = {
    selectedRequestKey,
  };

  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    dispatch(detailRetrieveETagProfilesStart(eTagProfilesRequest));

    try {
      const rootState: TRootState = getState();
      const { tag_primary_key, toEntity } = rootState.detail.present;

      if (isEmptyValue(tag_primary_key)) {
        throw new Error('Missing tag_primary_key');
      }

      checkToEntity(toEntity);

      const toEntityId: TToEntityId = toEntity!.to_entity;
      const detailStateLoadTransforms: TStateLoadTransform<IDetailState>[] = [
        retrieveAndTransformDistributedTagPhysicalSegmentsProfiles(
          toEntityId,
          tag_primary_key!,
          selectedRequestKey,
        ),
      ];

      dispatch(
        detailRetrieveETagProfilesSuccess({
          stateTransforms: await Promise.all(
            detailStateLoadTransforms.map(
              (detailStateLoadTransform: TStateLoadTransform<IDetailState>) =>
                detailStateLoadTransform(),
            ),
          ),
        }),
      );
    } catch (error: any) {
      captureError(error);

      dispatch(
        detailRetrieveETagProfilesFailure({
          errorMessage:
            'An error occurred loading profiles. Please try again later.',
        }),
      );
    }
  };
};

export const detailRetrieveETagSnapshotsStart = (
  eTagSnapshotsRequest: IETagSnapshotsRequest,
): TDetailAction => ({
  payload: eTagSnapshotsRequest,
  type: EDetailAction.RetrieveETagSnapshotsStart,
});

export const detailRetrieveETagSnapshotsFailure = (
  eTagSnapshotsFailureReply: IETagSnapshotsFailureReply,
): TDetailAction => ({
  payload: eTagSnapshotsFailureReply,
  type: EDetailAction.RetrieveETagSnapshotsFailure,
});

export const detailRetrieveETagSnapshotsSuccess = (
  eTagSnapshotsSuccessReply: IETagSnapshotsSuccessReply,
): TDetailAction => ({
  payload: eTagSnapshotsSuccessReply,
  type: EDetailAction.RetrieveETagSnapshotsSuccess,
});

export const detailRetrieveETagSnapshots = (
  minRequestId: number,
  maxRequestId: number,
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  const eTagSnapshotsRequest: IETagSnapshotsRequest = {};

  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const rootState: TRootState = getState();
    const { energyProfileSnapshots } = rootState.detail.present;

    if (
      energyProfileSnapshots === null ||
      energyProfileSnapshots.min_request_id !== minRequestId ||
      energyProfileSnapshots.max_request_id !== maxRequestId
    ) {
      dispatch(detailRetrieveETagSnapshotsStart(eTagSnapshotsRequest));

      try {
        const { tag_primary_key, toEntity } = rootState.detail.present;

        if (isEmptyValue(tag_primary_key)) {
          throw new Error('Missing tag_primary_key');
        }

        checkToEntity(toEntity);

        const toEntityId: TToEntityId = toEntity!.to_entity;

        const detailStateLoadTransforms: TStateLoadTransform<IDetailState>[] = [
          retrieveAndTransformDistributedTagEnergyProfileSnapshots(
            toEntityId,
            tag_primary_key!,
            minRequestId,
            maxRequestId,
          ),
        ];

        dispatch(
          detailRetrieveETagSnapshotsSuccess({
            stateTransforms: await Promise.all(
              detailStateLoadTransforms.map(
                (detailStateLoadTransform: TStateLoadTransform<IDetailState>) =>
                  detailStateLoadTransform(),
              ),
            ),
          }),
        );
      } catch (error: any) {
        captureError(error);

        dispatch(
          detailRetrieveETagSnapshotsFailure({
            errorMessage:
              'An error occurred loading snapshots. Please try again later.',
          }),
        );
      }
    }
  };
};

export const detailSetETagDetailSelectedPlotTime = (
  eTagDetailSelectedPlotTime: IETagDetailSelectedPlotTime,
): TDetailAction => ({
  payload: eTagDetailSelectedPlotTime,
  type: EDetailAction.SetETagDetailSelectedPlotTime,
});

export const detailEditETagDetail = (
  eTagDetail: IETagDetail,
): TDetailAction => ({
  payload: eTagDetail,
  type: EDetailAction.EditETagDetail,
});

export const detailUpdateETagDetailsStart = (): TDetailAction => ({
  payload: {},
  type: EDetailAction.UpdateETagDetailsStart,
});

export const detailUpdateETagDetailsFailure = (
  eTagDraftUpdateFailureReply: IETagDetailsUpdateFailureReply,
): TDetailAction => ({
  payload: eTagDraftUpdateFailureReply,
  type: EDetailAction.UpdateETagDetailsFailure,
});

export const detailUpdateETagDetailsSuccess = (): TDetailAction => ({
  payload: {},
  type: EDetailAction.UpdateETagDetailsSuccess,
});

export const detailUpdateETagDetails = (
  stateTransformUpdate: TStateTransformUpdate<IDetailState>,
): ThunkAction<void, TRootState, unknown, TDetailAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TDetailAction | Action>,
    getState: () => TRootState,
  ): Promise<void> => {
    dispatch(detailUpdateETagDetailsStart());

    try {
      const rootState: TRootState = getState();

      await stateTransformUpdate(rootState.detail.present);

      dispatch(detailUpdateETagDetailsSuccess());

      dispatch(ActionCreators.clearHistory());
    } catch (error: any) {
      captureError(error, 'Save Failed');

      dispatch(
        detailUpdateETagDetailsFailure({
          errorMessage:
            'An error occurred updating details. Please try again later.',
          errorType: EErrorType.Update,
        }),
      );
    }
  };
};

export const detailSetValidation = (
  eTagDetailValidation: IETagDetailValidation,
): TDetailAction => ({
  payload: { ...eTagDetailValidation, ignore: true },
  type: EDetailAction.SetETagDetailValidation,
});

export const detailSetValidations = (
  eTagDetailValidations: IETagDetailValidations,
): TDetailAction => ({
  payload: { ...eTagDetailValidations, ignore: true },
  type: EDetailAction.SetETagDetailValidations,
});

export const detailSetIsDetailValidating = (
  eTagDetailIsDetailValidating: IETagDetailIsDetailValidating,
): TDetailAction => ({
  payload: eTagDetailIsDetailValidating,
  type: EDetailAction.SetETagDetailIsDetailValidating,
});

export const detailSetIsDetailDeleted = (
  eTagDetailIsDetailDeleted: IETagDetailIsDetailDeleted,
): TDetailAction => ({
  payload: eTagDetailIsDetailDeleted,
  type: EDetailAction.SetETagDetailIsDetailDeleted,
});

export const detailSetSelectedProfileFormat = (
  eTagDetailSelectedProfileFormat: IETagDetailSelectedProfileFormat,
): TDetailAction => ({
  payload: eTagDetailSelectedProfileFormat,
  type: EDetailAction.SetETagDetailSelectedProfileFormat,
});

export const detailSetSelectedRequestType = (
  eTagDetailSelectedRequestType: IETagDetailSelectedRequestType,
): TDetailAction => ({
  payload: eTagDetailSelectedRequestType,
  type: EDetailAction.SetETagDetailSelectedRequestType,
});

export const detailSetUseUniqueProfiles = (
  eTagDetailUseUniqueProfiles: IETagDetailUseUniqueProfiles,
): TDetailAction => ({
  payload: eTagDetailUseUniqueProfiles,
  type: EDetailAction.SetETagDetailUseUniqueProfiles,
});

export const detailSetProductProfilesApplied = (
  eTagDetailProductProfilesApplied: IETagDetailProductProfilesApplied,
): TDetailAction => ({
  payload: eTagDetailProductProfilesApplied,
  type: EDetailAction.SetETagDetailProductProfilesApplied,
});
