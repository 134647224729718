import Button from 'components/atoms/Button/Button';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { EActionState } from 'enums/General';
import styled from 'styled-components';

const StyledButton = styled.div`
  display: inline;
  margin-right: 8px;
`;
const ApiCallResult = styled.div`
  display: inline-block;
`;

interface IButtonWithResultProps {
  actionState: EActionState;
  encodedPermissionsId?: string;
  label: string;
  onClick: () => void;
  result?: string;
}

export const ButtonWithResult = (
  props: IButtonWithResultProps,
): JSX.Element => {
  const { actionState, encodedPermissionsId, label, onClick, result } = props;

  return (
    <SeparatedRowLayout>
      <StyledButton>
        <Button
          actionState={actionState}
          encodedPermissionsId={encodedPermissionsId}
          label={label}
          onClick={onClick}
        />
      </StyledButton>
      {result !== undefined && <ApiCallResult>{result}</ApiCallResult>}
    </SeparatedRowLayout>
  );
};
