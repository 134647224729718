import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import {
  DETAIL_VIEW_HEAD_COLOUR_LIGHT,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

interface IContainerProps {
  isCollapsed: boolean;
}

const Container = styled.div<IContainerProps>`
  width: 100%;

  ${(props) =>
    props.isCollapsed === true
      ? `
    height: 0;
    overflow: hidden;
  `
      : `
    height: auto;
    overflow: visible;
  `}
`;

const Header = styled.div`
  align-items: center;
  background-color: ${DETAIL_VIEW_HEAD_COLOUR_LIGHT};
  display: flex;
  flex-direction: row;
  margin-bottom: ${STANDARD_SPACING_VALUE / 2}px;
  width: 100%;
`;

interface ICollapsibleSectionProps {
  children?: ReactNode;
  isDisabled?: boolean;
  title?: ReactNode;
  headerExtraControl?: ReactNode;
}

const CollapsibleSection = ({
  children,
  isDisabled,
  title,
  headerExtraControl,
}: ICollapsibleSectionProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(
      (previousIsCollapsed: boolean): boolean => !previousIsCollapsed,
    );
  }, []);

  return (
    <Wrapper>
      <Header>
        <IconButton
          icon={isCollapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
          isContained={false}
          isDisabled={isDisabled}
          noBorder={true}
          onClick={handleCollapse}
          transparentBackground={true}
        />
        {title}
        <div>{headerExtraControl}</div>
      </Header>
      <Container isCollapsed={isCollapsed}>{children}</Container>
    </Wrapper>
  );
};

export default CollapsibleSection;
