import CustomFilter from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilter/CustomFilter';
import {
  isBinaryOperator,
  isUnaryOperator,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/helpers';
import { ICustomFilter } from 'interfaces/Filter';
import { useCallback } from 'react';
import styled from 'styled-components';
import { copyCustomFilter } from 'utils/filter';

interface IContainerProps {
  isHalfPadding?: boolean;
}

const Container = styled.div<IContainerProps>`
  padding-left: ${(props) => (props.isHalfPadding ? '36' : '72')}px;
`;

interface ICustomFilterOperatorProps {
  customFilter: ICustomFilter;
  isHalfPadding?: boolean;
  onChange: (customFilter: ICustomFilter) => void;
}

const CustomFilterOperator = ({
  customFilter,
  isHalfPadding,
  onChange,
}: ICustomFilterOperatorProps): JSX.Element => {
  const { sub_filter, sub_filter_list, type } = customFilter;

  const getHandleChange = useCallback(
    (index?: number) => (customFilterChange: ICustomFilter) => {
      const updatedCustomFilter: ICustomFilter = copyCustomFilter(customFilter);

      if (index === undefined) {
        updatedCustomFilter.sub_filter = customFilterChange;
      } else {
        if (updatedCustomFilter.sub_filter_list === null) {
          updatedCustomFilter.sub_filter_list = [];
        }

        updatedCustomFilter.sub_filter_list[index] = customFilterChange;
      }

      onChange(updatedCustomFilter);
    },
    [customFilter, onChange],
  );

  const getHandleRemove = useCallback(
    (index?: number) => () => {
      const updatedCustomFilter: ICustomFilter = copyCustomFilter(customFilter);

      if (index === undefined) {
        updatedCustomFilter.sub_filter = null;
      } else if (updatedCustomFilter.sub_filter_list !== null) {
        updatedCustomFilter.sub_filter_list =
          updatedCustomFilter.sub_filter_list.filter(
            (_, subFilterListIndex: number): boolean =>
              subFilterListIndex !== index,
          );
      }

      onChange(updatedCustomFilter);
    },
    [customFilter, onChange],
  );

  return (
    <Container isHalfPadding={isHalfPadding}>
      {isUnaryOperator(type) && sub_filter !== null ? (
        <CustomFilter
          customFilter={sub_filter}
          onChange={getHandleChange()}
          onRemove={getHandleRemove()}
        />
      ) : null}
      {isBinaryOperator(type) && sub_filter_list !== null
        ? sub_filter_list.map(
            (subFilter: ICustomFilter, index: number): JSX.Element => (
              <CustomFilter
                customFilter={subFilter}
                key={`sub-filter-${index}`}
                onChange={getHandleChange(index)}
                onRemove={getHandleRemove(index)}
              />
            ),
          )
        : null}
    </Container>
  );
};

export default CustomFilterOperator;
