import { STANDARD_SPACING } from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import styled from 'styled-components';
import { separator } from 'utils/styles';

const Separator = styled.div<IThemedProps>`
  height: ${STANDARD_SPACING};

  ${(props) => separator(props)}
`;

export default Separator;
