import { CopyOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import { AxiosResponse } from 'axios';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityETagPopover from 'components/molecules/ToEntityETagPopover/ToEntityETagPopover';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { ECompositeState } from 'enums/ETag';
import { EActionState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { IETagDraftCreateResponse } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { useState } from 'react';
import { createETagDraftFromDistributed } from 'services/agent/tags/drafts';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import { detailPageLocationString } from 'utils/detail';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { IETagCopyPayload } from './interfaces';

const CopyIcon = styled(CopyOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagCopyPopoverProps {
  contactInfo?: IContactInfo;
  eTagCopyPayload: IETagCopyPayload | undefined;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagCopyPopover = (
  props: IToEntityETagCopyPopoverProps,
): JSX.Element => {
  const { contactInfo, eTagCopyPayload, timeZone, toEntityId } = props;
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [startDateTime, setStartDateTime] = useState<ZonedDateTime | null>(
    eTagCopyPayload === undefined || eTagCopyPayload.start_date === null
      ? null
      : ZonedDateTime.parseIso(eTagCopyPayload.start_date, timeZone),
  );
  const [includeEnergyProfiles, setIncludeEnergyProfiles] =
    useState<boolean>(false);
  const [includeTransmissionProfiles, setIncludeTransmissionProfiles] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  const handleCreate = async () => {
    try {
      setActionState(EActionState.Actioning);

      const response: AxiosResponse<IETagDraftCreateResponse> =
        await createETagDraftFromDistributed(
          toEntityId,
          eTagCopyPayload!.tag_primary_key,
          includeEnergyProfiles,
          includeTransmissionProfiles,
          startDateTime!,
          contactInfo,
        );
      const eTagDraftCreateResponse: IETagDraftCreateResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(eTagDraftCreateResponse.errorMessage!);
      }

      setActionState(EActionState.Succeeded);

      if (eTagDraftCreateResponse.response.draft_id != null) {
        const href: string = detailPageLocationString({
          defaultTimeZone: timeZone,
          draftId: eTagDraftCreateResponse.response.draft_id,
          mode: EPageMode.Edit,
          toEntity: toEntityId,
        });
        window.open(`${process.env.PUBLIC_URL}${href}`, '_blank');
      } else {
        throw new Error('No draft_id in response, cannot open draft edit page');
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during draft tag creation. Please try again later.',
      );

      setActionState(EActionState.Failed);
    }
  };

  return (
    <ToEntityETagPopover
      actionState={actionState}
      errorMessage={errorMessage}
      isPrimaryActionDisabled={startDateTime === null}
      onPrimaryAction={handleCreate}
      onStartDateTimeChange={setStartDateTime}
      primaryActionLabel='Copy'
      startDateTime={startDateTime}
      timeZone={timeZone}
      toEntityId={toEntityId}
      title={`Copy tag ${eTagCopyPayload?.tag_id.ui_tag_id}`}
    >
      <Checkbox
        checked={includeEnergyProfiles}
        label='Include Energy Profiles'
        onChange={setIncludeEnergyProfiles}
      />
      <Checkbox
        checked={includeTransmissionProfiles}
        label='Include Transmission Profiles'
        onChange={setIncludeTransmissionProfiles}
      />
    </ToEntityETagPopover>
  );
};

interface IToEntityETagCopyProps {
  contactInfo?: IContactInfo;
  encodedPermissionsId: string;
  eTagCopyPayload: IETagCopyPayload | undefined;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagCopy = (props: IToEntityETagCopyProps): JSX.Element => {
  const {
    contactInfo,
    encodedPermissionsId,
    eTagCopyPayload,
    timeZone,
    toEntityId,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isDisabled: boolean =
    eTagCopyPayload === undefined ||
    eTagCopyPayload.composite_state === ECompositeState.Draft;

  return (
    <Tooltip isDisabled={isDisabled || isVisible} title='Copy Tag'>
      <AntDesignPopover
        content={
          <ToEntityETagCopyPopover
            contactInfo={contactInfo}
            eTagCopyPayload={eTagCopyPayload}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<CopyIcon />}
          isDisabled={isDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagCopy;
