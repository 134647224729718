import { UserOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import UserButtonPopover from 'components/molecules/UserButton/UserButtonPopover';
import { ETheme } from 'enums/Style';
import { IThemedProps } from 'interfaces/Component';
import { useState } from 'react';
import { TToEntityUserStates } from 'reduxes/User/types';
import styled from 'styled-components';
import { colourForTheme } from 'utils/styles';

const ActionIconContainer = styled.div<IThemedProps>`
  > .anticon {
    font-size: 18px;
    color: ${colourForTheme};
  }
`;

interface UserButtonProps {
  currentTheme: ETheme;
  userState: TToEntityUserStates;
}

const UserButton = (props: UserButtonProps): JSX.Element => {
  const { currentTheme: theme, userState } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Tooltip isDisabled={isVisible} placement='bottomLeft' title='Sign Out'>
      <AntDesignPopover
        content={<UserButtonPopover userState={userState} />}
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottomLeft'
        trigger='click'
        visible={isVisible}
      >
        <ActionIconContainer currentTheme={theme}>
          <UserOutlined />
        </ActionIconContainer>
      </AntDesignPopover>
    </Tooltip>
  );
};

export default UserButton;
