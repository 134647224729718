import usePrevious from 'hooks/usePrevious';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ResizeDetector from 'react-resize-detector';
import ScrollbarsCustom from 'react-scrollbars-custom';

export interface IDetectableOverflowProps {
  children?: ReactNode;
  className?: string;
  elementRef?: HTMLElement;
  onOverflowedX?: (isOverflowedX: boolean) => void;
  onOverflowedY?: (isOverflowedY: boolean) => void;
}

const DetectableOverflow = (props: IDetectableOverflowProps): JSX.Element => {
  const { children, className, elementRef, onOverflowedX, onOverflowedY } =
    props;
  const [isOverflowedX, setIsOverflowedX] = useState<boolean | undefined>(
    undefined,
  );
  const [isOverflowedY, setIsOverflowedY] = useState<boolean | undefined>(
    undefined,
  );
  const scrollbarsCustomRef = useRef<
    ScrollbarsCustom & HTMLDivElement
  >() as MutableRefObject<ScrollbarsCustom & HTMLDivElement>;
  const innerRef = useRef<HTMLElement | null>(
    elementRef === undefined ? null : elementRef,
  );
  const wrapperRef = useRef<HTMLElement | null>(null);
  const previousElementRef = usePrevious<HTMLElement | null | undefined>(
    elementRef,
  );

  useEffect(() => {
    if (innerRef.current === null) {
      innerRef.current = scrollbarsCustomRef.current;
    } else {
      wrapperRef.current = scrollbarsCustomRef.current;
    }
  }, [scrollbarsCustomRef]);

  useEffect(() => {
    if (previousElementRef !== elementRef) {
      if (elementRef === undefined || elementRef === null) {
        if (wrapperRef.current !== null) {
          innerRef.current = wrapperRef.current;
        }
      } else {
        innerRef.current = elementRef;
      }
    }

    if (innerRef.current) {
      const newIsOverflowedX: boolean =
        innerRef.current.clientWidth !== innerRef.current.scrollWidth;

      if (newIsOverflowedX !== isOverflowedX) {
        setIsOverflowedX(newIsOverflowedX);

        if (onOverflowedX) {
          onOverflowedX(newIsOverflowedX);
        }
      }

      const newIsOverflowedY: boolean =
        innerRef.current.clientHeight !== innerRef.current.scrollHeight;

      if (newIsOverflowedY !== isOverflowedY) {
        setIsOverflowedY(newIsOverflowedY);

        if (onOverflowedY) {
          onOverflowedY(newIsOverflowedY);
        }
      }
    }
  }, [
    children,
    className,
    elementRef,
    isOverflowedX,
    isOverflowedY,
    onOverflowedX,
    onOverflowedY,
    previousElementRef,
  ]);

  const handleResize = useCallback(() => {
    if (innerRef.current) {
      const newIsOverflowedX: boolean =
        innerRef.current.clientWidth !== innerRef.current.scrollWidth;

      if (newIsOverflowedX !== isOverflowedX) {
        setIsOverflowedX(newIsOverflowedX);

        if (onOverflowedX) {
          onOverflowedX(newIsOverflowedX);
        }
      }

      const newIsOverflowedY: boolean =
        innerRef.current.clientHeight !== innerRef.current.scrollHeight;

      if (newIsOverflowedY !== isOverflowedY) {
        setIsOverflowedY(newIsOverflowedY);

        if (onOverflowedY) {
          onOverflowedY(newIsOverflowedY);
        }
      }
    }
  }, [isOverflowedX, isOverflowedY, onOverflowedX, onOverflowedY]);

  return (
    <ScrollbarsCustom
      className={className}
      noDefaultStyles={true}
      ref={scrollbarsCustomRef}
    >
      <ResizeDetector handleWidth={true} onResize={handleResize} />
      {children}
    </ScrollbarsCustom>
  );
};

export default DetectableOverflow;
