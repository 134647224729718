import { IETagCopyPayload } from 'components/molecules/ToEntityETagCopy/interfaces';
import {
  COLUMN_0,
  COLUMN_1,
  COLUMN_2,
  COLUMN_3,
  NARROW_COLUMN,
  ROW_0,
  ROW_0_EDIT,
  ROW_1,
  ROW_1_EDIT,
  ROW_2,
  ROW_2_EDIT,
  ROW_3,
  ROW_3_EDIT,
} from 'components/organisms/ETagManager/constants';
import MarketInformationView from 'components/organisms/MarketInformationView/MarketInformationView';
import MarketPathView from 'components/organisms/MarketPathView/MarketPathView';
import PathDiagramView from 'components/organisms/PathDiagramView/PathDiagramView';
import PhysicalPathView from 'components/organisms/PhysicalPathView/PhysicalPathView';
import ProfileGraphView from 'components/organisms/ProfileGraphView/ProfileGraphView';
import ProfileInformationView from 'components/organisms/ProfileInformationView/ProfileInformationView';
import RequestsInformationView from 'components/organisms/RequestsInformationView/RequestsInformationView';
import SummaryInformationView from 'components/organisms/SummaryInformationView/SummaryInformationView';
import { VIEW_BASE_Z_INDEX } from 'constants/styles';
import { ECompositeState, EDistributedTagItem, EMessageType } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import { IDistributedTagItemDetailStateLoadTransform } from 'interfaces/Detail';
import { IETagNotification, IETagTagId } from 'interfaces/ETag';
import { IViewLayout } from 'interfaces/View';
import { TDetailValidations } from 'types/Detail';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { TViewLayoutMap } from 'types/View';
import {
  retrieveAndTransformDistributedTagAllTransmissionAllocations,
  retrieveAndTransformDistributedTagLossAccountings,
  retrieveAndTransformDistributedTagMarketSegment,
  retrieveAndTransformDistributedTagPhysicalSegment,
  retrieveAndTransformDistributedTagPhysicalSegmentsProfiles,
  retrieveAndTransformDistributedTagSummary,
  retrieveAndTransformDistributedTagTransactionStatuses,
  retrieveAndTransformDistributedTagTransmissionAllocations,
} from 'utils/detail';

export const getViewColumnsRows = (viewMode: EViewMode) => ({
  viewColumns: [COLUMN_0, COLUMN_1, COLUMN_2, COLUMN_3],
  viewRows:
    viewMode === EViewMode.EditETagDraft ||
    viewMode === EViewMode.EditETagTemplate
      ? [ROW_0_EDIT, ROW_1_EDIT, ROW_2_EDIT, ROW_2_EDIT]
      : [ROW_0, ROW_1, ROW_2, ROW_2],
});

export const getViewLayoutMap = (): TViewLayoutMap => ({
  physicalPath: {
    layoutGrid: {
      gridColumn: '1 / 4',
      gridRow: '1 / 3',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: PhysicalPathView,
  },
  marketPath: {
    layoutGrid: {
      gridColumn: '4 / 5',
      gridRow: '4 / 5',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: MarketPathView,
  },
  summaryInformation: {
    layoutGrid: {
      gridColumn: '4 / 5',
      gridRow: '1 / 3',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: SummaryInformationView,
  },
  requestsInformation: {
    layoutGrid: {
      gridColumn: '4 / 5',
      gridRow: '3 / 4',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: RequestsInformationView,
  },
  profileInformation: {
    layoutGrid: {
      gridColumn: '1 / 4',
      gridRow: '3 / 5',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: ProfileInformationView,
  },
  pathDiagram: {
    layoutGrid: {
      gridColumn: '1 / 4',
      gridRow: '1 / 3',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: PathDiagramView,
  },
  profileGraph: {
    layoutGrid: {
      gridColumn: '1 / 4',
      gridRow: '3 / 5',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: ProfileGraphView,
  },
});

export const getMarketInformationViewLayoutMap = (): IViewLayout => ({
  layoutGrid: {
    gridColumn: '4 / 5',
    gridRow: '4 / 5',
    zIndex: VIEW_BASE_Z_INDEX,
  },
  View: MarketInformationView,
});

export const getNarrowViewColumnsRows = (viewMode: EViewMode) => ({
  viewColumns: [NARROW_COLUMN, NARROW_COLUMN],
  viewRows:
    viewMode === EViewMode.EditETagDraft ||
    viewMode === EViewMode.EditETagTemplate
      ? [
          ROW_0_EDIT,
          ROW_1_EDIT,
          ROW_2_EDIT,
          ROW_2_EDIT,
          ROW_3_EDIT,
          ROW_3_EDIT,
          ROW_3_EDIT,
          ROW_3_EDIT,
        ]
      : [ROW_0, ROW_1, ROW_2, ROW_0, ROW_3, ROW_3, ROW_3, ROW_3],
});

export const getNarrowViewLayoutMap = (): TViewLayoutMap => ({
  physicalPath: {
    layoutGrid: {
      gridColumn: '1 / 3',
      gridRow: '1 / 3',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: PhysicalPathView,
  },
  profileInformation: {
    layoutGrid: {
      gridColumn: '1 / 3',
      gridRow: '3 / 5',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: ProfileInformationView,
  },
  summaryInformation: {
    layoutGrid: {
      gridColumn: '1 / 2',
      gridRow: '7 / 9',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: SummaryInformationView,
  },
  marketPath: {
    layoutGrid: {
      gridColumn: '2 / 3',
      gridRow: '7 / 9',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: MarketPathView,
  },
  requestsInformation: {
    layoutGrid: {
      gridColumn: '1 / 2',
      gridRow: '5 / 7',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: RequestsInformationView,
  },
  pathDiagram: {
    layoutGrid: {
      gridColumn: '1 / 3',
      gridRow: '1 / 3',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: PathDiagramView,
  },
  profileGraph: {
    layoutGrid: {
      gridColumn: '1 / 3',
      gridRow: '3 / 5',
      zIndex: VIEW_BASE_Z_INDEX,
    },
    View: ProfileGraphView,
  },
});

export const getNarrowMarketInformationViewLayoutMap = (): IViewLayout => ({
  layoutGrid: {
    gridColumn: '2 / 3',
    gridRow: '8 / 9',
    zIndex: VIEW_BASE_Z_INDEX,
  },
  View: MarketInformationView,
});

export const hasSameViewLayouts = (
  a: TViewLayoutMap,
  b: TViewLayoutMap,
): boolean => {
  const aKeys: string[] = Object.keys(a);
  const bKeys: string[] = Object.keys(b);

  return aKeys.length === bKeys.length;
};

export const copyViewLayout = (viewLayout: IViewLayout): IViewLayout => ({
  layoutGrid: { ...viewLayout.layoutGrid },
  View: viewLayout.View,
});

export const copyViewLayoutMap = (
  viewLayoutMap: TViewLayoutMap,
): TViewLayoutMap => {
  const newViewLayoutMap: TViewLayoutMap = {};

  Object.keys(viewLayoutMap).forEach((key: string) => {
    newViewLayoutMap[key] = copyViewLayout(viewLayoutMap[key]);
  });

  return newViewLayoutMap;
};

export const isAllValid = (detailValidations: TDetailValidations): boolean => {
  let allValid: boolean = true;

  for (const key in detailValidations) {
    allValid = allValid && detailValidations[key];
  }

  return allValid;
};

export const getAllDistributedTagItemStateLoadTransforms = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
): IDistributedTagItemDetailStateLoadTransform[] => [
  {
    detailStateLoadTransform: retrieveAndTransformDistributedTagSummary(
      toEntityId,
      tagPrimaryKey,
    ),
    distributedTagItem: EDistributedTagItem.Summary,
  },
  {
    detailStateLoadTransform: retrieveAndTransformDistributedTagMarketSegment(
      toEntityId,
      tagPrimaryKey,
    ),
    distributedTagItem: EDistributedTagItem.MarketSegment,
  },
  {
    detailStateLoadTransform: retrieveAndTransformDistributedTagPhysicalSegment(
      toEntityId,
      tagPrimaryKey,
    ),
    distributedTagItem: EDistributedTagItem.PhysicalSegment,
  },
  {
    detailStateLoadTransform: retrieveAndTransformDistributedTagLossAccountings(
      toEntityId,
      tagPrimaryKey,
    ),
    distributedTagItem: EDistributedTagItem.LossAccountings,
  },
  {
    detailStateLoadTransform:
      retrieveAndTransformDistributedTagTransmissionAllocations(
        toEntityId,
        tagPrimaryKey,
      ),
    distributedTagItem: EDistributedTagItem.TransmissionAllocations,
  },
  {
    detailStateLoadTransform:
      retrieveAndTransformDistributedTagAllTransmissionAllocations(
        toEntityId,
        tagPrimaryKey,
      ),
    distributedTagItem: EDistributedTagItem.AllTransmissionAllocations,
  },
  {
    detailStateLoadTransform:
      retrieveAndTransformDistributedTagTransactionStatuses(
        toEntityId,
        tagPrimaryKey,
      ),
    distributedTagItem: EDistributedTagItem.TransactionStatuses,
  },
  {
    detailStateLoadTransform:
      retrieveAndTransformDistributedTagPhysicalSegmentsProfiles(
        toEntityId,
        tagPrimaryKey,
      ),
    distributedTagItem: EDistributedTagItem.PhysicalSegmentsProfiles,
  },
];

export const getEtagCopyPayload = (
  compositeState: ECompositeState | null,
  startDate: string | null,
  tagId: IETagTagId | null,
  tagPrimaryKey: string | undefined,
): IETagCopyPayload | undefined =>
  tagId === null || tagPrimaryKey === undefined
    ? undefined
    : {
        composite_state: compositeState,
        start_date: startDate,
        tag_id: tagId,
        tag_primary_key: tagPrimaryKey,
      };

export const notificationsToStateLoadTransforms = (
  eTagNotifications: IETagNotification[],
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
): IDistributedTagItemDetailStateLoadTransform[] => {
  const distributedTagItemDetailStateLoadTransforms: IDistributedTagItemDetailStateLoadTransform[] =
    [];

  eTagNotifications.forEach((eTagNotification: IETagNotification) => {
    const { message_type } = eTagNotification;
    switch (message_type) {
      case EMessageType.CompositeStateChange: {
        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform: retrieveAndTransformDistributedTagSummary(
            toEntityId,
            tagPrimaryKey,
          ),
          distributedTagItem: EDistributedTagItem.Summary,
        });

        break;
      }
      case EMessageType.DistributeCorrection: {
        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagLossAccountings(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.LossAccountings,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagMarketSegment(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.MarketSegment,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagPhysicalSegment(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.PhysicalSegment,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform: retrieveAndTransformDistributedTagSummary(
            toEntityId,
            tagPrimaryKey,
          ),
          distributedTagItem: EDistributedTagItem.Summary,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransactionStatuses(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransactionStatuses,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransmissionAllocations(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransmissionAllocations,
        });

        break;
      }
      case EMessageType.DistributeProfileChange: {
        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagLossAccountings(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.LossAccountings,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagPhysicalSegmentsProfiles(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.PhysicalSegmentsProfiles,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform: retrieveAndTransformDistributedTagSummary(
            toEntityId,
            tagPrimaryKey,
          ),
          distributedTagItem: EDistributedTagItem.Summary,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransactionStatuses(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransactionStatuses,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransmissionAllocations(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransmissionAllocations,
        });

        break;
      }
      case EMessageType.DistributeResolution: {
        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagLossAccountings(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.LossAccountings,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagPhysicalSegmentsProfiles(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.PhysicalSegmentsProfiles,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform: retrieveAndTransformDistributedTagSummary(
            toEntityId,
            tagPrimaryKey,
          ),
          distributedTagItem: EDistributedTagItem.Summary,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransactionStatuses(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransactionStatuses,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransmissionAllocations(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransmissionAllocations,
        });

        break;
      }
      case EMessageType.DistributeStatus: {
        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransactionStatuses(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransactionStatuses,
        });

        break;
      }
      case EMessageType.DistributeTerminateTag: {
        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagPhysicalSegmentsProfiles(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.PhysicalSegmentsProfiles,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform: retrieveAndTransformDistributedTagSummary(
            toEntityId,
            tagPrimaryKey,
          ),
          distributedTagItem: EDistributedTagItem.Summary,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransactionStatuses(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransactionStatuses,
        });

        distributedTagItemDetailStateLoadTransforms.push({
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransmissionAllocations(
              toEntityId,
              tagPrimaryKey,
            ),
          distributedTagItem: EDistributedTagItem.TransmissionAllocations,
        });

        break;
      }
      default: {
        break;
      }
    }
  });

  return distributedTagItemDetailStateLoadTransforms;
};

export const getUniqueETagNotifications = (
  eTagNotifications: IETagNotification[],
): IETagNotification[] => {
  const uniqueETagNotifications: IETagNotification[] = [];
  const messageTypeSet: Set<EMessageType> = new Set();

  eTagNotifications.forEach((eTagNotification: IETagNotification) => {
    const { message_type } = eTagNotification;

    if (message_type === undefined) {
      throw new Error(
        `Missing message_type for eTagNotification: ${JSON.stringify(
          eTagNotification,
        )}`,
      );
    }

    if (!messageTypeSet.has(message_type)) {
      messageTypeSet.add(message_type);

      uniqueETagNotifications.push(eTagNotification);
    }
  });

  return uniqueETagNotifications;
};
