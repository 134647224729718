interface IDraftIconProps {
  className?: string;
}

const DraftIcon = ({ className }: IDraftIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#1e90ff'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M811.107 225.492c-38.519-33.987-100.868-33.992-139.392 0-14.246 12.571-408.423 360.377-440.399 388.589-1.819 1.605-3.343 3.648-4.306 5.889-.023.051-.053.1-.076.152l-65.728 157.337c-5.583 13.36 9.192 26.403 24.34 21.477l178.316-57.995c.06-.02.116-.049.177-.069 2.55-.854 4.87-2.208 6.672-3.798 14.107-12.448 377.789-333.342 440.396-388.588 38.524-33.99 38.524-89.002 0-122.994zM357.306 701.595l-85.78-75.689 386.011-340.599 85.78 75.689-386.011 340.599zm426.996-376.761l-14.176 12.508-85.782-75.688 14.178-12.51c23.707-20.916 62.071-20.918 85.78 0 23.707 20.917 23.707 54.771 0 75.69z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default DraftIcon;
