import { Tooltip as AntDesignTooltip } from 'antd';
import ContentWrapper from 'components/molecules/Tooltip/ContentWrapper';
import {
  TOOLTIP_MOUSE_ENTER_DELAY_IN_SECONDS,
  TOOLTIP_MOUSE_LEAVE_DELAY_IN_SECONDS,
} from 'constants/misc';
import usePrevious from 'hooks/usePrevious';
import { ReactNode, useEffect, useState } from 'react';
import { TPlacement } from 'types/General';
import { isEmptyValue } from 'utils/general';
import styled from 'styled-components';
import ScrollableContent from '../ScrollableContent/ScrollableContent';

export interface ITooltipProps {
  children?: ReactNode;
  className?: string;
  isDisabled?: boolean;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  placement?: TPlacement;
  title: string;
  wrapTextTitle?: boolean;
}

const StyledWrappedText = styled.p`
  width: 725px;
  word-break: break-word;
  white-space: pre-line;
`;

const Tooltip = ({
  children,
  className,
  isDisabled,
  mouseEnterDelay,
  mouseLeaveDelay,
  placement,
  title,
  wrapTextTitle,
}: ITooltipProps): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const previousIsDisabled: boolean | undefined = usePrevious(isDisabled);

  useEffect(() => {
    if (previousIsDisabled !== isDisabled && isDisabled === true) {
      setVisible(false);
    }
  }, [isDisabled, previousIsDisabled]);

  useEffect(() => {
    if (isEmptyValue(title)) {
      setVisible(false);
    }
  }, [title]);

  const handleVisible = (visible: boolean) => {
    setVisible(isDisabled ? false : visible);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const getWrappedTitle = () => {
    return (
      <ScrollableContent maximumHeight={240}>
        <StyledWrappedText>{title}</StyledWrappedText>
      </ScrollableContent>
    );
  };

  return (
    <AntDesignTooltip
      overlayStyle={{ maxWidth: '1920px' }}
      destroyTooltipOnHide={true}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      onVisibleChange={handleVisible}
      placement={placement}
      title={wrapTextTitle ? getWrappedTitle : title}
      visible={wrapTextTitle ? undefined : visible}
    >
      <ContentWrapper
        className={className}
        hideTooltip={hideTooltip}
        isDisabled={isDisabled}
        visible={visible}
      >
        {children}
      </ContentWrapper>
    </AntDesignTooltip>
  );
};

Tooltip.defaultProps = {
  mouseEnterDelay: TOOLTIP_MOUSE_ENTER_DELAY_IN_SECONDS,
  mouseLeaveDelay: TOOLTIP_MOUSE_LEAVE_DELAY_IN_SECONDS,
} as Partial<ITooltipProps>;

export default Tooltip;
