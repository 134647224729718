import { IOption } from '../../../interfaces/Component';
import { EAlertAcknowledged } from '../../../enums/Alert';
import { ESeverity } from '../../../enums/General';

export const acknowledgeStatusOptions: IOption<EAlertAcknowledged>[] = [
  { label: 'All', value: EAlertAcknowledged.All },
  { label: 'Read', value: EAlertAcknowledged.Read },
  { label: 'Unread', value: EAlertAcknowledged.Unread },
];

export const alertSeverityOptions: IOption<ESeverity>[] = [
  { label: 'Error', value: ESeverity.Error },
  { label: 'Info', value: ESeverity.Info },
  { label: 'Warning', value: ESeverity.Warning },
];
