import DetailView from 'components/molecules/DetailView/DetailView';
import SummaryInformationTitle from 'components/molecules/SummaryInformationTitle/SummaryInformationTitle';
import {
  CONNECTOR_HEIGHT_VALUE,
  DEFAULT_NODE_SIZE_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import PathDiagram from 'components/organisms/PathDiagramView/PathDiagram';
import {
  EBlockType,
  IPathDiagramBlockData,
} from 'components/organisms/PathDiagramView/types';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import { EViewResize } from 'enums/View';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IETagPhysicalSegmentsProfile,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { IViewProps } from 'interfaces/View';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import {
  getOasisInfosForPhysicalSegment,
  getPseFromMarketSegments,
  getTransmissionAllocationTotal,
  getTransmissionSegmentFromPhysicalSegmentsProfile,
  getUniqueLossMethodsForPhysicalSegment,
} from 'utils/detail';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';

const StyledTitle = styled(SummaryInformationTitle)`
  position: absolute;
  padding-left: 500px;
`;

const retrievePathDiagramViewState = (state: TRootState) => {
  const {
    active,
    composite_state,
    generationPhysicalSegment,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    name,
    physicalSegmentsProfiles,
    retrievingDetail,
    retrievingDistributedTagItems,
    selectedPlotTime,
    tag_id,
    transmissionAllocations,
    transmission_physical_segments,
    viewMode,
  } = state.detail.present;

  const isTransmissionAllocationsLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isLossAccountingsLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isMarketSegmentLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isPhysicalSegmentLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    isTransmissionAllocationsLoading ||
    isLossAccountingsLoading ||
    isMarketSegmentLoading ||
    isPhysicalSegmentLoading ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegmentsProfiles]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[
        EDistributedTagItem.PhysicalSegmentsProfiles
      ].retrieveState !== ERetreiveState.RetrievingCompleted);

  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    isTransmissionAllocationsLoading ||
    isLossAccountingsLoading ||
    isMarketSegmentLoading ||
    isPhysicalSegmentLoading;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    active,
    composite_state,
    generationPhysicalSegment,
    isDetailLoading,
    isLoading,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    name,
    physicalSegmentsProfiles,
    selectedPlotTime,
    tag_id,
    timeZone,
    transmissionAllocations,
    transmission_physical_segments,
    viewMode,
  };
};

const PathDiagramView = (props: IViewProps): JSX.Element => {
  const {
    active,
    name,
    composite_state,
    generationPhysicalSegment,
    isDetailLoading,
    isLoading,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    physicalSegmentsProfiles,
    selectedPlotTime,
    tag_id,
    timeZone,
    transmissionAllocations,
    transmission_physical_segments,
    viewMode,
  } = useSelector(retrievePathDiagramViewState);
  const { layoutGrid, resize, viewId } = props;

  const { blocksData, nodeSize } = useMemo(() => {
    const blocksData: IPathDiagramBlockData[] = [];
    let mostOasisInfos: number = 0;

    if (selectedPlotTime !== undefined) {
      let eTagPhysicalSegmentsProfile:
        | IETagPhysicalSegmentsProfile
        | undefined =
        physicalSegmentsProfiles === null
          ? undefined
          : physicalSegmentsProfiles.find(
              (
                eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
              ): boolean =>
                eTagPhysicalSegmentsProfile.stop !== null &&
                ZonedDateTime.fromDate(selectedPlotTime, timeZone).isBefore(
                  ZonedDateTime.parseIso(
                    eTagPhysicalSegmentsProfile.stop,
                    timeZone,
                  ),
                ),
            );

      if (
        eTagPhysicalSegmentsProfile === undefined &&
        physicalSegmentsProfiles !== null &&
        physicalSegmentsProfiles.length > 0
      ) {
        eTagPhysicalSegmentsProfile =
          physicalSegmentsProfiles[physicalSegmentsProfiles.length - 1];
      }

      if (eTagPhysicalSegmentsProfile !== undefined) {
        if (generationPhysicalSegment !== null) {
          const pse: IEntityInfo | null = getPseFromMarketSegments(
            generationPhysicalSegment.market_segment_id,
            marketSegments,
          );
          const gpeName: string | null = pse === null ? null : pse.entity_code;
          const gcaName: string | null =
            tag_id === null || tag_id.gca === null
              ? null
              : tag_id.gca.entity_code;
          const generationSourceName: string | null =
            generationPhysicalSegment.generation_source === null
              ? null
              : generationPhysicalSegment.generation_source.point_name;

          blocksData.push({
            labelledValues: [
              { label: 'GPE', value: gpeName },
              { label: 'GCA', value: gcaName },
            ],
            mw:
              eTagPhysicalSegmentsProfile.physical_segments_profiles === null ||
              eTagPhysicalSegmentsProfile.physical_segments_profiles
                .generation === null ||
              eTagPhysicalSegmentsProfile.physical_segments_profiles.generation
                .profile === null
                ? null
                : eTagPhysicalSegmentsProfile.physical_segments_profiles
                    .generation.profile.mw,
            rightNodeLabel: generationSourceName,
            physical_segment_id: generationPhysicalSegment.physical_segment_id,
            type: EBlockType.Generation,
          });
        }

        if (transmission_physical_segments !== null) {
          transmission_physical_segments.forEach(
            (
              eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
              index: number,
            ) => {
              const eTagTransmissionSegment: IETagTransmissionSegment =
                getTransmissionSegmentFromPhysicalSegmentsProfile(
                  index,
                  eTagPhysicalSegmentsProfile!,
                  eTagTransmissionPhysicalSegment,
                );
              const pse: IEntityInfo | null = getPseFromMarketSegments(
                eTagTransmissionPhysicalSegment.market_segment_id,
                marketSegments,
              );
              const pseName: string | null =
                pse === null ? null : pse.entity_code;
              const tpCodeName: string | null =
                eTagTransmissionPhysicalSegment.tp_code == null
                  ? null
                  : eTagTransmissionPhysicalSegment.tp_code.entity_code;
              const ba: string[] =
                eTagTransmissionPhysicalSegment.scheduling_entities === null
                  ? []
                  : eTagTransmissionPhysicalSegment.scheduling_entities.map(
                      (entityInfo: IEntityInfo): string =>
                        entityInfo.entity_code,
                    );
              const porName: string | null =
                eTagTransmissionPhysicalSegment.por === null
                  ? null
                  : eTagTransmissionPhysicalSegment.por.point_name;
              const podName: string | null =
                eTagTransmissionPhysicalSegment.pod === null
                  ? null
                  : eTagTransmissionPhysicalSegment.pod.point_name;
              const oasisInfos: IETagTransmissionAllocation[] =
                getOasisInfosForPhysicalSegment(
                  eTagTransmissionPhysicalSegment.physical_segment_id,
                  transmissionAllocations,
                );
              const lossAccountingMethodNames: string[] =
                getUniqueLossMethodsForPhysicalSegment(
                  eTagTransmissionPhysicalSegment.physical_segment_id,
                  lossAccountings,
                );

              blocksData.push({
                labelledValues: [
                  { label: 'PSE', value: pseName },
                  { label: 'TP', value: tpCodeName },
                  { label: 'BA', value: ba.join(', ') },
                ],
                leftNodeLabel: porName,
                lossAccountingMethodNames,
                mw: null,
                oasisInfos,
                rightNodeLabel:
                  index === transmission_physical_segments.length - 1
                    ? podName
                    : undefined,
                physical_segment_id:
                  eTagTransmissionPhysicalSegment.physical_segment_id,
                transmissionMW: {
                  lossMW:
                    eTagTransmissionSegment.pod_energy_profile === null ||
                    eTagTransmissionSegment.pod_energy_profile.mw === null ||
                    eTagTransmissionSegment.por_energy_profile === null ||
                    eTagTransmissionSegment.por_energy_profile.mw === null
                      ? null
                      : eTagTransmissionSegment.por_energy_profile.mw -
                        eTagTransmissionSegment.pod_energy_profile.mw,
                  podMW:
                    eTagTransmissionSegment.pod_energy_profile === null ||
                    eTagTransmissionSegment.pod_energy_profile.mw === null
                      ? null
                      : eTagTransmissionSegment.pod_energy_profile.mw,
                  totalMW: getTransmissionAllocationTotal(
                    eTagTransmissionSegment,
                  ),
                },
                type: EBlockType.Transmission,
              });

              if (oasisInfos.length > mostOasisInfos) {
                mostOasisInfos = oasisInfos.length;
              }
            },
          );
        }

        if (loadPhysicalSegment !== null) {
          const pse: IEntityInfo | null = getPseFromMarketSegments(
            loadPhysicalSegment.market_segment_id,
            marketSegments,
          );
          const lseName: string | null = pse === null ? null : pse.entity_code;
          const lcaName: string | null =
            tag_id === null || tag_id.lca === null
              ? null
              : tag_id.lca.entity_code;
          const loadSinkName: string | null =
            loadPhysicalSegment.load_sink === null
              ? null
              : loadPhysicalSegment.load_sink.point_name;

          blocksData.push({
            labelledValues: [
              { label: 'LSE', value: lseName },
              { label: 'LCA', value: lcaName },
            ],
            leftNodeLabel: loadSinkName,
            mw:
              eTagPhysicalSegmentsProfile.physical_segments_profiles === null ||
              eTagPhysicalSegmentsProfile.physical_segments_profiles.load ===
                null ||
              eTagPhysicalSegmentsProfile.physical_segments_profiles.load
                .profile === null
                ? null
                : eTagPhysicalSegmentsProfile.physical_segments_profiles.load
                    .profile.mw,
            physical_segment_id: loadPhysicalSegment.physical_segment_id,
            type: EBlockType.Load,
          });
        }
      }
    }

    return {
      blocksData,
      nodeSize: Math.max(
        mostOasisInfos * CONNECTOR_HEIGHT_VALUE,
        DEFAULT_NODE_SIZE_VALUE,
      ),
    };
  }, [
    generationPhysicalSegment,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    physicalSegmentsProfiles,
    selectedPlotTime,
    tag_id,
    timeZone,
    transmissionAllocations,
    transmission_physical_segments,
  ]);

  const plotTime = useMemo(
    () =>
      selectedPlotTime === undefined
        ? undefined
        : ZonedDateTime.fromDate(selectedPlotTime, timeZone).format(
            DATE_TIME_FORMAT,
          ),
    [selectedPlotTime, timeZone],
  );

  return (
    <DetailView
      className='path-diagram-view'
      isLoading={isLoading}
      layoutGrid={layoutGrid}
      resize={resize}
      title={<>Path Diagram</>}
      unformattedTitle={
        <StyledTitle
          active={active}
          compositeState={composite_state}
          isLoading={isDetailLoading}
          name={name}
          uiTagId={tag_id === null ? null : tag_id.ui_tag_id}
          viewMode={viewMode}
        />
      }
      viewId={viewId}
      viewResizeSetting={EViewResize.Initial}
    >
      <PathDiagram
        blocksData={blocksData}
        nodeSize={nodeSize}
        plotTime={plotTime}
      />
    </DetailView>
  );
};

export default PathDiagramView;
