import AlertHistoryUI from './AlertHistoryUI';
import { useState } from 'react';
import {
  IAlertConfiguration,
  IAlertConfigurationsResponse,
  IAlertRule,
  IAlertRulesResponse,
  IConfiguredAlert,
} from '../../../interfaces/Alert';
import { TAlertRulesMap } from '../../../types/Alert';
import { TToEntityId } from '../../../types/ToEntity';
import { IToEntityUserState } from '../../../reduxes/User/types';
import { retrieveAlertRules } from '../../../services/alert/rules';
import useAsyncEffect from 'use-async-effect';
import { IUserInfo } from '../../../interfaces/User';
import { encodeIds, isSuccessStatus } from '../../../utils/general';
import { alertConfigurationSorterFor } from '../../organisms/ToEntityMonitor/helpers';
import { ZonedDateTime } from '../../../utils/zonedDateTime';
import { retrieveAlertConfigurations } from '../../../services/alert/userConfig';
import { IToEntity } from '../../../interfaces/ToEntity';

interface IProps {
  userInfo?: IUserInfo;
  toEntity?: IToEntity | undefined;
  toEntityId?: TToEntityId | null;
  toEntityUserState?: IToEntityUserState | undefined;
}

const ToEntityAlertHistoryUI = (props: IProps): JSX.Element => {
  const [toEntityId, setToEntityId] = useState<TToEntityId>('');
  const [alertRulesMap, setAlertRulesMap] = useState<TAlertRulesMap>({});
  const [alertConfigurations, setAlertConfigurations] = useState<
    IAlertConfiguration[]
  >([]);
  const { toEntity } = props;
  const encodedPermissionsId = encodeIds(['alertUI'], toEntityId);

  useAsyncEffect(async () => {
    const { toEntityId } = props;
    if (toEntityId) {
      setToEntityId(toEntityId);
      const [retrieveAlertConfigurationsResponse, retrieveAlertRulesResponse] =
        await Promise.all([
          retrieveAlertConfigurations(toEntityId),
          retrieveAlertRules(toEntityId),
        ]);

      const alertConfigurationsResponse: IAlertConfigurationsResponse =
        retrieveAlertConfigurationsResponse.data;

      const alertRulesResponse: IAlertRulesResponse =
        retrieveAlertRulesResponse.data;

      if (!isSuccessStatus(retrieveAlertRulesResponse.status)) {
        throw new Error(alertRulesResponse.errorMessage!);
      }

      const newAlertRulesMap: TAlertRulesMap = {};
      alertRulesResponse.response.forEach((alertRule: IAlertRule) => {
        newAlertRulesMap[alertRule.alert_rule_id] = alertRule;
      });

      const newAlertConfigurations: IAlertConfiguration[] =
        alertConfigurationsResponse.response.sort(
          alertConfigurationSorterFor(newAlertRulesMap),
        );

      setAlertConfigurations(newAlertConfigurations);
      setAlertRulesMap(newAlertRulesMap);
    }
  }, [props]);
  const [liveAlerts, setLiveAlerts] = useState<IConfiguredAlert[]>([]);

  return (
    <AlertHistoryUI
      alertConfigurations={alertConfigurations}
      alertRulesMap={alertRulesMap}
      encodedPermissionsId={encodedPermissionsId}
      liveAlerts={liveAlerts}
      setLiveAlerts={setLiveAlerts}
      timeZone={
        props?.toEntityUserState?.selectedTimeZone ??
        ZonedDateTime.defaultTimeZone()
      }
      toEntity={toEntity}
      toEntityId={toEntityId}
    />
  );
};

export default ToEntityAlertHistoryUI;
