export const TAG_COUNT_LABEL = 'Tag Count';

export const EMPTY_CELLS_SELECTION = {};

export const NULL_CELL_VALUE = ' ';

export const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 11497;

export const SUMMARY_TOTALS_ROW_LIGHT_COLOR = '#E6F7FF';
export const SUMMARY_TOTALS_ROW_DARK_COLOR = '#1E3349';
