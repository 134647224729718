export enum EPointType {
  POD = 'POD',
  POR = 'POR',
  PORPOD = 'PORPOD',
  SourceSink = 'SourceSink',
}

export enum EPointTypeName {
  POD = 'por/pod,pod',
  POR = 'por/pod,por',
  Sink = 'Sink,Source/Sink',
  Source = 'Source,Source/Sink',
}
