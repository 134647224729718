export const SUMMARY_ROUTE = '/summary'; // done
export const DETAIL_ROUTE = '/detail';
export const DETAIL_PRINT_ROUTE = '/detail-print';
export const SUMMARY_OPTIONS_ROUTE = '/summary-options'; // done
export const ALERT_RULES_ROUTE = '/alert-rules'; // done
export const TEMPLATES_ROUTE = '/templates'; // done
export const APPROVAL_ROUTE = '/approval'; // done
export const APPROVAL_CONFIGS_ROUTE = '/approval-configs';
export const AUDIT_ROUTE = '/audit'; // done
export const TRANSMISSION_CAPACITY_AVAILABILITY_ROUTE =
  '/transmission-availability';
export const ALERT_ROUTE = '/alert';
export const HELP_ROUTE = '/help';
