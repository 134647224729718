import { useState } from 'react';

export interface IuseArrayFilters<T> {
  arrayFilters: Record<string, T>;
  clearArrayFilters: () => void;
  setFilter: (key: string, filter: T) => void;
  unSetFilter: (key: string) => void;
}

const useArrayFilters = <T>(): IuseArrayFilters<T> => {
  const [arrayFilters, setArrayFilters] = useState<Record<string, T>>({});

  const clearArrayFilters = () => {
    setArrayFilters(() => ({}));
  };

  const setFilter = (key: string, filter: T) => {
    setArrayFilters(
      (previousArrayFilters: Record<string, T>): Record<string, T> => ({
        ...previousArrayFilters,
        [key]: filter,
      }),
    );
  };

  const unSetFilter = (key: string) => {
    setArrayFilters(
      (previousArrayFilters: Record<string, T>): Record<string, T> => {
        const newArrayFilters: Record<string, T> = {
          ...previousArrayFilters,
        };
        delete newArrayFilters[key];
        return newArrayFilters;
      },
    );
  };

  return {
    arrayFilters,
    clearArrayFilters,
    setFilter,
    unSetFilter,
  };
};

export default useArrayFilters;
