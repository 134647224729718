import {
  ColDef,
  FilterChangedEvent,
  GetRowIdParams,
  ProcessCellForExportParams,
  RowClassParams,
  RowClickedEvent,
  RowNode,
  SideBarDef,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { TAgGridSortState } from 'components/organisms/ToEntityMonitor/types';
import { ETheme } from 'enums/Style';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

export type TDataTableKey = number | string;

const StyledGrid = styled(AgGridReact)`
  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  .ag-ltr .ag-floating-filter-button {
    margin-left: 4px;
  }

  .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ag-cell {
    line-height: 17px;
    font-size: 12px;
  }

  .ag-row-selected {
    filter: invert(100%);
  }

  font-family: 'Roboto', sans-serif;
`;

export interface IRowSelection<D> {
  onChange?: (selectedRowKeys: TDataTableKey[], selectedRows: D[]) => void;
  selectedRowKeys: TDataTableKey[];
}

export interface IScrollConfig {
  x?: string | number | true;
  y?: string | number;
}

export interface IBottomRowData {
  id: string;
  data: any;
}

export interface IFloatingBottomData {
  id: string;
  rowIndex: number;
}

export interface IAGDataTableProps<D> {
  className?: string;
  clearFilters: boolean;
  columns: ColDef[];
  data: D[];
  defaultSort?: TAgGridSortState[];
  getRowClass?: (params: RowClassParams) => string | string[] | undefined;
  getRowId: (params: GetRowIdParams) => string;
  isLoading?: boolean;
  maxHeight: string;
  onRowClick?: (event: RowClickedEvent) => undefined;
  renderTrigger?: boolean;
  setClearFilters: (set: boolean) => void;
  setRowIds?: (ids: string[]) => void;
  sideBarDef?: SideBarDef;
  timeZone: TTimeZone | undefined;
  pinnedBottomData?: IBottomRowData[];
  showBottomRow?: boolean;
  gridOptions?: any;
}

const AGDataTable = <D extends object>(
  props: IAGDataTableProps<D>,
): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const {
    className,
    clearFilters,
    columns,
    data,
    defaultSort,
    getRowClass,
    isLoading,
    maxHeight,
    onRowClick,
    getRowId,
    renderTrigger,
    setClearFilters,
    setRowIds,
    sideBarDef,
    timeZone,
    pinnedBottomData,
    showBottomRow,
    gridOptions,
  } = props;
  const { currentTheme } = useThemeSwitcher();
  const [tableData, setTableData] = useState<D[]>([]);
  const [bottomTableRowData, setBottomRowTableData] = useState<
    IBottomRowData[]
  >([]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        if (gridRef.current) {
          // always reset filters before applying new filters on view change
          gridRef.current.columnApi.applyColumnState({
            defaultState: { sort: null },
          });
          gridRef.current.columnApi.applyColumnState({ state: defaultSort });
        }
      }, 0);
    }
  }, [defaultSort, gridRef.current?.columnApi]);

  /**
   * set filters rely on grid values for a column
   * don't set data to empty on refreshes
   * so set filters retain any options they can
   */
  useEffect(() => {
    if (!isLoading) {
      setTableData(data);
      setBottomRowTableData(pinnedBottomData ?? []);
    }
  }, [data, isLoading, pinnedBottomData, renderTrigger]);

  /**
   * Prevent the following:
   * Uncaught Error: AG Grid: cannot get grid to draw rows when it is in the middle of drawing rows.
   * Your code probably called a grid API method while the grid was in the render stage.
   * To overcome this, put the API call into a timeout,
   * e.g. instead of api.redrawRows(), call setTimeout(function() { api.redrawRows(); }, 0).
   */
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.api.setRowData(tableData);
          if (showBottomRow) {
            gridRef.current.api.setPinnedBottomRowData(bottomTableRowData);
          }
        }
      }, 0);
    }
  }, [bottomTableRowData, showBottomRow, tableData]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.api.redrawRows();
        }
      });
    }
  }, [renderTrigger]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      if (isLoading) {
        setTimeout(() => {
          if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
          }
        }, 0);
      } else {
        setTimeout(() => {
          if (gridRef.current) {
            gridRef.current.api.hideOverlay();
          }
        }, 0);
      }
    }
  }, [isLoading]);

  const processCellForClipboard = useCallback(
    (params: ProcessCellForExportParams): any => {
      if (
        params.column.getColDef().chartDataType === 'time' &&
        timeZone &&
        params.value // don't parse empty strings
      ) {
        return ZonedDateTime.fromDate(
          new Date(params.value),
          timeZone,
        ).toIsoString();
      }
      if (params.node?.rowPinned) {
        return params.node.data.data[params.column.getColId()];
      }
      return params.value;
    },
    [timeZone],
  );

  const handleRowDataChanged = () => {
    setTimeout(() => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.refreshCells();
      }
    }, 0);
  };

  useEffect(() => {
    if (clearFilters && gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.api.setFilterModel([]);
        }
      }, 0);
      setClearFilters(false);
    }
  }, [clearFilters, setClearFilters]);

  const handleModelUpdated = () => {
    if (gridRef.current && gridRef.current.api && setRowIds) {
      const idList: string[] = [];
      gridRef.current.api.forEachNodeAfterFilterAndSort((node: RowNode) => {
        if (node.id) {
          idList.push(node.id);
        }
      });
      setRowIds(idList);
    }
  };

  const handleFilterChanged = (e: FilterChangedEvent) => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      // Added the force flag so the non-aggregate totals cells get updated
      gridRef.current.api.refreshCells({ force: true });
    }
  };

  return (
    <div
      className={
        currentTheme === ETheme.Light
          ? 'ag-theme-alpine'
          : 'ag-theme-alpine-dark'
      }
      style={{ height: maxHeight, width: '100vw - 8px' }}
    >
      <StyledGrid
        className={className}
        columnDefs={columns}
        gridOptions={gridOptions}
        enableBrowserTooltips={true}
        enableRangeSelection={true}
        ensureDomOrder={true}
        getRowClass={getRowClass}
        getRowId={getRowId}
        multiSortKey={'ctrl'}
        onFilterChanged={handleFilterChanged}
        onModelUpdated={handleModelUpdated}
        onRowClicked={onRowClick}
        onRowDataChanged={handleRowDataChanged}
        onRowDataUpdated={undefined}
        overlayLoadingTemplate={
          '<span class="ag-overlay-loading-center">Loading data...</span>'
        }
        processCellForClipboard={processCellForClipboard}
        ref={gridRef}
        rowHeight={20}
        rowBuffer={20}
        rowMultiSelectWithClick={true}
        rowSelection={'single'}
        sideBar={sideBarDef}
        suppressAggFuncInHeader={true}
        suppressContextMenu={true}
        suppressCopyRowsToClipboard={true}
        // https://www.ag-grid.com/react-data-grid/value-getters/#example-value-cache
        valueCache={true}
      />
    </div>
  );
};

export default AGDataTable;
