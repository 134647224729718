import { Radio, RadioChangeEvent, Space } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import CustomTimeFrequencySelector from 'components/molecules/ToEntityValidationReportCreate/CustomTimeFrequencySelector';
import { useCallback, useEffect, useState } from 'react';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface IProps {
  defaultFrequency?: {
    day_of_month?: number;
    day_of_week?: string;
    hours_rate?: number;
    minutes_rate?: number;
  };
  isDisabled: boolean;
  onFrequencyChange: (
    frequency:
      | {
          minutes_rate?: number;
          hours_rate?: number;
          day_of_week?: string;
          day_of_month?: number;
        }
      | undefined,
  ) => void;
  startDate: ZonedDateTime | null;
}

const FrequencyOfDataSelect = ({
  defaultFrequency,
  isDisabled,
  onFrequencyChange,
  startDate,
}: IProps): JSX.Element => {
  const [selectedRadioValue, setSelectedRadioValue] = useState<
    'hour' | 'day' | 'week' | 'month' | 'custom'
  >();
  const [defaultInputValue, setDefaultInputValue] = useState<
    number | undefined
  >();
  const [defaultUnit, setDefaultUnit] = useState<
    'hour' | 'minute' | undefined
  >();

  const handleDataRangeChange = useCallback(
    (unit: 'hour' | 'minute' | undefined, value: number | undefined) => {
      if (
        selectedRadioValue === 'custom' &&
        (value === undefined || unit === undefined)
      ) {
        onFrequencyChange(undefined);
        return;
      }
      switch (unit) {
        case 'minute':
          onFrequencyChange({ minutes_rate: value });
          break;
        case 'hour':
          onFrequencyChange({ hours_rate: value });
          break;
      }
    },
    [onFrequencyChange, selectedRadioValue],
  );

  // this component can be passed a default frequency when in review mode
  // We need to parse that frequency to set the starting state of this component
  useEffect(() => {
    if (!defaultFrequency) {
      setSelectedRadioValue(undefined);
      setDefaultUnit(undefined);
      setDefaultInputValue(undefined);
      return;
    }
    setSelectedRadioValue('day'); // day is default
    // if minutes rate was defined, the custom input was used
    if (defaultFrequency.minutes_rate) {
      setSelectedRadioValue('custom');
      setDefaultUnit('minute');
      setDefaultInputValue(defaultFrequency.minutes_rate);
    }
    // if hours rate was defined and the value is something
    // other than one, the custom input was used
    if (defaultFrequency.hours_rate) {
      if (defaultFrequency.hours_rate !== 1) {
        setSelectedRadioValue('custom');
        setDefaultUnit('hour');
        setDefaultInputValue(defaultFrequency.hours_rate);
      } else {
        setSelectedRadioValue('hour');
      }
    }
    if (defaultFrequency.day_of_week) {
      setSelectedRadioValue('week');
    }
    if (defaultFrequency.day_of_month) {
      setSelectedRadioValue('month');
    }
  }, [defaultFrequency]);

  // periodicity in the backend can have different properties for frequency
  // here we parse the selection and determine which of those needs to be set
  useEffect(() => {
    if (selectedRadioValue !== 'custom') {
      switch (selectedRadioValue) {
        case 'hour':
          onFrequencyChange({ hours_rate: 1 });
          break;
        case 'day':
          onFrequencyChange({}); // daily is the default, so we pass nothing
          break;
        case 'week':
          onFrequencyChange({
            day_of_week: startDate?.getDayOfWeek().toLocaleLowerCase(),
          });
          break;
        case 'month':
          onFrequencyChange({ day_of_month: startDate?.getDayOfMonth() });
          break;
      }
    }
  }, [onFrequencyChange, selectedRadioValue, startDate]);

  const handleDataFrequencyRadioChange = (event: RadioChangeEvent) => {
    setSelectedRadioValue(event.target.value);
  };

  return (
    <Radio.Group
      disabled={isDisabled}
      onChange={handleDataFrequencyRadioChange}
      value={selectedRadioValue}
    >
      <Space direction='vertical' size={'small'}>
        <Radio value={'hour'}>Hourly</Radio>
        <Radio value={'day'}>Daily</Radio>
        <Radio value={'week'}>Weekly</Radio>
        <Radio value={'month'}>Monthly</Radio>
        <SeparatedRowLayout marginRight={0}>
          <Radio value={'custom'}>Custom: ±</Radio>
          {
            <CustomTimeFrequencySelector
              defaultInputValue={defaultInputValue}
              defaultUnit={defaultUnit}
              isDisabled={selectedRadioValue !== 'custom' || isDisabled}
              onChange={handleDataRangeChange}
            />
          }
        </SeparatedRowLayout>
      </Space>
    </Radio.Group>
  );
};

export default FrequencyOfDataSelect;
