import ContactInfoEditable from 'components/molecules/ContactInfoEditable/ContactInfoEditable';
import ValidationSubmissionPanel from 'components/molecules/ValidationSubmissionPanel/ValidationSubmissionPanel';
import useContactInfoEditable from 'hooks/useContactInfoEditable';
import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  requestWithdrawTag,
  validateRequestWithdrawTag,
} from 'services/agent/tags/requests';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue } from 'utils/general';

const WITHDRAW_REQUEST_CONTACT_INFO_EDIT_KEY =
  'withdrawRequestContactInfoEditKey';

interface IToEntityETagWithdrawRequestPanelProps {
  defaultContactInfo?: IContactInfo;
  isContactInfoDisabled?: boolean;
  request_id: number;
  securityKey: string;
  shouldContactInfoUpdate?: boolean;
  tagId: IETagTagId;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagWithdrawRequestPanel = (
  props: IToEntityETagWithdrawRequestPanelProps,
): JSX.Element => {
  const {
    defaultContactInfo,
    isContactInfoDisabled,
    request_id,
    securityKey,
    shouldContactInfoUpdate,
    tagId,
    timeZone,
    toEntityId,
  } = props;

  const { contactInfoEditableProps, contactInfo, isFaxValid, isPhoneValid } =
    useContactInfoEditable(
      WITHDRAW_REQUEST_CONTACT_INFO_EDIT_KEY,
      isContactInfoDisabled,
      shouldContactInfoUpdate,
      defaultContactInfo,
    );

  const requestBody = () => ({
    request_id,
    tag_id: {
      ...tagId,
      security_key: securityKey,
    },
    contact_info: contactInfo,
  });

  const validateRequest = async () =>
    await validateRequestWithdrawTag(toEntityId, timeZone, requestBody());

  const validateAndSubmitRequest = async (skipValidation: boolean) =>
    await requestWithdrawTag(
      toEntityId,
      timeZone,
      skipValidation,
      requestBody(),
    );

  const isDisabled: boolean =
    isEmptyValue(contactInfo.contact) || !isFaxValid || !isPhoneValid;

  return (
    <ValidationSubmissionPanel
      isValidateAndSubmitDisabled={isDisabled}
      isValidateDisabled={isDisabled}
      isValidateHidden={false}
      requestDisplayName='Withdraw'
      validateRequest={validateRequest}
      validateAndSubmitRequest={validateAndSubmitRequest}
    >
      <ContactInfoEditable {...contactInfoEditableProps} />
    </ValidationSubmissionPanel>
  );
};

export default ToEntityETagWithdrawRequestPanel;
