import FloatOver from 'components/molecules/FloatOver/FloatOver';
import FloatOverProvider, {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { isEmptyValue } from 'utils/general';

const FloatOverContainer = (): JSX.Element => {
  const {
    floatOverContent,
    floatOverDefaultPosition,
    floatOverId,
    floatOverMaximumHeight,
    floatOverUseDragPanel,
    setFloatOverContent,
    setFloatOverId,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isEmptyValue(floatOverContent)) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [floatOverContent]);

  const handleClose = () => {
    setVisible(false);
    setFloatOverContent(null);
    setFloatOverUseDragPanel(undefined);
    setFloatOverId('');
  };

  return (
    <FloatOver
      defaultPosition={floatOverDefaultPosition}
      id={floatOverId}
      maximumHeight={floatOverMaximumHeight}
      onClose={handleClose}
      useDragPanel={floatOverUseDragPanel}
      visible={visible}
    >
      {floatOverContent}
    </FloatOver>
  );
};

const withFloatOver =
  <T extends object>(Component: ComponentType<T>) =>
  (props: T) => {
    return (
      <FloatOverProvider>
        <FloatOverContainer />
        <Component {...(props as T)} />
      </FloatOverProvider>
    );
  };

export default withFloatOver;
