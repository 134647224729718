import Page from 'components/organisms/Page/Page';
import SummaryPageContent from 'components/pages/SummaryPage/SummaryPageContent';
import { EXTERNAL_LINKS_MAP } from 'constants/General';
import { EExternalLinks } from 'enums/General';
import { IRouteProps } from 'interfaces/Component';
import { useEffect } from 'react';

const SummaryPage = ({
  appActions,
  setUserGuideExternalLink,
}: IRouteProps): JSX.Element => {
  useEffect(() => {
    setUserGuideExternalLink(EXTERNAL_LINKS_MAP[EExternalLinks.SummaryGuide]);
  }, [setUserGuideExternalLink]);

  return (
    <Page
      appActions={appActions}
      PageContent={SummaryPageContent}
      pageTitle='Summary - PCI e-Tag+'
    />
  );
};

export default SummaryPage;
