import { InputNumber } from 'antd';
import Select from 'components/molecules/Select/Select';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
  width: 40px;
`;

interface IProps {
  defaultUnit?: 'hour' | 'day' | 'week' | 'month';
  defaultValue?: number;
  onChange: (
    unit: 'hour' | 'day' | 'week' | 'month' | undefined,
    value: number | undefined,
  ) => void;
  isDisabled: boolean;
}

const UnitSelectWrapper = styled.div`
  width: 80px;
`;

const CustomTimeRangeSelector = ({
  defaultUnit,
  defaultValue,
  onChange,
  isDisabled,
}: IProps): JSX.Element => {
  const [unit, setUnit] = useState<
    'hour' | 'day' | 'week' | 'month' | undefined
  >();
  const [value, setValue] = useState<number | undefined>();

  // defaultUnit can be passed in from a previously created report
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  // defaultUnit can be passed in from a previously created report
  // if that exists, use that value, otherwise default to 'hour'
  useEffect(() => {
    if (defaultUnit) {
      setUnit(defaultUnit);
    } else {
      setUnit('hour');
    }
  }, [defaultUnit]);

  useEffect(() => {
    if (!value || !unit) {
      // only inputs with both unit and value are valid
      onChange(undefined, undefined);
      return;
    }
    onChange(unit, value);
  }, [value, unit, onChange]);

  const handleUnitChange = (value: string | undefined) => {
    if (
      value === undefined ||
      value === 'hour' ||
      value === 'day' ||
      value === 'week' ||
      value === 'month'
    ) {
      setUnit(value);
    }
  };

  const handleValueChange = (value: number | string | undefined) => {
    if (typeof value === 'string') {
      return;
    }
    setValue(value);
  };

  return (
    <>
      <div>±</div>
      <StyledInputNumber
        disabled={isDisabled}
        min={1}
        max={unit === 'month' ? 12 : undefined}
        onChange={handleValueChange}
        value={value}
      />
      <UnitSelectWrapper>
        <Select
          isDisabled={isDisabled}
          onChange={handleUnitChange}
          options={[
            { value: 'hour', label: 'Hour(s)' },
            { value: 'day', label: 'Day(s)' },
            { value: 'week', label: 'Week(s)' },
            { value: 'month', label: 'Month(s)' },
          ]}
          placeholder='Unit'
          value={unit}
          valueToUid={(value: string) => value}
        />
      </UnitSelectWrapper>
    </>
  );
};

export default CustomTimeRangeSelector;
