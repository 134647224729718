import styled from 'styled-components';
import {
  BUTTON_ICON_DIMENSIONS,
  COLUMN_LAYOUT_SHARED_STYLES,
} from '../../../constants/styles';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import Tooltip from '../Tooltip/Tooltip';
import { EAlertAcknowledged } from '../../../enums/Alert';
import {
  EActionState,
  ERetreiveState,
  ESeverity,
} from '../../../enums/General';
import { TAlertId, TAlertRuleId, TAlertRulesMap } from '../../../types/Alert';
import { Index } from 'react-virtualized';
import {
  IAlert,
  IAlertConfiguration,
  IAlertRule,
  IAlertsAcknowledgeResponse,
  IConfiguredAlert,
} from '../../../interfaces/Alert';
import { IDateRange } from 'interfaces/Summary';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import Button from '../../atoms/Button/Button';
import InfiniteSelectableList, {
  IInfiniteSelectableListProps,
} from '../InfiniteSelectableList/InfiniteSelectableList';
import Select, { ISelectProps } from '../Select/Select';
import { TZonedDateTimeRange } from '../../../types/DateTime';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ZonedDateTime } from '../../../utils/zonedDateTime';
import { IOption } from '../../../interfaces/Component';
import { TErrorMessage } from '../../../types/Error';
import Alert from '../ToEntityAlertHistory/Alert';
import { loadAlerts } from '../ToEntityAlertHistory/helpers';
import { TNextToken } from '../../../types/Summary';
import { AxiosResponse } from 'axios';
import { acknowledgeAlerts } from '../../../services/alert/alerts';
import { isSuccessStatus } from '../../../utils/general';
import { captureError } from '../../../utils/error';
import {
  IwithAlertHeightMapProps,
  withAlertHeightMap,
} from '../ToEntityAlertHistory/withAlertHeightMap';
import IconButton from '../../atoms/IconButton/IconButton';
import { SyncOutlined } from '@ant-design/icons';
import {
  AUTO_REFRESH_OPTIONS,
  DEFAULT_AUTO_REFRESH,
} from '../../../constants/Detail';
import { numberToString } from '../../organisms/ETagManagerConfigurator/helpers';
import ToEntityDateTimePicker from '../ToEntityDateTimePicker/ToEntityDateTimePicker';
import useAsyncEffect from 'use-async-effect';
import { TTimeZone } from 'types/DateTime';
import { EDefaultDateRange } from 'enums/Summary';
import { defaultDateRangeToDateRange } from 'utils/summary';
import { useInterval } from 'usehooks-ts';
import { acknowledgeStatusOptions, alertSeverityOptions } from './constants';
import { TToEntityId } from '../../../types/ToEntity';
import { IToEntity } from '../../../interfaces/ToEntity';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';
import { useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 100%;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const DefaultAutoRefreshInSecondsSelect = styled(
  (props: ISelectProps<number>) => Select<number>(props),
)`
  width: 97px;
`;

// Specialise Select components
const AcknowledgeStatusSelect = styled(
  (props: ISelectProps<EAlertAcknowledged>) =>
    Select<EAlertAcknowledged>(props),
)`
  width: 83px;
`;

const AlertHistoryList = (props: IInfiniteSelectableListProps<IAlert>) =>
  InfiniteSelectableList<IAlert>(props);

const AlertSeveritySelect = styled((props: ISelectProps<ESeverity>) =>
  Select<ESeverity>(props),
)`
  width: 193px;
`;

const AlertRulesSelect = styled((props: ISelectProps<TAlertRuleId>) =>
  Select<TAlertRuleId>(props),
)`
  width: 200px;
`;

const EntityLabel = styled.span`
  font-size: 15px;
  padding-top: 6px;
`;

interface IProps extends IwithAlertHeightMapProps {
  alertConfigurations?: IAlertConfiguration[];
  alertRulesMap: TAlertRulesMap;
  encodedPermissionsId: string;
  liveAlerts: IConfiguredAlert[];
  setLiveAlerts: (configuredAlerts: IConfiguredAlert[]) => void;
  timeZone: TTimeZone;
  toEntity: IToEntity | undefined;
  toEntityId: TToEntityId;
}

const AlertHistoryUI = (props: IProps): JSX.Element => {
  const {
    alertConfigurations,
    alertRulesMap,
    getAlertHeightMap,
    encodedPermissionsId,
    recalculateAlertHeightMap,
    resetAlertHeightMap,
    setAlertsForHeightMap,
    timeZone,
    toEntity,
    toEntityId,
  } = props;

  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertRulesOptions, setAlertRulesOptions] = useState<
    IOption<TAlertRuleId>[]
  >([]);
  const [selectedDateTimes, setSelectedDateTimes] =
    useState<TZonedDateTimeRange>([
      ZonedDateTime.now(timeZone).subtract(2, 'hours'),
      ZonedDateTime.now(timeZone).add(1, 'hours'),
    ]);

  const [selectedAcknowledgeStatus, setSelectedAcknowledgeStatus] =
    useState<EAlertAcknowledged>(EAlertAcknowledged.Unread);
  const [selectedAlertSeverities, setSelectedAlertSeverities] = useState<
    ESeverity[]
  >([]);
  const [selectedAlertRules, setSelectedAlertRules] = useState<TAlertRuleId[]>(
    [],
  );
  const [selectedAlerts, setSelectedAlerts] = useState<TAlertId[]>([]);
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [alertConfigurationsMap, setAlertConfigurationsMap] = useState<
    Record<TAlertRuleId, IAlertConfiguration>
  >({});
  const [acknowledgeActionState, setAcknowledgeActionState] =
    useState<EActionState>(EActionState.NoAction);
  const [alertsErrorMap, setAlertsErrorMap] = useState<
    Record<TAlertId, TErrorMessage>
  >({});
  const [defaultAutoRefreshInSeconds, setDefaultAutoRefreshInSeconds] =
    useState<number>(DEFAULT_AUTO_REFRESH);
  const [selectedStartDate, setSelectedStartDate] =
    useState<ZonedDateTime | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<ZonedDateTime | null>(
    null,
  );

  const nextTokenRef = useRef<TNextToken | undefined>();
  const loadedRowsRef = useRef<true[]>([]);
  const [defaultDateRange, setDefaultDateRange] = useState<
    EDefaultDateRange | undefined
  >(EDefaultDateRange.Today);

  const setLoadedRows = (loadedRows: true[]) => {
    loadedRowsRef.current = loadedRows;
  };

  const setNextToken = (next_token: TNextToken) => {
    nextTokenRef.current = next_token;
  };

  const applyDefaultDateRange = useCallback(
    (defaultDateRange: EDefaultDateRange, timeZone: TTimeZone) => {
      const dateRange: IDateRange = defaultDateRangeToDateRange(
        defaultDateRange,
        timeZone,
      );

      setSelectedStartDate(dateRange.start);
      setSelectedEndDate(dateRange.end);

      setDefaultDateRange(undefined);
    },
    [],
  );

  useEffect(() => {
    if (defaultDateRange !== undefined) {
      applyDefaultDateRange(defaultDateRange, timeZone);
    }
  }, [applyDefaultDateRange, defaultDateRange, timeZone]);

  useEffect(() => {
    const tzRange: TZonedDateTimeRange = [selectedStartDate, selectedEndDate];
    setSelectedDateTimes(tzRange);
  }, [selectedStartDate, selectedEndDate]);

  const reloadAlerts = async () => {
    nextTokenRef.current = undefined;
    loadedRowsRef.current = [];
    resetAlertHeightMap();
    setAlerts([]);

    // It's important to note that the useState hook becomes asynchronous when
    // used within an asynchronous function. This means that render calls will
    // occur between local state updates!
    await loadAlerts(
      selectedAcknowledgeStatus,
      alertConfigurationsMap,
      selectedAlertRules,
      selectedAlertSeverities,
      [],
      alertsErrorMap,
      selectedDateTimes,
      undefined,
      setAlerts,
      setAlertsForHeightMap,
      setErrorMessage,
      setIsLoading,
      setLoadedRows,
      setNextToken,
      toEntityId,
    );
  };

  useAsyncEffect(async () => {
    await reloadAlerts();
  }, [
    selectedDateTimes,
    selectedAcknowledgeStatus,
    selectedAlertSeverities,
    selectedAlertRules,
  ]);

  useEffect(() => {
    if (
      Object.keys(alertRulesMap).length > 0 &&
      alertConfigurations !== undefined &&
      alertConfigurations.length > 0
    ) {
      const updatedAlertRulesOptions: IOption<TAlertRuleId>[] = [];
      const updatedAlertConfigurationsMap: Record<
        TAlertRuleId,
        IAlertConfiguration
      > = {};

      alertConfigurations.forEach((alertConfiguration: IAlertConfiguration) => {
        const { alert_rule_id } = alertConfiguration;
        const alertRule: IAlertRule | undefined = alertRulesMap[alert_rule_id];

        if (alertRule === undefined) {
          captureError(
            new Error(
              `Missing alert rule with alert_rule_id: ${alert_rule_id}`,
            ),
          );
        } else {
          updatedAlertRulesOptions.push({
            label: alertRule.name,
            value: alert_rule_id,
          });

          updatedAlertConfigurationsMap[alert_rule_id] = alertConfiguration;
        }
      });

      setAlertRulesOptions(updatedAlertRulesOptions);

      setAlertConfigurationsMap(updatedAlertConfigurationsMap);
    }
  }, [alertRulesMap, alertConfigurations]);

  const handleAcknowledgeStatusSelect = (
    value: EAlertAcknowledged | undefined,
  ) => {
    if (value === undefined) {
      throw new Error('Invalid acknowledge status value.');
    }

    setSelectedAcknowledgeStatus(value!);
  };

  const handleAlertSeveritySelect = (values: ESeverity[]) => {
    setSelectedAlertSeverities(values);
  };

  const handleAlertRulesSelect = (values: TAlertRuleId[]) => {
    setSelectedAlertRules(values);
  };

  const handleAcknowledge = async () => {
    if (selectedAlerts.length > 0) {
      try {
        setErrorMessage(null);
        setAlertsErrorMap({});
        setAcknowledgeActionState(EActionState.Actioning);

        const response: AxiosResponse<IAlertsAcknowledgeResponse> =
          await acknowledgeAlerts(toEntityId, selectedAlerts);
        const alertsAcknowledgeResponse: IAlertsAcknowledgeResponse =
          response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(alertsAcknowledgeResponse.errorMessage!);
        }

        const { response: alertsAcknowledge } = alertsAcknowledgeResponse;

        if (alertsAcknowledge.ack_count > 0) {
          await reloadAlerts();
        }

        if (alertsAcknowledge.failed_ack_alert_ids.length > 0) {
          const updatedAlertsErrorMap: Record<TAlertId, TErrorMessage> = {};

          alertsAcknowledge.failed_ack_alert_ids.forEach(
            (alert_id: TAlertId) => {
              updatedAlertsErrorMap[alert_id] =
                'An error occurred trying to mark as read. Please try again later.';
            },
          );

          setAlertsErrorMap(updatedAlertsErrorMap);

          recalculateAlertHeightMap(alerts, updatedAlertsErrorMap);
        }

        setAcknowledgeActionState(EActionState.Succeeded);
      } catch (error: any) {
        captureError(error);

        setErrorMessage(
          'An error occurred marking alerts. Please try again later.',
        );

        setAcknowledgeActionState(EActionState.Failed);
      }
    }
  };

  const alertRenderer = (alert: IAlert): JSX.Element => (
    <Alert
      alert={alert}
      errorMessage={alertsErrorMap[alert.alert_id]}
      timeZone={timeZone}
    />
  );

  const handleLoadData = async () => {
    const loadAlertsRequest = async () => {
      if (nextTokenRef.current !== null) {
        await loadAlerts(
          selectedAcknowledgeStatus,
          alertConfigurationsMap,
          selectedAlertRules,
          selectedAlertSeverities,
          alerts,
          alertsErrorMap,
          selectedDateTimes,
          nextTokenRef.current,
          setAlerts,
          setAlertsForHeightMap,
          setErrorMessage,
          setIsLoading,
          setLoadedRows,
          setNextToken,
          toEntityId,
        );
      }
    };

    if (!isLoading) {
      await loadAlertsRequest();
    }
  };

  useInterval(() => {
    reloadAlerts();
  }, defaultAutoRefreshInSeconds * 1000);

  const handleDefaultAutoRefreshInSecondsSelectChange = useCallback(
    (defaultAutoRefreshInSeconds: number | undefined) => {
      // We are not allowing clear on the Select component, so value can safely
      // be asserted as never being undefined
      setDefaultAutoRefreshInSeconds(defaultAutoRefreshInSeconds!);
    },
    [],
  );

  const [hideTenantTitle, setHideTenantTitle] = useState<boolean>(true);

  const configState = useSelector((state: TRootState) => state.config);
  useEffect(() => {
    if (configState.retrievingConfig === ERetreiveState.RetrievingCompleted) {
      const hideTenantTitleConfig =
        configState.hideTenantTitleConfig &&
        toEntity &&
        configState.hideTenantTitleConfig.get(toEntity.to_entity);
      setHideTenantTitle(hideTenantTitleConfig as boolean);
    }
  }, [configState, toEntity, setHideTenantTitle]);

  return (
    <Layout>
      <SeparatedRowLayout>
        <TopBarMenu
          encodedPermissionsId={encodedPermissionsId}
          toEntity={toEntity}
          timeZone={timeZone}
        />
        {hideTenantTitle ? null : (
          <EntityLabel>{toEntity?.entity_code}</EntityLabel>
        )}
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <Tooltip title='Filter Alerts by Date Range'>
          <ToEntityDateTimePicker
            end={selectedEndDate}
            includeRanges={true}
            setEnd={setSelectedEndDate}
            setStart={setSelectedStartDate}
            start={selectedStartDate}
            timeZone={timeZone}
          />
        </Tooltip>
        <Tooltip title='Filter Alerts by Read Status'>
          <AcknowledgeStatusSelect
            onChange={handleAcknowledgeStatusSelect}
            options={acknowledgeStatusOptions}
            placeholder='Read status'
            value={selectedAcknowledgeStatus}
            valueToUid={(value: EAlertAcknowledged): string => value as string}
          />
        </Tooltip>
        <Tooltip title='Filter Alerts by Severity'>
          <AlertSeveritySelect
            allowClear={true}
            allowMultiple={true}
            onChangeMultiple={handleAlertSeveritySelect}
            options={alertSeverityOptions}
            placeholder='Alert Severity'
            values={selectedAlertSeverities}
            valueToUid={(value: ESeverity): string => value as string}
          />
        </Tooltip>
        <Tooltip title='Filter Alerts by Alert Rules'>
          <AlertRulesSelect
            allowClear={true}
            allowMultiple={true}
            onChangeMultiple={handleAlertRulesSelect}
            options={alertRulesOptions}
            placeholder='Alert Rules'
            values={selectedAlertRules}
            valueToUid={(value: TAlertRuleId): string => value}
          />
        </Tooltip>
        <Tooltip title='Refresh view'>
          <IconButton icon={<RefreshIcon />} onClick={reloadAlerts} />
        </Tooltip>
        <DefaultAutoRefreshInSecondsSelect
          onChange={handleDefaultAutoRefreshInSecondsSelectChange}
          options={AUTO_REFRESH_OPTIONS}
          value={defaultAutoRefreshInSeconds}
          valueToUid={numberToString}
        />
      </SeparatedRowLayout>
      <AlertHistoryList
        data={alerts}
        isLoading={isLoading}
        isRowLoaded={(rowIndex: Index): boolean =>
          !!loadedRowsRef.current[rowIndex.index]
        }
        itemRenderer={alertRenderer}
        loadData={handleLoadData}
        minimumBatchSize={1}
        onSelectChange={setSelectedAlerts}
        overscanRowCount={1}
        rowDisabled={(alert: IAlert): boolean => alert.acknowledged === true}
        rowHeight={(params: Index): number =>
          getAlertHeightMap()[alerts[params.index].alert_id]
        }
        rowKey={(alert: IAlert): string => alert.alert_id}
        selectedKeys={selectedAlerts}
        threshold={1}
      />
      <ErrorMessage maxWidth='100%'>{errorMessage}</ErrorMessage>
      <Actions>
        <Button
          actionState={acknowledgeActionState}
          isDisabled={selectedAlerts.length === 0}
          label='Mark selected as read'
          onClick={handleAcknowledge}
        />
      </Actions>
    </Layout>
  );
};

export default withAlertHeightMap(AlertHistoryUI);
