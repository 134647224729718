import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import CompositeStateIcon from 'components/molecules/CompositeStateIcon/CompositeStateIcon';
import { ICON_FONT_SIZE, STATE_GREEN, STATE_GREY } from 'constants/styles';
import { ECompositeState } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import styled from 'styled-components';
import { compositeStateToColour } from 'utils/eTag';
import { isEmptyValue } from 'utils/general';

const ActiveIcon = styled(CheckCircleOutlined)`
  color: ${STATE_GREEN};
  font-size: ${ICON_FONT_SIZE};
`;

const InactiveIcon = styled(CloseCircleOutlined)`
  color: ${STATE_GREY};
  font-size: ${ICON_FONT_SIZE};
`;

interface ITemplateTitleProps {
  active: boolean | null;
}

const TemplateTitle = styled.div<ITemplateTitleProps>`
  color: ${(props) =>
    props.active === true
      ? STATE_GREEN
      : props.active === false
      ? STATE_GREY
      : ''};
  font-size: 18px;
  font-weight: bold;
`;

interface ITagTitleProps {
  compositeState: ECompositeState | null;
}

const TagTitle = styled.div<ITagTitleProps>`
  color: ${(props) => compositeStateToColour(props.compositeState)};
  font-size: 18px;
  font-weight: bold;
`;

interface ISummaryInformationTitleProps {
  active: boolean | null;
  compositeState: ECompositeState | null;
  isLoading: boolean;
  name: string | null;
  uiTagId: string | null;
  viewMode: EViewMode;
}

const SummaryInformationTitle = ({
  active,
  compositeState,
  isLoading,
  name,
  uiTagId,
  viewMode,
}: ISummaryInformationTitleProps): JSX.Element => {
  return (
    <>
      {isLoading || viewMode === EViewMode.None ? null : viewMode ===
          EViewMode.EditETagTemplate ||
        viewMode === EViewMode.ReviewETagTemplate ? (
        <SeparatedRowLayout includeEmpty={true}>
          {active === true ? (
            <ActiveIcon />
          ) : active === false ? (
            <InactiveIcon />
          ) : null}
          <TemplateTitle active={active}>
            {`${
              isEmptyValue(name)
                ? ''
                : `${name} - ${
                    active === true
                      ? 'ACTIVE'
                      : active === false
                      ? 'INACTIVE'
                      : ''
                  }`
            }`}
          </TemplateTitle>
        </SeparatedRowLayout>
      ) : (
        <SeparatedRowLayout includeEmpty={true}>
          <CompositeStateIcon compositeState={compositeState} />
          <TagTitle compositeState={compositeState}>
            {`${
              isEmptyValue(uiTagId)
                ? ''
                : `${uiTagId} - ${
                    isEmptyValue(compositeState) ? '' : compositeState
                  }`
            }`}
          </TagTitle>
        </SeparatedRowLayout>
      )}
    </>
  );
};

export default SummaryInformationTitle;
