import {
  NAVIGATION_ACTIONS_HEIGHT,
  NAVIGATION_ACTIONS_TOP,
} from 'constants/styles';
import styled from 'styled-components';

interface INavigationActionsProps {
  right: string;
}

const NavigationActions = styled.div<INavigationActionsProps>`
  height: ${NAVIGATION_ACTIONS_HEIGHT};
  position: fixed;
  right: ${(props) => props.right};
  top: ${NAVIGATION_ACTIONS_TOP};

  > div {
    display: flex;
    align-items: center;
    height: inherit;
  }
`;

export default NavigationActions;
