import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { EApprovalStatus } from 'enums/Approval';
import { IApprovalHistoryResponse } from 'interfaces/Detail';
import {
  IETagReasons,
  IETagPendingRequestsResponse,
  IETagReasonsResponse,
  IETagSetStateOnRequestResponse,
  IETagGetRulesResponse,
  IETagValidationReport,
  IETagGetScheduledFailedValidationsReportsResponse,
  IETagScheduleReportResponse,
  IETagDeleteReportResponse,
} from 'interfaces/ETag';
import {
  ALL_PENDING_REQUESTS_ROUTE,
  FAILED_VALIDATIONS_ROUTE,
  FAILED_VALIDATIONS_SCHEDULE_ID_ROUTE,
  FAILED_VALIDATIONS_SCHEDULE_ROUTE,
  PENDING_REQUESTS_ROUTE,
  REASON_ROUTE,
  RULES_ROUTE,
  SET_STATE_ROUTE,
  TAG_HISTORY_ROUTE,
} from 'services/approval/constants';
import { APPROVAL_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TTimeZone } from 'types/DateTime';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';

export const retrievePendingRequests = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  timeZone: TTimeZone,
): Promise<AxiosResponse<IETagPendingRequestsResponse>> => {
  return await API.get(
    APPROVAL_SERVICE_API_NAME,
    PENDING_REQUESTS_ROUTE(toEntityId, tagPrimaryKey, timeZone),
    DEFAULT_OPTIONS,
  );
};

export const retrieveAllPendingRequests = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): Promise<AxiosResponse<IETagPendingRequestsResponse>> => {
  return await API.get(
    APPROVAL_SERVICE_API_NAME,
    ALL_PENDING_REQUESTS_ROUTE(toEntityId, timeZone),
    DEFAULT_OPTIONS,
  );
};

export const retrieveReasons = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IETagReasonsResponse>> => {
  return await API.get(
    APPROVAL_SERVICE_API_NAME,
    REASON_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const updateReasons = async (
  toEntityId: TToEntityId,
  eTagReasons: IETagReasons,
): Promise<AxiosResponse<IETagReasonsResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: eTagReasons,
  };
  return await API.put(
    APPROVAL_SERVICE_API_NAME,
    REASON_ROUTE(toEntityId),
    options,
  );
};

export const retrieveApprovalHistory = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
  requestId?: number,
): Promise<AxiosResponse<IApprovalHistoryResponse>> => {
  return await API.get(
    APPROVAL_SERVICE_API_NAME,
    TAG_HISTORY_ROUTE(toEntityId, tagPrimaryKey, requestId),
    DEFAULT_OPTIONS,
  );
};

export const setStateOnRequest = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestRef: number,
  approvalStatus: EApprovalStatus,
  notes: string | null | undefined,
): Promise<AxiosResponse<IETagSetStateOnRequestResponse>> => {
  return await API.post(
    APPROVAL_SERVICE_API_NAME,
    SET_STATE_ROUTE(
      toEntityId,
      tagPrimaryKey,
      requestRef,
      approvalStatus,
      notes,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveApprovalRules = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IETagGetRulesResponse>> => {
  return await API.get(
    APPROVAL_SERVICE_API_NAME,
    RULES_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const createScheduledValidationReport = async (
  toEntityId: TToEntityId,
  report: IETagValidationReport,
): Promise<AxiosResponse<IETagScheduleReportResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: report,
  };
  return await API.post(
    APPROVAL_SERVICE_API_NAME,
    FAILED_VALIDATIONS_SCHEDULE_ROUTE(toEntityId),
    options,
  );
};

export const deleteScheduledValidationReport = async (
  toEntityId: TToEntityId,
  reportId: string,
): Promise<AxiosResponse<IETagDeleteReportResponse>> => {
  return await API.del(
    APPROVAL_SERVICE_API_NAME,
    FAILED_VALIDATIONS_SCHEDULE_ID_ROUTE(toEntityId, reportId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveScheduledValidationReports = async (
  toEntityId: TToEntityId,
): Promise<
  AxiosResponse<IETagGetScheduledFailedValidationsReportsResponse>
> => {
  return await API.get(
    APPROVAL_SERVICE_API_NAME,
    FAILED_VALIDATIONS_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};
