import Page from 'components/organisms/Page/Page';
import DetailPageContent from 'components/pages/DetailPage/DetailPageContent';
import { IRouteProps } from 'interfaces/Component';
import { useSelector } from 'react-redux';
import { TRootState } from 'types/Redux';
import { useEffect } from 'react';
import { EXTERNAL_LINKS_MAP } from 'constants/General';
import { EExternalLinks } from 'enums/General';

const retrieveDetailPageState = (state: TRootState) => {
  const { draft_id, name, tag_id, tag_primary_key, template_id } =
    state.detail.present;

  return {
    draft_id,
    name,
    tag_id,
    tag_primary_key,
    template_id,
  };
};

const DetailPage = ({
  appActions,
  setUserGuideExternalLink,
}: IRouteProps): JSX.Element => {
  const { draft_id, name, tag_id, tag_primary_key, template_id } = useSelector(
    retrieveDetailPageState,
  );

  useEffect(() => {
    setUserGuideExternalLink(EXTERNAL_LINKS_MAP[EExternalLinks.DetailGuide]);
  }, [setUserGuideExternalLink]);

  let pageTitle: string = 'Summary Detail - PCI e-Tag+';

  if (template_id === undefined) {
    if (tag_id !== null && tag_id.ui_tag_id !== null) {
      if (draft_id === null) {
        if (tag_primary_key !== undefined) {
          pageTitle = `Tag: ${tag_id.ui_tag_id}`;
        }
      } else {
        pageTitle = `Draft: ${tag_id.ui_tag_id}`;
      }
    }
  } else if (name !== null) {
    pageTitle = name;
  }

  return (
    <Page
      appActions={appActions}
      PageContent={DetailPageContent}
      pageTitle={pageTitle}
    />
  );
};

export default DetailPage;
