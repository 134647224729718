import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { EProfileType } from 'enums/ETag';
import { IETagDraft } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  TAGS_DRAFT_ROUTE,
  TAGS_DRAFT_SUMMARY_PROFILES_ROUTE,
  TAGS_DRAFT_SUMMARY_ROUTE,
  TAGS_DRAFTS_FROM_DISTRIBUTED_ROUTE,
  TAGS_DRAFTS_FROM_TEMPLATE_ROUTE,
  TAGS_DRAFTS_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TETagDraftId, TETagTagPrimaryKey } from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { TTimeZone } from '../../../types/DateTime';

export const retrieveETagDraftSummaryAttribute = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  filterId?: TFilterId,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFT_SUMMARY_ROUTE(toEntityId, draftId, start, end, filterId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDraftSummaryProfiles = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  timeZone: string,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: string,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFT_SUMMARY_PROFILES_ROUTE(
      toEntityId,
      draftId,
      timeZone,
      start,
      end,
      profileSegment,
    ),
    DEFAULT_OPTIONS,
  );
};

export const createETagDraftFromDistributed = async (
  toEntityId: TToEntityId,
  tag_primary_key: TETagTagPrimaryKey,
  includeEnergyProfiles: boolean,
  includeTransmissionProfiles: boolean,
  start: ZonedDateTime,
  updateContact?: IContactInfo,
): Promise<AxiosResponse> => {
  const profileType: EProfileType[] = [];

  if (includeEnergyProfiles) {
    profileType.push(EProfileType.Energy);
  }

  if (includeTransmissionProfiles) {
    profileType.push(EProfileType.Transmission);
  }

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFTS_FROM_DISTRIBUTED_ROUTE(
      toEntityId,
      tag_primary_key,
      profileType,
      start,
      updateContact,
    ),
    DEFAULT_OPTIONS,
  );
};

export const createETagDraftFromTemplate = async (
  toEntityId: TToEntityId,
  templateId: string,
  start: ZonedDateTime,
  timeZone?: TTimeZone,
  updateContact?: IContactInfo,
): Promise<AxiosResponse> => {
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFTS_FROM_TEMPLATE_ROUTE(
      toEntityId,
      templateId,
      start,
      timeZone,
      updateContact,
    ),
    DEFAULT_OPTIONS,
  );
};

export const createETagDraft = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
): Promise<AxiosResponse> => {
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFTS_ROUTE(toEntityId, start),
    DEFAULT_OPTIONS,
  );
};

export const removeETagDraft = async (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
): Promise<AxiosResponse> => {
  return await API.del(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFT_ROUTE(toEntityId, draftId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagDraft = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFT_ROUTE(toEntityId, draftId),
    DEFAULT_OPTIONS,
  );
};

export const updateETagDraft = async (
  toEntityId: TToEntityId,
  eTagDraft: IETagDraft,
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: eTagDraft,
  };
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TAGS_DRAFTS_ROUTE(toEntityId),
    options,
  );
};
