import {
  EActiveOptions,
  TActiveOptions,
} from 'components/pages/TemplatesPage/types';
import { TEMPLATES_SHOW_ALL_GROUPS } from 'constants/Template';
import { EColumnType } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import {
  IETagTemplateColumnData,
  IETagTemplateColumnDataAgGrid,
} from 'interfaces/ETag';
import { TTimeZone } from 'types/DateTime';

export const TO_ENTITY_FILTER_CONFIGURATOR_MODAL_WIDTH_VALUE = 970;

export const TEMPLATE_DATA_TABLE_ID = 'templateDataTable';

export const DEFAULT_TIME_ZONE: TTimeZone = 'America/Los_Angeles';

export const ACTIVE_FILTER_OPTIONS: TActiveOptions = [
  {
    label: 'Show All',
    value: EActiveOptions.ShowAll,
  },
  {
    label: 'Show Active',
    value: EActiveOptions.ShowActive,
  },
  {
    label: 'Show Inactive',
    value: EActiveOptions.ShowInactive,
  },
];

export const ETAG_TEMPLATE_COLUMN_DATA: IETagTemplateColumnData[] = [
  {
    dataIndex: 'active',
    displayName: 'Active',
    minColumnWidth: 90,
    type: EColumnType.Boolean,
  },
  {
    dataIndex: 'name',
    displayName: 'Name',
    minColumnWidth: 190,
    type: EColumnType.DetailLink,
  },
  {
    dataIndex: 'description',
    displayName: 'Description',
    minColumnWidth: 190,
    type: EColumnType.String,
  },
  {
    dataIndex: 'cpse',
    displayName: 'CPSE',
    minColumnWidth: 63,
    type: EColumnType.String,
  },
  {
    dataIndex: 'gca',
    displayName: 'GCA',
    minColumnWidth: 57,
    type: EColumnType.String,
  },
  {
    dataIndex: 'lca',
    displayName: 'LCA',
    minColumnWidth: 55,
    type: EColumnType.String,
  },
  {
    dataIndex: 'source',
    displayName: 'Source',
    minColumnWidth: 72,
    type: EColumnType.String,
  },
  {
    dataIndex: 'sink',
    displayName: 'Sink',
    minColumnWidth: 56,
    type: EColumnType.String,
  },
  {
    dataIndex: 'tag_type',
    displayName: 'Tag Type',
    minColumnWidth: 72,
    type: EColumnType.Enumeration,
  },
  {
    dataIndex: 'tag_note',
    displayName: 'Tag Note',
    minColumnWidth: 97,
    type: EColumnType.String,
  },
  {
    dataIndex: 'creator',
    displayName: 'Owner',
    minColumnWidth: 94,
    type: EColumnType.String,
  },
  {
    dataIndex: 'last_update_time',
    displayName: 'Last Update Time',
    minColumnWidth: 114,
    type: EColumnType.DateTime,
  },
  {
    dataIndex: 'last_update_user',
    displayName: 'Last Update By',
    minColumnWidth: 114,
    type: EColumnType.String,
  },
];

export const ETAG_TEMPLATE_COLUMN_DATA_AG_GRID: IETagTemplateColumnDataAgGrid[] =
  [
    {
      field: 'active',
      headerName: 'Active',
      minWidth: 107,
      maxWidth: 107,
      type: EColumnType.Boolean,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 237,
      maxWidth: 237,
      type: EColumnType.DetailLink,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 242,
      maxWidth: 242,
      type: EColumnType.String,
    },
    {
      field: 'cpse',
      headerName: 'CPSE',
      minWidth: 82,
      maxWidth: 82,
      type: EColumnType.String,
    },
    {
      field: 'gca',
      headerName: 'GCA',
      minWidth: 63,
      maxWidth: 63,
      type: EColumnType.String,
    },
    {
      field: 'lca',
      headerName: 'LCA',
      minWidth: 61,
      maxWidth: 61,
      type: EColumnType.String,
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 112,
      maxWidth: 112,
      type: EColumnType.String,
    },
    {
      field: 'sink',
      headerName: 'Sink',
      minWidth: 76,
      maxWidth: 76,
      type: EColumnType.String,
    },
    {
      field: 'tag_type',
      headerName: 'Tag Type',
      minWidth: 89,
      maxWidth: 89,
      type: EColumnType.Enumeration,
    },
    {
      field: 'tag_note',
      headerName: 'Tag Note',
      minWidth: 302,
      maxWidth: 302,
      type: EColumnType.String,
    },
    {
      field: 'creator',
      headerName: 'Owner',
      minWidth: 188,
      maxWidth: 188,
      type: EColumnType.String,
    },
    {
      field: 'last_update_time',
      headerName: 'Last Update Time',
      minWidth: 155,
      maxWidth: 155,
      type: EColumnType.DateTime,
    },
    {
      field: 'last_update_user',
      headerName: 'Last Update By',
      minWidth: 180,
      maxWidth: 180,
      type: EColumnType.String,
    },
  ];

export const DEFAULT_TEMPLATE_GROUP_OPTIONS: IOption<string | null>[] = [
  { label: 'Show All Groups', value: TEMPLATES_SHOW_ALL_GROUPS },
  { label: 'No Group', value: null },
];
