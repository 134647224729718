import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import {
  ACTION_BAR_HEIGHT_VALUE,
  COLUMN_LAYOUT_SHARED_STYLES,
  DETAIL_HEADER,
  LAYOUT_PADDING_VALUE,
  PUSH_RIGHT_VALUE,
  TO_ENTITY_TITLE_HEIGHT_VALUE,
} from 'constants/styles';
import { EDateTimeRangeDST } from 'enums/Time';
import {
  ITransmissionAvailabilityDataSet,
  ITransmissionAvailabilityReportResponse,
  ITransmissionAvailabilityReportView,
  ITransmissionCapacityAvailabilityReportViewData,
} from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useSelector } from 'react-redux';
import { IToEntityUserState } from 'reduxes/User/types';
import {
  retrieveAllTransmissionCapacityAvailability,
  retrieveTsnTransmissionCapacityAvailability,
  retrieveUnassociatedTsrTransmissionCapacityAvailability,
} from 'services/agent/transmissionAvailability';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import useAsyncEffect from 'use-async-effect';
import { getKeyForTransmissionAvailabilityDataSet } from 'utils/eTag';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { getDateTimeRangeDstType } from 'utils/time';
import { retrieveToEntityIdTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';
import {
  certainTypeSum,
  TRANSMISSION_AVAILABILITY_COLUMN_DATA_AG_GRID,
  transmissionAvailabilityExpandAllRows,
  transmissionAvailabilityOptionalColumns,
  transmissionCapacityAutoGroupColumnDef,
  transmissionCapacityDefaultColumnDefinition,
} from './constants';
import {
  getColumnsForTransmissionAvailabiltyReport,
  getTransmissionAvailabilityData,
} from './helpers';
import TransmissionCapacityActionBar from './TransmissionCapacityActionBar';
import TransmissionCapacityDataTable from './TransmissionCapacityDataTable';
import { ETransmissionCapacityDataViews } from './types';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import TopBarMenu from '../TopBarMenu/TopBarMenu';
import DownloadTagButton from '../../molecules/DownloadTagButton/DownloadTagButton';
import NavigationActions from '../../atoms/NavigationActions/NavigationActions';
import { TRootState } from '../../../types/Redux';
import { ERetreiveState } from '../../../enums/General';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 100%;
  padding: ${LAYOUT_PADDING_VALUE}px;
  padding-bottom: 0;
  position: relative;

  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Title = styled.div`
  ${DETAIL_HEADER}

  height: ${TO_ENTITY_TITLE_HEIGHT_VALUE}px;

  > div {
    padding-top: 6px;
  }
`;

interface SelectedDataSetState {
  activeDataSet: ITransmissionAvailabilityDataSet[];
}

// Props

interface ITransmissionCapacityAvailabilityMonitorProps {
  encodedPermissionsId: string;
  isEmbeddedTitle?: boolean;
  isUnconstrained?: boolean;
  maxHeight: string;
  toEntity: IToEntity;
  toEntityUserState: IToEntityUserState | undefined;
}

const TransmissionCapacityAvailabilityMonitor = ({
  encodedPermissionsId,
  isEmbeddedTitle,
  maxHeight,
  toEntity,
}: ITransmissionCapacityAvailabilityMonitorProps): JSX.Element => {
  const timeZone: TTimeZone = useSelector(
    retrieveToEntityIdTimeZone(toEntity.to_entity),
  );
  const configState = useSelector((state: TRootState) => state.config);

  const { currentTheme } = useThemeSwitcher();
  const [errorMessage] = useState<TErrorMessage>(null);
  const [tsnAddDisabled, setTsnAddDisabled] = useState<boolean>(true);

  const [selectedStartDate, setSelectedStartDate] =
    useState<ZonedDateTime | null>(
      ZonedDateTime.now(timeZone).withHour(0).withMinute(0).withSeconds(0),
    );
  const [selectedEndDate, setSelectedEndDate] = useState<ZonedDateTime | null>(
    ZonedDateTime.now(timeZone)
      .withHour(0)
      .withMinute(0)
      .withSeconds(0)
      .add(1, 'day'),
  );
  const [selectedDataView, setSelectedDataView] =
    useState<ETransmissionCapacityDataViews>(
      ETransmissionCapacityDataViews.USED_AREF,
    );
  const [prevSelectedDataView, setPrevSelectedDataView] =
    useState<ETransmissionCapacityDataViews>(
      ETransmissionCapacityDataViews.USED_AREF,
    );
  const [visibleOptionalColumns, setVisibleOptionalColumns] = useState<
    string[]
  >([]);
  const [allowCollapseAll, setAllowCollapseAll] = useState<boolean>(false);
  const [allowExpandAll, setAllowExpandAll] = useState<boolean>(false);
  const [collapseAllTrigger, setCollapseAllTrigger] = useState<boolean>(false);
  const [datePickerSubmitState, setDatePickerSubmitState] =
    useState<boolean>(false);
  const [expandAllTrigger, setExpandAllTrigger] = useState<boolean>(false);

  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isInitialLoadInProgress, setIsInitialLoadInProgress] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataViewLoading, setDataViewLoading] = useState<boolean>(false);
  // set to true to tell ag grid to clear filters, ag grid will set to false
  const [clearAgGridFilters, setClearAgGridFilters] = useState<boolean>(false);
  const [hasExtraHourColumn, setHasExtraHourColumn] = useState<boolean>(false);
  const [
    defaultTransmissionAvailabilityDataSets,
    setDefaultTransmissionAvailabilityDataSets,
  ] = useState<ITransmissionAvailabilityDataSet[]>([]);
  const [unassociatedArefTSRDataSets, setUnassociatedArefTSRDataSets] =
    useState<ITransmissionAvailabilityDataSet[]>([]);
  const [
    transmissionServiceNetworkDataSets,
    setTransmissionServiceNetworkDataSets,
  ] = useState<ITransmissionAvailabilityDataSet[]>([]);
  const [upToDateDataSets, setUpToDateDataSets] = useState<
    ETransmissionCapacityDataViews[]
  >([]);

  function activeDataSetsReducer(
    state: SelectedDataSetState,
    action: ETransmissionCapacityDataViews,
  ): SelectedDataSetState {
    switch (action) {
      case ETransmissionCapacityDataViews.USED_AREF:
        return { activeDataSet: defaultTransmissionAvailabilityDataSets };
      case ETransmissionCapacityDataViews.UNUSED_AREF:
        return { activeDataSet: unassociatedArefTSRDataSets };
      case ETransmissionCapacityDataViews.TSN:
        return { activeDataSet: transmissionServiceNetworkDataSets };
      default:
        return { activeDataSet: state.activeDataSet };
    }
  }

  const [activeDataSetsState, setActiveDataSetsDispatch] = useReducer(
    activeDataSetsReducer,
    { activeDataSet: [] },
  );
  const [hideTenantTitle, setHideTenantTitle] = useState<boolean>(true);

  useEffect(() => {
    if (configState.retrievingConfig === ERetreiveState.RetrievingCompleted) {
      const hideTenantTitleConfig =
        configState.hideTenantTitleConfig &&
        configState.hideTenantTitleConfig.get(toEntity.to_entity);
      setHideTenantTitle(hideTenantTitleConfig as boolean);
    }
  }, [configState, toEntity.to_entity, setHideTenantTitle]);

  useAsyncEffect(() => {
    if (selectedStartDate) {
      if (
        getDateTimeRangeDstType(
          selectedStartDate,
          selectedStartDate?.add(1, 'day'),
        ) === EDateTimeRangeDST.Shorter
      ) {
        setSelectedStartDate((previousSelectedStartDate) =>
          selectedStartDate.getUtcOffset() <
            selectedStartDate.withTimeZone(timeZone, true).getUtcOffset() &&
          timeZone !== 'America/Phoenix'
            ? previousSelectedStartDate
                ?.withTimeZone(timeZone, true)
                .add(1, 'day')
                .startOf('day') ?? null
            : previousSelectedStartDate
                ?.withTimeZone(timeZone, true)
                .startOf('day') ?? null,
        );
        setSelectedEndDate(
          (previousSelectedEndDate) =>
            previousSelectedEndDate?.withTimeZone(timeZone, true) ?? null,
        );
        return;
      }
    }
    if (selectedEndDate) {
      if (
        getDateTimeRangeDstType(
          selectedEndDate,
          selectedEndDate?.add(1, 'day'),
        ) === EDateTimeRangeDST.Shorter
      ) {
        setSelectedStartDate(
          (previousSelectedStartDate) =>
            previousSelectedStartDate?.withTimeZone(timeZone, true) ?? null,
        );
        setSelectedEndDate((previousSelectedEndDate) =>
          selectedEndDate.getUtcOffset() <
            selectedEndDate.withTimeZone(timeZone, true).getUtcOffset() &&
          timeZone !== 'America/Phoenix'
            ? previousSelectedEndDate
                ?.withTimeZone(timeZone, true)
                .add(1, 'day')
                .startOf('day') ?? null
            : previousSelectedEndDate
                ?.withTimeZone(timeZone, true)
                .startOf('day') ?? null,
        );
        return;
      }
    }

    setSelectedStartDate(
      (previousSelectedStartDate) =>
        previousSelectedStartDate
          ?.withTimeZone(timeZone, true)
          .startOf('day') ?? null,
    );

    setSelectedEndDate(
      (previousSelectedEndDate) =>
        previousSelectedEndDate?.withTimeZone(timeZone, true).startOf('day') ??
        null,
    );
  }, [timeZone]);

  useEffect(() => {
    setDataViewLoading(true);
    switch (selectedDataView) {
      case ETransmissionCapacityDataViews.USED_AREF: {
        setActiveDataSetsDispatch(ETransmissionCapacityDataViews.USED_AREF);
        setTsnAddDisabled(true);
        break;
      }
      case ETransmissionCapacityDataViews.UNUSED_AREF: {
        setActiveDataSetsDispatch(ETransmissionCapacityDataViews.UNUSED_AREF);
        setTsnAddDisabled(true);
        break;
      }
      case ETransmissionCapacityDataViews.TSN: {
        setActiveDataSetsDispatch(ETransmissionCapacityDataViews.TSN);
        setTsnAddDisabled(false);
        break;
      }
    }
    setDataViewLoading(false);
  }, [selectedDataView]);

  const columns: ColDef[] = useMemo(
    () =>
      getColumnsForTransmissionAvailabiltyReport(
        TRANSMISSION_AVAILABILITY_COLUMN_DATA_AG_GRID,
        timeZone,
        toEntity.to_entity,
        currentTheme,
        hasExtraHourColumn,
      ),
    // Disabling exhaustive dependencies here lets us trigger a recomputation of the column
    // definitions when the selected dates have changed or the refresh flag is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      toEntity,
      currentTheme,
      selectedStartDate,
      selectedEndDate,
      hasExtraHourColumn,
      refreshFlag,
    ],
  );

  useEffect(() => {
    setRefreshFlag(!refreshFlag);
    // Have to disable exhaustive deps here so we can set the refresh flag and not get stuck in an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerSubmitState]);

  const handleRefresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const handleTsnAdd = async () => {
    if (selectedStartDate != null && selectedEndDate != null) {
      // Need to update TSN data if we add one
      setIsLoading(true);
      const currentDayDstType: EDateTimeRangeDST = getDateTimeRangeDstType(
        selectedStartDate,
        selectedEndDate,
      );
      // Have to adjust ending hour so we don't double up on DST checks with the backend
      const adjustedEndDate: string =
        currentDayDstType === EDateTimeRangeDST.Longer
          ? selectedEndDate.add(-1, 'hour').isoFormat()
          : currentDayDstType === EDateTimeRangeDST.Shorter
          ? selectedEndDate.add(1, 'hour').isoFormat()
          : selectedEndDate.isoFormat();
      // All TSN Data
      const transmissionCapacityTSNResponse: AxiosResponse<ITransmissionAvailabilityReportResponse> =
        await retrieveTsnTransmissionCapacityAvailability(
          toEntity.to_entity,
          selectedStartDate.isoFormat(),
          adjustedEndDate,
        );

      const transmissionCapacityTSNAvailabilityView: ITransmissionAvailabilityReportView =
        transmissionCapacityTSNResponse.data.response;

      const transmissionCapacityTSNDataArray: ITransmissionCapacityAvailabilityReportViewData[] =
        transmissionCapacityTSNAvailabilityView.data;

      if (!isSuccessStatus(transmissionCapacityTSNResponse.status)) {
        throw new Error(transmissionCapacityTSNResponse.data.errorMessage!);
      }
      const [tsnDataSet] = getTransmissionAvailabilityData(
        transmissionCapacityTSNDataArray,
        selectedStartDate,
        selectedEndDate,
      );
      setTransmissionServiceNetworkDataSets(tsnDataSet);
      setIsLoading(false);
      setRefreshFlag(!refreshFlag);
    }
  };

  useAsyncEffect(async () => {
    if (
      selectedStartDate != null &&
      selectedEndDate != null &&
      !isInitialLoadInProgress
    ) {
      try {
        let shouldRecalculateData: boolean = true;
        if (isInitialLoad) {
          setIsInitialLoadInProgress(true);
        }
        // Data view changed, don't recalculate
        if (selectedDataView !== prevSelectedDataView) {
          shouldRecalculateData = false;
        }
        // Parameter other than data view changed, all sets out of date and must recalculate
        else {
          setUpToDateDataSets([]);
        }
        setPrevSelectedDataView(selectedDataView);
        setIsLoading(true);
        // Don't trigger the expand/collapse all methods after new data is loaded
        setAllowExpandAll(false);
        setAllowCollapseAll(false);
        const currentDayDstType: EDateTimeRangeDST = getDateTimeRangeDstType(
          selectedStartDate,
          selectedEndDate.add(1, 'day'),
        );
        // Have to adjust ending hour so we don't double up on DST checks with the backend
        const adjustedEndDate: string =
          currentDayDstType === EDateTimeRangeDST.Longer
            ? selectedEndDate.add(-1, 'hour').isoFormat()
            : currentDayDstType === EDateTimeRangeDST.Shorter
            ? selectedEndDate.add(1, 'hour').isoFormat()
            : selectedEndDate.isoFormat();

        switch (selectedDataView) {
          case ETransmissionCapacityDataViews.USED_AREF: {
            if (
              upToDateDataSets.includes(
                ETransmissionCapacityDataViews.USED_AREF,
              ) &&
              !shouldRecalculateData
            ) {
              break;
            }
            // All transmission capacity data (used AREF)
            const transmissionCapacityAllResponse: AxiosResponse<ITransmissionAvailabilityReportResponse> =
              await retrieveAllTransmissionCapacityAvailability(
                toEntity.to_entity,
                selectedStartDate.isoFormat(),
                adjustedEndDate,
              );

            const transmissionCapacityAllAvailabilityView: ITransmissionAvailabilityReportView =
              transmissionCapacityAllResponse.data.response;

            const transmissionCapacityAllTSRDataArray: ITransmissionCapacityAvailabilityReportViewData[] =
              transmissionCapacityAllAvailabilityView.data;

            if (!isSuccessStatus(transmissionCapacityAllResponse.status)) {
              throw new Error(
                transmissionCapacityAllResponse.data.errorMessage!,
              );
            }
            // Only need the extra hour boolean from this first data view/set since the dates are the same for all views
            const [defaultTransmissionAvailabilityDataSet, hasExtraHourData] =
              getTransmissionAvailabilityData(
                transmissionCapacityAllTSRDataArray,
                selectedStartDate,
                selectedEndDate,
              );
            setHasExtraHourColumn(hasExtraHourData);
            setDefaultTransmissionAvailabilityDataSets(
              defaultTransmissionAvailabilityDataSet,
            );
            const newUpToDateDataSets: ETransmissionCapacityDataViews[] = [
              ...upToDateDataSets,
              ETransmissionCapacityDataViews.USED_AREF,
            ];
            setUpToDateDataSets(newUpToDateDataSets);
            break;
          }
          case ETransmissionCapacityDataViews.UNUSED_AREF: {
            if (
              upToDateDataSets.includes(
                ETransmissionCapacityDataViews.UNUSED_AREF,
              ) &&
              !shouldRecalculateData
            ) {
              break;
            }
            // All TSR for AREFs not associated with tags (unused AREF)
            const transmissionCapacityUnassociatedTSRResponse: AxiosResponse<ITransmissionAvailabilityReportResponse> =
              await retrieveUnassociatedTsrTransmissionCapacityAvailability(
                toEntity.to_entity,
                selectedStartDate.isoFormat(),
                adjustedEndDate,
              );

            const transmissionCapacityUnassociatedTSRAvailabilityView: ITransmissionAvailabilityReportView =
              transmissionCapacityUnassociatedTSRResponse.data.response;

            const transmissionCapacityUnassociatedTSRDataArray: ITransmissionCapacityAvailabilityReportViewData[] =
              transmissionCapacityUnassociatedTSRAvailabilityView.data;

            if (
              !isSuccessStatus(
                transmissionCapacityUnassociatedTSRResponse.status,
              )
            ) {
              throw new Error(
                transmissionCapacityUnassociatedTSRResponse.data.errorMessage!,
              );
            }
            const [unassociatedTSRDataSet] = getTransmissionAvailabilityData(
              transmissionCapacityUnassociatedTSRDataArray,
              selectedStartDate,
              selectedEndDate,
            );
            setUnassociatedArefTSRDataSets(unassociatedTSRDataSet);
            const newUpToDateDataSets: ETransmissionCapacityDataViews[] = [
              ...upToDateDataSets,
              ETransmissionCapacityDataViews.UNUSED_AREF,
            ];
            setUpToDateDataSets(newUpToDateDataSets);
            break;
          }
          case ETransmissionCapacityDataViews.TSN: {
            if (
              upToDateDataSets.includes(ETransmissionCapacityDataViews.TSN) &&
              !shouldRecalculateData
            ) {
              break;
            }
            // All TSN Data
            const transmissionCapacityTSNResponse: AxiosResponse<ITransmissionAvailabilityReportResponse> =
              await retrieveTsnTransmissionCapacityAvailability(
                toEntity.to_entity,
                selectedStartDate.isoFormat(),
                adjustedEndDate,
              );

            const transmissionCapacityTSNAvailabilityView: ITransmissionAvailabilityReportView =
              transmissionCapacityTSNResponse.data.response;

            const transmissionCapacityTSNDataArray: ITransmissionCapacityAvailabilityReportViewData[] =
              transmissionCapacityTSNAvailabilityView.data;

            if (!isSuccessStatus(transmissionCapacityTSNResponse.status)) {
              throw new Error(
                transmissionCapacityTSNResponse.data.errorMessage!,
              );
            }
            const [tsnDataSet] = getTransmissionAvailabilityData(
              transmissionCapacityTSNDataArray,
              selectedStartDate,
              selectedEndDate,
            );
            setTransmissionServiceNetworkDataSets(tsnDataSet);
            const newUpToDateDataSets: ETransmissionCapacityDataViews[] = [
              ...upToDateDataSets,
              ETransmissionCapacityDataViews.TSN,
            ];
            setUpToDateDataSets(newUpToDateDataSets);
            break;
          }
        }
        setActiveDataSetsDispatch(selectedDataView);
        setIsLoading(false);
        setIsInitialLoadInProgress(false);
      } catch (error: any) {
        setIsLoading(false);
        setIsInitialLoad(false);
        setIsInitialLoadInProgress(false);
      }
    }
  }, [
    toEntity.to_entity,
    selectedStartDate,
    selectedEndDate,
    refreshFlag,
    selectedDataView,
  ]);

  // Misc

  // The key will always be unique with UTC. AG Grid requires unique IDs,
  // but we cannot use the timeZone variable here because it starts as undefined
  // which causes rows to have the same ID
  const rowKey = (
    transmissionAvailabilityDataSet: ITransmissionAvailabilityDataSet,
  ): string => {
    return getKeyForTransmissionAvailabilityDataSet(
      transmissionAvailabilityDataSet,
    );
  };

  // Toggling the expandAll or collapseAll flags triggers the corresponding method on the AGDataTable;
  // this allows the user to retrigger the collapse or expand all methods multiple times on the same data load,
  // while also making sure those methods don't trigger immediately by themselves on a new data load
  const handleExpandAll = () => {
    setAllowExpandAll(true);
    setExpandAllTrigger(!expandAllTrigger);
  };

  const handleCollapseAll = () => {
    setAllowCollapseAll(true);
    setCollapseAllTrigger(!collapseAllTrigger);
  };

  const getRowId = (params: GetRowIdParams) => rowKey(params.data);

  const handleClearAllFilters = useCallback(() => {
    setClearAgGridFilters(true);
  }, []);

  return (
    <Layout>
      <SeparatedRowLayout>
        {toEntity && toEntity.entity_code ? (
          <TopBarMenu
            encodedPermissionsId={encodedPermissionsId}
            timeZone={timeZone}
            toEntity={toEntity}
          />
        ) : null}
        {isEmbeddedTitle || hideTenantTitle ? null : (
          <Title>
            <div>{toEntity.entity_code}</div>
          </Title>
        )}
        <NavigationActions right={PUSH_RIGHT_VALUE}>
          <DownloadTagButton
            currentTheme={currentTheme}
            toEntityId={toEntity.to_entity}
          />
        </NavigationActions>
      </SeparatedRowLayout>
      <ErrorMessage maxWidth='100%' topMargin={0}>
        {errorMessage}
      </ErrorMessage>
      <TransmissionCapacityActionBar
        arefList={activeDataSetsState.activeDataSet.map((item) => item.aref)}
        allOptionalColumns={transmissionAvailabilityOptionalColumns}
        dataViewSelectDisabled={isLoading}
        datePickerSubmitState={datePickerSubmitState}
        encodedPermissionsId={encodeIds([encodedPermissionsId, 'actionBar'])}
        handleCollapseAllToggle={handleCollapseAll}
        handleExpandAllToggle={handleExpandAll}
        isEmbeddedTitle={isEmbeddedTitle}
        onClearAllFilters={handleClearAllFilters}
        onRefresh={handleRefresh}
        onTsnAdd={handleTsnAdd}
        selectedDataView={selectedDataView}
        selectedEndDate={selectedEndDate}
        selectedStartDate={selectedStartDate}
        selectedOptionalColumns={visibleOptionalColumns}
        setDatePickerSubmitState={setDatePickerSubmitState}
        setSelectedDataView={setSelectedDataView}
        setSelectedEndDate={setSelectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedOptionalColumns={setVisibleOptionalColumns}
        showExtraHourColumn={hasExtraHourColumn}
        timeZone={timeZone}
        toEntity={toEntity}
        transmissionAvailabilityColumnDatas={
          TRANSMISSION_AVAILABILITY_COLUMN_DATA_AG_GRID
        }
        transmissionAvailabilityDataSets={activeDataSetsState.activeDataSet}
        tsnAddDisabled={tsnAddDisabled}
      />
      <TransmissionCapacityDataTable
        aggFuncs={{ certainTypeSumID: certainTypeSum }}
        allowCollapseAll={allowCollapseAll}
        allowExpandAll={allowExpandAll}
        autoGroupColumnDef={transmissionCapacityAutoGroupColumnDef}
        clearFilters={clearAgGridFilters}
        collapseAllTrigger={collapseAllTrigger}
        columns={columns}
        data={activeDataSetsState.activeDataSet}
        defaultColumnDefinition={transmissionCapacityDefaultColumnDefinition(
          currentTheme,
        )}
        expandAllTrigger={expandAllTrigger}
        groupDefaultExpanded={2}
        groupDisplayType='singleColumn'
        handleExpandAll={transmissionAvailabilityExpandAllRows}
        isLoading={isLoading || dataViewLoading}
        maxHeight={`calc(${maxHeight} - ${
          TO_ENTITY_TITLE_HEIGHT_VALUE +
          ACTION_BAR_HEIGHT_VALUE +
          LAYOUT_PADDING_VALUE +
          LAYOUT_PADDING_VALUE
        }px)`}
        optionalColumns={transmissionAvailabilityOptionalColumns}
        getRowId={getRowId}
        setClearFilters={setClearAgGridFilters}
        setLoadingWhenDataChange={true}
        sideBarDef={{
          defaultToolPanel: '',
          toolPanels: ['filters'],
        }}
        timeZone={timeZone}
        useFilteredValuesForAgg={true}
        visibleOptionalColumns={visibleOptionalColumns}
      />
    </Layout>
  );
};

export default withFloatOver(TransmissionCapacityAvailabilityMonitor);
