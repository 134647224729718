import { IThemedProps } from 'interfaces/Component';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled, { keyframes } from 'styled-components';
import { textColour } from 'utils/styles';

const DOT_SIZE_VALUE = 2;
const DOT_RADIUS_VALUE = DOT_SIZE_VALUE / 2;
const DOT_SPACING_VALUE = DOT_SIZE_VALUE * 2;

const dots = keyframes`
  0% { opacity: 0; }
  100% { opacity: .71; }
`;

const dot = (props: IThemedProps): string => `
  background-color: ${textColour(props)};
  border-radius: ${DOT_RADIUS_VALUE}px;
  height: ${DOT_SIZE_VALUE}px;
  width: ${DOT_SIZE_VALUE}px;
`;

const Dots = styled.div<IThemedProps>`
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${dots};
  animation-timing-function: linear;
  margin: 0 ${DOT_SPACING_VALUE}px;
  position: relative;

  ${dot}

  ::before,
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  ::before {
    left: ${-DOT_SPACING_VALUE}px;

    ${dot}
  }

  ::after {
    left: ${DOT_SPACING_VALUE}px;

    ${dot}
  }
`;

const Indicator = (): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  return <Dots currentTheme={currentTheme!} />;
};

export default Indicator;
