import { Popover as AntDesignPopover } from 'antd';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import { IPendingRequest } from 'components/organisms/ToEntityETagReviewPendingRequests/types';
import { ERROR_RED } from 'constants/styles';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';
import { isEmptyValue } from 'utils/general';

interface IRowProps {
  hasErrorMessage?: boolean;
}

const Row = styled.tr<IRowProps>`
  ${(props) =>
    props.hasErrorMessage
      ? `
        * {
          color: ${ERROR_RED};
        }
      `
      : ''}
`;

export interface IPendingRequestRowProps extends HTMLAttributes<HTMLElement> {
  record: IPendingRequest | undefined;
}

const PendingRequest = ({
  record,
  ...rest
}: IPendingRequestRowProps): JSX.Element => {
  const errorMessage: TErrorMessage = record?.errorMessage;
  const hasErrorMessage: boolean = !isEmptyValue(errorMessage);

  return (
    <AntDesignPopover
      content={<ErrorMessage>{errorMessage}</ErrorMessage>}
      trigger={hasErrorMessage ? 'hover' : 'none'}
    >
      <Row hasErrorMessage={hasErrorMessage} {...rest} />
    </AntDesignPopover>
  );
};

export default PendingRequest;
