import { StringCell } from 'components/molecules/Cell/SimpleCell';
import {
  IStylingColumnData,
  IStylingData,
} from 'components/molecules/ETagTemplateRow/types';
import StylingRow, {
  IStyledStylingRowProps,
} from 'components/molecules/RowStylingConfigurator/StylingRow';
import { ETheme } from 'enums/Style';
import { IIndexable } from 'interfaces/General';
import { ISummaryStyles, ISummaryThemedStyles } from 'interfaces/Summary';
import { TableComponents } from 'rc-table/lib/interface';
import { backgroundColour } from 'utils/styles';

export const getCustomComponents = (): TableComponents<IStylingData> => ({
  // Since we cannot just pass props to the HeaderCell via composition we use
  // various contexts to allow HeaderCell to interact with arraySorters and
  // arrayFilters.
  body: {
    row: StylingRow,
  },
});

const getThemedStyles = (): ISummaryThemedStyles => {
  const summaryThemedStyles: Record<string, ISummaryStyles> = {};

  Object.values(ETheme).forEach((currentTheme: string) => {
    let summaryStyles: ISummaryStyles = {
      'background-color': backgroundColour({ currentTheme }),
    };
    summaryThemedStyles[currentTheme] = summaryStyles;
  });

  return summaryThemedStyles as ISummaryThemedStyles & IIndexable;
};
export const transformColumnInformation = (
  columnData: IStylingColumnData[],
): IStylingColumnData[] => {
  const transformed: IStylingColumnData[] = [];

  columnData.forEach((column: IStylingColumnData) => {
    const columnCopy: IStylingColumnData = { ...column };

    columnCopy.render = (value: string): JSX.Element => (
      <StringCell
        minWidth={columnCopy.minColumnWidth}
        summaryThemedStyles={getThemedStyles()}
        value={value}
      />
    );

    transformed.push(columnCopy);
  });

  return transformed;
};

export const getBrightnessValueFromFilter = (
  rowStyle: string | undefined,
): number => {
  return parseFloat(
    (rowStyle || '').replace('brightness(', '').replace(')', ''),
  );
};

export const getSelectedStyles = (props: IStyledStylingRowProps) =>
  props.isSelected
    ? `
    ${props.rowHighlightingStyle}
    filter: invert(100%);
  `
    : '';

export const getHoverHighlightStyles = (props: IStyledStylingRowProps) =>
  props.allowHoverHighlight
    ? `
  :hover {
    ${props.rowHighlightingStyle}

    ${getSelectedStyles(props)};
  }
  `
    : '';
