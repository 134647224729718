import { IDataGridPosition } from 'interfaces/Component';

export interface IPasteData {
  commitPaste: (addExtraRows?: boolean) => void;
  hasTooManyColumns: boolean;
  hasTooManyRows: boolean;
}

export enum ESelectedCellsDirection {
  Grid,
  Horizontal,
  None,
  Vertical,
}

export type TPositionHandler = (position: IDataGridPosition) => void;
