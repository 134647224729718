import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Spinner from 'components/atoms/Spinner/Spinner';
import {
  ERROR_RED,
  STANDARD_SPACING,
  SUCCESS_GREEN,
  WARNING_ORANGE,
} from 'constants/styles';
import styled from 'styled-components';

const SuccessIcon = styled(CheckCircleTwoTone)`
  font-size: 18px;
`;

const WarningIcon = styled(ExclamationCircleTwoTone)`
  font-size: 18px;
`;

const ErrorIcon = styled(CloseCircleTwoTone)`
  font-size: 18px;
`;

const Feedback = styled.div`
  margin: ${STANDARD_SPACING} 0;
  max-width: 800px;
  max-height: 600px;
  overflow-y: auto;

  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const ValidationError = styled.div`
  color: ${ERROR_RED};
  max-width: 100%;
  overflow-wrap: break-word;
`;

const ValidationWarning = styled.div`
  max-width: 100%;
  overflow-wrap: break-word;
`;

interface IETagRequestFormFeedbackProps {
  errorMessage: string | null | undefined;
  isValidateAndSubmitSuccessful: boolean;
  isValidateSuccessful: boolean;
  isValidating: boolean;
  requestType: string;
  successMessage?: string;
  validationErrors: string[];
  validationWarnings: string[];
}

export const ETagRequestFormFeedback = (
  props: IETagRequestFormFeedbackProps,
): JSX.Element => {
  let {
    errorMessage,
    isValidateAndSubmitSuccessful,
    isValidateSuccessful,
    isValidating,
    requestType,
    successMessage,
    validationErrors,
    validationWarnings,
  } = props;
  return (
    <Feedback>
      {isValidating ? <Spinner /> : null}
      {validationErrors.length > 0 ? (
        <>
          <SeparatedRowLayout>
            <WarningIcon twoToneColor={ERROR_RED} />
            <span>
              {requestType} Request failed validation with the following issues:
            </span>
          </SeparatedRowLayout>
          <ul>
            {validationErrors.map(
              (validationError: string, index: number): JSX.Element => (
                <li key={`li-${index}`}>
                  <ValidationError>{validationError}</ValidationError>
                </li>
              ),
            )}
          </ul>
        </>
      ) : null}
      {validationWarnings.length > 0 ? (
        <>
          <SeparatedRowLayout>
            <WarningIcon twoToneColor={WARNING_ORANGE} />
            <span>
              {requestType} Request had the following validation warnings:
            </span>
          </SeparatedRowLayout>
          <ul>
            {validationWarnings.map(
              (validationWarning: string, index: number): JSX.Element => (
                <li key={`li-${index}`}>
                  <ValidationWarning>{validationWarning}</ValidationWarning>
                </li>
              ),
            )}
          </ul>
        </>
      ) : null}
      {isValidateSuccessful || isValidateAndSubmitSuccessful ? (
        <SeparatedRowLayout>
          <SuccessIcon twoToneColor={SUCCESS_GREEN} />
          <span>
            {successMessage !== undefined
              ? successMessage
              : isValidateSuccessful
              ? `${requestType} Request is valid`
              : isValidateAndSubmitSuccessful
              ? `${requestType} Request is valid and has been submitted`
              : ''}
          </span>
        </SeparatedRowLayout>
      ) : null}
      {errorMessage === null ? null : (
        <SeparatedRowLayout>
          <ErrorIcon twoToneColor={ERROR_RED} />
          <ErrorMessage maxWidth='100%' topMargin={0}>
            {errorMessage}
          </ErrorMessage>
        </SeparatedRowLayout>
      )}
    </Feedback>
  );
};
