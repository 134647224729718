import { EAlertEffectOption } from 'components/molecules/ToEntityAlertConfiguration/types';
import { EAlertEffect } from 'enums/Alert';
import { IOption } from 'interfaces/Component';

export const subscriptionOptions: IOption<boolean>[] = [
  { label: 'Subscribe', value: true },
  { label: 'Unsubscribe', value: false },
];

export const alertEffectOptions: IOption<EAlertEffect>[] = new Array(4);
alertEffectOptions[EAlertEffectOption.ColourAndSound] = {
  label: 'Color alert + play sound',
  value: EAlertEffect.All,
};
alertEffectOptions[EAlertEffectOption.ColourOnly] = {
  label: 'Color alert only',
  value: EAlertEffect.Message,
};
alertEffectOptions[EAlertEffectOption.SoundOnly] = {
  label: 'Play sound only',
  value: EAlertEffect.Sound,
};
alertEffectOptions[EAlertEffectOption.NoEffect] = {
  label: 'No alert effects',
  value: EAlertEffect.None,
};

export const onScreenTimeOptions: IOption<number>[] = [
  { label: 'Show for 10 seconds', value: 10 },
  { label: 'Show for 15 seconds', value: 15 },
  { label: 'Show for 30 seconds', value: 30 },
  { label: 'Show for 1 minute', value: 60 },
  { label: 'Show for 2 minutes', value: 120 },
  { label: 'Show for 5 minutes', value: 300 },
  { label: 'Show for 10 minutes', value: 600 },
];

export const soundPlayRepetitionOptions: IOption<number>[] = [
  { label: 'Play sound once', value: 1 },
  { label: 'Play sound twice', value: 2 },
  { label: 'Play sound 3 times', value: 3 },
];

export const autoAcknowledgeOptions: IOption<number>[] = [
  { label: 'Auto read after 30 minutes', value: 30 },
  { label: 'Auto read after 1 hour', value: 60 },
  { label: 'Auto read after 2 hours', value: 120 },
  { label: 'Never auto read', value: 0 },
];
