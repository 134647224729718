import { ExpandableConfig } from 'antd/lib/table/interface';
import MiscInfoDataTable from 'components/molecules/MiscInfoDataTable/MiscInfoDataTable';
import { STANDARD_SPACING } from 'constants/styles';
import { EMiscInfosExpandedColumn } from 'enums/General';
import useExpandableDataTable from 'hooks/useExpandableDataTable';
import { IETagMiscInfos } from 'interfaces/ETag';
import { IMiscInfo } from 'interfaces/General';
import { useCallback } from 'react';
import styled from 'styled-components';

const ExpandedRowLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${STANDARD_SPACING};
`;

interface IuseExpandableMiscInfos<T> {
  expandableConfig: ExpandableConfig<T>;
  updateRowMiscInfosExpandedKey: (record: T) => void;
}

const useExpandableMiscInfos = <T extends IETagMiscInfos>(
  getRowKey: (record: T) => string,
  useNumberedKeys: boolean,
): IuseExpandableMiscInfos<T> => {
  const getExpandedRowRender = useCallback(
    (getRowExpandedKey: (record: T) => EMiscInfosExpandedColumn | undefined) =>
      (record: T): JSX.Element => {
        const miscInfoExpandedColumn: EMiscInfosExpandedColumn | undefined =
          getRowExpandedKey(record);

        if (miscInfoExpandedColumn !== undefined) {
          if (miscInfoExpandedColumn === EMiscInfosExpandedColumn.MiscInfos) {
            const adjustedMiscInfos: IMiscInfo[] =
              record.misc_infos === null ? [] : record.misc_infos;

            return (
              <ExpandedRowLayout>
                <MiscInfoDataTable
                  data={adjustedMiscInfos}
                  isUnconstrained={false}
                />
              </ExpandedRowLayout>
            );
          }
        }

        return <></>;
      },
    [],
  );

  const { expandableConfig, updateRowExpandedKey } = useExpandableDataTable<
    T,
    EMiscInfosExpandedColumn
  >(
    EMiscInfosExpandedColumn.None,
    getExpandedRowRender,
    getRowKey,
    useNumberedKeys,
  );

  const updateRowMiscInfosExpandedKey = useCallback(
    (record: T) => {
      return updateRowExpandedKey(EMiscInfosExpandedColumn.MiscInfos, record);
    },
    [updateRowExpandedKey],
  );

  return { expandableConfig, updateRowMiscInfosExpandedKey };
};

export default useExpandableMiscInfos;
