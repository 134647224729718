import styled from 'styled-components';
import { COLUMN_LAYOUT_SHARED_STYLES } from '../../../constants/styles';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import Tooltip from '../Tooltip/Tooltip';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../../../constants/time';
import DateTimeRangePicker from '../DateTimeRangePicker/DateTimeRangePicker';
import { TTimeZone, TZonedDateTimeRange } from '../../../types/DateTime';
import { IOption, IShowTime } from '../../../interfaces/Component';
import { useEffect, useRef, useState } from 'react';
import { ZonedDateTime } from '../../../utils/zonedDateTime';
import InfiniteSelectableList, {
  IInfiniteSelectableListProps,
} from '../InfiniteSelectableList/InfiniteSelectableList';
import { Index } from 'react-virtualized';
import { loadNotices } from './helpers';
import { TErrorMessage } from '../../../types/Error';
import useAsyncEffect from 'use-async-effect';
import {
  INotice,
  INoticesAcknowledgeResponse,
} from '../../../interfaces/Notice';
import Notice from './Notice';
import {
  IwithNoticeHeightMapProps,
  withNoticeHeightMap,
} from './withNoticeHeightMap';
import { TNoticeId } from 'types/Notices';
import { isSuccessStatus } from 'utils/general';
import { EActionState, ENoticeSeverity } from 'enums/General';
import { AxiosResponse } from 'axios';
import { acknowledgeNotices } from 'services/notice/notices';
import { captureError } from 'utils/error';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Button from 'components/atoms/Button/Button';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { EAlertAcknowledged } from 'enums/Alert';
import { IToEntity } from 'interfaces/ToEntity';
import IconButton from '../../atoms/IconButton/IconButton';
import { SyncOutlined } from '@ant-design/icons';

const SHOW_TIME_CONFIGURATION: IShowTime = { format: TIME_FORMAT };
const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 100%;
`;

const StyledDateTimeRangePicker = styled(DateTimeRangePicker)`
  width: 276px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

// Specialise Select components
const AcknowledgeStatusSelect = styled(
  (props: ISelectProps<EAlertAcknowledged>) =>
    Select<EAlertAcknowledged>(props),
)`
  width: 83px;
`;

const NoticeSeveritySelect = styled((props: ISelectProps<ENoticeSeverity>) =>
  Select<ENoticeSeverity>(props),
)`
  width: 193px;
`;

const NoticesHistoryList = (props: IInfiniteSelectableListProps<INotice>) =>
  InfiniteSelectableList<INotice>(props);

const acknowledgeStatusOptions: IOption<EAlertAcknowledged>[] = [
  { label: 'Read', value: EAlertAcknowledged.Read },
  { label: 'Unread', value: EAlertAcknowledged.Unread },
];

const noticeSeverityOptions: IOption<ENoticeSeverity>[] = [
  { label: 'Low', value: ENoticeSeverity.Low },
  { label: 'Medium', value: ENoticeSeverity.Medium },
  { label: 'High', value: ENoticeSeverity.High },
];
interface IProps extends IwithNoticeHeightMapProps {
  currentTheme: string;
  timeZone: TTimeZone;
  setHasUnreadNotices: (value: boolean) => void;
  toEntities?: IToEntity[];
}

const NoticesHistory = (props: IProps): JSX.Element => {
  const {
    currentTheme,
    getNoticeHeightMap,
    recalculateNoticeHeightMap,
    resetNoticeHeightMap,
    setNoticesForHeightMap,
    timeZone,
    toEntities,
    setHasUnreadNotices,
  } = props;
  const [notices, setNotices] = useState<INotice[]>([]);
  const [selectedDateTimes, setSelectedDateTimes] =
    useState<TZonedDateTimeRange>([
      ZonedDateTime.now(timeZone).subtract(2, 'hours'),
      ZonedDateTime.now(timeZone).add(1, 'hours'),
    ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedNotices, setSelectedNotices] = useState<TNoticeId[]>([]);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [loadNoticesRequest, setLoadNoticesRequest] = useState<{
    callback: (() => Promise<void>) | undefined;
  }>({ callback: undefined });
  const loadedRowsRef = useRef<true[]>([]);
  const [acknowledgeActionState, setAcknowledgeActionState] =
    useState<EActionState>(EActionState.NoAction);
  const [noticesErrorMap, setNoticesErrorMap] = useState<
    Record<TNoticeId, TErrorMessage>
  >({});
  const [selectedAcknowledgeStatus, setSelectedAcknowledgeStatus] =
    useState<EAlertAcknowledged>(EAlertAcknowledged.Unread);
  const [selectedNoticeSeverities, setSelectedNoticeSeverities] = useState<
    ENoticeSeverity[]
  >([]);

  useEffect(() => {
    setHasUnreadNotices(notices.length > 0);
  }, [notices, setHasUnreadNotices]);

  const handleDateTimesSelect = (dateTimes: TZonedDateTimeRange) => {
    setSelectedDateTimes(dateTimes);
  };

  const handleAcknowledge = async () => {
    if (selectedNotices.length > 0 && toEntities && toEntities.length > 0) {
      try {
        setErrorMessage(null);
        setNoticesErrorMap({});
        setAcknowledgeActionState(EActionState.Actioning);

        let ackCount = 0;
        let failedAckAlertIds: TNoticeId[] = [];

        toEntities.forEach(async (toEntity: IToEntity) => {
          const response: AxiosResponse<INoticesAcknowledgeResponse> =
            await acknowledgeNotices(toEntity.to_entity, selectedNotices);

          const noticesAcknowledgeResponse: INoticesAcknowledgeResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(noticesAcknowledgeResponse.errorMessage!);
          }

          const { response: noticesAcknowledge } = noticesAcknowledgeResponse;

          ackCount += noticesAcknowledge.ack_count;
          failedAckAlertIds = failedAckAlertIds.concat(
            noticesAcknowledge.failed_ack_alert_ids,
          );
        });

        if (ackCount > 0) {
          await reloadNotices();
        }

        if (failedAckAlertIds.length > 0) {
          const updatedNoticesErrorMap: Record<TNoticeId, TErrorMessage> = {};

          failedAckAlertIds.forEach((notice_id: TNoticeId) => {
            updatedNoticesErrorMap[notice_id] =
              'An error occurred trying to mark as read. Please try again later.';
          });

          setNoticesErrorMap(updatedNoticesErrorMap);

          recalculateNoticeHeightMap(notices, updatedNoticesErrorMap);
        }

        setAcknowledgeActionState(EActionState.Succeeded);
      } catch (error: any) {
        captureError(error);

        setErrorMessage(
          'An error occurred marking alerts. Please try again later.',
        );

        setAcknowledgeActionState(EActionState.Failed);
      }
    }
  };

  const noticeRenderer = (notice: INotice): JSX.Element => (
    <Notice currentTheme={currentTheme} notice={notice} timeZone={timeZone} />
  );

  useAsyncEffect(async () => {
    if (!isLoading && loadNoticesRequest.callback !== undefined) {
      setLoadNoticesRequest({ callback: undefined });

      await loadNoticesRequest.callback();
    }
  }, [isLoading, loadNoticesRequest.callback]);

  const handleLoadData = async () => {
    const loadNoticesRequest = async () => {
      await loadNotices(
        selectedAcknowledgeStatus,
        selectedNoticeSeverities,
        selectedDateTimes,
        noticesErrorMap,
        setIsLoading,
        setNotices,
        setErrorMessage,
        setNoticesForHeightMap,
        toEntities,
      );
    };

    if (isLoading) {
      setLoadNoticesRequest({ callback: loadNoticesRequest });
    } else {
      await loadNoticesRequest();
    }
  };

  const reloadNotices = async (includeExpired: boolean = true) => {
    resetNoticeHeightMap();
    loadedRowsRef.current = [];
    setNotices([]);

    await loadNotices(
      selectedAcknowledgeStatus,
      selectedNoticeSeverities,
      selectedDateTimes,
      noticesErrorMap,
      setIsLoading,
      setNotices,
      setErrorMessage,
      setNoticesForHeightMap,
      toEntities,
    );
  };

  const handleAcknowledgeStatusSelect = (
    value: EAlertAcknowledged | undefined,
  ) => {
    if (value === undefined) {
      throw new Error('Invalid acknowledge status value.');
    }

    setSelectedAcknowledgeStatus(value!);
  };

  useAsyncEffect(async () => {
    await reloadNotices();
  }, [selectedDateTimes, selectedAcknowledgeStatus, selectedNoticeSeverities]);

  const handleNoticeSeveritySelect = (values: ENoticeSeverity[]) => {
    setSelectedNoticeSeverities(values);
  };

  return (
    <Layout>
      <SeparatedRowLayout>
        <Tooltip title='Filter Notices by Date Range'>
          <StyledDateTimeRangePicker
            format={DATE_TIME_FORMAT}
            onChange={handleDateTimesSelect}
            showTime={SHOW_TIME_CONFIGURATION}
            timeZone={timeZone}
            value={selectedDateTimes}
          />
        </Tooltip>
        <Tooltip title='Refresh notices'>
          <IconButton
            icon={<SyncOutlined />}
            onClick={() => reloadNotices(false)}
          />
        </Tooltip>
        <Tooltip title='Filter Notices by Read Status'>
          <AcknowledgeStatusSelect
            onChange={handleAcknowledgeStatusSelect}
            options={acknowledgeStatusOptions}
            placeholder='Read status'
            value={selectedAcknowledgeStatus}
            valueToUid={(value: EAlertAcknowledged): string => value as string}
          />
        </Tooltip>
        <Tooltip title='Filter Notices by Severity'>
          <NoticeSeveritySelect
            allowClear={true}
            allowMultiple={true}
            onChangeMultiple={handleNoticeSeveritySelect}
            options={noticeSeverityOptions}
            placeholder='Notice Severity'
            values={selectedNoticeSeverities}
            valueToUid={(value: ENoticeSeverity): string => value as string}
          />
        </Tooltip>
      </SeparatedRowLayout>
      <NoticesHistoryList
        data={notices}
        isLoading={isLoading}
        isRowLoaded={(rowIndex: Index): boolean =>
          !!loadedRowsRef.current[rowIndex.index]
        }
        itemRenderer={noticeRenderer}
        loadData={handleLoadData}
        minimumBatchSize={1}
        onSelectChange={setSelectedNotices}
        overscanRowCount={1}
        rowHeight={(params: Index): number =>
          getNoticeHeightMap()[notices[params.index].notice_id]
        }
        rowKey={(notice: INotice): string => notice.notice_id}
        selectedKeys={selectedNotices}
        threshold={1}
      />
      <ErrorMessage maxWidth='100%'>{errorMessage}</ErrorMessage>
      <Actions>
        <Button
          actionState={acknowledgeActionState}
          isDisabled={selectedNotices.length === 0}
          label='Mark selected as read'
          onClick={handleAcknowledge}
        />
      </Actions>
    </Layout>
  );
};

export default withNoticeHeightMap(NoticesHistory);
