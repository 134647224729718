import { INotice } from '../../../interfaces/Notice';
import styled from 'styled-components';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from '../../../constants/styles';
import { ALERT_WIDTH_VALUE } from '../../../constants/Alert';
import { MutableRefObject, useRef, useState, useEffect } from 'react';
import NoticeBody from '../NoticeBody/NoticeBody';
import { TErrorMessage } from 'types/Error';
import { TTimeZone } from 'types/DateTime';
import { NO_OP_HANDLER } from 'constants/General';

const Container = styled.div`
  position: relative;
`;

const Layout = styled(SeparatedRowLayout)`
  width: 100%;
`;

const NoticeContent = styled.div`
  padding: ${STANDARD_SPACING_VALUE * 1.5}px ${STANDARD_SPACING}
    ${STANDARD_SPACING_VALUE * 1.5}px ${STANDARD_SPACING_VALUE * 2}px;
  width: ${ALERT_WIDTH_VALUE + 72}px;
`;

interface INoticeProps {
  currentTheme?: string;
  notice: INotice;
  errorMessage?: TErrorMessage;
  setNoticeHeight?: (height: number) => void;
  timeZone: TTimeZone;
}
const Notice = (props: INoticeProps): JSX.Element => {
  const { currentTheme, notice, errorMessage, setNoticeHeight, timeZone } =
    props;
  const noticeRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [internalErrorMessage, setInternalErrorMessage] =
    useState<TErrorMessage>(errorMessage);
  const errorMessageSetterRef =
    useRef<(errorMessage: TErrorMessage) => void>(NO_OP_HANDLER);

  useEffect(() => {
    setInternalErrorMessage(errorMessage);

    errorMessageSetterRef.current(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (noticeRef.current && setNoticeHeight !== undefined) {
      setNoticeHeight(noticeRef.current.getBoundingClientRect().height);
    }
  }, [notice.notice_id, noticeRef, internalErrorMessage, setNoticeHeight]);

  return (
    <Container>
      <Layout>
        <NoticeContent ref={noticeRef}>
          <NoticeBody
            body={notice.message_body}
            currentTheme={currentTheme}
            expirationTime={notice.expiration_time}
            link={notice.message_link}
            publishedDate={notice.publish_date}
            severity={notice.severity}
            timeZone={timeZone}
          />
        </NoticeContent>
      </Layout>
    </Container>
  );
};

export default Notice;
