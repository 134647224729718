import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import {
  TDetailRetrievingDistributedTagItems,
  TDetailValidations,
} from 'types/Detail';

export const getInitialRetrievingDistributedTagItems =
  (): TDetailRetrievingDistributedTagItems => ({
    [EDistributedTagItem.LossAccountings]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.MarketSegment]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.PhysicalSegment]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.PhysicalSegmentsProfiles]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.Summary]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.TransactionStatuses]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.TransmissionAllocations]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.AllTransmissionAllocations]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
    [EDistributedTagItem.TransmissionPrioritySegments]: {
      errorMessage: null,
      retrieveState: ERetreiveState.NotRetrieving,
    },
  });

export const updateValidations = (
  currentValidations: TDetailValidations,
  actionValidations: TDetailValidations,
): TDetailValidations => {
  let updatedValidations: TDetailValidations = { ...currentValidations };
  Object.keys(actionValidations).forEach((key: string) => {
    if (key.includes('loadPhysicalSegment')) {
      const oldLoadKey: string | undefined = Object.keys(
        updatedValidations,
      ).find(
        (notUpdatedKey: string) =>
          notUpdatedKey.includes('loadPhysicalSegment') &&
          key !== notUpdatedKey,
      );
      if (oldLoadKey) {
        delete updatedValidations[oldLoadKey];
      }
    }
    const validation: boolean | undefined = currentValidations[key];
    const detailValidation: boolean = actionValidations[key];

    if (validation === undefined || validation !== detailValidation) {
      updatedValidations = {
        ...updatedValidations,
        [key]: detailValidation,
      };
    }
  });
  return updatedValidations;
};
