import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import { getMarketData } from 'components/organisms/MarketInformation/helpers';
import useMarketInformationColumns from 'components/organisms/MarketInformation/useMarketInformationColumns/useMarketInformationColumns';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING_VALUE,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { IEditMarketInfo } from 'interfaces/Detail';
import {
  IETagMarketDetail,
  IETagMarketInfo,
  IETagMisoMarketData,
  IETagSppMarketData,
} from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TETagMarketInfoMarketData } from 'types/ETag';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 100%;
`;

const TimeZoneInformation = styled.div`
  padding: 0 ${STANDARD_SPACING_VALUE / 2}px;
  width: 100%;
`;

const MarketInformationDataTable = styled(
  (
    props: IDataTableProps<
      | IViewDataTableColumn<IETagMisoMarketData>
      | IViewDataTableColumn<IETagSppMarketData>,
      TETagMarketInfoMarketData
    >,
  ) =>
    DataTable<
      | IViewDataTableColumn<IETagMisoMarketData>
      | IViewDataTableColumn<IETagSppMarketData>,
      TETagMarketInfoMarketData
    >(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

interface IMarketInformationProps {
  initialMarketInfos?: IETagMarketInfo[];
  isDisabled?: boolean;
  isEditable: boolean;
  isUnconstrained: boolean;
  marketDate: ZonedDateTime;
  marketDetail: IETagMarketDetail;
  marketInfos: IETagMarketInfo[];
  onChange: (editMarketInfo: IEditMarketInfo) => void;
}

const MarketInformation = ({
  initialMarketInfos,
  isDisabled,
  isEditable,
  isUnconstrained,
  marketDate,
  marketDetail,
  marketInfos,
  onChange,
}: IMarketInformationProps): JSX.Element => {
  const columns:
    | IViewDataTableColumn<IETagMisoMarketData>[]
    | IViewDataTableColumn<IETagSppMarketData>[] = useMarketInformationColumns(
    marketDetail.market,
    marketDate,
    isEditable,
    isUnconstrained,
    onChange,
    initialMarketInfos,
    isDisabled,
  );

  const data: TETagMarketInfoMarketData[] = useMemo(
    (): TETagMarketInfoMarketData[] => getMarketData(marketDetail, marketInfos),
    [marketDetail, marketInfos],
  );

  return (
    <Layout>
      <TimeZoneInformation>
        <SeparatedRowLayout>
          <div>Time Zone:</div>
          <div>{marketDetail.timeZoneDisplayString}</div>
        </SeparatedRowLayout>
      </TimeZoneInformation>
      <>
        {data.length > 0 ? (
          <MarketInformationDataTable
            columns={columns}
            data={data}
            pagination={false}
            tableLayout='fixed'
          />
        ) : null}
      </>
    </Layout>
  );
};

export default MarketInformation;
