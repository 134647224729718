import { StopOutlined, SyncOutlined } from '@ant-design/icons';
import Button from 'components/atoms/Button/Button';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import AddTSN from 'components/molecules/AddTSN/AddTSN';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import ToEntityIndicator from 'components/molecules/ToEntityIndicator/ToEntityIndicator';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import TransmissionCapacityAvailabilityCsvExport from 'components/molecules/TransmissionCapacityAvailabilityCsvExport/TransmissionCapacityAvailabilityCsvExport';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import {
  ACTION_BAR_MARGIN_RIGHT_VALUE,
  BUTTON_ICON_DIMENSIONS,
} from 'constants/styles';
import {
  ITransmissionAvailabilityColumnDataAgGrid,
  ITransmissionAvailabilityDataSet,
} from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { encodeIds } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import TransmissionCapacityAvailabilityDatePicker from '../../molecules/TransmissionCapacityAvailabilityDatePicker/TransmissionCapacityAvailabilityDatePicker';
import {
  transmissionAvailabilityDataViewSelectorOptions,
  transmissionAvailabilityOptionalColumnSelectorOptions,
} from './constants';
import { ETransmissionCapacityDataViews, TOptionalColumn } from './types';
import AddCustomCapacity from '../../molecules/AddCustomCapacity/AddCustomCapacity';

const ActionsBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const OptionalColumnsSelect = styled((props: ISelectProps<TOptionalColumn>) =>
  Select<TOptionalColumn>(props),
)`
  width: 210px;
`;

const DataViewSelect = styled(
  (props: ISelectProps<ETransmissionCapacityDataViews>) =>
    Select<ETransmissionCapacityDataViews>(props),
)`
  width: 140px;
`;

const ClearAllFiltersIcon = styled(StopOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

// Props
interface IProps {
  allOptionalColumns: string[];
  arefList: string[];
  dataViewSelectDisabled: boolean;
  datePickerSubmitState: boolean;
  encodedPermissionsId: string;
  handleCollapseAllToggle: () => void;
  handleExpandAllToggle: () => void;
  isEmbeddedTitle?: boolean;
  onClearAllFilters: () => void;
  onRefresh: () => void;
  onTsnAdd: () => void;
  selectedDataView: ETransmissionCapacityDataViews | undefined;
  selectedEndDate: ZonedDateTime | null;
  selectedStartDate: ZonedDateTime | null;
  selectedOptionalColumns: string[] | undefined;
  setDatePickerSubmitState: (datePickerSubmitState: boolean) => void;
  setSelectedDataView: (
    selectedTransmissionAvailabilityView: ETransmissionCapacityDataViews,
  ) => void;
  setSelectedEndDate: (selectedEndDate: ZonedDateTime | null) => void;
  setSelectedStartDate: (selectedStartDate: ZonedDateTime | null) => void;
  setSelectedOptionalColumns: (selectedOptionalColumns: string[] | []) => void;
  showExtraHourColumn: boolean;
  timeZone: TTimeZone | undefined;
  toEntity: IToEntity;
  transmissionAvailabilityColumnDatas: ITransmissionAvailabilityColumnDataAgGrid[];
  transmissionAvailabilityDataSets: ITransmissionAvailabilityDataSet[];
  tsnAddDisabled: boolean;
}

const TransmissionCapacityActionBar = (props: IProps): JSX.Element => {
  const {
    allOptionalColumns,
    arefList,
    dataViewSelectDisabled,
    datePickerSubmitState,
    encodedPermissionsId,
    handleCollapseAllToggle,
    handleExpandAllToggle,
    isEmbeddedTitle,
    onClearAllFilters,
    onRefresh,
    onTsnAdd,
    selectedDataView,
    selectedEndDate,
    selectedStartDate,
    selectedOptionalColumns,
    setDatePickerSubmitState,
    setSelectedDataView,
    setSelectedEndDate,
    setSelectedStartDate,
    setSelectedOptionalColumns,
    showExtraHourColumn,
    timeZone,
    toEntity,
    transmissionAvailabilityColumnDatas,
    transmissionAvailabilityDataSets,
    tsnAddDisabled,
  } = props;

  const handleOptionalColumnSelect = (values: TOptionalColumn[]) => {
    setSelectedOptionalColumns(values);
  };

  const handleDataViewSelect = (
    value: ETransmissionCapacityDataViews | undefined,
  ) => {
    if (value === undefined) {
      setSelectedDataView(ETransmissionCapacityDataViews.USED_AREF);
    } else {
      setSelectedDataView(value);
    }
  };

  return (
    <ActionsBar>
      <SeparatedRowLayout marginRight={ACTION_BAR_MARGIN_RIGHT_VALUE}>
        {isEmbeddedTitle ? <ToEntityIndicator toEntity={toEntity} /> : null}
        <Tooltip title='Refresh Table Contents'>
          <IconButton
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'transmissionAvailabilityRefresh',
            ])}
            icon={<RefreshIcon />}
            onClick={onRefresh}
          />
        </Tooltip>
        <TransmissionCapacityAvailabilityCsvExport
          allOptionalColumns={allOptionalColumns}
          transmissionAvailabilityColumnDatas={
            transmissionAvailabilityColumnDatas
          }
          transmissionAvailabilityDataSets={transmissionAvailabilityDataSets}
          panelPlacement='bottomLeft'
          selectedEndDate={selectedEndDate}
          selectedStartDate={selectedStartDate}
          timeZone={timeZone ?? 'UTC'}
          toEntityUiName={toEntity.entity_code}
          selectedOptionalColumns={selectedOptionalColumns}
          showExtraHourColumn={showExtraHourColumn}
        />
        <DataViewSelect
          isDisabled={dataViewSelectDisabled}
          onChange={handleDataViewSelect}
          options={transmissionAvailabilityDataViewSelectorOptions}
          value={selectedDataView}
          valueToUid={(value: ETransmissionCapacityDataViews): string => value}
        />
        {selectedDataView === ETransmissionCapacityDataViews.USED_AREF ? (
          <AddCustomCapacity
            arefList={arefList}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'transmissionAvailabilityAddTSN',
            ])}
            onSave={onRefresh}
            timeZone={timeZone ?? 'UTC'}
            transmissionAvailabilityDataSets={transmissionAvailabilityDataSets}
            toEntityId={toEntity.to_entity}
            startDate={selectedStartDate}
            stopDate={selectedEndDate}
          />
        ) : (
          <AddTSN
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'transmissionAvailabilityAddTSN',
            ])}
            isDisabled={tsnAddDisabled}
            onApply={onTsnAdd}
            timeZone={timeZone ?? 'UTC'}
            toEntityId={toEntity.to_entity}
          />
        )}
        <UserTimeZoneSelector toEntityId={toEntity.to_entity} />
        {timeZone === undefined ? null : (
          <Tooltip title='Date Time Range Selection'>
            <TransmissionCapacityAvailabilityDatePicker
              datePickerSubmitState={datePickerSubmitState}
              end={selectedEndDate}
              includeRanges={true}
              rangeLimitInHours={120}
              setDatePickerSubmitState={setDatePickerSubmitState}
              setEnd={setSelectedEndDate}
              setStart={setSelectedStartDate}
              start={selectedStartDate}
              timeZone={timeZone}
            />
          </Tooltip>
        )}
        <OptionalColumnsSelect
          allowClear
          allowMultiple
          maxTagCount={'responsive'}
          onChangeMultiple={handleOptionalColumnSelect}
          options={transmissionAvailabilityOptionalColumnSelectorOptions}
          placeholder={'Optional Columns'}
          values={selectedOptionalColumns}
          valueToUid={(value: TOptionalColumn): string => value}
        />
        <Button label='Expand All' onClick={handleExpandAllToggle} />
        <Button label='Collapse All' onClick={handleCollapseAllToggle} />
        <Tooltip title='Clear All Filters'>
          <IconButton
            icon={<ClearAllFiltersIcon />}
            onClick={onClearAllFilters}
          />
        </Tooltip>
      </SeparatedRowLayout>
    </ActionsBar>
  );
};

export default TransmissionCapacityActionBar;
