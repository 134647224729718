import Tooltip from '../Tooltip/Tooltip';
import NoticesHistory from './NoticesHistory';
import { Drawer as AntDesignDrawer } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { TTimeZone } from '../../../types/DateTime';
import { IThemedProps } from 'interfaces/Component';
import { colourForTheme } from 'utils/styles';
import { IToEntity } from 'interfaces/ToEntity';
import { permissionsForIdAndToEntity } from '../../../hooks/usePermissions';

const DRAWER_WIDTH_VALUE = 635;
const NoticesHistoryFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IProps {
  timeZone: TTimeZone;
  currentTheme: string;
  toEntities?: IToEntity[];
  permissionId: string;
}

interface INoticesActionIcon extends IThemedProps {
  hasUnreadNotices: boolean;
}

const ActionIconContainer = styled.div<INoticesActionIcon>`
  > .anticon {
    font-size: 18px;
    color: ${colourForTheme};
    cursor: pointer;
    animation: ${(props) =>
      props.hasUnreadNotices
        ? 'blink-animation 2s steps(5, start) infinite'
        : undefined};
    -webkit-animation: ${(props) =>
      props.hasUnreadNotices
        ? 'blink-animation 2s steps(5, start) infinite'
        : undefined};
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
`;

const ToEntityNoticesHistory = (props: IProps): JSX.Element => {
  const { timeZone, currentTheme: theme, toEntities, permissionId } = props;
  const [hasUnreadNotices, setHasUnreadNotices] = useState<boolean>(true);
  const [tooltipDisabled, setTooltipDisabled] = useState<boolean>(false);

  const toEntitiesInWhichUserCanDisplayNotices = toEntities?.filter(
    (toEntity: IToEntity): boolean =>
      permissionsForIdAndToEntity(permissionId, toEntity).isExecutable,
  );

  const [showNotices, setShowNotices] = useState<boolean>(false);
  const handleNotices = () => {
    setShowNotices(true);
    setTooltipDisabled(true);
  };

  const handleClose = () => {
    setShowNotices(false);
    setTooltipDisabled(false);
  };

  return (
    <Tooltip isDisabled={tooltipDisabled} title={'Notices History'}>
      <ActionIconContainer
        currentTheme={theme}
        onClick={handleNotices}
        hasUnreadNotices={hasUnreadNotices}
      >
        <ExclamationCircleFilled />
      </ActionIconContainer>
      <AntDesignDrawer
        destroyOnClose={true}
        maskClosable={true}
        onClose={handleClose}
        title={
          <NoticesHistoryFlexDiv>
            <span>Notices History</span>
          </NoticesHistoryFlexDiv>
        }
        width={DRAWER_WIDTH_VALUE}
        visible={showNotices}
      >
        <NoticesHistory
          currentTheme={theme}
          timeZone={timeZone}
          setHasUnreadNotices={setHasUnreadNotices}
          toEntities={toEntitiesInWhichUserCanDisplayNotices}
        />
      </AntDesignDrawer>
    </Tooltip>
  );
};

export default ToEntityNoticesHistory;
