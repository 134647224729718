import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { IToEntityConfiguratorProps } from 'components/molecules/ToEntityConfiguration/ToEntityConfiguration';
import {
  displayViewToString,
  numberToString,
} from 'components/organisms/ETagManagerConfigurator/helpers';
import {
  AUTO_REFRESH_OPTIONS,
  DEFAULT_AUTO_REFRESH,
  DEFAULT_DISPLAY_VIEW,
  DEFAULT_PROFILE_FORMAT,
  DISPLAY_VIEW_OPTIONS,
} from 'constants/Detail';
import { EActionState } from 'enums/General';
import { EDisplayView } from 'enums/View';
import { IActionResponse, IModalHandlers } from 'interfaces/Component';
import { IETagManagerConfiguration } from 'interfaces/Detail';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { captureError } from 'utils/error';
import {
  ALL_PROFILES_FORMAT_OPTIONS,
  REDUCED_PROFILE_FORMAT_SELECT_WIDTH,
} from '../ProfileInformationView/ProfileInformationManager/constants';
import { EProfileFormat } from '../../../enums/Detail';
import { ECompositeState } from 'enums/ETag';

interface IProfileFormatSelectProps extends ISelectProps<EProfileFormat> {
  width: string;
}

// Specialize the Select components
const DefaultAutoRefreshInSecondsSelect = styled(
  (props: ISelectProps<number>) => Select<number>(props),
)`
  width: 97px;
`;

const DefaultDisplayViewSelect = styled((props: ISelectProps<EDisplayView>) =>
  Select<EDisplayView>(props),
)`
  width: 67px;
`;

const ProfileFormatSelect = styled((props: ISelectProps<EProfileFormat>) =>
  Select<EProfileFormat>(props),
)<IProfileFormatSelectProps>`
  width: ${(props) => props.width};
`;

export interface IETagManagerConfiguratorProps {
  eTagManagerConfiguration: IETagManagerConfiguration;
  updateETagManagerConfiguration: (
    eTagManagerConfiguration: IETagManagerConfiguration,
  ) => Promise<void>;
  compositeState?: ECompositeState | null;
}

const ETagManagerConfigurator = (
  props: IETagManagerConfiguratorProps & IToEntityConfiguratorProps,
): JSX.Element => {
  const {
    eTagManagerConfiguration,
    isDisabled,
    setHandlers,
    updateETagManagerConfiguration,
    compositeState,
  } = props;
  const [defaultAutoRefreshInSeconds, setDefaultAutoRefreshInSeconds] =
    useState<number>(DEFAULT_AUTO_REFRESH);
  const [defaultDisplayView, setDefaultDisplayView] =
    useState<EDisplayView>(DEFAULT_DISPLAY_VIEW);
  const [defaultProfileFormat, setDefaultProfileFormat] = useState<
    EProfileFormat | undefined
  >(EProfileFormat.StartStop);
  useEffect(() => {
    setDefaultAutoRefreshInSeconds(
      eTagManagerConfiguration.defaultAutoRefreshInSeconds === undefined
        ? DEFAULT_AUTO_REFRESH
        : eTagManagerConfiguration.defaultAutoRefreshInSeconds,
    );
  }, [eTagManagerConfiguration.defaultAutoRefreshInSeconds]);

  useEffect(() => {
    setDefaultDisplayView(
      eTagManagerConfiguration.defaultDisplayView === undefined
        ? DEFAULT_DISPLAY_VIEW
        : eTagManagerConfiguration.defaultDisplayView,
    );
  }, [eTagManagerConfiguration.defaultDisplayView]);

  useEffect(() => {
    setDefaultProfileFormat(
      eTagManagerConfiguration.defaultProfileFormat === undefined
        ? DEFAULT_PROFILE_FORMAT
        : eTagManagerConfiguration.defaultProfileFormat,
    );
  }, [eTagManagerConfiguration.defaultProfileFormat]);

  useEffect(() => {
    const modalHandlers: IModalHandlers = {};

    if (
      (eTagManagerConfiguration.defaultAutoRefreshInSeconds === undefined &&
        defaultAutoRefreshInSeconds !== DEFAULT_AUTO_REFRESH) ||
      (eTagManagerConfiguration.defaultAutoRefreshInSeconds !== undefined &&
        defaultAutoRefreshInSeconds !==
          eTagManagerConfiguration.defaultAutoRefreshInSeconds) ||
      (eTagManagerConfiguration.defaultDisplayView === undefined &&
        defaultDisplayView !== DEFAULT_DISPLAY_VIEW) ||
      (eTagManagerConfiguration.defaultDisplayView !== undefined &&
        defaultDisplayView !== eTagManagerConfiguration.defaultDisplayView) ||
      (eTagManagerConfiguration.defaultProfileFormat === undefined &&
        defaultProfileFormat !== DEFAULT_PROFILE_FORMAT) ||
      (eTagManagerConfiguration.defaultProfileFormat !== undefined &&
        defaultProfileFormat !== eTagManagerConfiguration.defaultProfileFormat)
    ) {
      modalHandlers.saveHandler = async () => {
        const updatedETagManagerConfiguration: IETagManagerConfiguration = {
          ...eTagManagerConfiguration,
          defaultAutoRefreshInSeconds,
          defaultDisplayView,
          defaultProfileFormat,
        };
        const actionResponse: IActionResponse = {
          actionState: EActionState.NoAction,
          errorMessage: null,
        };

        try {
          await updateETagManagerConfiguration(updatedETagManagerConfiguration);

          actionResponse.actionState = EActionState.Succeeded;
        } catch (error: any) {
          captureError(error);

          actionResponse.actionState = EActionState.Failed;
          actionResponse.errorMessage = error;
        } finally {
          return actionResponse;
        }
      };
    }

    setHandlers(modalHandlers);
  }, [
    defaultAutoRefreshInSeconds,
    defaultDisplayView,
    defaultProfileFormat,
    eTagManagerConfiguration,
    setHandlers,
    updateETagManagerConfiguration,
  ]);

  const handleDefaultAutoRefreshInSecondsSelectChange = useCallback(
    (defaultAutoRefreshInSeconds: number | undefined) => {
      // We are not allowing clear on the Select component, so value can safely
      // be asserted as never being undefined
      setDefaultAutoRefreshInSeconds(defaultAutoRefreshInSeconds!);
    },
    [],
  );

  const handleDefaultDisplayViewSelectChange = useCallback(
    (defaultDisplayView: EDisplayView | undefined) => {
      // We are not allowing clear on the Select component, so value can safely
      // be asserted as never being undefined
      setDefaultDisplayView(defaultDisplayView!);
    },
    [],
  );

  const handleProfileFormatChange = useCallback(
    (defaultProfileFormat: EProfileFormat | undefined) => {
      setDefaultProfileFormat(defaultProfileFormat);
    },
    [],
  );

  return (
    <SeparatedRowLayout>
      <SeparatedRowLayout>
        <div>Default Review Auto Refresh:</div>
        <DefaultAutoRefreshInSecondsSelect
          isDisabled={isDisabled}
          onChange={handleDefaultAutoRefreshInSecondsSelectChange}
          options={AUTO_REFRESH_OPTIONS}
          value={defaultAutoRefreshInSeconds}
          valueToUid={numberToString}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <div>Default Review Display View:</div>
        <DefaultDisplayViewSelect
          isDisabled={isDisabled}
          onChange={handleDefaultDisplayViewSelectChange}
          options={DISPLAY_VIEW_OPTIONS}
          value={defaultDisplayView}
          valueToUid={displayViewToString}
        />
      </SeparatedRowLayout>
      {compositeState !== ECompositeState.Draft ? (
        <SeparatedRowLayout>
          <div>Default Profile Format:</div>
          <div>
            <ProfileFormatSelect
              isDisabled={isDisabled}
              onChange={handleProfileFormatChange}
              options={ALL_PROFILES_FORMAT_OPTIONS}
              placeholder='Select Profile Format'
              value={defaultProfileFormat}
              valueToUid={(value: EProfileFormat): string => value as string}
              width={REDUCED_PROFILE_FORMAT_SELECT_WIDTH}
            />
          </div>
        </SeparatedRowLayout>
      ) : null}
    </SeparatedRowLayout>
  );
};

export default ETagManagerConfigurator;
