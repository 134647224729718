import { AnimationContext } from 'contexts/Animation/Animation';
import { IETagDataSet } from 'interfaces/ETag';
import { ISummaryStyles } from 'interfaces/Summary';
import { IToEntity } from 'interfaces/ToEntity';
import { Component, CSSProperties, HTMLAttributes, ReactNode } from 'react';
import styled, { keyframes, Keyframes } from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { getKeyForETagDataSet } from 'utils/eTag';
import { unpackSummaryStyles } from 'utils/styles';

interface IAnimatedETagRowProps {
  allowHoverHighlight?: boolean;
  animation?: Keyframes;
  duration: number;
  isSelected?: boolean;
  rowHighlightingStyle: string;
  rowStyle: string;
}

const getSelectedStyles = (props: IAnimatedETagRowProps) =>
  props.isSelected
    ? `
    ${props.rowHighlightingStyle}
    filter: invert(100%);
  `
    : '';

const getHoverHighlightStyles = (props: IAnimatedETagRowProps) =>
  props.allowHoverHighlight
    ? `
  :hover {
    ${props.rowHighlightingStyle}

    ${getSelectedStyles(props)};
  }
  `
    : '';

const AnimatedETagRow = styled.tr<IAnimatedETagRowProps>`
  animation-name: ${(props) => props.animation};
  animation-duration: ${(props) => props.duration}ms;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.19, 1.55, 0.47, 1.79);

  ${(props) => props.rowStyle};
  ${(props) => getSelectedStyles(props)};
  ${(props) => getHoverHighlightStyles(props)};
`;

const collapse = keyframes`
  0% { transform: scaleY(1) }
  30% { transform: scaleY(1.2)}
  100% { transform: scaleY(0.3) }
`;

export interface IETagRowProps extends HTMLAttributes<HTMLElement> {
  alternatingRowStyle: ISummaryStyles;
  children?: ReactNode;
  className?: string;
  'data-row-key'?: string;
  index?: number;
  onRemoveAnimationComplete?: (row: ETagRow) => void;
  onRowClick?: (rowKey: string) => void;
  record?: IETagDataSet;
  rowHighlightingStyle: ISummaryStyles;
  selectedRowKey: string | undefined;
  style?: CSSProperties;
  timeZone: TTimeZone;
  toEntity: IToEntity;
}

interface IETagRowState {
  alternatingRowStyle: string;
  animation?: Keyframes;
  animationDurationMilliseconds: number;
  rowHighlightingStyle: string;
}

class ETagRow extends Component<IETagRowProps, IETagRowState> {
  static contextType = AnimationContext;

  timeoutId?: number;

  constructor(props: IETagRowProps) {
    super(props);

    this.state = {
      alternatingRowStyle: unpackSummaryStyles(props.alternatingRowStyle),
      animation: undefined,
      animationDurationMilliseconds: 2000,
      rowHighlightingStyle: unpackSummaryStyles(props.rowHighlightingStyle),
    };
  }

  componentDidMount() {
    const { shouldAnimate } = this.context;
    const { onRemoveAnimationComplete, record } = this.props;
    const { animationDurationMilliseconds } = this.state;

    if (shouldAnimate) {
      if (record?.summaryAttributeRemoved || record?.summaryProfileRemoved) {
        this.setState({ animation: collapse });
        this.timeoutId = window.setTimeout(() => {
          if (onRemoveAnimationComplete) {
            onRemoveAnimationComplete(this);
          }
          this.setState({ animation: undefined });
        }, animationDurationMilliseconds);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  componentDidUpdate(prevProps: IETagRowProps) {
    const { shouldAnimate } = this.context;
    const {
      alternatingRowStyle,
      onRemoveAnimationComplete,
      record,
      rowHighlightingStyle,
    } = this.props;
    const { animation, animationDurationMilliseconds } = this.state;

    if (prevProps.alternatingRowStyle !== alternatingRowStyle) {
      this.setState({
        alternatingRowStyle: unpackSummaryStyles(alternatingRowStyle),
      });
    }

    if (prevProps.rowHighlightingStyle !== rowHighlightingStyle) {
      this.setState({
        rowHighlightingStyle: unpackSummaryStyles(rowHighlightingStyle),
      });
    }

    if (
      prevProps.record &&
      ((prevProps.record?.summaryAttributeRemoved &&
        !record?.summaryAttributeRemoved) ||
        (prevProps.record?.summaryProfileRemoved &&
          !record?.summaryProfileRemoved)) &&
      this.timeoutId
    ) {
      clearTimeout(this.timeoutId);
      this.setState({ animation: undefined });
    }

    if (shouldAnimate) {
      if (
        animation === undefined &&
        prevProps.record &&
        ((!prevProps.record?.summaryAttributeRemoved &&
          record?.summaryAttributeRemoved) ||
          (!prevProps.record?.summaryProfileRemoved &&
            record?.summaryProfileRemoved))
      ) {
        this.setState({ animation: collapse });
        this.timeoutId = window.setTimeout(() => {
          if (onRemoveAnimationComplete) {
            onRemoveAnimationComplete(this);
          }
          this.setState({ animation: undefined });
        }, animationDurationMilliseconds);
      }
    }
  }

  handleClick = () => {
    const { onRowClick, record, timeZone } = this.props;
    if (onRowClick !== undefined && record !== undefined) {
      onRowClick(getKeyForETagDataSet(record, timeZone));
    }
  };

  render() {
    const { children, record, selectedRowKey, timeZone } = this.props;
    const {
      alternatingRowStyle,
      animation,
      animationDurationMilliseconds,
      rowHighlightingStyle,
    } = this.state;

    const hasRecord: boolean = record !== undefined;
    const hasRowIndex: boolean = hasRecord && record!.rowIndex !== undefined;
    const rowStyle: string =
      hasRowIndex && record!.rowIndex! % 2 === 1 ? alternatingRowStyle : '';

    return (
      <AnimatedETagRow
        allowHoverHighlight={record !== undefined}
        animation={animation}
        duration={animationDurationMilliseconds}
        isSelected={
          record !== undefined &&
          selectedRowKey === getKeyForETagDataSet(record, timeZone)
        }
        onClick={this.handleClick}
        rowHighlightingStyle={rowHighlightingStyle}
        rowStyle={rowStyle}
      >
        {children}
      </AnimatedETagRow>
    );
  }
}

export default ETagRow;
