import { AxiosResponse } from 'axios';
import {
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_FAX_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_PHONE_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
} from 'constants/Detail';
import {
  ICON_BUTTON_SIZE_VALUE,
  VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { EPointTypeName } from 'enums/Point';
import { EViewMode } from 'enums/View';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IEditPhysicalPathInformation } from 'interfaces/Detail';
import { IEntityInfo } from 'interfaces/Entity';
import { IContactInfo, IContract, IMiscInfo } from 'interfaces/General';
import { IPointInfo, IRegistryPoint } from 'interfaces/Point';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { IDetailGenerationPhysicalSegment } from 'reduxes/Detail/types';
import { retrieveRegistryPoints } from 'services/naesb-registry/registry';
import { TDetailValidations } from 'types/Detail';
import { TToEntityId } from 'types/ToEntity';
import {
  getEditInfoKey,
  getSplitEditInfoKey,
  validateEditInfo,
} from 'utils/detail';
import {
  caEntityInfoEqual,
  entityInfoToString,
  entityInfoToUid,
  moEntityInfoEqual,
  pseEntityInfoEqual,
} from 'utils/entity';
import { captureError } from 'utils/error';
import {
  contactInfosEqual,
  contractsEqual,
  eventToStringOrNull,
  isEmptyValue,
  isSuccessStatus,
  miscInfosEqual,
  selectOptionLabelFilter,
  simpleEqualityChecker,
} from 'utils/general';
import {
  pointInfoEqual,
  pointInfoToString,
  pointInfoToUid,
  registryPointToPointInfoOption,
} from 'utils/point';
import {
  emptyRender,
  getColumnContactInfoEditRender,
  getColumnContactInfoRender,
  getColumnContractsEditRender,
  getColumnEntityInfoRender,
  getColumnMiscInfoEditRender,
  getColumnPointInfoRender,
  getColumnRender,
  getColumnSelectRender,
  getPhysicalPathContactInfoTitle,
  midSelectRender,
} from 'utils/views';

const getGenerationContactInfoEditId = (
  record: IDetailGenerationPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getGenerationContactInfoInitialEditKey = (
  record: IDetailGenerationPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getGenerationContactInfoPhoneEditId = (record: IContactInfo): string => {
  const { primaryId } = getSplitEditInfoKey(record.key);

  return getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_PHONE_LABEL,
    primaryId,
    0,
  );
};

const getGenerationContactInfoFaxEditId = (record: IContactInfo): string => {
  const { primaryId } = getSplitEditInfoKey(record.key);

  return getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_FAX_LABEL,
    primaryId,
    0,
  );
};

const getGenerationContractsEditId = (
  record: IDetailGenerationPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
    record.physical_segment_id,
    0,
  );

const getGenerationContractsInitialEditKey = (
  record: IDetailGenerationPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
    record.physical_segment_id,
    0,
  );

const getGenerationMiscInfoEditId = (
  record: IDetailGenerationPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getGenerationMiscInfoInitialEditKey = (
  record: IDetailGenerationPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const generationMidSelectRender =
  midSelectRender<IDetailGenerationPhysicalSegment>();

const generationMoSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IDetailGenerationPhysicalSegment
>('100%');

const sourceSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IDetailGenerationPhysicalSegment
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const useGenerationEditColumns = (
  detailValidations: TDetailValidations,
  focusKey: string | null,
  gca: IEntityInfo | null,
  pse: IEntityInfo | null,
  initialGenerationPhysicalSegment:
    | IDetailGenerationPhysicalSegment[]
    | undefined,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isDetailValidating: boolean,
  isUnconstrained: boolean,
  midOptions: IOption<number>[],
  moOptions: IOption<IEntityInfo>[],
  onChange: (editPhysicalPathInformation: IEditPhysicalPathInformation) => void,
  previousIsDetailDeleted: boolean | undefined,
  previousIsDetailUpdating: boolean | undefined,
  previousIsDetailValidating: boolean | undefined,
  previousMidOptions: IOption<number>[] | undefined,
  previousMoOptions: IOption<IEntityInfo>[] | undefined,
  previousDetailValidations: Record<string, boolean> | undefined,
  setDetailValidation: (id: string, isValid: boolean) => void,
  toEntityId: TToEntityId,
  viewMode: EViewMode,
  isDisabled?: boolean,
) => {
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;
  const hasDetailValidated: boolean =
    isDetailValidating !== previousIsDetailValidating ||
    detailValidations !== previousDetailValidations;
  const isDetailDisabled: boolean = isDetailDeleted || isDetailUpdating;

  const getMidOptions = useCallback(async () => midOptions, [midOptions]);

  const getInitialGenerationMidSelectValue = useCallback(
    (): number | null =>
      initialGenerationPhysicalSegment === undefined ||
      initialGenerationPhysicalSegment[0] === undefined
        ? null
        : initialGenerationPhysicalSegment[0].market_segment_id,
    [initialGenerationPhysicalSegment],
  );

  const generationMidOnChange = useCallback(
    (value: number | null | undefined) => {
      onChange({
        editGenerationPhysicalSegment: {
          market_segment_id: value === undefined ? null : value,
        },
      });
    },
    [onChange],
  );

  const getMoOptions = useCallback(async () => moOptions, [moOptions]);

  const getInitialGenerationMoSelectValue = useCallback(
    (): IEntityInfo | null =>
      initialGenerationPhysicalSegment === undefined ||
      initialGenerationPhysicalSegment[0] === undefined
        ? null
        : initialGenerationPhysicalSegment[0].mo_code,
    [initialGenerationPhysicalSegment],
  );

  const generationMoOnChange = useCallback(
    (value: IEntityInfo | null | undefined) => {
      onChange({
        editGenerationPhysicalSegment: {
          mo_code: value === undefined ? null : value,
        },
      });
    },
    [onChange],
  );

  const shouldGetSourceOptions = useCallback(
    (
      record: IDetailGenerationPhysicalSegment,
      previousRecord: IDetailGenerationPhysicalSegment | undefined,
    ): boolean =>
      previousRecord === undefined ||
      !caEntityInfoEqual(record.gca, previousRecord.gca) ||
      !pseEntityInfoEqual(record.pse, previousRecord.pse),
    [],
  );

  const getSourceOptions = useCallback(async () => {
    if (gca !== null || pse !== null) {
      try {
        const response: AxiosResponse<IRegistryPoint[]> =
          await retrieveRegistryPoints(
            toEntityId,
            EPointTypeName.Source,
            gca?.tagging_entity_id,
            pse?.tagging_entity_id,
          );

        if (!isSuccessStatus(response.status)) {
          throw new Error(response.statusText);
        }

        const registryPoints: IRegistryPoint[] = response.data;

        return registryPoints.map(registryPointToPointInfoOption);
      } catch (error: any) {
        captureError(error);
      }
    }

    return [];
  }, [gca, pse, toEntityId]);

  const previousGetSourceOptions = usePrevious(getSourceOptions);

  const getInitialGenerationSourceSelectValue = useCallback(
    (): IPointInfo | null =>
      initialGenerationPhysicalSegment === undefined ||
      initialGenerationPhysicalSegment[0] === undefined
        ? null
        : initialGenerationPhysicalSegment[0].generation_source,
    [initialGenerationPhysicalSegment],
  );

  const generationSourceOnChange = useCallback(
    (value: IPointInfo | null | undefined) => {
      onChange({
        editGenerationPhysicalSegment: {
          generation_source: value === undefined ? null : value,
        },
      });
    },
    [onChange],
  );

  const contractsEditRender = useMemo(
    () =>
      getColumnContractsEditRender<IDetailGenerationPhysicalSegment>(
        hasDetailChanged,
      ),
    [hasDetailChanged],
  );

  const getInitialGenerationContractData = useCallback(
    (): IContract[] | null =>
      initialGenerationPhysicalSegment === undefined ||
      initialGenerationPhysicalSegment[0] === undefined
        ? null
        : initialGenerationPhysicalSegment[0].contracts,
    [initialGenerationPhysicalSegment],
  );

  const generationContractOnAdd = useCallback(
    (value: unknown, contractRecord: IContract) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContracts: {
            addAfterContract: contractRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const generationContractOnRemove = useCallback(
    (contractRecord: IContract) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContracts: {
            removeContract: contractRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialGenerationContractInputValue = useCallback(
    (contractRecord: IContract): string | null => {
      if (
        initialGenerationPhysicalSegment === undefined ||
        initialGenerationPhysicalSegment[0] === undefined ||
        initialGenerationPhysicalSegment[0].contracts === null
      ) {
        return null;
      }

      const initialContract: IContract | undefined =
        initialGenerationPhysicalSegment[0].contracts.find(
          (contract: IContract): boolean => contract.key === contractRecord.key,
        );

      return initialContract === undefined ? null : initialContract.contract;
    },
    [initialGenerationPhysicalSegment],
  );

  const generationContractInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, contractRecord: IContract) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContracts: {
            editContractMap: {
              [contractRecord.key]: { contract: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const contactInfoEditRender = useMemo(
    () =>
      getColumnContactInfoEditRender<IDetailGenerationPhysicalSegment>(
        hasDetailChanged || hasDetailValidated,
      ),
    [hasDetailChanged, hasDetailValidated],
  );

  const getInitialGenerationContactData = useCallback(
    (): IContactInfo[] | null =>
      initialGenerationPhysicalSegment === undefined ||
      initialGenerationPhysicalSegment[0] === undefined
        ? null
        : initialGenerationPhysicalSegment[0].contactInfos,
    [initialGenerationPhysicalSegment],
  );

  const generationContactOnRemove = useCallback(
    (contactInfoRecord: IContactInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContactInfos: { editContactInfo: null },
        },
      });
    },
    [onChange],
  );

  const getInitialGenerationContactInputValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      if (
        initialGenerationPhysicalSegment === undefined ||
        initialGenerationPhysicalSegment[0] === undefined ||
        initialGenerationPhysicalSegment[0].contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialGenerationPhysicalSegment[0].contactInfos.find(
          (contactInfo: IContactInfo): boolean =>
            contactInfo.key === contactInfoRecord.key,
        );

      return initialContactInfo === undefined
        ? null
        : initialContactInfo.contact;
    },
    [initialGenerationPhysicalSegment],
  );

  const generationContactInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, contactInfoRecord: IContactInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContactInfos: {
            editContactInfo: { contact: eventToStringOrNull(event) },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialGenerationPhoneInputPhoneNumberValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      if (
        initialGenerationPhysicalSegment === undefined ||
        initialGenerationPhysicalSegment[0] === undefined ||
        initialGenerationPhysicalSegment[0].contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialGenerationPhysicalSegment[0].contactInfos.find(
          (contactInfo: IContactInfo): boolean =>
            contactInfo.key === contactInfoRecord.key,
        );

      return initialContactInfo === undefined ? null : initialContactInfo.phone;
    },
    [initialGenerationPhysicalSegment],
  );

  const generationPhoneInputOnChange = useCallback(
    (value: string | null, contactInfoRecord: IContactInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContactInfos: { editContactInfo: { phone: value } },
        },
      });
    },
    [onChange],
  );

  const getInitialGenerationFaxInputPhoneNumberValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      if (
        initialGenerationPhysicalSegment === undefined ||
        initialGenerationPhysicalSegment[0] === undefined ||
        initialGenerationPhysicalSegment[0].contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialGenerationPhysicalSegment[0].contactInfos.find(
          (contactInfo: IContactInfo): boolean =>
            contactInfo.key === contactInfoRecord.key,
        );

      return initialContactInfo === undefined ? null : initialContactInfo.fax;
    },
    [initialGenerationPhysicalSegment],
  );

  const generationFaxInputOnChange = useCallback(
    (value: string | null, contactInfoRecord: IContactInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editContactInfos: { editContactInfo: { fax: value } },
        },
      });
    },
    [onChange],
  );

  const miscInfoEditRender =
    getColumnMiscInfoEditRender<IDetailGenerationPhysicalSegment>(isDisabled);

  const getInitialGenerationMiscInfoData = useCallback(
    (record: IDetailGenerationPhysicalSegment): IMiscInfo[] | null =>
      initialGenerationPhysicalSegment === undefined ||
      initialGenerationPhysicalSegment[0] === undefined
        ? null
        : initialGenerationPhysicalSegment[0].misc_infos,
    [initialGenerationPhysicalSegment],
  );

  const generationMiscInfoOnAdd = useCallback(
    (value: unknown, miscInfoRecord: IMiscInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editMiscInfos: {
            addAfterMiscInfo: miscInfoRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const generationMiscInfoOnRemove = useCallback(
    (miscInfoRecord: IMiscInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editMiscInfos: {
            removeMiscInfo: miscInfoRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialGenerationTokenInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      if (
        initialGenerationPhysicalSegment === undefined ||
        initialGenerationPhysicalSegment[0] === undefined ||
        initialGenerationPhysicalSegment[0].misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialGenerationPhysicalSegment[0].misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.token;
    },
    [initialGenerationPhysicalSegment],
  );

  const generationTokenInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editMiscInfos: {
            editMiscInfoMap: {
              [miscInfoRecord.key]: { token: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialGenerationValueInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      if (
        initialGenerationPhysicalSegment === undefined ||
        initialGenerationPhysicalSegment[0] === undefined ||
        initialGenerationPhysicalSegment[0].misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialGenerationPhysicalSegment[0].misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.value;
    },
    [initialGenerationPhysicalSegment],
  );

  const generationValueInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      onChange({
        editGenerationPhysicalSegment: {
          editMiscInfos: {
            editMiscInfoMap: {
              [miscInfoRecord.key]: { value: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getIsGenerationPhoneValid = useCallback(
    (record: IContactInfo) => (isValid: boolean) => {
      setDetailValidation(getGenerationContactInfoPhoneEditId(record), isValid);
    },
    [setDetailValidation],
  );

  const getIsGenerationFaxValid = useCallback(
    (record: IContactInfo) => (isValid: boolean) => {
      setDetailValidation(getGenerationContactInfoFaxEditId(record), isValid);
    },
    [setDetailValidation],
  );

  const getIsGenerationContactInfoValid = useCallback(
    (record: IDetailGenerationPhysicalSegment): boolean =>
      validateEditInfo(
        getGenerationContactInfoEditId(record),
        detailValidations,
      ),
    [detailValidations],
  );

  return useMemo(() => {
    const midOptionsChanged: boolean = midOptions !== previousMidOptions;
    const moOptionsChanged: boolean = moOptions !== previousMoOptions;
    const sourceOptionsChanged: boolean =
      getSourceOptions !== previousGetSourceOptions;

    return [
      {
        dataIndex: 'physical_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'PID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'market_segment_id',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnRender(isUnconstrained)
            : generationMidSelectRender({
                allowClear: true,
                equalityChecker: simpleEqualityChecker,
                getInitialValue: getInitialGenerationMidSelectValue,
                getOptions: getMidOptions,
                isDisabled: isDetailDisabled,
                onChange: generationMidOnChange,
                valueToUid: (value: number | null | undefined): string =>
                  isEmptyValue(value) ? '' : value!.toString(),
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailGenerationPhysicalSegment,
                previousRecord: IDetailGenerationPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                midOptionsChanged ||
                record.market_segment_id !== previousRecord.market_segment_id,
        title: 'MID',
        width: VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'gca',
        render: getColumnRender(isUnconstrained, entityInfoToString),
        title: 'GCA',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'mo_code',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnEntityInfoRender(isUnconstrained)
            : generationMoSelectRender({
                allowClear: true,
                equalityChecker: moEntityInfoEqual,
                filter: selectOptionLabelFilter,
                getInitialValue: getInitialGenerationMoSelectValue,
                getOptions: getMoOptions,
                isDisabled: isDetailDisabled,
                onChange: generationMoOnChange,
                showSearch: true,
                valueToUid: entityInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailGenerationPhysicalSegment,
                previousRecord: IDetailGenerationPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                moOptionsChanged ||
                (record.mo_code === null
                  ? previousRecord.mo_code !== null
                  : previousRecord.mo_code === null
                  ? true
                  : !(
                      record.mo_code.tagging_entity_id ===
                        previousRecord.mo_code.tagging_entity_id &&
                      record.mo_code.entity_type ===
                        previousRecord.mo_code.entity_type
                    )),
        title: 'MO',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'pse',
        render: getColumnRender(isUnconstrained, entityInfoToString),
        title: 'GPE',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH,
      },
      {
        dataIndex: 'generation_source',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnPointInfoRender(isUnconstrained)
            : sourceSelectRender({
                allowClear: true,
                filter: selectOptionLabelFilter,
                equalityChecker: pointInfoEqual,
                equalityCheckerForOptions: pointInfoEqual,
                getInitialValue: getInitialGenerationSourceSelectValue,
                getOptions: getSourceOptions,
                isDisabled: isDetailDisabled,
                onChange: generationSourceOnChange,
                showSearch: true,
                shouldGetOptions: shouldGetSourceOptions,
                valueToUid: pointInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailGenerationPhysicalSegment,
                previousRecord: IDetailGenerationPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                sourceOptionsChanged ||
                (record.generation_source === null
                  ? previousRecord.generation_source !== null
                  : previousRecord.generation_source === null
                  ? true
                  : !(
                      record.generation_source.point_id ===
                      previousRecord.generation_source.point_id
                    )),
        title: 'Source',
        width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contracts',
        render: contractsEditRender({
          contractInputColumnConfig: {
            getInitialValue: getInitialGenerationContractInputValue,
            getKey: (contractRecord: IContract): string => contractRecord.key,
            isDisabled: isDetailDisabled,
            onChange: generationContractInputOnChange,
            shouldFocusOn: (contractRecord: IContract): boolean =>
              contractRecord.key === focusKey,
          },
          equalityChecker: contractsEqual,
          getId: getGenerationContractsEditId,
          getInitialData: getInitialGenerationContractData,
          getInitialEditKey: getGenerationContractsInitialEditKey,
          getTableTitle: (record: IDetailGenerationPhysicalSegment): string =>
            `Edit Generation Physical Segment Contracts`,
          onAdd: generationContractOnAdd,
          onRemove: generationContractOnRemove,
        }),
        shouldCellUpdate: (
          record: IDetailGenerationPhysicalSegment,
          previousRecord: IDetailGenerationPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.contracts === null
            ? previousRecord.contracts !== null
            : previousRecord.contracts === null
            ? true
            : !contractsEqual(record.contracts, previousRecord.contracts)),
        title: 'Contracts',
        width: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contactInfos',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnContactInfoRender(
                isUnconstrained,
                undefined,
                getPhysicalPathContactInfoTitle,
              )
            : contactInfoEditRender({
                contactInputColumnConfig: {
                  getInitialValue: getInitialGenerationContactInputValue,
                  getKey: (contactInfoRecord: IContactInfo): string =>
                    contactInfoRecord.key,
                  isDisabled: isDetailDisabled,
                  onChange: generationContactInputOnChange,
                  shouldFocusOn: (contactInfoRecord: IContactInfo): boolean =>
                    contactInfoRecord.key === focusKey,
                },
                equalityChecker: contactInfosEqual,
                faxInputPhoneNumberColumnConfig: {
                  getInitialValue: getInitialGenerationFaxInputPhoneNumberValue,
                  getIsValid: getIsGenerationFaxValid,
                  isDisabled: isDetailDisabled,
                  onChange: generationFaxInputOnChange,
                },
                getId: getGenerationContactInfoEditId,
                getInitialData: getInitialGenerationContactData,
                getInitialEditKey: getGenerationContactInfoInitialEditKey,
                getIsValid: getIsGenerationContactInfoValid,
                getTableTitle: (
                  record: IDetailGenerationPhysicalSegment,
                ): string => `Edit Generation Physical Segment Contact Info`,
                showErrorMessageWhenInvalid: isDetailValidating,
                onRemove: generationContactOnRemove,
                phoneInputPhoneNumberColumnConfig: {
                  getInitialValue:
                    getInitialGenerationPhoneInputPhoneNumberValue,
                  getIsValid: getIsGenerationPhoneValid,
                  isDisabled: isDetailDisabled,
                  onChange: generationPhoneInputOnChange,
                },
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailGenerationPhysicalSegment,
                previousRecord: IDetailGenerationPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                hasDetailValidated ||
                (record.contactInfos === null
                  ? previousRecord.contactInfos !== null
                  : previousRecord.contactInfos === null
                  ? true
                  : !contactInfosEqual(
                      record.contactInfos,
                      previousRecord.contactInfos,
                    )),
        title: 'Contact',
        width: VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'misc_infos',
        render: miscInfoEditRender({
          editButtonWidth:
            viewMode === EViewMode.EditETagCorrection
              ? undefined
              : `${
                  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE -
                  ICON_BUTTON_SIZE_VALUE
                }px`,
          equalityChecker: miscInfosEqual,
          getId: getGenerationMiscInfoEditId,
          getInitialData: getInitialGenerationMiscInfoData,
          getInitialEditKey: getGenerationMiscInfoInitialEditKey,
          getTableTitle: (record: IDetailGenerationPhysicalSegment): string =>
            `Edit Generation Physical Segment Misc Info`,
          onAdd: generationMiscInfoOnAdd,
          onRemove: generationMiscInfoOnRemove,
          tokenInputColumnConfig: {
            getInitialValue: getInitialGenerationTokenInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: generationTokenInputOnChange,
            shouldFocusOn: (miscInfoRecord: IMiscInfo): boolean =>
              miscInfoRecord.key === focusKey,
          },
          valueInputColumnConfig: {
            getInitialValue: getInitialGenerationValueInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: generationValueInputOnChange,
          },
        }),
        shouldCellUpdate: (
          record: IDetailGenerationPhysicalSegment,
          previousRecord: IDetailGenerationPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.misc_infos === null
            ? previousRecord.misc_infos !== null
            : previousRecord.misc_infos === null
            ? true
            : !miscInfosEqual(record.misc_infos, previousRecord.misc_infos)),
        title: 'Misc Info',
        width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
      },
    ];
  }, [
    contactInfoEditRender,
    contractsEditRender,
    focusKey,
    generationContactInputOnChange,
    generationContactOnRemove,
    generationContractInputOnChange,
    generationContractOnAdd,
    generationContractOnRemove,
    generationFaxInputOnChange,
    generationMidOnChange,
    generationMiscInfoOnAdd,
    generationMiscInfoOnRemove,
    generationMoOnChange,
    generationPhoneInputOnChange,
    generationSourceOnChange,
    generationTokenInputOnChange,
    generationValueInputOnChange,
    getInitialGenerationContactData,
    getInitialGenerationContactInputValue,
    getInitialGenerationContractData,
    getInitialGenerationContractInputValue,
    getInitialGenerationFaxInputPhoneNumberValue,
    getInitialGenerationMidSelectValue,
    getInitialGenerationMiscInfoData,
    getInitialGenerationMoSelectValue,
    getInitialGenerationPhoneInputPhoneNumberValue,
    getInitialGenerationTokenInputValue,
    getInitialGenerationValueInputValue,
    getInitialGenerationSourceSelectValue,
    getIsGenerationContactInfoValid,
    getIsGenerationFaxValid,
    getIsGenerationPhoneValid,
    getMidOptions,
    getMoOptions,
    getSourceOptions,
    hasDetailChanged,
    hasDetailValidated,
    isDetailDisabled,
    isDetailValidating,
    isUnconstrained,
    midOptions,
    miscInfoEditRender,
    moOptions,
    previousMidOptions,
    previousMoOptions,
    previousGetSourceOptions,
    shouldGetSourceOptions,
    viewMode,
  ]);
};

export default useGenerationEditColumns;
