import { STANDARD_SPACING_VALUE, WARNING_ORANGE } from 'constants/styles';
import styled from 'styled-components';

const WARNING_MESSAGE_MAX_WIDTH = '238px';

interface IProps {
  maxWidth?: string;
  topMargin?: number;
}

const WarningMessage = styled.span<IProps>`
  color: ${WARNING_ORANGE};
  font-weight: bold;
  margin-top: ${(props) =>
    props.topMargin === undefined ? STANDARD_SPACING_VALUE : props.topMargin}px;
  max-width: ${(props) =>
    props.maxWidth === undefined ? WARNING_MESSAGE_MAX_WIDTH : props.maxWidth};
  text-align: left;
`;

export default WarningMessage;
