const SetStateStudyIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    viewBox='64 64 896 896'
    width='1.2em'
    height='1.2em'
  >
    <path d='M180 904 A 330 330 0 0 1 510 574' fill='none' strokeWidth='80' />
    <path d='M491 569 A 210 210 0 1 1 716 396' fill='none' strokeWidth='80' />
    <path
      d='M764 280.9c-14-30.6-33.9-58.1-59.3-81.6C653.1 151.4 584.6 125 512 125s-141.1 26.4-192.7 74.2c-25.4 23.6-45.3 51-59.3 81.7-14.6 32-22 65.9-22 100.9v27c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-27c0-99.5 88.6-180.4 197.6-180.4s197.6 80.9 197.6 180.4c0 40.8-14.5 79.2-42 111.2-27.2 31.7-65.6 54.4-108.1 64-24.3 5.5-46.2 19.2-61.7 38.8a110.85 110.85 0 00-23.9 68.6v31.4c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-31.4c0-15.7 10.9-29.5 26-32.9 58.4-13.2 111.4-44.7 149.3-88.7 19.1-22.3 34-47.1 44.3-74 10.7-27.9 16.1-57.2 16.1-87 0-35-7.4-69-22-100.9zM512 787c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56z'
      fill='currentColor'
      strokeWidth='98'
      strokeLinejoin='round'
      transform='scale(0.6) translate(750 640)'
    />
  </svg>
);

export default SetStateStudyIcon;
