import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { IContactInfo } from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { alternatingTableRowBackground } from 'utils/styles';
import { getContactInfoColumns } from 'utils/views';

const ViewDataTable = styled(
  (
    props: IDataTableProps<IViewDataTableColumn<IContactInfo>, IContactInfo> &
      IThemedProps,
  ) => DataTable<IViewDataTableColumn<IContactInfo>, IContactInfo>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}

  &&&& > * {
    .ant-table {
      margin: 0;
    }
  }
`;

interface IContactInfoDataTable {
  data: IContactInfo[];
  isUnconstrained: boolean;
}

const ContactInfoDataTable = ({
  data,
  isUnconstrained,
}: IContactInfoDataTable): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  const contactInfoColumns: IViewDataTableColumn<IContactInfo>[] = useMemo(
    () => getContactInfoColumns(isUnconstrained),
    [isUnconstrained],
  );

  return (
    <ViewDataTable
      columns={contactInfoColumns}
      currentTheme={currentTheme!}
      data={data}
      pagination={false}
    />
  );
};

export default ContactInfoDataTable;
