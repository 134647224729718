import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Spinner from 'components/atoms/Spinner/Spinner';
import { getAllDistributedTagItemStateLoadTransforms } from 'components/organisms/ETagManager/helpers';
import { IPageContentProps } from 'components/organisms/Page/Page';
import DetailExportView from 'components/pages/DetailPrintPage/DetailExportView/DetailExportView';
import PrintWidthTuner from 'components/pages/DetailPrintPage/PrintWidthTuner/PrintWidthTuner';
import {
  PAGE_LAYOUT_STYLES,
  PRINT_WIDTH_A4_PORTRAIT_VALUE,
  PRINT_WIDTH_TUNER_MAXIMUM_LEFT_OFFSET_VALUE,
} from 'constants/styles';
import { EDetailIdType, EProfileFormat } from 'enums/Detail';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import { IDistributedTagItemDetailStateLoadTransform } from 'interfaces/Detail';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  detailRetrieveETagDetail,
  detailRetrieveETagDistributedTagItems,
  detailSetETagDetailInitialParameters,
} from 'reduxes/Detail/actions';
import { IDetailState } from 'reduxes/Detail/types';
import { userSetSelectedToEntity } from 'reduxes/User/actions';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';
import { TETagDraftId, TETagTagPrimaryKey, TETagTemplateId } from 'types/ETag';
import { TStateLoadTransform } from 'types/General';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import {
  retrieveAndTransformETagDraftSummary,
  retrieveAndTransformETagTemplate,
  retrieveAndTransformRegistryEntities,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';

interface ILayoutProps {
  isShowingSpinner?: boolean;
}

const Layout = styled.div<ILayoutProps>`
  position: relative;

  ${(props) => (props.isShowingSpinner === true ? PAGE_LAYOUT_STYLES : '')}
`;

interface IDetailPrintPageContentProps extends IPageContentProps<undefined> {}

const retrieveDetailPrintPageContentState = (state: TRootState) => {
  const {
    draft_id,
    isDetailDeleted,
    retrievingDetail,
    retrievingDistributedTagItems,
    tag_primary_key,
    template_id,
    toEntity,
  } = state.detail.present;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegmentsProfiles]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[
        EDistributedTagItem.PhysicalSegmentsProfiles
      ].retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.Summary]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.Summary]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.TransactionStatuses]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.TransactionStatuses]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
        .retrieveState !== ERetreiveState.RetrievingCompleted);

  return {
    draft_id,
    isDetailDeleted,
    isDetailLoading,
    tag_primary_key,
    template_id,
    toEntity,
  };
};

const DetailPrintPageContent = ({
  userInfo,
}: IDetailPrintPageContentProps): JSX.Element => {
  const { toEntities } = userInfo;
  const dispatch = useDispatch();
  const {
    draft_id,
    isDetailDeleted,
    isDetailLoading,
    tag_primary_key,
    template_id,
    toEntity,
  } = useSelector(retrieveDetailPrintPageContentState);
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [printWidth, setPrintWidth] = useState<number>(
    PRINT_WIDTH_A4_PORTRAIT_VALUE,
  );
  const [tunerHeight, setTunerHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isDetailDeleted) {
      const eTagType: string | undefined = undefined;

      if (eTagType !== undefined) {
        setErrorMessage(`${eTagType} has been deleted.`);
      }
    }
  }, [isDetailDeleted]);

  useEffect(() => {
    if (toEntity !== null) {
      const foundToEntity = toEntities.find(
        (searchToEntity: IToEntity): boolean =>
          searchToEntity.to_entity === toEntity.to_entity,
      );

      if (foundToEntity !== undefined) {
        dispatch(userSetSelectedToEntity({ selectedToEntity: foundToEntity }));
      }
    }
  }, [dispatch, toEntities, toEntity]);

  const setETagDetailInitialParameters = useCallback(
    (
      toEntity: IToEntity,
      viewMode: EViewMode,
      draft_id: TETagDraftId,
      tag_primary_key?: TETagTagPrimaryKey,
      template_id?: TETagTemplateId,
      selectedProfileFormat?: EProfileFormat | undefined,
    ) => {
      dispatch(
        detailSetETagDetailInitialParameters({
          draft_id,
          pageMode: EPageMode.Review,
          tag_primary_key,
          template_id,
          toEntity,
          viewMode,
          selectedProfileFormat,
          isPrintView: true,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (toEntities.length > 0) {
      const query: URLSearchParams = new URLSearchParams(search);
      const toEntityId: TToEntityId | null = query.get('toEntity');
      const selectedProfileFormat: string | null = query.get(
        'selectedProfileFormat',
      );

      if (isEmptyValue(toEntityId)) {
        setErrorMessage('Missing query parameter "toEntity".');
      } else {
        const toEntity: IToEntity | undefined = toEntities.find(
          (toEntity: IToEntity): boolean => toEntity.to_entity === toEntityId,
        );

        if (toEntity === undefined) {
          setErrorMessage(
            `The current user does not have permission to access toEntity: ${toEntityId}`,
          );
        } else {
          const draftId: TETagDraftId = query.get(EDetailIdType.DraftId);
          const tagPrimaryKey: TETagTagPrimaryKey | null = query.get(
            EDetailIdType.TagPrimaryKey,
          );

          if (isEmptyValue(draftId)) {
            if (isEmptyValue(tagPrimaryKey)) {
              const templateId: TETagTemplateId | null = query.get(
                EDetailIdType.TemplateId,
              );

              if (isEmptyValue(templateId)) {
                setErrorMessage(
                  `Missing query parameter. "${EDetailIdType.DraftId}" or "${EDetailIdType.TagPrimaryKey}" or "${EDetailIdType.TemplateId}" must be included.`,
                );
              } else {
                setETagDetailInitialParameters(
                  toEntity!,
                  EViewMode.ReviewETagTemplate,
                  null,
                  undefined,
                  templateId!,
                  selectedProfileFormat as EProfileFormat,
                );
              }
            } else {
              setETagDetailInitialParameters(
                toEntity!,
                EViewMode.ReviewETag,
                null,
                tagPrimaryKey!,
                undefined,
                selectedProfileFormat as EProfileFormat,
              );
            }
          } else {
            setETagDetailInitialParameters(
              toEntity!,
              EViewMode.ReviewETagDraft,
              draftId,
              undefined,
              undefined,
              selectedProfileFormat as EProfileFormat,
            );
          }
        }
      }
    }
  }, [search, setETagDetailInitialParameters, toEntities]);

  const retrieveETagDetail = useCallback(
    (
      detailIdType: EDetailIdType,
      stateLoadTransform: TStateLoadTransform<IDetailState>[],
      shouldCaptureError?: boolean,
    ) =>
      dispatch(
        detailRetrieveETagDetail(
          detailIdType,
          stateLoadTransform,
          shouldCaptureError,
        ),
      ),
    [dispatch],
  );

  const retrieveETagDistributedTagItems = useCallback(
    (
      distributedTagItemDetailStateLoadTransform: IDistributedTagItemDetailStateLoadTransform[],
    ) =>
      dispatch(
        detailRetrieveETagDistributedTagItems(
          distributedTagItemDetailStateLoadTransform,
        ),
      ),
    [dispatch],
  );

  const updateETagDetail = useCallback(
    (shouldCaptureError?: boolean) => {
      if (toEntity !== null) {
        const toEntityId: TToEntityId = toEntity.to_entity;
        const detailStateLoadTransforms: TStateLoadTransform<IDetailState>[] =
          [];

        if (!isEmptyValue(draft_id)) {
          detailStateLoadTransforms.push(
            retrieveAndTransformRegistryEntities(toEntityId),
            retrieveAndTransformETagDraftSummary(toEntityId, draft_id),
          );

          retrieveETagDetail(
            EDetailIdType.DraftId,
            detailStateLoadTransforms,
            shouldCaptureError,
          );
        } else if (!isEmptyValue(tag_primary_key)) {
          detailStateLoadTransforms.push(
            retrieveAndTransformRegistryEntities(toEntityId),
          );
          retrieveETagDetail(
            EDetailIdType.TagPrimaryKey,
            detailStateLoadTransforms,
            shouldCaptureError,
          );
          retrieveETagDistributedTagItems(
            getAllDistributedTagItemStateLoadTransforms(
              toEntityId,
              tag_primary_key!,
            ),
          );
        } else if (!isEmptyValue(template_id)) {
          detailStateLoadTransforms.push(
            retrieveAndTransformRegistryEntities(toEntityId),
            retrieveAndTransformETagTemplate(toEntityId, template_id!),
          );

          retrieveETagDetail(
            EDetailIdType.TemplateId,
            detailStateLoadTransforms,
            shouldCaptureError,
          );
        }
      }
    },
    [
      draft_id,
      retrieveETagDetail,
      retrieveETagDistributedTagItems,
      tag_primary_key,
      template_id,
      toEntity,
    ],
  );

  useEffect(updateETagDetail, [updateETagDetail]);

  const handleResize = useCallback((width?: number, height?: number) => {
    if (height !== undefined) {
      setTunerHeight(height);
    }
  }, []);

  const handlePrintWidthTunerMove = useCallback((xDelta: number) => {
    setPrintWidth(PRINT_WIDTH_A4_PORTRAIT_VALUE + xDelta);
  }, []);

  const showSpinner: boolean = toEntity === null || isDetailLoading;

  return (
    <>
      <Layout isShowingSpinner={showSpinner}>
        {errorMessage === null ? (
          showSpinner ? (
            <Spinner />
          ) : (
            <>
              <PrintWidthTuner
                height={tunerHeight}
                initialXOffset={PRINT_WIDTH_A4_PORTRAIT_VALUE}
                maximumLeftOffset={PRINT_WIDTH_TUNER_MAXIMUM_LEFT_OFFSET_VALUE}
                onMove={handlePrintWidthTunerMove}
              />
              <DetailExportView onResize={handleResize} width={printWidth} />
            </>
          )
        ) : (
          <ErrorMessage maxWidth='100%'>{errorMessage}</ErrorMessage>
        )}
      </Layout>
    </>
  );
};

export default DetailPrintPageContent;
