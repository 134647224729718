import DetailView from 'components/molecules/DetailView/DetailView';
import MarketPathReview from 'components/organisms/MarketPathReview/MarketPathReview';
import { editMarketPathInformationToDetailState } from 'components/organisms/MarketPathView/helpers';
import MarketPathEdit from 'components/organisms/MarketPathView/MarketPathEdit';
import { IEditMarketPathInformation } from 'components/organisms/MarketPathView/types';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode, EViewResize } from 'enums/View';
import useInitialData from 'hooks/useInitialData';
import usePrevious from 'hooks/usePrevious';
import { IViewProps } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailEditETagDetail } from 'reduxes/Detail/actions';
import { IDetailMarketSegment } from 'reduxes/Detail/types';
import { TRootState } from 'types/Redux';

const retrieveMarketPathViewState = (state: TRootState) => {
  const {
    isDetailDeleted,
    marketSegments,
    pageMode,
    retrievingDetail,
    retrievingDistributedTagItems,
    updatingDetail,
    viewMode,
  } = state.detail.present;
  const isMarketSegmentLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    isMarketSegmentLoading;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  return {
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    isMarketSegmentLoading,
    marketSegments,
    pageMode,
    viewMode,
  };
};

const MarketPathView = ({
  layoutGrid,
  resize,
  viewId,
}: IViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    isMarketSegmentLoading,
    marketSegments,
    pageMode,
    viewMode,
  } = useSelector(retrieveMarketPathViewState);
  const previousIsDetailLoading: boolean | undefined =
    usePrevious(isDetailLoading);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);
  const previousIsMarketSegmentLoading: boolean | undefined = usePrevious(
    isMarketSegmentLoading,
  );
  const previousPageMode: EPageMode | undefined = usePrevious(pageMode);

  const hasMarketSegmentChanged = (): boolean =>
    (isDetailUpdating === false && previousIsDetailUpdating === true) ||
    (isMarketSegmentLoading === false &&
      previousIsMarketSegmentLoading === true) ||
    ((viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate) &&
      isDetailLoading === false &&
      previousIsDetailLoading === true) ||
    previousPageMode !== pageMode;

  const adjustedMarketSegments: IDetailMarketSegment[] = useMemo(
    () => (marketSegments === null ? [] : marketSegments),
    [marketSegments],
  );

  const initialMarketSegment: IDetailMarketSegment[] | undefined =
    useInitialData(adjustedMarketSegments, hasMarketSegmentChanged);

  const handleChange = useCallback(
    (editMarketPathInformation: IEditMarketPathInformation) =>
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editMarketPathInformationToDetailState(
            editMarketPathInformation,
          ),
        }),
      ),
    [dispatch],
  );

  return (
    <DetailView
      isLoading={isDetailLoading}
      layoutGrid={layoutGrid}
      resize={resize}
      title='Market Path'
      viewId={viewId}
      viewResizeSetting={EViewResize.Down}
    >
      {pageMode === EPageMode.Review ||
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ? (
        <MarketPathReview marketSegments={adjustedMarketSegments} />
      ) : pageMode === EPageMode.Edit ? (
        <MarketPathEdit
          initialMarketSegment={initialMarketSegment}
          isDisabled={isDetailDeleted || isDetailLoading || isDetailUpdating}
          marketSegments={adjustedMarketSegments}
          onChange={handleChange}
          previousIsDetailUpdating={previousIsDetailUpdating}
        />
      ) : null}
    </DetailView>
  );
};

export default MarketPathView;
