import SetStateApprovedIcon from 'components/molecules/SetStateIcon/SetStateApprovedIcon';
import SetStateDeniedIcon from 'components/molecules/SetStateIcon/SetStateDeniedIcon';
import SetStateStudyIcon from 'components/molecules/SetStateIcon/SetStateStudyIcon';
import { EApprovalStatus } from 'enums/Approval';

interface ISetStateIconProps {
  approvalStatus: EApprovalStatus;
}
const SetStateIcon = ({ approvalStatus }: ISetStateIconProps): JSX.Element => {
  switch (approvalStatus) {
    case EApprovalStatus.Approved:
      return <SetStateApprovedIcon />;
    case EApprovalStatus.Denied:
      return <SetStateDeniedIcon />;
    case EApprovalStatus.Study:
      return <SetStateStudyIcon />;
    default:
      throw new Error(
        `Unsupported approvalStatus for SetStateIcon: ${approvalStatus}`,
      );
  }
};

export default SetStateIcon;
