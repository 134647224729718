import { ClockCircleOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import { AxiosResponse } from 'axios';
import IconButton from 'components/atoms/IconButton/IconButton';
import { scheduledDraftSubmissionsSorter } from 'components/molecules/ReviewScheduledSubmissions/helpers';
import ReviewScheduledSubmissionsPopover from 'components/molecules/ReviewScheduledSubmissions/ReviewScheduledSubmissionsPopover';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import {
  IETagScheduledDraftResponse,
  IETagScheduledDraftSubmission,
} from 'interfaces/ETag';
import { useMemo, useState } from 'react';
import { getScheduledSubmissionForDraft } from 'services/agent/tags/requests';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { encodeIds, isSuccessStatus } from 'utils/general';

const ScheduleIcon = styled(ClockCircleOutlined)`
  color: ${(props) => props.color};
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IReviewScheduledSubmissionsProps {
  encodedPermissionsId: string;
  isDisabled: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ReviewScheduledSubmissions = ({
  encodedPermissionsId,
  isDisabled,
  timeZone,
  toEntityId,
}: IReviewScheduledSubmissionsProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scheduledSubmissions, setScheduledSubmissions] = useState<
    IETagScheduledDraftSubmission[] | null
  >(null);

  useAsyncEffect(async () => {
    if (isVisible || scheduledSubmissions === null) {
      setIsLoading(true);
      const response: AxiosResponse<IETagScheduledDraftResponse> =
        await getScheduledSubmissionForDraft(toEntityId);
      const scheduleDraftSubmissionResponse: IETagScheduledDraftResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(scheduleDraftSubmissionResponse.errorMessage!);
      }
      setScheduledSubmissions(scheduleDraftSubmissionResponse.response);
    }
    setIsLoading(false);
  }, [isVisible, timeZone]);

  const sortedScheduledSubmissions: IETagScheduledDraftSubmission[] = useMemo(
    () =>
      scheduledSubmissions !== null && scheduledSubmissions.length > 0
        ? [...scheduledSubmissions].sort(scheduledDraftSubmissionsSorter)
        : [],
    [scheduledSubmissions],
  );

  return (
    <Tooltip
      title={
        isLoading || sortedScheduledSubmissions.length > 0
          ? 'Scheduled Draft Submissions'
          : 'No drafts are scheduled for submission at this time.'
      }
      isDisabled={isVisible}
    >
      <AntDesignPopover
        content={
          <ReviewScheduledSubmissionsPopover
            data={sortedScheduledSubmissions}
            isLoading={isLoading}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<ScheduleIcon />}
          isDisabled={isDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ReviewScheduledSubmissions;
