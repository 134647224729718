import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { ALERT_SOUND_MAP } from 'components/hocs/withAlertsQueue/constants';
import SeverityIcon from 'components/molecules/SeverityIcon/SeverityIcon';
import { ALERT_SEVERITY_MAP } from 'constants/Alert';
import { EAlertSound } from 'enums/Alert';
import { ESeverity } from 'enums/General';
import { useEffect } from 'react';
import styled from 'styled-components';
import { TAlertGroup } from 'types/Alert';
import useSound from 'use-sound';

const AlertTitleContainer = styled(SeparatedRowLayout)`
  font-size: 13px;
  width: 100%;

  > .anticon {
    font-size: 18px;
  }
`;

interface IProps {
  className?: string;
  group: TAlertGroup;
  name: string;
  severity: ESeverity;
  soundEffect?: EAlertSound;
  soundPlayRepetitions?: number;
}

const AlertTitle = (props: IProps): JSX.Element => {
  const [playAlarm, alarmData] = useSound(ALERT_SOUND_MAP[EAlertSound.Alarm], {
    interrupt: true,
  });
  const [playBeep, beepData] = useSound(ALERT_SOUND_MAP[EAlertSound.Beep], {
    interrupt: true,
  });
  const {
    className,
    group,
    name,
    severity,
    soundEffect,
    soundPlayRepetitions,
  } = props;

  useEffect(() => {
    const handleSoundEffect = (
      repetitions: number,
      soundEffect?: EAlertSound,
    ) => {
      let count: number = repetitions;
      let duration: number = 0;

      if (count > 0) {
        if (soundEffect === EAlertSound.Alarm) {
          duration = alarmData.duration!;

          playAlarm();
        } else if (soundEffect === EAlertSound.Beep) {
          duration = beepData.duration!;

          playBeep();
        }

        window.setTimeout(() => {
          handleSoundEffect(count - 1, soundEffect);
        }, duration);
      }
    };

    if (soundEffect !== undefined && soundPlayRepetitions !== undefined) {
      handleSoundEffect(soundPlayRepetitions, soundEffect);
    }
  }, [
    alarmData.duration,
    beepData.duration,
    playAlarm,
    playBeep,
    soundEffect,
    soundPlayRepetitions,
  ]);

  return (
    <AlertTitleContainer className={className}>
      <SeverityIcon severity={severity} />
      <span>{ALERT_SEVERITY_MAP[severity]}:</span>
      <span>{`${name}: ${group}`}</span>
    </AlertTitleContainer>
  );
};

export default AlertTitle;
