import {
  BINARY_OPERATORS,
  LIST_CUSTOM_FILTER_TYPE,
  MISC_INFO_MARKET_SEGMENT_ATTRIBUTES,
  MISC_INFO_PHYSICAL_SEGMENT_ATTRIBUTES,
  MISC_INFO_TOKEN_ATTRIBUTES,
  MISC_INFO_TRANS_ALLOC_ATTRIBUTES,
  MISC_INFO_VALUE_ATTRIBUTES,
  OPERANDS,
  STRING_CUSTOM_FILTER_TYPE,
  UNARY_OPERATORS,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/constants';
import {
  EAttribute,
  EList,
  EOperator,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { ECustomFilterType } from 'enums/Filter';
import { IOption } from 'interfaces/Component';

export const isOperand = (type: ECustomFilterType | null): boolean =>
  type === null ? false : OPERANDS.includes(type);

export const isUnaryOperator = (type: ECustomFilterType | null): boolean =>
  type === null ? false : UNARY_OPERATORS.includes(type);

export const isBinaryOperator = (type: ECustomFilterType | null): boolean =>
  type === null ? false : BINARY_OPERATORS.includes(type);

export const operatorToColour = (operator: EOperator): string | undefined =>
  'purple';

export const operatorToDisplayString = (
  operator: EOperator,
  options: IOption<EOperator>[],
): string => {
  const operatorOption: IOption<EOperator> | undefined = options.find(
    (option: IOption<EOperator>): boolean => option.value === operator,
  );

  if (operatorOption === undefined) {
    throw new Error(`Invalid operator: ${operator}`);
  }

  return operatorOption.label;
};

export const operatorToUid = (operator: EOperator): string =>
  operator as string;

export const uidToOperator = (uid: string): EOperator => uid as EOperator;

export const typeToOperator = (
  type: ECustomFilterType | null,
): EOperator | undefined => {
  if (type === ECustomFilterType.And) {
    return EOperator.And;
  } else if (type === ECustomFilterType.Not) {
    return EOperator.Not;
  } else if (type === ECustomFilterType.Or) {
    return EOperator.Or;
  }

  return undefined;
};

export const attributeToUid = (attribute: EAttribute): string =>
  attribute as string;

export const isStringCustomFilterType = (
  customFilterType: ECustomFilterType,
): boolean => STRING_CUSTOM_FILTER_TYPE.includes(customFilterType);

export const isListCustomFilterType = (
  customFilterType: ECustomFilterType,
): boolean => LIST_CUSTOM_FILTER_TYPE.includes(customFilterType);

export const listToColour = (): string | undefined => 'cyan';

export const listToDisplayString = (
  list: EList,
  options: IOption<EList>[],
): string => {
  const listOption: IOption<EList> | undefined = options.find(
    (option: IOption<EList>): boolean => option.value === list,
  );

  if (listOption === undefined) {
    throw new Error(`Invalid list: ${list}`);
  }

  return listOption.label;
};

export const listToUid = (list: EList): string => list as string;

export const uidToList = (uid: string): EList => uid as EList;

export const isMiscInfoTokenAttribute = (attribute: EAttribute): boolean =>
  MISC_INFO_TOKEN_ATTRIBUTES.includes(attribute);

export const isMiscInfoValueAttribute = (attribute: EAttribute): boolean =>
  MISC_INFO_VALUE_ATTRIBUTES.includes(attribute);

export const isMiscInfoMarketSegmentAttribute = (
  attribute: EAttribute,
): boolean => MISC_INFO_MARKET_SEGMENT_ATTRIBUTES.includes(attribute);

export const isMiscInfoPhysicalSegmentAttribute = (
  attribute: EAttribute,
): boolean => MISC_INFO_PHYSICAL_SEGMENT_ATTRIBUTES.includes(attribute);

export const isMiscInfoTransAllocAttribute = (attribute: EAttribute): boolean =>
  MISC_INFO_TRANS_ALLOC_ATTRIBUTES.includes(attribute);
