import { TConfigAction } from './types';

export enum EConfigActions {
  RetrieveConfigStart = 'RETRIEVE_CONFIG_START',
  RetrieveConfigSuccess = 'RETRIEVE_CONFIG_SUCCESS',
  RetrieveConfigFailure = 'RETRIEVE_CONFIG_FAILURE',
}

export const retrieveConfigStart = (): TConfigAction => ({
  type: EConfigActions.RetrieveConfigStart,
});
export const retrieveConfigSuccess = (payload: any): TConfigAction => ({
  payload: payload,
  type: EConfigActions.RetrieveConfigSuccess,
});

export const retrieveConfigFailure = (payload: any): TConfigAction => ({
  payload: payload,
  type: EConfigActions.RetrieveConfigFailure,
});
