import { getColumnsForMarket } from 'components/organisms/MarketInformation/useMarketInformationColumns/helpers';
import { EMarketInfoMarket } from 'enums/ETag';
import { IEditMarketInfo } from 'interfaces/Detail';
import {
  IETagMarketInfo,
  IETagMisoMarketData,
  IETagSppMarketData,
} from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { ZonedDateTime } from 'utils/zonedDateTime';

const useMarketInformationColumns = (
  market: EMarketInfoMarket,
  marketDate: ZonedDateTime,
  isEditable: boolean,
  isUnconstrained: boolean,
  onChange: (editMarketInfo: IEditMarketInfo) => void,
  initialMarketInfos?: IETagMarketInfo[],
  isDisabled?: boolean,
):
  | IViewDataTableColumn<IETagMisoMarketData>[]
  | IViewDataTableColumn<IETagSppMarketData>[] => {
  return useMemo(
    () =>
      getColumnsForMarket(
        market,
        marketDate,
        isEditable,
        isUnconstrained,
        onChange,
        initialMarketInfos,
        isDisabled,
      ),
    [
      initialMarketInfos,
      isDisabled,
      isEditable,
      isUnconstrained,
      market,
      marketDate,
      onChange,
    ],
  );
};

export default useMarketInformationColumns;
