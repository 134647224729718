import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import IconButton, {
  IIconButtonProps,
} from 'components/atoms/IconButton/IconButton';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  BUTTON_ICON_DIMENSIONS,
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
  VIEW_EDIT_BUTTON_Z_INDEX,
} from 'constants/styles';
import usePrevious from 'hooks/usePrevious';
import { IExpandableRowProps } from 'interfaces/Component';
import { IOffset } from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import {
  TViewDataTableColumnRender,
  TViewDataTableShouldCellUpdate,
} from 'types/View';
import {
  alternatingTableRowBackground,
  hideExpandIconColumn,
} from 'utils/styles';

const AddIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const RemoveIcon = styled(MinusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IEditButtonProps extends IIconButtonProps {
  offset?: IOffset;
}

const AddButton = styled(IconButton)<IEditButtonProps>`
  position: absolute;
  z-index: ${VIEW_EDIT_BUTTON_Z_INDEX};

  ${BUTTON_ICON_DIMENSIONS}
  ${(props) => (props.offset === undefined ? '' : { ...props.offset })}
`;

const RemoveButton = styled(IconButton)<IEditButtonProps>`
  position: absolute;
  z-index: ${VIEW_EDIT_BUTTON_Z_INDEX};

  ${BUTTON_ICON_DIMENSIONS}
  ${(props) => (props.offset === undefined ? '' : { ...props.offset })}
`;

const CellAnchor = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const StyledDataTable = styled((props) => <DataTable {...props} />)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}

  ${(props) => alternatingTableRowBackground(props)}
  ${(props) => hideExpandIconColumn(props)}
`;

export interface IEditableDataTableProps<T>
  extends IDataTableProps<IViewDataTableColumn<T>, T>,
    IExpandableRowProps {
  addButtonOffset?: IOffset;
  allowRowAdd?: (
    value: unknown,
    record: T,
    data: T[],
    index: number,
  ) => boolean;
  allowRowRemove?: (
    value: unknown,
    record: T,
    data: T[],
    index: number,
  ) => boolean;
  hideExpandIconColumn?: boolean;
  initialiser?: () => T;
  isDisabled?: boolean;
  maximumAllowableAdds: number;
  onAdd?: (value: unknown, record: T, index: number) => void;
  onRemove?: (record: T, index: number) => void;
  removeButtonOffset?: IOffset;
}

const EditableDataTable = <T extends any>({
  addButtonOffset,
  allowRowAdd,
  allowRowRemove,
  columns,
  data,
  initialiser,
  isDisabled,
  maximumAllowableAdds,
  onAdd,
  onRemove,
  removeButtonOffset,
  ...rest
}: IEditableDataTableProps<T>): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const previousData: T[] | undefined = usePrevious(data);

  const { adjustedColumns } = useMemo(() => {
    const adjustedColumns: IViewDataTableColumn<T>[] = columns.map(
      (column: IViewDataTableColumn<T>): IViewDataTableColumn<T> => ({
        ...column,
      }),
    );

    if (adjustedColumns.length > 0) {
      const lastColumn: IViewDataTableColumn<T> =
        adjustedColumns[adjustedColumns.length - 1];
      const renderForLastColumn: TViewDataTableColumnRender<T> =
        lastColumn.render;
      const shouldCellUpdateForLastColumn:
        | TViewDataTableShouldCellUpdate<T>
        | undefined = lastColumn.shouldCellUpdate;

      lastColumn.render = (value: unknown, record: T, index: number) => {
        const includeAddButton: boolean =
          onAdd !== undefined &&
          (maximumAllowableAdds === -1 || index + 1 < maximumAllowableAdds) &&
          (allowRowAdd === undefined ||
            allowRowAdd(value, record, data, index));
        const includeRemoveButton: boolean =
          onRemove !== undefined &&
          (allowRowRemove === undefined ||
            allowRowRemove(value, record, data, index));

        const handleAdd = () => {
          if (onAdd) {
            onAdd(value, record, index);
          }
        };

        const handleRemove = () => {
          if (onRemove) {
            onRemove(record, index);
          }
        };

        return includeAddButton || includeRemoveButton ? (
          <CellAnchor>
            {renderForLastColumn(value, record, index)}
            {includeRemoveButton ? (
              <RemoveButton
                icon={<RemoveIcon />}
                isContained={true}
                isDisabled={isDisabled}
                noBorder={true}
                offset={removeButtonOffset}
                onClick={handleRemove}
                tabIndex={-1}
                transparentBackground={true}
              />
            ) : null}
            {includeAddButton ? (
              <AddButton
                icon={<AddIcon />}
                isContained={true}
                isDisabled={isDisabled || data.length === 0}
                noBorder={true}
                offset={addButtonOffset}
                onClick={handleAdd}
                tabIndex={-1}
              />
            ) : null}
          </CellAnchor>
        ) : (
          renderForLastColumn(value, record, index)
        );
      };

      lastColumn.shouldCellUpdate = (record: T, previousRecord: T): boolean => {
        let shouldCellUpdate: boolean = false;

        if (shouldCellUpdateForLastColumn !== undefined) {
          shouldCellUpdate = shouldCellUpdateForLastColumn(
            record,
            previousRecord,
          );
        }

        if (previousData !== undefined && previousData.length !== data.length) {
          shouldCellUpdate = shouldCellUpdate || true;
        }

        return shouldCellUpdate;
      };
    }

    return { adjustedColumns };
  }, [
    addButtonOffset,
    allowRowAdd,
    allowRowRemove,
    columns,
    data,
    isDisabled,
    maximumAllowableAdds,
    onAdd,
    onRemove,
    previousData,
    removeButtonOffset,
  ]);

  const adjustedData: T[] = useMemo(
    () =>
      data.length === 0 && maximumAllowableAdds !== 0 && initialiser
        ? [initialiser()]
        : data,
    [data, initialiser, maximumAllowableAdds],
  );

  return (
    <StyledDataTable
      {...rest}
      columns={adjustedColumns}
      currentTheme={currentTheme!}
      data={adjustedData}
    />
  );
};

export default EditableDataTable;
