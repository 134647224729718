import {
  isBinaryOperator,
  isUnaryOperator,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/helpers';
import { EOperation } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { ECustomFilterType } from 'enums/Filter';
import { ICustomFilter } from 'interfaces/Filter';
import { copyCustomFilter, generateCustomFilter } from 'utils/filter';

export const updateCustomFilterForOperation = (
  customFilter: ICustomFilter,
  operation: EOperation,
): ICustomFilter => {
  const { type } = customFilter;
  let updatedCustomFilter: ICustomFilter = customFilter;

  if (operation === EOperation.Criteria) {
    if (type === null) {
      updatedCustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.type = ECustomFilterType.Equals;
    } else if (isUnaryOperator(type)) {
      updatedCustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.sub_filter_list = null;
      updatedCustomFilter.sub_filter = generateCustomFilter();
      updatedCustomFilter.sub_filter.type = ECustomFilterType.Equals;
    } else if (isBinaryOperator(type)) {
      updatedCustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.sub_filter = null;

      if (updatedCustomFilter.sub_filter_list === null) {
        updatedCustomFilter.sub_filter_list = [];
      }

      const newCustomFilter: ICustomFilter = generateCustomFilter();

      newCustomFilter.type = ECustomFilterType.Equals;

      updatedCustomFilter.sub_filter_list.push(newCustomFilter);
    } else {
      throw new Error(`Invalid type: ${type} for operation: criteria`);
    }
  } else if (operation === EOperation.Operator) {
    if (type === null) {
      updatedCustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.type = ECustomFilterType.And;
      updatedCustomFilter.sub_filter_list = [];
    } else if (isUnaryOperator(type)) {
      updatedCustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.sub_filter_list = null;
      updatedCustomFilter.sub_filter = generateCustomFilter();
      updatedCustomFilter.sub_filter.type = ECustomFilterType.And;
    } else if (isBinaryOperator(type)) {
      updatedCustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.sub_filter = null;

      if (updatedCustomFilter.sub_filter_list === null) {
        updatedCustomFilter.sub_filter_list = [];
      }

      const newCustomFilter: ICustomFilter = generateCustomFilter();

      newCustomFilter.type = ECustomFilterType.And;

      updatedCustomFilter.sub_filter_list.push(newCustomFilter);
    }
  } else if (operation === EOperation.OperatorBefore) {
    updatedCustomFilter = copyCustomFilter(customFilter);

    updatedCustomFilter.type = ECustomFilterType.And;
    updatedCustomFilter.sub_filter = null;
    updatedCustomFilter.sub_filter_list = [customFilter];
  } else {
    throw new Error(`Invalid operation: ${operation}`);
  }

  return updatedCustomFilter;
};
