import { Input as AntDesignInput } from 'antd';
import { IThemedProps, IValueChanged } from 'interfaces/Component';
import { ChangeEvent } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { inputBackground, inputValueChanged } from 'utils/styles';

const { TextArea: AntDesignTextArea } = AntDesignInput;

const StyledTextArea = styled(
  ({
    currentTheme,
    valueChanged,
    ...rest
  }: IThemedProps & ITextAreaProps & IValueChanged) => (
    <AntDesignTextArea {...rest} />
  ),
)<IThemedProps & ITextAreaProps & IValueChanged>`
  ${(props) => inputBackground(props)}
  ${(props) => inputValueChanged(props)}
`;

export interface ITextAreaProps {
  className?: string;
  initialValue?: string | null;
  isDisabled?: boolean;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  value?: string;
}

const TextArea = ({
  className,
  initialValue,
  isDisabled,
  maxLength,
  onChange,
  placeholder,
  rows,
  value,
}: ITextAreaProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  return (
    <StyledTextArea
      className={className}
      currentTheme={currentTheme!}
      disabled={isDisabled}
      maxLength={maxLength}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      value={value}
      valueChanged={initialValue !== undefined && initialValue !== value}
    />
  );
};

export default TextArea;
