import {
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_LABEL,
  EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
  EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
} from 'constants/Detail';
import { ESeverity } from 'enums/General';
import { IValidationMessage } from 'interfaces/Component';
import {
  IEditGenerationPhysicalSegment,
  IEditLoadPhysicalSegment,
  IEditPhysicalPathInformation,
  IEditTransmissionPhysicalSegment,
  IEditTransmissionPhysicalSegments,
} from 'interfaces/Detail';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IETagPhysicalSegmentProfile,
  IETagPhysicalSegmentsProfile,
  IETagPhysicalSegmentsProfiles,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionPhysicalSegmentProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import {
  IContract,
  IEditContactInfos,
  IEditContracts,
  IEditMiscInfos,
  IEnergyProfile,
  IMiscInfo,
} from 'interfaces/General';
import { IPointInfo } from 'interfaces/Point';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailLossAccounting,
  IDetailMarketSegment,
  IDetailPhysicalSegment,
  IDetailState,
} from 'reduxes/Detail/types';
import { TDetailValidations } from 'types/Detail';
import { TStateTransform } from 'types/General';
import {
  copyDetailGenerationPhysicalSegment,
  copyDetailLoadPhysicalSegment,
  copyDetailLossAccounting,
  copyTransmissionAllocation,
  copyTransmissionPhysicalSegment,
  getEditInfoKey,
  getInitialContactInfo,
  getInitialContract,
  getInitialGenerationPhysicalSegment,
  getInitialLoadPhysicalSegment,
  getInitialMiscInfo,
  getInitialTransmissionPhysicalSegment,
  getInitialTransmissionSegment,
  getLoadProfileRef,
  getNextLoadPhysicalSegmentId,
  getNextLoadPhysicalSegmentKey,
  getPODProfileRef,
  getPORProfileRef,
  getSplitEditInfoKey,
  getTransmissionAllocationKey,
  getTransmissionPhysicalSegmentId,
  getTransmissionPhysicalSegmentKey,
  isValidGenerationPhysicalSegment,
  isValidLoadPhysicalSegment,
  isValidLossAccounting,
  isValidTransmissionAllocation,
  isValidTransmissionPhysicalSegment,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';

const initialiseOrCopyPhysicalSegment = (
  isGeneration: boolean,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = { ...detailState };
  const { tag_id } = updatedDetailState;

  if (isGeneration) {
    updatedDetailState.generationPhysicalSegment =
      updatedDetailState.generationPhysicalSegment === null
        ? getInitialGenerationPhysicalSegment(tag_id)
        : copyDetailGenerationPhysicalSegment(
            updatedDetailState.generationPhysicalSegment,
          );
  } else {
    updatedDetailState.loadPhysicalSegment =
      updatedDetailState.loadPhysicalSegment === null
        ? getInitialLoadPhysicalSegment(
            getNextLoadPhysicalSegmentId(
              updatedDetailState.transmission_physical_segments,
            ),
            tag_id,
          )
        : copyDetailLoadPhysicalSegment(updatedDetailState.loadPhysicalSegment);
  }

  return updatedDetailState;
};

const editPhysicalSegmentMarketSegmentId = (
  market_segment_id: number | null,
  isGeneration: boolean,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = initialiseOrCopyPhysicalSegment(
    isGeneration,
    detailState,
  );
  const detailPhysicalSegment: IDetailPhysicalSegment = isGeneration
    ? updatedDetailState.generationPhysicalSegment!
    : updatedDetailState.loadPhysicalSegment!;
  const foundMarketSegment: IDetailMarketSegment | undefined =
    detailState.marketSegments === null
      ? undefined
      : detailState.marketSegments.find(
          (marketSegment: IDetailMarketSegment): boolean =>
            marketSegment.market_segment_id === market_segment_id,
        );

  detailPhysicalSegment.market_segment_id = market_segment_id;
  detailPhysicalSegment.pse =
    foundMarketSegment === undefined ? null : foundMarketSegment.pse;

  return updatedDetailState;
};

const editPhysicalSegmentMoCode = (
  mo_code: IEntityInfo | null,
  isGeneration: boolean,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = initialiseOrCopyPhysicalSegment(
    isGeneration,
    detailState,
  );
  const detailPhysicalSegment: IDetailPhysicalSegment = isGeneration
    ? updatedDetailState.generationPhysicalSegment!
    : updatedDetailState.loadPhysicalSegment!;

  detailPhysicalSegment.mo_code = mo_code;

  return updatedDetailState;
};

const editPhysicalSegmentContactInfo = (
  editContactInfos: IEditContactInfos,
  isGeneration: boolean,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = initialiseOrCopyPhysicalSegment(
    isGeneration,
    detailState,
  );
  const detailPhysicalSegment: IDetailPhysicalSegment = isGeneration
    ? updatedDetailState.generationPhysicalSegment!
    : updatedDetailState.loadPhysicalSegment!;
  const { editContactInfo } = editContactInfos;

  if (editContactInfo === null) {
    detailPhysicalSegment.contactInfos = null;
  } else {
    detailPhysicalSegment.contactInfos = [
      {
        ...(detailPhysicalSegment.contactInfos === null
          ? getInitialContactInfo(
              getEditInfoKey(
                isGeneration
                  ? EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL
                  : EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
                detailPhysicalSegment.physical_segment_id,
                0,
              ),
            )
          : detailPhysicalSegment.contactInfos[0]),
        ...editContactInfo,
      },
    ];
  }

  updatedDetailState.focusKey = null;

  return updatedDetailState;
};

const editPhysicalSegmentContracts = (
  editContracts: IEditContracts,
  isGeneration: boolean,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = initialiseOrCopyPhysicalSegment(
    isGeneration,
    detailState,
  );
  const detailPhysicalSegment: IDetailPhysicalSegment = isGeneration
    ? updatedDetailState.generationPhysicalSegment!
    : updatedDetailState.loadPhysicalSegment!;
  const {
    addAfterContract,
    editContractMap: editContract,
    removeContract,
  } = editContracts;

  if (detailPhysicalSegment.contracts === null) {
    detailPhysicalSegment.contracts = [];
  }

  if (addAfterContract !== undefined) {
    let foundIndex: number = -1;
    let highestIndex: number = -1;

    detailPhysicalSegment.contracts.forEach(
      (contract: IContract, index: number) => {
        const { editIndex } = getSplitEditInfoKey(contract.key);

        if (editIndex === undefined) {
          throw new Error(`Invalid contract.key: ${contract.key}`);
        }

        if (editIndex > highestIndex) {
          highestIndex = editIndex;
        }

        if (contract.key === addAfterContract) {
          foundIndex = index;
        }
      },
    );

    if (foundIndex !== -1) {
      const newEditInfoKey: string = getEditInfoKey(
        isGeneration
          ? EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL
          : EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
        detailPhysicalSegment.physical_segment_id,
        highestIndex + 1,
      );

      detailPhysicalSegment.contracts.splice(
        foundIndex + 1,
        0,
        getInitialContract(newEditInfoKey),
      );

      updatedDetailState.focusKey = newEditInfoKey;
    }
  }

  if (editContract !== undefined) {
    for (let contractKey in editContract) {
      let contractIndex: number = detailPhysicalSegment.contracts.findIndex(
        (contract: IContract): boolean => contract.key === contractKey,
      );
      let updatedContract: IContract;

      if (contractIndex === -1) {
        updatedContract = getInitialContract(contractKey);
        contractIndex = detailPhysicalSegment.contracts.length;
      } else {
        updatedContract = detailPhysicalSegment.contracts[contractIndex];
      }

      detailPhysicalSegment.contracts[contractIndex] = {
        ...updatedContract,
        ...editContract[contractKey],
      };
    }

    updatedDetailState.focusKey = null;
  }

  if (removeContract !== undefined) {
    const foundIndex: number = detailPhysicalSegment.contracts.findIndex(
      (contract: IContract): boolean => contract.key === removeContract,
    );

    if (foundIndex !== -1) {
      detailPhysicalSegment.contracts.splice(foundIndex, 1);
    }

    if (detailPhysicalSegment.contracts.length === 0) {
      detailPhysicalSegment.contracts = null;
    }
  }

  return updatedDetailState;
};

const editPhysicalSegmentMiscInfo = (
  editMiscInfos: IEditMiscInfos,
  isGeneration: boolean,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = initialiseOrCopyPhysicalSegment(
    isGeneration,
    detailState,
  );
  const updatedDetailPhysicalSegment: IDetailPhysicalSegment = isGeneration
    ? updatedDetailState.generationPhysicalSegment!
    : updatedDetailState.loadPhysicalSegment!;
  const { addAfterMiscInfo, editMiscInfoMap, removeMiscInfo } = editMiscInfos;

  if (updatedDetailPhysicalSegment.misc_infos === null) {
    updatedDetailPhysicalSegment.misc_infos = [];
  }

  if (addAfterMiscInfo !== undefined) {
    let foundIndex: number = -1;
    let highestIndex: number = -1;

    updatedDetailPhysicalSegment.misc_infos.forEach(
      (miscInfo: IMiscInfo, index: number) => {
        const { editIndex } = getSplitEditInfoKey(miscInfo.key);

        if (editIndex === undefined) {
          throw new Error(`Invalid miscInfo.key: ${miscInfo.key}`);
        }

        if (editIndex > highestIndex) {
          highestIndex = editIndex;
        }

        if (miscInfo.key === addAfterMiscInfo) {
          foundIndex = index;
        }
      },
    );

    if (foundIndex !== -1) {
      const newEditInfoKey: string = getEditInfoKey(
        isGeneration
          ? EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL
          : EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
        updatedDetailPhysicalSegment.physical_segment_id,
        highestIndex + 1,
      );

      updatedDetailPhysicalSegment.misc_infos.splice(
        foundIndex + 1,
        0,
        getInitialMiscInfo(newEditInfoKey),
      );

      updatedDetailState.focusKey = newEditInfoKey;
    }
  }

  if (editMiscInfoMap !== undefined) {
    for (let miscInfoKey in editMiscInfoMap) {
      let miscInfoIndex: number =
        updatedDetailPhysicalSegment.misc_infos.findIndex(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoKey,
        );
      let updatedMiscInfo: IMiscInfo;

      if (miscInfoIndex === -1) {
        updatedMiscInfo = getInitialMiscInfo(miscInfoKey);
        miscInfoIndex = updatedDetailPhysicalSegment.misc_infos.length;
      } else {
        updatedMiscInfo =
          updatedDetailPhysicalSegment.misc_infos[miscInfoIndex];
      }

      updatedDetailPhysicalSegment.misc_infos[miscInfoIndex] = {
        ...updatedMiscInfo,
        ...editMiscInfoMap[miscInfoKey],
      };
    }

    updatedDetailState.focusKey = null;
  }

  if (removeMiscInfo !== undefined) {
    const foundIndex: number =
      updatedDetailPhysicalSegment.misc_infos.findIndex(
        (miscInfo: IMiscInfo): boolean => miscInfo.key === removeMiscInfo,
      );

    if (foundIndex !== -1) {
      updatedDetailPhysicalSegment.misc_infos.splice(foundIndex, 1);
    }

    if (updatedDetailPhysicalSegment.misc_infos.length === 0) {
      updatedDetailPhysicalSegment.misc_infos = null;
    }
  }

  return updatedDetailState;
};

const editGenerationPhysicalSegmentToDetailState = (
  editGenerationPhysicalSegment: IEditGenerationPhysicalSegment,
  detailState: IDetailState,
): IDetailState => {
  const {
    editContactInfos,
    editContracts,
    editMiscInfos,
    generation_source,
    market_segment_id,
    mo_code,
    removeGenerationPhysicalSegment,
  } = editGenerationPhysicalSegment;
  let updatedDetailState: IDetailState = { ...detailState };

  if (market_segment_id !== undefined) {
    updatedDetailState = editPhysicalSegmentMarketSegmentId(
      market_segment_id,
      true,
      updatedDetailState,
    );
  }

  if (mo_code !== undefined) {
    updatedDetailState = editPhysicalSegmentMoCode(
      mo_code,
      true,
      updatedDetailState,
    );
  }

  if (generation_source !== undefined) {
    updatedDetailState = initialiseOrCopyPhysicalSegment(
      true,
      updatedDetailState,
    );

    updatedDetailState.generationPhysicalSegment!.generation_source =
      generation_source;
  }

  if (editContactInfos !== undefined) {
    updatedDetailState = editPhysicalSegmentContactInfo(
      editContactInfos,
      true,
      updatedDetailState,
    );
  }

  if (editContracts !== undefined) {
    updatedDetailState = editPhysicalSegmentContracts(
      editContracts,
      true,
      updatedDetailState,
    );
  }

  if (editMiscInfos !== undefined) {
    updatedDetailState = editPhysicalSegmentMiscInfo(
      editMiscInfos,
      true,
      updatedDetailState,
    );
  }

  if (removeGenerationPhysicalSegment === true) {
    updatedDetailState.generationPhysicalSegment = null;
  }

  return updatedDetailState;
};

const updatePhysicalSegmentsProfiles = (
  physicalSegmentId: number,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[],
  pod?: IPointInfo | null,
  por?: IPointInfo | null,
  tp_code?: IEntityInfo | null,
): IETagPhysicalSegmentsProfile[] => {
  const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
    ...physicalSegmentsProfiles,
  ];
  let hasUpdatedPhysicalSegmentsProfiles: boolean = false;

  if (pod !== undefined || por !== undefined || tp_code !== undefined) {
    updatedPhysicalSegmentsProfiles.forEach(
      (
        physicalSegmentsProfile: IETagPhysicalSegmentsProfile,
        physicalSegmentsProfileIndex: number,
      ) => {
        const updatedPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile = {
          ...physicalSegmentsProfile,
        };

        if (
          updatedPhysicalSegmentsProfile.physical_segments_profiles !== null
        ) {
          const updatedPhysical_segments_profiles: IETagPhysicalSegmentsProfiles =
            {
              ...updatedPhysicalSegmentsProfile.physical_segments_profiles,
            };

          if (updatedPhysical_segments_profiles.transmission !== null) {
            const updatedTransmission: IETagTransmissionPhysicalSegmentProfile =
              {
                ...updatedPhysical_segments_profiles.transmission,
              };

            if (updatedTransmission.transmission_segments !== null) {
              const updatedTransmissionSegments: IETagTransmissionSegment[] = [
                ...updatedTransmission.transmission_segments,
              ];

              updatedTransmissionSegments.forEach(
                (
                  transmissionSegment: IETagTransmissionSegment,
                  transmissionSegmentIndex: number,
                ) => {
                  const updatedTransmissionSegment: IETagTransmissionSegment = {
                    ...transmissionSegment,
                  };

                  if (
                    transmissionSegment.physical_segment_id ===
                    physicalSegmentId
                  ) {
                    if (pod !== undefined) {
                      updatedTransmissionSegment.pod = pod;

                      hasUpdatedPhysicalSegmentsProfiles = true;
                    }

                    if (por !== undefined) {
                      updatedTransmissionSegment.por = por;

                      hasUpdatedPhysicalSegmentsProfiles = true;
                    }

                    if (tp_code !== undefined) {
                      updatedTransmissionSegment.tp_code = tp_code;

                      hasUpdatedPhysicalSegmentsProfiles = true;
                    }

                    updatedTransmissionSegments[transmissionSegmentIndex] =
                      updatedTransmissionSegment;
                  }
                },
              );

              updatedTransmission.transmission_segments =
                updatedTransmissionSegments;
              updatedPhysical_segments_profiles.transmission =
                updatedTransmission;
              updatedPhysicalSegmentsProfile.physical_segments_profiles =
                updatedPhysical_segments_profiles;
              updatedPhysicalSegmentsProfiles[physicalSegmentsProfileIndex] =
                updatedPhysicalSegmentsProfile;
            }
          }
        }
      },
    );
  }

  return hasUpdatedPhysicalSegmentsProfiles
    ? updatedPhysicalSegmentsProfiles
    : physicalSegmentsProfiles;
};

const editTransmissionPhysicalSegmentWithKey = (
  editTransmissionPhysicalSegmentKey: string,
  editTransmissionPhysicalSegment: IEditTransmissionPhysicalSegment,
  detailState: IDetailState,
): IDetailState => {
  const updatedDetailState: IDetailState = { ...detailState };
  const physical_segment_id: number = parseInt(
    editTransmissionPhysicalSegmentKey,
    10,
  );
  const updatedTransmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] =
    updatedDetailState.transmission_physical_segments === null
      ? []
      : [...updatedDetailState.transmission_physical_segments];
  const foundTransmissionPhysicalSegmentIndex: number =
    updatedTransmissionPhysicalSegments.findIndex(
      (
        transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
      ): boolean =>
        transmissionPhysicalSegment.physical_segment_id === physical_segment_id,
    );
  let updatedTransmissionPhysicalSegment:
    | IETagTransmissionPhysicalSegment
    | undefined =
    foundTransmissionPhysicalSegmentIndex === -1
      ? undefined
      : updatedTransmissionPhysicalSegments[
          foundTransmissionPhysicalSegmentIndex
        ];

  if (updatedTransmissionPhysicalSegment === undefined) {
    updatedTransmissionPhysicalSegment =
      getInitialTransmissionPhysicalSegment(physical_segment_id);
  } else {
    updatedTransmissionPhysicalSegment = copyTransmissionPhysicalSegment(
      updatedTransmissionPhysicalSegment,
    );
  }

  const {
    editMiscInfos,
    market_segment_id,
    mo_code,
    pod,
    por,
    scheduling_entities,
    tp_code,
    physical_segment_loss_percentage,
  } = editTransmissionPhysicalSegment;

  if (physical_segment_loss_percentage !== undefined) {
    updatedTransmissionPhysicalSegment.physical_segment_loss_percentage =
      physical_segment_loss_percentage;
    if (updatedDetailState.physical_segment_loss_percentages) {
      updatedDetailState.physical_segment_loss_percentages[
        updatedTransmissionPhysicalSegment.physical_segment_id
      ] = physical_segment_loss_percentage;
    }
  }

  if (market_segment_id !== undefined) {
    const foundMarketSegment: IDetailMarketSegment | undefined =
      updatedDetailState.marketSegments === null
        ? undefined
        : updatedDetailState.marketSegments.find(
            (marketSegment: IDetailMarketSegment): boolean =>
              marketSegment.market_segment_id === market_segment_id,
          );

    updatedTransmissionPhysicalSegment.market_segment_id = market_segment_id;
    updatedTransmissionPhysicalSegment.pse =
      foundMarketSegment === undefined ? null : foundMarketSegment.pse;
  }

  if (tp_code !== undefined) {
    updatedTransmissionPhysicalSegment.tp_code = tp_code;
    // POD and POR are dependent upon the tp_code and so must
    // be reset to ensure that the user selects valid POD and
    // POR for the given tp_code
    updatedTransmissionPhysicalSegment.pod = null;
    updatedTransmissionPhysicalSegment.por = null;

    if (updatedDetailState.physicalSegmentsProfiles !== null) {
      updatedDetailState.physicalSegmentsProfiles =
        updatePhysicalSegmentsProfiles(
          physical_segment_id,
          updatedDetailState.physicalSegmentsProfiles,
          undefined,
          undefined,
          tp_code,
        );
    }
  }

  if (mo_code !== undefined) {
    updatedTransmissionPhysicalSegment.mo_code = mo_code;
  }

  if (por !== undefined) {
    updatedTransmissionPhysicalSegment.por = por;

    if (updatedDetailState.physicalSegmentsProfiles !== null) {
      updatedDetailState.physicalSegmentsProfiles =
        updatePhysicalSegmentsProfiles(
          physical_segment_id,
          updatedDetailState.physicalSegmentsProfiles,
          undefined,
          por,
          undefined,
        );
    }
  }

  if (pod !== undefined) {
    updatedTransmissionPhysicalSegment.pod = pod;

    if (updatedDetailState.physicalSegmentsProfiles !== null) {
      updatedDetailState.physicalSegmentsProfiles =
        updatePhysicalSegmentsProfiles(
          physical_segment_id,
          updatedDetailState.physicalSegmentsProfiles,
          pod,
          undefined,
          undefined,
        );
    }
  }

  if (scheduling_entities !== undefined) {
    updatedTransmissionPhysicalSegment.scheduling_entities =
      scheduling_entities;
  }

  if (editMiscInfos !== undefined) {
    const { addAfterMiscInfo, editMiscInfoMap, removeMiscInfo } = editMiscInfos;

    if (updatedTransmissionPhysicalSegment.misc_infos === null) {
      updatedTransmissionPhysicalSegment.misc_infos = [];
    }

    if (addAfterMiscInfo !== undefined) {
      let foundIndex: number = -1;
      let highestIndex: number = -1;

      updatedTransmissionPhysicalSegment.misc_infos.forEach(
        (miscInfo: IMiscInfo, index: number) => {
          const { editIndex } = getSplitEditInfoKey(miscInfo.key);

          if (editIndex === undefined) {
            throw new Error(`Invalid miscInfo.key: ${miscInfo.key}`);
          }

          if (editIndex > highestIndex) {
            highestIndex = editIndex;
          }

          if (miscInfo.key === addAfterMiscInfo) {
            foundIndex = index;
          }
        },
      );

      if (foundIndex !== -1) {
        const newEditInfoKey: string = getEditInfoKey(
          EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
          updatedTransmissionPhysicalSegment.physical_segment_id,
          highestIndex + 1,
        );

        updatedTransmissionPhysicalSegment.misc_infos.splice(
          foundIndex + 1,
          0,
          getInitialMiscInfo(newEditInfoKey),
        );

        updatedDetailState.focusKey = newEditInfoKey;
      }
    }

    if (editMiscInfoMap !== undefined) {
      for (let miscInfoKey in editMiscInfoMap) {
        let miscInfoIndex: number =
          updatedTransmissionPhysicalSegment.misc_infos.findIndex(
            (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoKey,
          );
        let updatedMiscInfo: IMiscInfo;

        if (miscInfoIndex === -1) {
          updatedMiscInfo = getInitialMiscInfo(miscInfoKey);
          miscInfoIndex = updatedTransmissionPhysicalSegment.misc_infos.length;
        } else {
          updatedMiscInfo =
            updatedTransmissionPhysicalSegment.misc_infos[miscInfoIndex];
        }

        updatedTransmissionPhysicalSegment.misc_infos[miscInfoIndex] = {
          ...updatedMiscInfo,
          ...editMiscInfoMap[miscInfoKey],
        };
      }

      updatedDetailState.focusKey = null;
    }

    if (removeMiscInfo !== undefined) {
      const foundIndex: number =
        updatedTransmissionPhysicalSegment.misc_infos.findIndex(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === removeMiscInfo,
        );

      if (foundIndex !== -1) {
        updatedTransmissionPhysicalSegment.misc_infos.splice(foundIndex, 1);
      }

      if (updatedTransmissionPhysicalSegment.misc_infos.length === 0) {
        updatedTransmissionPhysicalSegment.misc_infos = null;
      }
    }
  }

  updatedTransmissionPhysicalSegments[
    foundTransmissionPhysicalSegmentIndex === -1
      ? updatedTransmissionPhysicalSegments.length
      : foundTransmissionPhysicalSegmentIndex
  ] = updatedTransmissionPhysicalSegment;
  updatedDetailState.transmission_physical_segments =
    updatedTransmissionPhysicalSegments;

  let physicalSegmentLossPercentages: Record<number, string | number | null> =
    {};

  updatedDetailState.transmission_physical_segments.forEach(
    (segment: IETagTransmissionPhysicalSegment) => {
      if (segment && segment.physical_segment_loss_percentage) {
        physicalSegmentLossPercentages[segment.physical_segment_id] =
          segment.physical_segment_loss_percentage;
      }
    },
  );

  if (physicalSegmentLossPercentages) {
    updatedDetailState.physical_segment_loss_percentages =
      physicalSegmentLossPercentages;
  }

  return updatedDetailState;
};

const updatePhysicalSegmentsProfilesAfterAdd = (
  physicalSegmentId: number,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[],
): IETagPhysicalSegmentsProfile[] => {
  const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
    ...physicalSegmentsProfiles,
  ];
  let hasUpdatedPhysicalSegmentsProfiles: boolean = false;

  updatedPhysicalSegmentsProfiles.forEach(
    (
      physicalSegmentsProfile: IETagPhysicalSegmentsProfile,
      physicalSegmentsProfileIndex: number,
    ) => {
      const updatedPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile = {
        ...physicalSegmentsProfile,
      };
      let hasUpdatedPhysicalSegmentsProfile: boolean = false;

      if (updatedPhysicalSegmentsProfile.physical_segments_profiles !== null) {
        const updatedPhysical_segments_profiles: IETagPhysicalSegmentsProfiles =
          {
            ...updatedPhysicalSegmentsProfile.physical_segments_profiles,
          };

        if (updatedPhysical_segments_profiles.transmission !== null) {
          const updatedTransmission: IETagTransmissionPhysicalSegmentProfile = {
            ...updatedPhysical_segments_profiles.transmission,
          };

          if (updatedTransmission.transmission_segments !== null) {
            const updatedTransmissionSegments: IETagTransmissionSegment[] = [];
            let transmissionSegmentInsertIndex: number | undefined = undefined;

            // We expect the transmission_segments to be ordered by physical_segment_id
            updatedTransmission.transmission_segments.forEach(
              (
                transmissionSegment: IETagTransmissionSegment,
                transmissionSegmentIndex: number,
              ) => {
                if (
                  transmissionSegment.physical_segment_id !== null &&
                  transmissionSegment.physical_segment_id > physicalSegmentId
                ) {
                  const updatedPhysicalSegmentId: number =
                    transmissionSegment.physical_segment_id + 1;

                  if (transmissionSegmentInsertIndex === undefined) {
                    transmissionSegmentInsertIndex = transmissionSegmentIndex;
                  }

                  hasUpdatedPhysicalSegmentsProfile = true;

                  updatedTransmissionSegments.push({
                    ...transmissionSegment,
                    physical_segment_id: updatedPhysicalSegmentId,
                    pod_energy_profile:
                      transmissionSegment.pod_energy_profile === null
                        ? null
                        : {
                            ...transmissionSegment.pod_energy_profile,
                            profile_id: getPODProfileRef(
                              updatedPhysicalSegmentId,
                            ),
                          },
                    por_energy_profile:
                      transmissionSegment.por_energy_profile === null
                        ? null
                        : {
                            ...transmissionSegment.por_energy_profile,
                            profile_id: getPORProfileRef(
                              updatedPhysicalSegmentId,
                            ),
                          },
                  });
                } else {
                  updatedTransmissionSegments.push(transmissionSegment);
                }
              },
            );

            if (transmissionSegmentInsertIndex !== undefined) {
              updatedTransmissionSegments.splice(
                transmissionSegmentInsertIndex,
                0,
                getInitialTransmissionSegment(physicalSegmentId + 1),
              );
            }

            updatedTransmission.transmission_segments =
              updatedTransmissionSegments;
            updatedPhysical_segments_profiles.transmission =
              updatedTransmission;
            updatedPhysicalSegmentsProfile.physical_segments_profiles =
              updatedPhysical_segments_profiles;
          }
        }

        if (updatedPhysical_segments_profiles.load !== null) {
          const updatedLoadPhysicalSegmentProfile: IETagPhysicalSegmentProfile =
            {
              ...updatedPhysical_segments_profiles.load,
            };
          const updatedPhysicalSegmentId: number =
            updatedLoadPhysicalSegmentProfile.physical_segment_id + 1;
          const updatedEnergyProfile: IEnergyProfile | null =
            updatedLoadPhysicalSegmentProfile.profile === null
              ? null
              : {
                  ...updatedLoadPhysicalSegmentProfile.profile,
                  profile_id: getLoadProfileRef(updatedPhysicalSegmentId),
                };

          updatedLoadPhysicalSegmentProfile.physical_segment_id =
            updatedPhysicalSegmentId;
          updatedLoadPhysicalSegmentProfile.profile = updatedEnergyProfile;
          updatedPhysical_segments_profiles.load =
            updatedLoadPhysicalSegmentProfile;

          hasUpdatedPhysicalSegmentsProfile = true;
        }

        if (hasUpdatedPhysicalSegmentsProfile) {
          updatedPhysicalSegmentsProfiles[physicalSegmentsProfileIndex] =
            updatedPhysicalSegmentsProfile;

          hasUpdatedPhysicalSegmentsProfiles = true;
        }
      }
    },
  );

  return hasUpdatedPhysicalSegmentsProfiles
    ? updatedPhysicalSegmentsProfiles
    : physicalSegmentsProfiles;
};

const transAllocByIdSorter = (
  a: IETagTransmissionAllocation,
  b: IETagTransmissionAllocation,
) => {
  if (a.trans_alloc_id < b.trans_alloc_id) {
    return -1;
  }
  if (a.trans_alloc_id > b.trans_alloc_id) {
    return 1;
  }
  return 0;
};

const updatePhysicalSegmentsProfilesAfterRemove = (
  physicalSegmentId: number,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[],
  updatedTransAlloc: IETagTransmissionAllocation | undefined,
): IETagPhysicalSegmentsProfile[] => {
  const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
    ...physicalSegmentsProfiles,
  ];
  let hasUpdatedPhysicalSegmentsProfiles: boolean = false;

  updatedPhysicalSegmentsProfiles.forEach(
    (
      physicalSegmentsProfile: IETagPhysicalSegmentsProfile,
      physicalSegmentsProfileIndex: number,
    ) => {
      const updatedPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile = {
        ...physicalSegmentsProfile,
      };
      let hasUpdatedPhysicalSegmentsProfile: boolean = false;

      if (updatedPhysicalSegmentsProfile.physical_segments_profiles !== null) {
        const updatedPhysical_segments_profiles: IETagPhysicalSegmentsProfiles =
          {
            ...updatedPhysicalSegmentsProfile.physical_segments_profiles,
          };

        if (updatedPhysical_segments_profiles.transmission !== null) {
          const updatedTransmission: IETagTransmissionPhysicalSegmentProfile = {
            ...updatedPhysical_segments_profiles.transmission,
          };

          if (updatedTransmission.transmission_segments !== null) {
            const updatedTransmissionSegments: IETagTransmissionSegment[] = [];

            updatedTransmission.transmission_segments.forEach(
              (transmissionSegment: IETagTransmissionSegment) => {
                if (transmissionSegment.physical_segment_id !== null) {
                  if (
                    transmissionSegment.physical_segment_id ===
                    physicalSegmentId
                  ) {
                    hasUpdatedPhysicalSegmentsProfile = true;
                  } else if (
                    transmissionSegment.physical_segment_id > physicalSegmentId
                  ) {
                    const updatedPhysicalSegmentId: number =
                      transmissionSegment.physical_segment_id - 1;

                    updatedTransmissionSegments.push({
                      ...transmissionSegment,
                      physical_segment_id: updatedPhysicalSegmentId,
                      pod_energy_profile:
                        transmissionSegment.pod_energy_profile === null
                          ? null
                          : {
                              ...transmissionSegment.pod_energy_profile,
                              profile_id: getPODProfileRef(
                                updatedPhysicalSegmentId,
                              ),
                            },
                      por_energy_profile:
                        transmissionSegment.por_energy_profile === null
                          ? null
                          : {
                              ...transmissionSegment.por_energy_profile,
                              profile_id: getPORProfileRef(
                                updatedPhysicalSegmentId,
                              ),
                            },
                    });
                  } else if (
                    transmissionSegment.physical_segment_id !==
                    physicalSegmentId
                  ) {
                    updatedTransmissionSegments.push(transmissionSegment);
                  }
                } else {
                  updatedTransmissionSegments.push(transmissionSegment);
                }
              },
            );

            updatedTransmission.transmission_segments =
              updatedTransmissionSegments;
            updatedPhysical_segments_profiles.transmission =
              updatedTransmission;
            updatedPhysicalSegmentsProfile.physical_segments_profiles =
              updatedPhysical_segments_profiles;
          }
        }

        if (updatedPhysical_segments_profiles.load !== null) {
          const updatedLoadPhysicalSegmentProfile: IETagPhysicalSegmentProfile =
            {
              ...updatedPhysical_segments_profiles.load,
            };
          const updatedPhysicalSegmentId: number =
            updatedLoadPhysicalSegmentProfile.physical_segment_id - 1;
          const updatedEnergyProfile: IEnergyProfile | null =
            updatedLoadPhysicalSegmentProfile.profile === null
              ? null
              : {
                  ...updatedLoadPhysicalSegmentProfile.profile,
                  profile_id: getLoadProfileRef(updatedPhysicalSegmentId),
                };

          updatedLoadPhysicalSegmentProfile.physical_segment_id =
            updatedPhysicalSegmentId;
          updatedLoadPhysicalSegmentProfile.profile = updatedEnergyProfile;
          updatedPhysical_segments_profiles.load =
            updatedLoadPhysicalSegmentProfile;

          hasUpdatedPhysicalSegmentsProfile = true;
        }

        if (hasUpdatedPhysicalSegmentsProfile) {
          updatedPhysicalSegmentsProfiles[physicalSegmentsProfileIndex] =
            updatedPhysicalSegmentsProfile;

          hasUpdatedPhysicalSegmentsProfiles = true;
        }
      }
    },
  );

  return hasUpdatedPhysicalSegmentsProfiles
    ? updatedPhysicalSegmentsProfiles
    : physicalSegmentsProfiles;
};

const updatedValidationsAfterRemove = (
  validations: TDetailValidations,
  previousTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[],
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
): TDetailValidations => {
  const updatedValidations: TDetailValidations = { ...validations };

  if (previousTransmissionPhysicalSegments !== null) {
    previousTransmissionPhysicalSegments.forEach(
      (eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
        delete updatedValidations[
          getTransmissionPhysicalSegmentKey(eTagTransmissionPhysicalSegment)
        ];
      },
    );
  }

  transmissionPhysicalSegments.forEach(
    (eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
      updatedValidations[
        getTransmissionPhysicalSegmentKey(eTagTransmissionPhysicalSegment)
      ] = isValidTransmissionPhysicalSegment(eTagTransmissionPhysicalSegment);
    },
  );

  delete updatedValidations[
    getNextLoadPhysicalSegmentKey(previousTransmissionPhysicalSegments)
  ];

  updatedValidations[
    getNextLoadPhysicalSegmentKey(transmissionPhysicalSegments)
  ] = isValidLoadPhysicalSegment(loadPhysicalSegment);

  return updatedValidations;
};

const editTransmissionPhysicalSegmentToDetailState = (
  editTransmissionPhysicalSegments: IEditTransmissionPhysicalSegments,
  detailState: IDetailState,
): IDetailState => {
  const {
    addAfterPhysicalSegmentId,
    transmissionPhysicalSegmentMap,
    removePhysicalSegmentId,
  } = editTransmissionPhysicalSegments;
  let updatedDetailState: IDetailState = { ...detailState };

  if (addAfterPhysicalSegmentId !== undefined) {
    const { transmission_physical_segments } = updatedDetailState;

    if (transmission_physical_segments !== null) {
      const updatedTransmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] =
        [...transmission_physical_segments];
      let indexOfMatching: number = -1;

      updatedTransmissionPhysicalSegments.forEach(
        (
          transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
          index: number,
        ) => {
          if (
            transmissionPhysicalSegment.physical_segment_id ===
            addAfterPhysicalSegmentId
          ) {
            indexOfMatching = index;
          }

          if (
            transmissionPhysicalSegment.physical_segment_id >
            addAfterPhysicalSegmentId
          ) {
            updatedTransmissionPhysicalSegments[index] =
              copyTransmissionPhysicalSegment(transmissionPhysicalSegment);
            updatedTransmissionPhysicalSegments[index].physical_segment_id += 1;
            updatedTransmissionPhysicalSegments[index].pod_profile_ref =
              getPODProfileRef(
                updatedTransmissionPhysicalSegments[index].physical_segment_id,
              );
            updatedTransmissionPhysicalSegments[index].por_profile_ref =
              getPORProfileRef(
                updatedTransmissionPhysicalSegments[index].physical_segment_id,
              );
          }
        },
      );

      if (indexOfMatching !== -1) {
        updatedTransmissionPhysicalSegments.splice(
          indexOfMatching + 1,
          0,
          getInitialTransmissionPhysicalSegment(addAfterPhysicalSegmentId + 1),
        );

        updatedDetailState.transmission_physical_segments =
          updatedTransmissionPhysicalSegments;

        // Update physical segment id and profile id references
        const {
          loadPhysicalSegment,
          lossAccountings,
          physicalSegmentsProfiles,
          transmissionAllocations,
        } = updatedDetailState;

        if (loadPhysicalSegment !== null) {
          const updatedLoadPhysicalSegment: IDetailLoadPhysicalSegment =
            copyDetailLoadPhysicalSegment(loadPhysicalSegment)!;
          updatedLoadPhysicalSegment.physical_segment_id += 1;
          updatedLoadPhysicalSegment.profile_ref = getLoadProfileRef(
            updatedLoadPhysicalSegment.physical_segment_id,
          );

          updatedDetailState.loadPhysicalSegment = updatedLoadPhysicalSegment;
        }

        const updatedLossAccountings: IDetailLossAccounting[] = [];

        lossAccountings.forEach(
          (detailLossAccounting: IDetailLossAccounting) => {
            if (
              detailLossAccounting.physical_segment_ref !== null &&
              detailLossAccounting.physical_segment_ref >
                addAfterPhysicalSegmentId
            ) {
              const updatedDetailLossAccounting: IDetailLossAccounting =
                copyDetailLossAccounting(detailLossAccounting);

              updatedDetailLossAccounting.physical_segment_ref! += 1;
              const { label, primaryId, editIndex } = getSplitEditInfoKey(
                updatedDetailLossAccounting.key,
              );
              updatedDetailLossAccounting.key = getEditInfoKey(
                label,
                primaryId + 1,
                editIndex,
              );

              updatedLossAccountings.push(updatedDetailLossAccounting);
            } else {
              updatedLossAccountings.push(detailLossAccounting);
            }
          },
        );

        updatedDetailState.lossAccountings = updatedLossAccountings;

        if (transmissionAllocations !== null) {
          const updatedTransmissionAllocations: IETagTransmissionAllocation[] =
            [];

          transmissionAllocations.forEach(
            (transmissionAllocation: IETagTransmissionAllocation) => {
              if (
                transmissionAllocation.physical_segment_ref !== null &&
                transmissionAllocation.physical_segment_ref >
                  addAfterPhysicalSegmentId
              ) {
                const updatedTransmissionAllocation: IETagTransmissionAllocation =
                  copyTransmissionAllocation(transmissionAllocation);

                updatedTransmissionAllocation.physical_segment_ref! += 1;

                updatedTransmissionAllocations.push(
                  updatedTransmissionAllocation,
                );
              } else {
                updatedTransmissionAllocations.push(transmissionAllocation);
              }
            },
          );

          updatedDetailState.transmissionAllocations =
            updatedTransmissionAllocations;
        }

        if (physicalSegmentsProfiles !== null) {
          updatedDetailState.physicalSegmentsProfiles =
            updatePhysicalSegmentsProfilesAfterAdd(
              addAfterPhysicalSegmentId,
              physicalSegmentsProfiles,
            );
        }
      }
    }
  }

  if (transmissionPhysicalSegmentMap !== undefined) {
    for (let editTransmissionPhysicalSegmentKey in transmissionPhysicalSegmentMap) {
      updatedDetailState = editTransmissionPhysicalSegmentWithKey(
        editTransmissionPhysicalSegmentKey,
        transmissionPhysicalSegmentMap[editTransmissionPhysicalSegmentKey],
        updatedDetailState,
      );
    }
  }

  if (removePhysicalSegmentId !== undefined) {
    const { transmission_physical_segments, transmissionAllocations } =
      updatedDetailState;

    if (transmissionAllocations !== null) {
      const updatedTransmissionAllocations: IETagTransmissionAllocation[] = [];
      transmissionAllocations.forEach(
        (transAlloc: IETagTransmissionAllocation) => {
          if (transAlloc.physical_segment_ref !== removePhysicalSegmentId) {
            updatedTransmissionAllocations.push(
              copyTransmissionAllocation(transAlloc),
            );
          }
        },
      );
      const updatedTransmissionAllocationsCorrectedIds: IETagTransmissionAllocation[] =
        [];
      let updatedLossAccountings: IDetailLossAccounting[] = [];

      updatedTransmissionAllocations
        .sort(transAllocByIdSorter)
        .forEach((transAlloc: IETagTransmissionAllocation, index) => {
          const originalTransAllocId = transAlloc.trans_alloc_id;
          const transAllocWithUpdatedId: IETagTransmissionAllocation = {
            ...copyTransmissionAllocation(transAlloc),
            trans_alloc_id: index + 1,
          };

          updatedDetailState.physicalSegmentsProfiles?.forEach(
            (physicalSegment) => {
              const transmissionSegment =
                physicalSegment.physical_segments_profiles?.transmission?.transmission_segments?.filter(
                  (tSegment) =>
                    tSegment.physical_segment_id &&
                    tSegment.physical_segment_id ===
                      transAllocWithUpdatedId.physical_segment_ref,
                );
              if (transmissionSegment) {
                transmissionSegment.forEach((tSegment) => {
                  if (tSegment.trans_alloc_profiles) {
                    tSegment.trans_alloc_profiles.forEach((profile) => {
                      if (profile.trans_alloc_id === originalTransAllocId) {
                        profile.trans_alloc_id =
                          transAllocWithUpdatedId.trans_alloc_id;
                      }
                    });
                  }
                });
              }
            },
          );

          updatedTransmissionAllocationsCorrectedIds.push(
            transAllocWithUpdatedId,
          );
        });

      updatedLossAccountings = updatedDetailState.lossAccountings.filter(
        (loss) => loss.physical_segment_ref !== removePhysicalSegmentId,
      );

      updatedDetailState.lossAccountings = updatedLossAccountings;

      updatedDetailState.transmissionAllocations =
        updatedTransmissionAllocationsCorrectedIds;
    }

    if (transmission_physical_segments !== null) {
      const updatedTransmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] =
        [];

      transmission_physical_segments.forEach(
        (transmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
          if (
            transmissionPhysicalSegment.physical_segment_id >
            removePhysicalSegmentId
          ) {
            const updatedTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
              copyTransmissionPhysicalSegment(transmissionPhysicalSegment);

            updatedTransmissionPhysicalSegment.physical_segment_id -= 1;
            updatedTransmissionPhysicalSegment.pod_profile_ref =
              getPODProfileRef(
                updatedTransmissionPhysicalSegment.physical_segment_id,
              );
            updatedTransmissionPhysicalSegment.por_profile_ref =
              getPORProfileRef(
                updatedTransmissionPhysicalSegment.physical_segment_id,
              );

            updatedTransmissionPhysicalSegments.push(
              updatedTransmissionPhysicalSegment,
            );
          } else if (
            transmissionPhysicalSegment.physical_segment_id !==
            removePhysicalSegmentId
          ) {
            updatedTransmissionPhysicalSegments.push(
              transmissionPhysicalSegment,
            );
          }
        },
      );

      if (
        updatedTransmissionPhysicalSegments.length !==
        transmission_physical_segments.length
      ) {
        updatedDetailState.transmission_physical_segments =
          updatedTransmissionPhysicalSegments.length === 0
            ? null
            : updatedTransmissionPhysicalSegments;

        // Update physical segment id references
        const {
          loadPhysicalSegment,
          lossAccountings,
          physicalSegmentsProfiles,
          transmissionAllocations,
        } = updatedDetailState;

        if (loadPhysicalSegment !== null) {
          const updatedLoadPhysicalSegment: IDetailLoadPhysicalSegment =
            copyDetailLoadPhysicalSegment(loadPhysicalSegment)!;

          // Minimum value is 3 since we always have at least a placeholder
          // transmission physical segment which has 2
          updatedLoadPhysicalSegment.physical_segment_id =
            updatedTransmissionPhysicalSegments.length < 2
              ? 3
              : updatedLoadPhysicalSegment.physical_segment_id - 1;
          updatedLoadPhysicalSegment.profile_ref = getLoadProfileRef(
            updatedLoadPhysicalSegment.physical_segment_id,
          );

          updatedDetailState.loadPhysicalSegment = updatedLoadPhysicalSegment;
        }

        const updatedLossAccountings: IDetailLossAccounting[] = [];

        lossAccountings.forEach(
          (detailLossAccounting: IDetailLossAccounting) => {
            if (detailLossAccounting.physical_segment_ref === null) {
              updatedLossAccountings.push(detailLossAccounting);
            } else if (
              detailLossAccounting.physical_segment_ref ===
              removePhysicalSegmentId
            ) {
              const updatedDetailLossAccounting: IDetailLossAccounting =
                copyDetailLossAccounting(detailLossAccounting);

              updatedDetailLossAccounting.physical_segment_ref = null;

              updatedLossAccountings.push(updatedDetailLossAccounting);
            } else if (
              detailLossAccounting.physical_segment_ref >
              removePhysicalSegmentId
            ) {
              const updatedDetailLossAccounting: IDetailLossAccounting =
                copyDetailLossAccounting(detailLossAccounting);

              updatedDetailLossAccounting.physical_segment_ref! -= 1;

              updatedLossAccountings.push(updatedDetailLossAccounting);
            } else {
              updatedLossAccountings.push(detailLossAccounting);
            }
          },
        );

        updatedDetailState.lossAccountings = updatedLossAccountings;

        if (transmissionAllocations !== null) {
          const updatedTransmissionAllocations: IETagTransmissionAllocation[] =
            [];

          transmissionAllocations.forEach(
            (transmissionAllocation: IETagTransmissionAllocation) => {
              if (transmissionAllocation.physical_segment_ref === null) {
                updatedTransmissionAllocations.push(transmissionAllocation);
              } else if (
                transmissionAllocation.physical_segment_ref ===
                removePhysicalSegmentId
              ) {
                const updatedTransmissionAllocation: IETagTransmissionAllocation =
                  copyTransmissionAllocation(transmissionAllocation);

                updatedTransmissionAllocation.physical_segment_ref = null;

                updatedTransmissionAllocations.push(
                  updatedTransmissionAllocation,
                );
              } else if (
                transmissionAllocation.physical_segment_ref >
                removePhysicalSegmentId
              ) {
                const updatedTransmissionAllocation: IETagTransmissionAllocation =
                  copyTransmissionAllocation(transmissionAllocation);

                updatedTransmissionAllocation.physical_segment_ref! -= 1;

                updatedTransmissionAllocations.push(
                  updatedTransmissionAllocation,
                );
              } else {
                updatedTransmissionAllocations.push(transmissionAllocation);
              }
            },
          );

          updatedDetailState.transmissionAllocations =
            updatedTransmissionAllocations;
        }

        const updatedTransAlloc =
          updatedDetailState.transmissionAllocations?.find(
            (trans) => trans.physical_segment_ref === removePhysicalSegmentId,
          );

        if (physicalSegmentsProfiles !== null) {
          updatedDetailState.physicalSegmentsProfiles =
            updatePhysicalSegmentsProfilesAfterRemove(
              removePhysicalSegmentId,
              physicalSegmentsProfiles,
              updatedTransAlloc,
            );
        }

        updatedDetailState.validations = updatedValidationsAfterRemove(
          updatedDetailState.validations,
          detailState.transmission_physical_segments,
          updatedTransmissionPhysicalSegments,
          updatedDetailState.loadPhysicalSegment,
        );
      }
    }
  }

  return updatedDetailState;
};

const editLoadPhysicalSegmentToDetailState = (
  editLoadPhysicalSegment: IEditLoadPhysicalSegment,
  detailState: IDetailState,
): IDetailState => {
  const {
    editContactInfos,
    editContracts,
    editMiscInfos,
    load_sink,
    market_segment_id,
    mo_code,
    removeLoadPhysicalSegment,
  } = editLoadPhysicalSegment;
  let updatedDetailState: IDetailState = { ...detailState };

  if (market_segment_id !== undefined) {
    updatedDetailState = editPhysicalSegmentMarketSegmentId(
      market_segment_id,
      false,
      updatedDetailState,
    );
  }

  if (mo_code !== undefined) {
    updatedDetailState = editPhysicalSegmentMoCode(
      mo_code,
      false,
      updatedDetailState,
    );
  }

  if (load_sink !== undefined) {
    updatedDetailState = initialiseOrCopyPhysicalSegment(
      false,
      updatedDetailState,
    );

    updatedDetailState.loadPhysicalSegment!.load_sink = load_sink;
  }

  if (editContactInfos !== undefined) {
    updatedDetailState = editPhysicalSegmentContactInfo(
      editContactInfos,
      false,
      updatedDetailState,
    );
  }

  if (editContracts !== undefined) {
    updatedDetailState = editPhysicalSegmentContracts(
      editContracts,
      false,
      updatedDetailState,
    );
  }

  if (editMiscInfos !== undefined) {
    updatedDetailState = editPhysicalSegmentMiscInfo(
      editMiscInfos,
      false,
      updatedDetailState,
    );
  }

  if (removeLoadPhysicalSegment === true) {
    updatedDetailState.loadPhysicalSegment = null;
  }

  return updatedDetailState;
};

export const editPhysicalPathInformationToDetailState = (
  editPhysicalPathInformation: IEditPhysicalPathInformation,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const {
      editGenerationPhysicalSegment,
      editLoadPhysicalSegment,
      editTransmissionPhysicalSegments,
    } = editPhysicalPathInformation;
    let updatedDetailState: IDetailState = { ...detailState };

    if (editGenerationPhysicalSegment !== undefined) {
      updatedDetailState = editGenerationPhysicalSegmentToDetailState(
        editGenerationPhysicalSegment,
        updatedDetailState,
      );
    }

    if (editTransmissionPhysicalSegments !== undefined) {
      updatedDetailState = editTransmissionPhysicalSegmentToDetailState(
        editTransmissionPhysicalSegments,
        updatedDetailState,
      );
    }

    if (editLoadPhysicalSegment !== undefined) {
      updatedDetailState = editLoadPhysicalSegmentToDetailState(
        editLoadPhysicalSegment,
        updatedDetailState,
      );
    }

    return updatedDetailState;
  };
};

export const validatePhysicalPath = (
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  lossAccountings: IDetailLossAccounting[],
) => {
  const detailValidations: TDetailValidations = {};
  const validationMessages: IValidationMessage[] = [];

  const isValidGeneration: boolean = isValidGenerationPhysicalSegment(
    generationPhysicalSegment,
  );

  detailValidations[
    getEditInfoKey(EDIT_GENERATION_PHYSICAL_SEGMENT_LABEL, 1, 0)
  ] = isValidGeneration;

  if (!isValidGeneration) {
    validationMessages.push({
      message: 'Missing MID for generation physical segment',
      severity: ESeverity.Error,
    });
  }

  if (transmissionPhysicalSegments === null) {
    detailValidations[getTransmissionPhysicalSegmentKey(null)] = false;

    validationMessages.push({
      message: `Missing MID for transmission physical segment with PID: ${getTransmissionPhysicalSegmentId(
        null,
      )}`,
      severity: ESeverity.Error,
    });
  } else {
    transmissionPhysicalSegments.forEach(
      (eTagTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
        const isValidTransmission: boolean = isValidTransmissionPhysicalSegment(
          eTagTransmissionPhysicalSegment,
        );

        detailValidations[
          getTransmissionPhysicalSegmentKey(eTagTransmissionPhysicalSegment)
        ] = isValidTransmission;

        if (!isValidTransmission) {
          validationMessages.push({
            message: `Missing MID for transmission physical segment with PID: ${eTagTransmissionPhysicalSegment.physical_segment_id}`,
            severity: ESeverity.Error,
          });
        }

        if (
          transmissionAllocations !== null &&
          transmissionAllocations.find(
            (
              eTagTransmissionAllocation: IETagTransmissionAllocation,
            ): boolean =>
              eTagTransmissionAllocation.physical_segment_ref ===
              eTagTransmissionPhysicalSegment.physical_segment_id,
          ) === undefined
        ) {
          validationMessages.push({
            message: `Missing OASIS information for transmission physical segment with PID: ${eTagTransmissionPhysicalSegment.physical_segment_id}`,
            severity: ESeverity.Warning,
          });
        }
      },
    );
  }

  const isValidLoad: boolean = isValidLoadPhysicalSegment(loadPhysicalSegment);

  detailValidations[
    getNextLoadPhysicalSegmentKey(transmissionPhysicalSegments)
  ] = isValidLoad;

  if (!isValidLoad) {
    validationMessages.push({
      message: 'Missing MID for load physical segment',
      severity: ESeverity.Error,
    });
  }

  if (transmissionAllocations !== null) {
    transmissionAllocations.forEach(
      (eTagTransmissionAllocation: IETagTransmissionAllocation) => {
        const isValid: boolean = isValidTransmissionAllocation(
          eTagTransmissionAllocation,
        );

        detailValidations[
          getTransmissionAllocationKey(eTagTransmissionAllocation)
        ] = isValid;

        if (!isValid) {
          validationMessages.push({
            message: `Missing PID for OASIS Information with id: ${eTagTransmissionAllocation.trans_alloc_id}`,
            severity: ESeverity.Error,
          });
        }

        if (isEmptyValue(eTagTransmissionAllocation.contract_number)) {
          validationMessages.push({
            message: `Missing OASIS detail for OASIS Information with ID: ${eTagTransmissionAllocation.trans_alloc_id}`,
            severity: ESeverity.Warning,
          });
        }
      },
    );
  }

  lossAccountings.forEach(
    (lossAccounting: IDetailLossAccounting, index: number) => {
      const isValid: boolean = isValidLossAccounting(lossAccounting);

      detailValidations[lossAccounting.key] = isValid;

      if (!isValid) {
        validationMessages.push({
          message: `Missing PID for loss method detail with ID: ${index + 1}`,
          severity: ESeverity.Error,
        });
      }
    },
  );

  return {
    detailValidations,
    validationMessages,
  };
};
