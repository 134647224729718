import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import { getProfileIntervalColumns } from 'components/organisms/ProductProfileSelection/helpers';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { IProfileIntervalGridData } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { alternatingTableRowBackground } from 'utils/styles';
import { ETheme } from 'enums/Style';

const ViewDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<IProfileIntervalGridData>,
      IProfileIntervalGridData
    > &
      IThemedProps,
  ) =>
    DataTable<
      IViewDataTableColumn<IProfileIntervalGridData>,
      IProfileIntervalGridData
    >(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
    ${(props) => alternatingTableRowBackground(props)}


    &.combined-profile-intervals-table > * {
    .ant-table-body {
      max-height: 260px !important;
    }
  }

  .holiday-row-light {
    background-color: AntiqueWhite !important;
  }

  .holiday-row-dark {
    background-color: DarkSlateBlue !important;
  }

  &&&& > * {
    .ant-table {
      margin: 0;
    }
  }
`;

interface IProductProfileDataTable {
  data: IProfileIntervalGridData[];
  isUnconstrained: boolean;
  isCombinedIntervalTable: boolean;
  isLoading?: boolean;
}

const ProductProfileDataTable = ({
  data,
  isUnconstrained,
  isCombinedIntervalTable,
  isLoading,
}: IProductProfileDataTable): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  const profileIntervalColumns: IViewDataTableColumn<IProfileIntervalGridData>[] =
    useMemo(
      () => getProfileIntervalColumns(isUnconstrained),
      [isUnconstrained],
    );

  return (
    <ViewDataTable
      className={
        isCombinedIntervalTable
          ? 'combined-profile-intervals-table'
          : 'single-profile-intervals-table'
      }
      rowClassName={(record) =>
        record.isHoliday
          ? currentTheme === ETheme.Dark
            ? 'holiday-row-dark'
            : 'holiday-row-light'
          : 'standard-row'
      }
      columns={profileIntervalColumns}
      currentTheme={currentTheme!}
      data={data}
      pagination={false}
      hasBorders={true}
      scrollConfig={{ y: 140 }}
      isLoading={isLoading ? isLoading : false}
    />
  );
};

export default ProductProfileDataTable;
