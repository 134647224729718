import IconButton from '../../atoms/IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  BUTTON_ICON_DIMENSIONS,
  COLUMN_LAYOUT_SHARED_STYLES,
} from '../../../constants/styles';
import { TTimeZone } from '../../../types/DateTime';
import { TToEntityId } from '../../../types/ToEntity';
import EditorFooter from '../EditorFooter/EditorFooter';
import { encodeIds, isSuccessStatus } from '../../../utils/general';
import Modal from '../Modal/Modal';
import { EActionState } from '../../../enums/General';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../../../constants/time';
import { ZonedDateTime } from '../../../utils/zonedDateTime';
import InputNumber from '../../atoms/InputNumber/InputNumber';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import usePermissions from '../../../hooks/usePermissions';
import {
  ITransmissionAvailabilityDataSet,
  ITransmissionServiceCustomCapacity,
  ITransmissionServiceNetworkResponse,
} from '../../../interfaces/ETag';
import { captureError } from '../../../utils/error';
import { AxiosResponse } from 'axios';
import { saveSingleCustomCapacity } from '../../../services/agent/transmissionAvailability';
import Select, { ISelectProps } from '../Select/Select';
import { IOption } from '../../../interfaces/Component';

const WIDTH = 400;
const LABEL_WIDTH_VALUE = 120;
const INPUT_ITEM_STYLE = `
  flex-grow: 100;
  width: 100px;
`;

const AddIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const Wrapper = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

const ArefSelect = (props: ISelectProps<string>) => Select<string>(props);

const StyledArefSelect = styled(ArefSelect)`
  width: 65%;
`;

interface IStyledLabelProps {
  width?: string;
}

const StyledLabel = styled.div<IStyledLabelProps>`
  font-weight: bold;
  ${(props) =>
    `width: ${
      props.width === undefined ? `${LABEL_WIDTH_VALUE}px` : props.width
    }`};
  padding: 3px;
`;

const StyledInputNumber = styled(InputNumber)`
  ${INPUT_ITEM_STYLE};
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  ${INPUT_ITEM_STYLE};
  > .ant-picker {
    width: 100%;
  }
`;

export interface IAddCustomCapacityProps {
  arefList: string[];
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onSave: () => void;
  timeZone: TTimeZone;
  transmissionAvailabilityDataSets: ITransmissionAvailabilityDataSet[];
  toEntityId: TToEntityId;
  startDate: ZonedDateTime | null;
  stopDate: ZonedDateTime | null;
}

const AddCustomCapacity = (props: IAddCustomCapacityProps): JSX.Element => {
  const {
    arefList,
    encodedPermissionsId,
    isDisabled,
    onSave,
    timeZone,
    transmissionAvailabilityDataSets,
    toEntityId,
    startDate,
    stopDate,
  } = props;
  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedAref, setSelectedAref] = useState<string | undefined>(
    undefined,
  );
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [customCapacitySaveErrorMessage, setCustomCapacitySaveErrorMessage] =
    useState<string | null>(null);
  const permissions = usePermissions(encodedPermissionsId);
  const [startDateTime, setStartDateTime] = useState<ZonedDateTime | null>(
    startDate ? startDate.withHour(0).withMinute(0).withSeconds(0) : null,
  );
  const [stopDateTime, setStopDateTime] = useState<ZonedDateTime | null>(
    stopDate ? stopDate.withHour(0).withMinute(0).withSeconds(0) : null,
  );
  const [customCapacity, setCustomCapacity] = useState<number | null>(null);
  const [arefOptions, setArefOptions] = useState<IOption<string>[]>([]);
  const [arefMinDate, setArefMinDate] = useState<string | null>(null);
  const [arefMaxDate, setArefMaxDate] = useState<string | null>(null);

  useEffect(() => {
    let options: IOption<string>[] = [];
    const uniqueArefList = Array.from(new Set(arefList));
    uniqueArefList.forEach((aref) =>
      options.push({ label: aref, value: aref }),
    );

    setArefOptions(options);
  }, [arefList]);

  useEffect(() => {
    setStartDateTime(startDate);
    setStopDateTime(stopDate);
  }, [startDate, stopDate]);

  useEffect(() => {
    if (!showModal) {
      setActionState(EActionState.NoAction);
      setCustomCapacitySaveErrorMessage(null);
    }
  }, [showModal]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCancel = async () => {
    setActionState(EActionState.Succeeded);
    setSelectedAref(undefined);
    setCustomCapacitySaveErrorMessage(null);
    setShowModal(false);
    setStartDateTime(startDate);
    setStopDateTime(stopDate);
    setCustomCapacity(null);
  };

  const initialCustomCapacity: ITransmissionServiceCustomCapacity = {
    assignment_ref: null,
    start: null,
    stop: null,
    custom_capacity: null,
  };

  const [
    transmissionServiceCustomCapacity,
    setTransmissionServiceCustomCapacity,
  ] = useState<ITransmissionServiceCustomCapacity>(initialCustomCapacity);

  const isSaveEnabled = useMemo(() => {
    let areSelectedDatesInRange = true;
    if (arefMinDate && startDateTime && stopDateTime && arefMaxDate) {
      const minDate = ZonedDateTime.parse(arefMinDate, timeZone, 'MM/DD/YYYY');
      const maxDate = ZonedDateTime.parse(arefMaxDate, timeZone, 'MM/DD/YYYY');

      const startDateTimeWithNoMinutesAndSeconds = startDateTime
        .withMinute(0)
        .withSeconds(0);
      const stopDateTimeWithNoMinutesAndSeconds = stopDateTime
        .withMinute(0)
        .withSeconds(0);

      areSelectedDatesInRange =
        startDateTimeWithNoMinutesAndSeconds.isSameOrAfter(minDate) &&
        stopDateTimeWithNoMinutesAndSeconds.isSameOrBefore(maxDate) &&
        stopDateTimeWithNoMinutesAndSeconds.isSameOrAfter(
          startDateTimeWithNoMinutesAndSeconds,
        );
    }

    if (!areSelectedDatesInRange && selectedAref) {
      if (arefMinDate && arefMaxDate) {
        setCustomCapacitySaveErrorMessage(
          `Dates should be within the range of ${arefMinDate} - ${arefMaxDate}`,
        );
      } else {
        setCustomCapacitySaveErrorMessage(
          'Dates are out of range for the selected AREF',
        );
      }
    } else {
      setCustomCapacitySaveErrorMessage(null);
    }

    return (
      selectedAref &&
      customCapacity &&
      startDateTime &&
      stopDateTime &&
      areSelectedDatesInRange
    );
  }, [
    arefMaxDate,
    arefMinDate,
    customCapacity,
    startDateTime,
    stopDateTime,
    selectedAref,
    timeZone,
  ]);
  const handleSaveCustomCapacity = async () => {
    setActionState(EActionState.Actioning);
    try {
      const response: AxiosResponse<ITransmissionServiceNetworkResponse> =
        await saveSingleCustomCapacity(
          toEntityId,
          transmissionServiceCustomCapacity,
        );
      const customCapacityResponse: any = response.data;
      if (response.status === 400) {
        setActionState(EActionState.Failed);
        setCustomCapacitySaveErrorMessage(
          String(customCapacityResponse.errorMessage),
        );
      } else if (!isSuccessStatus(response.status)) {
        throw new Error(customCapacityResponse.errorMessage!);
      } else {
        setActionState(EActionState.Succeeded);
        setSelectedAref(undefined);
        setCustomCapacitySaveErrorMessage(null);
        setShowModal(false);
        setStartDateTime(startDate);
        setStopDateTime(stopDate);
        setCustomCapacity(null);
        onSave();
      }
    } catch (error: any) {
      captureError(error, `Failed saving Custom Capacity`);
      setActionState(EActionState.Failed);

      setCustomCapacitySaveErrorMessage(
        'Failed saving Custom Capacity. Please try again later.',
      );
    }
  };

  useEffect(() => {
    if (customCapacity && startDateTime && stopDateTime) {
      const newCustomCapacity: ITransmissionServiceCustomCapacity = {
        assignment_ref: transmissionServiceCustomCapacity.assignment_ref,
        custom_capacity: customCapacity,
        start: startDateTime
          .withMinute(0)
          .withSeconds(0)
          .withTimeZone('UTC', false)
          .toIsoString(),
        stop: stopDateTime
          .withMinute(0)
          .withSeconds(0)
          .withTimeZone('UTC', false)
          .toIsoString(),
      };
      setTransmissionServiceCustomCapacity(newCustomCapacity);
    }
  }, [
    transmissionServiceCustomCapacity.assignment_ref,
    customCapacity,
    startDateTime,
    stopDateTime,
  ]);

  const handleCapacityChanged = (capacity: number | undefined) => {
    if (capacity === undefined || capacity < 0) {
      setCustomCapacity(null);
    } else {
      setCustomCapacity(capacity);
    }
  };

  const handleStartChanged = (startDateTime: ZonedDateTime | null) => {
    setStartDateTime(startDateTime);
  };

  const handleStopChanged = (stopDateTime: ZonedDateTime | null) => {
    setStopDateTime(stopDateTime);
  };

  const disabledStartDates = (dateTime: ZonedDateTime | null): boolean => {
    if (stopDateTime && dateTime) {
      return dateTime?.isAfter(stopDateTime, 'day');
    }
    return false;
  };

  const disabledStopDates = (dateTime: ZonedDateTime | null): boolean => {
    if (startDateTime && dateTime) {
      return dateTime?.isBefore(startDateTime, 'day');
    }
    return false;
  };

  const handleArefChange = (selectedAref: string | undefined) => {
    if (selectedAref) {
      const arefMinDate = transmissionAvailabilityDataSets
        .filter((item) => item.aref === selectedAref)
        .map((item) => item.date)
        .reduce((a, b) =>
          Date.parse(a || Date.now().toString()) <
          Date.parse(b || Date.now().toString())
            ? a
            : b,
        );

      const item = transmissionAvailabilityDataSets.filter(
        (item) => item.aref === selectedAref,
      );

      const arefEndDate = item && item.length > 0 && item[0].end_date;
      const arefStartDate = item && item.length > 0 && item[0].start_date;

      let formattedArefMinDate;
      if (arefStartDate) {
        formattedArefMinDate = ZonedDateTime.parse(
          arefStartDate,
          timeZone,
          'YYYY-MM-DD-hh-mm',
        ).format('MM/DD/YYYY');
      }

      let formattedArefMaxDate;
      if (arefEndDate) {
        formattedArefMaxDate = ZonedDateTime.parse(
          arefEndDate,
          timeZone,
          'YYYY-MM-DD-hh-mm',
        ).format('MM/DD/YYYY');
      }

      setArefMinDate(formattedArefMinDate || arefMinDate);
      setArefMaxDate(formattedArefMaxDate || arefMaxDate);
      setTransmissionServiceCustomCapacity({
        ...transmissionServiceCustomCapacity,
        assignment_ref: selectedAref || null,
      });
      setSelectedAref(selectedAref);
    }
  };

  return (
    <>
      <Tooltip title={'Add Custom Capacity'}>
        <IconButton
          buttonRef={buttonRef}
          icon={<AddIcon />}
          isDisabled={isDisabled}
          onClick={handleClick}
        />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            confirmActionState={actionState}
            confirmLabel='Save'
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'footer'],
              toEntityId,
            )}
            errorMessage={customCapacitySaveErrorMessage}
            isConfirmDisabled={!isSaveEnabled}
            onCancel={handleCancel}
            onConfirm={handleSaveCustomCapacity}
          />
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={`Add Custom Capacity`}
        width={WIDTH}
      >
        <Wrapper>
          <SeparatedRowLayout>
            <StyledLabel>AREF Name:</StyledLabel>
            <StyledArefSelect
              allowClear={false}
              isDisabled={!permissions.isExecutable}
              options={arefOptions}
              onChange={handleArefChange}
              value={selectedAref}
              valueToUid={(value: string): string => value}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Start Date:</StyledLabel>
            <StyledDateTimePicker
              allowClear={true}
              isDisabled={!selectedAref}
              onChange={handleStartChanged}
              selectHourOnly={true}
              format={DATE_TIME_FORMAT}
              placeholder='Start Date'
              disabledDate={disabledStartDates}
              showTime={{
                format: TIME_FORMAT,
                defaultValue: ZonedDateTime.now(timeZone).startOf('day'),
              }}
              timeZone={timeZone}
              value={startDateTime}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Stop Date:</StyledLabel>
            <StyledDateTimePicker
              allowClear={true}
              isDisabled={!selectedAref}
              onChange={handleStopChanged}
              defaultPickerValue={
                stopDateTime ?? ZonedDateTime.now(timeZone).startOf('day')
              }
              selectHourOnly={true}
              format={DATE_TIME_FORMAT}
              placeholder='Stop Date'
              disabledDate={disabledStopDates}
              showTime={{
                format: TIME_FORMAT,
                defaultValue: ZonedDateTime.now(timeZone).startOf('day'),
              }}
              timeZone={timeZone}
              value={stopDateTime}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Capacity Granted:</StyledLabel>
            <StyledInputNumber
              hideArrows={true}
              isDisabled={!permissions.isExecutable}
              preventSpecialCharacters={true}
              onChange={handleCapacityChanged}
              value={customCapacity}
            />
          </SeparatedRowLayout>
        </Wrapper>
      </Modal>
    </>
  );
};

export default AddCustomCapacity;
