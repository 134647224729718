import Connector from 'components/organisms/PathDiagramView/Connector';
import {
  LOAD_BLOCK_WIDTH_VALUE,
  SHORT_CONNECTOR_WIDTH_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import Node from 'components/organisms/PathDiagramView/Node';
import { ENodePosition } from 'components/organisms/PathDiagramView/types';
import { IThemedProps } from 'interfaces/Component';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { textColour } from 'utils/styles';

const SINK_WIDTH_VALUE = 22;

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const SinkIcon = styled.div<IThemedProps>`
  height: 3px;
  position: relative;
  width: 3px;

  div:first-child {
    border-left: 3px solid ${(props) => textColour(props)};
    height: 30px;
    position: absolute;
    width: 3px;
  }

  span {
    position: absolute;
    right: -22px;
    top: 53px;
    white-space: nowrap;
  }

  div:last-child {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid ${(props) => textColour(props)};
    left: -9px;
    position: absolute;
    top: 30px;
  }
`;

interface IProps {
  mw: number | null;
  nodeLabel?: string | null;
  nodeSize: number;
}

const LoadDiagram = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { mw, nodeLabel, nodeSize } = props;

  return (
    <Layout>
      <Connector connectorWidth={SHORT_CONNECTOR_WIDTH_VALUE} />
      <Node
        nodeLabel={nodeLabel}
        nodePosition={ENodePosition.Left}
        nodeSize={nodeSize}
      />
      <Connector
        connectorWidth={
          LOAD_BLOCK_WIDTH_VALUE -
          SHORT_CONNECTOR_WIDTH_VALUE -
          nodeSize -
          SINK_WIDTH_VALUE
        }
      />
      <SinkIcon currentTheme={currentTheme!}>
        <div />
        <span>{mw === null ? null : `${mw} MW`}</span>
        <div />
      </SinkIcon>
    </Layout>
  );
};

export default LoadDiagram;
