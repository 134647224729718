import { AxiosResponse } from 'axios';
import {
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_FAX_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_PHONE_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
  EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
} from 'constants/Detail';
import {
  ICON_BUTTON_SIZE_VALUE,
  VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { EPointTypeName } from 'enums/Point';
import { EViewMode } from 'enums/View';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IEditPhysicalPathInformation } from 'interfaces/Detail';
import { IEntityInfo } from 'interfaces/Entity';
import { IContactInfo, IContract, IMiscInfo } from 'interfaces/General';
import { IPointInfo, IRegistryPoint } from 'interfaces/Point';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { IDetailLoadPhysicalSegment } from 'reduxes/Detail/types';
import { retrieveRegistryPoints } from 'services/naesb-registry/registry';
import { TDetailValidations } from 'types/Detail';
import { TToEntityId } from 'types/ToEntity';
import {
  getEditInfoKey,
  getSplitEditInfoKey,
  validateEditInfo,
} from 'utils/detail';
import {
  caEntityInfoEqual,
  entityInfoToString,
  entityInfoToUid,
  moEntityInfoEqual,
  pseEntityInfoEqual,
} from 'utils/entity';
import { captureError } from 'utils/error';
import {
  contactInfosEqual,
  contractsEqual,
  eventToStringOrNull,
  isEmptyValue,
  isSuccessStatus,
  miscInfosEqual,
  selectOptionLabelFilter,
  simpleEqualityChecker,
} from 'utils/general';
import {
  pointInfoEqual,
  pointInfoToString,
  pointInfoToUid,
  registryPointToPointInfoOption,
} from 'utils/point';
import {
  emptyRender,
  getColumnContactInfoEditRender,
  getColumnContactInfoRender,
  getColumnContractsEditRender,
  getColumnEntityInfoRender,
  getColumnMiscInfoEditRender,
  getColumnPointInfoRender,
  getColumnRender,
  getColumnSelectRender,
  getPhysicalPathContactInfoTitle,
  midSelectRender,
} from 'utils/views';

const getLoadContactInfoEditId = (record: IDetailLoadPhysicalSegment): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getLoadContactInfoInitialEditKey = (
  record: IDetailLoadPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getLoadContactInfoPhoneEditId = (record: IContactInfo): string => {
  const { primaryId } = getSplitEditInfoKey(record.key);

  return getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_PHONE_LABEL,
    primaryId,
    0,
  );
};

const getLoadContactInfoFaxEditId = (record: IContactInfo): string => {
  const { primaryId } = getSplitEditInfoKey(record.key);

  return getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_FAX_LABEL,
    primaryId,
    0,
  );
};

const getLoadContractsEditId = (record: IDetailLoadPhysicalSegment): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
    record.physical_segment_id,
    0,
  );

const getLoadContractsInitialEditKey = (
  record: IDetailLoadPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL,
    record.physical_segment_id,
    0,
  );

const getLoadMiscInfoEditId = (record: IDetailLoadPhysicalSegment): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getLoadMiscInfoInitialEditKey = (
  record: IDetailLoadPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const loadMidSelectRender = midSelectRender<IDetailLoadPhysicalSegment>();

const loadMoSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IDetailLoadPhysicalSegment
>('100%');

const sinkSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IDetailLoadPhysicalSegment
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const useLoadEditColumns = (
  detailValidations: TDetailValidations,
  focusKey: string | null,
  lca: IEntityInfo | null,
  pse: IEntityInfo | null,
  initialLoadPhysicalSegment: IDetailLoadPhysicalSegment[] | undefined,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isDetailValidating: boolean,
  isUnconstrained: boolean,
  midOptions: IOption<number>[],
  moOptions: IOption<IEntityInfo>[],
  onChange: (editPhysicalPathInformation: IEditPhysicalPathInformation) => void,
  previousIsDetailDeleted: boolean | undefined,
  previousIsDetailUpdating: boolean | undefined,
  previousIsDetailValidating: boolean | undefined,
  previousMidOptions: IOption<number>[] | undefined,
  previousMoOptions: IOption<IEntityInfo>[] | undefined,
  previousDetailValidations: Record<string, boolean> | undefined,
  setDetailValidation: (id: string, isValid: boolean) => void,
  toEntityId: TToEntityId,
  viewMode: EViewMode,
  isDisabled?: boolean,
) => {
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;
  const hasDetailValidated: boolean =
    isDetailValidating !== previousIsDetailValidating ||
    detailValidations !== previousDetailValidations;
  const isDetailDisabled: boolean = isDetailDeleted || isDetailUpdating;

  const getMidOptions = useCallback(async () => midOptions, [midOptions]);

  const getInitialLoadMidSelectValue = useCallback(
    (): number | null =>
      initialLoadPhysicalSegment === undefined ||
      initialLoadPhysicalSegment[0] === undefined
        ? null
        : initialLoadPhysicalSegment[0].market_segment_id,
    [initialLoadPhysicalSegment],
  );

  const loadMidOnChange = useCallback(
    (value: number | null | undefined) => {
      onChange({
        editLoadPhysicalSegment: {
          market_segment_id: value === undefined ? null : value,
        },
      });
    },
    [onChange],
  );

  const getMoOptions = useCallback(async () => moOptions, [moOptions]);

  const getInitialLoadMoSelectValue = useCallback(
    (): IEntityInfo | null =>
      initialLoadPhysicalSegment === undefined ||
      initialLoadPhysicalSegment[0] === undefined
        ? null
        : initialLoadPhysicalSegment[0].mo_code,
    [initialLoadPhysicalSegment],
  );

  const loadMoOnChange = useCallback(
    (value: IEntityInfo | null | undefined) => {
      onChange({
        editLoadPhysicalSegment: {
          mo_code: value === undefined ? null : value,
        },
      });
    },
    [onChange],
  );

  const shouldGetSinkOptions = useCallback(
    (
      record: IDetailLoadPhysicalSegment,
      previousRecord: IDetailLoadPhysicalSegment | undefined,
    ): boolean =>
      previousRecord === undefined ||
      !caEntityInfoEqual(record.lca, previousRecord.lca) ||
      !pseEntityInfoEqual(record.pse, previousRecord.pse),
    [],
  );

  const getSinkOptions = useCallback(async () => {
    if (lca !== null || pse !== null) {
      try {
        const response: AxiosResponse<IRegistryPoint[]> =
          await retrieveRegistryPoints(
            toEntityId,
            EPointTypeName.Sink,
            lca?.tagging_entity_id,
            pse?.tagging_entity_id,
          );

        if (!isSuccessStatus(response.status)) {
          throw new Error(response.statusText);
        }

        const registryPoints: IRegistryPoint[] = response.data;

        return registryPoints.map(registryPointToPointInfoOption);
      } catch (error: any) {
        captureError(error);
      }
    }

    return [];
  }, [lca, pse, toEntityId]);

  const previousGetSinkOptions = usePrevious(getSinkOptions);

  const getInitialLoadSinkSelectValue = useCallback(
    (): IPointInfo | null =>
      initialLoadPhysicalSegment === undefined ||
      initialLoadPhysicalSegment[0] === undefined
        ? null
        : initialLoadPhysicalSegment[0].load_sink,
    [initialLoadPhysicalSegment],
  );

  const loadSinkOnChange = useCallback(
    (value: IPointInfo | null | undefined) => {
      onChange({
        editLoadPhysicalSegment: {
          load_sink: value === undefined ? null : value,
        },
      });
    },
    [onChange],
  );

  const contractsEditRender = useMemo(
    () =>
      getColumnContractsEditRender<IDetailLoadPhysicalSegment>(
        hasDetailChanged,
      ),
    [hasDetailChanged],
  );

  const getInitialLoadContractData = useCallback(
    (): IContract[] | null =>
      initialLoadPhysicalSegment === undefined ||
      initialLoadPhysicalSegment[0] === undefined
        ? null
        : initialLoadPhysicalSegment[0].contracts,
    [initialLoadPhysicalSegment],
  );

  const loadContractOnAdd = useCallback(
    (value: unknown, contractRecord: IContract) => {
      onChange({
        editLoadPhysicalSegment: {
          editContracts: {
            addAfterContract: contractRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const loadContractOnRemove = useCallback(
    (contractRecord: IContract) => {
      onChange({
        editLoadPhysicalSegment: {
          editContracts: {
            removeContract: contractRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialLoadContractInputValue = useCallback(
    (contractRecord: IContract): string | null => {
      if (
        initialLoadPhysicalSegment === undefined ||
        initialLoadPhysicalSegment[0] === undefined ||
        initialLoadPhysicalSegment[0].contracts === null
      ) {
        return null;
      }

      const initialContract: IContract | undefined =
        initialLoadPhysicalSegment[0].contracts.find(
          (contract: IContract): boolean => contract.key === contractRecord.key,
        );

      return initialContract === undefined ? null : initialContract.contract;
    },
    [initialLoadPhysicalSegment],
  );

  const loadContractInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, contractRecord: IContract) => {
      onChange({
        editLoadPhysicalSegment: {
          editContracts: {
            editContractMap: {
              [contractRecord.key]: { contract: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const contactInfoEditRender = useMemo(
    () =>
      getColumnContactInfoEditRender<IDetailLoadPhysicalSegment>(
        hasDetailChanged || hasDetailValidated,
      ),
    [hasDetailChanged, hasDetailValidated],
  );

  const getInitialLoadContactData = useCallback(
    (): IContactInfo[] | null =>
      initialLoadPhysicalSegment === undefined ||
      initialLoadPhysicalSegment[0] === undefined
        ? null
        : initialLoadPhysicalSegment[0].contactInfos,
    [initialLoadPhysicalSegment],
  );

  const loadContactOnRemove = useCallback(
    (contactInfoRecord: IContactInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editContactInfos: { editContactInfo: null },
        },
      });
    },
    [onChange],
  );

  const getInitialLoadContactInputValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      if (
        initialLoadPhysicalSegment === undefined ||
        initialLoadPhysicalSegment[0] === undefined ||
        initialLoadPhysicalSegment[0].contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialLoadPhysicalSegment[0].contactInfos.find(
          (contactInfo: IContactInfo): boolean =>
            contactInfo.key === contactInfoRecord.key,
        );

      return initialContactInfo === undefined
        ? null
        : initialContactInfo.contact;
    },
    [initialLoadPhysicalSegment],
  );

  const loadContactInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, contactInfoRecord: IContactInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editContactInfos: {
            editContactInfo: { contact: eventToStringOrNull(event) },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialLoadPhoneInputPhoneNumberValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      if (
        initialLoadPhysicalSegment === undefined ||
        initialLoadPhysicalSegment[0] === undefined ||
        initialLoadPhysicalSegment[0].contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialLoadPhysicalSegment[0].contactInfos.find(
          (contactInfo: IContactInfo): boolean =>
            contactInfo.key === contactInfoRecord.key,
        );

      return initialContactInfo === undefined ? null : initialContactInfo.phone;
    },
    [initialLoadPhysicalSegment],
  );

  const loadPhoneInputOnChange = useCallback(
    (value: string | null, contactInfoRecord: IContactInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editContactInfos: { editContactInfo: { phone: value } },
        },
      });
    },
    [onChange],
  );

  const getInitialLoadFaxInputPhoneNumberValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      if (
        initialLoadPhysicalSegment === undefined ||
        initialLoadPhysicalSegment[0] === undefined ||
        initialLoadPhysicalSegment[0].contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialLoadPhysicalSegment[0].contactInfos.find(
          (contactInfo: IContactInfo): boolean =>
            contactInfo.key === contactInfoRecord.key,
        );

      return initialContactInfo === undefined ? null : initialContactInfo.fax;
    },
    [initialLoadPhysicalSegment],
  );

  const loadFaxInputOnChange = useCallback(
    (value: string | null, contactInfoRecord: IContactInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editContactInfos: { editContactInfo: { fax: value } },
        },
      });
    },
    [onChange],
  );

  const miscInfoEditRender =
    getColumnMiscInfoEditRender<IDetailLoadPhysicalSegment>(
      isDisabled,
      hasDetailChanged,
    );

  const getInitialLoadMiscInfoData = useCallback(
    (record: IDetailLoadPhysicalSegment): IMiscInfo[] | null =>
      initialLoadPhysicalSegment === undefined ||
      initialLoadPhysicalSegment[0] === undefined
        ? null
        : initialLoadPhysicalSegment[0].misc_infos,
    [initialLoadPhysicalSegment],
  );

  const loadMiscInfoOnAdd = useCallback(
    (value: unknown, miscInfoRecord: IMiscInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editMiscInfos: {
            addAfterMiscInfo: miscInfoRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const loadMiscInfoOnRemove = useCallback(
    (miscInfoRecord: IMiscInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editMiscInfos: {
            removeMiscInfo: miscInfoRecord.key,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialLoadTokenInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      if (
        initialLoadPhysicalSegment === undefined ||
        initialLoadPhysicalSegment[0] === undefined ||
        initialLoadPhysicalSegment[0].misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialLoadPhysicalSegment[0].misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.token;
    },
    [initialLoadPhysicalSegment],
  );

  const loadTokenInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editMiscInfos: {
            editMiscInfoMap: {
              [miscInfoRecord.key]: { token: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialLoadValueInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      if (
        initialLoadPhysicalSegment === undefined ||
        initialLoadPhysicalSegment[0] === undefined ||
        initialLoadPhysicalSegment[0].misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialLoadPhysicalSegment[0].misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.value;
    },
    [initialLoadPhysicalSegment],
  );

  const loadValueInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      onChange({
        editLoadPhysicalSegment: {
          editMiscInfos: {
            editMiscInfoMap: {
              [miscInfoRecord.key]: { value: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getIsLoadPhoneValid = useCallback(
    (record: IContactInfo) => (isValid: boolean) => {
      setDetailValidation(getLoadContactInfoPhoneEditId(record), isValid);
    },
    [setDetailValidation],
  );

  const getIsLoadFaxValid = useCallback(
    (record: IContactInfo) => (isValid: boolean) => {
      setDetailValidation(getLoadContactInfoFaxEditId(record), isValid);
    },
    [setDetailValidation],
  );

  const getIsLoadContactInfoValid = useCallback(
    (record: IDetailLoadPhysicalSegment): boolean =>
      validateEditInfo(getLoadContactInfoEditId(record), detailValidations),
    [detailValidations],
  );

  return useMemo(() => {
    const midOptionsChanged: boolean = midOptions !== previousMidOptions;
    const moOptionsChanged: boolean = moOptions !== previousMoOptions;
    const sinkOptionsChanged: boolean =
      getSinkOptions !== previousGetSinkOptions;

    return [
      {
        dataIndex: 'physical_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'PID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'market_segment_id',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnRender(isUnconstrained)
            : loadMidSelectRender({
                allowClear: true,
                equalityChecker: simpleEqualityChecker,
                getInitialValue: getInitialLoadMidSelectValue,
                getOptions: getMidOptions,
                isDisabled: isDetailDisabled,
                onChange: loadMidOnChange,
                valueToUid: (value: number | null | undefined): string =>
                  isEmptyValue(value) ? '' : value!.toString(),
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailLoadPhysicalSegment,
                previousRecord: IDetailLoadPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                midOptionsChanged ||
                record.market_segment_id !== previousRecord.market_segment_id,
        title: 'MID',
        width: VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'lca',
        render: getColumnRender(isUnconstrained, entityInfoToString),
        title: 'LCA',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'mo_code',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnEntityInfoRender(isUnconstrained)
            : loadMoSelectRender({
                allowClear: true,
                equalityChecker: moEntityInfoEqual,
                filter: selectOptionLabelFilter,
                getInitialValue: getInitialLoadMoSelectValue,
                getOptions: getMoOptions,
                isDisabled: isDetailDisabled,
                onChange: loadMoOnChange,
                showSearch: true,
                valueToUid: entityInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailLoadPhysicalSegment,
                previousRecord: IDetailLoadPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                moOptionsChanged ||
                (record.mo_code === null
                  ? previousRecord.mo_code !== null
                  : previousRecord.mo_code === null
                  ? true
                  : !(
                      record.mo_code.tagging_entity_id ===
                        previousRecord.mo_code.tagging_entity_id &&
                      record.mo_code.entity_type ===
                        previousRecord.mo_code.entity_type
                    )),
        title: 'MO',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'pse',
        render: getColumnRender(isUnconstrained, entityInfoToString),
        title: 'LSE',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH,
      },
      {
        dataIndex: 'load_sink',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnPointInfoRender(isUnconstrained)
            : sinkSelectRender({
                allowClear: true,
                filter: selectOptionLabelFilter,
                equalityChecker: pointInfoEqual,
                equalityCheckerForOptions: pointInfoEqual,
                getInitialValue: getInitialLoadSinkSelectValue,
                getOptions: getSinkOptions,
                isDisabled: isDetailDisabled,
                onChange: loadSinkOnChange,
                showSearch: true,
                shouldGetOptions: shouldGetSinkOptions,
                valueToUid: pointInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailLoadPhysicalSegment,
                previousRecord: IDetailLoadPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                sinkOptionsChanged ||
                (record.load_sink === null
                  ? previousRecord.load_sink !== null
                  : previousRecord.load_sink === null
                  ? true
                  : !(
                      record.load_sink.point_id ===
                      previousRecord.load_sink.point_id
                    )),
        title: 'Sink',
        width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contracts',
        render: contractsEditRender({
          contractInputColumnConfig: {
            getInitialValue: getInitialLoadContractInputValue,
            getKey: (contractRecord: IContract): string => contractRecord.key,
            isDisabled: isDetailDisabled,
            onChange: loadContractInputOnChange,
            shouldFocusOn: (contractRecord: IContract): boolean =>
              contractRecord.key === focusKey,
          },
          equalityChecker: contractsEqual,
          getId: getLoadContractsEditId,
          getInitialData: getInitialLoadContractData,
          getInitialEditKey: getLoadContractsInitialEditKey,
          getTableTitle: (record: IDetailLoadPhysicalSegment): string =>
            `Edit Load Physical Segment Contracts`,
          onAdd: loadContractOnAdd,
          onRemove: loadContractOnRemove,
        }),
        shouldCellUpdate: (
          record: IDetailLoadPhysicalSegment,
          previousRecord: IDetailLoadPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.contracts === null
            ? previousRecord.contracts !== null
            : previousRecord.contracts === null
            ? true
            : !contractsEqual(record.contracts, previousRecord.contracts)),
        title: 'Contracts',
        width: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contactInfos',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnContactInfoRender(
                isUnconstrained,
                undefined,
                getPhysicalPathContactInfoTitle,
              )
            : contactInfoEditRender({
                contactInputColumnConfig: {
                  getInitialValue: getInitialLoadContactInputValue,
                  getKey: (contactInfoRecord: IContactInfo): string =>
                    contactInfoRecord.key,
                  isDisabled: isDetailDisabled,
                  onChange: loadContactInputOnChange,
                  shouldFocusOn: (contactInfoRecord: IContactInfo): boolean =>
                    contactInfoRecord.key === focusKey,
                },
                equalityChecker: contactInfosEqual,
                faxInputPhoneNumberColumnConfig: {
                  getInitialValue: getInitialLoadFaxInputPhoneNumberValue,
                  getIsValid: getIsLoadFaxValid,
                  isDisabled: isDetailDisabled,
                  onChange: loadFaxInputOnChange,
                },
                getId: getLoadContactInfoEditId,
                getInitialData: getInitialLoadContactData,
                getInitialEditKey: getLoadContactInfoInitialEditKey,
                getIsValid: getIsLoadContactInfoValid,
                getTableTitle: (record: IDetailLoadPhysicalSegment): string =>
                  `Edit Load Physical Segment Contact Info`,
                showErrorMessageWhenInvalid: isDetailValidating,
                onRemove: loadContactOnRemove,
                phoneInputPhoneNumberColumnConfig: {
                  getInitialValue: getInitialLoadPhoneInputPhoneNumberValue,
                  getIsValid: getIsLoadPhoneValid,
                  isDisabled: isDetailDisabled,
                  onChange: loadPhoneInputOnChange,
                },
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailLoadPhysicalSegment,
                previousRecord: IDetailLoadPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                hasDetailValidated ||
                (record.contactInfos === null
                  ? previousRecord.contactInfos !== null
                  : previousRecord.contactInfos === null
                  ? true
                  : !contactInfosEqual(
                      record.contactInfos,
                      previousRecord.contactInfos,
                    )),
        title: 'Contact',
        width: VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'misc_infos',
        render: miscInfoEditRender({
          editButtonWidth:
            viewMode === EViewMode.EditETagCorrection
              ? undefined
              : `${
                  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE -
                  ICON_BUTTON_SIZE_VALUE
                }px`,
          equalityChecker: miscInfosEqual,
          getId: getLoadMiscInfoEditId,
          getInitialData: getInitialLoadMiscInfoData,
          getInitialEditKey: getLoadMiscInfoInitialEditKey,
          getTableTitle: (record: IDetailLoadPhysicalSegment): string =>
            `Edit Load Physical Segment Misc Info`,
          onAdd: loadMiscInfoOnAdd,
          onRemove: loadMiscInfoOnRemove,
          tokenInputColumnConfig: {
            getInitialValue: getInitialLoadTokenInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: loadTokenInputOnChange,
            shouldFocusOn: (miscInfoRecord: IMiscInfo): boolean =>
              miscInfoRecord.key === focusKey,
          },
          valueInputColumnConfig: {
            getInitialValue: getInitialLoadValueInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: loadValueInputOnChange,
          },
        }),
        shouldCellUpdate: (
          record: IDetailLoadPhysicalSegment,
          previousRecord: IDetailLoadPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.misc_infos === null
            ? previousRecord.misc_infos !== null
            : previousRecord.misc_infos === null
            ? true
            : !miscInfosEqual(record.misc_infos, previousRecord.misc_infos)),
        title: 'Misc Info',
        width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
      },
    ];
  }, [
    contactInfoEditRender,
    contractsEditRender,
    focusKey,
    getInitialLoadContactData,
    getInitialLoadContactInputValue,
    getInitialLoadContractData,
    getInitialLoadContractInputValue,
    getInitialLoadFaxInputPhoneNumberValue,
    getInitialLoadMidSelectValue,
    getInitialLoadMiscInfoData,
    getInitialLoadMoSelectValue,
    getInitialLoadPhoneInputPhoneNumberValue,
    getInitialLoadSinkSelectValue,
    getInitialLoadTokenInputValue,
    getInitialLoadValueInputValue,
    getIsLoadContactInfoValid,
    getIsLoadFaxValid,
    getIsLoadPhoneValid,
    getMidOptions,
    getMoOptions,
    getSinkOptions,
    hasDetailChanged,
    hasDetailValidated,
    isDetailDisabled,
    isDetailValidating,
    isUnconstrained,
    loadContactInputOnChange,
    loadContactOnRemove,
    loadContractInputOnChange,
    loadContractOnAdd,
    loadContractOnRemove,
    loadFaxInputOnChange,
    loadMidOnChange,
    loadMiscInfoOnAdd,
    loadMiscInfoOnRemove,
    loadMoOnChange,
    loadPhoneInputOnChange,
    loadSinkOnChange,
    loadTokenInputOnChange,
    loadValueInputOnChange,
    midOptions,
    miscInfoEditRender,
    moOptions,
    previousMidOptions,
    previousMoOptions,
    previousGetSinkOptions,
    shouldGetSinkOptions,
    viewMode,
  ]);
};

export default useLoadEditColumns;
