import { ExpandableConfig } from 'antd/lib/table/interface';
import { useCallback, useMemo, useState } from 'react';
import { TMap } from 'types/General';

interface IuseExpandableDataTable<T, K> {
  expandableConfig: ExpandableConfig<T>;
  updateRowExpandedKey: (expandedKey: K, record: T) => void;
}

const useExpandableDataTable = <T extends object, K>(
  collapsedKey: K,
  getExpandedRowRender: (
    getRowExpandedKey: (record: T) => K | undefined,
  ) => (record: T) => JSX.Element,
  getRowKey: (record: T) => string,
  useNumberedKeys: boolean = false,
): IuseExpandableDataTable<T, K> => {
  const [expandedRowKeysMap, setExpandedRowKeysMap] = useState<TMap<string, K>>(
    {},
  );

  const expandIcon = useCallback(() => <></>, []);

  const getRowExpandedKey = useCallback(
    (record: T): K | undefined => expandedRowKeysMap[getRowKey(record)],
    [expandedRowKeysMap, getRowKey],
  );

  const expandableConfig: ExpandableConfig<T> = useMemo(() => {
    const expandedRowKeys: string[] = Object.keys(expandedRowKeysMap).filter(
      (key: string): boolean => expandedRowKeysMap[key] !== collapsedKey,
    );

    return {
      expandIcon,
      expandedRowKeys: useNumberedKeys
        ? expandedRowKeys.map((expandedRowKey: string): number =>
            parseInt(expandedRowKey, 10),
          )
        : expandedRowKeys,
      expandedRowRender: getExpandedRowRender(getRowExpandedKey),
    };
  }, [
    collapsedKey,
    expandIcon,
    expandedRowKeysMap,
    getExpandedRowRender,
    getRowExpandedKey,
    useNumberedKeys,
  ]);

  const updateRowExpandedKey = useCallback(
    (expandedKey: K, record: T) => {
      setExpandedRowKeysMap(
        (previousExpandedRowKeysMap: TMap<string, K>): TMap<string, K> => {
          const rowKey: string = getRowKey(record);
          const previousRowExpandedKey: K | undefined =
            previousExpandedRowKeysMap[rowKey];

          if (previousRowExpandedKey === undefined) {
            return {
              ...previousExpandedRowKeysMap,
              [rowKey]: expandedKey,
            };
          }

          return {
            ...previousExpandedRowKeysMap,
            [rowKey]:
              previousRowExpandedKey === expandedKey
                ? collapsedKey
                : expandedKey,
          };
        },
      );
    },
    [collapsedKey, getRowKey],
  );

  return { expandableConfig, updateRowExpandedKey };
};

export default useExpandableDataTable;
