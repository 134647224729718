import { getTextWidthForElement } from 'components/molecules/ContainedText/helpers';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { TPlacement } from 'types/General';

interface IContainerProps {
  maxWidth: string;
}

const Container = styled.div<IContainerProps>`
  max-width: ${(props) => props.maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IProps {
  className?: string;
  maxWidth: string;
  text: string;
  tooltipPlacement?: TPlacement;
  tooltipText?: string;
  renderTextAsHtml?: boolean;
}

const ContainedText = ({
  className,
  maxWidth,
  text,
  tooltipPlacement,
  tooltipText,
  renderTextAsHtml,
}: IProps): JSX.Element => {
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const containerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (containerRef.current !== undefined) {
      if (
        containerRef.current.offsetWidth < containerRef.current.scrollWidth ||
        getTextWidthForElement(containerRef.current) >
          containerRef.current.clientWidth
      ) {
        setIsOverflowed(true);
      }
    }
  }, [containerRef, text]);

  const shouldTitleTextBeWrapped: boolean = useMemo(() => {
    return (
      (!!tooltipText && tooltipText.toString().split(',').length > 11) ||
      (!!text && text.toString().split(',').length > 11)
    );
  }, [text, tooltipText]);

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={
        tooltipText === undefined
          ? isOverflowed
            ? text
            : undefined
          : tooltipText
      }
      wrapTextTitle={shouldTitleTextBeWrapped}
    >
      {renderTextAsHtml ? (
        <Container
          className={className}
          maxWidth={maxWidth}
          ref={containerRef}
          dangerouslySetInnerHTML={{ __html: text }}
        ></Container>
      ) : (
        <Container className={className} maxWidth={maxWidth} ref={containerRef}>
          {text}
        </Container>
      )}
    </Tooltip>
  );
};

export default ContainedText;
