interface IImplementedIconProps {
  className?: string;
}

const ImplementedIcon = ({ className }: IImplementedIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#228b22'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M171.703 244.386h599.103l128.137 128.137-128.137 128.138H169.749v-2l125.194-128.138-123.24-126.137z'
      fill='none'
      stroke='#fff'
      strokeWidth='72.26'
      transform='matrix(.8914 0 0 1.36572 23.687 -21.263)'
    />
  </svg>
);

export default ImplementedIcon;
