import { LINK_BOX_WIDTH_VALUE } from 'components/pages/LandingPage/constants';
import { bgColorForTheme } from 'components/pages/LandingPage/helpers';
import { IThemedProps } from 'interfaces/Component';
import styled from 'styled-components';
import { colourDisabledForTheme, colourForTheme } from 'utils/styles';

interface ILinkBoxProps extends IThemedProps {
  implemented: boolean;
}

const LinkBox = styled.div<ILinkBoxProps>`
  align-items: center;
  background-color: ${bgColorForTheme};
  display: flex;
  font-size: 120%;
  font-weight: bold;
  height: 115px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 8px;
  padding-top: 8px;
  width: ${LINK_BOX_WIDTH_VALUE}px;

  ${(props) => {
    return props.implemented
      ? `border: 1px solid black; color: ${colourForTheme(
          props,
        )}; cursor: pointer;`
      : `border: 1px dashed black; color: ${colourDisabledForTheme(props)};`;
  }}

  > .anticon {
    font-size: 30px;
    font-weight: bold;
    padding-right: 10px;

    ${(props) =>
      props.implemented
        ? `color: ${colourForTheme};`
        : `color: ${colourDisabledForTheme};`}
  }
`;

export default LinkBox;
