import { CloseCircleOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import TextArea from 'components/atoms/TextArea/TextArea';
import ContactInfoEditable from 'components/molecules/ContactInfoEditable/ContactInfoEditable';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import ValidationSubmissionPanel from 'components/molecules/ValidationSubmissionPanel/ValidationSubmissionPanel';
import { NOTES_MAX_LENGTH } from 'constants/Detail';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import { ECompositeState, ERequestType } from 'enums/ETag';
import useContactInfoEditable from 'hooks/useContactInfoEditable';
import { IETagTagId, IETagTerminateTagRequest } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { ChangeEvent, useCallback, useState } from 'react';
import {
  requestTerminateTag,
  validateRequestTerminateTag,
} from 'services/agent/tags/requests';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { toToEntityId } from 'utils/entity';
import { encodeIds, isEmptyValue } from 'utils/general';
import { toFormattedUtcString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

const TERMINATE_TAG_CONTACT_INFO_EDIT_KEY = 'terminateTagContactInfoEditKey';

const Label = styled.span`
  max-width: 130px;
  width: 100%;
`;

const TerminateTagIcon = styled(CloseCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagTerminateTagPanelProps {
  defaultContactInfo?: IContactInfo;
  isContactInfoDisabled?: boolean;
  securityKey: string;
  shouldContactInfoUpdate?: boolean;
  tagId: IETagTagId | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagTerminateTagPanel = (
  props: IToEntityETagTerminateTagPanelProps,
): JSX.Element => {
  const {
    defaultContactInfo,
    isContactInfoDisabled,
    securityKey,
    shouldContactInfoUpdate,
    tagId,
    timeZone,
    toEntityId,
  } = props;
  const [start, setStart] = useState<ZonedDateTime | null>(null);
  const [startRampDur, setStartRampDur] = useState<number | null>(null);
  const [notes, setNotes] = useState<string | null>(null);

  const { contactInfoEditableProps, contactInfo, isFaxValid, isPhoneValid } =
    useContactInfoEditable(
      TERMINATE_TAG_CONTACT_INFO_EDIT_KEY,
      isContactInfoDisabled,
      shouldContactInfoUpdate,
      defaultContactInfo,
    );

  const handleNotesInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const createRequestPostBodyPayload =
    useCallback((): IETagTerminateTagRequest => {
      if (start === null) {
        throw new Error(
          'Illegal state: start must not be null to create request body payload.',
        );
      } else if (isEmptyValue(contactInfo.contact)) {
        throw new Error(
          'Illegal state: contact info must not have empty values to create request body payload.',
        );
      } else if (tagId === null) {
        throw new Error(
          'Illegal state: tagId must not be null to create request body payload.',
        );
      }

      return {
        tag_id: {
          ...tagId,
          security_key: securityKey,
          request_type: ERequestType.Termination,
        },
        start: toFormattedUtcString(start),
        start_ramp_dur: startRampDur,
        contact_info: contactInfo,
        notes: notes ?? undefined,
      };
    }, [contactInfo, notes, securityKey, start, startRampDur, tagId]);

  const validateRequest = useCallback(async () => {
    return await validateRequestTerminateTag(
      toEntityId,
      timeZone,
      createRequestPostBodyPayload(),
    );
  }, [createRequestPostBodyPayload, timeZone, toEntityId]);

  const validateAndSubmitRequest = useCallback(
    async (skipValidation: boolean) => {
      return await requestTerminateTag(
        toEntityId,
        skipValidation,
        timeZone,
        createRequestPostBodyPayload(),
      );
    },
    [createRequestPostBodyPayload, timeZone, toEntityId],
  );

  const isDisabled: boolean =
    start === null ||
    isEmptyValue(contactInfo.contact) ||
    !isFaxValid ||
    !isPhoneValid;

  return (
    <ValidationSubmissionPanel
      isValidateAndSubmitDisabled={isDisabled}
      isValidateDisabled={isDisabled}
      isValidateHidden={false}
      requestDisplayName='Termination'
      validateAndSubmitRequest={validateAndSubmitRequest}
      validateRequest={validateRequest}
    >
      <SeparatedRowLayout>
        <Label>Start:</Label>
        <DateTimePicker
          allowClear={false}
          onChange={setStart}
          placeholder='Termination Start'
          selectHourOnly={false}
          format={DATE_TIME_FORMAT}
          showTime={{ format: TIME_FORMAT }}
          timeZone={timeZone}
          value={start}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <Label>
          Start Ramp Duration:
          <br />
          (optional)
        </Label>
        <InputNumber
          hideArrows={true}
          min={0}
          onChange={(value: number | undefined) =>
            setStartRampDur(value ?? null)
          }
          value={startRampDur}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <Label>
          Notes:
          <br />
          (optional)
        </Label>
        <TextArea
          maxLength={NOTES_MAX_LENGTH}
          onChange={handleNotesInput}
          placeholder='Termination Notes'
          rows={2}
          value={notes ?? undefined}
        />
      </SeparatedRowLayout>
      <ContactInfoEditable {...contactInfoEditableProps} />
    </ValidationSubmissionPanel>
  );
};

interface IProps {
  compositeState: ECompositeState | null;
  defaultContactInfo?: IContactInfo;
  encodedPermissionsId: string;
  isDisabled: boolean;
  securityKey: string;
  shouldContactInfoUpdate?: boolean;
  tagId: IETagTagId | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagTerminateTag = (props: IProps): JSX.Element => {
  const {
    compositeState,
    defaultContactInfo,
    encodedPermissionsId,
    isDisabled,
    securityKey,
    shouldContactInfoUpdate,
    tagId,
    timeZone,
    toEntityId,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const adjustedIsDisabled: boolean =
    isDisabled ||
    isVisible ||
    tagId === null ||
    !(
      compositeState === ECompositeState.Confirmed ||
      compositeState === ECompositeState.Implemented ||
      compositeState === ECompositeState.Terminated
    ) ||
    tagId.pse === null ||
    toEntityId !== toToEntityId(tagId.pse);

  return (
    <Tooltip isDisabled={isDisabled || isVisible} title='Terminate Tag'>
      <AntDesignPopover
        content={
          <ToEntityETagTerminateTagPanel
            defaultContactInfo={defaultContactInfo}
            isContactInfoDisabled={isDisabled}
            securityKey={securityKey}
            shouldContactInfoUpdate={shouldContactInfoUpdate}
            tagId={tagId}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        title={'Termination Request'}
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<TerminateTagIcon />}
          isDisabled={adjustedIsDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagTerminateTag;
