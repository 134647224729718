import { combinePermissions } from 'components/pages/LandingPage/helpers';
import LinkBox from 'components/pages/LandingPage/LinkBox';
import NameAndDescription from 'components/pages/LandingPage/NameAndDescription';
import { IStyledLinkProps } from 'components/pages/LandingPage/types';

const FutureLink = ({
  currentTheme,
  description,
  icon,
  name,
  permissionId,
  toEntities,
}: IStyledLinkProps): JSX.Element => {
  const { isDisplayable } = combinePermissions(permissionId, toEntities);

  if (isDisplayable) {
    return (
      <LinkBox implemented={false} currentTheme={currentTheme}>
        {icon}
        <NameAndDescription description={description} name={name} />
      </LinkBox>
    );
  } else {
    return <></>;
  }
};

export default FutureLink;
