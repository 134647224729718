import { IApprovalHistoryDataSet } from 'components/organisms/ApprovalHistory/types';
import { IViewDataTableColumn } from 'interfaces/View';
import { TTimeZone } from 'types/DateTime';
import { getColumnRender, getDateTimeColumnRender } from 'utils/views';

export const getApprovalHistoryColumns = (
  timeZone: TTimeZone,
): IViewDataTableColumn<IApprovalHistoryDataSet>[] => {
  return [
    {
      dataIndex: 'requestId',
      render: getColumnRender(false),
      title: 'Request ID',
      width: '80px',
    },
    {
      dataIndex: 'actionTaken',
      render: getColumnRender(false),
      title: 'Action Taken',
      width: '120px',
    },
    {
      dataIndex: 'reason',
      render: getColumnRender(false),
      title: 'Reason',
    },
    {
      dataIndex: 'actionTakenBy',
      render: getColumnRender(false),
      title: 'Action Taken By',
    },
    {
      dataIndex: 'actionTakenTime',
      render: getDateTimeColumnRender(timeZone, false),
      title: 'Action Taken Time',
    },
  ];
};
