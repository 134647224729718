import { Divider, Tooltip } from 'antd';
import Button from 'components/atoms/Button/Button';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Spinner from 'components/atoms/Spinner/Spinner';
import ConfigurationSelector from 'components/molecules/ConfigurationSelector/ConfigurationSelector';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import { SHOW_TIME_CONFIGURATION } from 'components/molecules/ScheduleSubmission/constants';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
  TABLE_CONFIGURATION_SELECT_WIDTH_VALUE,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import usePermissions from 'hooks/usePermissions';
import { IOption } from 'interfaces/Component';
import { ICustomFilter } from 'interfaces/Filter';
import { ITableConfiguration } from 'interfaces/Summary';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TTableConfigurationOptions } from 'types/Summary';
import { customFilterToUid } from 'utils/filter';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};
  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

const AlignFormRight = styled.div`
  margin-left: auto;
  margin-right: 0;
`;

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
`;

const StyledDivider = styled(Divider)`
  height: 300px;
`;
interface IToEntityReportCreatePanelProps {
  createReport: () => void;
  customFilterOptions: IOption<ICustomFilter>[];
  encodedPermissionsId: string;
  isCreateDisabled: boolean;
  isLoading: boolean;
  onFilterChange: (value: ICustomFilter | undefined) => void;
  onStartDateTimeChange: (value: ZonedDateTime | null) => void;
  onStopDateTimeChange: (value: ZonedDateTime | null) => void;
  onTableConfigurationChange: (value: ITableConfiguration | undefined) => void;
  selectedFilter: ICustomFilter | undefined;
  selectedTableConfiguration: ITableConfiguration | undefined;
  showTimedMessage: boolean;
  startDateTime: ZonedDateTime | null;
  stopDateTime: ZonedDateTime | null;
  tableConfigurationOptions: TTableConfigurationOptions;
  timedMessage: JSX.Element;
  timeZone: TTimeZone;
}

const ToEntityReportCreatePanel = ({
  createReport,
  customFilterOptions,
  encodedPermissionsId,
  isCreateDisabled,
  isLoading,
  onFilterChange,
  onStartDateTimeChange,
  onStopDateTimeChange,
  onTableConfigurationChange,
  selectedFilter,
  selectedTableConfiguration,
  showTimedMessage,
  startDateTime,
  stopDateTime,
  tableConfigurationOptions,
  timedMessage,
  timeZone,
}: IToEntityReportCreatePanelProps): JSX.Element => {
  const permissions = usePermissions(encodedPermissionsId);

  const disabledStartDates = (dateTime: ZonedDateTime | null): boolean => {
    if (stopDateTime && dateTime) {
      return dateTime?.isSameOrAfter(stopDateTime);
    }
    return false;
  };

  const disabledStopDates = (dateTime: ZonedDateTime | null): boolean => {
    if (startDateTime && dateTime) {
      return dateTime?.isSameOrBefore(startDateTime);
    }
    return false;
  };
  return permissions.isDisplayable ? (
    <>
      <Layout>
        {
          <SeparatedRowLayout>
            <div>Report Start Time:</div>
            <AlignFormRight>
              <DateTimePicker
                allowClear={false}
                disabledDate={disabledStartDates}
                format={DATE_TIME_FORMAT}
                onChange={onStartDateTimeChange}
                selectHourOnly={true}
                showTime={SHOW_TIME_CONFIGURATION}
                timeZone={timeZone}
                value={startDateTime}
              />
            </AlignFormRight>
          </SeparatedRowLayout>
        }
        {
          <SeparatedRowLayout>
            <div>Report Stop Time:</div>
            <AlignFormRight>
              <DateTimePicker
                allowClear={false}
                disabledDate={disabledStopDates}
                format={DATE_TIME_FORMAT}
                onChange={onStopDateTimeChange}
                selectHourOnly={true}
                showTime={SHOW_TIME_CONFIGURATION}
                timeZone={timeZone}
                value={stopDateTime}
              />
            </AlignFormRight>
          </SeparatedRowLayout>
        }
        {
          <SeparatedRowLayout>
            <div>Filter Name:</div>
            <AlignFormRight>
              <Tooltip title='Custom Filter Selection'>
                <ConfigurationSelector<ICustomFilter>
                  allowClear={true}
                  onChange={onFilterChange}
                  options={customFilterOptions}
                  placeholder='Select Custom Filter'
                  value={selectedFilter}
                  valueToUid={customFilterToUid}
                  width={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
                />
              </Tooltip>
            </AlignFormRight>
          </SeparatedRowLayout>
        }
        {
          <SeparatedRowLayout>
            <div>Summary View Name:</div>
            <AlignFormRight>
              <Tooltip title='Table Configuration Selection'>
                <ConfigurationSelector<ITableConfiguration>
                  onChange={onTableConfigurationChange}
                  options={tableConfigurationOptions}
                  value={selectedTableConfiguration}
                  valueToUid={(value: ITableConfiguration): string =>
                    value.id.toString()
                  }
                  width={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
                />
              </Tooltip>
            </AlignFormRight>
          </SeparatedRowLayout>
        }
        <Actions>
          {showTimedMessage ? timedMessage : null}
          {isLoading ? <Spinner /> : null}
          <Button
            isDisabled={isCreateDisabled}
            isPrimary={true}
            label={'Create'}
            onClick={createReport}
          />
        </Actions>
      </Layout>
      <StyledDivider type='vertical' />
    </>
  ) : (
    <></>
  );
};

export default ToEntityReportCreatePanel;
