import ZonedTimePicker from 'components/molecules/TimePicker/ZonedTimePicker';
import { INPUT_HEIGHT_VALUE } from 'constants/styles';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { setTimeZonePreserveLocalSafe } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const Wrapper = styled.div`
  height: ${INPUT_HEIGHT_VALUE}px;
`;

export interface ITimePickerProps {
  className?: string;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
  format?: string;
  isDisabled?: boolean;
  onChange?: (time: ZonedDateTime | null) => void;
  showNow?: boolean;
  timeZone: TTimeZone;
  value?: ZonedDateTime | null;
}

const TimePicker = (props: ITimePickerProps): JSX.Element => {
  const {
    className,
    disabledHours,
    disabledMinutes,
    disabledSeconds,
    format,
    isDisabled,
    onChange,
    showNow,
    timeZone,
    value,
  } = props;
  const selectedTimeRef = useRef<ZonedDateTime | undefined | null>();

  useEffect(() => {
    if (value !== null && value !== undefined) {
      if (value.timeZone() !== timeZone) {
        selectedTimeRef.current = setTimeZonePreserveLocalSafe(value, timeZone);

        if (onChange !== undefined) {
          onChange(selectedTimeRef.current);
        }
      }
    }
  }, [onChange, timeZone, value]);

  const handleChange = (dateTime: ZonedDateTime | null) => {
    if (onChange !== undefined) {
      onChange(setTimeZonePreserveLocalSafe(dateTime, timeZone));
    }
  };

  return (
    <Wrapper className={className}>
      <ZonedTimePicker
        disabled={isDisabled}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
        disabledSeconds={disabledSeconds}
        format={format}
        onChange={handleChange}
        showNow={showNow}
        timeZone={timeZone}
        value={value ?? null}
      />
    </Wrapper>
  );
};

export default TimePicker;
