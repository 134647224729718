import { HTTP_AND_HTTPS_PREFIX_REGEX } from 'constants/General';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  external?: boolean;
  to: string;
}

const Link = ({ children, external = false, to, ...rest }: ILinkProps) => {
  return external || HTTP_AND_HTTPS_PREFIX_REGEX.test(to) ? (
    <a href={to} {...rest}>
      {children}
    </a>
  ) : (
    <ReactRouterLink to={to} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;
