import { SUCCESS_MESSAGE_MAX_WIDTH } from 'components/atoms/SuccessMessage/constants';
import { STANDARD_SPACING_VALUE, SUCCESS_GREEN } from 'constants/styles';
import styled from 'styled-components';

interface ISuccessMessageProps {
  maxWidth?: string;
  topMargin?: number;
}

const SuccessMessage = styled.span<ISuccessMessageProps>`
  color: ${SUCCESS_GREEN};
  font-weight: bold;
  margin-top: ${(props) =>
    props.topMargin === undefined ? STANDARD_SPACING_VALUE : props.topMargin}px;
  max-width: ${(props) =>
    props.maxWidth === undefined ? SUCCESS_MESSAGE_MAX_WIDTH : props.maxWidth};
  text-align: left;
`;

export default SuccessMessage;
