import {
  EDIT_MARKET_INFOS_LABEL,
  EDIT_MISO_MARKET_DATA_LABEL,
  EDIT_SPP_MARKET_DATA_LABEL,
  INITIAL_RECORD_ID,
} from 'constants/Detail';
import { MISO_MARKET_DETAIL, SPP_MARKET_DETAIL } from 'constants/ETag';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/time';
import { EMarketInfoMarket } from 'enums/ETag';
import { ESeverity } from 'enums/General';
import { IOption, IValidationMessage } from 'interfaces/Component';
import { IEditMarketInfo } from 'interfaces/Detail';
import {
  IETagMarketDetail,
  IETagMarketInfo,
  IETagMisoMarketData,
  IETagSppMarketData,
} from 'interfaces/ETag';
import { IDetailState } from 'reduxes/Detail/types';
import { TDetailValidations } from 'types/Detail';
import { TStateTransform } from 'types/General';
import { getEditInfoKey, getSplitEditInfoKey } from 'utils/detail';
import { getMarketDetailOptionForMarket } from 'utils/eTag';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getMarketDetailOptionsForEdit = (
  markets: EMarketInfoMarket[],
): IOption<IETagMarketDetail>[] => markets.map(getMarketDetailOptionForMarket);

export const getMarketDetailOptionsForReview = (
  marketInfos: IETagMarketInfo[],
): IOption<IETagMarketDetail>[] =>
  marketInfos.map(
    (marketInfo: IETagMarketInfo): IOption<IETagMarketDetail> =>
      getMarketDetailOptionForMarket(marketInfo.market_info_market),
  );

export const marketDetailToUid = (marketDetail: IETagMarketDetail): string =>
  marketDetail.market as string;

export const getDateTimeFormat = (marketDetail: IETagMarketDetail): string => {
  switch (marketDetail.market) {
    case EMarketInfoMarket.MISO: {
      return DATE_TIME_FORMAT;
    }
    case EMarketInfoMarket.SPP: {
      return DATE_FORMAT;
    }
    default: {
      throw new Error(`Invalid marketDetail.market: ${marketDetail.market}`);
    }
  }
};

const getEditMarketDataLabel = (market: EMarketInfoMarket): string => {
  switch (market) {
    case EMarketInfoMarket.MISO: {
      return EDIT_MISO_MARKET_DATA_LABEL;
    }
    case EMarketInfoMarket.SPP: {
      return EDIT_SPP_MARKET_DATA_LABEL;
    }
    default: {
      throw new Error(`Invalid market: ${market}`);
    }
  }
};

export const updateMarketInfoDataKeys = (marketInfos: IETagMarketInfo[]) => {
  marketInfos.forEach((marketInfo: IETagMarketInfo, index: number) => {
    const { data, market_info_market } = marketInfo;

    data.key = getEditInfoKey(
      getEditMarketDataLabel(market_info_market),
      1,
      index,
    );
  });
};

export const marketInfosToDetailState = (
  marketInfos: IETagMarketInfo[],
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => ({
    ...detailState,
    marketInfos,
  });
};

export const editMarketInfoToDetailState = (
  editMarketInfo: IEditMarketInfo,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedDetailState: IDetailState = { ...detailState };

    updatedDetailState.marketInfos = updatedDetailState.marketInfos.map(
      (marketInfo: IETagMarketInfo): IETagMarketInfo => {
        const { data, market_info_market } = marketInfo;

        if (market_info_market === editMarketInfo.market) {
          const { key } = data;
          const { editIndex, label, primaryId } = getSplitEditInfoKey(key);

          return {
            data: {
              ...data,
              ...editMarketInfo.data,
              key:
                primaryId === INITIAL_RECORD_ID
                  ? getEditInfoKey(label, 1, editIndex)
                  : key,
            },
            market_info_market,
          };
        }

        return marketInfo;
      },
    );

    return updatedDetailState;
  };
};

export const validateMarketInfos = (
  hasMISOOnMarketSegment = false,
  hasSWPPOnMarketSegment = false,
) => {
  const detailValidations: TDetailValidations = {};
  const validationMessages: IValidationMessage[] = [];

  let isMarketInfosValid: boolean = true;

  if (!hasMISOOnMarketSegment && !hasSWPPOnMarketSegment) {
    validationMessages.push({
      message: `SWPP/MISO are not part of this tag, market information is not required`,
      severity: ESeverity.Info,
    });
  }

  if (hasMISOOnMarketSegment && hasSWPPOnMarketSegment) {
    validationMessages.push({
      message: `Required MISO and SWPP as market information`,
      severity: ESeverity.Error,
    });
  } else {
    if (hasMISOOnMarketSegment) {
      validationMessages.push({
        message: `Required MISO as market information`,
        severity: ESeverity.Error,
      });
    }

    if (hasSWPPOnMarketSegment) {
      validationMessages.push({
        message: `Required SWPP as market information`,
        severity: ESeverity.Error,
      });
    }
  }

  detailValidations[getEditInfoKey(EDIT_MARKET_INFOS_LABEL, 0, 0)] =
    isMarketInfosValid;

  return {
    detailValidations,
    validationMessages,
  };
};

export const getInitialMarketDate = (
  market?: EMarketInfoMarket,
  initialMarketInfos?: IETagMarketInfo[],
): ZonedDateTime | null | undefined => {
  if (market === undefined) {
    return undefined;
  }

  const initialMarketInfo: IETagMarketInfo | undefined =
    initialMarketInfos?.find(
      (marketInfo: IETagMarketInfo): boolean =>
        marketInfo.market_info_market === market,
    );

  if (initialMarketInfo === undefined) {
    return null;
  }

  if (market === EMarketInfoMarket.MISO) {
    const marketDate: string | null | undefined = (
      initialMarketInfo.data as IETagMisoMarketData
    ).miso_market_date;

    if (isEmptyValue(marketDate)) {
      return null;
    }

    return ZonedDateTime.parseIso(marketDate!, MISO_MARKET_DETAIL.timeZone);
  } else if (market === EMarketInfoMarket.SPP) {
    const marketDate: string | null | undefined = (
      initialMarketInfo.data as IETagSppMarketData
    ).spp_market_date;

    if (isEmptyValue(marketDate)) {
      return null;
    }

    return ZonedDateTime.parseIso(marketDate!, SPP_MARKET_DETAIL.timeZone);
  } else {
    throw new Error(`Invalid market: ${market}`);
  }
};
