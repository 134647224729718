import styled from 'styled-components';
import { ETheme } from '../../../../enums/Style';
import {
  COMPONENT_BACKGROUND_DARK,
  COMPONENT_BACKGROUND_LIGHT,
  TEXT_DARK,
  TEXT_LIGHT,
} from '../../../../constants/styles';

interface IStyledMenu {
  open: boolean;
  currentTheme?: string;
}

export const StyledMenu = styled.div<IStyledMenu>`
  display: flex;
  flex-direction: column;
  background: ${({ currentTheme }) =>
    currentTheme === ETheme.Light
      ? COMPONENT_BACKGROUND_LIGHT
      : COMPONENT_BACKGROUND_DARK};
  color: ${({ currentTheme }) =>
    currentTheme === ETheme.Light ? TEXT_LIGHT : TEXT_DARK};
  text-align: left;
  padding: 10px;
  position: absolute;
  left: 0;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  font-size: 16px;

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  .menu-option {
    :hover {
      color: #1a80de;
    }
    padding: 0 10px 0 5px;
    cursor: pointer;
    .anticon {
      padding-right: 5px;
    }
  }

  .menu-option-icon {
    display: flex;
    :hover {
      color: #1a80de;
    }
    padding: 0 10px 0 5px;
    cursor: pointer;
    .ant-btn {
      margin-right: 5px;
    }
  }

  .menu-option-link {
    a {
      color: ${({ currentTheme }) =>
        currentTheme === ETheme.Light ? TEXT_LIGHT : TEXT_DARK};
    }
    text-decoration: none;
    display: flex;
    :hover {
      color: #1a80de;
    }
    padding: 0 10px 0 5px;
    cursor: pointer;
    .ant-btn {
      margin-right: 5px;
    }
  }

  .footer {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;

    a {
      letter-spacing: normal;
      font-weight: normal;
      font-size: 18px;
      padding: 0;
      text-decoration: none;
      text-transform: none;
    }

    span,
    a {
      :hover {
        color: #1a80de;
      }
      cursor: pointer;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  a {
    color: ${({ currentTheme }) =>
      currentTheme === ETheme.Light ? TEXT_LIGHT : TEXT_DARK};
  }
`;
