import CompositeStateIcon from 'components/molecules/CompositeStateIcon/CompositeStateIcon';
import RequestStatusIcon from 'components/molecules/RequestStatusIcon/RequestStatusIcon';
import RequestTypeIcon from 'components/molecules/RequestTypeIcon/RequestTypeIcon';
import {
  LEGEND_CELL_BORDER,
  LEGEND_CELL_CONTENT_PADDING,
  STANDARD_SPACING,
} from 'constants/styles';
import { ECompositeState, ERequestStatus, ERequestType } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { getBackgroundColourForLastRequestType } from 'utils/detail';
import { compositeStateToColour } from 'utils/eTag';
import { isEmptyValue } from 'utils/general';

const Layout = styled.div`
  h3,
  h4 {
    margin: 0;
  }

  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

const StyledTd = styled.td`
  border: ${LEGEND_CELL_BORDER};
  height: 32px;
  padding: ${LEGEND_CELL_CONTENT_PADDING};
  text-align: center;
`;

const TableLayout = styled.div`
  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

interface ILegendLabelProps {
  colour?: string;
}

const LegendLabel = styled.div<ILegendLabelProps>`
  font-weight: bold;

  ${(props) => (props.colour === undefined ? '' : `color: ${props.colour};`)}
`;

interface ILegendColourProps {
  colour?: string;
}

const LegendColour = styled.div<ILegendColourProps>`
  background-color: ${(props) => props.colour};
  height: 100%;
  width: 100%;
`;

const TitleFiller = styled.div`
  height: 23px;
`;

interface ILegendTableProps {
  columns: string[];
  data: ReactNode[][];
  title?: string;
  useFiller?: boolean;
}

const LegendTable = (props: ILegendTableProps): JSX.Element => {
  const { columns, data, title, useFiller } = props;

  return (
    <TableLayout>
      {isEmptyValue(title) ? (
        useFiller ? (
          <TitleFiller />
        ) : null
      ) : (
        <h3>{title}</h3>
      )}
      <table>
        <thead>
          <tr>
            {columns.map((column: string, index: number) => (
              <StyledTd key={`column_${index}`}>
                <h4>{column}</h4>
              </StyledTd>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(
            (row: ReactNode[], index: number): JSX.Element => (
              <tr key={`row_${index}`}>
                {row.map(
                  (cell: ReactNode, index: number): JSX.Element => (
                    <StyledTd key={`cell_${index}`}>{cell}</StyledTd>
                  ),
                )}
              </tr>
            ),
          )}
        </tbody>
      </table>
    </TableLayout>
  );
};

interface IProps {
  viewMode: EViewMode;
}

const Legend = (props: IProps): JSX.Element => {
  const { viewMode } = props;

  return (
    <Layout>
      <LayoutRow>
        <LegendTable
          columns={['Composite State', 'Icon']}
          data={[
            ECompositeState.Draft,
            ECompositeState.Pending,
            ECompositeState.Confirmed,
            ECompositeState.Implemented,
            ECompositeState.Terminated,
            ECompositeState.Cancelled,
            ECompositeState.Withdrawn,
            ECompositeState.Denied,
            ECompositeState.Expired,
          ].map((value: ECompositeState): ReactNode[] => [
            <LegendLabel colour={compositeStateToColour(value)}>
              {value}
            </LegendLabel>,
            <CompositeStateIcon compositeState={value} />,
          ])}
          title='Header Legend'
        />
        {viewMode === EViewMode.ReviewETag ? (
          <>
            <LegendTable
              columns={['Request Type', 'Icon']}
              data={[
                ERequestType.CurrentLevel,
                ERequestType.CurrentPendingLevel,
                ERequestType.NewTag,
                ERequestType.Correction,
                ERequestType.Curtailment,
                ERequestType.Reload,
                ERequestType.BtfAdjustment,
                ERequestType.AtfAdjustment,
                ERequestType.Extension,
                ERequestType.Termination,
              ].map((value: ERequestType): ReactNode[] => [
                <LegendLabel>{value}</LegendLabel>,
                <RequestTypeIcon hideTooltip={true} requestType={value} />,
              ])}
              title='Requests Legend'
            />
            <LegendTable
              columns={['Request Status', 'Icon']}
              data={[
                ERequestStatus.Pending,
                ERequestStatus.Approved,
                ERequestStatus.Denied,
                ERequestStatus.Expired,
                ERequestStatus.Withdrawn,
              ].map((value: ERequestStatus): ReactNode[] => [
                <LegendLabel>{value}</LegendLabel>,
                <RequestStatusIcon hideTooltip={true} requestStatus={value} />,
              ])}
              useFiller={true}
            />
          </>
        ) : null}
        <LegendTable
          columns={['Last Request Type', 'Color']}
          data={[
            ERequestType.AtfAdjustment,
            ERequestType.Curtailment,
            ERequestType.Reload,
            ERequestType.Termination,
          ].map((value: ERequestType): ReactNode[] => {
            const colour: string | undefined =
              getBackgroundColourForLastRequestType(value);

            return [
              <LegendLabel>
                {value === ERequestType.AtfAdjustment ? 'ADJUSTMENT' : value}
              </LegendLabel>,
              <LegendColour colour={colour} />,
            ];
          })}
          title='Profile Legend'
        />
      </LayoutRow>
    </Layout>
  );
};

export default Legend;
