import { SyncOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  getReportReviewColumns,
  rowKey,
} from 'components/molecules/ToEntityReportReview/helpers';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { IETagCheckoutReportDataSet } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { alternatingTableRowBackground } from 'utils/styles';

// Specialize the DataTable component
const ReportReviewDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<IETagCheckoutReportDataSet>,
      IETagCheckoutReportDataSet
    > &
      IThemedProps & { maxHeight: string },
  ) =>
    DataTable<
      IViewDataTableColumn<IETagCheckoutReportDataSet>,
      IETagCheckoutReportDataSet
    >(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
    ${(props) => alternatingTableRowBackground(props)}

  .ant-table-content {
    max-height: ${(props) => props.maxHeight};
    overflow: auto !important;
    align-items: center;
  }

  .ant-table {
    font-size: 11px;

    &-tbody {
      > tr > td.ant-table-cell {
        padding: 8px 2px;
      }
    }
  }
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};
`;

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
`;

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const REPORT_DATA_TABLE_MAX_HEIGHT = '250px';

interface IReportReviewTableProps {
  data: IETagCheckoutReportDataSet[];
  isUnconstrained: boolean;
  onRefresh: () => void;
  timeZone: TTimeZone;
}

const ReportReviewTable = ({
  data,
  isUnconstrained,
  onRefresh,
  timeZone,
}: IReportReviewTableProps): JSX.Element | null => {
  const { currentTheme } = useThemeSwitcher();

  const reportReviewColumns: IViewDataTableColumn<IETagCheckoutReportDataSet>[] =
    useMemo(
      () => getReportReviewColumns(timeZone, isUnconstrained),
      [isUnconstrained, timeZone],
    );

  return (
    <Layout>
      <ReportReviewDataTable
        columns={reportReviewColumns}
        currentTheme={currentTheme!}
        data={data}
        pagination={false}
        maxHeight={REPORT_DATA_TABLE_MAX_HEIGHT}
        rowKey={rowKey}
        tableLayout='fixed'
      />
      <Actions>
        <Tooltip title='Refresh Table Contents'>
          <IconButton
            icon={<RefreshIcon />}
            isContained={true}
            onClick={onRefresh}
          />
        </Tooltip>
      </Actions>
    </Layout>
  );
};

export default ReportReviewTable;
