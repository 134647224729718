import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Spinner from 'components/atoms/Spinner/Spinner';
import { PAGE_LAYOUT_STYLES } from 'constants/styles';
import useUserInfo from 'hooks/useUserInfo';
import { IUserInfo } from 'interfaces/User';
import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';
import { isEmptyValue } from 'utils/general';
import {
  retrieveConfigFailure,
  retrieveConfigStart,
  retrieveConfigSuccess,
} from '../../../reduxes/Config/actions';
import { useDispatch } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import { AxiosResponse } from 'axios';
import { IETagConfigResponse } from '../../../interfaces/ETag';
import { retrieveToEntityConfig } from '../../../services/configclient/config';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}
`;

export interface IPageContentProps<T> {
  additionalProps?: T;
  setIsLightMode?: (isLightMode: boolean) => void;
  userInfo: IUserInfo;
}

export interface IPageProps<T> {
  appActions: ReactNode;
  PageContent: (props: IPageContentProps<T>) => JSX.Element;
  pageContentAdditionalProps?: T;
  pageErrorMessage?: TErrorMessage;
  pageIsLoading?: boolean;
  pageTitle: string;
  setIsLightMode?: (isLightMode: boolean) => void;
}

const Page = <T extends any>({
  appActions,
  PageContent,
  pageContentAdditionalProps,
  pageErrorMessage,
  pageIsLoading,
  pageTitle,
  setIsLightMode,
}: IPageProps<T>): JSX.Element => {
  const userInfo: IUserInfo = useUserInfo();
  const { errorMessage, isLoading, selectTenantToEntity } = userInfo;

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const dispatch = useDispatch();
  const { toEntities } = userInfo;
  useAsyncEffect(async () => {
    try {
      dispatch(retrieveConfigStart());
      const payload = new Map<string, boolean | undefined>();
      for (const entity of toEntities) {
        const retrieveToEntityConfigResponse: AxiosResponse<IETagConfigResponse> =
          await retrieveToEntityConfig(entity.to_entity);
        const eTagConfigResponse: IETagConfigResponse =
          retrieveToEntityConfigResponse.data;
        payload.set(
          entity.to_entity,
          eTagConfigResponse.response.hide_tenant_title,
        );

        dispatch(retrieveConfigSuccess({ hideTenantTitleConfig: payload }));
      }
    } catch (error: any) {
      dispatch(retrieveConfigFailure({ errorMessage: error.message }));
    }
  }, [dispatch, toEntities]);

  const adjustedIsLoading: boolean = isLoading || pageIsLoading === true;
  const adjustedErrorMessage: TErrorMessage = isEmptyValue(errorMessage)
    ? isEmptyValue(pageErrorMessage)
      ? null
      : pageErrorMessage
    : errorMessage;

  return selectTenantToEntity ? (
    <>{selectTenantToEntity()}</>
  ) : (
    <>
      {adjustedErrorMessage === null && !adjustedIsLoading ? (
        <PageContent
          additionalProps={pageContentAdditionalProps}
          setIsLightMode={setIsLightMode}
          userInfo={userInfo}
        />
      ) : (
        <Layout>
          {adjustedErrorMessage === null ? (
            adjustedIsLoading ? (
              <Spinner />
            ) : null
          ) : (
            <ErrorMessage maxWidth='100%'>{adjustedErrorMessage}</ErrorMessage>
          )}
        </Layout>
      )}
      {appActions}
    </>
  );
};

export default Page;
