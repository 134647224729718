import { EApprovalStatus } from 'enums/Approval';
import { timeZoneParams } from 'services/helpers';
import { TTimeZone } from 'types/DateTime';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue } from 'utils/general';

export const PENDING_REQUESTS_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/approval/${encodeURIComponent(
    tagPrimaryKey,
  )}/pendingRequests${timeZoneParams('?', timeZone)}`;

export const ALL_PENDING_REQUESTS_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/approval/pendingRequests${timeZoneParams('?', timeZone)}`;

export const REASON_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/approval/reasons`;

export const SET_STATE_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestRef: number,
  approvalStatus: EApprovalStatus,
  notes: string | null | undefined,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/approval/${encodeURIComponent(
    tagPrimaryKey,
  )}/setState?requestRef=${encodeURIComponent(
    requestRef,
  )}&approvalStatus=${encodeURIComponent(approvalStatus)}${
    isEmptyValue(notes) ? '' : `&notes=${encodeURIComponent(notes!)}`
  }`;

export const TAG_HISTORY_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestId?: number,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/approval/${encodeURIComponent(
    tagPrimaryKey,
  )}/tagHistory${
    isEmptyValue(requestId)
      ? ''
      : `?requestId=${encodeURIComponent(requestId!)}`
  }`;

export const RULES_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/approval/rules`;

export const FAILED_VALIDATIONS_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/approval/failedValidations`;

export const FAILED_VALIDATIONS_SCHEDULE_ROUTE = (
  toEntityId: TToEntityId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/approval/failedValidations/schedule`;

export const FAILED_VALIDATIONS_SCHEDULE_ID_ROUTE = (
  toEntityId: TToEntityId,
  reportId: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/approval/failedValidations/schedule/${encodeURIComponent(reportId)}`;
