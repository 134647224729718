import { NAVIGATION_ICON_FONT } from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import styled from 'styled-components';
import { colourForTheme } from 'utils/styles';

const NavigationActionIconContainer = styled.div<IThemedProps>`
  display: flex;

  > .anticon {
    font-size: ${NAVIGATION_ICON_FONT};
    color: ${(props) => colourForTheme(props)};
  }
`;

export default NavigationActionIconContainer;
