import { forwardRef } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';

export default forwardRef((props: ICellRendererParams, ref) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
  };

  return <span style={style}>MWs Totals</span>;
});
