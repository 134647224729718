import { IRequestDataSet } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import { getColumnNotesRender, getColumnRender } from 'utils/views';

export const rowKey = (record: IRequestDataSet): string =>
  `${record.entity_code}_${record.entity_type}_${record.delivery_status}`;

export const getRequestColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IRequestDataSet>[] => [
  {
    dataIndex: 'entity_code',
    render: getColumnRender(isUnconstrained),
    title: 'Entity',
    width: '50px',
  },
  {
    dataIndex: 'entity_type',
    render: getColumnRender(isUnconstrained),
    title: 'Type',
    width: '38px',
  },
  {
    dataIndex: 'approval_status',
    render: getColumnRender(isUnconstrained),
    title: 'Approval Status',
    width: '78px',
  },
  {
    dataIndex: 'approval_status_type',
    render: getColumnRender(isUnconstrained),
    title: 'Approval Type',
    width: '64px',
  },
  {
    dataIndex: 'approvalTimestamp',
    render: getColumnRender(isUnconstrained),
    title: 'Approval Time',
    width: '112px',
  },
  {
    dataIndex: 'approver_notes',
    render: getColumnNotesRender(isUnconstrained),
    title: 'Approver Notes',
    width: '60px',
  },
  {
    dataIndex: 'delivery_status',
    render: getColumnRender(isUnconstrained),
    title: 'Delivery Status',
    width: '68px',
  },
];
