import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { IOption } from 'interfaces/Component';

export interface ISelectEnumProps<T>
  extends Omit<ISelectProps<T>, 'options' | 'valueToUid'> {
  // note: tried enumType: T, but get errors at call site like
  // Type 'typeof EAlertRuleType' is not assignable to type 'EAlertRuleType'.
  enumType: any;
  optionMapper?: (option: IOption<T>) => IOption<T> | null;
  valueToUid?: (value: T) => string;
}

function SelectEnum<T>(props: ISelectEnumProps<T>): JSX.Element {
  const { enumType, optionMapper, valueToUid, ...rest } = props;
  const resolvedOptionMapper =
    optionMapper === undefined ? (option: IOption<T>) => option : optionMapper;
  const resolvedOptions: IOption<T>[] = Object.keys(enumType)
    .filter((enumKey: string) => isNaN(Number(enumKey)))
    .map((enumKey: string): IOption<T> | null =>
      resolvedOptionMapper({
        label: enumKey,
        value: enumType[enumKey as keyof typeof enumType] as unknown as T,
      }),
    )
    .filter(
      (option: IOption<T> | null): boolean => option !== null,
    ) as IOption<T>[];
  const resolvedValueToUid =
    valueToUid === undefined
      ? (enumValue: T): string => String(enumValue)
      : valueToUid;

  return (
    <Select
      options={resolvedOptions}
      valueToUid={resolvedValueToUid}
      {...rest}
    />
  );
}

export default SelectEnum;
