import { ERegistryType } from 'enums/General';
import { IToEntity } from 'interfaces/ToEntity';
import { registryTypeToEntityType } from 'utils/entity';
import { isEmptyValue } from 'utils/general';

export const checkToEntity = (toEntity: IToEntity | null) => {
  if (toEntity === null) {
    throw new Error('Invalid toEntity: null');
  } else if (isEmptyValue(toEntity.to_entity)) {
    throw new Error(`Invalid toEntity.to_entity: ${toEntity.to_entity}`);
  }
};

export const getQueryEntityFor = (
  registryType: ERegistryType,
  taggingEntityId: string,
): string => `${registryTypeToEntityType(registryType)}Code-${taggingEntityId}`;
