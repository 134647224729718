import DataGrid, {
  IDataGridProps,
} from 'components/organisms/DataGrid/DataGrid';
import {
  copyProfileDataGridCellTo,
  getProfileDataGridCellToString,
  getUpdateExtraRows,
  getUpdateRowWithValue,
} from 'components/organisms/ProfileDataGrid/helpers';
import ProfileDataGridEditRow from 'components/organisms/ProfileDataGrid/ProfileDataGridEditRow/ProfileDataGridEditRow';
import {
  IDataGridHandle,
  IDataGridSelectionContext,
} from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { Context, forwardRef, Ref, useCallback, useMemo } from 'react';
import { RowRendererProps } from 'react-data-grid';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';

interface IProfileDataGridProps
  extends IDataGridProps<
    TProfileDataGridColumn,
    TProfileDataGridRow,
    TProfileDataGridSummaryRow,
    IProfileDataGridCell
  > {
  initialRows?: TProfileDataGridRow[];
  isEditable?: boolean;
  timeZone: TTimeZone;
}

const ProfileDataGrid = (
  { initialRows, isEditable, timeZone, ...rest }: IProfileDataGridProps,
  ref: Ref<IDataGridHandle>,
): JSX.Element => {
  const getRowRenderer = useCallback(
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
      isEditable === true
        ? (
            props: RowRendererProps<
              TProfileDataGridRow,
              TProfileDataGridSummaryRow
            >,
          ): JSX.Element => (
            <ProfileDataGridEditRow
              {...props}
              DataGridSelectionContext={DataGridSelectionContext}
              initialDataSet={initialRows}
            />
          )
        : undefined,
    [initialRows, isEditable],
  );

  const cellToString = useMemo(
    () => getProfileDataGridCellToString(timeZone),
    [timeZone],
  );

  const updateRowWithValue = useMemo(
    () => getUpdateRowWithValue(timeZone),
    [timeZone],
  );

  const updateExtraRows = useMemo(
    () => getUpdateExtraRows(updateRowWithValue),
    [updateRowWithValue],
  );

  return (
    <DataGrid
      {...rest}
      cellToString={cellToString}
      className='profile-data-grid'
      copyDataGridCellTo={copyProfileDataGridCellTo}
      getRowRenderer={getRowRenderer}
      ref={ref}
      updateExtraRows={updateExtraRows}
      updateRowWithValue={updateRowWithValue}
    />
  );
};

export default forwardRef(ProfileDataGrid);
