import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  IAlertRule,
  IAlertRuleDeleteResponse,
  IAlertRuleSaveResponse,
  IAlertRulesResponse,
} from 'interfaces/Alert';
import { ALERTS_RULES_ROUTE } from 'services/alert/constants';
import { ALERT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TAlertRuleId } from 'types/Alert';
import { TToEntityId } from 'types/ToEntity';

export const retrieveAlertRules = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IAlertRulesResponse>> => {
  return await API.get(
    ALERT_SERVICE_API_NAME,
    ALERTS_RULES_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const saveAlertRule = async (
  toEntityId: TToEntityId,
  alertRule: IAlertRule,
): Promise<AxiosResponse<IAlertRuleSaveResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: alertRule };
  return await API.put(
    ALERT_SERVICE_API_NAME,
    ALERTS_RULES_ROUTE(toEntityId),
    options,
  );
};

export const deleteAlertRule = async (
  toEntityId: TToEntityId,
  alertRuleId: TAlertRuleId,
): Promise<AxiosResponse<IAlertRuleDeleteResponse>> => {
  return await API.del(
    ALERT_SERVICE_API_NAME,
    `${ALERTS_RULES_ROUTE(toEntityId)}/${encodeURIComponent(
      String(alertRuleId),
    )}`,
    DEFAULT_OPTIONS,
  );
};
