import { FilterOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge as AntDesignBadge } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import TemplateFilterConfigurator, {
  ITemplateFilterConfigurationProps,
} from 'components/molecules/TemplateFilterConfigurator/TemplatesFilterConfigurator';
import ToEntityETagCreate from 'components/molecules/ToEntityETagCreate/ToEntityETagCreate';
import ToEntityETagTemplateActivate from 'components/molecules/ToEntityETagTemplateActivate/ToEntityETagTemplateActivate';
import ToEntityETagTemplateCopy from 'components/molecules/ToEntityETagTemplateCopy/ToEntityETagTemplateCopy';
import ToEntityETagTemplateDelete from 'components/molecules/ToEntityETagTemplateDelete/ToEntityETagTemplateDelete';
import ToEntityIndicator from 'components/molecules/ToEntityIndicator/ToEntityIndicator';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import {
  DEFAULT_TEMPLATE_GROUP_OPTIONS,
  TO_ENTITY_FILTER_CONFIGURATOR_MODAL_WIDTH_VALUE,
} from 'components/pages/TemplatesPage/constants';
import { checkStringEquality } from 'components/pages/TemplatesPage/helpers';
import TemplatesConfiguration from 'components/pages/TemplatesPage/TemplatesConfiguration';
import {
  EActiveOptions,
  TActiveOptions,
} from 'components/pages/TemplatesPage/types';
import {
  ACTION_BAR_MARGIN_RIGHT_VALUE,
  BADGE_Z_INDEX,
  BUTTON_ICON_DIMENSIONS,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import { TEMPLATES_SHOW_ALL_GROUPS } from 'constants/Template';
import usePermissions from 'hooks/usePermissions';
import { IOption } from 'interfaces/Component';
import { IETagTemplate } from 'interfaces/ETag';
import { ITemplateFilter } from 'interfaces/Filter';
import { IContactInfo } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { encodeIds } from 'utils/general';

const FilterIcon = styled(FilterOutlined)`
  height: ${INPUT_HEIGHT_VALUE / 2}px;
  width: ${INPUT_HEIGHT_VALUE / 2}px;
`;

const ActionsBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledBadge = styled(AntDesignBadge)`
  .ant-badge-count {
    z-index: ${BADGE_Z_INDEX};
  }
`;

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ClearAllFiltersIcon = styled(StopOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;
interface IActiveSelectProps extends ISelectProps<EActiveOptions> {
  encodedPermissionsId: string;
}

interface IGroupSelectProps extends ISelectProps<string | null> {
  encodedPermissionsId: string;
}

// Specialize the Select component
const ActiveSelect = styled((props: IActiveSelectProps) => {
  const { encodedPermissionsId } = props;
  const { isDisplayable } = usePermissions(encodedPermissionsId);

  if (isDisplayable) {
    return Select<EActiveOptions>(props);
  }
  return null;
})`
  width: 130px;
`;

// Specialize the Select component
const GroupSelect = styled((props: IGroupSelectProps) => {
  const { encodedPermissionsId } = props;
  const { isDisplayable } = usePermissions(encodedPermissionsId);

  if (isDisplayable) {
    return Select<string | null>(props);
  }
  return null;
})`
  width: 145px;
`;

// Props
interface IProps {
  activeOptions: TActiveOptions;
  className?: string;
  contactInfo?: IContactInfo;
  currentFilter: ITemplateFilter;
  encodedPermissionsId: string;
  isEmbeddedTitle?: boolean;
  isTableLoading: boolean;
  onActivateSuccess: (eTagTemplate: IETagTemplate) => void;
  onClearAllFilters: () => void;
  onDelete: () => void;
  onRefresh: () => void;
  onSaveFilter: () => void;
  selectedActiveFilter: EActiveOptions | undefined;
  selectedGroupFilter: string | null | undefined;
  selectedTemplate: IETagTemplate | undefined;
  setActiveFilter: (selectedFilter: EActiveOptions | undefined) => void;
  setFilter: (selectedFilter: ITemplateFilter) => void;
  setSelectedTemplateGroup: (selectedGroup: string | null | undefined) => void;
  templateGroupOptions: IOption<string | null>[];
  timeZone: TTimeZone;
  toEntity: IToEntity;
}

const TemplatesActionBar = ({
  activeOptions,
  className,
  contactInfo,
  currentFilter,
  encodedPermissionsId,
  isEmbeddedTitle,
  isTableLoading,
  onActivateSuccess,
  onDelete,
  onClearAllFilters,
  onRefresh,
  selectedTemplate,
  setActiveFilter,
  setSelectedTemplateGroup,
  selectedActiveFilter,
  selectedGroupFilter,
  templateGroupOptions,
  onSaveFilter,
  setFilter,
  timeZone,
  toEntity,
}: IProps): JSX.Element => {
  const adjustedTemplateGroupOptions: IOption<string | null>[] = useMemo(
    () => DEFAULT_TEMPLATE_GROUP_OPTIONS.concat(templateGroupOptions),
    [templateGroupOptions],
  );

  return (
    <ActionsBar className={className}>
      <SeparatedRowLayout marginRight={ACTION_BAR_MARGIN_RIGHT_VALUE}>
        {isEmbeddedTitle ? <ToEntityIndicator toEntity={toEntity} /> : null}
        <Tooltip title='Refresh Table'>
          <StyledBadge>
            <IconButton
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'eTagTemplateRefresh',
              ])}
              icon={<RefreshIcon />}
              onClick={onRefresh}
              isDisabled={isTableLoading}
            />
          </StyledBadge>
        </Tooltip>
        <UserTimeZoneSelector toEntityId={toEntity.to_entity} />
        <Tooltip title='Filter Active'>
          <ActiveSelect
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagTemplateActiveSelect',
            ])}
            onChange={setActiveFilter}
            options={activeOptions}
            placeholder='Select Active Filter'
            value={selectedActiveFilter}
            valueToUid={(value: EActiveOptions): string => value}
          />
        </Tooltip>
        <Tooltip title='Select Template Group'>
          <GroupSelect
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagTemplateGroupSelect',
            ])}
            equalityChecker={checkStringEquality}
            initialValue={TEMPLATES_SHOW_ALL_GROUPS}
            onChange={setSelectedTemplateGroup}
            options={adjustedTemplateGroupOptions}
            placeholder='Select Group Filter'
            value={selectedGroupFilter}
            valueToUid={(value: string | null): string =>
              value === null ? 'NULL_KEY' : (value as string)
            }
          />
        </Tooltip>
        <TemplatesConfiguration<ITemplateFilterConfigurationProps>
          Configurator={TemplateFilterConfigurator}
          configuratorProps={{
            currentFilter,
            setFilter,
          }}
          icon={<FilterIcon />}
          encodedPermissionsId={encodeIds(
            [encodedPermissionsId, 'filterConfiguration'],
            toEntity.to_entity,
          )}
          label='Custom Filter'
          modalWidth={TO_ENTITY_FILTER_CONFIGURATOR_MODAL_WIDTH_VALUE}
          onSaveFilter={onSaveFilter}
          toEntity={toEntity}
        />
        <Tooltip title='Clear Custom Filter and All Column Filters'>
          <IconButton
            icon={<ClearAllFiltersIcon />}
            onClick={onClearAllFilters}
          />
        </Tooltip>
        <ToEntityETagCreate
          encodedPermissionsId={encodeIds([encodedPermissionsId, 'eTagCreate'])}
          contactInfo={contactInfo}
          isDisabled={
            !(selectedTemplate !== undefined && selectedTemplate.active)
          }
          template={selectedTemplate}
          timeZone={timeZone}
          toEntityId={toEntity.to_entity}
        />
        {timeZone === undefined ? null : (
          <ToEntityETagTemplateCopy
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagTemplateCopy',
            ])}
            eTagTemplate={selectedTemplate}
            timeZone={timeZone}
            toEntityId={toEntity.to_entity}
          />
        )}
        <ToEntityETagTemplateActivate
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'eTagTemplateActivate',
          ])}
          isDisabled={selectedTemplate === undefined}
          onSuccess={onActivateSuccess}
          template={selectedTemplate}
          toEntityId={toEntity.to_entity}
        />
        <ToEntityETagTemplateDelete
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'eTagTemplateDelete',
          ])}
          isDisabled={selectedTemplate === undefined}
          onSuccess={onDelete}
          template={selectedTemplate}
          toEntityId={toEntity.to_entity}
        />
      </SeparatedRowLayout>
    </ActionsBar>
  );
};

export default TemplatesActionBar;
