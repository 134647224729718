import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { APPROVAL_ACTION_OPTIONS } from 'components/organisms/ToEntityETagReviewPendingRequests/constants';
import {
  approvalActionToString,
  approvalRightToString,
  requestTypeToString,
} from 'components/organisms/ToEntityETagReviewPendingRequests/helpers';
import {
  IEditPendingRequests,
  IPendingRequest,
} from 'components/organisms/ToEntityETagReviewPendingRequests/types';
import { NOTES_MAX_LENGTH } from 'constants/Detail';
import { EApprovalStatus } from 'enums/Approval';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IETagReasons } from 'interfaces/ETag';
import { IInputColumnConfig, ISelectColumnConfig } from 'interfaces/View';
import { ChangeEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { eventToStringOrNull, isEmptyValue } from 'utils/general';
import {
  getColumnEntityInfoRender,
  getColumnInputRender,
  getColumnRender,
  getColumnSelectRender,
} from 'utils/views';

const Reason = styled.div`
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 1px;
  }
`;

const actionSelectRender = getColumnSelectRender<
  EApprovalStatus,
  IPendingRequest
>('100%', '97px');

const reasonInputRender = getColumnInputRender<IPendingRequest>('318px', 32);

const reasonSelectRender = getColumnSelectRender<string, IPendingRequest>(
  '26px',
);

const reasonRender =
  (
    inputColumnConfig: IInputColumnConfig<IPendingRequest>,
    selectColumnConfig: ISelectColumnConfig<string, IPendingRequest>,
  ) =>
  (value: unknown, record: IPendingRequest, index: number): JSX.Element => {
    return (
      <Reason>
        {reasonInputRender(inputColumnConfig)(value, record)}
        <Tooltip title='Reason Selection'>
          {reasonSelectRender(selectColumnConfig)(value, record, index)}
        </Tooltip>
      </Reason>
    );
  };

const getColumnRequestTypeRender = (
  isUnconstrained: boolean,
): ((value: unknown) => JSX.Element) =>
  getColumnRender(isUnconstrained, requestTypeToString);

const getColumnApprovalRightRender = (
  isUnconstrained: boolean,
): ((value: unknown) => JSX.Element) =>
  getColumnRender(isUnconstrained, approvalRightToString);

const getColumnApprovalActionRender = (
  isUnconstrained: boolean,
): ((value: unknown) => JSX.Element) =>
  getColumnRender(isUnconstrained, approvalActionToString);

const useReviewPendingRequestsColumns = (
  isDisabled: boolean | undefined,
  isUnconstrained: boolean,
  onChange: (editPendingRequests: IEditPendingRequests) => void,
  reasons: IETagReasons,
) => {
  const previousIsDisabled: boolean | undefined = usePrevious(isDisabled);
  const previousReasons: IETagReasons | undefined = usePrevious(reasons);

  const isReasonsChanged: boolean = useMemo(
    () => reasons !== previousReasons,
    [previousReasons, reasons],
  );

  const getActionOptions = useCallback(async () => APPROVAL_ACTION_OPTIONS, []);

  const actionSelectOnChange = useCallback(
    (value: EApprovalStatus | undefined, record: IPendingRequest) => {
      onChange({
        editPendingRequestsMap: {
          [record.key]: {
            action: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const reasonInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, record: IPendingRequest) => {
      onChange({
        editPendingRequestsMap: {
          [record.key]: {
            pci_suggested_note: eventToStringOrNull(event),
          },
        },
      });
    },
    [onChange],
  );

  const approvedReasonOptions: IOption<string>[] = useMemo(
    () =>
      reasons.approve_reasons.map(
        (value: string): IOption<string> => ({ label: value, value }),
      ),
    [reasons],
  );

  const deniedReasonOptions: IOption<string>[] = useMemo(
    () =>
      reasons.denied_reasons.map(
        (value: string): IOption<string> => ({ label: value, value }),
      ),
    [reasons],
  );

  const studyReasonOptions: IOption<string>[] = useMemo(
    () =>
      reasons.study_reasons.map(
        (value: string): IOption<string> => ({ label: value, value }),
      ),
    [reasons],
  );

  const getReasonOptions = useCallback(
    async (record: IPendingRequest) => {
      switch (record.action) {
        case EApprovalStatus.Approved: {
          return approvedReasonOptions;
        }
        case EApprovalStatus.Denied: {
          return deniedReasonOptions;
        }
        case EApprovalStatus.Study: {
          return studyReasonOptions;
        }
        default: {
          return [];
        }
      }
    },
    [approvedReasonOptions, deniedReasonOptions, studyReasonOptions],
  );

  const reasonSelectOnChange = useCallback(
    (value: string | undefined, record: IPendingRequest) => {
      onChange({
        editPendingRequestsMap: {
          [record.key]: {
            pci_suggested_note: isEmptyValue(value) ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const reasonSelectShouldGetOptions = useCallback(
    (
      record: IPendingRequest,
      previousRecord: IPendingRequest | undefined,
    ): boolean => isReasonsChanged || record.action !== previousRecord?.action,
    [isReasonsChanged],
  );

  return useMemo(() => {
    const isDisabledChanged: boolean = isDisabled !== previousIsDisabled;

    return [
      {
        dataIndex: 'action',
        render: actionSelectRender({
          isDisabled,
          allowClear: true,
          getOptions: getActionOptions,
          onChange: actionSelectOnChange,
          valueToUid: (value: EApprovalStatus): string => value as string,
        }),
        shouldCellUpdate: (
          record: IPendingRequest,
          previousRecord: IPendingRequest,
        ): boolean =>
          isDisabledChanged || record.action !== previousRecord.action,
        title: 'Action',
        width: '97px',
      },
      {
        dataIndex: 'pci_suggested_note',
        render: reasonRender(
          {
            isDisabled,
            getKey: (record: IPendingRequest): string => record.key,
            maxLength: NOTES_MAX_LENGTH,
            onChange: reasonInputOnChange,
          },
          {
            dropdownMatchSelectWidth: false,
            getOptions: getReasonOptions,
            isDisabled,
            onChange: reasonSelectOnChange,
            shouldGetOptions: reasonSelectShouldGetOptions,
            showSelectionArrowOnly: true,
            valueToUid: (value: string): string => value,
          },
        ),
        shouldCellUpdate: (
          record: IPendingRequest,
          previousRecord: IPendingRequest,
        ): boolean =>
          isDisabledChanged ||
          isReasonsChanged ||
          record.action !== previousRecord.action ||
          record.pci_suggested_note !== previousRecord.pci_suggested_note,
        title: 'Reason',
        width: '345px',
      },
      {
        dataIndex: 'ui_tag_id',
        render: getColumnRender(isUnconstrained),
        title: 'Tag ID',
        width: '191px',
      },
      {
        dataIndex: 'request_id',
        render: getColumnRender(isUnconstrained),
        title: 'Request ID',
        width: '61px',
      },
      {
        dataIndex: 'requestor',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'Requestor',
      },
      {
        dataIndex: 'request_type',
        render: getColumnRequestTypeRender(isUnconstrained),
        title: 'Request Type',
        width: '103px',
      },
      {
        dataIndex: 'request_note',
        render: getColumnRender(isUnconstrained),
        title: 'Request Note',
      },
      {
        dataIndex: 'approval_rights',
        render: getColumnApprovalRightRender(isUnconstrained),
        title: 'Approval Right',
        width: '73px',
      },
      {
        dataIndex: 'request_act_on_by_time',
        render: getColumnRender(isUnconstrained),
        title: 'Approval Deadline',
        width: '123px',
      },
      {
        dataIndex: 'request_start_time',
        render: getColumnRender(isUnconstrained),
        title: 'Start',
        width: '123px',
      },
      {
        dataIndex: 'request_stop_time',
        render: getColumnRender(isUnconstrained),
        title: 'Stop',
        width: '123px',
      },
      {
        dataIndex: 'request_total_mwh',
        render: getColumnRender(isUnconstrained),
        title: 'MWh',
        width: '53px',
      },
      {
        dataIndex: 'pci_suggested_action',
        render: getColumnApprovalActionRender(isUnconstrained),
        title: 'PCI Suggested',
        width: '87px',
      },
      {
        dataIndex: 'last_action_taken',
        render: getColumnRender(isUnconstrained),
        title: 'Action Taken',
        width: '53px',
      },
    ];
  }, [
    actionSelectOnChange,
    getActionOptions,
    getReasonOptions,
    isDisabled,
    isReasonsChanged,
    isUnconstrained,
    previousIsDisabled,
    reasonInputOnChange,
    reasonSelectOnChange,
    reasonSelectShouldGetOptions,
  ]);
};

export default useReviewPendingRequestsColumns;
