import { SyncOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { TPendingRequestsOptionalColumn } from 'components/organisms/ToEntityETagReviewPendingRequests/types';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IOption } from 'interfaces/Component';
import styled from 'styled-components';
import { TETagTagPrimaryKey } from 'types/ETag';

const PendingRequestsSelect = styled(
  (props: ISelectProps<TPendingRequestsOptionalColumn>) =>
    Select<TPendingRequestsOptionalColumn>(props),
)`
  width: 85%;
  margin-right: 10px;
`;

const Label = styled.div`
  white-space: nowrap;
`;

const Layout = styled(SeparatedRowLayout)`
  height: 25px;
`;

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IProps {
  setSelectedTagPrimaryKeys: (values: string[]) => void;
  selectedTagPrimaryKeys: TETagTagPrimaryKey[];
  summaryTagOptions: IOption<string>[];
  handleRefresh: () => void;
}

const ReviewPendingRequestsHeader = ({
  setSelectedTagPrimaryKeys,
  selectedTagPrimaryKeys,
  summaryTagOptions,
  handleRefresh,
}: IProps): JSX.Element => {
  return (
    <Layout>
      <Label>Review Pending Requests for: </Label>
      <PendingRequestsSelect
        allowClear
        allowMultiple
        maxTagCount={'responsive'}
        onChangeMultiple={setSelectedTagPrimaryKeys}
        options={summaryTagOptions}
        placeholder={'All Tags'}
        values={selectedTagPrimaryKeys}
        valueToUid={(value: TPendingRequestsOptionalColumn): string => value}
      />
      <IconButton icon={<RefreshIcon />} onClick={handleRefresh} />
    </Layout>
  );
};

export default ReviewPendingRequestsHeader;
