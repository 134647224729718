import ReviewDataTable from 'components/molecules/ReviewDataTable/ReviewDataTable';
import { OASIS_INFO_TITLE } from 'components/organisms/PhysicalPathReview/constants';
import { DETAIL_REVIEW_FLOAT_OVER_MAXIMUM_HEIGHT } from 'constants/styles';
import { ERetreiveState } from 'enums/General';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'types/Redux';
import { getOasisInfoColumns } from 'utils/OasisInfo';

interface IOasisInfoReviewProps {
  allowPopover: boolean;
  physicalSegmentId?: number;
  isUnconstrained?: boolean;
}

const retrieveOasisInfoReviewState = (state: TRootState) => {
  const {
    retrievingDetail,
    transmissionAllocations,
    allUnfilteredTransmissionAllocations,
  } = state.detail.present;
  const isLoading: boolean =
    retrievingDetail !== ERetreiveState.NotRetrieving &&
    retrievingDetail !== ERetreiveState.RetrievingCompleted;

  return {
    isLoading,
    transmissionAllocations,
    allUnfilteredTransmissionAllocations,
  };
};

const OasisInfoReview = ({
  allowPopover,
  physicalSegmentId,
  isUnconstrained = false,
}: IOasisInfoReviewProps): JSX.Element => {
  const { isLoading, allUnfilteredTransmissionAllocations } = useSelector(
    retrieveOasisInfoReviewState,
  );

  const oasisInfoColumns: IViewDataTableColumn<IETagTransmissionAllocation>[] =
    useMemo(() => getOasisInfoColumns(isUnconstrained), [isUnconstrained]);

  const adjustedTransmissionAllocations: IETagTransmissionAllocation[] =
    useMemo(() => {
      let filteredTransmissionAllocations: IETagTransmissionAllocation[] = [];
      if (allUnfilteredTransmissionAllocations) {
        const allUnfilteredTransmissionAllocationsCopy =
          allUnfilteredTransmissionAllocations.slice();
        filteredTransmissionAllocations =
          allUnfilteredTransmissionAllocationsCopy
            .reverse()
            .filter(
              (obj, index) =>
                allUnfilteredTransmissionAllocationsCopy.findIndex(
                  (item) =>
                    item.physical_segment_ref === obj.physical_segment_ref,
                ) === index,
            )
            .reverse()
            .sort((a, b) => {
              if (a.physical_segment_ref === null) {
                return 1;
              }

              if (b.physical_segment_ref === null) {
                return -1;
              }

              if (a.physical_segment_ref === b.physical_segment_ref) {
                return 0;
              }

              return a.physical_segment_ref < b.physical_segment_ref ? -1 : 1;
            });
      }

      return filteredTransmissionAllocations === null
        ? []
        : filteredTransmissionAllocations;
    }, [allUnfilteredTransmissionAllocations]);

  const filteredTransAllocs: IETagTransmissionAllocation[] =
    useMemo((): IETagTransmissionAllocation[] => {
      if (physicalSegmentId === undefined) {
        return adjustedTransmissionAllocations;
      }

      const onlyMatchingTransmissionAllocations: IETagTransmissionAllocation[] =
        adjustedTransmissionAllocations.filter(
          (transAlloc: IETagTransmissionAllocation) =>
            transAlloc.physical_segment_ref === physicalSegmentId ||
            transAlloc.physical_segment_ref === null,
        );

      return onlyMatchingTransmissionAllocations;
    }, [adjustedTransmissionAllocations, physicalSegmentId]);

  const dataString: string = filteredTransAllocs
    .map(
      (transAlloc: IETagTransmissionAllocation) =>
        `${transAlloc.contract_number || ''}${
          transAlloc.trans_product_ref?.product_name
            ? ` (${transAlloc.trans_product_ref?.product_name})`
            : ''
        }`,
    )
    .join(',');

  return (
    <>
      {allowPopover ? (
        <ReviewDataTable<IETagTransmissionAllocation>
          columns={oasisInfoColumns}
          data={
            physicalSegmentId
              ? filteredTransAllocs
              : adjustedTransmissionAllocations
          }
          id='oasisInfoReview'
          isLoading={isLoading}
          maximumHeight={DETAIL_REVIEW_FLOAT_OVER_MAXIMUM_HEIGHT}
          pagination={false}
          rowKey='trans_alloc_id'
          tableTitle='OASIS Information Details'
          title={physicalSegmentId ? dataString : OASIS_INFO_TITLE}
        />
      ) : (
        <>{OASIS_INFO_TITLE}</>
      )}
    </>
  );
};

export default OasisInfoReview;
