import { IRequestAuditDataSet } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import { getColumnRender } from 'utils/views';

export const rowKey = (record: IRequestAuditDataSet): string =>
  `${record.submitted_by}_${record.submitted_on}`;

export const getRequestAuditColumns = (
  isUnconstrained: boolean,
  showApprovalColumns: boolean,
  submittedByData: boolean,
): IViewDataTableColumn<IRequestAuditDataSet>[] => {
  const auditColumns = [];
  if (!submittedByData) {
    if (showApprovalColumns) {
      auditColumns.push(
        {
          dataIndex: 'request_timestamp',
          render: getColumnRender(isUnconstrained),
          title: 'Request Created On',
          width: '60px',
        },
        {
          dataIndex: 'pci_approved_by',
          render: getColumnRender(isUnconstrained),
          title: 'PCI Approved By',
          width: '60px',
        },
        {
          dataIndex: 'pci_approved_on',
          render: getColumnRender(isUnconstrained),
          title: 'PCI Approved On',
          width: '60px',
        },
      );
    } else {
      auditColumns.push({
        dataIndex: 'request_timestamp',
        render: getColumnRender(isUnconstrained),
        title: 'Request Created On',
        width: '60px',
      });
    }
  } else {
    auditColumns.push(
      {
        dataIndex: 'submitted_by',
        render: getColumnRender(isUnconstrained),
        title: 'Submitted By',
        width: '60px',
      },
      {
        dataIndex: 'submitted_on',
        render: getColumnRender(isUnconstrained),
        title: 'Submitted On',
        width: '60px',
      },
      {
        dataIndex: 'pci_approved_by',
        render: getColumnRender(isUnconstrained),
        title: 'Approval Action By',
        width: '60px',
      },
      {
        dataIndex: 'pci_approved_on',
        render: getColumnRender(isUnconstrained),
        title: 'Approval Action On',
        width: '60px',
      },
    );
  }

  return auditColumns;
};
