import {
  EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
  INITIAL_RECORD_ID,
} from 'constants/Detail';
import { EProfileFormat, ETableConfiguration } from 'enums/Detail';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import useInitialData from 'hooks/useInitialData';
import usePrevious from 'hooks/usePrevious';
import {
  getProfileInformationColumns,
  getProfileInformationData,
} from 'hooks/useProfileInformationReview/helpers';
import { integrate } from 'hooks/useProfileInformationReview/integrate';
import {
  IETagPhysicalSegmentsProfile,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { useCallback, useMemo } from 'react';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
} from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import {
  getEditInfoKey,
  getInitialPhysicalSegmentsProfile,
} from 'utils/detail';

interface IuseProfileInformationReview {
  adjustedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null;
  hasGenerationReliability: boolean;
  hasLoadReliability: boolean;
  initialProfileInformationDataSet: TProfileDataGridRow[] | undefined;
  profileInformationColumns: TProfileDataGridColumn[];
  profileInformationDataSet: TProfileDataGridRow[];
  profileInformationSummaryRows: TProfileDataGridSummaryRow[];
}

const useProfileInformationReview = (
  endDate: string | null,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  generationPhysicalSegmentName: string,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isEditable: boolean,
  isLoading: boolean,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  loadPhysicalSegmentName: string,
  pageMode: EPageMode,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  previousIsDetailUpdating: boolean | undefined,
  selectedProfileFormat: EProfileFormat | undefined,
  selectedTableConfiguration: ETableConfiguration | undefined,
  showReliability: boolean | undefined,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  startDate: string | null,
  timeZone: TTimeZone,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  useUniqueProfiles: boolean,
  viewMode: EViewMode,
  show_losses: boolean | null,
  isPrintView?: boolean,
): IuseProfileInformationReview => {
  const previousPageMode: EPageMode | undefined = usePrevious(pageMode);

  const adjustedPhysicalSegmentsProfiles:
    | IETagPhysicalSegmentsProfile[]
    | null = useMemo(
    () =>
      isEditable &&
      physicalSegmentsProfiles !== null &&
      physicalSegmentsProfiles.length === 0
        ? [
            getInitialPhysicalSegmentsProfile(
              getEditInfoKey(
                EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
                INITIAL_RECORD_ID,
                0,
              ),
              startDate,
              endDate,
              generationPhysicalSegment,
              loadPhysicalSegment,
              transmissionPhysicalSegments,
              transmissionAllocations,
            ),
          ]
        : physicalSegmentsProfiles,
    [
      endDate,
      generationPhysicalSegment,
      isEditable,
      loadPhysicalSegment,
      physicalSegmentsProfiles,
      startDate,
      transmissionAllocations,
      transmissionPhysicalSegments,
    ],
  );

  const {
    integratedPhysicalSegmentsProfiles,
    summaryRowPhysicalSegmentsProfiles,
  } = useMemo(() => {
    try {
      const integratedPhysicalSegmentsProfiles:
        | IETagPhysicalSegmentsProfile[]
        | null = integrate(
        adjustedPhysicalSegmentsProfiles,
        selectedProfileFormat,
        timeZone,
      );
      let summaryRowPhysicalSegmentsProfiles:
        | IETagPhysicalSegmentsProfile[]
        | null = integratedPhysicalSegmentsProfiles;

      if (selectedProfileFormat === EProfileFormat.StartStop) {
        summaryRowPhysicalSegmentsProfiles = integrate(
          summaryRowPhysicalSegmentsProfiles,
          EProfileFormat.Hourly,
          timeZone,
        );
      }

      return {
        integratedPhysicalSegmentsProfiles,
        summaryRowPhysicalSegmentsProfiles,
      };
    } catch (error: any) {
      return {
        integratedPhysicalSegmentsProfiles: adjustedPhysicalSegmentsProfiles,
        summaryRowPhysicalSegmentsProfiles: [],
      };
    }
  }, [adjustedPhysicalSegmentsProfiles, selectedProfileFormat, timeZone]);

  const {
    hasGenerationReliability,
    hasLoadReliability,
    profileInformationDataSet,
    profileInformationSummaryRows,
  } = useMemo(
    () =>
      getProfileInformationData(
        generationPhysicalSegmentName,
        loadPhysicalSegmentName,
        integratedPhysicalSegmentsProfiles,
        transmissionPhysicalSegments,
        sortedTransmissionAllocations,
        summaryRowPhysicalSegmentsProfiles,
        show_losses,
      ),
    [
      generationPhysicalSegmentName,
      integratedPhysicalSegmentsProfiles,
      loadPhysicalSegmentName,
      sortedTransmissionAllocations,
      summaryRowPhysicalSegmentsProfiles,
      transmissionPhysicalSegments,
      show_losses,
    ],
  );

  const hasChanged = useCallback(
    (
      previousProfileInformationDataSet: TProfileDataGridRow[] | undefined,
    ): boolean =>
      (previousPageMode !== undefined && previousPageMode !== pageMode) ||
      (previousProfileInformationDataSet === undefined && !isLoading) ||
      (isDetailUpdating === false && previousIsDetailUpdating === true),
    [
      isDetailUpdating,
      isLoading,
      pageMode,
      previousIsDetailUpdating,
      previousPageMode,
    ],
  );

  const initialProfileInformationDataSet: TProfileDataGridRow[] | undefined =
    useInitialData(profileInformationDataSet, hasChanged);

  const profileInformationColumns = useMemo(
    () =>
      getProfileInformationColumns(
        transmissionPhysicalSegments,
        sortedTransmissionAllocations,
        generationPhysicalSegment,
        generationPhysicalSegmentName,
        loadPhysicalSegmentName,
        showReliability,
        selectedTableConfiguration,
        useUniqueProfiles,
        viewMode,
        isDetailDeleted,
        isDetailUpdating,
        initialProfileInformationDataSet,
        show_losses,
        isPrintView,
      ),
    [
      generationPhysicalSegment,
      generationPhysicalSegmentName,
      initialProfileInformationDataSet,
      isDetailDeleted,
      isDetailUpdating,
      loadPhysicalSegmentName,
      selectedTableConfiguration,
      showReliability,
      sortedTransmissionAllocations,
      transmissionPhysicalSegments,
      useUniqueProfiles,
      viewMode,
      show_losses,
      isPrintView,
    ],
  );

  return {
    adjustedPhysicalSegmentsProfiles,
    hasGenerationReliability,
    hasLoadReliability,
    initialProfileInformationDataSet,
    profileInformationColumns,
    profileInformationDataSet,
    profileInformationSummaryRows,
  };
};

export default useProfileInformationReview;
