interface ICancelledIconProps {
  className?: string;
}

const CancelledIcon = ({ className }: ICancelledIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#c33333'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M526.127 324.902c-24.346 0-46.479 3.689-66.398 11.804-20.657 7.377-37.625 19.181-52.38 34.674-14.755 14.755-25.822 33.2-33.937 55.332-8.115 22.87-12.542 47.954-12.542 76.726 0 28.034 3.69 53.118 11.804 74.513 7.378 21.395 17.706 39.838 31.724 53.856 14.017 14.755 30.985 25.083 50.905 32.46 19.919 7.378 42.79 11.067 68.61 11.067 17.707 0 36.888-1.475 55.332-4.426 19.181-2.951 36.888-6.64 53.856-12.542v75.988c-16.968 5.164-35.412 8.853-54.594 11.804-19.92 2.214-39.838 3.689-60.495 3.689-41.315 0-76.727-5.902-107.712-16.968-30.986-11.804-56.807-28.773-77.464-49.43-21.395-21.394-36.888-47.216-47.216-76.726-10.329-29.51-15.493-61.971-15.493-98.12 0-36.888 5.164-70.825 16.968-102.548 11.066-30.986 26.56-58.283 47.954-81.153 20.657-23.608 47.216-41.314 78.94-54.594 31.723-13.28 67.135-19.92 106.974-20.657 20.657 0 39.838 1.476 58.282 4.427 18.444 2.95 34.674 6.64 49.43 11.804v75.25c-19.182-6.64-37.626-10.328-53.857-13.28-16.968-2.212-33.198-2.95-48.691-2.95z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default CancelledIcon;
