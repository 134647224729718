import {
  ALL_TIME_ZONE_OPTIONS,
  ALL_TIME_ZONES,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
} from 'constants/time';
import { EDateTimeRangeDST } from 'enums/Time';
import { TTimeZone, TTimeZoneOption } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

/**
 * Type predicate:
 * @param value
 */
export const isTimeZone = (value: any): value is TTimeZone => {
  return ALL_TIME_ZONES.includes(value);
};

export const getDateTimeForTimeZone = (
  dateTime: ZonedDateTime,
  timeZone: TTimeZone,
): ZonedDateTime => dateTime.withTimeZone(timeZone, true);

export const getUTCDateTimeForTimeZone = (
  dateTime: ZonedDateTime,
  timeZone: TTimeZone,
): ZonedDateTime =>
  dateTime.withTimeZone(timeZone, true).withTimeZone('UTC', false);

// A simple check to determine if a given date time range has a short day, a
// long day or neither. This assumes that the date time range does NOT cross
// multiple DST date times which cancel out the hour changes over this range.
export const getDateTimeRangeDstType = (
  startDateTime: ZonedDateTime,
  endDateTime: ZonedDateTime,
): EDateTimeRangeDST => {
  const hourDifference: number = endDateTime.diff(startDateTime, 'hours') % 24;
  return hourDifference === 1
    ? EDateTimeRangeDST.Longer
    : hourDifference === 23
    ? EDateTimeRangeDST.Shorter
    : EDateTimeRangeDST.Normal;
};

export const toFormattedDateTimeString = (
  value: string,
  timeZone: TTimeZone,
): string => ZonedDateTime.parseIso(value, timeZone).format(DATE_TIME_FORMAT);

export const toFormattedDateTimeStringHandleNull = (
  value: string | null,
  timeZone: TTimeZone,
): string | null => (value ? toFormattedDateTimeString(value, timeZone) : null);

export const toFormattedDateString = (
  value: string,
  timeZone: TTimeZone,
): string => ZonedDateTime.parseIso(value, timeZone).format(DATE_FORMAT);

export const toFormattedDateStringHandleNull = (
  value: string | null,
  timeZone: TTimeZone,
): string | null => (value ? toFormattedDateString(value, timeZone) : null);

export const toFormattedUtcString = (value: ZonedDateTime): string =>
  value
    .withTimeZone('UTC', false)
    .withSeconds(0)
    .withMilliseconds(0)
    .toIsoString();

/**
 * Convert the time zone to the given zone, preserving the local time.
 * You can think of the local time as the formatted time when no offset
 * or zone information is included.
 * @param datetime the instant to modify
 * @param tz the time zone to convert to
 */
export const setTimeZonePreserveLocal = (
  datetime: ZonedDateTime,
  tz: TTimeZone | undefined,
) => {
  if (tz === undefined) {
    return datetime;
  }
  // preserve local date time (implies non-tz format of time is preserved)
  // but this does change the "valueOf"/"epoch millis" value
  // this is expected since a date time picker is concerned only with
  // the local date time selection
  return datetime.withTimeZone(tz, true);
};

/**
 * Like setTimeZonePreserveLocal but can handle null or undefined inputs.
 * @param datetime the instant to modify
 * @param tz the time zone to convert to
 */
export const setTimeZonePreserveLocalSafe = (
  datetime: ZonedDateTime | null | undefined,
  tz: TTimeZone | undefined,
): ZonedDateTime | null => {
  if (datetime === null || datetime === undefined) {
    return null;
  }
  return setTimeZonePreserveLocal(datetime, tz);
};

export const getTimeZoneDisplayString = (timeZone: TTimeZone): string => {
  const timeZoneOption: TTimeZoneOption | undefined =
    ALL_TIME_ZONE_OPTIONS.find(
      (timeZoneOption: TTimeZoneOption): boolean =>
        timeZoneOption.value === timeZone,
    );

  return timeZoneOption === undefined ? '' : timeZoneOption.label;
};
