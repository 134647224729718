import { List as AntDesignList } from 'antd';
import DragableListRow from 'components/molecules/ReorderableList/DraggableListRow/DraggableListRow';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export interface IReorderableListProps<T> {
  bordered?: boolean;
  className?: string;
  data: T[];
  footer?: string | JSX.Element;
  header?: string | JSX.Element;
  itemRenderer: (item: T) => JSX.Element;
  onMoveRow: (dragIndex: number, hoverIndex: number) => void;
}

const ReorderableList = <T extends any>({
  bordered,
  className,
  data,
  footer,
  header,
  itemRenderer,
  onMoveRow,
}: IReorderableListProps<T>): JSX.Element => {
  const ListRenderItem = (item: T, index: number): JSX.Element => (
    <DragableListRow
      content={itemRenderer(item)}
      index={index}
      moveRow={onMoveRow}
    />
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <AntDesignList
        bordered={bordered}
        className={className}
        dataSource={data}
        footer={footer}
        header={header}
        renderItem={ListRenderItem}
        size='small'
      />
    </DndProvider>
  );
};

export default ReorderableList;
