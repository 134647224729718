import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { ERegistryType } from 'enums/General';
import { EPointTypeName } from 'enums/Point';
import { IRegistryEntitiesResponse, IRegistryEntity } from 'interfaces/Entity';
import { IRegistryPoint } from 'interfaces/Point';
import {
  DEFAULT_OPTIONS,
  NAESB_REGISTRY_SERVICE_API_NAME,
} from 'services/constants';
import {
  REGISTRY_ENTITIES_BY_TYPE_ROUTE,
  REGISTRY_ENTITIES_ROUTE,
  REGISTRY_POINTS_ROUTE,
} from 'services/naesb-registry/constants';
import { TToEntityId } from 'types/ToEntity';

export const retrieveRegistryEntities = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IRegistryEntitiesResponse>> => {
  return await API.get(
    NAESB_REGISTRY_SERVICE_API_NAME,
    REGISTRY_ENTITIES_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveRegistryEntitiesByRegistryType = async (
  toEntityId: TToEntityId,
  registryType: ERegistryType,
  taggingEntityIds?: number[],
): Promise<AxiosResponse<IRegistryEntity[]>> => {
  return await API.get(
    NAESB_REGISTRY_SERVICE_API_NAME,
    REGISTRY_ENTITIES_BY_TYPE_ROUTE(toEntityId, registryType, taggingEntityIds),
    DEFAULT_OPTIONS,
  );
};

export const retrieveRegistryPoints = async (
  toEntityId: TToEntityId,
  pointTypeName: EPointTypeName,
  baTaggingEntityId?: number,
  pseTaggingEntityId?: number,
  tspTaggingEntityId?: number,
): Promise<AxiosResponse<IRegistryPoint[]>> => {
  return await API.get(
    NAESB_REGISTRY_SERVICE_API_NAME,
    REGISTRY_POINTS_ROUTE(
      toEntityId,
      pointTypeName,
      baTaggingEntityId,
      pseTaggingEntityId,
      tspTaggingEntityId,
    ),
    DEFAULT_OPTIONS,
  );
};
