import { TMomentRange } from 'components/molecules/DateTimeRangePicker/ZonedDateTimeRangePicker';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { momentToZonedDateTime } from 'utils/zonedDateTime';

export const toZonedDateTimeRange = (
  timeZone: TTimeZone,
  momentRange: TMomentRange,
): TZonedDateTimeRange =>
  momentRange === null
    ? null
    : [
        momentToZonedDateTime(timeZone, momentRange[0] ?? null),
        momentToZonedDateTime(timeZone, momentRange[1] ?? null),
      ];
