import { IIndexable } from 'interfaces/General';
import { ISummaryStyles, ISummaryThemedStyles } from 'interfaces/Summary';
import { ReactNode } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { unpackSummaryStyles } from 'utils/styles';

interface IStyledDivProps {
  height?: string;
  minWidth: number;
  padding?: string;
  textAlign: string;
  summaryStyles?: ISummaryStyles;
}

const StyledDiv = styled.div<IStyledDivProps>`
  ${(props) => unpackSummaryStyles(props.summaryStyles)}
  ${(props) => (props.height === undefined ? '' : `height: ${props.height};`)}
  ${(props) =>
    props.padding === undefined ? '' : `padding: ${props.padding};`}
  min-width: ${(props) => props.minWidth}px;
  text-align: ${(props) => props.textAlign};
  white-space: nowrap;
`;

export interface ITextAlignedProps {
  children: ReactNode;
  height?: string;
  minWidth: number;
  padding?: string;
  textAlign: string;
  summaryThemedStyles?: ISummaryThemedStyles & IIndexable;
}

const TextAligned = ({
  children,
  height,
  minWidth,
  padding,
  summaryThemedStyles,
  textAlign,
}: ITextAlignedProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  const summaryStyles: ISummaryStyles | undefined =
    summaryThemedStyles?.[currentTheme!];

  return (
    <StyledDiv
      height={height}
      minWidth={minWidth}
      padding={padding}
      summaryStyles={summaryStyles}
      textAlign={textAlign}
    >
      {children}
    </StyledDiv>
  );
};

export default TextAligned;
