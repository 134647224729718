import * as am4core from '@amcharts/amcharts4/core';
import { IProfileSegment } from 'components/organisms/ProfileGraphView/types';
import {
  BACKGROUND_WHITE,
  HIGHLIGHT_BLUE,
  HIGHLIGHT_DARK_MAGENTA,
  HIGHLIGHT_MAGENTA,
  STATE_BLUE,
  STATE_GREEN,
  STATE_GREY,
  STATE_ORANGE,
  STATE_RED,
  TEXT_DARK,
} from 'constants/styles';
import { EProfileSegment } from 'enums/ETag';
import { IOption } from 'interfaces/Component';

export const STATE_BLUE_COLOR = am4core.color(STATE_BLUE);
export const STATE_GREEN_COLOR = am4core.color(STATE_GREEN);
export const STATE_GREY_COLOR = am4core.color(STATE_GREY);
export const STATE_ORANGE_COLOR = am4core.color(STATE_ORANGE);
export const STATE_RED_COLOR = am4core.color(STATE_RED);

export const RANGE_HIGHLIGHT_BLUE_COLOR = am4core.color(HIGHLIGHT_BLUE);

export const TRANSMISSION_LIMIT_COLOUR = STATE_BLUE;

export const TRANSMISSION_LIMIT_COLOR = am4core.color(
  TRANSMISSION_LIMIT_COLOUR,
);

export const CURRENT_LEVEL_COLOUR = STATE_GREEN;
export const CURRENT_LEVEL_WITH_RELIABILITY_LIMIT_COLOUR = STATE_RED;
export const CURRENT_LEVEL_WITH_RELOAD_COLOUR = STATE_ORANGE;

export const CURRENT_TIME_COLOR = am4core.color(HIGHLIGHT_MAGENTA);

export const USER_TIME_COLOR = am4core.color(HIGHLIGHT_DARK_MAGENTA);

export const WHITE_COLOR = am4core.color(BACKGROUND_WHITE);
export const TEXT_DARK_COLOR = am4core.color(TEXT_DARK);

export const PROFILE_SEGMENT_GEN: IProfileSegment = {
  profileSegment: EProfileSegment.Gen,
};

export const PROFILE_SEGMENT_LOAD: IProfileSegment = {
  profileSegment: EProfileSegment.Load,
};

export const PROFILE_SEGMENT_GEN_OPTION: IOption<IProfileSegment> = {
  label: 'Generation',
  value: PROFILE_SEGMENT_GEN,
};

export const PROFILE_SEGMENT_LOAD_OPTION: IOption<IProfileSegment> = {
  label: 'Load',
  value: PROFILE_SEGMENT_LOAD,
};
