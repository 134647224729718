import Button from 'components/atoms/Button/Button';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { STANDARD_SPACING_VALUE } from 'constants/styles';
import { EPageMode } from 'enums/Page';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TDetailPageLocationParameters } from 'types/Detail';
import { TRootState } from 'types/Redux';
import { detailPageLocationString } from 'utils/detail';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';

const Actions = styled(SeparatedRowLayout)`
  justify-content: flex-end;
  padding-top: ${2 * STANDARD_SPACING_VALUE}px;
`;

const Message = styled.div``;

interface IRefreshActionsProps {
  onKeep: () => void;
  onRefresh: () => void;
}

const retrieveRefreshActionsState = (state: TRootState) => {
  const { draft_id, isDetailEdited, tag_primary_key, toEntity } =
    state.detail.present;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    draft_id,
    isDetailEdited,
    tag_primary_key,
    timeZone,
    toEntity,
  };
};

const RefreshActions = ({
  onKeep,
  onRefresh,
}: IRefreshActionsProps): JSX.Element => {
  const { draft_id, isDetailEdited, tag_primary_key, timeZone, toEntity } =
    useSelector(retrieveRefreshActionsState);

  const handleReview = useMemo(() => {
    if (toEntity !== null) {
      let linkOptions: TDetailPageLocationParameters | null = null;

      if (draft_id === null) {
        if (tag_primary_key !== undefined) {
          linkOptions = {
            defaultTimeZone: timeZone,
            mode: EPageMode.Review,
            tagPrimaryKey: tag_primary_key,
            toEntity: toEntity.to_entity,
          };
        }
      } else {
        linkOptions = {
          defaultTimeZone: timeZone,
          draftId: draft_id,
          mode: EPageMode.Review,
          toEntity: toEntity.to_entity,
        };
      }

      if (linkOptions !== null) {
        const href: string = detailPageLocationString(linkOptions);

        return () => {
          window.open(`${process.env.PUBLIC_URL}${href}`, '_blank');
        };
      }
    }

    return undefined;
  }, [draft_id, tag_primary_key, timeZone, toEntity]);

  return (
    <>
      {isDetailEdited ? (
        <Message>
          Updating may discard some or all of your current changes
        </Message>
      ) : null}
      <Actions>
        <Button label='Keep View' onClick={onKeep} />
        <Button label='Update View' onClick={onRefresh} />
        {isDetailEdited && handleReview !== undefined ? (
          <Button
            isPrimary={true}
            label='Review Updates'
            onClick={handleReview}
          />
        ) : null}
      </Actions>
    </>
  );
};

export default RefreshActions;
