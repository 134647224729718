import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import { BUTTON_ICON_DIMENSION_VALUE } from 'constants/styles';
import { IContactInfo } from 'interfaces/General';
import {
  IInputColumnConfig,
  IInputPhoneNumberColumnConfig,
  IViewDataTableColumn,
} from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { getInitialContactInfo } from 'utils/detail';
import {
  contactInputRender,
  faxInputPhoneNumberRender,
  phoneInputPhoneNumberRender,
} from 'utils/views';

export const CONTACT_INFO_REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

export const getEditContactInfoColumns = (
  contactInputColumnConfig: IInputColumnConfig<IContactInfo>,
  phoneInputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<IContactInfo>,
  faxInputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<IContactInfo>,
  hasChanged?: boolean,
  shouldContactUpdate?: () => boolean,
  shouldFaxUpdate?: () => boolean,
  shouldPhoneUpdate?: () => boolean,
): IViewDataTableColumn<IContactInfo>[] => [
  {
    dataIndex: 'contact',
    render: contactInputRender(contactInputColumnConfig),
    shouldCellUpdate: (
      record: IContactInfo,
      previousRecord: IContactInfo,
    ): boolean =>
      (shouldContactUpdate !== undefined && shouldContactUpdate()) ||
      hasChanged ||
      record.contact !== previousRecord.contact,
    title: 'Contact',
    width: '149px',
  },
  {
    dataIndex: 'phone',
    render: phoneInputPhoneNumberRender(phoneInputPhoneNumberColumnConfig),
    shouldCellUpdate: (
      record: IContactInfo,
      previousRecord: IContactInfo,
    ): boolean =>
      (shouldPhoneUpdate !== undefined && shouldPhoneUpdate()) ||
      hasChanged ||
      record.phone !== previousRecord.phone,
    title: 'Phone',
    width: '160px',
  },
  {
    dataIndex: 'fax',
    render: faxInputPhoneNumberRender(faxInputPhoneNumberColumnConfig),
    shouldCellUpdate: (
      record: IContactInfo,
      previousRecord: IContactInfo,
    ): boolean =>
      (shouldFaxUpdate !== undefined && shouldFaxUpdate()) ||
      hasChanged ||
      record.fax !== previousRecord.fax,
    title: 'Fax',
    width: '172px',
  },
];

export interface IContactInfoEditableProps {
  contactInputColumnConfig: IInputColumnConfig<IContactInfo>;
  data: IContactInfo[];
  faxInputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<IContactInfo>;
  hasChanged?: boolean;
  initialEditKey: string;
  isDisabled?: boolean;
  onRemove: (record: IContactInfo, index: number) => void;
  phoneInputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<IContactInfo>;
  shouldContactUpdate?: () => boolean;
  shouldFaxUpdate?: () => boolean;
  shouldPhoneUpdate?: () => boolean;
}

const ContactInfoEditable = (props: IContactInfoEditableProps): JSX.Element => {
  const {
    contactInputColumnConfig,
    data,
    faxInputPhoneNumberColumnConfig,
    hasChanged,
    initialEditKey,
    isDisabled,
    onRemove,
    phoneInputPhoneNumberColumnConfig,
    shouldContactUpdate,
    shouldFaxUpdate,
    shouldPhoneUpdate,
  } = props;

  const editContactInfoColumns: IViewDataTableColumn<IContactInfo>[] = useMemo(
    () =>
      getEditContactInfoColumns(
        contactInputColumnConfig,
        phoneInputPhoneNumberColumnConfig,
        faxInputPhoneNumberColumnConfig,
        hasChanged,
        shouldContactUpdate,
        shouldFaxUpdate,
        shouldPhoneUpdate,
      ),
    [
      contactInputColumnConfig,
      faxInputPhoneNumberColumnConfig,
      hasChanged,
      phoneInputPhoneNumberColumnConfig,
      shouldContactUpdate,
      shouldFaxUpdate,
      shouldPhoneUpdate,
    ],
  );

  const initialiser = useCallback(
    () => getInitialContactInfo(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditableDataTable<IContactInfo>
      columns={editContactInfoColumns}
      data={data}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={1}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={CONTACT_INFO_REMOVE_BUTTON_OFFSET}
    />
  );
};
export default ContactInfoEditable;
