import { IScrollConfig } from 'components/molecules/DataTable/DataTable';
import { EApprovalStatus } from 'enums/Approval';
import { IOption } from 'interfaces/Component';
import { IPendingRequest } from './types';

export const TO_ENTITY_REVIEW_PENDING_REQUESTS_MODAL_WIDTH_VALUE = 1822;

export const EDIT_ETAG_PENDING_REQUEST_LABEL = 'eTagPendingRequest';

export const ALL_TAGS_OPTION = 'All Tags';

export const APPROVAL_ACTION_OPTIONS: IOption<EApprovalStatus>[] = [
  {
    label: 'Approve',
    value: EApprovalStatus.Approved,
  },
  {
    label: 'Deny',
    value: EApprovalStatus.Denied,
  },
  {
    label: 'Study',
    value: EApprovalStatus.Study,
  },
];

export const SCROLL_CONFIGURATION: IScrollConfig = { x: true };

export const pendingRequestsSorter = (
  a: IPendingRequest,
  b: IPendingRequest,
): number => {
  switch (a.ui_tag_id?.localeCompare(b.ui_tag_id ?? '') ?? 0) {
    case 0:
      return (
        a.request_act_on_by_time?.localeCompare(
          b.request_act_on_by_time ?? '',
        ) ?? 0
      );
    case 1:
      return 1;
    case -1:
      return -1;
    default:
      return 0;
  }
};

export const tagSelectSorter = (
  a: IOption<string>,
  b: IOption<string>,
): number => {
  return a.label.localeCompare(b.label);
};
