import { STANDARD_SPACING_VALUE } from 'constants/styles';
import styled from 'styled-components';

const INFO_MESSAGE_MAX_WIDTH = '238px';

interface IProps {
  maxWidth?: string;
  topMargin?: number;
}

const InfoMessage = styled.span<IProps>`
  font-weight: bold;
  margin-top: ${(props) =>
    props.topMargin === undefined ? STANDARD_SPACING_VALUE : props.topMargin}px;
  max-width: ${(props) =>
    props.maxWidth === undefined ? INFO_MESSAGE_MAX_WIDTH : props.maxWidth};
  text-align: left;
`;

export default InfoMessage;
