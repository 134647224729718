import Input from 'components/atoms/Input/Input';
import ConfigurationSelector from 'components/molecules/ConfigurationSelector/ConfigurationSelector';
import Select from 'components/molecules/Select/Select';
import { STANDARD_SPACING } from 'constants/styles';
import usePermissions, { IusePermissionsProps } from 'hooks/usePermissions';
import { IEditableOption, IOption } from 'interfaces/Component';
import { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';

const SELECT_EXTRA_WIDTH_VALUE = 27;

const ConfigurationSelection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 1px;
  }

  > :first-child {
    margin-right: ${STANDARD_SPACING};
  }
`;

interface IStyledInputProps {
  width: number;
}

const StyledInput = styled(Input)<IStyledInputProps>`
  width: ${(props) => props.width}px;
`;

interface IConfigurationSelectProps {
  width: number;
}

const ConfigurationSelect = styled.div<IConfigurationSelectProps>`
  width: ${(props) => props.width}px;
`;

export interface IToEntityConfigurationEditProps<T>
  extends IusePermissionsProps {
  configurationToUid: (configuration: T) => string;
  copyConfiguration: (configuration: T) => T;
  getOptionLabel?: (option: IOption<T>) => string;
  isDisabled?: boolean;
  label?: string;
  newConfiguration: () => T;
  onAddConfigurationOption: (configurationOption: IEditableOption<T>) => void;
  onConfigurationLabelChange: (label: string) => void;
  onSelect: (configuration: T | undefined) => void;
  options: IEditableOption<T>[];
  selectedConfigurationOption?: IEditableOption<T>;
  selectPlaceholder?: string;
  selectWidth: number;
}

const ToEntityConfigurationEdit = <T extends object>(
  props: IToEntityConfigurationEditProps<T>,
): JSX.Element | null => {
  const {
    configurationToUid,
    copyConfiguration,
    encodedPermissionsId,
    getOptionLabel,
    isDisabled,
    label,
    newConfiguration,
    onAddConfigurationOption,
    onConfigurationLabelChange,
    onSelect,
    options,
    selectedConfigurationOption,
    selectPlaceholder,
    selectWidth,
  } = props;
  const permissions = usePermissions(encodedPermissionsId);

  const handleConfigurationNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onConfigurationLabelChange(event.target.value);
    },
    [onConfigurationLabelChange],
  );

  if (permissions.isDisplayable) {
    const selector: JSX.Element = permissions.isExecutable ? (
      <>
        <StyledInput
          isDisabled={isDisabled || selectedConfigurationOption === undefined}
          onChange={handleConfigurationNameChange}
          placeholder={selectPlaceholder}
          value={selectedConfigurationOption?.label}
          width={selectWidth}
        />
        <ConfigurationSelector<T>
          copyConfiguration={copyConfiguration}
          getOptionLabel={getOptionLabel}
          isDisabled={isDisabled}
          newConfigurationGenerator={newConfiguration}
          onAddConfigurationOption={onAddConfigurationOption}
          onChange={onSelect}
          options={options}
          showSelectionArrowOnly={true}
          showUnsavedIndicator={true}
          value={selectedConfigurationOption?.value}
          valueToUid={configurationToUid}
          width={selectWidth}
        />
      </>
    ) : (
      <ConfigurationSelect width={selectWidth + SELECT_EXTRA_WIDTH_VALUE}>
        <Select<T>
          isDisabled={isDisabled}
          onChange={onSelect}
          options={options}
          placeholder={selectPlaceholder}
          value={selectedConfigurationOption?.value}
          valueToUid={configurationToUid}
        />
      </ConfigurationSelect>
    );

    return (
      <ConfigurationSelection>
        <div>{label}</div>
        {selector}
      </ConfigurationSelection>
    );
  }

  return null;
};

export default ToEntityConfigurationEdit;
