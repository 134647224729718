import { IOption } from 'interfaces/Component';
import {
  IETagCheckoutReport,
  IETagCheckoutReportDataSet,
} from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import { IColumnConfiguration, ITableConfiguration } from 'interfaces/Summary';
import { TTimeZone } from 'types/DateTime';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

/**
 * The backend uses different attribute names than
 * the attribute names of the columns on the UI
 * so we must map the UI column names to the backend attribute
 */
export const getAttributeNamesFromTableConfiguration = (
  config: ITableConfiguration,
): string[] => {
  const attributeNames: string[] = [];

  config.columns.forEach((column: IColumnConfiguration) => {
    switch (column.dataIndex) {
      case 'tag_code':
      case 'composite_state':
      case 'last_request_time':
      case 'last_request_status':
      case 'last_request_id':
      case 'curtailed':
      case 'pending_requests':
      case 'approval_right':
      case 'contact':
      case 'terminated_cancelled':
      case 'denied':
      case 'withdrawn':
      case 'total_mw': {
        attributeNames.push(column.dataIndex.toLocaleUpperCase());
        break;
      }
      case 'ui_tag_id': {
        attributeNames.push('TAG_ID');
        break;
      }
      case 'ui_gca': {
        attributeNames.push('GCA');
        break;
      }
      case 'ui_cpse': {
        attributeNames.push('CPSE');
        break;
      }
      case 'ui_lca': {
        attributeNames.push('LCA');
        break;
      }
      case 'transaction_type': {
        attributeNames.push('TAG_TYPE');
        break;
      }
      case 'ui_source': {
        attributeNames.push('SOURCE');
        break;
      }
      case 'ui_sink': {
        attributeNames.push('SINK');
        break;
      }
      case 'notes': {
        attributeNames.push('TAG_NOTES');
        break;
      }
      case 'test_flag': {
        attributeNames.push('TEST');
        break;
      }
      case 'creation_time': {
        attributeNames.push('TAG_CREATED_TIME');
        break;
      }
      case 'start_date': {
        attributeNames.push('TAG_START_TIME');
        break;
      }
      case 'end_date': {
        attributeNames.push('TAG_STOP_TIME');
        break;
      }
      case 'act_on_by_time': {
        attributeNames.push('APPROVAL_DEADLINE');
        break;
      }
      case 'approved_termination_time': {
        attributeNames.push('TERMINATION_TIME');
        break;
      }
      case 'denied_withdrawn': {
        attributeNames.push(...['DENIED', 'WITHDRAWN']);
        break;
      }
      case 'is_subhourly_tag': {
        attributeNames.push('SUBHOURLY');
        break;
      }
      case 'day': {
        attributeNames.push('DATE');
        break;
      }
      case 'on_peak_total_mw': {
        attributeNames.push('ON_PEAK_TOTAL');
        break;
      }
      case 'off_peak_total_mw': {
        attributeNames.push('OFF_PEAK_TOTAL');
        break;
      }
      case 'hour_endings': {
        attributeNames.push(
          ...[
            'HE01',
            'HE02',
            'HE03',
            'HE04',
            'HE05',
            'HE06',
            'HE07',
            'HE08',
            'HE09',
            'HE10',
            'HE11',
            'HE12',
            'HE13',
            'HE14',
            'HE15',
            'HE16',
            'HE17',
            'HE18',
            'HE19',
            'HE20',
            'HE21',
            'HE22',
            'HE23',
            'HE24',
          ],
        );
        break;
      }
      default: {
        break;
      }
    }
  });

  return attributeNames;
};

const getFilterNameFromId = (
  filterId: string,
  filterOptions: IOption<ICustomFilter>[],
): string => {
  const filterOption: IOption<ICustomFilter> | undefined = filterOptions.find(
    (option: IOption<ICustomFilter>) => option.value.filter_id === filterId,
  );
  return !isEmptyValue(filterOption?.value.filter_name)
    ? filterOption?.value.filter_name?.trim() ?? ''
    : 'AllTags';
};

/**
 * @returns A dataset with the string used to display
 * the filename in the checkout report review table
 */
export const getCheckoutDataSetFromData = (
  checkoutData: IETagCheckoutReport[],
  filterOptions: IOption<ICustomFilter>[],
  timeZone: TTimeZone,
): IETagCheckoutReportDataSet[] => {
  return checkoutData.map(
    (checkoutDatum: IETagCheckoutReport): IETagCheckoutReportDataSet => ({
      ...checkoutDatum,
      file: {
        fileName: `${ZonedDateTime.parseIso(
          checkoutDatum.request_time,
          timeZone,
        ).fileFormat()}_${getFilterNameFromId(
          checkoutDatum.filter_ids,
          filterOptions,
        ).replaceAll(' ', '_')}_${ZonedDateTime.parseIso(
          checkoutDatum.start,
          timeZone,
        ).fileFormat()}_${ZonedDateTime.parseIso(
          checkoutDatum.end,
          timeZone,
        ).fileFormat()}.csv`,
        fileUrl: checkoutDatum.report_url,
        inProgress: checkoutDatum.in_progress,
      },
    }),
  );
};

/**
 * @returns sorts IETagCheckoutReport objects by request time, most recent first
 */
export const checkoutReportSorter = (
  a: IETagCheckoutReport,
  b: IETagCheckoutReport,
): number =>
  // can't pass a timezone to this sorter, but all items should have
  //the same timezone, so we use UTC to compare them to each other
  ZonedDateTime.parseIso(a.request_time, 'UTC').isBefore(
    ZonedDateTime.parseIso(b.request_time, 'UTC'),
  )
    ? 1
    : ZonedDateTime.parseIso(a.request_time, 'UTC').isAfter(
        ZonedDateTime.parseIso(b.request_time, 'UTC'),
      )
    ? -1
    : 0;
