import { StopOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const ClearTableFiltersIcon = styled(StopOutlined)`
  width: 24px;
  height: 24px;
  font-size: 15px;
`;

interface IProps {
  handleClearGridFilters: () => void;
}

const ClearGridFilters = (props: IProps): JSX.Element => {
  const { handleClearGridFilters } = props;

  return <ClearTableFiltersIcon onClick={handleClearGridFilters} size={30} />;
};

export default ClearGridFilters;
