import { MessageOutlined } from '@ant-design/icons';
import {
  AutoComplete as AntDesignAutoComplete,
  Input as AntDesignInput,
  Popover as AntDesignPopover,
} from 'antd';
import DataIndicator from 'components/atoms/DataIndicator/DataIndicator';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Input from 'components/atoms/Input/Input';
import Link from 'components/atoms/Link/Link';
import ContactInfoDataTable from 'components/molecules/ContactInfoDataTable/ContactInfoDataTable';
import ContactInfoEdit from 'components/molecules/ContactInfoEdit/ContactInfoEdit';
import ContainedText from 'components/molecules/ContainedText/ContainedText';
import ContractsEdit from 'components/molecules/ContractsEdit/ContractsEdit';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import InputPhoneNumber from 'components/molecules/InputPhoneNumber/InputPhoneNumber';
import MiscInfoDataTable from 'components/molecules/MiscInfoDataTable/MiscInfoDataTable';
import MiscInfoEdit from 'components/molecules/MiscInfoEdit/MiscInfoEdit';
import PopoverDataTable from 'components/molecules/PopoverDataTable/PopoverDataTable';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import OasisInfoReview from 'components/organisms/PhysicalPathReview/OasisInfoReview';
import {
  EDIT_KEY_SEPARATOR,
  INITIAL_RECORD_ID,
  TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY,
} from 'constants/Detail';
import { TRANSMISSION_ENERGY_PRODUCT_OPTIONS } from 'constants/ETag';
import {
  DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT,
  ERROR_BORDER,
  ICON_BUTTON_SIZE,
  ICON_BUTTON_SIZE_VALUE,
  INPUT_HEIGHT,
  STANDARD_SPACING,
  VIEW_DATA_TABLE_CELL_PADDING,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_NITS_RESOURCE_INPUT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_OASIS_INPUT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { EMiscInfosExpandedColumn } from 'enums/General';
import { EViewMode } from 'enums/View';
import usePrevious from 'hooks/usePrevious';
import LossMethodsEdit from 'hooks/useTransmissionEditColumns/LossMethodsEdit/LossMethodsEdit';
import OasisInfoEdit from 'hooks/useTransmissionEditColumns/OasisInfoEdit/OasisInfoEdit';
import { IEditOasisInfo } from 'hooks/useTransmissionEditColumns/OasisInfoEdit/types';
import { IAutoCompleteValue, IOption } from 'interfaces/Component';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IETagContactInfo,
  IETagMiscInfos,
  IETagTagId,
  IETagTransmissionAllocation,
} from 'interfaces/ETag';
import {
  IContactInfo,
  IContract,
  IEnergyProductInfo,
  IMiscInfo,
} from 'interfaces/General';
import {
  IAutoCompleteColumnConfig,
  IContactInfoEditColumnConfig,
  IContractsEditColumnConfig,
  IDateTimePickerColumnConfig,
  IIconButtonColumnConfig,
  IInputColumnConfig,
  IInputPhoneNumberColumnConfig,
  ILosesInfoEditColumnConfig,
  IMiscInfoEditColumnConfig,
  IOasisInfoEditColumnConfig,
  ISelectColumnConfig,
  IViewDataTableColumn,
} from 'interfaces/View';
import {
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IDetailContactInfos,
  IDetailLossAccounting,
  IDetailPhysicalSegment,
} from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import useAsyncEffect from 'use-async-effect';
import { isNumberSelectEmpty } from 'utils/detail';
import {
  entityInfoToString,
  entityInfoToUid,
  pseEntityInfoEqual,
} from 'utils/entity';
import { captureError } from 'utils/error';
import {
  energyProductInfoEqual,
  energyProductInfoToUid,
  eventToStringOrNull,
  isEmptyValue,
  selectOptionLabelFilter,
  simpleEqualityChecker,
} from 'utils/general';
import { pointInfoToString } from 'utils/point';
import { toFormattedDateTimeString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { ELossMethodEntryType } from '../enums/ETag';

const NotesIcon = styled(MessageOutlined)`
  font-size: 18px;
`;

const StyledTooltip = styled(Tooltip)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface IColouredCellProps {
  backgroundColour?: string;
}

const ColouredCell = styled.div<IColouredCellProps>`
  margin: -${VIEW_DATA_TABLE_CELL_PADDING};
  padding: ${VIEW_DATA_TABLE_CELL_PADDING};

  ${(props) =>
    props.backgroundColour === undefined
      ? ''
      : `background-color: ${props.backgroundColour}`};
`;

const ExpandContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const emptyRender = (): JSX.Element => <></>;

export const getColumnRender =
  <T extends any>(
    isUnconstrained: boolean,
    valueToString: (value: T) => string = (value: T): string => value as string,
    maxWidth?: string,
    valueToBackgroundColour?: (value: T) => string | undefined,
  ) =>
  (value: unknown): JSX.Element => {
    const valueAsString: string = isEmptyValue(value)
      ? ''
      : valueToString(value as T);

    return (
      <ColouredCell backgroundColour={valueToBackgroundColour?.(value as T)}>
        {isUnconstrained ? (
          valueAsString
        ) : (
          <ContainedText
            maxWidth={isEmptyValue(maxWidth) ? '100%' : maxWidth!}
            text={valueAsString}
          />
        )}
      </ColouredCell>
    );
  };

export const getOASISInfoColumnRender =
  <T extends any>(
    isUnconstrained: boolean,
    transmissionAllocations: IETagTransmissionAllocation[] | null,
    valueToString: (value: T) => string = (value: T): string => value as string,
    maxWidth?: string,
    valueToBackgroundColour?: (value: T) => string | undefined,
  ) =>
  (value: unknown, record: T): JSX.Element => {
    const valueAsString: string = isEmptyValue(value)
      ? ''
      : valueToString(value as T);

    const values: Array<string> = [];

    if (Array.isArray(valueAsString)) {
      (valueAsString as Array<string>).forEach((value) => {
        const lastIndex = transmissionAllocations
          ?.map(
            (alloc) =>
              alloc.contract_number === value &&
              alloc.physical_segment_ref ===
                (record as any).physical_segment_id,
          )
          .lastIndexOf(true);

        const productName =
          lastIndex !== undefined && lastIndex > -1 && transmissionAllocations
            ? transmissionAllocations[lastIndex]?.trans_product_ref
                ?.product_name
            : '';
        values.push(`${value} (${productName})`);
      });
    }
    let text = values.toString();

    return (
      <ColouredCell backgroundColour={valueToBackgroundColour?.(value as T)}>
        {isUnconstrained ? (
          text
        ) : (
          <ContainedText
            maxWidth={isEmptyValue(maxWidth) ? '100%' : maxWidth!}
            text={text}
          />
        )}
      </ColouredCell>
    );
  };

/**
 * We need to display the filename as a link for download if there is a report url
 * If there is no report url and the report is complete, there is no data
 * If the report is in progress, then we display that.
 */
export const getReportColumnRender =
  () =>
  (value: unknown): JSX.Element => {
    if (
      typeof value === 'object' &&
      value !== null &&
      'fileName' in value &&
      'fileUrl' in value &&
      'inProgress' in value
    ) {
      const valueSet: {
        fileName: string | null;
        fileUrl: string | null;
        inProgress: boolean;
      } = value as {
        fileName: string | null;
        fileUrl: string | null;
        inProgress: boolean;
      };
      return valueSet.fileUrl ? (
        <Link to={valueSet.fileUrl} external={true}>
          {valueSet.fileName}
        </Link>
      ) : (
        <ContainedText
          maxWidth='100%'
          text={`${valueSet.fileName ?? ''}${
            valueSet.inProgress ? ' (In Progress)' : ' (No Tags Found)'
          }`}
        />
      );
    } else {
      return <></>;
    }
  };

export const getColumnContractsRender =
  (isUnconstrained: boolean) =>
  (value: unknown): JSX.Element => {
    const contracts: string = isEmptyValue(value)
      ? ''
      : (value as IContract[])
          .filter(
            (contract: IContract): boolean => !isEmptyValue(contract.contract),
          )
          .map((contract: IContract): string => contract.contract!)
          .join(', ');

    return isUnconstrained ? (
      <>{contracts}</>
    ) : (
      <ContainedText maxWidth='100%' text={contracts} />
    );
  };

export const getColumnEnergyProductRender =
  () =>
  (value: unknown): JSX.Element =>
    (
      <ContainedText
        maxWidth='100%'
        text={value === null ? '' : (value as IEnergyProductInfo).product_name}
      />
    );

export const getColumnIconButton = <T extends any>(width: string) => {
  const StyledIconButton = styled(IconButton)`
    height: ${INPUT_HEIGHT};
    width: ${width};
  `;

  return (iconButtonColumnConfig: IIconButtonColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const { getIcon, getIsDisabled, icon, isDisabled, onClick } =
        iconButtonColumnConfig;

      const adjustedIcon: ReactNode = useMemo(
        () => (getIcon ? getIcon(value as number | string, record) : icon),
        [getIcon, icon, record, value],
      );

      const adjustedIsDisabled: boolean | undefined = useMemo(() => {
        if (getIsDisabled) {
          return getIsDisabled(record);
        }

        return isDisabled;
      }, [getIsDisabled, isDisabled, record]);

      const handleClick = useCallback(() => {
        onClick(record);
      }, [onClick, record]);

      return (
        <StyledIconButton
          icon={adjustedIcon}
          isDisabled={adjustedIsDisabled}
          onClick={handleClick}
        />
      );
    };
};

export const getColumnInputRender = <T extends any>(
  width: string,
  toolTipMaxCharacters?: number,
) => {
  const StyledInput = styled(Input)`
    width: ${width};
  `;

  return (inputColumnConfig: IInputColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        getInitialValue,
        getKey,
        onChange,
        shouldFocusOn,
        ...inputConfig
      } = inputColumnConfig;
      const inputRef =
        useRef<AntDesignInput>() as MutableRefObject<AntDesignInput>;

      useEffect(() => {
        if (inputRef && shouldFocusOn && shouldFocusOn(record)) {
          inputRef.current.focus();
        }
      }, [inputRef, record, shouldFocusOn]);

      const key: string = useMemo(() => getKey(record), [getKey, record]);

      const initialValue: string | null | undefined = useMemo(
        () =>
          getInitialValue === undefined ? undefined : getInitialValue(record),
        [getInitialValue, record],
      );

      const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          onChange(event, record);
        },
        [onChange, record],
      );

      const valueAsString: string | undefined =
        value === null ? undefined : (value as string);
      const showTooltip: boolean =
        toolTipMaxCharacters !== undefined &&
        valueAsString !== undefined &&
        valueAsString.length > toolTipMaxCharacters;

      return (
        <Tooltip isDisabled={!showTooltip} title={valueAsString}>
          <StyledInput
            {...inputConfig}
            initialValue={initialValue}
            inputRef={inputRef}
            key={key}
            onChange={handleChange}
            value={valueAsString}
          />
        </Tooltip>
      );
    };
};

export const getColumnAutoCompleteRender = <T extends any>(
  width: string,
  toolTipMaxCharacters?: number,
) => {
  const StyledAutoComplete = styled(AntDesignAutoComplete)`
    width: ${width};
  `;

  return (autoCompleteColumnConfig: IAutoCompleteColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        filterOption,
        getAutoCompleteValues,
        getDefaultValue,
        getKey,
        isDisabled,
        onChange,
        shouldFocusOn,
        toEntityId,
        ...inputConfig
      } = autoCompleteColumnConfig;

      const [autoCompleteValues, setAutoCompleteValues] = useState<
        IAutoCompleteValue[]
      >([]);
      const [refresh, setRefresh] = useState<boolean>(false);

      const key: string = useMemo(() => getKey(record), [getKey, record]);

      useAsyncEffect(async () => {
        try {
          const autoCompleteValues: IAutoCompleteValue[] =
            await getAutoCompleteValues(record);

          setAutoCompleteValues(autoCompleteValues);
        } catch (error: any) {
          captureError(error);
        }
      }, [refresh]);

      const initialValue: string | undefined = useMemo(
        () =>
          getDefaultValue === undefined ? undefined : getDefaultValue(record),
        [getDefaultValue, record],
      );

      const handleChange = useCallback(
        (value: string) => {
          onChange(value, record);
        },
        [onChange, record],
      );

      const handleFocus = useCallback(() => setRefresh(!refresh), [refresh]);

      const handleBlur = useCallback(() => setAutoCompleteValues([]), []);

      const valueAsString: string | undefined =
        value === null ? undefined : (value as string);
      const showTooltip: boolean =
        toolTipMaxCharacters !== undefined &&
        valueAsString !== undefined &&
        valueAsString.length > toolTipMaxCharacters;

      return (
        <Tooltip isDisabled={!showTooltip} title={valueAsString}>
          <StyledAutoComplete
            {...inputConfig}
            backfill={true}
            defaultValue={initialValue}
            disabled={isDisabled}
            filterOption={filterOption}
            key={key}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            options={autoCompleteValues}
            value={valueAsString}
          />
        </Tooltip>
      );
    };
};

export const getColumnInputPhoneNumberRender = <T extends any>(
  width: string,
) => {
  const StyledInputPhoneNumber = styled(InputPhoneNumber)`
    width: ${width};
  `;

  return (inputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        getInitialValue,
        getIsValid,
        onChange,
        ...inputPhoneNumberConfig
      } = inputPhoneNumberColumnConfig;

      const initialValue: string | null | undefined = useMemo(
        () =>
          getInitialValue === undefined ? undefined : getInitialValue(record),
        [getInitialValue, record],
      );

      const handleChange = (value: string | null) => {
        onChange(value, record);
      };

      const handleGetIsValid = useCallback(
        (isValid: boolean) => {
          if (getIsValid !== undefined) {
            const getIsValidForRecord:
              | ((isValid: boolean) => void)
              | undefined = getIsValid(record);
            if (getIsValidForRecord !== undefined) {
              getIsValidForRecord(isValid);
            }
          }
        },
        [getIsValid, record],
      );

      return (
        <StyledInputPhoneNumber
          {...inputPhoneNumberConfig}
          getIsValid={handleGetIsValid}
          initialValue={initialValue}
          onChange={handleChange}
          value={value as string}
        />
      );
    };
};

export const getColumnSelectRender = <T extends any, U>(
  width: string,
  minWidth?: string,
  valueToLabel?: (value: T[] | T | undefined) => string,
  shouldHighlight?: (value: T[] | T | undefined) => boolean,
) => {
  const StyledSelect = styled((props: ISelectProps<T>) => Select<T>(props))`
    width: ${width};

    ${minWidth === undefined
      ? ''
      : `
        min-width: ${minWidth};
      `}

    ${(props) =>
      shouldHighlight === undefined || !shouldHighlight(props.value)
        ? ''
        : `
      && .ant-select-selector {
        ${ERROR_BORDER}
      }
    `}
  `;

  const StyledTooltip = styled(Tooltip)`
    width: 100%;
  `;

  return (selectColumnConfig: ISelectColumnConfig<T, U>) =>
    (value: unknown, record: U, index: number): JSX.Element => {
      const {
        allowMultiple,
        equalityCheckerForOptions,
        getInitialValue,
        getInitialValues,
        getOptions,
        onChange,
        onChangeMultiple,
        shouldGetOptions,
        ...selectConfig
      } = selectColumnConfig;
      const [isLoading, setIsLoading] = useState<boolean>(true);
      const [options, setOptions] = useState<IOption<T>[]>([]);
      const previousRecord = usePrevious(record);
      const isMountedRef = useRef<boolean>(false);

      useEffect(() => {
        isMountedRef.current = true;

        return () => {
          isMountedRef.current = false;
        };
      }, []);

      useAsyncEffect(async () => {
        if (
          shouldGetOptions === undefined ||
          shouldGetOptions(record, previousRecord)
        ) {
          try {
            setIsLoading(true);

            const newOptions: IOption<T>[] = await getOptions(record, index);

            if (isMountedRef.current) {
              setOptions(newOptions);

              if (equalityCheckerForOptions) {
                if (allowMultiple && onChangeMultiple) {
                  const values: T[] = value as T[];
                  const updatedValue: T[] = [];

                  values.forEach((value: T) => {
                    if (
                      newOptions.find((option: IOption<T>): boolean =>
                        equalityCheckerForOptions(option.value, value),
                      ) !== undefined
                    ) {
                      updatedValue.push(value);
                    }
                  });

                  if (values.length !== updatedValue.length) {
                    onChangeMultiple(updatedValue, record);
                  }
                } else if (
                  onChange &&
                  value !== null &&
                  newOptions.find((option: IOption<T>): boolean =>
                    equalityCheckerForOptions(option.value, value as T),
                  ) === undefined
                ) {
                  onChange(undefined, record);
                }
              }
            }
          } catch (error: any) {
            captureError(error);
          } finally {
            if (isMountedRef.current) {
              setIsLoading(false);
            }
          }
        }
      }, [
        allowMultiple,
        equalityCheckerForOptions,
        getOptions,
        onChange,
        onChangeMultiple,
        previousRecord,
        record,
        shouldGetOptions,
        value,
      ]);

      const initialValue: T | null | undefined = useMemo(
        () =>
          getInitialValue === undefined ? undefined : getInitialValue(record),
        [getInitialValue, record],
      );

      const initialValues: T[] | null | undefined = useMemo(
        () =>
          getInitialValues === undefined ? undefined : getInitialValues(record),
        [getInitialValues, record],
      );

      const handleChange = (value: T | undefined) => {
        if (onChange) {
          onChange(value, record);
        }
      };

      const handleChangeMultiple = (values: T[]) => {
        if (onChangeMultiple) {
          onChangeMultiple(values, record);
        }
      };

      return (
        <StyledTooltip
          title={
            valueToLabel === undefined
              ? undefined
              : valueToLabel(allowMultiple ? (value as T[]) : (value as T))
          }
        >
          <StyledSelect
            {...selectConfig}
            allowMultiple={allowMultiple}
            initialValue={initialValue}
            initialValues={initialValues}
            isDisabled={isLoading || selectConfig.isDisabled}
            isLoading={isLoading}
            onChange={handleChange}
            onChangeMultiple={handleChangeMultiple}
            options={options}
            value={allowMultiple ? undefined : (value as T)}
            values={allowMultiple ? (value as T[]) : undefined}
          />
        </StyledTooltip>
      );
    };
};

export const getColumnDateTimePickerRender = <T extends any>(width: string) => {
  const StyledDateTimePicker = styled(DateTimePicker)`
    width: ${width};
  `;

  return (dateTimePickerColumnConfig: IDateTimePickerColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        getDisabledDate,
        getInitialValue,
        onChange,
        timeZone,
        ...dateTimePickerConfig
      } = dateTimePickerColumnConfig;

      const initialValue: ZonedDateTime | null | undefined = useMemo(() => {
        if (getInitialValue !== undefined) {
          const initialValue: string | null | undefined =
            getInitialValue(record);

          if (initialValue !== undefined) {
            return initialValue === null
              ? null
              : ZonedDateTime.parseIso(initialValue, timeZone);
          }
        }

        return undefined;
      }, [getInitialValue, record, timeZone]);

      const disabledDate = useMemo(() => {
        if (getDisabledDate) {
          return getDisabledDate(record);
        }

        return undefined;
      }, [getDisabledDate, record]);

      const handleChange = useCallback(
        (dateTime: ZonedDateTime | null) => {
          onChange(dateTime, record);
        },
        [onChange, record],
      );

      return (
        <StyledDateTimePicker
          {...dateTimePickerConfig}
          initialValue={initialValue}
          disabledDate={disabledDate}
          onChange={handleChange}
          timeZone={timeZone}
          value={
            isEmptyValue(value)
              ? undefined
              : ZonedDateTime.parseIso(value as string, timeZone)
          }
        />
      );
    };
};

export const getColumnContactInfoEditRender = <T extends any>(
  isDisabled?: boolean,
  hasChanged?: boolean,
) => {
  return (contactInfoEditColumnConfig: IContactInfoEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        contactInputColumnConfig,
        editButtonWidth,
        equalityChecker,
        faxInputPhoneNumberColumnConfig,
        getId,
        getInitialData,
        getInitialEditKey,
        getIsValid,
        getTableTitle,
        showErrorMessageWhenInvalid,
        invalidMessagePlacement,
        onRemove,
        phoneInputPhoneNumberColumnConfig,
      } = contactInfoEditColumnConfig;

      const initialData: IContactInfo[] | null | undefined = useMemo(
        () =>
          getInitialData === undefined ? undefined : getInitialData(record),
        [getInitialData, record],
      );

      const data: IContactInfo[] = useMemo(
        () => (isEmptyValue(value) ? [] : (value as IContactInfo[])),
        [value],
      );

      const isValid: boolean = useMemo(
        () => getIsValid(record),
        [getIsValid, record],
      );

      const tableTitle: string | undefined = useMemo(
        () => getTableTitle?.(record),
        [getTableTitle, record],
      );

      return (
        <AntDesignPopover
          content={<ErrorMessage>Invalid contact</ErrorMessage>}
          placement={invalidMessagePlacement}
          visible={showErrorMessageWhenInvalid && !isValid}
        >
          <ContactInfoEdit
            contactInputColumnConfig={contactInputColumnConfig}
            data={data}
            editButtonWidth={editButtonWidth}
            equalityChecker={equalityChecker}
            faxInputPhoneNumberColumnConfig={faxInputPhoneNumberColumnConfig}
            hasChanged={hasChanged}
            highlightButton={!isValid}
            id={getId(record)}
            initialData={initialData}
            initialEditKey={getInitialEditKey(record)}
            isDisabled={isDisabled}
            onRemove={onRemove}
            phoneInputPhoneNumberColumnConfig={
              phoneInputPhoneNumberColumnConfig
            }
            tableTitle={tableTitle}
          />
        </AntDesignPopover>
      );
    };
};

export const getColumnContractsEditRender = <T extends any>(
  isDisabled: boolean,
  hasChanged?: boolean,
) => {
  return (contractsEditColumnConfig: IContractsEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        contractInputColumnConfig,
        editButtonWidth,
        equalityChecker,
        getId,
        getInitialData,
        getInitialEditKey,
        getTableTitle,
        onAdd,
        onRemove,
      } = contractsEditColumnConfig;

      const initialData: IContract[] | null | undefined = useMemo(
        () =>
          getInitialData === undefined ? undefined : getInitialData(record),
        [getInitialData, record],
      );

      const data: IContract[] = useMemo(
        () => (isEmptyValue(value) ? [] : (value as IContract[])),
        [value],
      );

      const tableTitle: string | undefined = useMemo(
        () => getTableTitle?.(record),
        [getTableTitle, record],
      );

      return (
        <ContractsEdit
          contractInputColumnConfig={contractInputColumnConfig}
          data={data}
          editButtonWidth={editButtonWidth}
          equalityChecker={equalityChecker}
          hasChanged={hasChanged}
          id={getId(record)}
          initialData={initialData}
          initialEditKey={getInitialEditKey(record)}
          isDisabled={isDisabled}
          onAdd={onAdd}
          onRemove={onRemove}
          tableTitle={tableTitle}
        />
      );
    };
};

export const getLossesPercentageRender = <T extends any>() => {
  return (inputColumnConfig: ILosesInfoEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const valueAsString: string | undefined =
        value === null ? undefined : (value as string);

      const [currentValue, setCurrentValue] = useState<string | undefined>('');
      const [tempValue, setTempValue] = useState<string | undefined>(
        valueAsString,
      );
      const { shouldFocusOn, getInitialValue, onChange, ...inputConfig } =
        inputColumnConfig;
      const inputRef =
        useRef<AntDesignInput>() as MutableRefObject<AntDesignInput>;
      useEffect(() => {
        if (inputRef && shouldFocusOn && shouldFocusOn(record)) {
          inputRef.current.focus();
        }
      }, [inputRef, record, shouldFocusOn]);

      const initialValue: string | null | undefined = useMemo(
        () =>
          getInitialValue === undefined ? undefined : getInitialValue(record),
        [getInitialValue, record],
      );

      useEffect(
        () => {
          // Wait 1000ms before copying the value of tempValue into value;
          const timeout = setTimeout(() => {
            setCurrentValue(tempValue);
          }, 500);

          // If the hook is called again, cancel the previous timeout
          // This creates a debounce instead of a delay
          return () => clearTimeout(timeout);
        },
        // Run the hook every time the user makes a keystroke
        [tempValue],
      );

      useEffect(() => {
        if (currentValue) {
          onChange(currentValue, record);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentValue]);

      const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const regex = /([0-9]*[.|,]{0,1}[0-9]{0,2})/s;
        let v: string;
        // @ts-ignore
        v = value.match(regex)[0];
        setTempValue(v);
      }, []);

      return (
        <Input
          {...inputConfig}
          inputRef={inputRef}
          initialValue={initialValue}
          value={tempValue}
          onChange={handleChange}
        />
      );
    };
};

export const getColumnMiscInfoEditRender = <T extends any>(
  isDisabled?: boolean,
  hasChanged?: boolean,
) => {
  return (miscInfoEditColumnConfig: IMiscInfoEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const {
        editButtonWidth,
        equalityChecker,
        getId,
        getInitialData,
        getInitialEditKey,
        getTableTitle,
        onAdd,
        onRemove,
        tokenInputColumnConfig,
        valueInputColumnConfig,
      } = miscInfoEditColumnConfig;

      const initialData: IMiscInfo[] | null | undefined = useMemo(
        () =>
          getInitialData === undefined ? undefined : getInitialData(record),
        [getInitialData, record],
      );

      const data: IMiscInfo[] = useMemo(
        () => (isEmptyValue(value) ? [] : (value as IMiscInfo[])),
        [value],
      );

      const tableTitle: string | undefined = useMemo(
        () => getTableTitle?.(record),
        [getTableTitle, record],
      );

      return (
        <MiscInfoEdit
          data={data}
          editButtonWidth={editButtonWidth}
          equalityChecker={equalityChecker}
          hasChanged={hasChanged}
          id={getId(record)}
          initialData={initialData}
          initialEditKey={getInitialEditKey(record)}
          isDisabled={isDisabled}
          onAdd={onAdd}
          onRemove={onRemove}
          tableTitle={tableTitle}
          tokenInputColumnConfig={tokenInputColumnConfig}
          valueInputColumnConfig={valueInputColumnConfig}
        />
      );
    };
};

export const getColumnEntityInfoRender = (isUnconstrained: boolean) =>
  getColumnRender(isUnconstrained, entityInfoToString);

export const getColumnPointInfoRender = (isUnconstrained: boolean) =>
  getColumnRender(isUnconstrained, pointInfoToString);

export const midSelectRender = <T extends any>() =>
  getColumnSelectRender<number | null, T>(
    '100%',
    undefined,
    undefined,
    isNumberSelectEmpty,
  );

export const getPidSelectRender = <T extends any>() =>
  getColumnSelectRender<number, T>('100%');

export const getPidSelectNoHighlightRender = <T extends any>() =>
  getColumnSelectRender<number, T>('100%');

export const contactInputRender = getColumnInputRender<IContactInfo>(
  '100%',
  18,
);

export const faxInputPhoneNumberRender =
  getColumnInputPhoneNumberRender<IContactInfo>('100%');

export const phoneInputPhoneNumberRender =
  getColumnInputPhoneNumberRender<IContactInfo>('100%');

export const contractInputRender = getColumnInputRender<IContract>(
  `calc(100% + ${STANDARD_SPACING} - ${ICON_BUTTON_SIZE})`,
  18,
);

export const tokenInputRender = getColumnInputRender<IMiscInfo>('100%', 20);

export const valueInputRender = getColumnInputRender<IMiscInfo>(
  `calc(100% + ${STANDARD_SPACING} - ${ICON_BUTTON_SIZE})`,
  18,
);

export const getDateTimeColumnRender = (
  timeZone: TTimeZone,
  isUnconstrained: boolean,
) =>
  getColumnRender(isUnconstrained, (value: unknown): string =>
    toFormattedDateTimeString(value as string, timeZone),
  );

export const getMiscInfoColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IMiscInfo>[] => [
  {
    dataIndex: 'token',
    render: getColumnRender(isUnconstrained),
    title: 'Token',
  },
  {
    dataIndex: 'value',
    render: getColumnRender(isUnconstrained),
    title: 'Value',
  },
];

export const getContactInfoColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IContactInfo>[] => [
  {
    dataIndex: 'contact',
    render: getColumnRender(isUnconstrained),
    title: 'Contact',
  },
  {
    dataIndex: 'phone',
    render: getColumnRender(isUnconstrained),
    title: 'Phone',
  },
  {
    dataIndex: 'fax',
    render: getColumnRender(isUnconstrained),
    title: 'Fax',
  },
];

export const getETagTagIdColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IETagTagId>[] => [
  {
    dataIndex: 'gca',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'GCA',
    width: '25%',
  },
  {
    dataIndex: 'pse',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'CPSE',
    width: '25%',
  },
  {
    dataIndex: 'tag_code',
    render: getColumnRender(isUnconstrained),
    title: 'Tag Code',
    width: '25%',
  },
  {
    dataIndex: 'lca',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'LCA',
    width: '25%',
  },
];

export const getColumnContactInfoRender =
  <T extends IDetailContactInfos | IETagContactInfo>(
    isUnconstrained: boolean,
    getShowDataCount?: (record: T) => boolean,
    getTitle?: (record: T) => string,
  ) =>
  (value: unknown, record: T): JSX.Element => {
    const contactInfos: IContactInfo[] = useMemo(
      () =>
        isEmptyValue(value)
          ? []
          : Array.isArray(value)
          ? (value as IContactInfo[])
          : [value as IContactInfo],
      [value],
    );

    const adjustedGetShowDataCount = useCallback((): boolean => {
      return getShowDataCount === undefined || getShowDataCount(record);
    }, [record]);

    return isUnconstrained ? (
      contactInfos.length > 0 ? (
        <ContactInfoDataTable
          data={contactInfos}
          isUnconstrained={isUnconstrained}
        />
      ) : (
        <></>
      )
    ) : (
      <PopoverDataTable<IViewDataTableColumn<IContactInfo>, IContactInfo>
        columns={getContactInfoColumns(isUnconstrained)}
        data={contactInfos}
        getShowDataCount={adjustedGetShowDataCount}
        pagination={false}
        title={getTitle?.(record)}
      />
    );
  };

export const getColumnMiscInfosRender =
  <T extends IETagMiscInfos>(
    isUnconstrained: boolean,
    getTitle?: (record: T) => string,
    handleExpand?: (record: T) => void,
  ) =>
  (value: unknown, record: T): JSX.Element => {
    const miscInfos: IMiscInfo[] = useMemo(
      () => (isEmptyValue(value) ? [] : (value as IMiscInfo[])),
      [value],
    );

    const handleClick = useCallback(() => {
      if (handleExpand !== undefined) {
        handleExpand(record);
      }
    }, [record]);

    return isUnconstrained ? (
      handleExpand === undefined ? (
        <MiscInfoDataTable data={miscInfos} isUnconstrained={isUnconstrained} />
      ) : (
        <ExpandContainer>
          <IconButton
            icon={
              <>{record.misc_infos === null ? 0 : record.misc_infos.length}</>
            }
            onClick={handleClick}
          />
        </ExpandContainer>
      )
    ) : (
      <PopoverDataTable<IViewDataTableColumn<IMiscInfo>, IMiscInfo>
        columns={getMiscInfoColumns(isUnconstrained)}
        data={isEmptyValue(value) ? [] : (value as IMiscInfo[])}
        maximumHeight={DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT}
        pagination={false}
        placement='bottomRight'
        title={getTitle?.(record)}
      />
    );
  };

export const getColumnNotesRender =
  (isUnconstrained: boolean) =>
  (value: unknown): JSX.Element =>
    isEmptyValue(value) ? (
      <></>
    ) : isUnconstrained ? (
      <>{value as string}</>
    ) : (
      <StyledTooltip title={value as string}>
        <NotesIcon />
      </StyledTooltip>
    );

export const getPhysicalPathContactInfoTitle = (
  record: IDetailPhysicalSegment,
): string => `Physical Path ${record.physical_segment_id} Contact Info`;

export const getColumnOasisInfoIdColumnConfig = (isUnconstrained: boolean) => ({
  dataIndex: 'trans_alloc_id',
  render: getColumnRender(isUnconstrained),
  title: 'ID',
  width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
});

const getColumnOasisInfoMiscInfosRender =
  (isUnconstrained: boolean) =>
  (value: unknown, record: IETagTransmissionAllocation): JSX.Element =>
    (
      <PopoverDataTable<IViewDataTableColumn<IMiscInfo>, IMiscInfo>
        columns={getMiscInfoColumns(isUnconstrained)}
        data={isEmptyValue(value) ? [] : (value as IMiscInfo[])}
        pagination={false}
        title={`Physical Path ${record.physical_segment_ref} Misc Info`}
      />
    );

const oasisInfoPidSelectRender =
  getPidSelectRender<IETagTransmissionAllocation>();

const oasisInfoPidSelectNoHighlightRender =
  getPidSelectNoHighlightRender<IETagTransmissionAllocation>();

const contractNumberInputRender =
  getColumnInputRender<IETagTransmissionAllocation>('100%', 36);

const productSelectRender = getColumnSelectRender<
  IEnergyProductInfo | null,
  IETagTransmissionAllocation
>(
  '100%',
  undefined,
  (
    value:
      | (IEnergyProductInfo | null)[]
      | (IEnergyProductInfo | null)
      | undefined,
  ): string =>
    isEmptyValue(value) ? '' : (value as IEnergyProductInfo).product_name,
);

const pseSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IETagTransmissionAllocation
>(
  '100%',
  undefined,
  (value: (IEntityInfo | null)[] | (IEntityInfo | null) | undefined): string =>
    isEmptyValue(value) ? '' : (value as IEntityInfo).entity_code,
);

const nitsResourceInputRender =
  getColumnInputRender<IETagTransmissionAllocation>('100%');

const miscInfoIconButtonRender =
  getColumnIconButton<IETagTransmissionAllocation>('100%');

const shortMiscInfoIconButtonRender =
  getColumnIconButton<IETagTransmissionAllocation>(
    `${
      VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE - ICON_BUTTON_SIZE_VALUE
    }px`,
  );

const shouldAllowEdit = (
  viewMode: EViewMode,
  record: IETagTransmissionAllocation,
  initialTransAllocIds: number[],
): boolean =>
  (viewMode !== EViewMode.EditETagAdjustment &&
    viewMode !== EViewMode.EditETagAdjustmentWithATF) ||
  ((viewMode === EViewMode.EditETagAdjustment ||
    viewMode === EViewMode.EditETagAdjustmentWithATF) &&
    !initialTransAllocIds.includes(record.trans_alloc_id));

const getOasisInfoPidRender =
  (
    selectColumnConfig: ISelectColumnConfig<
      number,
      IETagTransmissionAllocation
    >,
    viewMode: EViewMode,
    initialTransAllocIds: number[],
    isUnconstrained: boolean,
  ) =>
  (
    value: unknown,
    record: IETagTransmissionAllocation,
    index: number,
  ): JSX.Element =>
    viewMode === EViewMode.EditETagCorrection ||
    !shouldAllowEdit(viewMode, record, initialTransAllocIds)
      ? getColumnRender(isUnconstrained)(value)
      : record.trans_alloc_id === INITIAL_RECORD_ID
      ? oasisInfoPidSelectNoHighlightRender(selectColumnConfig)(
          value,
          record,
          index,
        )
      : oasisInfoPidSelectRender(selectColumnConfig)(value, record, index);

const getOasisInfoContractNumberRender =
  (
    inputColumnConfig: IInputColumnConfig<IETagTransmissionAllocation>,
    viewMode: EViewMode,
    initialTransAllocIds: number[],
    isUnconstrained: boolean,
  ) =>
  (value: unknown, record: IETagTransmissionAllocation): JSX.Element =>
    shouldAllowEdit(viewMode, record, initialTransAllocIds)
      ? contractNumberInputRender(inputColumnConfig)(value, record)
      : getColumnRender(
          isUnconstrained,
          undefined,
          VIEW_DATA_TABLE_COLUMN_OASIS_INPUT_COLUMN_WIDTH,
        )(value);

const oasisInfoTransProductRefRender =
  (
    selectColumnConfig: ISelectColumnConfig<
      IEnergyProductInfo | null,
      IETagTransmissionAllocation
    >,
    viewMode: EViewMode,
    initialTransAllocIds: number[],
  ) =>
  (
    value: unknown,
    record: IETagTransmissionAllocation,
    index: number,
  ): JSX.Element =>
    shouldAllowEdit(viewMode, record, initialTransAllocIds)
      ? productSelectRender(selectColumnConfig)(value, record, index)
      : getColumnEnergyProductRender()(value);

const getOasisInfoTransAllocCustomerCodeRender =
  (
    selectColumnConfig: ISelectColumnConfig<
      IEntityInfo | null,
      IETagTransmissionAllocation
    >,
    viewMode: EViewMode,
    initialTransAllocIds: number[],
    isUnconstrained: boolean,
  ) =>
  (
    value: unknown,
    record: IETagTransmissionAllocation,
    index: number,
  ): JSX.Element =>
    shouldAllowEdit(viewMode, record, initialTransAllocIds)
      ? pseSelectRender(selectColumnConfig)(value, record, index)
      : getColumnEntityInfoRender(isUnconstrained)(value);

const getOasisInfoNitsResourceRender =
  (
    inputColumnConfig: IInputColumnConfig<IETagTransmissionAllocation>,
    viewMode: EViewMode,
    initialTransAllocIds: number[],
    isUnconstrained: boolean,
  ) =>
  (value: unknown, record: IETagTransmissionAllocation): JSX.Element =>
    shouldAllowEdit(viewMode, record, initialTransAllocIds)
      ? nitsResourceInputRender(inputColumnConfig)(value, record)
      : getColumnRender(isUnconstrained)(value);

const oasisInfoMiscInfosRender =
  (
    iconButtonColumnConfig: IIconButtonColumnConfig<IETagTransmissionAllocation>,
    viewMode: EViewMode,
    initialTransAllocIds: number[],
    isUnconstrained: boolean,
  ) =>
  (value: unknown, record: IETagTransmissionAllocation): JSX.Element =>
    shouldAllowEdit(viewMode, record, initialTransAllocIds)
      ? (viewMode === EViewMode.EditETagCorrection
          ? miscInfoIconButtonRender(iconButtonColumnConfig)
          : shortMiscInfoIconButtonRender(iconButtonColumnConfig))(
          value,
          record,
        )
      : getColumnOasisInfoMiscInfosRender(isUnconstrained)(value, record);

export const useOasisInfoEditColumns = (
  getInitialTransmissionAllocationForId: (
    trans_alloc_id: number,
  ) => IETagTransmissionAllocation | undefined,
  initialTransmissionAllocations: IETagTransmissionAllocation[] | null,
  initialTransAllocIds: number[],
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isUnconstrained: boolean,
  onChange: (editOasisInfo: IEditOasisInfo) => void,
  onClick: (
    miscInfosExpandedColumn: EMiscInfosExpandedColumn,
    record: IETagTransmissionAllocation,
  ) => void,
  pidOptions: IOption<number>[],
  previousIsDetailDeleted: boolean | undefined,
  previousIsDetailUpdating: boolean | undefined,
  pseOptions: IOption<IEntityInfo>[],
  transmissionAllocations: IETagTransmissionAllocation[],
  viewMode: EViewMode,
) => {
  const previousPidOptions: IOption<number>[] | undefined =
    usePrevious(pidOptions);
  const previousPseOptions: IOption<IEntityInfo>[] | undefined =
    usePrevious(pseOptions);
  const previousTransmissionAllocations:
    | IETagTransmissionAllocation[]
    | undefined = usePrevious(transmissionAllocations);

  const getPidOptions = useCallback(
    async (_: IETagTransmissionAllocation, index: number) => {
      if (
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF
      ) {
        return pidOptions;
      }

      let highestPIDBefore: number = Number.MIN_SAFE_INTEGER;
      let lowestPIDAfter: number = Number.MAX_SAFE_INTEGER;

      for (let i: number = 0; i < index; i += 1) {
        const eTagTransmissionAllocation: IETagTransmissionAllocation =
          transmissionAllocations[i];

        if (
          eTagTransmissionAllocation.physical_segment_ref !== null &&
          eTagTransmissionAllocation.physical_segment_ref > highestPIDBefore
        ) {
          highestPIDBefore = eTagTransmissionAllocation.physical_segment_ref;
        }
      }

      for (
        let i: number = index + 1;
        i < transmissionAllocations.length;
        i += 1
      ) {
        const eTagTransmissionAllocation: IETagTransmissionAllocation =
          transmissionAllocations[i];

        if (
          eTagTransmissionAllocation.physical_segment_ref !== null &&
          eTagTransmissionAllocation.physical_segment_ref < lowestPIDAfter
        ) {
          lowestPIDAfter = eTagTransmissionAllocation.physical_segment_ref;
        }
      }

      return pidOptions.filter(
        (pidOption: IOption<number>): boolean =>
          pidOption.value >= highestPIDBefore &&
          pidOption.value <= lowestPIDAfter,
      );
    },
    [pidOptions, transmissionAllocations, viewMode],
  );

  const getInitialOasisInfoPidSelectValue = useCallback(
    (record: IETagTransmissionAllocation): number | null => {
      if (initialTransmissionAllocations === null) {
        return null;
      }

      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = initialTransmissionAllocations.find(
        (transmissionAllocation: IETagTransmissionAllocation): boolean =>
          transmissionAllocation.trans_alloc_id === record.trans_alloc_id,
      );

      return initialTransmissionAllocation === undefined
        ? null
        : initialTransmissionAllocation.physical_segment_ref;
    },
    [initialTransmissionAllocations],
  );

  const pidSelectOnChange = useCallback(
    (value: number | undefined, record: IETagTransmissionAllocation) => {
      onChange({
        editTransmissionAllocationMap: {
          [record.trans_alloc_id]: {
            physical_segment_ref: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialContractNumberInputValue = useCallback(
    (record: IETagTransmissionAllocation): string | null => {
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(
        record.trans_alloc_id,
      );

      return initialTransmissionAllocation === undefined
        ? null
        : initialTransmissionAllocation.contract_number;
    },
    [getInitialTransmissionAllocationForId],
  );

  const contractNumberInputOnChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      record: IETagTransmissionAllocation,
    ) => {
      onChange({
        editTransmissionAllocationMap: {
          [record.trans_alloc_id]: {
            contract_number: eventToStringOrNull(event),
          },
        },
      });
    },
    [onChange],
  );

  const contractNumberInputOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === EDIT_KEY_SEPARATOR ||
        event.key === TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY
      ) {
        event.preventDefault();
      }
    },
    [],
  );

  const getProductOptions = useCallback(
    async () => TRANSMISSION_ENERGY_PRODUCT_OPTIONS,
    [],
  );

  const getInitialProductSelectValue = useCallback(
    (record: IETagTransmissionAllocation): IEnergyProductInfo | null => {
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(
        record.trans_alloc_id,
      );

      return initialTransmissionAllocation === undefined
        ? null
        : initialTransmissionAllocation.trans_product_ref;
    },
    [getInitialTransmissionAllocationForId],
  );

  const productSelectOnChange = useCallback(
    (
      value: IEnergyProductInfo | null | undefined,
      record: IETagTransmissionAllocation,
    ) => {
      onChange({
        editTransmissionAllocationMap: {
          [record.trans_alloc_id]: {
            trans_product_ref: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialNitsResourceInputValue = useCallback(
    (record: IETagTransmissionAllocation): string | null => {
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(
        record.trans_alloc_id,
      );

      return initialTransmissionAllocation === undefined
        ? null
        : initialTransmissionAllocation.nits_resource;
    },
    [getInitialTransmissionAllocationForId],
  );

  const nitsResourceInputOnChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      record: IETagTransmissionAllocation,
    ) => {
      onChange({
        editTransmissionAllocationMap: {
          [record.trans_alloc_id]: {
            nits_resource: eventToStringOrNull(event),
          },
        },
      });
    },
    [onChange],
  );

  const getPseOptions = useCallback(async () => pseOptions, [pseOptions]);

  const getInitialPseSelectValue = useCallback(
    (record: IETagTransmissionAllocation): IEntityInfo | null => {
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(
        record.trans_alloc_id,
      );

      return initialTransmissionAllocation === undefined
        ? null
        : initialTransmissionAllocation.trans_alloc_customer_code;
    },
    [getInitialTransmissionAllocationForId],
  );

  const pseSelectOnChange = useCallback(
    (
      value: IEntityInfo | null | undefined,
      record: IETagTransmissionAllocation,
    ) => {
      onChange({
        editTransmissionAllocationMap: {
          [record.trans_alloc_id]: {
            trans_alloc_customer_code: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const getMiscInfoIcon = useCallback(
    (
      value: number | string,
      record: IETagTransmissionAllocation,
    ): ReactNode => {
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(
        record.trans_alloc_id,
      );
      const valueChanged: boolean | undefined =
        initialTransmissionAllocation === undefined
          ? undefined
          : (initialTransmissionAllocation.misc_infos !== null &&
              record.misc_infos === null) ||
            (initialTransmissionAllocation.misc_infos === null &&
              record.misc_infos !== null &&
              record.misc_infos.length > 0) ||
            (initialTransmissionAllocation.misc_infos !== null &&
              record.misc_infos !== null &&
              initialTransmissionAllocation.misc_infos.length !==
                record.misc_infos.length);

      return (
        <DataIndicator
          value={record.misc_infos === null ? 0 : record.misc_infos.length}
          valueChanged={valueChanged}
        />
      );
    },
    [getInitialTransmissionAllocationForId],
  );

  const miscInfoIconButtonOnClick = useCallback(
    (record: IETagTransmissionAllocation) => {
      onClick(EMiscInfosExpandedColumn.MiscInfos, record);
    },
    [onClick],
  );

  return useMemo(() => {
    const isDetailDeletedChanged: boolean =
      isDetailDeleted !== previousIsDetailDeleted;
    const isDetailUpdatingChanged: boolean =
      isDetailUpdating !== previousIsDetailUpdating;
    const pidOptionsChanged: boolean = pidOptions !== previousPidOptions;
    const pseOptionsChanged: boolean = pseOptions !== previousPseOptions;
    const transmissionAllocationsChanged: boolean =
      transmissionAllocations !== previousTransmissionAllocations;

    return [
      {
        dataIndex: 'physical_segment_ref',
        render: getOasisInfoPidRender(
          {
            allowClear: true,
            equalityChecker: simpleEqualityChecker,
            getInitialValue: getInitialOasisInfoPidSelectValue,
            getOptions: getPidOptions,
            isDisabled: isDetailDeleted || isDetailUpdating,
            onChange: pidSelectOnChange,
            valueToUid: (value: number | null | undefined): string =>
              isEmptyValue(value) ? '' : value!.toString(),
          },
          viewMode,
          initialTransAllocIds,
          isUnconstrained,
        ),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IETagTransmissionAllocation,
                previousRecord: IETagTransmissionAllocation,
              ): boolean =>
                isDetailDeletedChanged ||
                isDetailUpdatingChanged ||
                pidOptionsChanged ||
                record.physical_segment_ref !==
                  previousRecord.physical_segment_ref ||
                transmissionAllocationsChanged,
        title: 'PID',
        width: VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contract_number',
        render: getOasisInfoContractNumberRender(
          {
            getInitialValue: getInitialContractNumberInputValue,
            getKey: (record: IETagTransmissionAllocation): string =>
              record.trans_alloc_id.toString(),
            isDisabled: isDetailDeleted || isDetailUpdating,
            onChange: contractNumberInputOnChange,
            onKeyDown: contractNumberInputOnKeyDown,
          },
          viewMode,
          initialTransAllocIds,
          isUnconstrained,
        ),
        shouldCellUpdate: (
          record: IETagTransmissionAllocation,
          previousRecord: IETagTransmissionAllocation,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.contract_number !== previousRecord.contract_number,
        title: 'OASIS',
        width: VIEW_DATA_TABLE_COLUMN_OASIS_INPUT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'trans_product_ref',
        render: oasisInfoTransProductRefRender(
          {
            allowClear: true,
            equalityChecker: energyProductInfoEqual,
            filter: selectOptionLabelFilter,
            getInitialValue: getInitialProductSelectValue,
            getOptions: getProductOptions,
            isDisabled: isDetailDeleted || isDetailUpdating,
            onChange: productSelectOnChange,
            showSearch: true,
            valueToUid: energyProductInfoToUid,
          },
          viewMode,
          initialTransAllocIds,
        ),
        shouldCellUpdate: (
          record: IETagTransmissionAllocation,
          previousRecord: IETagTransmissionAllocation,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          (record.trans_product_ref === null
            ? previousRecord.trans_product_ref !== null
            : previousRecord.trans_product_ref === null
            ? true
            : record.trans_product_ref.product_ref !==
              previousRecord.trans_product_ref.product_ref),
        title: 'Product',
        width: VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'trans_alloc_customer_code',
        render: getOasisInfoTransAllocCustomerCodeRender(
          {
            allowClear: true,
            equalityChecker: pseEntityInfoEqual,
            filter: selectOptionLabelFilter,
            getInitialValue: getInitialPseSelectValue,
            getOptions: getPseOptions,
            isDisabled: isDetailDeleted || isDetailUpdating,
            onChange: pseSelectOnChange,
            showSearch: true,
            valueToUid: entityInfoToUid,
          },
          viewMode,
          initialTransAllocIds,
          isUnconstrained,
        ),
        shouldCellUpdate: (
          record: IETagTransmissionAllocation,
          previousRecord: IETagTransmissionAllocation,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          pseOptionsChanged ||
          (record.trans_alloc_customer_code === null
            ? previousRecord.trans_alloc_customer_code !== null
            : previousRecord.trans_alloc_customer_code === null
            ? true
            : !pseEntityInfoEqual(
                record.trans_alloc_customer_code,
                previousRecord.trans_alloc_customer_code,
              )),
        title: 'Transmission Customer',
        width: VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'nits_resource',
        render: getOasisInfoNitsResourceRender(
          {
            getInitialValue: getInitialNitsResourceInputValue,
            getKey: (record: IETagTransmissionAllocation): string =>
              record.trans_alloc_id.toString(),
            isDisabled: isDetailDeleted || isDetailUpdating,
            onChange: nitsResourceInputOnChange,
          },
          viewMode,
          initialTransAllocIds,
          isUnconstrained,
        ),
        shouldCellUpdate: (
          record: IETagTransmissionAllocation,
          previousRecord: IETagTransmissionAllocation,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.nits_resource !== previousRecord.nits_resource,
        title: 'NITS Resource',
        width: VIEW_DATA_TABLE_COLUMN_NITS_RESOURCE_INPUT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'misc_infos',
        render: oasisInfoMiscInfosRender(
          {
            getIcon: getMiscInfoIcon,
            onClick: miscInfoIconButtonOnClick,
          },
          viewMode,
          initialTransAllocIds,
          isUnconstrained,
        ),
        shouldCellUpdate: (
          record: IETagTransmissionAllocation,
          previousRecord: IETagTransmissionAllocation,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          (record.misc_infos === null && previousRecord.misc_infos !== null) ||
          (record.misc_infos !== null && previousRecord.misc_infos === null) ||
          (record.misc_infos !== null &&
            previousRecord.misc_infos !== null &&
            record.misc_infos !== previousRecord.misc_infos),
        title: 'Misc Info',
        width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
      },
    ];
  }, [
    contractNumberInputOnChange,
    contractNumberInputOnKeyDown,
    getInitialContractNumberInputValue,
    getInitialNitsResourceInputValue,
    getInitialOasisInfoPidSelectValue,
    getInitialProductSelectValue,
    getInitialPseSelectValue,
    getMiscInfoIcon,
    getPidOptions,
    getProductOptions,
    getPseOptions,
    initialTransAllocIds,
    isDetailDeleted,
    isDetailUpdating,
    isUnconstrained,
    miscInfoIconButtonOnClick,
    nitsResourceInputOnChange,
    pidOptions,
    pidSelectOnChange,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    previousPidOptions,
    previousPseOptions,
    previousTransmissionAllocations,
    productSelectOnChange,
    pseOptions,
    pseSelectOnChange,
    transmissionAllocations,
    viewMode,
  ]);
};

export const getColumnOasisInfoEditRender = <T extends any>(
  isDisabled?: boolean,
) => {
  return (oasisInfoEditColumnConfig: IOasisInfoEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const { getInitialData } = oasisInfoEditColumnConfig;

      const initialData: number | null | undefined = useMemo(
        () =>
          getInitialData === undefined ? undefined : getInitialData(record),
        [getInitialData, record],
      );

      return (
        <OasisInfoEdit
          isDisabled={isDisabled}
          physicalSegmentId={initialData ? initialData : undefined}
        />
      );
    };
};

export const getColumnOasisInfoReviewRender = <T extends any>() => {
  return (oasisInfoEditColumnConfig: IOasisInfoEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const { getInitialData } = oasisInfoEditColumnConfig;

      const initialData: number | null | undefined = useMemo(
        () =>
          getInitialData === undefined ? undefined : getInitialData(record),
        [getInitialData, record],
      );

      return (
        <OasisInfoReview
          allowPopover={true}
          physicalSegmentId={initialData ? initialData : undefined}
        />
      );
    };
};

export const getColumnLossesEditRender = <T extends any>(
  isDisabled?: boolean,
) => {
  return (oasisInfoEditColumnConfig: IOasisInfoEditColumnConfig<T>) =>
    (value: unknown, record: T): JSX.Element => {
      const { getInitialData } = oasisInfoEditColumnConfig;

      const initialData: any | null | undefined = useMemo(
        () =>
          getInitialData === undefined ? undefined : getInitialData(record),
        [getInitialData, record],
      );

      const lossMethods =
        initialData.lossMethods && Array.isArray(initialData.lossMethods)
          ? initialData.lossMethods.join(', ')
          : initialData.lossMethods;

      return (
        <LossMethodsEdit
          isDisabled={isDisabled}
          physicalSegmentId={
            initialData ? initialData.physicalSegmentId : undefined
          }
          lossMethods={initialData ? lossMethods : undefined}
        />
      );
    };
};

export const dateTimePickerRenderForLosses =
  getColumnDateTimePickerRender<IDetailLossAccounting>('100%');

export const lossMethodsPidNoHighlightSelectRender =
  getPidSelectNoHighlightRender<IDetailLossAccounting>();
export const lossMethodsPidSelectRender =
  getPidSelectRender<IDetailLossAccounting>();

export const getLossMethodEntryTypeSelectRender = getColumnSelectRender<
  ELossMethodEntryType | null,
  IDetailLossAccounting
>('100%');

export const getLossMethodTagIdsIconButtonRender =
  getColumnIconButton<IDetailLossAccounting>('100%');

export const getLossMethodContractsIconButtonRender =
  getColumnIconButton<IDetailLossAccounting>(
    `${
      VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH_VALUE -
      ICON_BUTTON_SIZE_VALUE
    }px`,
  );

export const gcaSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IETagTagId
>('100%');
export const pseSelectRenderForEtagID = getColumnSelectRender<
  IEntityInfo | null,
  IETagTagId
>('100%');
export const tagCodeInputRender = getColumnInputRender<IETagTagId>('100%');
export const lcaSelectRenderWithRemoveHandler = getColumnSelectRender<
  IEntityInfo | null,
  IETagTagId
>(`calc(100% + ${STANDARD_SPACING} - ${ICON_BUTTON_SIZE})`);
export const lcaSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IETagTagId
>('100%');
