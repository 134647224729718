import ContractsEditable, {
  IContractsEditableProps,
} from 'components/molecules/ContractsEditable/ContractsEditable';
import { IContract } from 'interfaces/General';
import { useMemo } from 'react';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import styled from 'styled-components';

const ContractsContainer = styled.div`
  width: 200px;
`;

interface IExpandedContractsEditableProps
  extends Omit<IContractsEditableProps, 'data'> {
  lossAccounting: IDetailLossAccounting;
}

const ExpandedContractsEditable = (
  props: IExpandedContractsEditableProps,
): JSX.Element => {
  const {
    contractInputColumnConfig,
    hasChanged,
    initialEditKey,
    isDisabled,
    lossAccounting,
    onAdd,
    onRemove,
  } = props;

  const data: IContract[] = useMemo(
    () =>
      lossAccounting.lossMethod === null ||
      lossAccounting.lossMethod.contractNumbers === null
        ? []
        : lossAccounting.lossMethod.contractNumbers,
    [lossAccounting],
  );

  return (
    <ContractsContainer>
      <ContractsEditable
        data={data}
        contractInputColumnConfig={contractInputColumnConfig}
        hasChanged={hasChanged}
        initialEditKey={initialEditKey}
        isDisabled={isDisabled}
        onAdd={onAdd}
        onRemove={onRemove}
      />
    </ContractsContainer>
  );
};

export default ExpandedContractsEditable;
