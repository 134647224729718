import { IToEntitiesPermissionId } from 'components/pages/LandingPage/types';
import { ETheme } from 'enums/Style';
import { permissionsForIdAndToEntity } from 'hooks/usePermissions';
import { IThemedProps } from 'interfaces/Component';
import { IPermissions } from 'interfaces/Permissions';
import { IToEntity } from 'interfaces/ToEntity';

export const bgColorForTheme = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Light
    ? 'rgba(240, 240, 240, 0.7)'
    : 'rgba(10, 10, 10, 0.7)';

export const combinePermissions = (
  permissionId: string,
  toEntities: IToEntity[],
): IPermissions =>
  toEntities.reduce(
    (permissions: IPermissions, toEntity: IToEntity): IPermissions => {
      const { isDisplayable, isExecutable } = permissionsForIdAndToEntity(
        permissionId,
        toEntity,
      );

      if (isDisplayable || isExecutable) {
        return {
          isDisplayable: isDisplayable || permissions.isDisplayable,
          isExecutable: isExecutable || permissions.isExecutable,
        };
      } else {
        return permissions;
      }
    },
    { isDisplayable: false, isExecutable: false },
  );

export const checkPermissionsAndThen = <T extends IToEntitiesPermissionId>(
  component: (props: T) => JSX.Element,
): ((props: T) => JSX.Element) => {
  return (props: T): JSX.Element => {
    const { children, permissionId, toEntities } = props;
    const { isDisplayable, isExecutable } = combinePermissions(
      permissionId,
      toEntities,
    );

    if (isDisplayable) {
      if (isExecutable) {
        return <>{component(props)}</>;
      } else {
        return <>{children}</>;
      }
    } else {
      return <></>;
    }
  };
};
