export enum EApprovalStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Study = 'STUDY',
  Nothing = 'NOTHING', // this is just a placeholder because the backend cannot support empty. Not a real request type
}

export enum EApprovalReasons {
  Approve = 'approve_reasons',
  Denied = 'denied_reasons',
  Study = 'study_reasons',
}
