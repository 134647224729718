import { AxiosResponse } from 'axios';
import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import SuccessMessage from 'components/atoms/SuccessMessage/SuccessMessage';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import { SHOW_TIME_CONFIGURATION } from 'components/molecules/ScheduleSubmission/constants';
import Select from 'components/molecules/Select/Select';
import FrequencyOfDataSelect from 'components/molecules/ToEntityValidationReportCreate/FrequencyOfDataSelect';
import RangeOfDataSelect from 'components/molecules/ToEntityValidationReportCreate/RangeOfDataSelect';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
} from 'constants/styles';
import { ALL_TIME_ZONE_OPTIONS, DATE_TIME_FORMAT } from 'constants/time';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import usePermissions from 'hooks/usePermissions';
import { IOption } from 'interfaces/Component';
import {
  IETagDeleteReportResponse,
  IETagScheduleReportResponse,
  IETagValidationReport,
} from 'interfaces/ETag';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  createScheduledValidationReport,
  deleteScheduledValidationReport,
} from 'services/approval/approval';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
`;

const AlignFormRight = styled.div`
  margin-left: auto;
`;

const ReportNameInputContainer = styled.div`
  margin-left: auto;
  padding-right: 100px;
  width: 300px;
`;

const RulesListInputContainer = styled.div`
  margin-left: auto;
  padding-right: 100px;
  width: 300px;
`;

const TimeZoneSelectContainer = styled.div`
  margin-left: auto;
  padding-right: 100px;
  width: 250px;
`;

const DateSelectContainer = styled.div`
  margin-left: auto;
  padding-right: 100px;
  width: 250px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};
`;

interface IProps {
  encodedPermissionsId: string;
  handleRefresh: () => void;
  report?: IETagValidationReport;
  ruleOptions: IOption<string>[];
  toEntityId: TToEntityId;
}

const ToEntityValidationReportCreate = ({
  encodedPermissionsId,
  handleRefresh,
  report,
  ruleOptions,
  toEntityId,
}: IProps): JSX.Element => {
  const permissions = usePermissions(encodedPermissionsId);

  const [reportName, setReportName] = useState<string | undefined>();
  const [startDateTime, setStartDateTime] = useState<ZonedDateTime | null>(
    null,
  );
  const [timeZone, setSelectedTimeZone] = useState<TTimeZone | undefined>();
  const [selectedRuleNames, setSelectedRuleNames] = useState<string[]>([]);
  const [rangeExpression, setRangeExpression] = useState<string | undefined>();
  const [frequency, setFrequency] = useState<
    | {
        day_of_month?: number;
        day_of_week?: string;
        hours_rate?: number;
        minutes_rate?: number;
      }
    | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>();
  const [successMessage, setSuccessMessage] = useState<
    string | undefined | null
  >();
  const { displayTimedMessage, timedMessage, showTimedMessage } =
    useDisplayTimedMessage();

  useEffect(() => {
    if (successMessage) {
      displayTimedMessage(
        <SuccessMessage>{successMessage}</SuccessMessage>,
        5000,
      );
    }
  }, [displayTimedMessage, successMessage]);

  useEffect(() => {
    if (errorMessage) {
      displayTimedMessage(<ErrorMessage>{errorMessage}</ErrorMessage>, 5000);
    }
  }, [displayTimedMessage, errorMessage]);

  const clearInputs = () => {
    setReportName(undefined);
    setSelectedRuleNames([]);
    setSelectedTimeZone(undefined);
    setStartDateTime(null);
    setRangeExpression(undefined);
    setFrequency(undefined);
  };

  // if we pass a report into this component, it is in 'review' mode
  // so we set values here to display inside of "disabled" components
  useEffect(() => {
    if (report) {
      setReportName(report.report_name);
      setSelectedRuleNames(report.rules_list);
      setSelectedTimeZone(report.timezone);
      setFrequency({ ...report.periodicity });
      setRangeExpression(report.periodicity.range_expression);
      setStartDateTime(
        ZonedDateTime.parseIso(report.start_date, report.timezone),
      );
      return;
    }
    clearInputs();
  }, [report]);

  const handleCreateReport = async () => {
    if (
      !reportName ||
      !startDateTime ||
      !timeZone ||
      !rangeExpression ||
      !frequency
    ) {
      return;
    }
    try {
      const report: IETagValidationReport = {
        periodicity: {
          range_expression: rangeExpression,
          ...frequency,
        },
        report_name: reportName,
        rules_list: selectedRuleNames,
        start_date: startDateTime.isoFormat(),
        timezone: timeZone,
      };

      const createResponse: AxiosResponse<IETagScheduleReportResponse> =
        await createScheduledValidationReport(toEntityId, report);

      if (!isSuccessStatus(createResponse.status)) {
        setErrorMessage(
          'There was an error scheduling your report. Please try again later.',
        );
      }
      setSuccessMessage('Your report will be available shortly.');
    } catch (error: any) {
      captureError(error);
    } finally {
      handleRefresh();
      clearInputs();
    }
  };

  const handleDeleteReport = async () => {
    if (!report?.id) {
      return;
    }
    try {
      const deleteResponse: AxiosResponse<IETagDeleteReportResponse> =
        await deleteScheduledValidationReport(toEntityId, report.id);

      if (!isSuccessStatus(deleteResponse.status)) {
        setErrorMessage(
          'There was an error deleting your report. Please try again later.',
        );
      }
      setSuccessMessage('Your report has been deleted.');
      handleRefresh();
    } catch (error: any) {
      captureError(error);
    } finally {
    }
  };

  const handleReportNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReportName(event.target.value);
  };

  const isCreateDisabled: boolean =
    !reportName ||
    !startDateTime ||
    !timeZone ||
    !rangeExpression ||
    !frequency ||
    selectedRuleNames.length < 1;

  const isDeleteDisabled: boolean = !report?.id;
  return permissions.isDisplayable ? (
    <Layout>
      {
        <SeparatedRowLayout>
          <div>Report Name:</div>
          <ReportNameInputContainer>
            <Input
              allowClear={true}
              isDisabled={!!report}
              onChange={handleReportNameChange}
              placeholder='Enter Report Name'
              value={reportName}
            />
          </ReportNameInputContainer>
        </SeparatedRowLayout>
      }
      {
        <SeparatedRowLayout>
          <div>Rules List:</div>
          <RulesListInputContainer>
            <Tooltip title={selectedRuleNames.toString()}>
              <Select
                allowMultiple={true}
                isDisabled={!!report}
                onChangeMultiple={setSelectedRuleNames}
                options={ruleOptions}
                values={selectedRuleNames}
                valueToUid={(value: string | undefined) => value ?? ''}
              />
            </Tooltip>
          </RulesListInputContainer>
        </SeparatedRowLayout>
      }
      {
        <SeparatedRowLayout>
          <div>Timezone:</div>
          <TimeZoneSelectContainer>
            <Select
              options={ALL_TIME_ZONE_OPTIONS}
              isDisabled={!!report}
              onChange={setSelectedTimeZone}
              value={timeZone}
              valueToUid={(value: string) => value}
            />
          </TimeZoneSelectContainer>
        </SeparatedRowLayout>
      }
      {
        <SeparatedRowLayout>
          <div>Start Date:</div>
          <AlignFormRight>
            <DateSelectContainer>
              <DateTimePicker
                allowClear={false}
                isDisabled={!timeZone || !!report}
                format={DATE_TIME_FORMAT}
                onChange={setStartDateTime}
                showTime={SHOW_TIME_CONFIGURATION}
                timeZone={timeZone ?? 'America/Los_Angeles'}
                value={startDateTime}
              />
            </DateSelectContainer>
          </AlignFormRight>
        </SeparatedRowLayout>
      }
      {
        <SeparatedRowLayout>
          <Layout>
            <div>Range of data:</div>
            <RangeOfDataSelect
              defaultRangeExpression={report?.periodicity.range_expression}
              isDisabled={!!report}
              onDataRangeChange={setRangeExpression}
            />
          </Layout>
          <Layout>
            <div>Frequency:</div>
            <FrequencyOfDataSelect
              defaultFrequency={report ? { ...report.periodicity } : undefined}
              isDisabled={!!report}
              onFrequencyChange={setFrequency}
              startDate={startDateTime}
            />
          </Layout>
        </SeparatedRowLayout>
      }
      {
        <Actions>
          {showTimedMessage ? timedMessage : null}
          {!report ? (
            <Button
              encodedPermissionsId={encodeIds(
                [encodedPermissionsId, 'create'],
                toEntityId,
              )}
              isDisabled={isCreateDisabled}
              isPrimary={true}
              label={'Create Scheduled Report'}
              onClick={handleCreateReport}
            />
          ) : (
            <Button
              encodedPermissionsId={encodeIds(
                [encodedPermissionsId, 'delete'],
                toEntityId,
              )}
              isDisabled={isDeleteDisabled}
              isPrimary={false}
              label={'Delete Scheduled Report'}
              onClick={handleDeleteReport}
            />
          )}
        </Actions>
      }
    </Layout>
  ) : (
    <ErrorMessage title='User does not have access' />
  );
};

export default ToEntityValidationReportCreate;
