import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import RequestIndicator from 'components/molecules/RequestIndicator/RequestIndicator';
import SummaryInformationTitle from 'components/molecules/SummaryInformationTitle/SummaryInformationTitle';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import MarketPathReview from 'components/organisms/MarketPathReview/MarketPathReview';
import PhysicalPathReview from 'components/organisms/PhysicalPathReview/PhysicalPathReview';
import RequestsInformationDataTable from 'components/organisms/RequestsInformationDataTable/RequestsInformationDataTable';
import SummaryInformationReview from 'components/organisms/SummaryInformationReview/SummaryInformationReview';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import AutoSizingProfileDataTable from 'components/pages/DetailPrintPage/AutoSizingProfileDataTable/AutoSizingProfileDataTable';
import CollapsibleSection from 'components/pages/DetailPrintPage/CollapsibleSection';
import { getProfileColumns } from 'components/pages/DetailPrintPage/DetailExportView/helpers';
import LossMethodsDataTable from 'components/pages/DetailPrintPage/DetailExportView/LossMethodsDataTable';
import OasisInfoDataTable from 'components/pages/DetailPrintPage/DetailExportView/OasisInfoDataTable';
import { DETAIL_HEADER, STANDARD_SPACING_VALUE } from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EProfileFormat, ETableConfiguration } from 'enums/Detail';
import { ERetreiveState } from 'enums/General';
import usePhysicalPathReview from 'hooks/usePhysicalPathReview';
import useProfileInformationReview from 'hooks/useProfileInformationReview/useProfileInformationReview';
import useSummaryInformationReview from 'hooks/useSummaryInformationReview';
import {
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import { IDetailMarketSegment } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import {
  getGenerationPhysicalSegmentName,
  getLoadPhysicalSegmentName,
  transmissionAllocationSorter,
} from 'utils/detail';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';
import {
  PRINT_VIEW_PROFILES_FORMAT_OPTIONS,
  PROFILE_FORMAT_SELECT_WIDTH,
} from '../../../organisms/ProfileInformationView/ProfileInformationManager/constants';
import Select, { ISelectProps } from '../../../molecules/Select/Select';

interface IContainerProps {
  width: number;
}

interface IProfileFormatSelectProps extends ISelectProps<EProfileFormat> {
  width: string;
}

const ProfileFormatSelect = styled((props: ISelectProps<EProfileFormat>) =>
  Select<EProfileFormat>(props),
)<IProfileFormatSelectProps>`
  width: ${(props) => props.width};
  margin-left: 200px;
`;

const Container = styled.div<IContainerProps>`
  width: ${(props) => props.width}px;

  > :not(:last-child) {
    margin-bottom: ${2 * STANDARD_SPACING_VALUE}px;
  }

  > :first-child {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  ${DETAIL_HEADER}
`;

interface IDetailExportViewProps {
  onResize: (width?: number, height?: number) => void;
  width: number;
}

const retrieveExportViewState = (state: TRootState) => {
  const {
    active,
    approved_termination_time,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    end_date,
    generationPhysicalSegment,
    group_name,
    last_update_time,
    last_update_user,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    name,
    notes,
    pageMode,
    physicalSegmentsProfiles,
    retrievingDetail,
    selectedProfileFormat,
    selectedRequestKey,
    start_date,
    tag_id,
    test_flag,
    toEntity,
    transaction_type,
    transactionStatuses,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    viewMode,
    physical_segment_loss_percentages,
    isPrintView,
  } = state.detail.present;
  const isDetailLoading: boolean =
    retrievingDetail !== ERetreiveState.NotRetrieving &&
    retrievingDetail !== ERetreiveState.RetrievingCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    active,
    approved_termination_time,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    end_date,
    generationPhysicalSegment,
    group_name,
    isDetailLoading,
    last_update_time,
    last_update_user,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    name,
    notes,
    pageMode,
    physicalSegmentsProfiles,
    selectedProfileFormat,
    selectedRequestKey,
    start_date,
    tag_id,
    test_flag,
    timeZone,
    toEntity,
    transaction_type,
    transactionStatuses,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    viewMode,
    physical_segment_loss_percentages,
    isPrintView,
  };
};

const DetailExportView = ({
  onResize,
  width,
}: IDetailExportViewProps): JSX.Element => {
  const {
    active,
    approved_termination_time,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    end_date,
    generationPhysicalSegment,
    group_name,
    isDetailLoading,
    last_update_time,
    last_update_user,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    name,
    notes,
    pageMode,
    physicalSegmentsProfiles,
    selectedProfileFormat,
    selectedRequestKey,
    start_date,
    tag_id,
    test_flag,
    timeZone,
    toEntity,
    transaction_type,
    transactionStatuses,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    viewMode,
    physical_segment_loss_percentages,
    isPrintView,
  } = useSelector(retrieveExportViewState);

  transmission_physical_segments?.map(
    (segment: IETagTransmissionPhysicalSegment) =>
      (segment.physical_segment_loss_percentage =
        physical_segment_loss_percentages
          ? physical_segment_loss_percentages[segment.physical_segment_id]
          : null),
  );

  const {
    contactInfos,
    dateTimeDataSet,
    eTagTagIds,
    tagHeaderDataSet,
    tagNoteDataSet,
    templateDataSet,
  } = useSummaryInformationReview(
    approved_termination_time,
    cc_list,
    contact_info,
    creator,
    end_date,
    group_name,
    last_update_time,
    last_update_user,
    notes,
    start_date,
    tag_id,
    test_flag,
    timeZone,
    transaction_type,
  );

  const [selectedPrintViewProfileFormat, setSelectedPrintViewProfileFormat] =
    useState<EProfileFormat | undefined>(selectedProfileFormat);

  const adjustedMarketSegments: IDetailMarketSegment[] = useMemo(
    () => (marketSegments === null ? [] : marketSegments),
    [marketSegments],
  );

  const {
    generationPhysicalSegments,
    loadPhysicalSegments,
    transmissionPhysicalSegments,
  } = usePhysicalPathReview(
    tag_id,
    marketSegments,
    generationPhysicalSegment,
    transmission_physical_segments,
    loadPhysicalSegment,
    lossAccountings,
    transmissionAllocations,
  );

  const generationPhysicalSegmentName: string = useMemo(
    () => getGenerationPhysicalSegmentName(generationPhysicalSegment),
    [generationPhysicalSegment],
  );

  const loadPhysicalSegmentName: string = useMemo(
    () => getLoadPhysicalSegmentName(loadPhysicalSegment),
    [loadPhysicalSegment],
  );

  const sortedTransmissionAllocations: IETagTransmissionAllocation[] = useMemo(
    () =>
      transmissionAllocations === null
        ? []
        : [...transmissionAllocations].sort(transmissionAllocationSorter),
    [transmissionAllocations],
  );

  const {
    profileInformationColumns,
    profileInformationDataSet,
    profileInformationSummaryRows,
  } = useProfileInformationReview(
    end_date,
    generationPhysicalSegment,
    generationPhysicalSegmentName,
    false,
    false,
    false,
    false,
    loadPhysicalSegment,
    loadPhysicalSegmentName,
    pageMode,
    physicalSegmentsProfiles,
    false,
    selectedPrintViewProfileFormat,
    ETableConfiguration.All,
    true,
    sortedTransmissionAllocations,
    start_date,
    timeZone,
    transmissionAllocations,
    transmissionPhysicalSegments,
    useUniqueProfiles,
    viewMode,
    false,
    isPrintView,
  );

  const startDateTime: string = useMemo(
    () =>
      start_date === null
        ? ''
        : ZonedDateTime.parseIso(start_date, timeZone).format(DATE_TIME_FORMAT),
    [start_date, timeZone],
  );

  const endDateTime: string = useMemo(
    () =>
      end_date === null
        ? ''
        : ZonedDateTime.parseIso(end_date, timeZone).format(DATE_TIME_FORMAT),
    [end_date, timeZone],
  );

  const printDateTime: string = useMemo(
    () =>
      ZonedDateTime.now(ZonedDateTime.defaultTimeZone()).format(
        DATE_TIME_FORMAT,
      ),
    [],
  );

  const header = useMemo(
    () => (
      <Header>
        <Title>
          <SeparatedRowLayout centered={true}>
            <SummaryInformationTitle
              active={active}
              compositeState={composite_state}
              isLoading={isDetailLoading}
              name={name}
              uiTagId={tag_id === null ? null : tag_id.ui_tag_id}
              viewMode={viewMode}
            />
            <SeparatedRowLayout>
              <div>Print Date Time:</div>
              <div>{printDateTime}</div>
            </SeparatedRowLayout>
          </SeparatedRowLayout>
        </Title>
        {toEntity === null ? null : (
          <UserTimeZoneSelector toEntityId={toEntity.to_entity} />
        )}
      </Header>
    ),
    [
      active,
      composite_state,
      isDetailLoading,
      name,
      printDateTime,
      tag_id,
      toEntity,
      viewMode,
    ],
  );

  const { energyProfileColumns, transmissionAllocationProfileColumns } =
    getProfileColumns(profileInformationColumns, profileInformationDataSet);

  const headerInfoTitle = useMemo(() => <Title>Header Info</Title>, []);

  const marketPathTitle = useMemo(() => <Title>Market Path</Title>, []);

  const physicalPathTitle = useMemo(() => <Title>Physical Path</Title>, []);

  const oasisInformationDetailsTitle = useMemo(
    () => <Title>OASIS Information Details</Title>,
    [],
  );

  const lossMethodsTitle = useMemo(() => <Title>Loss Methods</Title>, []);

  const requestInformationTitle = useMemo(
    () => <Title>Request Information</Title>,
    [],
  );

  const energyProfileTitle = useMemo(
    () => (
      <Title>
        Energy Profile Data for{'  '}
        <RequestIndicator requestKey={selectedRequestKey} />
        for Date Time Range: [{startDateTime} - {endDateTime}]
      </Title>
    ),
    [endDateTime, selectedRequestKey, startDateTime],
  );

  const transmissionAllocationProfileTitle = useMemo(
    () => (
      <Title>
        Transmission Allocation Profile Data for{'  '}
        <RequestIndicator requestKey={selectedRequestKey} />
        for Date Time Range: [{startDateTime} - {endDateTime}]
      </Title>
    ),
    [endDateTime, selectedRequestKey, startDateTime],
  );

  const handleProfileFormatChange = (format: EProfileFormat | undefined) => {
    if (format === undefined) {
      throw new Error('Invalid format selected');
    }
    setSelectedPrintViewProfileFormat(format as EProfileFormat);
  };

  const profileFormatSelect = useMemo(
    () => (
      <Tooltip title='Profile Format Selection'>
        <ProfileFormatSelect
          isDisabled={false}
          options={PRINT_VIEW_PROFILES_FORMAT_OPTIONS}
          onChange={handleProfileFormatChange}
          placeholder='Select Profile Format'
          value={selectedPrintViewProfileFormat}
          valueToUid={(value: EProfileFormat): string => value as string}
          width={PROFILE_FORMAT_SELECT_WIDTH}
        />
      </Tooltip>
    ),
    [selectedPrintViewProfileFormat],
  );

  return (
    <Container className='detail-export-view' width={width}>
      <ResizeDetector onResize={onResize} />
      {header}
      <CollapsibleSection title={headerInfoTitle}>
        <SummaryInformationReview
          contactInfos={contactInfos}
          dateTimeDataSet={dateTimeDataSet}
          description={description}
          eTagTagIds={eTagTagIds}
          isRowLayout={true}
          isUnconstrained={true}
          tagHeaderDataSet={tagHeaderDataSet}
          tagNoteDataSet={tagNoteDataSet}
          templateDataSet={templateDataSet}
          viewMode={viewMode}
        />
      </CollapsibleSection>
      <CollapsibleSection title={marketPathTitle}>
        <MarketPathReview
          isExpandable={true}
          isUnconstrained={true}
          marketSegments={adjustedMarketSegments}
        />
      </CollapsibleSection>
      <CollapsibleSection title={physicalPathTitle}>
        <PhysicalPathReview
          generationPhysicalSegments={generationPhysicalSegments}
          isExpandable={true}
          isLossMethodsPopover={false}
          isOasisInfoPopover={false}
          isUnconstrained={true}
          loadPhysicalSegments={loadPhysicalSegments}
          transmissionAllocations={transmissionAllocations}
          transmissionPhysicalSegments={transmissionPhysicalSegments}
          viewMode={viewMode}
          composite_state={composite_state}
        />
      </CollapsibleSection>
      <CollapsibleSection title={oasisInformationDetailsTitle}>
        <OasisInfoDataTable transmissionAllocations={transmissionAllocations} />
      </CollapsibleSection>
      <CollapsibleSection title={lossMethodsTitle}>
        <LossMethodsDataTable
          isUnconstrained={true}
          lossAccountings={lossAccountings}
          timeZone={timeZone}
        />
      </CollapsibleSection>
      <CollapsibleSection title={requestInformationTitle}>
        <RequestsInformationDataTable
          isUnconstrained={true}
          selectedRequestKey={selectedRequestKey}
          toEntity={toEntity}
          tag_primary_key={''}
          timeZone={timeZone}
          transactionStatuses={transactionStatuses}
        />
      </CollapsibleSection>
      <CollapsibleSection
        title={energyProfileTitle}
        headerExtraControl={profileFormatSelect}
      >
        <AutoSizingProfileDataTable
          columns={energyProfileColumns}
          rows={profileInformationDataSet}
          selectedRequestKey={selectedRequestKey}
          summaryRows={profileInformationSummaryRows}
          timeZone={timeZone}
          width={width}
        />
      </CollapsibleSection>
      <CollapsibleSection title={transmissionAllocationProfileTitle}>
        <AutoSizingProfileDataTable
          columns={transmissionAllocationProfileColumns}
          rows={profileInformationDataSet}
          selectedRequestKey={selectedRequestKey}
          summaryRows={profileInformationSummaryRows}
          timeZone={timeZone}
          width={width}
        />
      </CollapsibleSection>
    </Container>
  );
};

export default DetailExportView;
