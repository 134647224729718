import Page from 'components/organisms/Page/Page';
import SummaryOptionsPageContent from 'components/pages/SummaryOptionsPage/SummaryOptionsPageContent';
import { IRouteProps } from 'interfaces/Component';

const SummaryOptionsPage = ({ appActions }: IRouteProps): JSX.Element => (
  <Page
    appActions={appActions}
    PageContent={SummaryOptionsPageContent}
    pageTitle='Summary Options - PCI e-Tag+'
  />
);

export default SummaryOptionsPage;
