import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import eTagProducts from 'data/eTagProducts.json';
import { EAdHocFilter, EAdHocFilterConfigurationType } from 'enums/AdHocFilter';
import {
  ECompositeState,
  EMarketInfoMarket,
  ERequestStatus,
  ETimeClassification,
  ETransactionType,
} from 'enums/ETag';
import {
  ECustomFilterType,
  EFilterMiscType,
  EFilterStringType,
} from 'enums/Filter';
import { ESearchStringType } from 'enums/Search';
import { IOption } from 'interfaces/Component';
import { IETagMarketDetail } from 'interfaces/ETag';
import { IFilterDetail } from 'interfaces/Filter';
import { IEnergyProductInfo } from 'interfaces/General';
import { ISearchStringDetail } from 'interfaces/Search';
import {
  TETagAdHocFilterConfiguration,
  TETagEnumeration,
  TETagFilterEnumeration,
  TETagProductValue,
  TETagTemplateEnumeration,
  TETagTemplateFilterEnumeration,
} from 'types/ETag';
import {
  caseInsensitiveDoesNotEqualFilter,
  caseInsensitiveEqualsFilter,
  containsFilter,
  doesNotContainFilter,
  doesNotEndWithFilter,
  doesNotEqualFilter,
  doesNotStartWithFilter,
  endsWithFilter,
  equalsFilter,
  inclusiveIsBetweenFilter,
  inclusiveIsWithinFilter,
  isAfterFilter,
  isBeforeFilter,
  isBetweenFilter,
  isBlankFilter,
  isGreaterThanFilter,
  isGreaterThanOrEqualFilter,
  isLessThanFilter,
  isLessThanOrEqualFilter,
  isNotBlankFilter,
  isNotOneOfFilter,
  isOneOfFilter,
  isSameFilter,
  isSameOrAfterFilter,
  isSameOrBeforeFilter,
  isWithinFilter,
  numberDoesNotEqualToFilter,
  numberEqualsFilter,
  startsWithFilter,
} from 'utils/filter';

const energyProductInfoToOption = (
  energyProductInfo: IEnergyProductInfo,
): IOption<IEnergyProductInfo> => ({
  label: energyProductInfo.product_name,
  value: energyProductInfo,
});

const energyProductNameToOption = (
  energyProductInfo: IEnergyProductInfo,
): IOption<string> => ({
  label: energyProductInfo.product_name,
  value: energyProductInfo.product_name,
});

export const GENERATION_ENERGY_PRODUCT_OPTIONS =
  eTagProducts.generation_products.map(energyProductInfoToOption);
export const LOAD_ENERGY_PRODUCT_OPTIONS = eTagProducts.load_products.map(
  energyProductInfoToOption,
);
export const TRANSMISSION_ENERGY_PRODUCT_OPTIONS =
  eTagProducts.transmission_products.map(energyProductInfoToOption);

export const TRANSMISSION_ENERGY_PRODUCT_OPTIONS2 =
  eTagProducts.transmission_products.map(energyProductNameToOption);

const energyProductInfoOptionToETagProductValueOption = (
  energyProductInfoOption: IOption<IEnergyProductInfo>,
): IOption<TETagProductValue> => ({
  label: energyProductInfoOption.label,
  value: energyProductInfoOption.value.product_ref,
});

export const ETAG_BOOLEAN_OPTIONS: IOption<boolean>[] = [
  { label: BOOLEAN_TRUE_LABEL, value: true },
  { label: BOOLEAN_FALSE_LABEL, value: false },
];

export const ETAG_ENUM_OPTIONS_MAP: Record<
  string,
  IOption<TETagEnumeration>[]
> = {
  composite_state: Object.values(ECompositeState).map(
    (value: ECompositeState) => ({ label: value as string, value }),
  ),
  last_request_status: Object.values(ERequestStatus).map(
    (value: ERequestStatus) => ({ label: value as string, value }),
  ),
  transaction_type: Object.values(ETransactionType).map(
    (value: ETransactionType) => ({ label: value as string, value }),
  ),
};

export const ETAG_TEMPLATE_ENUM_OPTIONS_MAP: Record<
  string,
  IOption<TETagTemplateEnumeration>[]
> = {
  tag_type: Object.values(ETransactionType).map((value: ETransactionType) => ({
    label: value as string,
    value,
  })),
};

export const ETAG_FILTER_ENUM_OPTIONS_MAP: Record<
  string,
  IOption<TETagFilterEnumeration>[]
> = {
  composite_state_filter: Object.values(ECompositeState).map(
    (value: ECompositeState) => ({ label: value as string, value }),
  ),
  tag_type_filter: Object.values(ETransactionType).map(
    (value: ETransactionType) => ({ label: value as string, value }),
  ),
  time_classification_filter: Object.values(ETimeClassification).map(
    (value: ETimeClassification) => ({ label: value as string, value }),
  ),
  market_segment_energy_product_filter: GENERATION_ENERGY_PRODUCT_OPTIONS.map(
    energyProductInfoOptionToETagProductValueOption,
  ),
  trans_alloc_product_filter: TRANSMISSION_ENERGY_PRODUCT_OPTIONS.map(
    energyProductInfoOptionToETagProductValueOption,
  ),
};

export const ETAG_TEMPLATE_FILTER_ENUM_OPTIONS_MAP: Record<
  string,
  IOption<TETagTemplateFilterEnumeration>[]
> = {
  transaction_type_filter: Object.values(ETransactionType).map(
    (value: ETransactionType) => ({ label: value as string, value }),
  ),
  gen_product_filter: GENERATION_ENERGY_PRODUCT_OPTIONS.map(
    energyProductInfoOptionToETagProductValueOption,
  ),
  load_product_filter: LOAD_ENERGY_PRODUCT_OPTIONS.map(
    energyProductInfoOptionToETagProductValueOption,
  ),
  trans_product_filter: TRANSMISSION_ENERGY_PRODUCT_OPTIONS.map(
    energyProductInfoOptionToETagProductValueOption,
  ),
};

export const ETAG_AD_HOC_FILTER_MAP: Record<
  string,
  TETagAdHocFilterConfiguration
> = {
  [EAdHocFilter.Contains]: {
    description: 'Contains',
    filterString: containsFilter,
    label: '~',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.DoesNotContain]: {
    description: 'Does not contain',
    filterString: doesNotContainFilter,
    label: '!~',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.StartsWith]: {
    description: 'Starts with',
    filterString: startsWithFilter,
    label: '^',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.DoesNotStartWith]: {
    description: 'Does not start with',
    filterString: doesNotStartWithFilter,
    label: '!^',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.EndsWith]: {
    description: 'Ends with',
    filterString: endsWithFilter,
    label: '|',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.DoesNotEndWith]: {
    description: 'Does not end with',
    filterString: doesNotEndWithFilter,
    label: '!@',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.Equals]: {
    description: 'Equals',
    filterString: equalsFilter,
    label: '==',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.DoesNotEqual]: {
    description: 'Does not equal',
    filterString: doesNotEqualFilter,
    label: '!=',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.CaseInsensitiveEquals]: {
    description: 'Case insensitive equals',
    filterString: caseInsensitiveEqualsFilter,
    label: 'i==',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.CaseInsensitiveDoesNotEqual]: {
    description: 'Case insensitive does not equal',
    filterString: caseInsensitiveDoesNotEqualFilter,
    label: 'i!=',
    type: EAdHocFilterConfigurationType.String,
  },
  [EAdHocFilter.BooleanIsOneOf]: {
    description: 'Is one of',
    filterEnumeration: isOneOfFilter,
    label: '=()',
    type: EAdHocFilterConfigurationType.Boolean,
  },
  [EAdHocFilter.BooleanIsNotOneOf]: {
    description: 'Is not one of',
    filterEnumeration: isNotOneOfFilter,
    label: '!=()',
    type: EAdHocFilterConfigurationType.Boolean,
  },
  [EAdHocFilter.IsOneOf]: {
    description: 'Is one of',
    filterEnumeration: isOneOfFilter,
    label: '=()',
    type: EAdHocFilterConfigurationType.Enumerations,
  },
  [EAdHocFilter.IsNotOneOf]: {
    description: 'Is not one of',
    filterEnumeration: isNotOneOfFilter,
    label: '!=()',
    type: EAdHocFilterConfigurationType.Enumerations,
  },
  [EAdHocFilter.IsBlank]: {
    description: 'Is blank',
    filterBlank: isBlankFilter,
    label: '#',
    type: EAdHocFilterConfigurationType.Blank,
  },
  [EAdHocFilter.IsNotBlank]: {
    description: 'Is not blank',
    filterBlank: isNotBlankFilter,
    label: '!#',
    type: EAdHocFilterConfigurationType.Blank,
  },
  [EAdHocFilter.IsLessThan]: {
    description: 'Is less than',
    filterTwoNumber: isLessThanFilter,
    label: '<',
    type: EAdHocFilterConfigurationType.Number,
  },
  [EAdHocFilter.IsLessThanOrEqual]: {
    description: 'Is less than or equal',
    filterTwoNumber: isLessThanOrEqualFilter,
    label: '<=',
    type: EAdHocFilterConfigurationType.Number,
  },
  [EAdHocFilter.IsGreaterThan]: {
    description: 'Is greater than',
    filterTwoNumber: isGreaterThanFilter,
    label: '>',
    type: EAdHocFilterConfigurationType.Number,
  },
  [EAdHocFilter.IsGreaterThanOrEqual]: {
    description: 'Is greater than or equal',
    filterTwoNumber: isGreaterThanOrEqualFilter,
    label: '>=',
    type: EAdHocFilterConfigurationType.Number,
  },
  [EAdHocFilter.NumberEquals]: {
    description: 'Equals',
    filterTwoNumber: numberEqualsFilter,
    label: '==',
    type: EAdHocFilterConfigurationType.Number,
  },
  [EAdHocFilter.NumberDoesNotEqual]: {
    description: 'Does not equal',
    filterTwoNumber: numberDoesNotEqualToFilter,
    label: '!=',
    type: EAdHocFilterConfigurationType.Number,
  },
  [EAdHocFilter.IsBetween]: {
    description: 'Is between',
    filterThreeNumber: isBetweenFilter,
    label: '<<',
    type: EAdHocFilterConfigurationType.Numbers,
  },
  [EAdHocFilter.InclusiveIsBetween]: {
    description: 'Inclusive is between',
    filterThreeNumber: inclusiveIsBetweenFilter,
    label: '<<=',
    type: EAdHocFilterConfigurationType.Numbers,
  },
  [EAdHocFilter.IsSame]: {
    description: 'Is same',
    filterTwoDateTime: isSameFilter,
    label: '==',
    type: EAdHocFilterConfigurationType.Date,
  },
  [EAdHocFilter.IsBefore]: {
    description: 'Is before',
    filterTwoDateTime: isBeforeFilter,
    label: '<',
    type: EAdHocFilterConfigurationType.Date,
  },
  [EAdHocFilter.IsSameOrBefore]: {
    description: 'Is same or before',
    filterTwoDateTime: isSameOrBeforeFilter,
    label: '<=',
    type: EAdHocFilterConfigurationType.Date,
  },
  [EAdHocFilter.IsAfter]: {
    description: 'Is after',
    filterTwoDateTime: isAfterFilter,
    label: '>',
    type: EAdHocFilterConfigurationType.Date,
  },
  [EAdHocFilter.IsSameOrAfter]: {
    description: 'Is same or after',
    filterTwoDateTime: isSameOrAfterFilter,
    label: '>=',
    type: EAdHocFilterConfigurationType.Date,
  },
  [EAdHocFilter.IsWithin]: {
    description: 'Is between',
    filterThreeDateTime: isWithinFilter,
    label: '<<',
    type: EAdHocFilterConfigurationType.Dates,
  },
  [EAdHocFilter.InclusiveIsWithin]: {
    description: 'Inclusive is between',
    filterThreeDateTime: inclusiveIsWithinFilter,
    label: '<<=',
    type: EAdHocFilterConfigurationType.Dates,
  },
};

export const ETAG_FILTER_DETAIL_MAP: Record<string, IFilterDetail> = {
  [EFilterStringType.Contains]: {
    description: 'Contains',
    label: '~',
  },
  [EFilterStringType.NotContains]: {
    description: 'Does not contain',
    label: '!~',
  },
  [EFilterStringType.Equals]: {
    description: 'Equals',
    label: '==',
  },
  [EFilterStringType.NotEquals]: {
    description: 'Does not equal',
    label: '!==',
  },
  [EFilterStringType.BeginsWith]: {
    description: 'Begins with',
    label: '^',
  },
  [EFilterStringType.NotBeginsWith]: {
    description: 'Does not begin with',
    label: '!^',
  },
  [EFilterStringType.EndsWith]: {
    description: 'Ends with',
    label: '|',
  },
  [EFilterStringType.NotEndsWith]: {
    description: 'Does not end with',
    label: '!|',
  },
  // The order of EFilterMiscType is important for the display order in
  // ETagFilterMisc
  [EFilterMiscType.AnyValue]: {
    description: 'Any value',
    label: '*',
  },
  [EFilterMiscType.ValueContains]: {
    description: 'Contains',
    label: '~',
  },
  [EFilterMiscType.ValueEquals]: {
    description: 'Equals',
    label: '==',
  },
};

export const ETAG_CUSTOM_FILTER_DETAIL_MAP: Record<string, IFilterDetail> = {
  [ECustomFilterType.Equals]: {
    description: 'Equals',
    label: '==',
  },
  [ECustomFilterType.StringBeginsWith]: {
    description: 'Begins With',
    label: '^',
  },
  [ECustomFilterType.StringContains]: {
    description: 'Contains',
    label: '~',
  },
  [ECustomFilterType.StringEndsWith]: {
    description: 'Ends With',
    label: '|',
  },
};

export const ETAG_SEARCH_DETAIL_MAP: Record<
  ESearchStringType,
  ISearchStringDetail
> = {
  [ESearchStringType.Equals]: {
    description: 'Equals',
    label: '==',
  },
};

export const TAG_CODE_REGEX = /^[A-Za-z0-9]+$/;
export const PRIMARY_KEY_REGEX =
  /^[0-9a-zA-Z]+(\|[0-9a-zA-Z]+){3}(\|[0-9a-zA-Z]{12})$/;

export const TEMPLATE_GROUP_NAME_REGEX = /^[A-Za-z0-9_-]+$/;

export const SUMMARY_PROFILE_TOTAL_MW_KEY = 'total_mw';
export const SUMMARY_PROFILE_ON_PEAK_TOTAL_MW_KEY = 'on_peak_total_mw';
export const SUMMARY_PROFILE_OFF_PEAK_TOTAL_MW_KEY = 'off_peak_total_mw';

export const MISO_MARKET_DETAIL: IETagMarketDetail = {
  market: EMarketInfoMarket.MISO,
  timeZone: 'America/Jamaica',
  timeZoneDisplayString: '(GMT-05:00)',
};

export const SPP_MARKET_DETAIL: IETagMarketDetail = {
  dateTimeFormat: 'YYYY-MM-DD',
  market: EMarketInfoMarket.SPP,
  timeZone: 'America/Chicago',
  timeZoneDisplayString: 'Chicago (CPT)',
};

export const MISO_MARKET_OPTION: IOption<IETagMarketDetail> = {
  label: 'MISO',
  value: MISO_MARKET_DETAIL,
};

export const SPP_MARKET_OPTION: IOption<IETagMarketDetail> = {
  label: 'SPP',
  value: SPP_MARKET_DETAIL,
};
