import PopoverDataTable from 'components/molecules/PopoverDataTable/PopoverDataTable';
import {
  CONNECTOR_HEIGHT_VALUE,
  INDICATOR_RIGHT_OFFSET_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import { IThemedProps } from 'interfaces/Component';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { getOasisInfoColumns } from 'utils/OasisInfo';
import { componentBackground, textColour } from 'utils/styles';

interface ILayoutProps {
  connectorWidth: number;
}

const Layout = styled.div<ILayoutProps>`
  height: ${CONNECTOR_HEIGHT_VALUE}px;
  position: relative;
  transition: 0.25s;
  transition-timing-function: ease;
  width: ${(props) => props.connectorWidth}px;
`;

const Wire = styled.div<IThemedProps>`
  border-bottom: 3px solid ${(props) => textColour(props)};
  height: 3px;
  position: absolute;
  top: 2px;
  width: 100%;
`;

const Indicator = styled.div<IThemedProps>`
  border: 2px solid ${(props) => textColour(props)};
  height: 7px;
  position: absolute;
  right: ${INDICATOR_RIGHT_OFFSET_VALUE}px;
  width: 23px;

  ${(props) => componentBackground(props)}
`;

interface IProps {
  connectorWidth: number;
  includeIndicator?: boolean;
  transmissionAllocation?: IETagTransmissionAllocation;
}

const Connector = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { connectorWidth, includeIndicator, transmissionAllocation } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const oasisInfoColumns: IViewDataTableColumn<IETagTransmissionAllocation>[] =
    useMemo(() => getOasisInfoColumns(false), []);

  const transmissionAllocations: IETagTransmissionAllocation[] = useMemo(
    () =>
      transmissionAllocation === undefined ? [] : [transmissionAllocation],
    [transmissionAllocation],
  );

  return (
    <PopoverDataTable
      centerDataTable={true}
      columns={oasisInfoColumns}
      data={transmissionAllocations}
      getIsVisible={setIsVisible}
      pagination={false}
      title={
        transmissionAllocation === undefined
          ? undefined
          : `Physical Segment ${transmissionAllocation.physical_segment_ref} OASIS Info`
      }
    >
      <Layout
        className={isVisible ? 'connector-over' : undefined}
        connectorWidth={connectorWidth}
      >
        <Wire currentTheme={currentTheme!} />
        {includeIndicator ? <Indicator currentTheme={currentTheme!} /> : null}
      </Layout>
    </PopoverDataTable>
  );
};

export default Connector;
