import { Popover as AntDesignPopover } from 'antd';
import ZonedDateTimeRangePicker, {
  IRangeInfo,
} from 'components/molecules/DateTimeRangePicker/ZonedDateTimeRangePicker';
import {
  getDateTimeFrom,
  injectMouseDownInElementCallback,
  listenToMouseDownInElement,
} from 'components/molecules/ToEntityDateTimePicker/helpers';
import ToEntityDateTimePickerFooter from 'components/molecules/ToEntityDateTimePicker/ToEntityDateTimePickerFooter';
import { ERROR_RED } from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { IDateRange } from 'interfaces/Summary';
import { FocusEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

enum EFocusOnItem {
  Start = 0,
  End = 1,
}

const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 7500;
const START_DATE_PLACEHOLDER = 'Start date';
const END_DATE_PLACEHOLDER = 'End date';
const FOCUS_DELAY_IN_MILLISECONDS = 125;

const Message = styled.div`
  color: ${ERROR_RED};
  text-align: center;
`;

interface IProps {
  end: ZonedDateTime | null;
  includeRanges?: boolean;
  rangeLimitInHours?: number;
  setEnd: (end: ZonedDateTime | null) => void;
  setStart: (start: ZonedDateTime | null) => void;
  start: ZonedDateTime | null;
  timeZone: TTimeZone;
}

const ToEntityDateTimePicker = (props: IProps): JSX.Element => {
  const {
    end,
    includeRanges,
    rangeLimitInHours,
    setEnd,
    setStart,
    start,
    timeZone,
  } = props;

  const pickerRef = useRef<HTMLDivElement>(null);
  const mouseDownRef = useRef<Record<string, boolean>>({});

  const [startDateInput, setStartDateInput] = useState<
    HTMLInputElement | undefined
  >();
  const [endDateInput, setEndDateInput] = useState<
    HTMLInputElement | undefined
  >();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<number | undefined>();

  const [focusedOnStart, setFocusedOnStart] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<ZonedDateTime | null>(null);
  const [startTime, setStartTime] = useState<number>(0);

  const [endDate, setEndDate] = useState<ZonedDateTime | null>(null);
  const [endTime, setEndTime] = useState<number>(0);

  const displayLimitMessage = () => {
    setShowMessage(true);
    setTimeoutId(
      window.setTimeout(() => {
        setShowMessage(false);
      }, MESSAGE_DISPLAY_TIME_IN_MILLISECONDS),
    );
  };

  useEffect(
    () => {
      if (start === null) {
        setStartDate(null);
        setStartTime(0);
      } else {
        setStartDate(start);
        setStartTime(start.getHour());
      }

      if (end === null) {
        setEndDate(null);
        setEndTime(0);
      } else {
        setEndDate(end);
        setEndTime(end.getHour());
      }

      if (
        rangeLimitInHours !== undefined &&
        start !== null &&
        end !== null &&
        end.diff(start, 'hours') > rangeLimitInHours
      ) {
        const limitedEnd: ZonedDateTime = start.add(rangeLimitInHours, 'hours');
        setEnd(limitedEnd);

        setEndDate(limitedEnd);
        setEndTime(limitedEnd.getHour());

        displayLimitMessage();
      }
    },
    // As an optimisation, we don't need to trigger an update for
    // displayLimiteMessagesince these do not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [end, rangeLimitInHours, start],
  );

  const handleMouseDown = (context: string) => (isInside: boolean) => {
    mouseDownRef.current[context] = isInside;
  };

  useEffect(() => {
    if (pickerRef.current) {
      const inputElements: HTMLCollectionOf<HTMLInputElement> =
        pickerRef.current.getElementsByTagName('input');

      for (let i: number = 0; i < inputElements.length; i += 1) {
        const inputElement: HTMLInputElement = inputElements[i];

        if (inputElement.placeholder === START_DATE_PLACEHOLDER) {
          setStartDateInput(inputElement);
        } else if (inputElement.placeholder === END_DATE_PLACEHOLDER) {
          setEndDateInput(inputElement);
        }
      }

      listenToMouseDownInElement(pickerRef.current, handleMouseDown('picker'));
    }
  }, [pickerRef]);

  useEffect(() => {
    if (isOpen) {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
        setTimeoutId(undefined);
      }
      if (showMessage) {
        setShowMessage(false);
      }
    }
  }, [isOpen, showMessage, timeoutId]);

  useEffect(() => {
    if (!isOpen && startDateInput && endDateInput) {
      startDateInput.blur();
      endDateInput.blur();
    }
  }, [endDateInput, isOpen, startDateInput]);

  useEffect(() => {
    if (startDate !== null) {
      const newStartDate: ZonedDateTime = startDate.withHour(startTime);

      if (!newStartDate.isSame(startDate)) {
        setStartDate(newStartDate);
      }
    }
  }, [startDate, startTime]);

  useEffect(() => {
    if (endDate !== null) {
      const newEndDate: ZonedDateTime = endDate.withHour(endTime);

      if (!newEndDate.isSame(endDate)) {
        setEndDate(newEndDate);
      }
    }
  }, [endDate, endTime]);

  const focusOn = (focusOnItem: EFocusOnItem) => {
    // Before switching focus, we delay for a moment in order for Ant Design
    // Range Picker to update internal state.
    if (focusOnItem === EFocusOnItem.Start) {
      window.setTimeout(() => {
        startDateInput?.focus();
        setFocusedOnStart(true);
      }, FOCUS_DELAY_IN_MILLISECONDS);
    } else if (focusOnItem === EFocusOnItem.End) {
      window.setTimeout(() => {
        endDateInput?.focus();
        setFocusedOnStart(false);
      }, FOCUS_DELAY_IN_MILLISECONDS);
    }
  };

  const handleCancel = () => {
    // We clone start and/or end when we know that we want to revert the
    // state of the Ant Design Range Picker component by triggering an
    // update through setStart/setEnd. If our startDate/startTime and/or
    // endDate/endTime is unchanged then there is no need to revert the
    // Ant Design Range Picker component state since it will be in sync
    // with our startDate/startTime and endDate/endTime already and thus
    // we can avoid the cloning.
    if (start !== null) {
      if (startDate === null) {
        setStart(start);
      } else {
        const startDateTime: ZonedDateTime = getDateTimeFrom(
          startDate,
          startTime,
        );

        if (!startDateTime.isSame(start)) {
          setStart(start);
        }
      }
    }

    if (end !== null) {
      if (endDate === null) {
        setEnd(end);
      } else {
        const endDateTime: ZonedDateTime = getDateTimeFrom(endDate, endTime);

        if (!endDateTime.isSame(end)) {
          setEnd(end);
        }
      }
    }

    setIsOpen(false);
  };

  const handleOk = () => {
    if (startDate !== null && endDate !== null) {
      const startDateTime: ZonedDateTime = getDateTimeFrom(
        startDate,
        startTime,
      );

      if (!start?.isSame(startDateTime)) {
        setStart(startDate);
      }

      const endDateTime: ZonedDateTime = getDateTimeFrom(endDate, endTime);

      if (!end?.isSame(endDateTime)) {
        setEnd(endDate);
      }

      setIsOpen(false);
    }
  };

  const handleRangeButton = (range: IDateRange) => () => {
    setStartDate(range.start);
    setStartTime(range.start.getHour());

    setEndDate(range.end);
    setEndTime(range.end.getHour());
  };

  const handleOpen = (open: boolean) => {
    // We use a mapping of element contexts to clicks using a ref to ensure we
    // maintain the click ordering. This mapping is used to determine if a
    // click has occurred within any of the elements that belong to this
    // component. The Ant Design Range Picker component will try to close the
    // dropdown if the focus is removed from either the start or the end input
    // elements. We override this behaviour in order to keep the dropdown open
    // unless a click occurs outside of the component or the cancel or ok
    // buttons are clicked (the button handlers manage that part of the close).
    const inToEntityDateTimePicker = Object.values(mouseDownRef.current).reduce(
      (previousValue, currentValue): boolean => previousValue || currentValue,
      false,
    );

    if (inToEntityDateTimePicker) {
      setIsOpen(true);
    } else {
      setIsOpen(open);

      if (!open) {
        handleCancel();
      }
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocusedOnStart(event.target.placeholder === START_DATE_PLACEHOLDER);
  };

  const handleCalendarChange = (
    values: TZonedDateTimeRange,
    _formatString: [string, string],
    rangeInfo: IRangeInfo,
  ) => {
    if (rangeInfo.range === 'start') {
      const start: ZonedDateTime | null = values === null ? null : values[0];

      setStartDate(start);

      if (start !== null) {
        focusOn(EFocusOnItem.End);
      }

      if (
        (endDate !== null && start?.isAfter(endDate)) ||
        (rangeLimitInHours !== undefined &&
          start !== null &&
          endDate !== null &&
          endDate.diff(start, 'hours') > rangeLimitInHours)
      ) {
        setEndDate(null);
      }
    } else if (rangeInfo.range === 'end') {
      const end: ZonedDateTime | null = values === null ? null : values[1];

      setEndDate(end);

      if (end !== null) {
        focusOn(EFocusOnItem.Start);
      }
    }
  };

  const handleChange = (dates: TZonedDateTimeRange) => {
    if (dates === null) {
      focusOn(EFocusOnItem.Start);
    }
  };

  const handleStartTimeChange = (startTime: number) => {
    setStartTime(startTime);

    if (startDate === null) {
      focusOn(EFocusOnItem.Start);
    } else {
      const startDateTime: ZonedDateTime = getDateTimeFrom(
        startDate,
        startTime,
      );

      if (
        (endDate !== null && startDateTime.isAfter(endDate)) ||
        (rangeLimitInHours !== undefined &&
          endDate !== null &&
          endDate.diff(startDateTime, 'hours') > rangeLimitInHours)
      ) {
        setEndDate(null);
      }

      focusOn(EFocusOnItem.End);
    }
  };

  const handleEndTimeChange = (endTime: number) => {
    setEndTime(endTime);

    if (endDate === null) {
      focusOn(EFocusOnItem.End);
    } else {
      focusOn(EFocusOnItem.Start);
    }
  };

  const disabledDate = (dateTime: ZonedDateTime | null): boolean => {
    if (dateTime !== null) {
      const startOfDateTime: ZonedDateTime = dateTime.startOf('day');

      if (
        rangeLimitInHours !== undefined &&
        !focusedOnStart &&
        startDate !== null
      ) {
        if (
          startOfDateTime.isBefore(startDate) ||
          startOfDateTime.isAfter(startDate.add(rangeLimitInHours, 'hours'))
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const getFooter = (): JSX.Element => (
    <ToEntityDateTimePickerFooter
      endDate={endDate}
      endTime={endTime}
      includeRanges={isOpen && includeRanges}
      mouseDownInSelectCallback={handleMouseDown}
      onCancel={handleCancel}
      onEndTimeChange={handleEndTimeChange}
      onOk={handleOk}
      onRangeButton={handleRangeButton}
      onStartTimeChange={handleStartTimeChange}
      rangeLimitInHours={rangeLimitInHours}
      startDate={startDate}
      startTime={startTime}
      timeZone={timeZone}
    />
  );

  const message = useMemo(
    () => (
      <Message>
        End date has been set to maximum range for summary profiles.
      </Message>
    ),
    [],
  );

  return (
    <AntDesignPopover content={message} visible={showMessage}>
      <div ref={pickerRef}>
        <ZonedDateTimeRangePicker
          disabledDate={disabledDate}
          dropdownClassName='to-entity-date-time-picker-dropdown'
          format={DATE_TIME_FORMAT}
          getPopupContainer={injectMouseDownInElementCallback(
            handleMouseDown('dropdown'),
          )}
          onCalendarChange={handleCalendarChange}
          onChange={handleChange}
          onFocus={handleFocus}
          onOpenChange={handleOpen}
          open={isOpen}
          placeholder={[START_DATE_PLACEHOLDER, END_DATE_PLACEHOLDER]}
          renderExtraFooter={getFooter}
          timeZone={timeZone}
          value={[startDate, endDate]}
        />
      </div>
    </AntDesignPopover>
  );
};

export default ToEntityDateTimePicker;
