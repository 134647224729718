import { marginRight } from 'components/atoms/SeparatedRowLayout/helpers';
import styled from 'styled-components';

export interface ISeparatedRowLayoutProps {
  centered?: boolean;
  includeEmpty?: boolean;
  marginRight?: number;
}

const SeparatedRowLayout = styled.div<ISeparatedRowLayoutProps>`
  align-items: center;
  display: flex;
  flex-direction: row;

  ${(props) => (props.centered ? 'justify-content: center;' : '')}

  ${(props) =>
    props.includeEmpty
      ? `> :not(:last-child) {
          ${marginRight(props)}
        }`
      : `> :not(:empty):not(:last-child) {
          ${marginRight(props)}
        }`}
`;

export default SeparatedRowLayout;
