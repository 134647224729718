import { NO_OP_HANDLER } from 'constants/General';
import useArraySorters, { IuseArraySorters } from 'hooks/useArraySorters';
import { Context, createContext, ReactNode } from 'react';
import { TETagDataArraySorter } from 'types/ETag';

export interface IETagSortingContext
  extends IuseArraySorters<TETagDataArraySorter> {}

export const ETagSortingContext: Context<IETagSortingContext> =
  createContext<IETagSortingContext>({
    arraySorters: [],
    addArraySorter: NO_OP_HANDLER,
    clearArraySorters: NO_OP_HANDLER,
    removeArraySorter: NO_OP_HANDLER,
    replaceArraySorter: NO_OP_HANDLER,
    setDefaultArraySorter: NO_OP_HANDLER,
  });

interface IETagSortingProviderProps {
  children: ReactNode;
}

const ETagSortingProvider = (props: IETagSortingProviderProps) => {
  const { children } = props;
  const {
    arraySorters,
    addArraySorter,
    clearArraySorters,
    removeArraySorter,
    replaceArraySorter,
    setDefaultArraySorter,
  } = useArraySorters<TETagDataArraySorter>();

  return (
    <ETagSortingContext.Provider
      value={{
        arraySorters,
        addArraySorter,
        clearArraySorters,
        removeArraySorter,
        replaceArraySorter,
        setDefaultArraySorter,
      }}
    >
      {children}
    </ETagSortingContext.Provider>
  );
};

export default ETagSortingProvider;
