import Page from 'components/organisms/Page/Page';
import TransmissionCapacityAvailabilityPageContent from 'components/pages/TransmissionCapacityAvailabilityPage/TransmissionCapacityAvailabilityPageContent';
import { IRouteProps } from 'interfaces/Component';

const TransmissionCapacityAvailabilityPage = ({
  appActions,
}: IRouteProps): JSX.Element => (
  <Page
    appActions={appActions}
    PageContent={TransmissionCapacityAvailabilityPageContent}
    pageTitle='Transmission Availability - PCI E-Tag'
  />
);

export default TransmissionCapacityAvailabilityPage;
