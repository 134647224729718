import { useCallback, useRef, useState } from 'react';

const useDisplayTimedMessage = () => {
  const timeoutId = useRef<number | undefined>();
  const [timedMessage, setTimedMessage] = useState<JSX.Element>(<></>);
  const [showTimedMessage, setShowTimedMessage] = useState<boolean>(false);

  const displayTimedMessage = useCallback(
    (message: JSX.Element, messageDisplayTimeInMilliseconds: number) => {
      clearTimeout(timeoutId.current);

      setTimedMessage(message);
      setShowTimedMessage(true);

      timeoutId.current = window.setTimeout(() => {
        setShowTimedMessage(false);
      }, messageDisplayTimeInMilliseconds);
    },
    [],
  );

  const cancelDisplayTimedMessage = useCallback(() => {
    clearTimeout(timeoutId.current);
    setTimedMessage(<></>);
    setShowTimedMessage(false);
  }, []);

  return {
    cancelDisplayTimedMessage,
    displayTimedMessage,
    timedMessage,
    showTimedMessage,
  };
};

export default useDisplayTimedMessage;
