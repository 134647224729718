import IconButton from 'components/atoms/IconButton/IconButton';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import ColourPaletteIcon from 'components/molecules/PageLegend/ColourPaletteIcon';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { STANDARD_SPACING_VALUE } from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import usePrevious from 'hooks/usePrevious';
import { MutableRefObject, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TPlacement } from 'types/General';
import { isEmptyValue } from 'utils/general';

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
`;

interface IPageLegendProps {
  content: JSX.Element | null;
  id: string;
  maximumHeight?: string;
  tooltipPlacement?: TPlacement;
}

const PageLegend = (props: IPageLegendProps): JSX.Element => {
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const { content, id, maximumHeight, tooltipPlacement } = props;
  const iconButtonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const previousContent = usePrevious(content);

  useEffect(() => {
    if (
      !isEmptyValue(floatOverContent) &&
      floatOverId === id &&
      previousContent !== content
    ) {
      setFloatOverUseDragPanel(false);
      setFloatOverContent(content);
    }
  }, [
    content,
    floatOverContent,
    floatOverId,
    id,
    previousContent,
    setFloatOverContent,
    setFloatOverUseDragPanel,
  ]);

  const handleClick = () => {
    if (content !== undefined) {
      if (isEmptyValue(floatOverContent) || floatOverId !== id) {
        const { height, x, y } = iconButtonRef.current.getBoundingClientRect();

        setFloatOverId(id);
        setFloatOverUseDragPanel(false);
        setFloatOverContent(content);
        setFloatOverMaximumHeight(maximumHeight);
        setFloatOverDefaultPosition({
          placement: EFloatOverPlacement.Left,
          x: x,
          y: y + height + STANDARD_SPACING_VALUE,
        });
      } else if (floatOverId === id) {
        setFloatOverContent(null);
      }
    }
  };

  return (
    <Tooltip placement={tooltipPlacement} title='Table Legend'>
      <IconButton
        buttonRef={iconButtonRef}
        icon={
          <IconContainer>
            <ColourPaletteIcon />
          </IconContainer>
        }
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default PageLegend;
