import {
  IETagTagId,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { useMemo, useRef } from 'react';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailLossAccounting,
  IDetailMarketSegment,
} from 'reduxes/Detail/types';
import {
  copyDetailGenerationPhysicalSegmentWithOverrides,
  copyDetailLoadPhysicalSegmentWithOverrides,
  copyTransmissionPhysicalSegmentWithOverrides,
  getPseFromMarketSegments,
  getUniqueLossMethodsForPhysicalSegment,
  getUniqueOasisInfoForPhysicalSegment,
} from 'utils/detail';
import { pseEntityInfoEqual } from 'utils/entity';

interface IusePhysicalPathReview {
  generationPhysicalSegments: IDetailGenerationPhysicalSegment[];
  loadPhysicalSegments: IDetailLoadPhysicalSegment[];
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[];
}

const usePhysicalPathReview = (
  tag_id: IETagTagId | null,
  marketSegments: IDetailMarketSegment[] | null,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  lossAccountings: IDetailLossAccounting[],
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IusePhysicalPathReview => {
  const previousMarketSegments = useRef<IDetailMarketSegment[] | null>(null);

  const cachedMarketSegments: IDetailMarketSegment[] | null = useMemo(() => {
    if (previousMarketSegments.current === null) {
      previousMarketSegments.current = marketSegments;
      return previousMarketSegments.current;
    }

    if (marketSegments === null) {
      previousMarketSegments.current = marketSegments;
      return previousMarketSegments.current;
    }

    if (marketSegments.length === previousMarketSegments.current.length) {
      let pseChanged: boolean = false;

      for (let i: number = 0; i < marketSegments.length; i += 1) {
        if (
          !pseEntityInfoEqual(
            marketSegments[i].pse,
            previousMarketSegments.current[i].pse,
          )
        ) {
          pseChanged = true;
          break;
        }
      }

      if (pseChanged) {
        previousMarketSegments.current = marketSegments;
        return previousMarketSegments.current;
      } else {
        return previousMarketSegments.current;
      }
    }

    previousMarketSegments.current = marketSegments;
    return previousMarketSegments.current;
  }, [marketSegments, previousMarketSegments]);

  const generationPhysicalSegments: IDetailGenerationPhysicalSegment[] =
    useMemo(
      () =>
        generationPhysicalSegment === null
          ? []
          : [
              copyDetailGenerationPhysicalSegmentWithOverrides(
                generationPhysicalSegment,
                tag_id === null ? null : tag_id.gca,
                getPseFromMarketSegments(
                  generationPhysicalSegment.market_segment_id,
                  cachedMarketSegments,
                ),
              )!,
            ],
      [cachedMarketSegments, generationPhysicalSegment, tag_id],
    );

  const transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] =
    useMemo(
      () =>
        transmission_physical_segments === null
          ? []
          : transmission_physical_segments.map(
              (
                transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
              ): IETagTransmissionPhysicalSegment =>
                copyTransmissionPhysicalSegmentWithOverrides(
                  transmissionPhysicalSegment,
                  getPseFromMarketSegments(
                    transmissionPhysicalSegment.market_segment_id,
                    cachedMarketSegments,
                  ),
                  transmissionPhysicalSegment.scheduling_entities === null
                    ? []
                    : transmissionPhysicalSegment.scheduling_entities,
                  getUniqueOasisInfoForPhysicalSegment(
                    transmissionPhysicalSegment.physical_segment_id,
                    transmissionAllocations,
                  ),
                  getUniqueLossMethodsForPhysicalSegment(
                    transmissionPhysicalSegment.physical_segment_id,
                    lossAccountings,
                  ),
                ),
            ),
      [
        cachedMarketSegments,
        lossAccountings,
        transmission_physical_segments,
        transmissionAllocations,
      ],
    );

  const loadPhysicalSegments: IDetailLoadPhysicalSegment[] = useMemo(
    () =>
      loadPhysicalSegment === null
        ? []
        : [
            copyDetailLoadPhysicalSegmentWithOverrides(
              loadPhysicalSegment,
              tag_id === null ? null : tag_id.lca,
              getPseFromMarketSegments(
                loadPhysicalSegment.market_segment_id,
                cachedMarketSegments,
              ),
            )!,
          ],
    [cachedMarketSegments, loadPhysicalSegment, tag_id],
  );

  return {
    generationPhysicalSegments,
    transmissionPhysicalSegments,
    loadPhysicalSegments,
  };
};

export default usePhysicalPathReview;
