import { IToEntity } from 'interfaces/ToEntity';
import { useMemo } from 'react';
import { TToEntityId } from 'types/ToEntity';
import { checkToEntity } from 'utils/toEntity';

const useToEntityId = (toEntity: IToEntity | null): TToEntityId => {
  const toEntityId: TToEntityId = useMemo(() => {
    checkToEntity(toEntity);

    return toEntity!.to_entity;
  }, [toEntity]);

  return toEntityId;
};

export default useToEntityId;
