// Singleton canvas element for accurately measuring text width for an element
class LazyContext {
  static _context: CanvasRenderingContext2D | null | undefined = undefined;

  static get context() {
    if (this._context === undefined) {
      const canvas = document.createElement('canvas');
      this._context = canvas.getContext('2d');
    }
    return this._context;
  }
}

export const getTextWidthForElement = (element: HTMLElement): number => {
  if (LazyContext.context !== null) {
    const computedStyles = window.getComputedStyle(element);
    LazyContext.context.font = `${computedStyles.fontWeight} ${computedStyles.fontSize} ${computedStyles.fontFamily}`;
    const metrics = LazyContext.context.measureText(element.innerText);

    return metrics.width;
  }

  return -1;
};
