import { ExclamationCircleTwoTone } from '@ant-design/icons';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { ERROR_RED, WARNING_ORANGE } from 'constants/styles';
import styled from 'styled-components';

const WarningIcon = styled(ExclamationCircleTwoTone)`
  font-size: 18px;
`;

const ValidationError = styled.div`
  color: ${ERROR_RED};
`;

interface IScheduleSubmissionValidationListProps {
  validationFailures: string[];
  validationWarnings: string[];
}

export const ScheduleSubmissionValidationList = (
  props: IScheduleSubmissionValidationListProps,
): JSX.Element => {
  let { validationFailures, validationWarnings } = props;
  return (
    <>
      {validationFailures.length > 0 ? (
        <>
          <SeparatedRowLayout>
            <WarningIcon twoToneColor={ERROR_RED} />
            <span>
              Schedule Submission Request failed validation with the following
              issues:
            </span>
          </SeparatedRowLayout>
          <ul>
            {validationFailures.map(
              (validationError: string, index: number): JSX.Element => (
                <li key={`li-${index}`}>
                  <ValidationError>{validationError}</ValidationError>
                </li>
              ),
            )}
          </ul>
        </>
      ) : null}
      {validationWarnings.length > 0 ? (
        <>
          <SeparatedRowLayout>
            <WarningIcon twoToneColor={WARNING_ORANGE} />
            <span>Schedule Submission Request had the following warnings:</span>
          </SeparatedRowLayout>
          <ul>
            {validationWarnings.map(
              (validationWarning: string, index: number): JSX.Element => (
                <li key={`li-${index}`}>
                  <span>{validationWarning}</span>
                </li>
              ),
            )}
          </ul>
        </>
      ) : null}
    </>
  );
};
