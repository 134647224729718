import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import {
  ENHANCED_LIST_OPTIONS,
  LIST_OPTIONS,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/constants';
import AttributeSelect from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/AttributeSelect';
import FilterSelector from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/FilterSelector';
import {
  attributeToCustomFilterAttribute,
  booleanEnumerationToUid,
  enumerationToUid,
  getAttributeValues,
  getCustomFilterForList,
  getCustomFilterTypeForList,
  getEnumerationOptions,
  isEnumerationCustomFilterAttribute,
  isEnumerationListCustomFilterAttribute,
  isMiscInfoListCustomFilterAttribute,
  isStringListCustomFilterAttribute,
  isStringListEnhancedCustomFilterAttribute,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/helpers';
import { IAttributeValues } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/types';
import {
  isMiscInfoTokenAttribute,
  isMiscInfoValueAttribute,
  isStringCustomFilterType,
  listToColour,
  listToDisplayString,
  listToUid,
  uidToList,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/helpers';
import TaggedSelector from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/TaggedSelector/TaggedSelector';
import {
  EAttribute,
  EList,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { ETAG_BOOLEAN_OPTIONS } from 'constants/ETag';
import { ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE } from 'constants/styles';
import { ECustomFilterAttribute, ECustomFilterType } from 'enums/Filter';
import { IOption } from 'interfaces/Component';
import { ICustomFilter } from 'interfaces/Filter';
import { ChangeEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { TETagCustomFilterEnumeration } from 'types/ETag';
import { copyCustomFilter, generateCustomFilter } from 'utils/filter';
import { selectOptionLabelFilter } from 'utils/general';

const BooleanEnumerationSelect = styled((props: ISelectProps<boolean>) =>
  Select<boolean>(props),
)`
  width: 53px;
`;

const EnumerationSelect = styled(
  (props: ISelectProps<TETagCustomFilterEnumeration>) =>
    Select<TETagCustomFilterEnumeration>(props),
)`
  width: 227px;
`;

const StringInput = styled(Input)`
  width: 227px;
`;

interface ICustomFilterCriteriaProps {
  customFilter: ICustomFilter;
  isDisabled?: boolean;
  isList?: boolean;
  onChange: (customFilter: ICustomFilter) => void;
}

const CustomFilterCriteria = ({
  customFilter,
  isDisabled,
  isList,
  onChange,
}: ICustomFilterCriteriaProps): JSX.Element => {
  const handleAttributeChange = useCallback(
    (attribute: EAttribute | undefined) => {
      if (attribute !== undefined) {
        const customFilterAttribute: ECustomFilterAttribute =
          attributeToCustomFilterAttribute(attribute);
        const updatedCustomFilter: ICustomFilter =
          copyCustomFilter(customFilter);

        updatedCustomFilter.attribute = customFilterAttribute;
        updatedCustomFilter.sub_filter = null;
        updatedCustomFilter.sub_filter_list = null;
        updatedCustomFilter.value = null;

        if (isEnumerationCustomFilterAttribute(customFilterAttribute)) {
          updatedCustomFilter.type = ECustomFilterType.Equals;
        } else if (
          isEnumerationListCustomFilterAttribute(customFilterAttribute)
        ) {
          const hiddenEqualsCustomFilter: ICustomFilter =
            generateCustomFilter();

          hiddenEqualsCustomFilter.attribute = customFilterAttribute;
          hiddenEqualsCustomFilter.type = ECustomFilterType.Equals;

          updatedCustomFilter.sub_filter = hiddenEqualsCustomFilter;
          updatedCustomFilter.type = ECustomFilterType.AnyMatch;
        } else if (isStringListCustomFilterAttribute(customFilterAttribute)) {
          const hiddenEqualsCustomFilter: ICustomFilter =
            generateCustomFilter();

          hiddenEqualsCustomFilter.attribute = customFilterAttribute;
          hiddenEqualsCustomFilter.type = ECustomFilterType.Equals;

          updatedCustomFilter.sub_filter = hiddenEqualsCustomFilter;
          updatedCustomFilter.type = ECustomFilterType.AnyMatch;
        } else if (isMiscInfoListCustomFilterAttribute(customFilterAttribute)) {
          const hiddenEqualsCustomFilter: ICustomFilter =
            generateCustomFilter();

          hiddenEqualsCustomFilter.type = ECustomFilterType.Equals;

          if (isMiscInfoTokenAttribute(attribute)) {
            hiddenEqualsCustomFilter.attribute = ECustomFilterAttribute.Token;
          } else if (isMiscInfoValueAttribute(attribute)) {
            hiddenEqualsCustomFilter.attribute = ECustomFilterAttribute.Value;
          }

          updatedCustomFilter.sub_filter = hiddenEqualsCustomFilter;
          updatedCustomFilter.type = ECustomFilterType.AnyMatch;
        } else {
          updatedCustomFilter.type = ECustomFilterType.Equals;
        }

        onChange(updatedCustomFilter);
      }
    },
    [customFilter, onChange],
  );

  const listOptions: IOption<EList>[] = useMemo(
    () =>
      customFilter.attribute !== null &&
      isStringListEnhancedCustomFilterAttribute(customFilter.attribute)
        ? ENHANCED_LIST_OPTIONS
        : LIST_OPTIONS,
    [customFilter.attribute],
  );

  const handleListChange = useCallback(
    (list: EList) => {
      const updatedCustomFilter: ICustomFilter = copyCustomFilter(customFilter);

      updatedCustomFilter.type = getCustomFilterTypeForList(list, customFilter);

      onChange(updatedCustomFilter);
    },
    [customFilter, onChange],
  );

  const adjustedCustomFilter: ICustomFilter = useMemo(
    () => (isList ? getCustomFilterForList(customFilter) : customFilter),
    [customFilter, isList],
  );

  const handleBooleanEnumerationChange = useCallback(
    (value: boolean | undefined) => {
      const updatedCustomFilter: ICustomFilter =
        copyCustomFilter(adjustedCustomFilter);

      updatedCustomFilter.value = value === undefined ? null : value;

      onChange(updatedCustomFilter);
    },
    [adjustedCustomFilter, onChange],
  );

  const handleEnumerationChange = useCallback(
    (values: TETagCustomFilterEnumeration[]) => {
      const updatedCustomFilter: ICustomFilter =
        copyCustomFilter(adjustedCustomFilter);
      const numberOfValues: number = values.length;

      updatedCustomFilter.sub_filter = null;
      updatedCustomFilter.sub_filter_list = null;

      if (numberOfValues > 1) {
        updatedCustomFilter.sub_filter_list = [];

        for (let i: number = 0; i < numberOfValues; i += 1) {
          const enumerationCustomFilter: ICustomFilter = generateCustomFilter();

          enumerationCustomFilter.attribute = updatedCustomFilter.attribute;
          enumerationCustomFilter.type = ECustomFilterType.Equals;
          enumerationCustomFilter.value = values[i];

          updatedCustomFilter.type = ECustomFilterType.Or;
          updatedCustomFilter.sub_filter_list.push(enumerationCustomFilter);
          updatedCustomFilter.value = null;
        }
      } else if (numberOfValues === 1) {
        updatedCustomFilter.type = ECustomFilterType.Equals;
        updatedCustomFilter.value = values[0];
      } else {
        updatedCustomFilter.type = ECustomFilterType.Equals;
        updatedCustomFilter.value = null;
      }

      if (isList) {
        const updatedAttributeRootCustomFilter: ICustomFilter =
          copyCustomFilter(customFilter);

        updatedAttributeRootCustomFilter.sub_filter = updatedCustomFilter;

        onChange(updatedAttributeRootCustomFilter);
      } else {
        onChange(updatedCustomFilter);
      }
    },
    [adjustedCustomFilter, customFilter, isList, onChange],
  );

  const handleFilterChange = useCallback(
    (customFilterType: ECustomFilterType) => {
      if (isStringCustomFilterType(customFilterType)) {
        const updatedCustomFilter: ICustomFilter =
          copyCustomFilter(adjustedCustomFilter);

        updatedCustomFilter.type = customFilterType;

        if (isList) {
          const updatedAttributeRootCustomFilter: ICustomFilter =
            copyCustomFilter(customFilter);

          updatedAttributeRootCustomFilter.sub_filter = updatedCustomFilter;

          onChange(updatedAttributeRootCustomFilter);
        } else {
          onChange(updatedCustomFilter);
        }
      } else {
        throw new Error(`Invalid customFilterType: ${customFilterType}`);
      }
    },
    [adjustedCustomFilter, customFilter, isList, onChange],
  );

  const handleStringInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const updatedCustomFilter: ICustomFilter =
        copyCustomFilter(adjustedCustomFilter);

      updatedCustomFilter.value =
        event.type === 'click' ? null : event.target.value;

      if (isList) {
        const updatedAttributeRootCustomFilter: ICustomFilter =
          copyCustomFilter(customFilter);

        updatedAttributeRootCustomFilter.sub_filter = updatedCustomFilter;

        onChange(updatedAttributeRootCustomFilter);
      } else {
        onChange(updatedCustomFilter);
      }
    },
    [adjustedCustomFilter, customFilter, isList, onChange],
  );

  const enumerationOptions: IOption<TETagCustomFilterEnumeration>[] = useMemo(
    (): IOption<TETagCustomFilterEnumeration>[] =>
      getEnumerationOptions(adjustedCustomFilter.attribute),
    [adjustedCustomFilter.attribute],
  );

  const {
    attributeValue,
    booleanValue,
    enumerationValues,
    filterType,
    listValue,
    showBooleanSelect,
    showEnumerationSelect,
    showStringInput,
    stringValue,
  } = useMemo(
    (): IAttributeValues =>
      getAttributeValues(adjustedCustomFilter, customFilter),
    [adjustedCustomFilter, customFilter],
  );

  return (
    <SeparatedRowLayout>
      {isList ? (
        <TaggedSelector<EList>
          isDisabled={isDisabled}
          onChange={handleListChange}
          options={listOptions}
          uidToValue={uidToList}
          value={listValue}
          valueToColour={listToColour}
          valueToDisplayString={listToDisplayString}
          valueToUid={listToUid}
        />
      ) : null}
      <AttributeSelect
        isDisabled={isDisabled}
        onChange={handleAttributeChange}
        value={attributeValue}
      />
      {showBooleanSelect ? (
        <BooleanEnumerationSelect
          allowClear={true}
          isDisabled={isDisabled}
          onChange={handleBooleanEnumerationChange}
          options={ETAG_BOOLEAN_OPTIONS}
          showSearch={true}
          value={booleanValue}
          valueToUid={booleanEnumerationToUid}
        />
      ) : null}
      {showEnumerationSelect ? (
        <EnumerationSelect
          allowClear={true}
          allowMultiple={true}
          filter={selectOptionLabelFilter}
          isDisabled={isDisabled}
          listHeight={ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE}
          maxTagCount='responsive'
          onChangeMultiple={handleEnumerationChange}
          options={enumerationOptions}
          placeholder='Values'
          showSearch={true}
          values={enumerationValues}
          valueToUid={enumerationToUid}
        />
      ) : null}
      {showStringInput ? (
        <SeparatedRowLayout>
          {filterType === undefined ? null : (
            <FilterSelector
              customFilterType={filterType}
              isDisabled={isDisabled}
              onChange={handleFilterChange}
            />
          )}
          <StringInput
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleStringInputChange}
            placeholder='Value'
            value={stringValue}
          />
        </SeparatedRowLayout>
      ) : null}
    </SeparatedRowLayout>
  );
};

export default CustomFilterCriteria;
