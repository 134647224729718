import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityExportPanel from 'components/molecules/ToEntityCsvExport/ToEntityCsvExportPanel';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IETagColumnData, IETagDataSet } from 'interfaces/ETag';
import { useState } from 'react';
import styled from 'styled-components';
import { TToEntityDataTableSummaryDataSet } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { TPlacement } from 'types/General';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ExportCsvIcon = styled(DeliveredProcedureOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityCsvExportProps {
  eTagColumnDatas: IETagColumnData[];
  eTagDataSets: IETagDataSet[];
  isDisabled?: boolean;
  panelPlacement?: TPlacement;
  selectedEndDate: ZonedDateTime | null;
  selectedStartDate: ZonedDateTime | null;
  summaryDataSets: TToEntityDataTableSummaryDataSet[];
  timeZone: TTimeZone;
  toEntityUiName: string;
  tooltipPlacement?: TPlacement;
}

const ToEntityCsvExport = (props: IToEntityCsvExportProps): JSX.Element => {
  const {
    eTagColumnDatas,
    eTagDataSets,
    isDisabled,
    panelPlacement,
    selectedEndDate,
    selectedStartDate,
    summaryDataSets,
    timeZone,
    toEntityUiName,
    tooltipPlacement,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };
  const handleSuccessfulExport = () => {
    setIsVisible(false);
  };

  const adjustedIsDisabled: boolean = isDisabled || eTagDataSets.length === 0;

  return (
    <Tooltip
      isDisabled={adjustedIsDisabled || isVisible}
      placement={tooltipPlacement}
      title='Export Table to CSV'
    >
      <AntDesignPopover
        content={
          <ToEntityExportPanel
            eTagColumnDatas={eTagColumnDatas}
            eTagDataSets={eTagDataSets}
            onSuccessfulExport={handleSuccessfulExport}
            selectedEndDate={selectedEndDate}
            selectedStartDate={selectedStartDate}
            summaryDataSets={summaryDataSets}
            timeZone={timeZone}
            toEntityUiName={toEntityUiName}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={handleVisibleChange}
        placement={panelPlacement}
        trigger='click'
        visible={isVisible}
      >
        <IconButton icon={<ExportCsvIcon />} isDisabled={adjustedIsDisabled} />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityCsvExport;
