import {
  IETagPhysicalSegmentsProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';

export const hasMultipleEnergyProfiles = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
): boolean =>
  physicalSegmentsProfiles !== null &&
  physicalSegmentsProfiles.find(
    (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile): boolean =>
      eTagPhysicalSegmentsProfile.physical_segments_profiles !== null &&
      ((eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission !==
        null &&
        eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission
          .transmission_segments !== null &&
        eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission.transmission_segments.find(
          (eTagTransmissionSegment: IETagTransmissionSegment): boolean =>
            (eTagTransmissionSegment.pod_energy_profile !== null &&
              eTagTransmissionSegment.pod_energy_profile.profile_id !== 1) ||
            (eTagTransmissionSegment.por_energy_profile !== null &&
              eTagTransmissionSegment.por_energy_profile.profile_id !== 1),
        ) !== undefined) ||
        (eTagPhysicalSegmentsProfile.physical_segments_profiles.load !== null &&
          eTagPhysicalSegmentsProfile.physical_segments_profiles.load
            .profile !== null &&
          eTagPhysicalSegmentsProfile.physical_segments_profiles.load.profile
            .profile_id !== 1)),
  ) !== undefined;
