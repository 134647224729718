import { Popover as AntDesignPopover } from 'antd';
import IconButton, {
  IIconButtonProps,
} from 'components/atoms/IconButton/IconButton';
import SetStateIcon from 'components/molecules/SetStateIcon/SetStateIcon';
import SetStatePanel from 'components/molecules/SetStatePanel/SetStatePanel';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  CURRENT_PENDING_REQUEST_ID,
  CURRENT_REQUEST_ID,
} from 'constants/Detail';
import { ERROR_RED, SUCCESS_GREEN, WARNING_ORANGE } from 'constants/styles';
import { EApprovalStatus } from 'enums/Approval';
import { ERequestStatus } from 'enums/ETag';
import { useEffect, useState } from 'react';
import { IDetailTransactionStatuses } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TNoteOption } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface ISetStateButtonProps extends IIconButtonProps {
  approvalStatus: EApprovalStatus;
}

const styleForApprovalStatus = (approvalStatus: EApprovalStatus): string => {
  const color: string =
    approvalStatus === EApprovalStatus.Approved
      ? SUCCESS_GREEN
      : approvalStatus === EApprovalStatus.Denied
      ? ERROR_RED
      : approvalStatus === EApprovalStatus.Study
      ? WARNING_ORANGE
      : '';
  return `
      color: ${color}; 
      background-color: ${color}11;
    `;
};

const SetStateButton = styled(IconButton)<ISetStateButtonProps>`
  ${(props) => styleForApprovalStatus(props.approvalStatus)}
`;

interface IProps {
  approvalRight: boolean | null;
  approvalStatus: EApprovalStatus;
  encodedPermissionsId: string;
  noteOptions: TNoteOption[];
  onPanelVisibleChange: (value: boolean) => void;
  requestId: number;
  tagPrimaryKey: TETagTagPrimaryKey | undefined;
  timeZone: TTimeZone;
  title: string;
  toEntityId: TToEntityId;
  transactionStatus: IDetailTransactionStatuses | undefined;
}

const RequestActionSetState = (props: IProps): JSX.Element => {
  const {
    approvalRight,
    approvalStatus,
    encodedPermissionsId,
    noteOptions,
    requestId,
    onPanelVisibleChange,
    tagPrimaryKey,
    timeZone,
    title,
    toEntityId,
    transactionStatus,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    onPanelVisibleChange(isVisible);
  }, [isVisible, onPanelVisibleChange]);

  const adjustedIsDisabled: boolean =
    approvalRight !== true ||
    isVisible ||
    requestId === CURRENT_REQUEST_ID ||
    requestId === CURRENT_PENDING_REQUEST_ID ||
    tagPrimaryKey === undefined ||
    transactionStatus === undefined ||
    transactionStatus.resolution_status !== ERequestStatus.Pending ||
    transactionStatus.act_on_by_time === null ||
    ZonedDateTime.now(timeZone).isAfter(
      ZonedDateTime.parseIso(transactionStatus.act_on_by_time, timeZone),
    );

  return (
    <Tooltip isDisabled={isVisible} title={title}>
      <AntDesignPopover
        content={
          <SetStatePanel
            noteOptions={noteOptions}
            notesRequired={approvalStatus !== EApprovalStatus.Approved}
            requestId={requestId}
            approvalStatus={approvalStatus}
            tagPrimaryKey={tagPrimaryKey}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        title={title}
        trigger='click'
        visible={isVisible}
      >
        <SetStateButton
          approvalStatus={approvalStatus}
          icon={<SetStateIcon approvalStatus={approvalStatus} />}
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            approvalStatus,
          ])}
          isDisabled={adjustedIsDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default RequestActionSetState;
