import ETagRow from 'components/molecules/ETagRow/ETagRow';
import HeaderCell from 'components/molecules/HeaderCell/HeaderCell';
import { IScroll } from 'interfaces/Component';
import { IETagDataSet } from 'interfaces/ETag';
import { TableComponents } from 'rc-table/lib/interface';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { getTagCountMessage } from 'utils/component';

const TagCount = styled.div`
  text-align: left;
  width: 100%;
`;

export const getScrollFrom = (htmlElement: HTMLElement): IScroll => ({
  clientHeight: htmlElement.clientHeight,
  scrollHeight: htmlElement.scrollHeight,
  scrollLeft: htmlElement.scrollLeft,
  scrollTop: htmlElement.scrollTop,
  scrollWidth: htmlElement.scrollWidth,
});
export const getCustomComponents = (
  id: string,
): TableComponents<IETagDataSet> => ({
  // Since we cannot just pass props to the HeaderCell via composition we use
  // various contexts to allow HeaderCell to interact with arraySorters and
  // arrayFilters.
  header: {
    cell: HeaderCell,
  },
  body: {
    row: ETagRow,
  },
});

export const getTagCountNode = (tagCount: number | undefined): ReactNode =>
  tagCount === undefined ? undefined : (
    <TagCount className='tag-count'>{getTagCountMessage(tagCount)}</TagCount>
  );

export const yesNoFromBool = (bool: boolean | null) =>
  bool === true ? 'Yes' : bool === false ? 'No' : '';
