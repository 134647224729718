import { Popover as AntDesignPopover } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToEntityETagCreatePanel from 'components/molecules/ToEntityETagCreatePanel/ToEntityETagCreatePanel';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import { checkPermissionsAndThen } from 'components/pages/LandingPage/helpers';
import { IToEntitiesPermissionId } from 'components/pages/LandingPage/types';
import { permissionsForIdAndToEntity } from 'hooks/usePermissions';
import { IToEntity } from 'interfaces/ToEntity';
import { useState } from 'react';
import { TTimeZone } from 'types/DateTime';

interface ICreateTagPopoverProps extends IToEntitiesPermissionId {
  selectedToEntity: IToEntity | undefined;
  timeZone: TTimeZone;
}

const CreateTagPopover = checkPermissionsAndThen(
  ({
    children,
    permissionId,
    selectedToEntity,
    timeZone,
    toEntities,
  }: ICreateTagPopoverProps): JSX.Element => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const toEntitiesInWhichUserCanCreateTags = toEntities.filter(
      (toEntity: IToEntity): boolean =>
        permissionsForIdAndToEntity(permissionId, toEntity).isExecutable,
    );

    return (
      <AntDesignPopover
        content={
          <ToEntityETagCreatePanel
            timeZone={timeZone}
            toEntityId={selectedToEntity?.to_entity}
          >
            <UserToEntitySelection
              filteredToEntities={toEntitiesInWhichUserCanCreateTags}
            />
            <SeparatedRowLayout>
              <div>Time Zone:</div>
              <UserTimeZoneSelector />
            </SeparatedRowLayout>
          </ToEntityETagCreatePanel>
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        title='Create New Tag'
        trigger='click'
        visible={isVisible}
      >
        {children}
      </AntDesignPopover>
    );
  },
);

export default CreateTagPopover;
