import {
  IETagEnergyProfileDetail,
  IETagPathEnergyProfile,
  IETagPathTransmissionAllocationProfile,
  IETagTemplate,
  IETagTransmissionAllocationProfileDetail,
} from 'interfaces/ETag';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getTemplateProfileStartDateTime = (
  eTagTemplate: IETagTemplate,
  timeZone: TTimeZone,
): ZonedDateTime => {
  const startOfCurrentDay: ZonedDateTime =
    ZonedDateTime.now(timeZone).startOf('day');
  let earliestStart: ZonedDateTime | undefined = undefined;

  if (eTagTemplate.tag !== null && eTagTemplate.tag.path_profile !== null) {
    if (eTagTemplate.tag.path_profile.energy_profiles !== null) {
      eTagTemplate.tag.path_profile.energy_profiles.forEach(
        (eTagPathEnergyProfile: IETagPathEnergyProfile) => {
          if (eTagPathEnergyProfile.energy_profile_details !== null) {
            eTagPathEnergyProfile.energy_profile_details.forEach(
              (eTagEnergyProfileDetail: IETagEnergyProfileDetail) => {
                if (eTagEnergyProfileDetail.start !== null) {
                  if (earliestStart === undefined) {
                    earliestStart = ZonedDateTime.parseIso(
                      eTagEnergyProfileDetail.start,
                      timeZone,
                    );
                  } else if (
                    earliestStart.isAfter(
                      ZonedDateTime.parseIso(
                        eTagEnergyProfileDetail.start,
                        timeZone,
                      ),
                    )
                  ) {
                    earliestStart = ZonedDateTime.parseIso(
                      eTagEnergyProfileDetail.start,
                      timeZone,
                    );
                  }
                }
              },
            );
          }
        },
      );
    }

    if (eTagTemplate.tag.path_profile.trans_alloc_profiles !== null) {
      eTagTemplate.tag.path_profile.trans_alloc_profiles.forEach(
        (
          eTagPathTransmissionAllocationProfile: IETagPathTransmissionAllocationProfile,
        ) => {
          if (
            eTagPathTransmissionAllocationProfile.trans_alloc_profile_details !==
            null
          ) {
            eTagPathTransmissionAllocationProfile.trans_alloc_profile_details.forEach(
              (
                eTagTransmissionAllocationProfileDetail: IETagTransmissionAllocationProfileDetail,
              ) => {
                if (eTagTransmissionAllocationProfileDetail.start !== null) {
                  if (earliestStart === undefined) {
                    earliestStart = ZonedDateTime.parseIso(
                      eTagTransmissionAllocationProfileDetail.start,
                      timeZone,
                    );
                  } else if (
                    earliestStart.isAfter(
                      ZonedDateTime.parseIso(
                        eTagTransmissionAllocationProfileDetail.start,
                        timeZone,
                      ),
                    )
                  ) {
                    earliestStart = ZonedDateTime.parseIso(
                      eTagTransmissionAllocationProfileDetail.start,
                      timeZone,
                    );
                  }
                }
              },
            );
          }
        },
      );
    }
  }

  return earliestStart === undefined
    ? startOfCurrentDay
    : startOfCurrentDay
        .withHour((earliestStart as ZonedDateTime).getHour())
        .withMinute((earliestStart as ZonedDateTime).getMinute());
};

export const eTagTemplateToUid = (eTagTemplate: IETagTemplate): string =>
  eTagTemplate.id;
