import { RollbackOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityETagWithdrawRequestPanel from 'components/molecules/RequestActionWithdraw/RequestActionWithdrawPanel';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  CURRENT_PENDING_REQUEST_ID,
  CURRENT_REQUEST_ID,
} from 'constants/Detail';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { ERequestStatus } from 'enums/ETag';
import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { useState } from 'react';
import { IDetailTransactionStatuses } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { toToEntityId } from 'utils/entity';
import { encodeIds } from 'utils/general';

const WithdrawRequestIcon = styled(RollbackOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IRequestActionWithdrawProps {
  defaultContactInfo?: IContactInfo;
  encodedPermissionsId: string;
  isDisabled?: boolean;
  requestId: number;
  securityKey: string;
  shouldContactInfoUpdate?: boolean;
  tagId: IETagTagId;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  transactionStatus: IDetailTransactionStatuses | undefined;
}

const RequestActionWithdraw = (
  props: IRequestActionWithdrawProps,
): JSX.Element => {
  const {
    defaultContactInfo,
    encodedPermissionsId,
    isDisabled,
    requestId,
    securityKey,
    shouldContactInfoUpdate,
    tagId,
    timeZone,
    toEntityId,
    transactionStatus,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const adjustedIsDisabled: boolean =
    isDisabled ||
    requestId === CURRENT_REQUEST_ID ||
    requestId === CURRENT_PENDING_REQUEST_ID ||
    transactionStatus === undefined ||
    (transactionStatus.correction_id !== null &&
      transactionStatus.correction_id > 0) ||
    transactionStatus.resolution_status === null ||
    transactionStatus.resolution_status !== ERequestStatus.Pending ||
    transactionStatus.requestor === null ||
    toToEntityId(transactionStatus.requestor) !== toEntityId;

  return (
    <Tooltip isDisabled={isDisabled || isVisible} title='Withdraw Request'>
      <AntDesignPopover
        content={
          <ToEntityETagWithdrawRequestPanel
            defaultContactInfo={defaultContactInfo}
            isContactInfoDisabled={isDisabled}
            request_id={requestId}
            securityKey={securityKey}
            shouldContactInfoUpdate={shouldContactInfoUpdate}
            tagId={tagId}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        title={`Withdraw Request ${requestId}`}
        trigger='click'
        visible={isVisible}
      />
      <IconButton
        encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
        icon={<WithdrawRequestIcon />}
        isDisabled={adjustedIsDisabled}
        onClick={() => setIsVisible(true)}
      />
    </Tooltip>
  );
};

export default RequestActionWithdraw;
