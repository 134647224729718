import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  eTagColumnDatasToCsvHeaderData,
  eTagDataSetsToCsvData,
} from 'components/molecules/ToEntityCsvExport/helpers';
import {
  ICsvHeader,
  TCsvData,
} from 'components/molecules/ToEntityCsvExport/types';
import { STANDARD_SPACING } from 'constants/styles';
import {
  FILENAME_DATE_TIME_FORMAT,
  FILENAME_SELECTED_DATE_TIME_FORMAT,
} from 'constants/time';
import { EActionState } from 'enums/General';
import { IETagColumnData, IETagDataSet } from 'interfaces/ETag';
import { ChangeEvent, useState } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { TToEntityDataTableSummaryDataSet } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { captureError } from 'utils/error';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Container = styled.div`
  padding: ${STANDARD_SPACING};
`;

const FilenameLabel = styled.span`
  width: 82px;
`;

const FilenameInput = styled(Input)`
  width: 321px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
`;

interface IToEntityExportPanelProps {
  eTagColumnDatas: IETagColumnData[];
  eTagDataSets: IETagDataSet[];
  onSuccessfulExport?: () => void;
  selectedEndDate: ZonedDateTime | null;
  selectedStartDate: ZonedDateTime | null;
  summaryDataSets: TToEntityDataTableSummaryDataSet[];
  toEntityUiName: string;
  timeZone: TTimeZone;
}

const ToEntityExportPanel = (props: IToEntityExportPanelProps): JSX.Element => {
  const {
    eTagDataSets,
    eTagColumnDatas,
    onSuccessfulExport,
    selectedEndDate,
    selectedStartDate,
    summaryDataSets,
    timeZone,
    toEntityUiName,
  } = props;
  const [csvHeaders, setCsvHeaders] = useState<ICsvHeader[]>([]);
  const [csvData, setCsvData] = useState<TCsvData>([]);
  const [filename, setFilename] = useState<string>(
    `Tag-Summary_${toEntityUiName}_${ZonedDateTime.now(timeZone).format(
      FILENAME_DATE_TIME_FORMAT,
    )}_${selectedStartDate?.format(
      FILENAME_SELECTED_DATE_TIME_FORMAT,
    )}-${selectedEndDate?.format(FILENAME_SELECTED_DATE_TIME_FORMAT)}`,
  );
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const handleFilenameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFilename(event.target.value);
  };

  const handleExport = async () => {
    try {
      setActionState(EActionState.Actioning);

      const { csvHeaders, eTagColumnDataMap } =
        eTagColumnDatasToCsvHeaderData(eTagColumnDatas);
      const csvData: TCsvData = eTagDataSetsToCsvData(
        eTagDataSets,
        eTagColumnDataMap,
        timeZone,
        summaryDataSets,
      );

      setCsvHeaders(csvHeaders);

      setCsvData(csvData);

      setActionState(EActionState.Succeeded);
    } catch (error: any) {
      captureError(error);

      setActionState(EActionState.Failed);
    }
  };

  const handleActionIconRemoved = () => {
    if (
      actionState === EActionState.Succeeded &&
      onSuccessfulExport !== undefined
    ) {
      onSuccessfulExport();
    }
  };

  return (
    <Container>
      <SeparatedRowLayout>
        <FilenameLabel>CSV Filename:</FilenameLabel>
        <FilenameInput
          onChange={handleFilenameInput}
          placeholder='Filename'
          value={filename}
        />
      </SeparatedRowLayout>
      <Actions>
        <CSVLink
          asyncOnClick={true}
          data={csvData}
          filename={`${filename}.csv`}
          headers={csvHeaders}
          onClick={handleExport}
        >
          <Button
            actionState={actionState}
            label='Export to CSV'
            isDisabled={isEmptyValue(filename)}
            isPrimary={true}
            onActionIconRemoved={handleActionIconRemoved}
          />
        </CSVLink>
      </Actions>
    </Container>
  );
};

export default ToEntityExportPanel;
