import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';

import { ReasonsEditableContext } from './EditableRow';

interface Item {
  index: number;
  value: string;
}

interface EditableCellProps {
  title: ReactNode;
  editable: boolean;
  children: ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  handleRemove: (record: Item) => void;
}

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const EditableCellValueWrap = styled.div`
  width: 100%;
  min-height: 1.5rem;
`;

interface EditableCellPropsExt extends EditableCellProps {
  encodedPermissionsId: string;
}

const EditableCell: FC<EditableCellPropsExt> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleRemove,
  encodedPermissionsId,
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(ReasonsEditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            min: 1,
            max: 128,
            message: `${title} is invalid.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <EditableCellValueWrap onClick={toggleEdit}>
        {children}
      </EditableCellValueWrap>
    );
  }

  return (
    <td>
      <Cell>
        {childNode}
        {editable && (
          <Tooltip title={'Remove reason'}>
            <IconButton
              icon={<MinusCircleOutlined />}
              onClick={() => handleRemove(record)}
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'CommonReasonNotesConfiguration:remove',
              ])}
              size={'small'}
            />
          </Tooltip>
        )}
      </Cell>
    </td>
  );
};

export default EditableCell;
