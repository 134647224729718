import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
} from 'constants/styles';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TToEntityUserStates } from 'reduxes/User/types';
import styled from 'styled-components';
import { TTimeZone } from '../../../types/DateTime';
import { AxiosResponse } from 'axios';
import { rowBackgroundColour, textColour } from '../../../utils/styles';
import { EActionState } from '../../../enums/General';
import { PRIMARY_KEY_REGEX } from '../../../constants/ETag';
import { IETagTagId } from '../../../interfaces/ETag';
import { retrieveTagFiles } from '../../../services/entitiy/tags';
import Select, { ISelectProps } from '../Select/Select';
import { ALL_TIME_ZONE_OPTIONS } from '../../../constants/time';
import useUserInfo from '../../../hooks/useUserInfo';
import { IOption } from '../../../interfaces/Component';
import { IDownloadDistributedXmlsResponse } from '../../../interfaces/General';

interface IStylesStatusContainerHeader {
  currentTheme: string;
}

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 500px;
  padding: ${STANDARD_SPACING};
`;

const StyledDownloadButtonContainer = styled.div`
  display: flex !important;
  margin-left: auto;
`;

const StyledStatus = styled.div`
  height: 280px;
  margin-top: 15px;
  width: 100%;
`;

const StyledStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow: auto;
  width: 100%;
`;

const StyledStatusContainerHeader = styled.div<IStylesStatusContainerHeader>`
  ${(props) => `background-color: ${rowBackgroundColour(props)}`};
  ${(props) => `color: ${textColour(props)}`};
  display: flex;
  height: 35px;
  margin-bottom: 5px;
`;

const StyledStatusContainerHeaderText = styled.span`
  margin: auto;
`;

const StyledDownloadButton = styled(Button)`
  margin-top: 10px;
`;

const StyledReportName = styled.span`
  ::before {
    content: '■';
    margin-right: 5px;
  }
  margin-left: 10px;
`;

const StyledTimeZoneSelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 137px;
`;

interface IToEntityETagTemplateCreatePopoverProps {
  currentTheme: string;
  userState?: TToEntityUserStates;
  toEntityId?: string;
  tagID?: IETagTagId;
  primaryKey?: string;
  timeZones?: TTimeZone[];
}

interface IDownloadDistributeXml {
  primaryKey: string;
  url: string;
}

const DownloadButtonPopOver = (
  props: IToEntityETagTemplateCreatePopoverProps,
): JSX.Element => {
  const { userState, currentTheme, toEntityId, tagID, primaryKey } = props;

  const [primaryKeyValue, setPrimaryKeyValue] = useState<string>('');
  const [requestId, setRequestId] = useState<string>('');
  const [selectedEntityId, setSelectedEntityId] = useState<string>('');
  const [selectedTimeZone, setSelectedTimeZone] = useState<
    TTimeZone | undefined
  >();

  const [downloadButtonEnabled, setDownloadButtonEnabled] = useState<boolean>();
  const [selectedEntityButtonIndex, setSelectedEntityButtonIndex] =
    useState<number>(0);
  const [reportData, setReportData] = useState<IDownloadDistributeXml[]>([]);
  const [downloadActionState, setDownloadActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const { toEntityUserStates } = useUserInfo();
  const [timeZoneOptions, setTimeZoneOptions] = useState<IOption<TTimeZone>[]>(
    [],
  );

  useEffect(() => {
    if (userState) {
      setSelectedEntityId(Object.keys(userState)[0]);
    }
    if (toEntityId) {
      setSelectedEntityId(toEntityId);
    }
    if (primaryKey) {
      setPrimaryKeyValue(primaryKey);
    }
    if (tagID && tagID.tag_primary_key) {
      setPrimaryKeyValue(tagID.tag_primary_key);
    }
  }, [userState, tagID, toEntityId, primaryKey]);

  useEffect(() => {
    if (toEntityUserStates && selectedEntityId && !selectedTimeZone) {
      const tz = toEntityUserStates[selectedEntityId]?.selectedTimeZone;
      if (tz) {
        setSelectedTimeZone(tz);
      }
    }
    if (PRIMARY_KEY_REGEX.test(primaryKeyValue)) {
      setDownloadButtonEnabled(true);
    } else {
      setDownloadButtonEnabled(false);
    }
  }, [primaryKeyValue, selectedTimeZone, selectedEntityId, toEntityUserStates]);

  useEffect(() => {
    if (toEntityUserStates) {
      const tz =
        toEntityUserStates[selectedEntityId]?.toEntityUiConfig?.timeZones;
      const tzOptions: IOption<TTimeZone>[] = [];
      tz?.forEach((tz) => {
        const tzOption = ALL_TIME_ZONE_OPTIONS.find(
          (option) => option.value === tz,
        );
        tzOptions.push(tzOption || { label: tz, value: tz });
      });
      setTimeZoneOptions(tzOptions || []);
    }
  }, [selectedEntityId, toEntityUserStates]);

  const handleEntityClick = useCallback(
    (entity: string, index: number) => {
      setSelectedEntityId(entity);
      setSelectedEntityButtonIndex(index);
    },
    [setSelectedEntityId],
  );

  const getEntities = useCallback(() => {
    const entities: JSX.Element[] = [];
    if (userState && !toEntityId) {
      Object.keys(userState).forEach((key, index) => {
        entities.push(
          <Button
            isPrimary={selectedEntityButtonIndex === index}
            key={key}
            label={key}
            onClick={() => handleEntityClick(key, index)}
          />,
        );
      });
    }

    if (toEntityId) {
      entities.push(
        <Button isPrimary={true} key={toEntityId} label={toEntityId} />,
      );
    }

    return entities;
  }, [handleEntityClick, selectedEntityButtonIndex, userState, toEntityId]);

  const handleDownloadClick = async () => {
    setDownloadActionState(EActionState.Actioning);
    const retrieveTagFilesResponse: AxiosResponse<IDownloadDistributedXmlsResponse> =
      await retrieveTagFiles(
        selectedEntityId,
        primaryKeyValue,
        requestId,
        selectedTimeZone,
      );
    const response =
      retrieveTagFilesResponse &&
      retrieveTagFilesResponse.data &&
      retrieveTagFilesResponse.data.response;
    if (response) {
      const existingElementIndex = reportData
        .map((i) => i.primaryKey)
        .indexOf(primaryKeyValue);
      if (reportData) {
        if (existingElementIndex > -1) {
          const data = reportData;
          data[existingElementIndex] = {
            primaryKey: (tagID && tagID.ui_tag_id) || primaryKeyValue,
            url: response.url || 'NA',
          };
          setReportData(data);
        } else {
          setReportData((reportData) => [
            ...reportData,
            {
              primaryKey: (tagID && tagID.ui_tag_id) || primaryKeyValue,
              url: response.url || 'NA',
            },
          ]);
        }
      }
    }
    setDownloadActionState(EActionState.NoAction);
  };

  const handlePrimaryKeyInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPrimaryKeyValue(e.currentTarget.value || '');
    },
    [setPrimaryKeyValue],
  );

  const handleRequestInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setRequestId(e.currentTarget.value || '');
    },
    [setRequestId],
  );

  const handleTimeZoneChange = useCallback(
    (timeZone: string | undefined) => {
      setSelectedTimeZone(timeZone as TTimeZone);
    },
    [setSelectedTimeZone],
  );

  const getReportData = useCallback(() => {
    const rows: JSX.Element[] = [];
    reportData.forEach((report) => {
      rows.push(
        <tr key={report.primaryKey}>
          <td>
            <StyledReportName>{report.primaryKey}</StyledReportName>
          </td>
          <td>
            {report.url !== 'NA' ? <a href={report.url}>Download</a> : 'NA'}
          </td>
        </tr>,
      );
    });

    return rows;
  }, [reportData]);

  return (
    <Layout theme={currentTheme}>
      <SeparatedRowLayout>{getEntities()}</SeparatedRowLayout>
      <SeparatedRowLayout>
        <StyledTimeZoneSelect
          onChange={handleTimeZoneChange}
          options={timeZoneOptions}
          value={selectedTimeZone}
          valueToUid={(value: string): string => value}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <Button label={'Tag Primary Key'} isDisabled={true} />
        <Button label={'='} isDisabled={true} />
        <Input
          onChange={handlePrimaryKeyInputChange}
          value={primaryKeyValue}
        ></Input>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <Button label={'Request #'} isDisabled={true} />
        <Button label={'='} isDisabled={true} />
        <Input onChange={handleRequestInputChange}></Input>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <StyledDownloadButtonContainer>
          <StyledDownloadButton
            actionState={downloadActionState}
            isDisabled={!downloadButtonEnabled}
            isPrimary={true}
            label={'Download XML files'}
            onClick={handleDownloadClick}
          />
        </StyledDownloadButtonContainer>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <StyledStatus>
          <StyledStatusContainerHeader currentTheme={currentTheme}>
            <StyledStatusContainerHeaderText>
              Download status
            </StyledStatusContainerHeaderText>
          </StyledStatusContainerHeader>
          <StyledStatusContainer>
            <table>
              <tbody>{getReportData()}</tbody>
            </table>
          </StyledStatusContainer>
        </StyledStatus>
      </SeparatedRowLayout>
    </Layout>
  );
};

export default DownloadButtonPopOver;
