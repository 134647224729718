import { List as AntDesignList } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToEntityAlertConfiguration from 'components/molecules/ToEntityAlertConfiguration/ToEntityAlertConfiguration';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { LIST_ROW_SHARED_STYLES, STANDARD_SPACING } from 'constants/styles';
import { IEditableAlertConfiguration } from 'interfaces/Alert';
import styled from 'styled-components';
import { TAlertRulesMap } from 'types/Alert';

const Container = styled.div`
  height: 200px;

  .ant-list {
    height: 100%;

    .ant-list-header {
      padding: 7px ${STANDARD_SPACING} ${STANDARD_SPACING};
    }

    .ant-spin-nested-loading {
      /* 36px is height of .ant-list-header */
      height: calc(100% - 36px);
      overflow: auto;
    }
  }
`;

const ListRow = styled.li`
  ${LIST_ROW_SHARED_STYLES}
`;

interface IProps {
  alertRulesMap: TAlertRulesMap;
  editableAlertConfigurations: IEditableAlertConfiguration[];
  setEditableAlertConfigurations: (
    editableAlertConfigurations: IEditableAlertConfiguration[],
  ) => void;
}

const ToEntityAlertConfigurator = (props: IProps) => {
  const {
    alertRulesMap,
    editableAlertConfigurations,
    setEditableAlertConfigurations,
  } = props;

  const handleChange = (
    changedEditableAlertConfiguration: IEditableAlertConfiguration,
  ) => {
    setEditableAlertConfigurations(
      editableAlertConfigurations.map(
        (
          editableAlertConfiguration: IEditableAlertConfiguration,
        ): IEditableAlertConfiguration =>
          editableAlertConfiguration.alert_rule_id ===
          changedEditableAlertConfiguration.alert_rule_id
            ? changedEditableAlertConfiguration
            : editableAlertConfiguration,
      ),
    );
  };

  const ListRenderItem = (item: IEditableAlertConfiguration): JSX.Element => (
    <ListRow>
      <ToEntityAlertConfiguration
        alertRule={alertRulesMap[item.alert_rule_id]}
        editableAlertConfiguration={item}
        onChange={handleChange}
      />
    </ListRow>
  );

  const totalSubscriptions: number = editableAlertConfigurations.filter(
    (editableAlertConfiguration: IEditableAlertConfiguration): boolean =>
      editableAlertConfiguration.subscribe_to_alerts,
  ).length;
  const totalConfigurations: number = editableAlertConfigurations.length;

  return (
    <Container>
      <AntDesignList
        bordered={true}
        dataSource={editableAlertConfigurations}
        header={
          <SeparatedRowLayout>
            <div>Alert Configurations</div>
            <Tooltip title='(Total Subcribed / Total Configurations)'>
              <div>
                ({totalSubscriptions} / {totalConfigurations})
              </div>
            </Tooltip>
          </SeparatedRowLayout>
        }
        renderItem={ListRenderItem}
      />
    </Container>
  );
};

export default ToEntityAlertConfigurator;
