import { EExternalLinks } from 'enums/General';
import { IExternalLink } from 'interfaces/General';
import { TMap } from 'types/General';

export const APP_ELEMENT_ID = 'app-root';
export const THEMES_INSERTION_POINT = 'styles-insertion-point';

export const BACKSPACE_KEYCODE = 8;
export const ENTER_KEYCODE = 13;

export const ALT_GRAPH_KEY = 'Alt-Graph';
export const ALT_KEY = 'Alt';
export const CONTROL_KEY = 'Control';
export const META_KEY = 'Meta';
export const ENTER_KEY = 'Enter';
export const SHIFT_KEY = 'Shift';
export const TAB_KEY = 'Tab';

export const ARROW_LEFT_KEY = 'ArrowLeft';
export const ARROW_RIGHT_KEY = 'ArrowRight';
export const ARROW_UP_KEY = 'ArrowUp';
export const ARROW_DOWN_KEY = 'ArrowDown';

export const TAB_CHARACTER = '\t';
export const CARRIAGE_RETURN = '\r';
export const NEW_LINE_CHARACTER = '\n';

export const NO_OP_HANDLER = async () => {};

export const LEFT_SEPARATOR_CLASS = 'left-separator';
export const RIGHT_SEPARATOR_CLASS = 'right-separator';

export const ID_KEY = 'id';

export const DIGIT_REGEX = /^\d+$/;
export const FLOAT_REGEX = /^\d+(\.\d*)?$/;

export const HTTP_AND_HTTPS_PREFIX_REGEX = /^https?:\/\//;

export const EXTERNAL_LINKS_MAP: TMap<EExternalLinks, IExternalLink> = {
  [EExternalLinks.UserGuide]: {
    label: 'User Guide',
    location:
      'https://confluence.powercosts.com/display/KB/PCI+e-Tag+Plus+App+User+Guide+-+1.+PCI+e-Tag+Plus+Landing+Page',
  },
  [EExternalLinks.SummaryGuide]: {
    label: 'User Guide',
    location:
      'https://confluence.powercosts.com/display/KB/PCI+e-Tag+Plus+App+User+Guide+-+2.+PCI+e-Tag+Plus+Summary+Screen',
  },
  [EExternalLinks.TemplateGuide]: {
    label: 'User Guide',
    location:
      'https://confluence.powercosts.com/display/KB/PCI+e-Tag+Plus+App+User+Guide+-+3.+PCI+e-Tag+Plus+Template+Screen',
  },
  [EExternalLinks.DetailGuide]: {
    label: 'User Guide',
    location:
      'https://confluence.powercosts.com/display/KB/PCI+e-Tag+Plus+App+User+Guide+-+4.+PCI+e-Tag+Plus+Detail+Screen',
  },
};
