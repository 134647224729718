import DetailView from 'components/molecules/DetailView/DetailView';
import SummaryInformationReview from 'components/organisms/SummaryInformationReview/SummaryInformationReview';
import { editSummaryInformationToDetailState } from 'components/organisms/SummaryInformationView/helpers';
import SummaryInformationEdit from 'components/organisms/SummaryInformationView/SummaryInformationEdit';
import { IEditSummaryInformation } from 'components/organisms/SummaryInformationView/types';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode, EViewResize } from 'enums/View';
import useInitialData from 'hooks/useInitialData';
import usePrevious from 'hooks/usePrevious';
import useSummaryInformationReview from 'hooks/useSummaryInformationReview';
import { IETagTagId } from 'interfaces/ETag';
import {
  ITagHeaderDataSet,
  ITagNoteDataSet,
  ITemplateDataSet,
} from 'interfaces/SummaryInformation';
import { IViewProps } from 'interfaces/View';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailEditETagDetail } from 'reduxes/Detail/actions';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';

const retrieveSummaryInformationViewState = (state: TRootState) => {
  const {
    active,
    approved_termination_time,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    end_date,
    group_name,
    isDetailDeleted,
    last_update_time,
    last_update_user,
    name,
    notes,
    pageMode,
    retrievingDetail,
    retrievingDistributedTagItems,
    start_date,
    tag_id,
    test_flag,
    transaction_type,
    updatingDetail,
    viewMode,
  } = state.detail.present;
  const isSummaryLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.Summary].retrieveState !==
      ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.Summary].retrieveState !==
      ERetreiveState.RetrievingCompleted;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    isSummaryLoading;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    active,
    approved_termination_time,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    end_date,
    group_name,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    isSummaryLoading,
    last_update_time,
    last_update_user,
    name,
    notes,
    pageMode,
    start_date,
    tag_id,
    test_flag,
    timeZone,
    transaction_type,
    viewMode,
  };
};

const SummaryInformationView = ({
  layoutGrid,
  preventAutoEdits,
  resize,
  viewId,
}: IViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    approved_termination_time,
    cc_list,
    contact_info,
    creator,
    description,
    end_date,
    group_name,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    isSummaryLoading,
    last_update_time,
    last_update_user,
    notes,
    pageMode,
    start_date,
    tag_id,
    test_flag,
    timeZone,
    transaction_type,
    viewMode,
  } = useSelector(retrieveSummaryInformationViewState);
  const previousIsDetailLoading: boolean | undefined =
    usePrevious(isDetailLoading);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);
  const previousIsSummaryLoading: boolean | undefined =
    usePrevious(isSummaryLoading);
  const previousPageMode: EPageMode | undefined = usePrevious(pageMode);

  const hasSummaryChanged = (): boolean =>
    (isDetailUpdating === false && previousIsDetailUpdating === true) ||
    (isSummaryLoading === false && previousIsSummaryLoading === true) ||
    ((viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate) &&
      isDetailLoading === false &&
      previousIsDetailLoading === true) ||
    previousPageMode !== pageMode;

  const {
    contactInfos,
    dateTimeDataSet,
    eTagTagIds,
    tagHeaderDataSet,
    tagNoteDataSet,
    templateDataSet,
  } = useSummaryInformationReview(
    approved_termination_time,
    cc_list,
    contact_info,
    creator,
    end_date,
    group_name,
    last_update_time,
    last_update_user,
    notes,
    start_date,
    tag_id,
    test_flag,
    timeZone,
    transaction_type,
  );

  const initialETagTagIds: IETagTagId[] | undefined = useInitialData(
    eTagTagIds,
    hasSummaryChanged,
  );

  const initialTemplateDataSet: ITemplateDataSet[] | undefined = useInitialData(
    templateDataSet,
    hasSummaryChanged,
  );

  const initialTagHeaderDataSet: ITagHeaderDataSet[] | undefined =
    useInitialData(tagHeaderDataSet, hasSummaryChanged);

  const initialTagNoteDataSet: ITagNoteDataSet[] | undefined = useInitialData(
    tagNoteDataSet,
    hasSummaryChanged,
  );

  const initialDescription: string | null | undefined = useInitialData(
    description,
    hasSummaryChanged,
  );

  const handleChange = useCallback(
    (editSummaryInformation: IEditSummaryInformation) =>
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editSummaryInformationToDetailState(
            editSummaryInformation,
          ),
        }),
      ),
    [dispatch],
  );

  return (
    <DetailView
      isLoading={isDetailLoading}
      layoutGrid={layoutGrid}
      resize={resize}
      title='Tag Summary'
      viewId={viewId}
      viewResizeSetting={
        viewMode === EViewMode.ReviewETagTemplate ||
        viewMode === EViewMode.EditETagTemplate
          ? EViewResize.Down
          : undefined
      }
    >
      {pageMode === EPageMode.Review ||
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection ? (
        <SummaryInformationReview
          contactInfos={contactInfos}
          dateTimeDataSet={dateTimeDataSet}
          description={description}
          eTagTagIds={eTagTagIds}
          tagHeaderDataSet={tagHeaderDataSet}
          tagNoteDataSet={tagNoteDataSet}
          templateDataSet={templateDataSet}
          viewMode={viewMode}
        />
      ) : pageMode === EPageMode.Edit ? (
        <SummaryInformationEdit
          contactInfos={contactInfos}
          dateTimeDataSet={dateTimeDataSet}
          description={description}
          eTagTagIds={eTagTagIds}
          initialDescription={initialDescription}
          initialETagTagIds={initialETagTagIds}
          initialTagHeaderDataSet={initialTagHeaderDataSet}
          initialTagNoteDataSet={initialTagNoteDataSet}
          initialTemplateDataSet={initialTemplateDataSet}
          isDisabled={isDetailDeleted || isDetailLoading || isDetailUpdating}
          preventAutoEdits={preventAutoEdits}
          previousIsDetailUpdating={previousIsDetailUpdating}
          tagHeaderDataSet={tagHeaderDataSet}
          tagNoteDataSet={tagNoteDataSet}
          templateDataSet={templateDataSet}
          onChange={handleChange}
          viewMode={viewMode}
        />
      ) : null}
    </DetailView>
  );
};

export default SummaryInformationView;
