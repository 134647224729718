import { DatePicker as AntDesignDatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { TDateTimePickerRef } from 'components/molecules/DateTimePicker/DateTimePicker';
import { IShowTime } from 'interfaces/Component';
// this is one of only a few places we allow moment
// See (utils/zonedDateTime.ts) for why.
// eslint-disable-next-line no-restricted-imports
import { Moment } from 'moment';
import { Ref, useMemo } from 'react';
import { TTimeZone } from 'types/DateTime';
import { TDisabledDateHandler } from 'types/General';
import {
  momentToZonedDateTime,
  toDisabledMoment,
  ZonedDateTime,
  zonedDateTimetoMoment,
} from 'utils/zonedDateTime';

export interface IZonedDateTimePickerProps
  extends Omit<
    DatePickerProps,
    'disabledDate' | 'onChange' | 'onSelect' | 'value'
  > {
  defaultPickerValueZoned?: ZonedDateTime | null;
  disabledDate?: TDisabledDateHandler;
  onChange?: (zonedDateTime: ZonedDateTime | null) => void;
  onSelect?: (zonedDateTime: ZonedDateTime) => void;
  ref?: Ref<TDateTimePickerRef>;
  showTime?: IShowTime;
  timeZone: TTimeZone;
  value: ZonedDateTime | null;
}

const ZonedDateTimePicker = (props: IZonedDateTimePickerProps): JSX.Element => {
  const {
    defaultPickerValueZoned,
    disabledDate,
    onChange,
    onSelect,
    showTime,
    timeZone,
    value,
    ...rest
  } = props;

  const disabledMoment = useMemo(
    () => toDisabledMoment(timeZone, disabledDate),
    [disabledDate, timeZone],
  );

  const onChangeMoment = useMemo(
    () =>
      onChange === undefined
        ? undefined
        : (m: Moment | null): void =>
            onChange(momentToZonedDateTime(timeZone, m)),
    [onChange, timeZone],
  );

  const onSelectMoment = useMemo(
    () =>
      onSelect === undefined
        ? undefined
        : (datetime: Moment): void =>
            onSelect(ZonedDateTime.fromMoment(datetime, timeZone)),
    [onSelect, timeZone],
  );

  const showTimeMoment = useMemo(() => {
    if (showTime === undefined) {
      return undefined;
    }

    const { defaultValue, format, hourStep, minuteStep, secondStep } = showTime;

    if (hourStep === 0) {
      throw new Error('Invalid hourStep: 0');
    } else if (minuteStep === 0) {
      throw new Error('Invalid minuteStep: 0');
    } else if (secondStep === 0) {
      throw new Error('Invalid secondStep: 0');
    }

    return {
      defaultValue:
        defaultValue === undefined
          ? undefined
          : (defaultValue as ZonedDateTime).asMoment(),
      format,
      hourStep,
      minuteStep,
      secondStep,
    };
  }, [showTime]);

  const valueMoment = useMemo(() => zonedDateTimetoMoment(value), [value]);

  const defaultPickerValueMoment = useMemo(
    () =>
      zonedDateTimetoMoment(
        defaultPickerValueZoned ? defaultPickerValueZoned : null,
      ) ?? undefined,
    [defaultPickerValueZoned],
  );

  return (
    <AntDesignDatePicker
      {...rest}
      defaultPickerValue={defaultPickerValueMoment}
      disabledDate={disabledMoment}
      onChange={onChangeMoment}
      onSelect={onSelectMoment}
      showTime={showTimeMoment}
      value={valueMoment}
    />
  );
};

export default ZonedDateTimePicker;
