import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { EProfileType } from 'enums/ETag';
import { IETagTemplate, IETagTemplateCopyResponse } from 'interfaces/ETag';
import {
  TEMPLATES_FROM_DISTRIBUTED_ROUTE,
  TEMPLATES_FROM_DRAFT_ROUTE,
  TEMPLATES_FROM_TEMPLATE_ROUTE,
  TEMPLATES_ROUTE,
  TEMPLATES_SUMMARY_ROUTE,
  TEMPLATES_TEMPLATE_GROUPS_ROUTE,
  TEMPLATES_TEMPLATE_NAMES_ROUTE,
  TEMPLATES_TEMPLATE_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TETagTagPrimaryKey, TETagTemplateId } from 'types/ETag';
import { TRequestBodyFilter } from 'types/Filter';
import { TToEntityId } from 'types/ToEntity';

export const retrieveTemplates = async (
  toEntityId: TToEntityId,
  requestBodyFilters?: TRequestBodyFilter,
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: requestBodyFilters,
  };

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_TEMPLATE_NAMES_ROUTE(toEntityId),
    options,
  );
};

export const retrieveTemplateGroups = async (
  toEntityId: TToEntityId,
  requestBodyFilters?: TRequestBodyFilter,
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: requestBodyFilters,
  };

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_TEMPLATE_GROUPS_ROUTE(toEntityId),
    options,
  );
};

export const deleteETagTemplate = async (
  toEntityId: TToEntityId,
  templateId: TETagTemplateId,
): Promise<AxiosResponse> => {
  return await API.del(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_TEMPLATE_ROUTE(toEntityId, templateId),
    DEFAULT_OPTIONS,
  );
};

export const createTemplateFromDistributed = async (
  toEntityId: TToEntityId,
  tag_primary_key: TETagTagPrimaryKey,
  name: string,
  includeEnergyProfiles: boolean,
  includeTransmissionProfiles: boolean,
  groupName?: string,
  description?: string,
): Promise<AxiosResponse> => {
  const profileType: EProfileType[] = [];

  if (includeEnergyProfiles) {
    profileType.push(EProfileType.Energy);
  }

  if (includeTransmissionProfiles) {
    profileType.push(EProfileType.Transmission);
  }

  return await API.put(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_FROM_DISTRIBUTED_ROUTE(
      toEntityId,
      tag_primary_key,
      name,
      profileType,
      groupName,
      description,
    ),
    DEFAULT_OPTIONS,
  );
};

export const createTemplateFromDraft = async (
  toEntityId: TToEntityId,
  tag_primary_key: TETagTagPrimaryKey,
  name: string,
  groupName?: string,
  description?: string,
): Promise<AxiosResponse> => {
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_FROM_DRAFT_ROUTE(
      toEntityId,
      tag_primary_key,
      name,
      groupName,
      description,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagTemplate = async (
  toEntityId: TToEntityId,
  templateId: TETagTemplateId,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_TEMPLATE_ROUTE(toEntityId, templateId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveBulkETagTemplates = async (
  toEntityId: TToEntityId,
  templateIds: TETagTemplateId[],
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_SUMMARY_ROUTE(toEntityId, templateIds),
    DEFAULT_OPTIONS,
  );
};

export const updateETagTemplate = async (
  toEntityId: TToEntityId,
  eTagTemplate: IETagTemplate,
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: eTagTemplate,
  };
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_ROUTE(toEntityId),
    options,
  );
};

export const copyETagTemplate = async (
  toEntityId: TToEntityId,
  template: IETagTemplate,
  name: string,
  groupName?: string,
  description?: string,
): Promise<AxiosResponse<IETagTemplateCopyResponse>> => {
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TEMPLATES_FROM_TEMPLATE_ROUTE(
      toEntityId,
      template,
      name,
      groupName,
      description,
    ),
    DEFAULT_OPTIONS,
  );
};
