import {
  CaretDownOutlined,
  CaretUpOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { OPERATOR_OPTIONS } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/constants';
import { updateCustomFilterForOperation } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilter/helpers';
import OperationSelector from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilter/OperationSelector';
import CustomFilterCriteria from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/CustomFilterCriteria';
import {
  isEnumerationCustomFilterAttribute,
  isEnumerationListCustomFilterAttribute,
  isMiscInfoListCustomFilterAttribute,
  isStringListCustomFilterAttribute,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/helpers';
import CustomFilterOperator from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterOperator/CustomFilterOperator';
import {
  isBinaryOperator,
  isOperand,
  isUnaryOperator,
  operatorToColour,
  operatorToDisplayString,
  operatorToUid,
  typeToOperator,
  uidToOperator,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/helpers';
import TaggedSelector from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/TaggedSelector/TaggedSelector';
import {
  EOperation,
  EOperator,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import {
  BUTTON_ICON_DIMENSIONS,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { ECustomFilterType } from 'enums/Filter';
import { ICustomFilter } from 'interfaces/Filter';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { copyCustomFilter } from 'utils/filter';

const RemoveIcon = styled(MinusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const Container = styled.div`
  padding: ${STANDARD_SPACING_VALUE / 2}px 0;
`;

const CollapseExpandButton = styled(IconButton)`
  &&& {
    margin-right: 0;
  }
`;

interface ICustomFilterProps {
  customFilter: ICustomFilter;
  isDisabled?: boolean;
  onChange: (customFilter: ICustomFilter) => void;
  onRemove: () => void;
}

const CustomFilter = ({
  customFilter,
  isDisabled,
  onChange,
  onRemove,
}: ICustomFilterProps): JSX.Element => {
  const { attribute, sub_filter, type } = customFilter;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(
      (previousIsCollapsed: boolean): boolean => !previousIsCollapsed,
    );
  }, []);

  const handleOperatorChange = useCallback(
    (operator: EOperator) => {
      const updatedCustomFilter: ICustomFilter = copyCustomFilter(customFilter);
      const isAnd: boolean = operator === EOperator.And;
      const isNot: boolean = operator === EOperator.Not;
      const isOr: boolean = operator === EOperator.Or;

      if (isAnd || isOr) {
        updatedCustomFilter.type = isAnd
          ? ECustomFilterType.And
          : ECustomFilterType.Or;

        if (updatedCustomFilter.sub_filter_list === null) {
          updatedCustomFilter.sub_filter_list = [];
        }

        if (updatedCustomFilter.sub_filter !== null) {
          updatedCustomFilter.sub_filter_list.push(
            updatedCustomFilter.sub_filter,
          );
        }

        updatedCustomFilter.sub_filter = null;
      } else if (isNot) {
        updatedCustomFilter.type = ECustomFilterType.Not;
        updatedCustomFilter.sub_filter = null;

        if (updatedCustomFilter.sub_filter_list !== null) {
          const subFilter: ICustomFilter | undefined =
            updatedCustomFilter.sub_filter_list[0];

          if (subFilter !== undefined) {
            updatedCustomFilter.sub_filter = subFilter;
          }
        }

        updatedCustomFilter.sub_filter_list = null;
      } else {
        throw new Error(`Invalid operator: ${operator}`);
      }

      onChange(updatedCustomFilter);
    },
    [customFilter, onChange],
  );

  const handleAdd = useCallback(
    (operation: EOperation) => {
      onChange(updateCustomFilterForOperation(customFilter, operation));
    },
    [customFilter, onChange],
  );

  const operator: EOperator | undefined = useMemo(
    (): EOperator | undefined => typeToOperator(type),
    [type],
  );

  const isEnumerationAttribute: boolean =
    attribute === null ? false : isEnumerationCustomFilterAttribute(attribute);
  const isEnumerationListAttribute: boolean =
    attribute === null
      ? false
      : isEnumerationListCustomFilterAttribute(attribute);
  const isMiscInfoListAttribute: boolean =
    attribute === null ? false : isMiscInfoListCustomFilterAttribute(attribute);
  const isStringListAttribute: boolean =
    attribute === null ? false : isStringListCustomFilterAttribute(attribute);
  const isListAttribute: boolean =
    isEnumerationListAttribute ||
    isMiscInfoListAttribute ||
    isStringListAttribute;
  const isUnaryOperatorType: boolean = isUnaryOperator(type);
  const isOperatorType: boolean =
    (isBinaryOperator(type) || isUnaryOperatorType) && !isEnumerationAttribute;
  const isOperandType: boolean = isOperand(type);
  const hideOperationSelector: boolean =
    isOperandType || isEnumerationAttribute || isListAttribute;
  const hideOperatorSelector: boolean =
    operator === undefined || isEnumerationAttribute;
  const allowBeforeOnly: boolean = isUnaryOperatorType && sub_filter !== null;

  return (
    <Container>
      <SeparatedRowLayout>
        {isOperatorType ? (
          <CollapseExpandButton
            icon={isCollapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
            isContained={false}
            isDisabled={isDisabled}
            noBorder={true}
            onClick={handleCollapse}
            transparentBackground={true}
          />
        ) : null}
        {hideOperationSelector ? null : (
          <OperationSelector
            allowBefore={!hideOperatorSelector}
            allowBeforeOnly={allowBeforeOnly}
            isDisabled={isDisabled}
            onChange={handleAdd}
          />
        )}
        {hideOperatorSelector ? null : (
          <TaggedSelector<EOperator>
            isDisabled={isDisabled}
            onChange={handleOperatorChange}
            options={OPERATOR_OPTIONS}
            uidToValue={uidToOperator}
            value={operator}
            valueToColour={operatorToColour}
            valueToDisplayString={operatorToDisplayString}
            valueToUid={operatorToUid}
          />
        )}
        {isOperandType || isEnumerationAttribute || isListAttribute ? (
          <CustomFilterCriteria
            customFilter={customFilter}
            isList={isListAttribute}
            onChange={onChange}
          />
        ) : null}
        {type === null ? null : (
          <IconButton
            icon={<RemoveIcon />}
            isContained={true}
            isDisabled={isDisabled}
            noBorder={true}
            onClick={onRemove}
            transparentBackground={true}
          />
        )}
      </SeparatedRowLayout>
      {isOperatorType && !isCollapsed ? (
        <CustomFilterOperator
          customFilter={customFilter}
          isHalfPadding={hideOperationSelector}
          onChange={onChange}
        />
      ) : null}
    </Container>
  );
};

export default CustomFilter;
