import { BellOutlined, MinusOutlined, SoundOutlined } from '@ant-design/icons';
import { EAlertSound } from 'enums/Alert';

interface IAlertSoundProps {
  sound: EAlertSound;
}

const AlertSoundIcon = (props: IAlertSoundProps): JSX.Element => {
  const { sound } = props;
  let icon: JSX.Element;

  switch (sound) {
    case EAlertSound.Beep: {
      icon = <BellOutlined className='anticon' />;
      break;
    }
    case EAlertSound.Alarm: {
      icon = <SoundOutlined className='anticon' />;
      break;
    }
    default: {
      icon = <MinusOutlined className='anticon' />;
      break;
    }
  }
  return icon;
};

export default AlertSoundIcon;
