import { InputNumber as AntDesignInputNumber } from 'antd';
import {
  EXPANDED_INPUT_HEIGHT_VALUE,
  EXPANSION_TRANSITION_IN_SECONDS_VALUE,
  FLOAT_BOX_SHADOW,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import { IExpandableProps, IValueChanged } from 'interfaces/Component';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { filterSpecialCharacters, isEmptyValue } from 'utils/general';
import {
  inputBackground,
  inputBackgroundExpand,
  inputValueChanged,
} from 'utils/styles';

interface IExpandableInputNumberProps extends IExpandableProps, IValueChanged {
  hideArrows?: boolean;
}

export const Wrapper = styled.div<IExpandableInputNumberProps>`
  height: ${INPUT_HEIGHT_VALUE}px;

  .ant-input-number {
    align-items: center;
    display: flex;
    flex-direction: row;

    ${(props) => inputBackground(props)}
    ${(props) => inputValueChanged(props)}
  }

  ${(props) =>
    props.shouldExpand
      ? `
    .ant-input-number {
      ${inputBackgroundExpand(props)}
      transition: ${EXPANSION_TRANSITION_IN_SECONDS_VALUE}s;
      transition-timing-function: ease;
      width: 100%;
    }

    .ant-input-number-focused {
      box-shadow: ${FLOAT_BOX_SHADOW};
      height: ${EXPANDED_INPUT_HEIGHT_VALUE}px;
      width: 200%;
    }
  `
      : ''}

  ${(props) =>
    props.hideArrows
      ? `
    .ant-input-number-handler-wrap {
      display: none;
    }
  `
      : ''}
`;

export interface IInputNumberProps {
  className?: string;
  hideArrows?: boolean;
  initialValue?: number | null;
  isDisabled?: boolean;
  max?: number;
  min?: number;
  onChange?: (_number: number | undefined) => void;
  placeholder?: string;
  preventSpecialCharacters?: boolean;
  shouldExpand?: boolean;
  step?: number;
  value?: number | null;
}

const InputNumber = (props: IInputNumberProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    className,
    hideArrows,
    initialValue,
    isDisabled,
    max,
    min,
    onChange,
    placeholder,
    preventSpecialCharacters,
    shouldExpand,
    step,
    value,
  } = props;

  const handleChange = (value: string | number | undefined | null) => {
    if (onChange !== undefined) {
      onChange(isEmptyValue(value) ? undefined : Number(value));
    }
  };

  return (
    <Wrapper
      className={className}
      currentTheme={currentTheme!}
      hideArrows={hideArrows}
      shouldExpand={shouldExpand}
      valueChanged={initialValue !== undefined && initialValue !== value}
    >
      <AntDesignInputNumber
        disabled={isDisabled}
        max={max}
        min={min}
        onChange={handleChange}
        onKeyDown={
          preventSpecialCharacters ? filterSpecialCharacters : undefined
        }
        placeholder={placeholder}
        step={step}
        type='number'
        value={value === null ? undefined : value}
      />
    </Wrapper>
  );
};

export default InputNumber;
