import { VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH } from 'constants/styles';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import {
  getColumnEnergyProductRender,
  getColumnEntityInfoRender,
  getColumnMiscInfosRender,
  getColumnRender,
} from 'utils/views';

const getOasisTitle = (record: IETagTransmissionAllocation): string =>
  `Physical Path ${record.physical_segment_ref} Misc Info`;

export const getOasisInfoColumns = (
  isUnconstrained: boolean,
  handleExpandMiscInfos?: (record: IETagTransmissionAllocation) => void,
): IViewDataTableColumn<IETagTransmissionAllocation>[] => [
  {
    dataIndex: 'physical_segment_ref',
    render: getColumnRender(isUnconstrained),
    title: 'PID',
  },
  {
    dataIndex: 'contract_number',
    render: getColumnRender(isUnconstrained),
    title: 'OASIS',
    width: '20px',
  },
  {
    dataIndex: 'trans_product_ref',
    render: getColumnEnergyProductRender(),
    title: 'Product',
  },
  {
    dataIndex: 'trans_alloc_customer_code',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'Transmission Customer',
  },
  {
    dataIndex: 'nits_resource',
    render: getColumnRender(isUnconstrained),
    title: 'NITS Resource',
  },
  {
    dataIndex: 'misc_infos',
    render: getColumnMiscInfosRender(
      isUnconstrained,
      getOasisTitle,
      handleExpandMiscInfos,
    ),
    title: 'Misc Info',
    width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  },
];
