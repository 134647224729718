import { Card as AntDesignCard } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  getTagHeaderColumns,
  getTagNoteColumns,
} from 'components/organisms/SummaryInformationReview/helpers';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { EViewMode } from 'enums/View';
import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  ITagDateTimeDataSet,
  ITagHeaderDataSet,
  ITagNoteDataSet,
  ITemplateDataSet,
} from 'interfaces/SummaryInformation';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import styled from 'styled-components';
import {
  getContactColumns,
  getDateTimeColumns,
  getTagTemplateColumns,
  getTemplateColumns,
} from 'utils/SummaryInformation';
import { getETagTagIdColumns } from 'utils/views';

const TEMPLATE_DESCRIPTION_LABEL = 'Template Description: ';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  height: 100%;

  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const TemplateDescription = styled(AntDesignCard)`
  flex-shrink: 0;
  min-height: 24px;

  > .ant-card-body {
    line-height: 16px;
    padding: 4px;

    span {
      font-weight: 500;
    }
  }
`;

// Specialize the DataTable component
const TagDataTable = styled(
  (props: IDataTableProps<IViewDataTableColumn<IETagTagId>, IETagTagId>) =>
    DataTable<IViewDataTableColumn<IETagTagId>, IETagTagId>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const DateTimeDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITagDateTimeDataSet>,
      ITagDateTimeDataSet
    >,
  ) =>
    DataTable<IViewDataTableColumn<ITagDateTimeDataSet>, ITagDateTimeDataSet>(
      props,
    ),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const TemplateDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITemplateDataSet>,
      ITemplateDataSet
    >,
  ) =>
    DataTable<IViewDataTableColumn<ITemplateDataSet>, ITemplateDataSet>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const TagHeaderDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITagHeaderDataSet>,
      ITagHeaderDataSet
    >,
  ) =>
    DataTable<IViewDataTableColumn<ITagHeaderDataSet>, ITagHeaderDataSet>(
      props,
    ),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const ContactDataTable = styled(
  (props: IDataTableProps<IViewDataTableColumn<IContactInfo>, IContactInfo>) =>
    DataTable<IViewDataTableColumn<IContactInfo>, IContactInfo>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const TagNoteDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITagNoteDataSet>,
      ITagNoteDataSet
    >,
  ) => DataTable<IViewDataTableColumn<ITagNoteDataSet>, ITagNoteDataSet>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

interface ISummaryInformationReviewProps {
  contactInfos: IContactInfo[];
  dateTimeDataSet: ITagDateTimeDataSet[];
  description: string | null;
  eTagTagIds: IETagTagId[];
  isRowLayout?: boolean;
  isUnconstrained?: boolean;
  tagHeaderDataSet: ITagHeaderDataSet[];
  tagNoteDataSet: ITagNoteDataSet[];
  templateDataSet: ITemplateDataSet[];
  viewMode: EViewMode;
}

const SummaryInformationReview = ({
  contactInfos,
  dateTimeDataSet,
  description,
  eTagTagIds,
  isRowLayout = false,
  isUnconstrained = false,
  tagHeaderDataSet,
  tagNoteDataSet,
  templateDataSet,
  viewMode,
}: ISummaryInformationReviewProps): JSX.Element => {
  const templateColumns: IViewDataTableColumn<ITemplateDataSet>[] = useMemo(
    () => getTemplateColumns(isUnconstrained),
    [isUnconstrained],
  );

  const tagTemplateColumns: IViewDataTableColumn<IETagTagId>[] = useMemo(
    () => getTagTemplateColumns(isUnconstrained),
    [isUnconstrained],
  );

  const eTagTagIdColumns: IViewDataTableColumn<IETagTagId>[] = useMemo(
    () => getETagTagIdColumns(isUnconstrained),
    [isUnconstrained],
  );

  const dateTimeColumns: IViewDataTableColumn<ITagDateTimeDataSet>[] = useMemo(
    () => getDateTimeColumns(isUnconstrained),
    [isUnconstrained],
  );

  const tagHeaderColumns: IViewDataTableColumn<ITagHeaderDataSet>[] = useMemo(
    () => getTagHeaderColumns(isUnconstrained),
    [isUnconstrained],
  );

  const contactColumns: IViewDataTableColumn<IContactInfo>[] = useMemo(
    () => getContactColumns(isUnconstrained),
    [isUnconstrained],
  );

  const tagNoteColumns: IViewDataTableColumn<ITagNoteDataSet>[] = useMemo(
    () => getTagNoteColumns(isUnconstrained),
    [isUnconstrained],
  );

  const tagAndDateTime = useMemo(
    () => (
      <>
        <TagDataTable
          columns={eTagTagIdColumns}
          data={eTagTagIds}
          pagination={false}
          tableLayout='fixed'
        />
        <DateTimeDataTable
          columns={dateTimeColumns}
          data={dateTimeDataSet}
          pagination={false}
          tableLayout='fixed'
        />
      </>
    ),
    [dateTimeColumns, dateTimeDataSet, eTagTagIdColumns, eTagTagIds],
  );

  const tagHeaderAndContact = useMemo(
    () => (
      <>
        <TagHeaderDataTable
          columns={tagHeaderColumns}
          data={tagHeaderDataSet}
          pagination={false}
          rowKey='transaction_type'
          tableLayout='fixed'
        />
        <ContactDataTable
          columns={contactColumns}
          data={contactInfos}
          pagination={false}
          rowKey='contact'
          tableLayout='fixed'
        />
      </>
    ),
    [contactColumns, contactInfos, tagHeaderColumns, tagHeaderDataSet],
  );

  return (
    <Layout>
      {viewMode === EViewMode.None ? null : viewMode ===
        EViewMode.ReviewETagTemplate ? (
        <>
          <TemplateDataTable
            columns={templateColumns}
            data={templateDataSet}
            pagination={false}
            rowKey='creator'
            tableLayout='fixed'
          />
          <TemplateDescription bordered={false}>
            <span>{TEMPLATE_DESCRIPTION_LABEL}</span>
            {description}
          </TemplateDescription>
          <TagDataTable
            columns={tagTemplateColumns}
            data={eTagTagIds}
            pagination={false}
            tableLayout='fixed'
          />
        </>
      ) : isRowLayout === false ? (
        tagAndDateTime
      ) : (
        <SeparatedRowLayout>{tagAndDateTime}</SeparatedRowLayout>
      )}
      {isRowLayout === false ? (
        tagHeaderAndContact
      ) : (
        <SeparatedRowLayout>{tagHeaderAndContact}</SeparatedRowLayout>
      )}
      <TagNoteDataTable
        columns={tagNoteColumns}
        data={tagNoteDataSet}
        pagination={false}
        tableLayout='fixed'
      />
    </Layout>
  );
};

export default SummaryInformationReview;
