import { EMarketInfoMarket } from 'enums/ETag';
import { EPageMode } from 'enums/Page';
import useInitialData from 'hooks/useInitialData';
import usePrevious from 'hooks/usePrevious';
import { IAdjustedMarketInfos, IMarketInfos } from 'interfaces/Detail';
import { IETagMarketInfo } from 'interfaces/ETag';
import { useCallback, useMemo } from 'react';
import { getInitialMarketData } from 'utils/detail';
import { getMarketDetailOptionForMarket } from 'utils/eTag';
import { ZonedDateTime } from 'utils/zonedDateTime';

const useMarketInfos = (
  marketInfoMarkets: EMarketInfoMarket[],
  marketInfos: IETagMarketInfo[],
  isEditable: boolean,
  isDetailLoading: boolean,
  isDetailUpdating: boolean,
  pageMode: EPageMode,
  start_date: string | null,
): IMarketInfos => {
  const previousIsDetailLoading: boolean | undefined =
    usePrevious<boolean>(isDetailLoading);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious<boolean>(isDetailUpdating);
  const previousPageMode: EPageMode | undefined =
    usePrevious<EPageMode>(pageMode);

  const { adjustedMarketInfos, unchangedMarketInfos }: IAdjustedMarketInfos =
    useMemo((): IAdjustedMarketInfos => {
      const adjustedMarketInfos: IAdjustedMarketInfos = {
        adjustedMarketInfos: marketInfos,
        unchangedMarketInfos: marketInfos,
      };

      let allMarketsIncluded: boolean = true;

      adjustedMarketInfos.adjustedMarketInfos = [];
      adjustedMarketInfos.unchangedMarketInfos = [];

      for (let i: number = 0; i < marketInfoMarkets.length; i += 1) {
        const availableMarket: EMarketInfoMarket = marketInfoMarkets[i];
        const marketInfo: IETagMarketInfo | undefined = marketInfos.find(
          (eTagMarketInfo: IETagMarketInfo): boolean =>
            eTagMarketInfo.market_info_market === availableMarket,
        );

        if (marketInfo === undefined) {
          adjustedMarketInfos.adjustedMarketInfos.push({
            data: getInitialMarketData(
              availableMarket,
              start_date
                ? ZonedDateTime.parseIso(
                    start_date,
                    getMarketDetailOptionForMarket(availableMarket).value
                      .timeZone,
                  )
                : ZonedDateTime.now(
                    getMarketDetailOptionForMarket(availableMarket).value
                      .timeZone,
                  ).startOf('day'),
            ),
            market_info_market: availableMarket,
          });

          allMarketsIncluded = false;
        } else {
          adjustedMarketInfos.adjustedMarketInfos.push(marketInfo);
          adjustedMarketInfos.unchangedMarketInfos.push(marketInfo);
        }
      }

      if (allMarketsIncluded) {
        adjustedMarketInfos.adjustedMarketInfos = marketInfos;
      }

      return adjustedMarketInfos;
    }, [marketInfoMarkets, marketInfos, start_date]);

  const hasMarketInfosChanged = useCallback(
    (): boolean =>
      (isDetailUpdating === false && previousIsDetailUpdating === true) ||
      (isDetailLoading === false && previousIsDetailLoading === true) ||
      previousPageMode !== pageMode,
    [
      isDetailLoading,
      isDetailUpdating,
      pageMode,
      previousIsDetailLoading,
      previousIsDetailUpdating,
      previousPageMode,
    ],
  );

  const initialMarketInfos: IETagMarketInfo[] | undefined = useInitialData<
    IETagMarketInfo[]
  >(unchangedMarketInfos, hasMarketInfosChanged);

  return isEditable && !isDetailLoading
    ? {
        adjustedMarketInfos,
        initialMarketInfos,
      }
    : {
        adjustedMarketInfos: marketInfos,
        initialMarketInfos: marketInfos,
      };
};

export default useMarketInfos;
