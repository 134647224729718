import { notification as AntDesignNotification } from 'antd';
import { AxiosResponse } from 'axios';
import {
  addWithAlertsQueueClass,
  numberOfOpen,
} from 'components/hocs/withAlertsQueue/helpers';
import { IManagedAlert } from 'components/hocs/withAlertsQueue/types';
import AlertBody from 'components/molecules/AlertBody/AlertBody';
import AlertTitle from 'components/molecules/AlertTitle/AlertTitle';
import { ALERT_WIDTH_VALUE } from 'constants/Alert';
import useUserInfo from 'hooks/useUserInfo';
import { IAlertsAcknowledgeResponse, IConfiguredAlert } from 'interfaces/Alert';
import { ComponentType, CSSProperties, useRef } from 'react';
import { IToEntityUserState } from 'reduxes/User/types';
import { acknowledgeAlerts } from 'services/alert/alerts';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ON_SCREEN_ALERT_LIMIT = 3;
const ALERT_CLOSE_DURATION_IN_MILLISECONDS = 300;
const ALERT_ERROR_SHOW_DURATION_IN_MILLISECONDS = 10000;

export interface IwithAlertsQueueProps {
  addAlert: (configuredAlert: IConfiguredAlert) => void;
  closeAllAlerts: (closedCallback: () => void) => void;
}

const withAlertsQueue =
  <T extends IwithAlertsQueueProps>(Component: ComponentType<T>) =>
  (props: Omit<T, 'addAlert' | 'closeAllAlerts'>) => {
    const [notificationAPI, contextHolder] =
      AntDesignNotification.useNotification();
    const queueRef = useRef<IManagedAlert[]>([]);
    const { toEntityUserStates } = useUserInfo();

    const handleClose = (managedAlert: IManagedAlert) => {
      AntDesignNotification.close(managedAlert.alert_id);

      window.setTimeout(() => {
        queueRef.current = queueRef.current.filter(
          (queuedAlert: IManagedAlert): boolean => queuedAlert !== managedAlert,
        );

        managedAlert.isOpen = false;

        handleDisplay();
      }, ALERT_CLOSE_DURATION_IN_MILLISECONDS);
    };

    const handleAcknowledge = (managedAlert: IManagedAlert) => async () => {
      try {
        const response: AxiosResponse<IAlertsAcknowledgeResponse> =
          await acknowledgeAlerts(managedAlert.to_entity, [
            managedAlert.alert_id,
          ]);
        const alertsAcknowledgeResponse: IAlertsAcknowledgeResponse =
          response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(alertsAcknowledgeResponse.errorMessage!);
        }

        handleClose(managedAlert);
      } catch (error: any) {
        captureError(error);

        clearTimeout(managedAlert.timeoutId);

        managedAlert.errorMessageSetterRef.current!(
          'An error occurred trying to mark as read. Please try again later.',
        );

        managedAlert.timeoutId = window.setTimeout(() => {
          handleClose(managedAlert);
        }, ALERT_ERROR_SHOW_DURATION_IN_MILLISECONDS);
      }
    };

    const handleClick = (managedAlert: IManagedAlert) => () => {
      handleClose(managedAlert);
    };

    const handleDisplay = () => {
      if (numberOfOpen(queueRef.current) < ON_SCREEN_ALERT_LIMIT) {
        const managedAlert: IManagedAlert | undefined = queueRef.current.find(
          (queuedAlert: IManagedAlert): boolean => !queuedAlert.isOpen,
        );

        if (managedAlert !== undefined) {
          managedAlert.isOpen = true;

          const style: CSSProperties = {
            width: ALERT_WIDTH_VALUE,
          };

          if (managedAlert.color_effect !== undefined) {
            style.backgroundColor = managedAlert.color_effect;
          }

          const toEntityUserState: IToEntityUserState | undefined =
            toEntityUserStates?.[managedAlert.to_entity];

          // We must use the notification instance to open a new notification in
          // order to retain the context at the call site. This then ensures that
          // react-redux can continue as it is built on top of Context
          notificationAPI.open({
            closeIcon: <></>,
            description: (
              <AlertBody
                body={managedAlert.alert_body}
                creationTime={managedAlert.created_at_time}
                errorMessageSetterRef={managedAlert.errorMessageSetterRef}
                onAcknowledge={handleAcknowledge(managedAlert)}
                timeZone={
                  toEntityUserState?.selectedTimeZone ??
                  ZonedDateTime.defaultTimeZone()
                }
                toEntityId={managedAlert.to_entity}
              />
            ),
            duration: 0,
            getContainer: addWithAlertsQueueClass,
            key: managedAlert.alert_id,
            message: (
              <AlertTitle
                group={managedAlert.group}
                name={managedAlert.name}
                severity={managedAlert.severity}
                soundEffect={managedAlert.sound_effect}
                soundPlayRepetitions={managedAlert.sound_play_repetitions}
              />
            ),
            onClick: handleClick(managedAlert),
            placement: 'bottomRight',
            style,
          });

          managedAlert.timeoutId = window.setTimeout(() => {
            handleClose(managedAlert);
          }, managedAlert.visible_time * 1000);
        }
      }
    };

    const closeAllAlerts = (closedCallback: () => void) => {
      const updatedQueue: IManagedAlert[] = [];

      queueRef.current.forEach((managedAlert: IManagedAlert) => {
        if (managedAlert.isOpen) {
          managedAlert.isOpen = false;

          AntDesignNotification.close(managedAlert.alert_id);
        } else {
          updatedQueue.push(managedAlert);
        }
      });

      queueRef.current = updatedQueue;

      window.setTimeout(closedCallback, ALERT_CLOSE_DURATION_IN_MILLISECONDS);
    };

    const addAlert = (configuredAlert: IConfiguredAlert) => {
      // Convert IConfiguredAlert to IManagedAlert
      queueRef.current.push({
        ...configuredAlert,
        errorMessageSetterRef: { current: undefined },
        isOpen: false,
      });

      handleDisplay();
    };

    // In order for the correct context to be included with the wrapped Component
    // we need to ensure that contextHolder is a sibling in the hierarchy. This
    // is an implementation detail from Ant Design's management of Context
    return (
      <>
        {contextHolder}
        <Component
          {...(props as T)}
          addAlert={addAlert}
          closeAllAlerts={closeAllAlerts}
        />
      </>
    );
  };

export default withAlertsQueue;
