import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { transformAlertMessage } from 'components/molecules/AlertBody/helpers';
import {
  ALERT_MESSAGE_ATTRIBUTE_MAP,
  ALERT_MESSAGE_ORDERING,
} from 'constants/Alert';
import { STANDARD_SPACING } from 'constants/styles';
import { EAlertMessageAttribute } from 'enums/Alert';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TAlertBody } from 'types/Alert';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { toFormattedDateTimeString } from 'utils/time';
import { EPageMode } from 'enums/Page';
import { TToEntityId } from 'types/ToEntity';
import { detailPageLocationString } from 'utils/detail';
import Link from 'components/atoms/Link/Link';

const AlertMessageGroup = styled(SeparatedRowLayout)`
  span {
    font-size: 13px;
    font-weight: 500;
  }
`;

const AlertActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};
`;

const CreationTime = styled.span`
  font-size: 11px;
`;

interface IAlertBodyProps {
  body: TAlertBody;
  creationTime: string;
  errorMessageSetterRef?: {
    current?: (errorMessage: TErrorMessage) => void;
  };
  onAcknowledge?: () => void;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const AlertBody = (props: IAlertBodyProps): JSX.Element => {
  const {
    body,
    creationTime,
    errorMessageSetterRef,
    onAcknowledge,
    timeZone,
    toEntityId,
  } = props;
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  useEffect(
    () => {
      if (errorMessageSetterRef !== undefined) {
        errorMessageSetterRef.current = setErrorMessage;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <CreationTime>
        {`Created On Time: ${toFormattedDateTimeString(
          creationTime,
          timeZone,
        )}`}
      </CreationTime>
      {ALERT_MESSAGE_ORDERING.map(
        (messageGroup: EAlertMessageAttribute[], index: number) => (
          <AlertMessageGroup key={index.toString()}>
            {messageGroup.map((alertMessageAttribute: EAlertMessageAttribute) =>
              body[alertMessageAttribute] ===
              undefined ? null : alertMessageAttribute ===
                  EAlertMessageAttribute.UiTagId && body.tag_primary_key ? (
                <SeparatedRowLayout key={alertMessageAttribute}>
                  <div>
                    {ALERT_MESSAGE_ATTRIBUTE_MAP[alertMessageAttribute]}:
                  </div>
                  <Link
                    to={detailPageLocationString({
                      tagPrimaryKey: body.tag_primary_key,
                      defaultTimeZone: timeZone,
                      mode: EPageMode.Review,
                      toEntity: toEntityId,
                    })}
                    target='_blank'
                  >
                    {transformAlertMessage(
                      body[alertMessageAttribute],
                      alertMessageAttribute,
                      timeZone,
                    )}
                  </Link>
                </SeparatedRowLayout>
              ) : (
                <SeparatedRowLayout key={alertMessageAttribute}>
                  <div>
                    {ALERT_MESSAGE_ATTRIBUTE_MAP[alertMessageAttribute]}:
                  </div>
                  <span>
                    {transformAlertMessage(
                      body[alertMessageAttribute],
                      alertMessageAttribute,
                      timeZone,
                    )}
                  </span>
                </SeparatedRowLayout>
              ),
            )}
          </AlertMessageGroup>
        ),
      )}
      <ErrorMessage>{errorMessage}</ErrorMessage>
      {onAcknowledge === undefined ? null : (
        <AlertActions className='alert-actions'>
          <Button
            label='Mark as read'
            onClick={onAcknowledge}
            stopPropagation={true}
          />
        </AlertActions>
      )}
    </>
  );
};

export default AlertBody;
