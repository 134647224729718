import { PlusCircleOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover, Tag } from 'antd';
import AlertSoundIcon from 'components/atoms/AlertSoundIcon/AlertSoundIcon';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Spinner from 'components/atoms/Spinner/Spinner';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import SeverityIcon from 'components/molecules/SeverityIcon/SeverityIcon';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import AlertRuleEditColumnRender from 'components/organisms/AlertRulesConfiguration/AlertRuleEditColumnRender';
import AlertRuleEditor, {
  DEFAULTS_BY_SEVERITY,
} from 'components/organisms/AlertRulesConfiguration/AlertRuleEditor';
import {
  ensureCoreAttributes,
  filteredAttributes,
} from 'components/organisms/AlertRulesConfiguration/helpers';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import { ALERT_MESSAGE_ATTRIBUTE_MAP } from 'constants/Alert';
import {
  BACKGROUND_WHITE,
  BUTTON_ICON_DIMENSIONS,
  COLUMN_LAYOUT_SHARED_STYLES,
  PUSH_RIGHT_VALUE,
  ROW_BACKGROUND_DARK,
} from 'constants/styles';
import { EAlertMessageAttribute, EAlertSound } from 'enums/Alert';
import { ERetreiveState, ESeverity } from 'enums/General';
import { ETheme } from 'enums/Style';
import usePermissions, {
  IusePermissionsProps,
  permissionsForEncodedPermissionsId,
} from 'hooks/usePermissions';
import {
  IAlertColourEffect,
  IAlertMessageTemplate,
  IAlertRule,
  IAlertRulesResponse,
  IAlertRuleTableColumnConfig,
  IRenderProps,
} from 'interfaces/Alert';
import { IIndexable } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import {
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { retrieveAlertRules } from 'services/alert/rules';
import styled from 'styled-components';
import { TEditableAlertRule } from 'types/Alert';
import { TThemeValue } from 'types/Style';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { stringSortWithEmpties } from 'utils/sort';
import {
  replaceDanglingParenWithUndefined,
  unpackSummaryStyles,
} from 'utils/styles';
import { getColumnRender } from 'utils/views';
import TopBarMenu from '../TopBarMenu/TopBarMenu';
import DownloadTagButton from '../../molecules/DownloadTagButton/DownloadTagButton';
import NavigationActions from '../../atoms/NavigationActions/NavigationActions';
import { useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';

const CreateIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const Title = styled.div`
  font-size: 24px;
`;

const ICON_STYLE = `
  > .anticon {
    font-size: 18px;
  }
`;

const IconWrapper = styled.div`
  ${ICON_STYLE}
`;

interface IAlertRuleTableColumnProps {
  dataIndex: string;
  onCell:
    | ((
        alertRule: TEditableAlertRule,
      ) => Omit<ICellProps, 'children' | 'index'>)
    | null;
  onHeaderCell?: (
    column: IAlertRuleTableColumnProps,
  ) => HTMLAttributes<HTMLElement>;
  render: (
    value: unknown,
    record: TEditableAlertRule,
    index: number,
  ) => JSX.Element;
  textAlign?: string;
  title: ReactNode;
  tooltipText?: string;
  width?: string | number;
}

// Specialize the DataTable component for alert rules
const AlertRulesTable = styled(
  (props: IDataTableProps<IAlertRuleTableColumnProps, TEditableAlertRule>) =>
    DataTable<IAlertRuleTableColumnProps, TEditableAlertRule>(props),
)`
  .ant-table-tbody {
    > tr > td {
      padding: 0;
    }
  }
`;

const columnRender = (props: IRenderProps): JSX.Element =>
  getColumnRender(false)(props.value);

const colorEffectRender = (props: IRenderProps): JSX.Element => {
  const colourEffect: string | undefined = (
    props.value as (IAlertColourEffect & IIndexable) | undefined
  )?.[String(props.currentTheme)];

  return columnRender({ ...props, value: colourEffect });
};

const StyledAlertMessageTemplatePopoverContent = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

interface IAlertMessageTemplatePopoverProps {
  content: ReactNode;
  children: ReactNode;
}

const AlertMessageTemplatePopover = (
  props: IAlertMessageTemplatePopoverProps,
): JSX.Element => {
  const { content, children } = props;

  return (
    <AntDesignPopover
      content={content}
      destroyTooltipOnHide={true}
      trigger='hover'
    >
      {children}
    </AntDesignPopover>
  );
};

const AlertMessageTemplateWrapper = styled.div`
  overflow: hidden;
`;

const alertMessageTemplateTags = (
  alertMessageTemplate: IAlertMessageTemplate,
): JSX.Element => (
  <>
    {filteredAttributes(alertMessageTemplate).map(
      (attribute: EAlertMessageAttribute): JSX.Element => (
        <Tag key={attribute}>{ALERT_MESSAGE_ATTRIBUTE_MAP[attribute]}</Tag>
      ),
    )}
  </>
);

const alertRuleColumns: IAlertRuleTableColumnConfig[] = [
  {
    dataIndex: 'type',
    renderWithTheme: columnRender,
    title: 'Type',
    width: '62px',
  },
  {
    dataIndex: 'name',
    renderWithTheme: columnRender,
    title: 'Name',
    width: '65px',
  },
  {
    dataIndex: 'group',
    renderWithTheme: columnRender,
    title: 'Group',
    width: '30px',
  },
  {
    dataIndex: 'severity',
    renderWithTheme: (props: IRenderProps): JSX.Element => {
      if (props.value === undefined) {
        return <></>;
      } else {
        return (
          <>
            <Tooltip title={String(props.value)}>
              <IconWrapper>
                <SeverityIcon severity={props.value as ESeverity} />
              </IconWrapper>
            </Tooltip>
          </>
        );
      }
    },
    textAlign: 'center',
    title: 'Severity',
    width: '18px',
  },
  {
    backgroundColor: (theme: ETheme, alertRule: TEditableAlertRule) =>
      replaceDanglingParenWithUndefined(alertRule?.color_effect?.[theme]) ??
      null,
    dataIndex: 'color_effect',
    renderWithTheme: colorEffectRender,
    textAlign: 'center',
    title: '',
    titleByTheme: (currentTheme: TThemeValue) =>
      `Color Effect (${currentTheme})`,
    tooltipText: `Don't forget to switch themes to edit other colors`,
    width: '22px',
  },
  {
    dataIndex: 'sound_effect',
    renderWithTheme: (props: IRenderProps): JSX.Element => {
      if (props.value === undefined) {
        return <></>;
      } else {
        let title: string;
        if (props.value === EAlertSound.None) {
          title = 'No Sound';
        } else {
          title = props.value as EAlertSound;
        }
        return (
          <>
            <Tooltip title={title}>
              <IconWrapper>
                <AlertSoundIcon sound={props.value as EAlertSound} />
              </IconWrapper>
            </Tooltip>
          </>
        );
      }
    },
    textAlign: 'center',
    title: 'Sound Effect',
    width: '16px',
  },
  {
    dataIndex: 'alert_duration_hours',
    renderWithTheme: columnRender,
    textAlign: 'center',
    title: 'Duration (Hours)',
    width: '20px',
  },
  {
    dataIndex: 'alert_message_template',
    renderWithTheme: (props: IRenderProps): JSX.Element => {
      return props.value === undefined ? (
        <></>
      ) : (
        <AlertMessageTemplatePopover
          content={
            <StyledAlertMessageTemplatePopoverContent>
              {alertMessageTemplateTags(props.value as IAlertMessageTemplate)}
            </StyledAlertMessageTemplatePopoverContent>
          }
        >
          <AlertMessageTemplateWrapper>
            <SeparatedRowLayout marginRight={3}>
              {alertMessageTemplateTags(props.value as IAlertMessageTemplate)}
            </SeparatedRowLayout>
          </AlertMessageTemplateWrapper>
        </AlertMessageTemplatePopover>
      );
    },
    title: 'Alert Message Template',
    width: '200px',
  },
];

interface ICellProps extends HTMLAttributes<HTMLElement> {
  alertRule: TEditableAlertRule;
  backgroundColor: string | null;
  children: ReactNode;
  textAlign: string | null;
}

// using &s to increase the specificity: see
// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledTd = styled.td<{
  backgroundColor: string | null;
  textAlign: string | null;
}>`
  &&&& {
    ${(props) =>
      props.backgroundColor === null
        ? ''
        : `background-color: ${props.backgroundColor};`}
    ${(props) =>
      props.textAlign === null ? '' : `text-align: ${props.textAlign};`}
    max-width: 100px;
  }
`;

const StyledTr = styled.tr<{ rowStyle: string; rowHighlightingStyle: string }>`
  ${(props) => props.rowStyle};

  :hover {
    ${(props) => props.rowHighlightingStyle};
  }
`;

interface IRowProps extends HTMLAttributes<HTMLElement> {
  index?: number;
}

const onRow = (_: TEditableAlertRule, index: number | undefined): IRowProps => {
  return {
    index: index,
  };
};

const DEFAULT_ALTERNATING_ROW_STYLES = {
  [ETheme.Light]: {
    1: unpackSummaryStyles({
      filter: 'brightness(0.89)',
      'background-color': BACKGROUND_WHITE,
    }),
    0: unpackSummaryStyles({
      'background-color': BACKGROUND_WHITE,
    }),
  },
  [ETheme.Dark]: {
    1: unpackSummaryStyles({
      filter: 'brightness(1.11)',
      'background-color': ROW_BACKGROUND_DARK,
    }),
    0: unpackSummaryStyles({
      'background-color': ROW_BACKGROUND_DARK,
    }),
  },
};
const DEFAULT_ROW_HIGHLIGHTING_STYLE = {
  [ETheme.Light]: unpackSummaryStyles({
    filter: 'brightness(0.77)',
  }),
  [ETheme.Dark]: unpackSummaryStyles({
    filter: 'brightness(1.23)',
  }),
};
const BodyRow = (props: IRowProps): JSX.Element => {
  const { children, index } = props;
  const themeSwitcher = useThemeSwitcher();
  const theme = themeSwitcher.currentTheme! as TThemeValue;
  return (
    <StyledTr
      rowStyle={
        DEFAULT_ALTERNATING_ROW_STYLES[theme][((index ?? 0) % 2) as 0 | 1]
      }
      rowHighlightingStyle={DEFAULT_ROW_HIGHLIGHTING_STYLE[theme]}
    >
      {children}
    </StyledTr>
  );
};

// using &s to increase the specificity: see
// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledTh = styled.th<{ textAlign: string | null }>`
  &&& {
    ${(props) =>
      props.textAlign === null ? '' : `text-align: ${props.textAlign};`}
  }
`;

interface IHeaderCellProps extends HTMLAttributes<HTMLElement> {
  tooltipText: string | null;
  textAlign: string | null;
}

const HeaderCell = (props: IHeaderCellProps): JSX.Element => {
  const { children, tooltipText, ...rest } = props;

  const childNode =
    tooltipText === null ? (
      children
    ) : (
      <Tooltip title={tooltipText}>{children}</Tooltip>
    );
  return <StyledTh {...rest}>{childNode}</StyledTh>;
};

// this is called by the grid framework, but the function is passed
// to the DataTable component, and many of the properties are constructed
// by the 'onCell' function below in the main component
const BodyCell = (props: ICellProps): JSX.Element => {
  const { children, ...rest } = props;

  return <StyledTd {...rest}>{children}</StyledTd>;
};

interface IAlertRulesConfigurationProps extends IusePermissionsProps {
  toEntity?: IToEntity;
}

enum EEditView {
  EDIT = 'edit',
  VIEW = 'view',
}

interface IEditingOrViewingAlertRule {
  alertRule: TEditableAlertRule;
  editOrView: EEditView;
}

const AlertRulesConfiguration = (
  props: IAlertRulesConfigurationProps,
): JSX.Element => {
  const { encodedPermissionsId, toEntity } = props;
  const permissions = usePermissions(encodedPermissionsId);
  const themeSwitcher = useThemeSwitcher();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [alertRules, setAlertRules] = useState<TEditableAlertRule[]>([]);
  const [orderedAlertRules, setOrderedAlertRules] = useState<
    TEditableAlertRule[]
  >([]);
  const [editingOrViewingAlertRule, setEditingOrViewingAlertRule] =
    useState<IEditingOrViewingAlertRule | null>(null);

  useEffect(() => {
    const alertRulesCopy = [...alertRules];
    const sorter = stringSortWithEmpties(true);
    alertRulesCopy.sort(
      (
        alertRule1: TEditableAlertRule,
        alertRule2: TEditableAlertRule,
      ): number => sorter(alertRule1.name, alertRule2.name),
    );
    setOrderedAlertRules(alertRulesCopy);
  }, [alertRules]);

  useAsyncEffect(async () => {
    if (toEntity !== undefined) {
      try {
        setIsLoading(true);
        setLoadErrorMessage(undefined);

        const retrieveAlertRulesResponse = await retrieveAlertRules(
          toEntity.to_entity,
        );

        const alertRulesResponse: IAlertRulesResponse =
          retrieveAlertRulesResponse.data;

        if (!isSuccessStatus(retrieveAlertRulesResponse.status)) {
          throw new Error(alertRulesResponse.errorMessage!);
        }
        setAlertRules(alertRulesResponse.response);
      } catch (error: any) {
        captureError(
          error,
          `Error getting alert rules for entity: ${toEntity.to_entity}`,
        );

        setAlertRules([]);

        setLoadErrorMessage(
          'Could not load alert rules. Please try again later.',
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [toEntity]);

  const onSaveAlertRule = useCallback(
    (alertRule: TEditableAlertRule) => {
      const newAlertRules = [...alertRules];
      const index = newAlertRules.findIndex(
        (item: TEditableAlertRule) =>
          alertRule.alert_rule_id === item.alert_rule_id,
      );
      if (index >= 0) {
        const foundAlertRule = newAlertRules[index];
        const item = {
          ...foundAlertRule,
          ...alertRule,
        };
        newAlertRules.splice(index, 1, item);
      } else {
        newAlertRules.push(alertRule);
      }
      setAlertRules(newAlertRules);
    },
    [alertRules],
  );

  const currentTheme = themeSwitcher.currentTheme as TThemeValue;

  const onCell = (
    column: IAlertRuleTableColumnConfig,
  ): ((
    alertRule: TEditableAlertRule,
    // we produce all required props except 'children' and 'index',
    // which are produced by the grid framework
  ) => Omit<ICellProps, 'children' | 'index'>) => {
    return (alertRule: TEditableAlertRule) => {
      return {
        alertRule,
        backgroundColor:
          column.backgroundColor === undefined
            ? null
            : column.backgroundColor(currentTheme, alertRule),
        textAlign: column.textAlign === undefined ? null : column.textAlign,
      };
    };
  };

  const onHeaderCell = (
    column: IAlertRuleTableColumnProps,
  ): IHeaderCellProps => {
    return {
      tooltipText: column.tooltipText ?? null,
      textAlign: column.textAlign ?? null,
    };
  };

  const removeAlertRule = (alertRuleToRemove: TEditableAlertRule) => {
    const index = alertRules.findIndex(
      (alertRule: TEditableAlertRule) =>
        alertRule.alert_rule_id === alertRuleToRemove.alert_rule_id,
    );
    if (index >= 0) {
      const newAlertRules: TEditableAlertRule[] = [...alertRules];
      newAlertRules.splice(index, 1);
      setAlertRules(newAlertRules);
    }
  };

  const columns: IAlertRuleTableColumnProps[] = [];

  if (encodedPermissionsId !== undefined && toEntity !== undefined) {
    // there is a way to get the column to auto-fit the contents: don't use 'fixed' layout
    // on the grid. However, this makes it hard to control the grid size.
    // the lengthy columns in this grid make it have double scroll bars, which is a bad
    // user experience. If this can be figured out, then the permissions -> sizing
    // computation here can be removed.
    const editingColumnPermissionId = encodeIds([
      encodedPermissionsId,
      'editingColumn',
    ]);
    const copyPermissionId = encodeIds([editingColumnPermissionId, 'copy']);
    const deletePermissionId = encodeIds([editingColumnPermissionId, 'delete']);
    const editPermissionId = encodeIds([editingColumnPermissionId, 'edit']);
    const viewPermissionId = encodeIds([editingColumnPermissionId, 'view']);
    const numDisplayable = [
      permissionsForEncodedPermissionsId(copyPermissionId, [toEntity])
        .isDisplayable,
      permissionsForEncodedPermissionsId(deletePermissionId, [toEntity])
        .isDisplayable,
      permissionsForEncodedPermissionsId(editPermissionId, [toEntity])
        .isDisplayable,
      permissionsForEncodedPermissionsId(viewPermissionId, [toEntity])
        .isDisplayable,
    ].filter((b) => b).length;
    columns.push({
      dataIndex: 'actions',
      onCell: null,
      render: (_, alertRule: TEditableAlertRule) => (
        <AlertRuleEditColumnRender
          alertRuleId={alertRule.alert_rule_id}
          copyPermissionId={copyPermissionId}
          deletePermissionId={deletePermissionId}
          editPermissionId={editPermissionId}
          isViewing={
            alertRule.alert_rule_id ===
            editingOrViewingAlertRule?.alertRule?.alert_rule_id
          }
          onClickCopy={() => startEditingNewAlertRuleFromPrototype(alertRule)}
          onClickEdit={() =>
            setEditingOrViewingAlertRule({
              alertRule,
              editOrView: EEditView.EDIT,
            })
          }
          onClickDeleteAlertRule={() => setEditingOrViewingAlertRule(null)}
          onSuccessfulDeleteAlertRule={() => removeAlertRule(alertRule)}
          toEntity={toEntity!}
          viewPermissionId={viewPermissionId}
        />
      ),
      title: '',
      tooltipText: undefined,
      width: numDisplayable * 10,
    });
  }

  alertRuleColumns.forEach((column: IAlertRuleTableColumnConfig) =>
    columns.push({
      dataIndex: column.dataIndex,
      onCell: onCell(column),
      onHeaderCell: onHeaderCell,
      render: (value: unknown) =>
        column.renderWithTheme({
          currentTheme,
          toEntity: toEntity!,
          value: value as IAlertRule[keyof IAlertRule] | undefined,
        }),
      title:
        column.titleByTheme === undefined
          ? column.title
          : column.titleByTheme(currentTheme),
      textAlign: column.textAlign,
      tooltipText: column.tooltipText,
      width: column.width,
    }),
  );

  const startEditingNewAlertRuleFromPrototype = (
    alertRulePrototype: TEditableAlertRule,
  ) => {
    const addedAlertRule: TEditableAlertRule = {
      ...alertRulePrototype,
      alert_message_template: {
        ...alertRulePrototype.alert_message_template,
        attributes: ensureCoreAttributes(
          alertRulePrototype.alert_message_template?.attributes,
        ),
      },
    };
    delete addedAlertRule.alert_rule_id;
    setEditingOrViewingAlertRule({
      alertRule: addedAlertRule,
      editOrView: EEditView.EDIT,
    });
  };

  const handleAddNewAlertRule = useCallback(
    () =>
      startEditingNewAlertRuleFromPrototype({
        alert_duration_hours: 24,
        color_effect: DEFAULTS_BY_SEVERITY[ESeverity.Info].color_effect,
        severity: ESeverity.Info,
        sound_effect: DEFAULTS_BY_SEVERITY[ESeverity.Info].sound_effect,
        to_entity: toEntity!.to_entity,
      }),
    [toEntity],
  );

  const [hideTenantTitle, setHideTenantTitle] = useState<boolean>(true);

  const configState = useSelector((state: TRootState) => state.config);
  useEffect(() => {
    if (configState.retrievingConfig === ERetreiveState.RetrievingCompleted) {
      const hideTenantTitleConfig =
        configState.hideTenantTitleConfig &&
        toEntity &&
        configState.hideTenantTitleConfig.get(toEntity?.to_entity);
      setHideTenantTitle(hideTenantTitleConfig as boolean);
    }
  }, [configState, toEntity, setHideTenantTitle]);

  return (
    <>
      <SeparatedRowLayout>
        <Title>Alert Rules</Title>
        {toEntity && toEntity.entity_code ? (
          <TopBarMenu
            encodedPermissionsId={encodedPermissionsId}
            toEntity={toEntity}
          />
        ) : null}
        {hideTenantTitle ? null : <UserToEntitySelection />}
        {toEntity !== undefined && (
          <Tooltip title='Add New Alert Rule'>
            <IconButton
              encodedPermissionsId={
                encodedPermissionsId === undefined
                  ? undefined
                  : encodeIds(
                      [encodedPermissionsId, 'add'],
                      toEntity!.to_entity,
                    )
              }
              icon={<CreateIcon />}
              onClick={handleAddNewAlertRule}
            />
          </Tooltip>
        )}
        <NavigationActions right={PUSH_RIGHT_VALUE}>
          <DownloadTagButton
            currentTheme={currentTheme}
            toEntityId={toEntity?.to_entity}
          />
        </NavigationActions>
      </SeparatedRowLayout>
      {loading && <Spinner />}
      {!permissions.isDisplayable && toEntity !== undefined && (
        <ErrorMessage>{`You do not have permissions to view
         alert rules for Entity ${toEntity.entity_code}`}</ErrorMessage>
      )}
      {permissions.isDisplayable &&
        !loading &&
        loadErrorMessage === undefined &&
        toEntity !== undefined && (
          <>
            {editingOrViewingAlertRule !== null &&
              encodedPermissionsId !== undefined && (
                <AlertRuleEditor
                  alertRule={editingOrViewingAlertRule.alertRule}
                  encodedPermissionsId={encodeIds(
                    [
                      encodedPermissionsId,
                      editingOrViewingAlertRule.editOrView,
                      'alertRuleEditor',
                    ],
                    toEntity!.to_entity,
                  )}
                  onClose={() => setEditingOrViewingAlertRule(null)}
                  onSave={onSaveAlertRule}
                  toEntity={toEntity}
                />
              )}
            <AlertRulesTable
              columns={columns}
              components={{
                body: { row: BodyRow, cell: BodyCell },
                header: { cell: HeaderCell },
              }}
              data={orderedAlertRules}
              onRow={onRow}
              pagination={false}
              rowKey={(record: TEditableAlertRule) =>
                String(record.alert_rule_id)
              }
              tableLayout='fixed'
            />
          </>
        )}
      {loadErrorMessage !== undefined && (
        <ErrorMessage>{loadErrorMessage}</ErrorMessage>
      )}
    </>
  );
};

export default AlertRulesConfiguration;
