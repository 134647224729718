import { IRouteProps } from '../../../interfaces/Component';
import Page from '../../organisms/Page/Page';
import HelpTutorialsPageContent from './HelpTutorialsPageContent';

const HelpTutorialsPage = ({ appActions }: IRouteProps): JSX.Element => (
  <Page
    appActions={appActions}
    PageContent={HelpTutorialsPageContent}
    pageTitle='Help - Tutorials - PCI e-Tag+'
  />
);

export default HelpTutorialsPage;
