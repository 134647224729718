import { TErrorMessage } from '../../../types/Error';
import { captureError } from '../../../utils/error';
import { AxiosResponse } from 'axios';
import { retrieveNotices } from '../../../services/notice/notices';
import { isSuccessStatus } from '../../../utils/general';
import { INotice } from '../../../interfaces/Notice';
import { TNoticeId } from 'types/Notices';
import { EAlertAcknowledged } from 'enums/Alert';
import { ENoticeSeverity } from 'enums/General';
import { TZonedDateTimeRange } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { IToEntity } from 'interfaces/ToEntity';

export const loadNotices = async (
  acknowledgeStatus: EAlertAcknowledged,
  noticeSeverities: ENoticeSeverity[],
  dateTimes: TZonedDateTimeRange,
  noticesErrorMap: Record<TNoticeId, TErrorMessage>,
  setIsLoading: (isLoading: boolean) => void,
  setNotices: (alerts: any[]) => void,
  setErrorMessage: (errorMessage: TErrorMessage) => void,
  setNoticesForHeightMap: (
    notices: INotice[],
    noticesErrorMap: Record<TNoticeId, TErrorMessage>,
  ) => void,
  toEntities?: IToEntity[],
) => {
  const start: ZonedDateTime | null = dateTimes === null ? null : dateTimes[0];
  const stop: ZonedDateTime | null = dateTimes === null ? null : dateTimes[1];

  if (start !== null && stop !== null) {
    try {
      setIsLoading(true);

      setErrorMessage(null);

      let fetchedNotices: INotice[] = [];
      let itemCounter = 0;

      if (toEntities && toEntities.length > 0) {
        toEntities.forEach(async (toEntity: IToEntity) => {
          const response: AxiosResponse<any> = await retrieveNotices(
            toEntity.to_entity,
            start,
            stop,
            noticeSeverities,
            acknowledgeStatus,
            acknowledgeStatus === EAlertAcknowledged.Read,
          );

          const noticesRetrieveResponse = response.data;

          fetchedNotices = [
            ...fetchedNotices,
            ...noticesRetrieveResponse.response.map(
              (noticeObj: any) => noticeObj.notice,
            ),
          ];

          fetchedNotices = fetchedNotices.sort(
            (a: any, b: any) =>
              Date.parse(a.publish_date) - Date.parse(b.publish_date),
          );

          if (!isSuccessStatus(response.status)) {
            throw new Error(noticesRetrieveResponse.errorMessage!);
          }

          itemCounter++;
          if (itemCounter === toEntities.length) {
            setNoticesForHeightMap(fetchedNotices, noticesErrorMap);

            setNotices(fetchedNotices);
          }

          setIsLoading(false);
        });
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during loading. Please try again later.',
      );
    } finally {
      setIsLoading(false);
    }
  }
};
