export enum EDefaultDateRange {
  Today = 'today',
  Tomorrow = 'tomorrow',
  Yesterday = 'yesterday',
  TodayTomorrow = 'todayTomorrow',
  YesterdayToday = 'yesterdayToday',
  TodayPlus2Days = 'todayPlus2Days',
  TodayPlus3Days = 'todayPlus3Days',
  TodayPlus4Days = 'todayPlus4Days',
  LastWeek = 'lastWeek',
  ThisWeek = 'thisWeek',
  NextWeek = 'nextWeek',
  LastMonth = 'lastMonth',
  ThisMonth = 'thisMonth',
  NextMonth = 'nextMonth',
}
