import {
  CopyOutlined,
  DeleteRowOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Popconfirm as AntDesignPopconfirm } from 'antd';
import { AxiosResponse } from 'axios';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  ERROR_RED,
  HIGHLIGHT_BLUE,
} from 'constants/styles';
import { IAlertRuleDeleteResponse } from 'interfaces/Alert';
import { IToEntity } from 'interfaces/ToEntity';
import { useState } from 'react';
import { deleteAlertRule } from 'services/alert/rules';
import styled from 'styled-components';
import { TAlertRuleId } from 'types/Alert';
import { TErrorMessage } from 'types/Error';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';

const EditIcon = styled(EditOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ViewIcon = styled(EyeOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const DeleteIcon = styled(DeleteRowOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const CopyIcon = styled(CopyOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const BUTTON_HIGHLIGHT_STYLE = `
  background-color: ${HIGHLIGHT_BLUE};
  opacity: 70;
`;
const BUTTON_HAS_ERROR_STYLE = `
  background-color: ${ERROR_RED};
  opacity: 70;
`;

const StyledIconButton = styled(IconButton)<{
  hasError: boolean;
  highlight: boolean;
}>`
  ${(props) => (props.highlight ? `${BUTTON_HIGHLIGHT_STYLE};` : '')}
  ${(props) => (props.hasError ? `${BUTTON_HAS_ERROR_STYLE};` : '')}
`;

interface IAlertRuleEditColumnRenderProps {
  alertRuleId?: TAlertRuleId;
  copyPermissionId: string;
  deletePermissionId: string;
  editPermissionId: string;
  isViewing: boolean;
  onClickCopy: () => void;
  onClickEdit: () => void;
  onClickDeleteAlertRule: () => void;
  onSuccessfulDeleteAlertRule: () => void;
  toEntity: IToEntity;
  viewPermissionId: string;
}

const AlertRuleEditColumnRender = (
  props: IAlertRuleEditColumnRenderProps,
): JSX.Element => {
  const {
    alertRuleId,
    copyPermissionId,
    deletePermissionId,
    editPermissionId,
    isViewing,
    onClickCopy,
    onClickEdit,
    onClickDeleteAlertRule,
    onSuccessfulDeleteAlertRule,
    toEntity,
    viewPermissionId,
  } = props;
  const [deleteErrorMessage, setDeleteErrorMessage] =
    useState<TErrorMessage>(null);

  const onDelete = async () => {
    if (alertRuleId !== undefined) {
      try {
        const response: AxiosResponse<IAlertRuleDeleteResponse> =
          await deleteAlertRule(toEntity!.to_entity, alertRuleId!);
        const alertRuleDeleteResponse: IAlertRuleDeleteResponse = response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(alertRuleDeleteResponse.errorMessage!);
        }

        onSuccessfulDeleteAlertRule();
      } catch (error: any) {
        captureError(error, `Failed to delete alert rule id: ${alertRuleId}`);

        setDeleteErrorMessage(
          `Failed to delete alert rule ${alertRuleId}. Please try again later.`,
        );
      }
    } else {
      onSuccessfulDeleteAlertRule();
    }
  };

  const PopConfirm = () => (
    <AntDesignPopconfirm
      cancelText='No'
      okText='Yes'
      onConfirm={onDelete}
      onVisibleChange={(visible: boolean) => {
        if (visible) {
          onClickDeleteAlertRule();
        }
      }}
      title='Delete alert rule?'
    >
      <StyledIconButton
        encodedPermissionsId={deletePermissionId}
        hasError={deleteErrorMessage !== null}
        highlight={false}
        icon={<DeleteIcon />}
      />
    </AntDesignPopconfirm>
  );

  return (
    <SeparatedRowLayout>
      <StyledIconButton
        encodedPermissionsId={editPermissionId}
        hasError={false}
        highlight={isViewing}
        icon={<EditIcon />}
        onClick={onClickEdit}
      />
      <StyledIconButton
        encodedPermissionsId={viewPermissionId}
        hasError={false}
        highlight={isViewing}
        icon={<ViewIcon />}
        onClick={onClickEdit}
      />
      {deleteErrorMessage === null && <PopConfirm />}
      {deleteErrorMessage !== null && (
        <Tooltip mouseEnterDelay={0} title={deleteErrorMessage!}>
          <PopConfirm />
        </Tooltip>
      )}
      <Tooltip title='Copy Alert Rule'>
        <StyledIconButton
          encodedPermissionsId={copyPermissionId}
          hasError={false}
          highlight={false}
          icon={<CopyIcon />}
          onClick={onClickCopy}
        />
      </Tooltip>
    </SeparatedRowLayout>
  );
};

export default AlertRuleEditColumnRender;
