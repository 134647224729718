import EditDataTable from 'components/molecules/EditDataTable/EditDataTable';
import {
  getEditMiscInfoColumns,
  IMiscInfoEditableProps,
  MISC_INFO_ADD_BUTTON_OFFSET,
  MISC_INFO_REMOVE_BUTTON_OFFSET,
} from 'components/molecules/MiscInfoEditable/MiscInfoEditable';
import { DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT } from 'constants/styles';
import { IMiscInfo } from 'interfaces/General';
import { useCallback, useMemo } from 'react';
import { getInitialMiscInfo } from 'utils/detail';

interface IMiscInfoEditProps extends IMiscInfoEditableProps {
  editButtonWidth?: string;
  equalityChecker?: (a: IMiscInfo[], b: IMiscInfo[]) => boolean;
  hasChanged?: boolean;
  id: string;
  initialData?: IMiscInfo[] | null;
  tableTitle?: string;
}

const MiscInfoEdit = (props: IMiscInfoEditProps): JSX.Element => {
  const {
    data,
    editButtonWidth,
    equalityChecker,
    hasChanged,
    id,
    initialData,
    initialEditKey,
    isDisabled,
    onAdd,
    onRemove,
    tableTitle,
    tokenInputColumnConfig,
    valueInputColumnConfig,
  } = props;

  const editMiscInfoColumns = useMemo(
    () =>
      getEditMiscInfoColumns(
        tokenInputColumnConfig,
        valueInputColumnConfig,
        hasChanged,
      ),
    [hasChanged, tokenInputColumnConfig, valueInputColumnConfig],
  );

  const initialiser = useCallback(
    () => getInitialMiscInfo(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditDataTable<IMiscInfo>
      addButtonOffset={MISC_INFO_ADD_BUTTON_OFFSET}
      columns={editMiscInfoColumns}
      data={data}
      editButtonWidth={editButtonWidth}
      equalityChecker={equalityChecker}
      id={id}
      initialData={initialData}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={-1}
      maximumHeight={DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT}
      onAdd={onAdd}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={MISC_INFO_REMOVE_BUTTON_OFFSET}
      showDataCount={true}
      tableTitle={tableTitle}
      tooltipTitle='Edit All Misc Info'
    />
  );
};

export default MiscInfoEdit;
