import Button from 'components/atoms/Button/Button';
import Select from 'components/molecules/Select/Select';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING } from 'constants/styles';
import { IOption } from 'interfaces/Component';
import { ITenantToEntities } from 'interfaces/Config';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  width: 33%;

  padding: ${STANDARD_SPACING};
`;

interface IProps {
  tenantToEntities: ITenantToEntities[];
  toEntities: IToEntity[];
  onSelectToEntities: (toEntities: string[]) => void;
}

const UserTenantToEntitySelect = ({
  tenantToEntities,
  toEntities,
  onSelectToEntities,
}: IProps): JSX.Element => {
  const [tenantOptions, setTenantOptions] = useState<
    IOption<ITenantToEntities>[]
  >([]);
  const [toEntityOptions, setToEntityOptions] = useState<IOption<string>[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<
    ITenantToEntities | undefined
  >();
  const [selectedToEntities, setSelectedToEntities] = useState<string[]>([]);

  useEffect(() => {
    if (!tenantToEntities) {
      return;
    }
    const tenants: IOption<ITenantToEntities>[] = tenantToEntities.map(
      (tenantToEntity: ITenantToEntities) => ({
        label: tenantToEntity.tenant_id,
        value: tenantToEntity,
      }),
    );
    setTenantOptions(tenants);
  }, [tenantToEntities]);

  useEffect(() => {
    if (selectedTenant) {
      const toEntityCodes: IOption<string>[] = selectedTenant.to_entities.map(
        (toEntity: string) => {
          const entity: IToEntity | undefined = toEntities.find(
            (toEntityRecord: IToEntity) =>
              toEntityRecord.to_entity === toEntity,
          );
          if (entity) {
            return {
              label: `${entity.entity_code} (${toEntity})`,
              value: toEntity,
            };
          }
          return { label: toEntity, value: toEntity };
        },
      );
      setToEntityOptions(toEntityCodes);
    }
  }, [selectedTenant, toEntities]);

  const handleTenantSelection = (tenant: ITenantToEntities | undefined) => {
    setSelectedTenant(tenant);
    setSelectedToEntities([]);
  };

  const handleToEntitySelection = () => {
    onSelectToEntities(selectedToEntities);
  };

  return (
    <Layout>
      <div>Please select tenant</div>
      <Select
        onChange={handleTenantSelection}
        options={tenantOptions}
        valueToUid={(value: ITenantToEntities): string => value.tenant_id}
      />
      {selectedTenant ? (
        <>
          <div>Please select toEntity</div>
          <Select
            allowMultiple={true}
            onChangeMultiple={setSelectedToEntities}
            options={toEntityOptions}
            valueToUid={(value: string): string => value}
          />
        </>
      ) : null}
      {selectedTenant && selectedToEntities ? (
        <Button
          isPrimary={true}
          label='Confirm'
          onClick={handleToEntitySelection}
        ></Button>
      ) : null}
    </Layout>
  );
};

export default UserTenantToEntitySelect;
