import CancelledIcon from 'components/atoms/CancelledIcon/CancelledIcon';
import ConfirmedIcon from 'components/atoms/ConfirmedIcon/ConfirmedIcon';
import DeniedIcon from 'components/atoms/DeniedIcon/DeniedIcon';
import DraftIcon from 'components/atoms/DraftIcon/DraftIcon';
import ExpiredIcon from 'components/atoms/ExpiredIcon/ExpiredIcon';
import ImplementedIcon from 'components/atoms/ImplementedIcon/ImplementedIcon';
import PendingIcon from 'components/atoms/PendingIcon/PendingIcon';
import TerminatedIcon from 'components/atoms/TerminatedIcon/TerminatedIcon';
import WithdrawnIcon from 'components/atoms/WithdrawnIcon/WithdrawnIcon';
import { ICON_FONT_SIZE } from 'constants/styles';
import { ECompositeState } from 'enums/ETag';
import styled from 'styled-components';

const Container = styled.div`
  height: 20px;
  width: 20px;
`;

const Cancelled = styled(CancelledIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Confirmed = styled(ConfirmedIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Denied = styled(DeniedIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Draft = styled(DraftIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Expired = styled(ExpiredIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Implemented = styled(ImplementedIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Pending = styled(PendingIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Terminated = styled(TerminatedIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Withdrawn = styled(WithdrawnIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

interface ICompositeStateIconProps {
  compositeState: ECompositeState | null;
}

const CompositeStateIcon = ({
  compositeState,
}: ICompositeStateIconProps): JSX.Element => {
  let icon: JSX.Element | null = null;

  switch (compositeState) {
    case ECompositeState.Cancelled: {
      icon = <Cancelled />;
      break;
    }
    case ECompositeState.Confirmed: {
      icon = <Confirmed />;
      break;
    }
    case ECompositeState.Denied: {
      icon = <Denied />;
      break;
    }
    case ECompositeState.Draft: {
      icon = <Draft />;
      break;
    }
    case ECompositeState.Expired: {
      icon = <Expired />;
      break;
    }
    case ECompositeState.Implemented: {
      icon = <Implemented />;
      break;
    }
    case ECompositeState.Pending: {
      icon = <Pending />;
      break;
    }
    case ECompositeState.Terminated: {
      icon = <Terminated />;
      break;
    }
    case ECompositeState.Withdrawn: {
      icon = <Withdrawn />;
      break;
    }
    default: {
      return <></>;
    }
  }

  return <Container>{icon}</Container>;
};

export default CompositeStateIcon;
