import Page from 'components/organisms/Page/Page';
import ApprovalConfigsContent from 'components/pages/ApprovalConfigsPage/ApprovalConfigsPageContent';
import { IRouteProps } from 'interfaces/Component';

const ApprovalConfigsPage = ({ appActions }: IRouteProps): JSX.Element => (
  <Page
    appActions={appActions}
    PageContent={ApprovalConfigsContent}
    pageTitle='Approval Configurations - PCI E-Tag'
  />
);

export default ApprovalConfigsPage;
