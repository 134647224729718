import { List as AntDesignList, ListProps } from 'antd';
import { AxiosResponse } from 'axios';
import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { SEARCH_TYPE_OPTIONS } from 'components/molecules/ToEntityETagSearchPanel/constants';
import {
  getETagSummaryAttributeToSearchResult,
  searchResultListItemRenderer,
  searchTypeToUid,
} from 'components/molecules/ToEntityETagSearchPanel/helpers';
import SearchKindButton from 'components/molecules/ToEntityETagSearchPanel/SearchKindButton';
import { ISearchResult } from 'components/molecules/ToEntityETagSearchPanel/types';
import { ETAG_SEARCH_DETAIL_MAP, TAG_CODE_REGEX } from 'constants/ETag';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
  SUCCESS_GREEN,
} from 'constants/styles';
import { ECompositeState, ESearchType } from 'enums/ETag';
import { EActionState } from 'enums/General';
import { ESearchStringType } from 'enums/Search';
import { IThemedProps } from 'interfaces/Component';
import {
  IETagQueryResponse,
  IETagSummaryAttribute,
  IETagTagId,
} from 'interfaces/ETag';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { queryETagDistributedSummaryAttribute } from 'services/agent/tags/distributed';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import { textColour } from 'utils/styles';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  min-width: 331px;
  padding: ${STANDARD_SPACING};
`;

const SearchKind = styled.div`
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-right: 2px;
  }
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Message = styled.div`
  color: ${SUCCESS_GREEN};
  font-weight: bold;
  padding: ${STANDARD_SPACING} 0;
  text-align: center;
  width: 100%;
`;

interface IStyledInputProps {
  width: number;
}

const StyledInput = styled(Input)<IStyledInputProps>`
  width: ${(props) => props.width}px;
`;

const Underscore = styled.div<IThemedProps>`
  border-bottom: solid 1px ${textColour};
  margin: 0 2px;
  width: 11px;
`;

// Specialize the Select component
const SearchTypeSelect = styled((props: ISelectProps<ESearchType>) =>
  Select<ESearchType>(props),
)`
  width: 181px;
`;

// Specialize the List component
const List = styled((props: ListProps<ISearchResult>) =>
  AntDesignList<ISearchResult>(props),
)`
  height: 111px;
  overflow: auto;
`;

interface IToEntityETagSearchPanelProps {
  children?: ReactNode;
  timeZone: TTimeZone;
  toEntityId?: TToEntityId;
}

const ToEntityETagSearchPanel = ({
  children,
  timeZone,
  toEntityId,
}: IToEntityETagSearchPanelProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const [selectedSearchType, setSelectedSearchType] = useState<
    ESearchType | undefined
  >(undefined);
  const [selectedSearchKind, setSelectedSearchKind] =
    useState<ESearchStringType>(ESearchStringType.Equals);
  const [tagCode, setTagCode] = useState<string>('');
  const [tagIdA, setTagIdA] = useState<string>('');
  const [tagIdB, setTagIdB] = useState<string>('');
  const [tagIdC, setTagIdC] = useState<string>('');
  const [queryResults, setQueryResults] = useState<IETagSummaryAttribute[]>([]);
  const [searchButtonActionState, setSearchButtonActionState] =
    useState<EActionState>(EActionState.NoAction);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  useEffect(() => {
    if (selectedSearchType === ESearchType.ByTagCode) {
      setTagIdA('');
      setTagIdB('');
      setTagIdC('');
    } else if (selectedSearchType === ESearchType.ByTagId) {
      setTagCode('');
    }

    setSearchButtonActionState(EActionState.NoAction);
    setQueryResults([]);
  }, [selectedSearchType]);

  const handleSearchKindChange = useCallback(
    (searchKind: ESearchStringType) => {
      setSelectedSearchKind(searchKind);
    },
    [],
  );

  const handleTagCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Only empty string, letters and numbers are allowed!
      if (
        isEmptyValue(event.target.value) ||
        TAG_CODE_REGEX.test(event.target.value)
      ) {
        setTagCode(event.target.value);
      }
    },
    [],
  );

  const handleTagIdAChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Only empty string, letters and numbers are allowed!
      if (
        isEmptyValue(event.target.value) ||
        TAG_CODE_REGEX.test(event.target.value)
      ) {
        setTagIdA(event.target.value);
      }
    },
    [],
  );

  const handleTagIdBChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Only empty string, letters and numbers are allowed!
      if (
        isEmptyValue(event.target.value) ||
        TAG_CODE_REGEX.test(event.target.value)
      ) {
        setTagIdB(event.target.value);
      }
    },
    [],
  );

  const handleTagIdCChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Only empty string, letters and numbers are allowed!
      if (
        isEmptyValue(event.target.value) ||
        TAG_CODE_REGEX.test(event.target.value)
      ) {
        setTagIdC(event.target.value);
      }
    },
    [],
  );

  const handleSearch = useCallback(async () => {
    if (toEntityId !== undefined) {
      try {
        setSearchButtonActionState(EActionState.Actioning);
        setErrorMessage(null);
        setQueryResults([]);

        const adjustedTagCode: string | undefined = isEmptyValue(tagCode)
          ? undefined
          : tagCode;
        const uiTagId: string | undefined =
          isEmptyValue(tagIdA) || isEmptyValue(tagIdB) || isEmptyValue(tagIdC)
            ? undefined
            : `${tagIdA}_${tagIdB}_${tagIdC}`;

        const response: AxiosResponse<IETagQueryResponse> =
          await queryETagDistributedSummaryAttribute(
            toEntityId,
            adjustedTagCode,
            uiTagId,
          );
        const eTagQueryResponse: IETagQueryResponse = response.data;

        let duplicatedTagsWithPendingState: IETagTagId[] = [];
        let dataWithoutDuplicates: IETagSummaryAttribute[] = [];

        if (eTagQueryResponse && eTagQueryResponse.response) {
          duplicatedTagsWithPendingState = eTagQueryResponse.response
            .filter(
              (item, index) =>
                eTagQueryResponse.response?.indexOf(item) !== index &&
                item.composite_state === ECompositeState.Pending,
            )
            .map(({ tag_id }) => tag_id);
          dataWithoutDuplicates = eTagQueryResponse.response.filter(
            ({ tag_id, composite_state }, index) =>
              !duplicatedTagsWithPendingState.includes(tag_id),
          );
        }

        if (!isSuccessStatus(response.status)) {
          throw new Error(eTagQueryResponse.errorMessage!);
        }

        setQueryResults(dataWithoutDuplicates);

        setSearchButtonActionState(EActionState.Succeeded);
      } catch (error: any) {
        setSearchButtonActionState(EActionState.Failed);

        setErrorMessage(error.message);
      }
    }
  }, [tagCode, tagIdA, tagIdB, tagIdC, toEntityId]);

  const inputIsDisabled: boolean = useMemo(
    () => searchButtonActionState === EActionState.Actioning,
    [searchButtonActionState],
  );

  const searchIsDisabled: boolean = useMemo(
    () =>
      toEntityId === undefined ||
      selectedSearchType === undefined ||
      (selectedSearchType === ESearchType.ByTagCode &&
        (isEmptyValue(tagCode) || tagCode.length !== 7)) ||
      (selectedSearchType === ESearchType.ByTagId &&
        (isEmptyValue(tagIdA) || isEmptyValue(tagIdB) || isEmptyValue(tagIdC))),
    [selectedSearchType, tagCode, tagIdA, tagIdB, tagIdC, toEntityId],
  );

  const searchResults: ISearchResult[] = useMemo(() => {
    if (toEntityId === undefined) {
      return [];
    }

    const eTagSummaryAttributeToSearchResult =
      getETagSummaryAttributeToSearchResult(timeZone, toEntityId);

    return queryResults.map(eTagSummaryAttributeToSearchResult);
  }, [queryResults, timeZone, toEntityId]);

  return (
    <Layout>
      {children}
      <SeparatedRowLayout>
        <span>Search Type:</span>
        <SearchTypeSelect
          isDisabled={inputIsDisabled}
          onChange={setSelectedSearchType}
          options={SEARCH_TYPE_OPTIONS}
          placeholder='Select Search Type'
          value={selectedSearchType}
          valueToUid={searchTypeToUid}
        />
      </SeparatedRowLayout>
      {selectedSearchType === ESearchType.ByTagCode ? (
        <SeparatedRowLayout>
          <span>Tag Code:</span>
          <SearchKind>
            <SearchKindButton
              kinds={ETAG_SEARCH_DETAIL_MAP}
              onChange={handleSearchKindChange}
              searchKind={selectedSearchKind}
            />
            <StyledInput
              isDisabled={inputIsDisabled}
              maxLength={7}
              onChange={handleTagCodeChange}
              placeholder='Enter Tag Code'
              value={tagCode}
              width={111}
            />
          </SearchKind>
        </SeparatedRowLayout>
      ) : null}
      {selectedSearchType === ESearchType.ByTagId ? (
        <SeparatedRowLayout>
          <span>Tag Id:</span>
          <SearchKind>
            <SearchKindButton
              kinds={ETAG_SEARCH_DETAIL_MAP}
              onChange={handleSearchKindChange}
              searchKind={selectedSearchKind}
            />
            <StyledInput
              isDisabled={inputIsDisabled}
              onChange={handleTagIdAChange}
              placeholder='Enter'
              value={tagIdA}
              width={53}
            />
            <Underscore currentTheme={currentTheme!} />
            <StyledInput
              isDisabled={inputIsDisabled}
              onChange={handleTagIdBChange}
              placeholder='Tag'
              value={tagIdB}
              width={111}
            />
            <Underscore currentTheme={currentTheme!} />
            <StyledInput
              isDisabled={inputIsDisabled}
              onChange={handleTagIdCChange}
              placeholder='ID'
              value={tagIdC}
              width={53}
            />
          </SearchKind>
        </SeparatedRowLayout>
      ) : null}
      {searchResults.length > 0 ? (
        <List
          bordered={true}
          dataSource={searchResults}
          renderItem={searchResultListItemRenderer}
        />
      ) : searchButtonActionState === EActionState.Succeeded ? (
        <Message>No tags found matching criteria</Message>
      ) : null}
      <ErrorMessage maxWidth='100%'>{errorMessage}</ErrorMessage>
      <Actions>
        <Button
          actionState={searchButtonActionState}
          isDisabled={searchIsDisabled}
          label='Search'
          onClick={handleSearch}
        />
      </Actions>
    </Layout>
  );
};

export default ToEntityETagSearchPanel;
