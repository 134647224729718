import { PrinterOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { EProfileFormat } from 'enums/Detail';
import { IETagExtendedIdentifier, IETagTemplate } from 'interfaces/ETag';
import { useCallback } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TDetailPrintPageLocationParameters } from 'types/Detail';
import { TToEntityId } from 'types/ToEntity';
import { detailPrintPageLocationString } from 'utils/detail';

const PrintViewIcon = styled(PrinterOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IPrintViewButtonProps {
  eTagExtendedIdentifier: IETagExtendedIdentifier;
  eTagTemplate?: IETagTemplate;
  isDisabled?: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  selectedProfileFormat?: EProfileFormat | undefined;
}

const PrintViewButton = ({
  eTagExtendedIdentifier,
  eTagTemplate,
  isDisabled,
  timeZone,
  toEntityId,
  selectedProfileFormat,
}: IPrintViewButtonProps): JSX.Element => {
  const handleClick = useCallback(() => {
    const { draft_id, tag_primary_key } = eTagExtendedIdentifier;
    const locationParameters: TDetailPrintPageLocationParameters | undefined =
      eTagTemplate === undefined
        ? draft_id === null || draft_id === undefined
          ? tag_primary_key === undefined
            ? undefined
            : {
                defaultTimeZone: timeZone,
                tagPrimaryKey: tag_primary_key,
                toEntity: toEntityId,
                selectedProfileFormat:
                  selectedProfileFormat !== EProfileFormat.Hourly
                    ? EProfileFormat.StartStop
                    : EProfileFormat.Hourly,
              }
          : {
              defaultTimeZone: timeZone,
              draftId: draft_id,
              toEntity: toEntityId,
              selectedProfileFormat:
                selectedProfileFormat !== EProfileFormat.Hourly
                  ? EProfileFormat.StartStop
                  : EProfileFormat.Hourly,
            }
        : {
            defaultTimeZone: timeZone,
            templateId: eTagTemplate.id,
            toEntity: toEntityId,
            selectedProfileFormat:
              selectedProfileFormat !== EProfileFormat.Hourly
                ? EProfileFormat.StartStop
                : EProfileFormat.Hourly,
          };

    if (locationParameters !== undefined) {
      const href: string = detailPrintPageLocationString(locationParameters);

      window.open(`${process.env.PUBLIC_URL}${href}`, '_blank');
    }
  }, [
    eTagExtendedIdentifier,
    eTagTemplate,
    timeZone,
    toEntityId,
    selectedProfileFormat,
  ]);

  return (
    <Tooltip isDisabled={isDisabled} title='Detail Print View'>
      <IconButton
        icon={<PrintViewIcon />}
        isDisabled={isDisabled}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default PrintViewButton;
