import { AxiosResponse } from 'axios';
import { ETagRequestFormFeedback } from 'components/molecules/ETagRequestFormFeedback/ETagRequestFormFeedback';
import { ETagRequestFormFooter } from 'components/molecules/ETagRequestFormFooter/ETagRequestFormFooter';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
} from 'constants/styles';
import { useValidationSubmissionHandlers } from 'hooks/useValidationSubmissionHandlers';
import { IETagValidationResponse } from 'interfaces/ETag';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

export const Layout = styled.div<{ width: number }>`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
  width: ${(props) => props.width}px;
`;

interface IValidationSubmissionPanelProps {
  children?: ReactNode;
  isValidateAndSubmitDisabled: boolean;
  isValidateDisabled: boolean;
  isValidateHidden: boolean;
  requestDisplayName: string;
  validateRequest: () => Promise<AxiosResponse<IETagValidationResponse>>;
  validateAndSubmitRequest: (
    skipValidation: boolean,
  ) => Promise<AxiosResponse<IETagValidationResponse>>;
}

const ValidationSubmissionPanel = (
  props: IValidationSubmissionPanelProps,
): JSX.Element => {
  const {
    children,
    isValidateAndSubmitDisabled,
    isValidateDisabled,
    isValidateHidden,
    requestDisplayName,
    validateAndSubmitRequest,
    validateRequest,
  } = props;
  const {
    errorMessage,
    isValidateAndSubmitSuccessful,
    isValidateSuccessful,
    isValidating,
    setSkipValidation,
    skipValidation,
    successMessage,
    validationFailures,
    validationWarnings,
    wrapValidateHandler,
    wrapValidateAndSubmitHandler,
  } = useValidationSubmissionHandlers(requestDisplayName);

  const handleValidate = useMemo(
    () => wrapValidateHandler(validateRequest),
    [validateRequest, wrapValidateHandler],
  );

  const handleValidateAndSubmit = useMemo(
    () => wrapValidateAndSubmitHandler(validateAndSubmitRequest),
    [validateAndSubmitRequest, wrapValidateAndSubmitHandler],
  );

  return (
    <>
      {children}
      <ETagRequestFormFeedback
        errorMessage={errorMessage}
        isValidateAndSubmitSuccessful={isValidateAndSubmitSuccessful}
        isValidateSuccessful={isValidateSuccessful}
        isValidating={isValidating}
        requestType={requestDisplayName}
        successMessage={successMessage ?? undefined}
        validationErrors={validationFailures}
        validationWarnings={validationWarnings}
      />
      <ETagRequestFormFooter
        handleValidate={handleValidate}
        handleValidateAndSubmit={handleValidateAndSubmit}
        isDisabled={isValidating}
        isValidateAndSubmitDisabled={isValidateAndSubmitDisabled}
        isValidateDisabled={isValidateDisabled}
        isValidateHidden={isValidateHidden}
        requestType={requestDisplayName}
        setSkipValidation={setSkipValidation}
        skipValidation={skipValidation}
        validationErrors={validationFailures}
      />
    </>
  );
};

export default ValidationSubmissionPanel;
