import { EConfigActions } from './actions';
import { ERetreiveState } from '../../enums/General';
import { IConfigState, TConfigAction } from './types';

const initialConfigState: IConfigState = {
  hideTenantTitleConfig: null,
  retrievingConfig: ERetreiveState.NotRetrieving,
  errorMessage: null,
};

export const configReducer = (
  state: IConfigState = initialConfigState,
  action: TConfigAction,
): IConfigState => {
  switch (action.type) {
    case EConfigActions.RetrieveConfigStart: {
      return { ...state, retrievingConfig: ERetreiveState.RetrievingStarted };
    }
    case EConfigActions.RetrieveConfigSuccess: {
      const { hideTenantTitleConfig } = action.payload;

      return {
        ...state,
        hideTenantTitleConfig: hideTenantTitleConfig,
        retrievingConfig: ERetreiveState.RetrievingCompleted,
      };
    }
    case EConfigActions.RetrieveConfigFailure: {
      const { errorMessage } = action.payload;

      return {
        ...state,
        errorMessage,
        retrievingConfig: ERetreiveState.NotRetrieving,
      };
    }
    default:
      return state;
  }
};
