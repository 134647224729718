import { Switch as AntDesignSwitch } from 'antd';

interface IToggleSwitchProps {
  checkedChildren?: JSX.Element;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (isChecked: boolean) => void;
  unCheckedChildren?: JSX.Element;
}

const ToggleSwitch = ({
  checkedChildren,
  isChecked,
  isDisabled,
  onChange,
  unCheckedChildren,
}: IToggleSwitchProps): JSX.Element => {
  return (
    <AntDesignSwitch
      checked={isChecked}
      checkedChildren={checkedChildren}
      disabled={isDisabled}
      onChange={onChange}
      unCheckedChildren={unCheckedChildren}
    />
  );
};

export default ToggleSwitch;
