import Indicator from 'components/atoms/Indicator/Indicator';
import { CELL_CONTENT_HEIGHT } from 'constants/styles';
import { ERetreiveState } from 'enums/General';
import { IETagSummaryProfilesRecord } from 'interfaces/ETag';
import { IToEntityRecord } from 'interfaces/ToEntity';
import { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { TETagRecordKey } from 'types/ETag';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';

const IndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${CELL_CONTENT_HEIGHT};
  justify-content: center;
`;

interface IProfileLoadIndicatorProps {
  children: ReactNode;
  eTagRecordKey: TETagRecordKey;
  isProfileData?: true;
  toEntityId: TToEntityId;
}

// Redux connections

// Since our mapStateToProps function has a dependency on props, we are not
// using the useSelector hook due to potential edge case bugs mentioned here:
// https://react-redux.js.org/api/hooks#usage-warnings
const mapStateToProps = (
  state: TRootState,
  props: IProfileLoadIndicatorProps,
) => {
  const reducerProps = {
    retrievingProfiles: ERetreiveState.NotRetrieving,
  };
  const toEntityRecord: IToEntityRecord | undefined =
    state.summary.toEntities[props.toEntityId];

  if (toEntityRecord !== undefined) {
    const eTagSummaryProfilesRecord: IETagSummaryProfilesRecord | undefined =
      toEntityRecord.eTagsSummaryProfilesMap[props.eTagRecordKey];

    if (eTagSummaryProfilesRecord !== undefined) {
      reducerProps.retrievingProfiles =
        eTagSummaryProfilesRecord.eTagSummaryProfilesRetrieving;
    }
  }

  return reducerProps;
};

const connector = connect(mapStateToProps);

type TProps = ConnectedProps<typeof connector> & IProfileLoadIndicatorProps;

const ProfileLoadIndicator = (props: TProps): JSX.Element => {
  const { children, isProfileData, retrievingProfiles } = props;

  const isProfileLoading: boolean =
    isProfileData === true &&
    !(
      retrievingProfiles === ERetreiveState.NotRetrieving ||
      retrievingProfiles === ERetreiveState.RetrievingCompleted
    );

  if (isProfileLoading) {
    return (
      <IndicatorContainer>
        <Indicator />
      </IndicatorContainer>
    );
  }

  return <>{children}</>;
};

export default connector(ProfileLoadIndicator);
