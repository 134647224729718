import { ERetreiveState } from 'enums/General';
import { IUserUiConfiguration } from 'interfaces/Config';
import { IToEntity } from 'interfaces/ToEntity';
import {
  EUserAction,
  IToEntityUserState,
  IUserState,
  TToEntityUserStates,
  TUserAction,
} from 'reduxes/User/types';

const initialState: IUserState = {
  selectedToEntity: undefined,
  errorMessage: null,
  retrievingUserInfo: ERetreiveState.NotRetrieving,
  tenantToEntities: [],
  toEntities: [],
  toEntityUserStates: {},
};

export const userReducer = (
  state: IUserState = initialState,
  action: TUserAction,
): IUserState => {
  switch (action.type) {
    case EUserAction.RetrieveUserInfoStart: {
      return { ...state, retrievingUserInfo: ERetreiveState.RetrievingStarted };
    }
    case EUserAction.RetrieveUserInfoSuccess: {
      const { toEntities, tenantToEntities } = action.payload;
      const toEntitiesCopy = [...toEntities];
      const tenantToEntitiesCopy = [...tenantToEntities];
      toEntitiesCopy.sort(
        (toEntity1: IToEntity, toEntity2: IToEntity): number =>
          toEntity1.entity_code.localeCompare(toEntity2.entity_code),
      );
      return {
        ...state,
        toEntities: toEntitiesCopy,
        tenantToEntities: tenantToEntitiesCopy,
        retrievingUserInfo: ERetreiveState.RetrievingCompleted,
      };
    }
    case EUserAction.RetrieveUserInfoFailure: {
      const { errorMessage } = action.payload;

      return {
        ...state,
        errorMessage,
        retrievingUserInfo: ERetreiveState.NotRetrieving,
      };
    }
    case EUserAction.RetrieveToEntityUiConfigStart: {
      const { toEntityId: to_entity } = action.payload;
      let toEntityUserState: IToEntityUserState | undefined =
        state.toEntityUserStates[to_entity];

      if (toEntityUserState === undefined) {
        toEntityUserState = {
          retrieving: ERetreiveState.NotRetrieving,
        };
      }

      return {
        ...state,
        toEntityUserStates: {
          ...state.toEntityUserStates,
          [to_entity]: {
            ...toEntityUserState,
            retrieving: ERetreiveState.RetrievingStarted,
          },
        },
      };
    }
    case EUserAction.RetrieveToEntityUiConfigSuccess: {
      const { toEntityId, toEntityUiConfiguration, userUiConfiguration } =
        action.payload;
      const toEntityUserState: IToEntityUserState | undefined =
        state.toEntityUserStates[toEntityId];

      if (toEntityUserState === undefined) {
        return state;
      }

      return {
        ...state,
        toEntityUserStates: {
          ...state.toEntityUserStates,
          [toEntityId]: {
            ...toEntityUserState,
            retrieving: ERetreiveState.RetrievingCompleted,
            toEntityUiConfig: toEntityUiConfiguration,
            userUiConfig: userUiConfiguration,
          },
        },
      };
    }
    case EUserAction.RetrieveToEntityUiConfigFailure: {
      const { errorMessage, toEntityId: to_entity } = action.payload;
      const toEntityUserState: IToEntityUserState | undefined =
        state.toEntityUserStates[to_entity];

      if (toEntityUserState === undefined) {
        return state;
      }

      return {
        ...state,
        toEntityUserStates: {
          ...state.toEntityUserStates,
          [to_entity]: {
            ...toEntityUserState,
            errorMessage,
            retrieving: ERetreiveState.NotRetrieving,
          },
        },
      };
    }
    case EUserAction.SetSelectedTimeZone: {
      const { selectedTimeZone, toEntityId: to_entity } = action.payload;
      const toEntityUserState: IToEntityUserState | undefined =
        state.toEntityUserStates[to_entity];

      if (toEntityUserState === undefined) {
        return state;
      }

      return {
        ...state,
        toEntityUserStates: {
          ...state.toEntityUserStates,
          [to_entity]: {
            ...toEntityUserState,
            selectedTimeZone,
          },
        },
      };
    }
    case EUserAction.SetSelectedToEntity: {
      const { selectedToEntity } = action.payload;

      return {
        ...state,
        selectedToEntity,
      };
    }
    case EUserAction.SetContactInfo: {
      const { contactInfo } = action.payload;
      const newState: IUserState = { ...state };
      const newToEntityUserStates: TToEntityUserStates = {
        ...newState.toEntityUserStates,
      };

      for (const key in newToEntityUserStates) {
        const newToEntityUserState: IToEntityUserState = {
          ...newToEntityUserStates[key]!,
        };
        const { userUiConfig } = newToEntityUserState;

        if (userUiConfig !== undefined) {
          const newUserUiConfig: IUserUiConfiguration = {
            ...userUiConfig,
          };

          newUserUiConfig.contactInfo = contactInfo;
          newToEntityUserState.userUiConfig = newUserUiConfig;
          newToEntityUserStates[key] = newToEntityUserState;
        }
      }

      newState.toEntityUserStates = newToEntityUserStates;

      return newState;
    }

    default:
      return state;
  }
};
