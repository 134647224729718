import { INotice } from 'interfaces/Notice';
import { TTimeZone } from 'types/DateTime';
import { TNoticeId } from 'types/Notices';
import { TErrorMessage } from 'types/Error';
import { TNoticeHeightMap } from 'types/Notices';
import { ComponentType, useState, useRef } from 'react';
import styled from 'styled-components';
import Notice from './Notice';

const HiddenNoticesContainer = styled.div`
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  visibility: hidden;
`;

const HiddenNotice = styled(Notice)`
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
`;

interface IHiddenNotice extends INotice {
  errorMessage: TErrorMessage;
}

interface IHiddenNoticesProps {
  noticeHeightMap: TNoticeHeightMap;
  hiddenNotices: IHiddenNotice[];
  setNoticeHeight: (notice_id: TNoticeId, height: number) => void;
  timeZone: TTimeZone;
}

const HiddenNotices = (props: IHiddenNoticesProps): JSX.Element => {
  const { hiddenNotices, setNoticeHeight, timeZone } = props;

  const handleSetNoticeHeight = (notice_id: TNoticeId) => (height: number) => {
    setNoticeHeight(notice_id, height);
  };

  return (
    <HiddenNoticesContainer>
      {hiddenNotices.map(
        (hiddenNotice: IHiddenNotice): JSX.Element => (
          <HiddenNotice
            notice={hiddenNotice}
            errorMessage={hiddenNotice.errorMessage}
            key={hiddenNotice.notice_id}
            setNoticeHeight={handleSetNoticeHeight(hiddenNotice.notice_id)}
            timeZone={timeZone}
          />
        ),
      )}
    </HiddenNoticesContainer>
  );
};

export interface IwithNoticeHeightMapProps {
  getNoticeHeightMap: () => TNoticeHeightMap;
  recalculateNoticeHeightMap: (
    notices: INotice[],
    noticesErrorMap: Record<TNoticeId, TErrorMessage>,
  ) => void;
  resetNoticeHeightMap: () => void;
  setNoticesForHeightMap: (
    notices: INotice[],
    noticesErrorMap: Record<TNoticeId, TErrorMessage>,
  ) => void;
  timeZone: TTimeZone;
}

export const withNoticeHeightMap =
  <T extends IwithNoticeHeightMapProps>(Component: ComponentType<T>) =>
  (
    props: Omit<
      T,
      | 'getNoticeHeightMap'
      | 'recalculateNoticeHeightMap'
      | 'resetNoticeHeightMap'
      | 'setNoticesForHeightMap'
    >,
  ) => {
    const { timeZone } = props;
    const [hiddenNotices, setHiddenNotices] = useState<IHiddenNotice[]>([]);
    const noticeHeightMapRef = useRef<TNoticeHeightMap>({});
    const nextNoticeHeightMapRef = useRef<TNoticeHeightMap>({});

    const getAlertHeightMap = (): TNoticeHeightMap =>
      noticeHeightMapRef.current;

    const resetAlertHeightMap = () => {
      nextNoticeHeightMapRef.current = {};
    };

    const setNoticesForHeightMap = (
      notices: INotice[],
      noticesErrorMap: Record<TNoticeId, TErrorMessage>,
    ) => {
      setHiddenNotices(
        notices.map((notice: INotice): IHiddenNotice => {
          const errorMessage: TErrorMessage | undefined =
            noticesErrorMap[notice.notice_id];

          return {
            ...notice,
            errorMessage: errorMessage || errorMessage,
          };
        }),
      );
    };

    const recalculateNoticeHeightMap = (
      notices: INotice[],
      noticesErrorMap: Record<TNoticeId, TErrorMessage>,
    ) => {
      resetAlertHeightMap();

      setNoticesForHeightMap(notices, noticesErrorMap);
    };

    const setNoticesHeight = (notice_id: TNoticeId, height: number) => {
      nextNoticeHeightMapRef.current[notice_id] = height;

      const noticeHeightMapKeys: TNoticeId[] = Object.keys(
        nextNoticeHeightMapRef.current,
      );

      if (
        (hiddenNotices
          .map((hiddenAlert: IHiddenNotice): boolean =>
            noticeHeightMapKeys.includes(hiddenAlert.notice_id),
          )
          .reduce(
            (previous: boolean, current: boolean): boolean =>
              previous && current,
          ),
        true)
      ) {
        noticeHeightMapRef.current = nextNoticeHeightMapRef.current;
      }
    };

    return (
      <>
        <Component
          {...(props as T)}
          getNoticeHeightMap={getAlertHeightMap}
          recalculateNoticeHeightMap={recalculateNoticeHeightMap}
          resetNoticeHeightMap={resetAlertHeightMap}
          setNoticesForHeightMap={setNoticesForHeightMap}
        />
        <HiddenNotices
          noticeHeightMap={noticeHeightMapRef.current}
          hiddenNotices={hiddenNotices}
          setNoticeHeight={setNoticesHeight}
          timeZone={timeZone}
        />
      </>
    );
  };
