import { PlusCircleOutlined } from '@ant-design/icons';
import { Dropdown as AntDesignDropdown, Menu as AntDesignMenu } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import { OPERATION_OPTIONS } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/constants';
import { EOperation } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IOption } from 'interfaces/Component';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

const AddIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IOperationSelectorProps {
  allowBefore?: boolean;
  allowBeforeOnly?: boolean;
  isDisabled?: boolean;
  onChange?: (operation: EOperation) => void;
}

const OperationSelector = ({
  allowBefore,
  allowBeforeOnly,
  isDisabled,
  onChange,
}: IOperationSelectorProps): JSX.Element => {
  const handleOperationSelect = useCallback(
    (menuInfo: MenuInfo) => {
      onChange?.(menuInfo.key as EOperation);
    },
    [onChange],
  );

  const operationMenu = useMemo((): JSX.Element => {
    let options: IOption<EOperation>[] = [];

    if (allowBefore) {
      if (allowBeforeOnly) {
        options = OPERATION_OPTIONS.filter(
          (option: IOption<EOperation>): boolean =>
            option.value === EOperation.OperatorBefore,
        );
      } else {
        options = OPERATION_OPTIONS;
      }
    } else {
      options = OPERATION_OPTIONS.filter(
        (option: IOption<EOperation>): boolean =>
          option.value !== EOperation.OperatorBefore,
      );
    }

    return (
      <AntDesignMenu onClick={handleOperationSelect}>
        {options.map(
          (option: IOption<EOperation>): JSX.Element => (
            <AntDesignMenu.Item key={option.value}>
              {option.label}
            </AntDesignMenu.Item>
          ),
        )}
      </AntDesignMenu>
    );
  }, [allowBefore, allowBeforeOnly, handleOperationSelect]);

  return (
    <AntDesignDropdown
      disabled={isDisabled}
      overlay={operationMenu}
      trigger={['click']}
    >
      <IconButton
        icon={<AddIcon />}
        isContained={true}
        isDisabled={isDisabled}
        noBorder={true}
        transparentBackground={true}
      />
    </AntDesignDropdown>
  );
};

export default OperationSelector;
