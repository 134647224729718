import { ERROR_MESSAGE_MAX_WIDTH } from 'components/atoms/ErrorMessage/constants';
import { ERROR_RED, STANDARD_SPACING_VALUE } from 'constants/styles';
import styled from 'styled-components';

interface IErrorMessageProps {
  maxWidth?: string;
  topMargin?: number;
}

const ErrorMessage = styled.span<IErrorMessageProps>`
  color: ${ERROR_RED};
  font-weight: bold;
  margin-top: ${(props) =>
    props.topMargin === undefined ? STANDARD_SPACING_VALUE : props.topMargin}px;
  max-width: ${(props) =>
    props.maxWidth === undefined ? ERROR_MESSAGE_MAX_WIDTH : props.maxWidth};
  text-align: left;
`;

export default ErrorMessage;
