import { CheckCircleTwoTone } from '@ant-design/icons';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import AlertBody from 'components/molecules/AlertBody/AlertBody';
import AlertTitle from 'components/molecules/AlertTitle/AlertTitle';
import { ALERT_WIDTH_VALUE } from 'constants/Alert';
import { NO_OP_HANDLER } from 'constants/General';
import {
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
  SUCCESS_GREEN,
} from 'constants/styles';
import { IAlert } from 'interfaces/Alert';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';

const Container = styled.div`
  position: relative;
`;

const Layout = styled(SeparatedRowLayout)`
  width: 100%;
`;

const AlertContent = styled.div`
  padding: ${STANDARD_SPACING_VALUE * 1.5}px ${STANDARD_SPACING}
    ${STANDARD_SPACING_VALUE * 1.5}px ${STANDARD_SPACING_VALUE * 2}px;
  width: ${ALERT_WIDTH_VALUE + 72}px;
`;

const StyledAlertTitle = styled(AlertTitle)`
  margin-bottom: ${STANDARD_SPACING};
`;

const AcknowledgedIcon = styled(CheckCircleTwoTone)`
  font-size: 18px;
  padding-top: 16px;
`;

const IconFiller = styled.div`
  height: 18px;
  width: 18px;
`;

interface IAlertProps {
  alert: IAlert;
  className?: string;
  errorMessage: TErrorMessage;
  setAlertHeight?: (height: number) => void;
  timeZone: TTimeZone;
}

const Alert = (props: IAlertProps): JSX.Element => {
  const { alert, className, errorMessage, setAlertHeight, timeZone } = props;
  const alertRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  // We use local state to keep a copy of the errorMessage so that we can
  // trigger a render by updating this local state. The render will then
  // ensure that we update the alert height with the newly updated
  // errorMessage.
  const [internalErrorMessage, setInternalErrorMessage] =
    useState<TErrorMessage>(errorMessage);
  const errorMessageSetterRef =
    useRef<(errorMessage: TErrorMessage) => void>(NO_OP_HANDLER);

  useEffect(() => {
    setInternalErrorMessage(errorMessage);

    errorMessageSetterRef.current(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (alertRef.current && setAlertHeight !== undefined) {
      setAlertHeight(alertRef.current.getBoundingClientRect().height);
    }
  }, [alert.alert_id, alertRef, internalErrorMessage, setAlertHeight]);

  return (
    <Container className={className}>
      <Layout>
        <AlertContent ref={alertRef}>
          <StyledAlertTitle
            group={alert.group}
            name={alert.name}
            severity={alert.severity}
          />
          <AlertBody
            body={alert.alert_body}
            creationTime={alert.created_at_time}
            errorMessageSetterRef={errorMessageSetterRef}
            timeZone={timeZone}
            toEntityId={alert.to_entity}
          />
        </AlertContent>
        {alert.acknowledged ? (
          <AcknowledgedIcon twoToneColor={SUCCESS_GREEN} />
        ) : (
          <IconFiller />
        )}
      </Layout>
    </Container>
  );
};

export default Alert;
