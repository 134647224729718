import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { hasMultipleEnergyProfiles } from 'components/organisms/ProfileInformationView/SameEnergyProfilesCheckbox/helpers';
import { EViewMode } from 'enums/View';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetUseUniqueProfiles } from 'reduxes/Detail/actions';
import { TRootState } from 'types/Redux';

const retrieveSameEnergyProfilesCheckboxState = (state: TRootState) => {
  const { physicalSegmentsProfiles, useUniqueProfiles, viewMode } =
    state.detail.present;

  return { physicalSegmentsProfiles, useUniqueProfiles, viewMode };
};

interface IProps {
  isDisabled?: boolean;
}

const SameEnergyProfilesCheckbox = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const { physicalSegmentsProfiles, useUniqueProfiles, viewMode } = useSelector(
    retrieveSameEnergyProfilesCheckboxState,
  );
  const { isDisabled } = props;

  const allowMultipleEnergyProfiles: boolean = useMemo(() => {
    if (
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF
    ) {
      return hasMultipleEnergyProfiles(physicalSegmentsProfiles);
    }

    return true;
  }, [physicalSegmentsProfiles, viewMode]);

  const handleUseUniqueProfilesChange = useCallback(
    (useUniqueProfiles: boolean) => {
      dispatch(
        detailSetUseUniqueProfiles({
          useUniqueProfiles: !useUniqueProfiles,
        }),
      );
    },
    [dispatch],
  );

  const adjustedIsDisabled: boolean =
    isDisabled || !allowMultipleEnergyProfiles;
  const checked: boolean = !useUniqueProfiles;

  return (
    <Tooltip
      isDisabled={adjustedIsDisabled}
      title={
        checked
          ? 'Uncheck to Allow Entering Different Energy Profiles for Each Physical Path to Account for Transmission Physical Loss'
          : 'Check to Have All Physical Paths Referring to the Same Energy Profile Id'
      }
    >
      <Checkbox
        checked={checked}
        isDisabled={adjustedIsDisabled}
        label='Same Energy Profiles'
        onChange={handleUseUniqueProfilesChange}
      />
    </Tooltip>
  );
};

export default SameEnergyProfilesCheckbox;
