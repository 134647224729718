import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  LABEL_DARK_THEME,
  LABEL_LIGHT_THEME,
  PCI_LOGO_RED,
} from 'components/pages/LandingPage/constants';
import { ETheme } from 'enums/Style';
import { IThemedProps } from 'interfaces/Component';
import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledPciLogo = styled.img<ImgHTMLAttributes<HTMLImageElement>>`
  width: 70px;
  padding-left: 4px;
  padding-right: 4px;
`;

const StyledEtagPlusLogo = styled.img<ImgHTMLAttributes<HTMLImageElement>>`
  width: 80px;
  padding-left: 4px;
  padding-top: 4px;
`;

const BANNER_TEXT_STYLES = `
  font-size: 1.8em;
  font-weight: bold;
  padding-left: 8px;
`;

const StyledEnvironmentBannerLabel = styled.span<IThemedProps>`
  color: ${(props) =>
    process.env.REACT_APP_BANNER_LABEL?.startsWith('mr')
      ? props.currentTheme === ETheme.Light
        ? LABEL_LIGHT_THEME
        : LABEL_DARK_THEME
      : process.env.REACT_APP_BANNER_LABEL_COLOR ?? PCI_LOGO_RED};
  ${BANNER_TEXT_STYLES}
`;

const PciLogo = (props: IThemedProps): JSX.Element => {
  const { currentTheme } = props;
  return (
    <SeparatedRowLayout>
      <StyledPciLogo
        src={
          currentTheme === ETheme.Light
            ? `${process.env.PUBLIC_URL}/assets/images/pci-logo.png`
            : `${process.env.PUBLIC_URL}/assets/images/pci-logo-inverted.png`
        }
      />
      <StyledEtagPlusLogo
        src={
          currentTheme === ETheme.Light
            ? `${process.env.PUBLIC_URL}/assets/images/e-tag-plus-light-theme.png`
            : `${process.env.PUBLIC_URL}/assets/images/e-tag-plus-dark-theme.png`
        }
      />
      <StyledEnvironmentBannerLabel currentTheme={currentTheme}>{`${
        (process.env.REACT_APP_BANNER_LABEL === 'PROD'
          ? ''
          : process.env.REACT_APP_BANNER_LABEL) ?? ''
      }`}</StyledEnvironmentBannerLabel>
    </SeparatedRowLayout>
  );
};

export default PciLogo;
