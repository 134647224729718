import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ContainedText from 'components/molecules/ContainedText/ContainedText';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import {
  alertEffectOptions,
  autoAcknowledgeOptions,
  onScreenTimeOptions,
  soundPlayRepetitionOptions,
  subscriptionOptions,
} from 'components/molecules/ToEntityAlertConfiguration/constants';
import { EAlertEffectOption } from 'components/molecules/ToEntityAlertConfiguration/types';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { EAlertEffect, EAlertSound } from 'enums/Alert';
import { IAlertRule, IEditableAlertConfiguration } from 'interfaces/Alert';
import { IOption } from 'interfaces/Component';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const LABEL_MAX_WIDTH = '216px';

const Label = styled(ContainedText)`
  width: ${LABEL_MAX_WIDTH};
`;

// Specialise Select components
const SubscriptionSelect = styled((props: ISelectProps<boolean>) =>
  Select<boolean>(props),
)`
  width: 103px;
`;

const AlertEffectSelect = styled((props: ISelectProps<EAlertEffect>) =>
  Select<EAlertEffect>(props),
)`
  width: 167px;
`;

const OnScreenTimeSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 151px;
`;

const SoundPlayRepetitionSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 139px;
`;

const AutoAcknowledgeSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 183px;
`;

interface IProps {
  alertRule: IAlertRule | undefined;
  editableAlertConfiguration: IEditableAlertConfiguration;
  onChange: (editableAlertConfiguration: IEditableAlertConfiguration) => void;
}

const ToEntityAlertConfiguration = (props: IProps): JSX.Element => {
  const { alertRule, editableAlertConfiguration, onChange } = props;
  const [filteredAlertEffectOptions, setFilteredAlertEffectOptions] = useState<
    IOption<EAlertEffect>[]
  >([]);

  useEffect(() => {
    if (alertRule !== undefined) {
      if (alertRule.sound_effect === EAlertSound.None) {
        setFilteredAlertEffectOptions([
          alertEffectOptions[EAlertEffectOption.ColourOnly],
          alertEffectOptions[EAlertEffectOption.NoEffect],
        ]);
      } else {
        setFilteredAlertEffectOptions(alertEffectOptions);
      }
    }
  }, [alertRule]);

  const handleSubscriptionSelect = (value: boolean | undefined) => {
    onChange({
      ...editableAlertConfiguration,
      isUnsaved: true,
      // We don't allow clearing a selection so value is never undefined
      subscribe_to_alerts: value!,
    });
  };

  const handleAlertEffectSelect = (value: EAlertEffect | undefined) => {
    onChange({
      ...editableAlertConfiguration,
      // We don't allow clearing a selection so value is never undefined
      alert_effect: value!,
      isUnsaved: true,
    });
  };

  const handleOnScreenTimeSelect = (value: number | undefined) => {
    onChange({
      ...editableAlertConfiguration,
      isUnsaved: true,
      // We don't allow clearing a selection so value is never undefined
      visible_time: value!,
    });
  };

  const handleSoundPlayRepetitionSelect = (value: number | undefined) => {
    onChange({
      ...editableAlertConfiguration,
      isUnsaved: true,
      // We don't allow clearing a selection so value is never undefined
      sound_play_repetitions: value!,
    });
  };

  const handleAutoAcknowledgeSelect = (value: number | undefined) => {
    onChange({
      ...editableAlertConfiguration,
      // We don't allow clearing a selection so value is never undefined
      cutoff_time_in_mins: value!,
      isUnsaved: true,
    });
  };

  return (
    <SeparatedRowLayout>
      <Label
        maxWidth={LABEL_MAX_WIDTH}
        text={alertRule === undefined ? '' : alertRule.name}
      />
      <Tooltip title='Subscribe to Alert'>
        <SubscriptionSelect
          isDisabled={false}
          onChange={handleSubscriptionSelect}
          options={subscriptionOptions}
          placeholder='Subscription'
          value={editableAlertConfiguration.subscribe_to_alerts}
          valueToUid={(value: boolean): string => value.toString()}
        />
      </Tooltip>
      <Tooltip title='Alert Effect'>
        <AlertEffectSelect
          isDisabled={!editableAlertConfiguration.subscribe_to_alerts}
          onChange={handleAlertEffectSelect}
          options={filteredAlertEffectOptions}
          placeholder='Alert effect'
          value={editableAlertConfiguration.alert_effect}
          valueToUid={(value: EAlertEffect): string => value}
        />
      </Tooltip>
      <Tooltip title='Alert on Screen Time'>
        <OnScreenTimeSelect
          isDisabled={!editableAlertConfiguration.subscribe_to_alerts}
          onChange={handleOnScreenTimeSelect}
          options={onScreenTimeOptions}
          placeholder='Duration'
          value={editableAlertConfiguration.visible_time}
          valueToUid={(value: number): string => value.toString()}
        />
      </Tooltip>
      <Tooltip title='Sound Play Repetitions'>
        <SoundPlayRepetitionSelect
          isDisabled={
            !editableAlertConfiguration.subscribe_to_alerts ||
            alertRule === undefined ||
            alertRule.sound_effect === EAlertSound.None
          }
          onChange={handleSoundPlayRepetitionSelect}
          options={soundPlayRepetitionOptions}
          placeholder='Number of repetitions'
          value={editableAlertConfiguration.sound_play_repetitions}
          valueToUid={(value: number): string => value.toString()}
        />
      </Tooltip>
      <Tooltip title='Auto Mark as Read After'>
        <AutoAcknowledgeSelect
          isDisabled={!editableAlertConfiguration.subscribe_to_alerts}
          onChange={handleAutoAcknowledgeSelect}
          options={autoAcknowledgeOptions}
          placeholder='Auto mark as read time'
          value={editableAlertConfiguration.cutoff_time_in_mins}
          valueToUid={(value: number): string => value.toString()}
        />
      </Tooltip>
    </SeparatedRowLayout>
  );
};

export default ToEntityAlertConfiguration;
