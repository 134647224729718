import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import PageLegend from 'components/molecules/PageLegend/PageLegend';
import RadioGroup from 'components/molecules/RadioGroup/RadioGroup';
import ScheduleSubmission from 'components/molecules/ScheduleSubmission/ScheduleSubmission';
import ToEntityConfiguration from 'components/molecules/ToEntityConfiguration/ToEntityConfiguration';
import ToEntityETagCopy from 'components/molecules/ToEntityETagCopy/ToEntityETagCopy';
import ToEntityETagCreate from 'components/molecules/ToEntityETagCreate/ToEntityETagCreate';
import ToEntityETagDelete from 'components/molecules/ToEntityETagDelete/ToEntityETagDelete';
import ToEntityETagTemplateActivate from 'components/molecules/ToEntityETagTemplateActivate/ToEntityETagTemplateActivate';
import ToEntityETagTemplateCopy from 'components/molecules/ToEntityETagTemplateCopy/ToEntityETagTemplateCopy';
import ToEntityETagTemplateCreate from 'components/molecules/ToEntityETagTemplateCreate/ToEntityETagTemplateCreate';
import ToEntityETagTemplateDelete from 'components/molecules/ToEntityETagTemplateDelete/ToEntityETagTemplateDelete';
import ToEntityETagTerminateTag from 'components/molecules/ToEntityETagTerminateTag/ToEntityETagTerminateTag';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import ValidateOrSubmitNewTag from 'components/molecules/ValidateOrSubmitNewTag/ValidateOrSubmitNewTag';
import DetailChangeRequest from 'components/organisms/DetailChangeRequest/DetailChangeRequest';
import ETagManagerRedoButton from 'components/organisms/ETagManager/ETagManagerRedoButton';
import ETagManagerUndoButton from 'components/organisms/ETagManager/ETagManagerUndoButton';
import Legend from 'components/organisms/ETagManager/Legend';
import PageModeButton from 'components/organisms/ETagManager/PageModeButton';
import PrintViewButton from 'components/organisms/ETagManager/PrintViewButton';
import Refresher from 'components/organisms/ETagManager/Refresher/Refresher';
import SaveDetailsButton from 'components/organisms/ETagManager/SaveDetailsButton/SaveDetailsButton';
import ETagManagerConfigurator, {
  IETagManagerConfiguratorProps,
} from 'components/organisms/ETagManagerConfigurator/ETagManagerConfigurator';
import { ToEntityMonitorConfiguratorIcon } from 'components/organisms/ToEntityMonitorConfigurator/ToEntityMonitorConfigurator';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import { DISPLAY_VIEW_OPTIONS } from 'constants/Detail';
import {
  ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE,
  PUSH_RIGHT_VALUE,
  VIEW_PADDING_VALUE,
} from 'constants/styles';
import { EProfileFormat } from 'enums/Detail';
import { ECompositeState } from 'enums/ETag';
import { EPageMode } from 'enums/Page';
import { EDisplayView, EViewMode } from 'enums/View';
import { IETagManagerConfiguration } from 'interfaces/Detail';
import {
  IETagExtendedIdentifier,
  IETagTagId,
  IETagTemplate,
} from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { SetStateAction, useMemo } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage, TErrorType } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';
import { getEtagCopyPayload } from './helpers';
import {
  ETAG_MANAGER_CONFIGURATOR_MODAL_WIDTH_VALUE,
  ETAG_MANAGER_CONFIGURATOR_WITH_PROFILE_FORMAT_MODAL_WIDTH_VALUE,
} from './constants';
import DownloadTagButton from '../../molecules/DownloadTagButton/DownloadTagButton';
import { TThemeValue } from '../../../types/Style';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import NavigationActions from '../../atoms/NavigationActions/NavigationActions';

const Layout = styled(SeparatedRowLayout)`
  padding: ${ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE}px
    ${VIEW_PADDING_VALUE}px;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

interface IETagManagerActionBarProps {
  allowDisplayViewSelection: boolean;
  compositeState: ECompositeState | null;
  defaultContactInfo?: IContactInfo;
  encodedPermissionsId: string;
  errorMessage: TErrorMessage;
  errorType: TErrorType;
  eTagExtendedIdentifier: IETagExtendedIdentifier;
  eTagManagerConfiguration: IETagManagerConfiguration;
  eTagTemplate: IETagTemplate | undefined;
  isDetailDeleted: boolean;
  isDetailEdited: boolean;
  isDetailLoading: boolean;
  isDetailUpdating: boolean;
  isSaveDisabled: boolean;
  numberOfDetailUpdates: number;
  numberOfRefreshes: number;
  onDraftDeleteSuccess: () => void;
  onTemplateActivateSuccess: () => void;
  onTemplateDeleteSuccess: () => void;
  onPageModeChange: (pageMode: EPageMode) => void;
  onRefresh: () => Promise<void>;
  onSave: () => void;
  pageMode: EPageMode;
  previousIsDetailDeleted: boolean | undefined;
  previousIsDetailUpdating: boolean | undefined;
  securityKey?: string;
  selectedDisplayView: EDisplayView | undefined;
  selectedProfileFormat?: EProfileFormat | undefined;
  setNumberOfDetailUpdates: (value: SetStateAction<number>) => void;
  setSelectedDisplayView: (displayView: EDisplayView | undefined) => void;
  startDate: string | null;
  tagId: IETagTagId | null;
  tagPrimaryKey: string | undefined;
  timeZone: TTimeZone;
  toEntity: IToEntity | null;
  toEntityId: TToEntityId;
  updateETagManagerConfiguration: (
    eTagManagerConfiguration: IETagManagerConfiguration,
  ) => Promise<void>;
  viewMode: EViewMode;
  viewModeErrorMessage: TErrorMessage;
}

/*const retrieveETagManagerState = (state: TRootState) => {
  const { template_id } = state.detail.present;

  return {
    template_id,
  };
};*/

const ETagManagerActionBar = ({
  allowDisplayViewSelection,
  compositeState,
  defaultContactInfo,
  encodedPermissionsId,
  errorMessage,
  errorType,
  eTagExtendedIdentifier,
  eTagManagerConfiguration,
  eTagTemplate,
  isDetailDeleted,
  isDetailEdited,
  isDetailLoading,
  isDetailUpdating,
  isSaveDisabled,
  numberOfDetailUpdates,
  numberOfRefreshes,
  onDraftDeleteSuccess,
  onTemplateActivateSuccess,
  onTemplateDeleteSuccess,
  onPageModeChange,
  onRefresh,
  onSave,
  pageMode,
  previousIsDetailDeleted,
  previousIsDetailUpdating,
  securityKey,
  selectedDisplayView,
  selectedProfileFormat,
  setNumberOfDetailUpdates,
  setSelectedDisplayView,
  startDate,
  tagId,
  tagPrimaryKey,
  timeZone,
  toEntity,
  toEntityId,
  updateETagManagerConfiguration,
  viewMode,
  viewModeErrorMessage,
}: IETagManagerActionBarProps): JSX.Element => {
  /*const { template_id } = useSelector(retrieveETagManagerState);*/
  const isDisabled: boolean =
    isDetailDeleted || isDetailLoading || isDetailUpdating;
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;
  const allowRequest: boolean =
    viewMode === EViewMode.EditETagAdjustment ||
    viewMode === EViewMode.EditETagAdjustmentWithATF ||
    viewMode === EViewMode.EditETagCorrection;

  const LegendContent: JSX.Element = useMemo(
    () => <Legend viewMode={viewMode} />,
    [viewMode],
  );

  const handleDisplayViewChange = (displayView: EDisplayView | undefined) => {
    setSelectedDisplayView(displayView);
  };

  const themeSwitcher = useThemeSwitcher();
  const currentTheme = themeSwitcher.currentTheme as TThemeValue;

  return (
    <Layout>
      <PageLegend
        content={LegendContent}
        id='tableLegend'
        tooltipPlacement='topLeft'
      />
      {toEntity === null ? null : (
        <>
          <ToEntityConfiguration<IETagManagerConfiguratorProps>
            Configurator={ETagManagerConfigurator}
            configuratorProps={{
              eTagManagerConfiguration,
              updateETagManagerConfiguration,
              compositeState,
            }}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'managerConfiguration',
            ])}
            icon={<ToEntityMonitorConfiguratorIcon />}
            isDisabled={eTagManagerConfiguration === undefined}
            label=''
            modalWidth={
              compositeState === ECompositeState.Draft
                ? ETAG_MANAGER_CONFIGURATOR_MODAL_WIDTH_VALUE
                : ETAG_MANAGER_CONFIGURATOR_WITH_PROFILE_FORMAT_MODAL_WIDTH_VALUE
            }
            title='Detail Screen Configurator'
            toEntity={toEntity}
            tooltipPlacement='topLeft'
          />
          {/*{viewMode === EViewMode.ReviewETagTemplate ||
          viewMode === EViewMode.EditETagTemplate ? (
            <TransmissionPriorityConfiguration
              toEntityId={toEntityId}
              isDisabled={false}
              icon={<UnorderedListOutlined />}
              modalWidth={800}
              templateId={template_id}
              toEntity={toEntity}
              viewMode={viewMode}
              tooltipPlacement={'topLeft'}
              title='Segment Transmission Priority'
            />
          ) : null}*/}
        </>
      )}
      {pageMode === EPageMode.None ? null : (
        <PageModeButton
          encodedPermissionsId={encodeIds([encodedPermissionsId, 'pageMode'])}
          isDisabled={
            isDisabled ||
            (viewMode === EViewMode.ReviewETagDraft &&
              compositeState !== ECompositeState.Draft)
          }
          onPageModeChange={onPageModeChange}
          pageMode={pageMode}
          viewModeErrorMessage={viewModeErrorMessage}
        />
      )}
      {
        <PrintViewButton
          eTagExtendedIdentifier={eTagExtendedIdentifier}
          eTagTemplate={eTagTemplate}
          isDisabled={isDisabled}
          timeZone={timeZone}
          toEntityId={toEntityId}
          selectedProfileFormat={selectedProfileFormat}
        />
      }
      <UserTimeZoneSelector />
      <Refresher
        defaultAutoRefreshInSeconds={
          eTagManagerConfiguration?.defaultAutoRefreshInSeconds
        }
        eTagExtendedIdentifier={eTagExtendedIdentifier}
        isDetailEdited={isDetailEdited}
        isDetailLoading={isDetailLoading}
        isDisabled={isDisabled}
        numberOfDetailUpdates={numberOfDetailUpdates}
        onRefresh={onRefresh}
        viewMode={viewMode}
      />
      {pageMode === EPageMode.Edit ? (
        <>
          <ETagManagerUndoButton isDisabled={isDisabled} />
          <ETagManagerRedoButton isDisabled={isDisabled} />
        </>
      ) : null}
      {allowDisplayViewSelection ? (
        <StyledTooltip title='Display View'>
          <RadioGroup
            isDisabled={isDisabled}
            onChange={handleDisplayViewChange}
            options={DISPLAY_VIEW_OPTIONS}
            value={selectedDisplayView}
            valueToUid={(value: EDisplayView): string => value as string}
          />
        </StyledTooltip>
      ) : null}
      {allowRequest ? (
        <DetailChangeRequest
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'detailChangeRequest',
          ])}
          id='detailChangeRequest'
          isDetailDeleted={isDetailDeleted}
          isDetailUpdating={isDetailUpdating}
          isDisabled={isDisabled}
          previousIsDetailDeleted={previousIsDetailDeleted}
          previousIsDetailUpdating={previousIsDetailUpdating}
          securityKey={securityKey}
          viewMode={viewMode}
        />
      ) : null}
      {(viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF) &&
      pageMode === EPageMode.Edit &&
      securityKey !== undefined ? (
        <ToEntityETagTerminateTag
          compositeState={compositeState}
          defaultContactInfo={defaultContactInfo}
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'eTagTerminateTag',
          ])}
          isDisabled={isDisabled}
          securityKey={securityKey}
          shouldContactInfoUpdate={hasDetailChanged}
          tagId={tagId}
          timeZone={timeZone}
          toEntityId={toEntityId}
        />
      ) : null}
      {viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate ? (
        <SaveDetailsButton
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'saveDetails',
          ])}
          errorMessage={errorMessage}
          errorType={errorType}
          isDisabled={!isDetailEdited || isDisabled || isSaveDisabled}
          onSave={onSave}
        />
      ) : null}
      {viewMode === EViewMode.ReviewETagTemplate &&
      eTagTemplate !== undefined ? (
        <>
          <ToEntityETagCreate
            contactInfo={defaultContactInfo}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagCreate',
            ])}
            eTagExtendedIdentifier={eTagExtendedIdentifier}
            isDisabled={isDisabled || eTagTemplate.active !== true}
            template={eTagTemplate}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
          {timeZone === undefined ? null : (
            <ToEntityETagTemplateCopy
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'eTagTemplateCopy',
              ])}
              eTagTemplate={eTagTemplate}
              timeZone={timeZone}
              toEntityId={toEntityId}
            />
          )}
          <ToEntityETagTemplateActivate
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagTemplateActivate',
            ])}
            isDisabled={isDisabled || eTagTemplate === undefined}
            onSuccess={onTemplateActivateSuccess}
            template={eTagTemplate}
            toEntityId={toEntityId}
          />
          <ToEntityETagTemplateDelete
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagTemplateDelete',
            ])}
            isDisabled={isDisabled}
            onSuccess={onTemplateDeleteSuccess}
            template={eTagTemplate}
            toEntityId={toEntityId}
          />
        </>
      ) : null}
      {viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection ||
      viewMode === EViewMode.ReviewETag ? (
        <ToEntityETagCopy
          contactInfo={defaultContactInfo}
          encodedPermissionsId={encodeIds([encodedPermissionsId, 'eTagCopy'])}
          eTagCopyPayload={getEtagCopyPayload(
            compositeState,
            startDate,
            tagId,
            tagPrimaryKey,
          )}
          timeZone={timeZone}
          toEntityId={toEntityId}
        />
      ) : null}
      {viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection ||
      viewMode === EViewMode.ReviewETag ||
      viewMode === EViewMode.ReviewETagDraft ? (
        <ToEntityETagTemplateCreate
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'eTagTemplateCreate',
          ])}
          eTagExtendedIdentifier={eTagExtendedIdentifier}
          isDisabled={
            isDisabled ||
            (viewMode === EViewMode.EditETagDraft && isDetailEdited)
          }
          timeZone={timeZone}
          toEntityId={toEntityId}
        />
      ) : null}
      {viewMode === EViewMode.EditETagDraft ? (
        <>
          <ToEntityETagDelete
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagDraftDelete',
            ])}
            eTagExtendedIdentifier={eTagExtendedIdentifier}
            isDisabled={isDisabled}
            onSuccess={onDraftDeleteSuccess}
            toEntityId={toEntityId}
          />
          {eTagExtendedIdentifier.draft_id !== null &&
          securityKey !== undefined ? (
            <ValidateOrSubmitNewTag
              draftId={eTagExtendedIdentifier.draft_id}
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'validateOrSubmitNewTag',
              ])}
              id='validateOrSubmitNewTag'
              isDisabled={isDisabled}
              security_key={securityKey}
              setNumberOfDetailUpdates={setNumberOfDetailUpdates}
              tag_id={tagId}
              timeZone={timeZone}
              toEntityId={toEntityId}
            />
          ) : null}
        </>
      ) : null}
      {eTagExtendedIdentifier.draft_id !== null &&
      (viewMode === EViewMode.EditETagDraft ||
        viewMode === EViewMode.ReviewETagDraft) ? (
        <ScheduleSubmission
          contactName={defaultContactInfo?.contact ?? ''}
          draftId={eTagExtendedIdentifier.draft_id}
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'scheduleDraftSubmission',
          ])}
          isDisabled={isDisabled}
          numberOfRefreshes={numberOfRefreshes}
          startDate={startDate}
          timeZone={timeZone}
          toEntityId={toEntityId}
        />
      ) : null}
      {tagId ? (
        <NavigationActions right={PUSH_RIGHT_VALUE}>
          <DownloadTagButton
            currentTheme={currentTheme}
            primaryKey={tagPrimaryKey}
            toEntityId={toEntityId}
          />
        </NavigationActions>
      ) : null}
    </Layout>
  );
};

export default ETagManagerActionBar;
