import DateTimeContainer from 'components/molecules/DateTimeContainer/DateTimeContainer';
import { ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX } from 'constants/Detail';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EProfileDataGridCellType } from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { FormatterProps } from 'react-data-grid';
import { useSelector } from 'react-redux';
import { IDetailEnergyProfileSnapshot } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';
import { TRootState } from 'types/Redux';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface IContainerProps {
  backgroundColour?: string;
}

const Container = styled.div<IContainerProps>`
  height: 100%;
  padding: 0 8px;
  position: absolute;
  width: 100%;

  ${(props) =>
    props.backgroundColour
      ? `background-color: ${props.backgroundColour};`
      : ''}
`;

interface IEnergyProfileSnapshotsRequestCellProps
  extends FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow> {
  getBackgroundColour?: (last_request_type: ERequestType) => string | undefined;
}

const retrieveEnergyProfileSnapshotsRequestCellState = (state: TRootState) => {
  const { selectedRequestKey, viewMode } = state.detail.present;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    selectedRequestKey,
    timeZone,
    viewMode,
  };
};

const EnergyProfileSnapshotsRequestCell = ({
  column: { key },
  getBackgroundColour,
  isCellSelected,
  row,
}: IEnergyProfileSnapshotsRequestCellProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { selectedRequestKey, timeZone, viewMode } = useSelector(
    retrieveEnergyProfileSnapshotsRequestCellState,
  );
  const requestKey: string = key.replace(
    ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX,
    '',
  );
  const isColumnSelected: boolean = requestKey === selectedRequestKey;

  const { backgroundColour, content } = useMemo(() => {
    const cell: IProfileDataGridCell | null | undefined = row[key];

    if (cell === null || cell === undefined) {
      return {
        content: null,
      };
    }

    const { type, value } = cell;

    if (type === EProfileDataGridCellType.DateTimeString) {
      return {
        content: (
          <DateTimeContainer currentTheme={currentTheme!}>
            {ZonedDateTime.parseIso(value as string, timeZone).format(
              DATE_TIME_FORMAT,
            )}
          </DateTimeContainer>
        ),
      };
    } else if (
      type === EProfileDataGridCellType.Number ||
      type === EProfileDataGridCellType.String
    ) {
      return { content: value };
    } else if (type === EProfileDataGridCellType.EnergyProfileSnapshot) {
      const detailEnergyProfileSnapshot: IDetailEnergyProfileSnapshot =
        value as IDetailEnergyProfileSnapshot;

      return {
        backgroundColour:
          viewMode === EViewMode.ReviewETag && getBackgroundColour
            ? getBackgroundColour(detailEnergyProfileSnapshot.last_request_type)
            : undefined,
        content: detailEnergyProfileSnapshot.mw,
      };
    } else {
      throw new Error(
        `Invalid type: ${type} for cell: ${JSON.stringify(cell)}`,
      );
    }
  }, [currentTheme, getBackgroundColour, key, row, timeZone, viewMode]);

  return (
    <Container
      backgroundColour={backgroundColour}
      className={`${
        isColumnSelected && backgroundColour === undefined
          ? 'energy-profile-snapshots-request-column-selected'
          : ''
      } ${
        isCellSelected ? 'energy-profile-snapshots-request-cell-selected' : ''
      }`}
    >
      {content}
    </Container>
  );
};

export default EnergyProfileSnapshotsRequestCell;
