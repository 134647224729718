import { createContext, useCallback, useContext, useState } from 'react';
import { Modal } from 'antd';
import SeverityIcon from '../components/molecules/SeverityIcon/SeverityIcon';
import { ESeverity } from '../enums/General';
import styled from 'styled-components';

const DialogTitleContainer = styled.div`
  display: flex;
  height: 20px;
  > .anticon {
    font-size: 20px;
  }
`;

const DialogContainer = styled.div`
  display: flex;
`;

const DialogTitleMessage = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-left: 15px;
`;

const DialogMessage = styled.p`
  margin: auto;
  font-size: 15px;
`;

interface DialogOptions {
  title: string;
  severity: ESeverity;
  message?: string;
}

interface PromiseInfo {
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  reject: (reason?: any) => void;
}

type ShowDialogHandler = (options: DialogOptions) => Promise<boolean>;

// Create the context, so we can use it in our App
const DialogContext = createContext<ShowDialogHandler>(() => {
  throw new Error('Component is not wrapped with a DialogProvider.');
});

const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DialogOptions>({
    title: '',
    severity: ESeverity.Info,
  });
  const [promiseInfo, setPromiseInfo] = useState<PromiseInfo>();
  const showDialog: ShowDialogHandler = (options) => {
    // When the dialog is shown, keep the promise info so we can resolve later
    return new Promise<boolean>((resolve, reject) => {
      setPromiseInfo({ resolve, reject });
      setOptions(options);
      setOpen(true);
    });
  };
  const handleConfirm = () => {
    // if the Confirm button gets clicked, resolve with `true`
    setOpen(false);
    promiseInfo?.resolve(true);
    setPromiseInfo(undefined);
  };
  const handleCancel = () => {
    // if the dialog gets canceled, resolve with `false`
    setOpen(false);
    promiseInfo?.resolve(false);
    setPromiseInfo(undefined);
  };

  const dialogTitle = useCallback(() => {
    return (
      <DialogTitleContainer>
        <SeverityIcon severity={options.severity} />
        <DialogTitleMessage>{options.title}</DialogTitleMessage>
      </DialogTitleContainer>
    );
  }, [options]);

  return (
    <>
      <Modal
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        visible={open}
        onCancel={handleCancel}
        onOk={handleConfirm}
        title={dialogTitle()}
        width={400}
      >
        <DialogContainer>
          <DialogMessage>{options.message}</DialogMessage>
        </DialogContainer>
      </Modal>
      <DialogContext.Provider value={showDialog}>
        {children}
      </DialogContext.Provider>
    </>
  );
};

// By calling `useDialog()` in a component we will be able to use the `showDialog()` function
export const useDialog = () => {
  return useContext(DialogContext);
};

export default DialogProvider;
