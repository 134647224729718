import { IManagedAlert } from 'components/hocs/withAlertsQueue/types';

export const numberOfOpen = (queue: IManagedAlert[]): number =>
  queue.reduce(
    (previous: number, current: IManagedAlert): number =>
      previous + (current.isOpen ? 1 : 0),
    0,
  );

export const addWithAlertsQueueClass = (): HTMLElement => {
  const container: HTMLElement = window.document.createElement('div');

  container.className = 'with-alerts-queue';

  window.document.body.appendChild(container);

  return container;
};
