import {
  ColDef,
  GetRowIdParams,
  IAggFunc,
  IsGroupOpenByDefaultParams,
  ProcessCellForExportParams,
  RowClassParams,
  RowClickedEvent,
  RowGroupingDisplayType,
  RowNode,
  SideBarDef,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { TAgGridSortState } from 'components/organisms/ToEntityMonitor/types';
import { ETheme } from 'enums/Style';
import { ITransmissionAvailabilityDataSet } from 'interfaces/ETag';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

export type TDataTableKey = number | string;

const StyledGrid = styled(AgGridReact)`
  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  .ag-ltr .ag-floating-filter-button {
    margin-left: 4px;
  }

  .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ag-cell {
    line-height: 17px;
    font-size: 12px;
  }

  .ag-row-selected {
    filter: invert(100%);
  }

  font-family: 'Roboto', sans-serif;
`;

export interface IRowSelection<D> {
  onChange?: (selectedRowKeys: TDataTableKey[], selectedRows: D[]) => void;
  selectedRowKeys: TDataTableKey[];
}

export interface IScrollConfig {
  x?: string | number | true;
  y?: string | number;
}

export interface ITransmissionCapacityDataTableProps {
  aggFuncs?: { [key: string]: IAggFunc };
  allowCollapseAll?: boolean;
  allowExpandAll?: boolean;
  autoGroupColumnDef?: ColDef;
  className?: string;
  clearFilters: boolean;
  collapseAllTrigger?: boolean;
  columns: ColDef[];
  data: ITransmissionAvailabilityDataSet[];
  defaultColumnDefinition?: ColDef;
  defaultSort?: TAgGridSortState[];
  expandAllTrigger?: boolean;
  getRowClass?: (params: RowClassParams) => string | string[] | undefined;
  getRowId: (params: GetRowIdParams) => string;
  groupDefaultExpanded?: number;
  groupDisplayType?: RowGroupingDisplayType;
  handleCollapseAll?: (rowNode: RowNode) => void;
  handleExpandAll?: (rowNode: RowNode) => void;
  isGroupOpenByDefault?: (params: IsGroupOpenByDefaultParams) => boolean;
  isLoading?: boolean;
  maxHeight: string;
  onRowClick?: (event: RowClickedEvent) => undefined;
  optionalColumns?: string[];
  pinnedTopData?: ITransmissionAvailabilityDataSet[];
  setClearFilters: (set: boolean) => void;
  setLoadingWhenDataChange?: boolean;
  setRowIds?: (ids: string[]) => void;
  sideBarDef?: SideBarDef;
  timeZone: TTimeZone | undefined;
  useFilteredValuesForAgg?: boolean;
  visibleOptionalColumns?: string[];
}

const TransmissionCapacityDataTable = (
  props: ITransmissionCapacityDataTableProps,
): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const {
    aggFuncs,
    allowCollapseAll,
    allowExpandAll,
    autoGroupColumnDef,
    className,
    clearFilters,
    collapseAllTrigger,
    columns,
    data,
    defaultColumnDefinition,
    defaultSort,
    getRowClass,
    expandAllTrigger,
    groupDefaultExpanded,
    groupDisplayType,
    handleCollapseAll,
    handleExpandAll,
    isGroupOpenByDefault,
    isLoading,
    maxHeight,
    onRowClick,
    optionalColumns,
    pinnedTopData,
    getRowId,
    setClearFilters,
    setLoadingWhenDataChange,
    setRowIds,
    sideBarDef,
    timeZone,
    useFilteredValuesForAgg,
    visibleOptionalColumns,
  } = props;
  const { currentTheme } = useThemeSwitcher();
  const [tableData, setTableData] = useState<
    ITransmissionAvailabilityDataSet[]
  >([]);
  const [topRowTableData, setTopRowTableData] = useState<
    ITransmissionAvailabilityDataSet[]
  >([]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        if (gridRef.current) {
          // always reset filters before applying new filters on view change
          gridRef.current.columnApi.applyColumnState({
            defaultState: { sort: null },
          });
          gridRef.current.columnApi.applyColumnState({ state: defaultSort });
        }
      }, 0);
    }
  }, [defaultSort, gridRef.current?.columnApi]);

  /**
   * set filters rely on grid values for a column
   * don't set data to empty on refreshes
   * so set filters retain any options they can
   */
  useEffect(() => {
    if (!isLoading) {
      setTableData(data);
      setTopRowTableData(pinnedTopData ?? []);
    }
  }, [data, isLoading, pinnedTopData]);

  /**
   * Prevent the following:
   * Uncaught Error: AG Grid: cannot get grid to draw rows when it is in the middle of drawing rows.
   * Your code probably called a grid API method while the grid was in the render stage.
   * To overcome this, put the API call into a timeout,
   * e.g. instead of api.redrawRows(), call setTimeout(function() { api.redrawRows(); }, 0).
   */
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      if (setLoadingWhenDataChange) {
        gridRef.current.api.showLoadingOverlay();
      }
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.api.setRowData(tableData);
          gridRef.current.api.setPinnedTopRowData(topRowTableData);
        }
      }, 0);
    }
  }, [setLoadingWhenDataChange, tableData, topRowTableData]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      if (isLoading) {
        setTimeout(() => {
          if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
          }
        }, 0);
      } else {
        setTimeout(() => {
          if (gridRef.current) {
            gridRef.current.api.hideOverlay();
          }
        }, 0);
      }
    }
  }, [isLoading]);

  // Optional columns are hidden by default, can be set to visible by passing in a list of field names to be made visible
  // The optional columns load before the columnApi on first load, so we need to check for when the columnApi is loaded
  // in order to set the optional columns as hidden after first load.
  useEffect(() => {
    gridRef.current?.columnApi?.setColumnsVisible(optionalColumns ?? [], false);
    gridRef.current?.columnApi?.setColumnsVisible(
      visibleOptionalColumns ?? [],
      true,
    );
  }, [visibleOptionalColumns, optionalColumns, gridRef.current?.columnApi]);

  useEffect(() => {
    if (!isLoading && allowExpandAll) {
      if (handleExpandAll) {
        gridRef.current?.api?.forEachNode((rowNode: RowNode) => {
          handleExpandAll(rowNode);
        });
      } else {
        gridRef.current?.api?.expandAll();
      }
    }
  }, [allowExpandAll, expandAllTrigger, isLoading, handleExpandAll]);

  useEffect(() => {
    if (!isLoading && allowCollapseAll) {
      if (handleCollapseAll) {
        gridRef.current?.api?.forEachNode((rowNode: RowNode) => {
          handleCollapseAll(rowNode);
        });
      } else {
        gridRef.current?.api?.collapseAll();
      }
    }
  }, [allowCollapseAll, collapseAllTrigger, isLoading, handleCollapseAll]);

  const processCellForClipboard = useCallback(
    (params: ProcessCellForExportParams): any => {
      if (
        params.column.getColDef().chartDataType === 'time' &&
        timeZone &&
        params.value // don't parse empty strings
      ) {
        return ZonedDateTime.fromDate(
          new Date(params.value),
          timeZone,
        ).toIsoString();
      }
      return params.value;
    },
    [timeZone],
  );

  const handleRowDataChanged = () => {
    setTimeout(() => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.refreshCells();
      }
    }, 0);
  };

  useEffect(() => {
    if (clearFilters && gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        if (gridRef.current) {
          gridRef.current.api.setFilterModel([]);
        }
      }, 0);
      setClearFilters(false);
    }
  }, [clearFilters, setClearFilters]);

  const handleModelUpdated = () => {
    if (gridRef.current && gridRef.current.api && setRowIds) {
      const idList: string[] = [];
      gridRef.current.api.forEachNodeAfterFilterAndSort((node: RowNode) => {
        if (node.id) {
          idList.push(node.id);
        }
      });
      setRowIds(idList);
    }
  };

  return (
    <div
      className={
        currentTheme === ETheme.Light
          ? 'ag-theme-alpine'
          : 'ag-theme-alpine-dark'
      }
      style={{ height: maxHeight, width: '100vw - 8px' }}
    >
      <StyledGrid
        aggFuncs={aggFuncs}
        autoGroupColumnDef={autoGroupColumnDef}
        className={className}
        columnDefs={columns}
        defaultColDef={defaultColumnDefinition}
        groupDefaultExpanded={groupDefaultExpanded ?? 0}
        enableBrowserTooltips={true}
        enableRangeSelection={true}
        ensureDomOrder={true}
        getRowClass={getRowClass}
        getRowId={getRowId}
        groupDisplayType={groupDisplayType}
        isGroupOpenByDefault={isGroupOpenByDefault}
        multiSortKey={'ctrl'}
        onModelUpdated={handleModelUpdated}
        onRowClicked={onRowClick}
        onRowDataChanged={handleRowDataChanged}
        overlayLoadingTemplate={
          '<span class="ag-overlay-loading-center">Loading data...</span>'
        }
        processCellForClipboard={processCellForClipboard}
        ref={gridRef}
        rowHeight={20}
        rowBuffer={20}
        rowMultiSelectWithClick={true}
        rowSelection={'single'}
        sideBar={sideBarDef}
        suppressAggFuncInHeader={true}
        suppressAggFilteredOnly={useFilteredValuesForAgg ?? false}
        suppressContextMenu={true}
        suppressCopyRowsToClipboard={true}
        // https://www.ag-grid.com/react-data-grid/value-getters/#example-value-cache
        valueCache={true}
      />
    </div>
  );
};

export default TransmissionCapacityDataTable;
