import ContainedText from 'components/molecules/ContainedText/ContainedText';
import {
  GENERATION_BLOCK_WIDTH_VALUE,
  LOAD_BLOCK_WIDTH_VALUE,
  TRANSMISSION_BLOCK_WIDTH_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import GenerationDiagram from 'components/organisms/PathDiagramView/GenerationDiagram';
import LoadDiagram from 'components/organisms/PathDiagramView/LoadDiagram';
import TransmissionDiagram from 'components/organisms/PathDiagramView/TransmissionDiagram';
import {
  EBlockType,
  ILabelledValue,
  IPathDiagramBlockData,
} from 'components/organisms/PathDiagramView/types';
import { STANDARD_SPACING, STANDARD_SPACING_VALUE } from 'constants/styles';
import styled from 'styled-components';

interface ILayoutProps {
  blockWidth: number;
}

const Layout = styled.div<ILayoutProps>`
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  width: ${(props) => props.blockWidth}px;
`;

const LabelledValues = styled.div`
  padding: ${STANDARD_SPACING};
`;

interface IProps {
  data: IPathDiagramBlockData;
  nodeSize: number;
}

const PathDiagramBlock = (props: IProps): JSX.Element => {
  const {
    data: {
      labelledValues,
      leftNodeLabel,
      lossAccountingMethodNames,
      mw,
      oasisInfos,
      rightNodeLabel,
      transmissionMW,
      type,
    },
    nodeSize,
  } = props;

  const blockWidth: number =
    type === EBlockType.Transmission
      ? TRANSMISSION_BLOCK_WIDTH_VALUE
      : type === EBlockType.Generation
      ? GENERATION_BLOCK_WIDTH_VALUE
      : type === EBlockType.Load
      ? LOAD_BLOCK_WIDTH_VALUE
      : 0;

  return (
    <Layout blockWidth={blockWidth}>
      <LabelledValues>
        {labelledValues.map(
          (labelledValue: ILabelledValue): JSX.Element => (
            <ContainedText
              key={labelledValue.label}
              maxWidth={`${
                blockWidth - STANDARD_SPACING_VALUE - STANDARD_SPACING_VALUE
              }px`}
              text={`${labelledValue.label}: ${labelledValue.value}`}
            />
          ),
        )}
      </LabelledValues>
      {type === EBlockType.Generation ? (
        <GenerationDiagram
          mw={mw}
          nodeLabel={rightNodeLabel}
          nodeSize={nodeSize}
        />
      ) : null}
      {type === EBlockType.Transmission ? (
        <TransmissionDiagram
          leftNodeLabel={leftNodeLabel}
          lossAccountingMethodNames={lossAccountingMethodNames}
          nodeSize={nodeSize}
          oasisInfos={oasisInfos}
          rightNodeLabel={rightNodeLabel}
          transmissionMW={transmissionMW}
        />
      ) : null}
      {type === EBlockType.Load ? (
        <LoadDiagram mw={mw} nodeLabel={leftNodeLabel} nodeSize={nodeSize} />
      ) : null}
    </Layout>
  );
};

export default PathDiagramBlock;
