import { TTimeZone } from 'types/DateTime';
import { Popover as AntDesignPopover } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
  SUCCESS_GREEN,
} from 'constants/styles';
import ContainedText from 'components/molecules/ContainedText/ContainedText';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { DATE_TIME_FORMAT } from 'constants/time';
import { TErrorMessage } from 'types/Error';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Button from 'components/atoms/Button/Button';
import { IDateTimeShortcut } from 'components/molecules/DateTimePicker/types';
import Spinner from 'components/atoms/Spinner/Spinner';
import {
  SHOW_TIME_CONFIGURATION,
  TITLE_TEXT_MAX_WIDTH,
} from 'components/molecules/ScheduleSubmission/constants';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds, isEmptyValue } from 'utils/general';
import usePermissions from 'hooks/usePermissions';
import { IETagScheduledDraftSubmission } from 'interfaces/ETag';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { EDraftScheduledStatusLabel } from 'components/molecules/ScheduleSubmission/interfaces';
import { ScheduleSubmissionValidationList } from './ScheduleSubmissionValidationList';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
`;

const StatusIndicator = styled.text`
  padding-left: 5px;
  color: ${(props) => props.color};
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

const SuccessIcon = styled(CheckCircleTwoTone)`
  font-size: 18px;
`;

interface IScheduleSubmissionPopoverProps {
  encodedPermissionsId: string;
  errorMessage: TErrorMessage;
  isScheduleDisabled: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  onCancel: () => void;
  onSchedule: () => void;
  onSubmissionDateTimeChange?: (startDateTime: ZonedDateTime | null) => void;
  scheduledSubmission: IETagScheduledDraftSubmission | null;
  startDate: string | null;
  status: EDraftScheduledStatusLabel;
  statusColor: string;
  submissionDateTime: ZonedDateTime | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  validationErrors: string[];
  validationWarnings: string[];
}

const ScheduleSubmissionPopover = ({
  encodedPermissionsId,
  errorMessage,
  isScheduleDisabled,
  isLoading,
  isSuccess,
  onCancel,
  onSchedule,
  onSubmissionDateTimeChange,
  scheduledSubmission,
  startDate,
  status,
  statusColor,
  submissionDateTime,
  timeZone,
  toEntityId,
  validationErrors,
  validationWarnings,
}: IScheduleSubmissionPopoverProps): JSX.Element => {
  const schedulePermissions = usePermissions(
    encodeIds([encodedPermissionsId, 'scheduleSubmission'], toEntityId),
  );
  const disabledDate = (dateTime: ZonedDateTime | null): boolean => {
    const tagStartDate: ZonedDateTime | null =
      startDate === null ? null : ZonedDateTime.parseIso(startDate, timeZone);
    const now: ZonedDateTime = ZonedDateTime.now(timeZone);
    if (dateTime !== null) {
      if (dateTime.isAfter(now.add(5, 'days'))) {
        return true;
      }
      if (tagStartDate !== null) {
        if (
          tagStartDate.subtract(1, 'days').isBefore(now) &&
          dateTime.isBefore(tagStartDate.subtract(1, 'days'))
        ) {
          return true;
        }

        if (
          tagStartDate.subtract(1, 'days').isAfter(now) &&
          dateTime.isBefore(now)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const shortCuts: IDateTimeShortcut[] = startDate
    ? [
        {
          dateTime: ZonedDateTime.parseIso(startDate, timeZone).subtract(
            1,
            'days',
          ),
          label: '1 Day Before Tag Start',
        },
      ]
    : [];

  const isScheduled: boolean = status === 'SCHEDULED';

  const statusTooltip: string =
    scheduledSubmission === null || status !== EDraftScheduledStatusLabel.Failed
      ? ''
      : scheduledSubmission.tries_log.length > 0
      ? scheduledSubmission.tries_log[scheduledSubmission.tries_log.length - 1]
          .message
      : '';

  return (
    <Layout>
      {
        <ContainedText
          maxWidth={TITLE_TEXT_MAX_WIDTH}
          text={'Schedule Draft for Submission'}
        />
      }
      {schedulePermissions.isDisplayable ? (
        <SeparatedRowLayout>
          <div>Submission Time:</div>
          <AntDesignPopover placement='top'>
            <DateTimePicker
              disabledDate={disabledDate}
              dateTimeShortcuts={shortCuts}
              format={DATE_TIME_FORMAT}
              onChange={onSubmissionDateTimeChange}
              showTime={SHOW_TIME_CONFIGURATION}
              timeZone={timeZone}
              value={submissionDateTime}
            />
          </AntDesignPopover>
        </SeparatedRowLayout>
      ) : null}
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SeparatedRowLayout>
            <Tooltip
              isDisabled={isEmptyValue(statusTooltip)}
              mouseEnterDelay={0}
              placement={'bottom'}
              title={statusTooltip}
            >
              <div>
                Submission Status:
                <StatusIndicator color={statusColor}>{status}</StatusIndicator>
              </div>
            </Tooltip>
          </SeparatedRowLayout>
          {scheduledSubmission ? (
            <SeparatedRowLayout>
              <div>Submitted By: {scheduledSubmission?.scheduled_by}</div>
            </SeparatedRowLayout>
          ) : null}
          {validationErrors.length > 0 || validationWarnings.length > 0 ? (
            <>
              <ScheduleSubmissionValidationList
                validationFailures={validationErrors}
                validationWarnings={validationWarnings}
              />
            </>
          ) : errorMessage ? (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          ) : isSuccess ? (
            <SeparatedRowLayout>
              <SuccessIcon twoToneColor={SUCCESS_GREEN} />
              <span>
                Schedule Submission Request is valid and has been submitted
              </span>
            </SeparatedRowLayout>
          ) : null}
        </>
      )}
      <Actions>
        {isScheduled ? (
          <Button
            label={'Cancel Draft Submission'}
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'cancelSubmission'],
              toEntityId,
            )}
            onClick={onCancel}
          />
        ) : null}
        <Tooltip
          isDisabled={
            !isScheduleDisabled && status !== EDraftScheduledStatusLabel.Failed
          }
          title={'Schedule failed. Please submit manually.'}
        >
          <Button
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'scheduleSubmission'],
              toEntityId,
            )}
            isDisabled={isScheduleDisabled}
            isPrimary={true}
            label={'Schedule Draft Submission'}
            onClick={onSchedule}
          />
        </Tooltip>
      </Actions>
    </Layout>
  );
};

export default ScheduleSubmissionPopover;
