import { Popover as AntDesignPopover } from 'antd';
import ValidationMessagesList from 'components/molecules/ValidationMessagesList/ValidationMessagesList';
import { ERROR_RED, WARNING_ORANGE } from 'constants/styles';
import { ESeverity } from 'enums/General';
import { IValidationMessage } from 'interfaces/Component';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { compareSeverity } from 'utils/general';

const StyledValidationMessagesList = styled(ValidationMessagesList)`
  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
`;

const TITLE_OVERRIDE_STYLE = `
      font-size: 19px;
      font-weight: bold;
      text-decoration: underline;
    `;

interface ITitleWrapperProps {
  severity?: ESeverity;
}

const TitleWrapper = styled.div<ITitleWrapperProps>`
  ${(props) =>
    props.severity === ESeverity.Error
      ? `
        color: ${ERROR_RED};
        ${TITLE_OVERRIDE_STYLE}
      `
      : props.severity === ESeverity.Warning
      ? `
        color: ${WARNING_ORANGE};
        ${TITLE_OVERRIDE_STYLE}
      `
      : ''}
`;

interface IProps {
  title?: ReactNode;
  validationMessages?: IValidationMessage[];
}

const DetailViewTitle = (props: IProps): JSX.Element => {
  const { title, validationMessages } = props;

  const highestSeverity: ESeverity = useMemo(() => {
    let highestSeverity: ESeverity = ESeverity.Info;

    if (validationMessages !== undefined) {
      validationMessages.forEach((validationMessage: IValidationMessage) => {
        if (
          compareSeverity(validationMessage.severity, highestSeverity) === 1
        ) {
          highestSeverity = validationMessage.severity;
        }
      });
    }

    return highestSeverity;
  }, [validationMessages]);

  return (
    <AntDesignPopover
      content={
        validationMessages === undefined ? null : (
          <StyledValidationMessagesList
            validationMessages={validationMessages}
          />
        )
      }
      placement={'bottomLeft'}
      trigger={
        highestSeverity === ESeverity.Info
          ? title !== 'Market Information'
            ? 'none'
            : 'hover'
          : 'hover'
      }
    >
      <TitleWrapper severity={highestSeverity}>{title}</TitleWrapper>
    </AntDesignPopover>
  );
};

export default DetailViewTitle;
