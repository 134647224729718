import { Input as AntDesignInput, Popover as AntDesignPopover } from 'antd';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { BACKSPACE_KEYCODE } from 'constants/General';
import { US_PHONE_NUMBER_EMPTY_REGEX } from 'constants/input';
import { ERROR_BORDER } from 'constants/styles';
import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { TPlacement } from 'types/General';
import { isEmptyValue } from 'utils/general';
import { verifyPhoneNumber } from 'utils/input';

const US_PHONE_NUMBER_REGEX = /^\(([0-9]{0,3})\)([0-9]{0,3})-([0-9]{0,4})$/;

const getPhoneNumber = (
  areaCode: string | undefined,
  threeDigit: string | undefined,
  fourDigit: string | undefined,
): string =>
  `(${areaCode === undefined ? '' : areaCode})${
    threeDigit === undefined ? '' : threeDigit
  }-${fourDigit === undefined ? '' : fourDigit}`;

interface IContainerProps {
  highlightInput: boolean;
}

const Container = styled.div<IContainerProps>`
  ${(props) =>
    props.highlightInput
      ? `
    input {
      ${ERROR_BORDER}
    }
  `
      : ''}
`;

const AreaCode = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const ThreeDigitInput = styled(Input)`
  width: 40px;
`;

const Dash = styled.div`
  padding: 0 1px;
`;

const FourDigitInput = styled(Input)`
  width: 47px;
`;

export interface IInputPhoneNumberConfig {
  showErrorMessageWhenInvalid?: boolean;
  initialValue?: string | null;
  invalidMessage?: string;
  invalidMessagePlacement?: TPlacement;
  isDisabled?: boolean;
  value?: string | null;
}

export interface IInputPhoneNumber extends IInputPhoneNumberConfig {
  className?: string;
  getIsValid?: (isValid: boolean) => void;
  onChange?: (value: string | null) => void;
}

// Phone numbers are expected in US standard format: (123)456-7890
const InputPhoneNumber = (props: IInputPhoneNumber): JSX.Element => {
  const {
    getIsValid,
    showErrorMessageWhenInvalid,
    initialValue,
    invalidMessage,
    invalidMessagePlacement,
    isDisabled,
    onChange,
    value,
  } = props;
  const [areaCode, setAreaCode] = useState<string | undefined>();
  const [threeDigit, setThreeDigit] = useState<string | undefined>();
  const [fourDigit, setFourDigit] = useState<string | undefined>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const areaCodeInputRef =
    useRef<AntDesignInput>() as MutableRefObject<AntDesignInput>;
  const threeDigitInputRef =
    useRef<AntDesignInput>() as MutableRefObject<AntDesignInput>;
  const threeDigitRef = useRef<string | undefined>();
  const previousThreeDigitRef = useRef<string | undefined>();
  const fourDigitInputRef =
    useRef<AntDesignInput>() as MutableRefObject<AntDesignInput>;
  const fourDigitRef = useRef<string | undefined>();
  const previousFourDigitRef = useRef<string | undefined>();

  useEffect(() => {
    if (value === null || value === undefined) {
      setAreaCode(undefined);
      setThreeDigit(undefined);
      setFourDigit(undefined);
    } else {
      const phoneNumber: RegExpMatchArray | null = value.match(
        US_PHONE_NUMBER_REGEX,
      );
      if (phoneNumber !== null) {
        setAreaCode(phoneNumber[1]);

        threeDigitRef.current = phoneNumber[2];
        setThreeDigit(threeDigitRef.current);

        fourDigitRef.current = phoneNumber[3];
        setFourDigit(fourDigitRef.current);
      }
    }
  }, [value]);

  useEffect(() => {
    if (threeDigitInputRef.current) {
      threeDigitInputRef.current.input.onkeyup = (event: KeyboardEvent) => {
        if (event.keyCode === BACKSPACE_KEYCODE) {
          if (threeDigitInputRef.current.input.value.length === 0) {
            if (isEmptyValue(previousThreeDigitRef.current)) {
              areaCodeInputRef.current.focus();

              const valueLength: number =
                areaCodeInputRef.current.input.value.length;

              areaCodeInputRef.current.input.setSelectionRange(
                valueLength,
                valueLength,
              );
            } else {
              previousThreeDigitRef.current = threeDigitRef.current;
            }
          }
        }
      };
    }
  }, [threeDigitInputRef]);

  useEffect(() => {
    if (fourDigitInputRef.current) {
      fourDigitInputRef.current.input.onkeyup = (event: KeyboardEvent) => {
        if (event.keyCode === BACKSPACE_KEYCODE) {
          if (fourDigitInputRef.current.input.value.length === 0) {
            if (isEmptyValue(previousFourDigitRef.current)) {
              threeDigitInputRef.current.focus();

              const valueLength: number =
                threeDigitInputRef.current.input.value.length;

              threeDigitInputRef.current.input.setSelectionRange(
                valueLength,
                valueLength,
              );
            } else {
              previousFourDigitRef.current = fourDigitRef.current;
            }
          }
        }
      };
    }
  }, [fourDigitInputRef]);

  useEffect(() => {
    const phoneNumber: string = getPhoneNumber(areaCode, threeDigit, fourDigit);
    const updatedIsValid: boolean = verifyPhoneNumber(phoneNumber);

    if (updatedIsValid !== isValid) {
      setIsValid(updatedIsValid);

      if (getIsValid) {
        getIsValid(updatedIsValid);
      }
    }
  }, [areaCode, fourDigit, getIsValid, isValid, threeDigit]);

  const handleChange = (
    areaCode: string | undefined,
    threeDigit: string | undefined,
    fourDigit: string | undefined,
  ) => {
    if (onChange) {
      const phoneNumber: string = getPhoneNumber(
        areaCode,
        threeDigit,
        fourDigit,
      );
      if (phoneNumber.match(US_PHONE_NUMBER_EMPTY_REGEX) === null) {
        onChange(phoneNumber);
      } else {
        onChange(null);
      }
    }
  };

  const handleAreaCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedValue: string = event.target.value.replace(/\D/g, '');

    setAreaCode(updatedValue);

    handleChange(updatedValue, threeDigit, fourDigit);

    if (updatedValue.length === 3) {
      threeDigitInputRef.current.focus();
    }
  };

  const handleThreeDigitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedValue: string = event.target.value.replace(/\D/g, '');

    setThreeDigit(updatedValue);

    handleChange(areaCode, updatedValue, fourDigit);

    if (updatedValue.length === 3) {
      fourDigitInputRef.current.focus();
    }

    previousThreeDigitRef.current = threeDigitRef.current;
    threeDigitRef.current = updatedValue;
  };

  const handleFourDigitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedValue: string = event.target.value.replace(/\D/g, '');

    setFourDigit(updatedValue);

    handleChange(areaCode, threeDigit, updatedValue);

    previousFourDigitRef.current = fourDigitRef.current;
    fourDigitRef.current = updatedValue;
  };

  let initialAreaCode: string | undefined = undefined;
  let initialThreeDigit: string | undefined = undefined;
  let initialFourDigit: string | undefined = undefined;

  if (initialValue !== undefined) {
    if (initialValue === null) {
      initialAreaCode = '';
      initialThreeDigit = '';
      initialFourDigit = '';
    } else {
      const phoneNumber: RegExpMatchArray | null = initialValue.match(
        US_PHONE_NUMBER_REGEX,
      );

      if (phoneNumber !== null) {
        initialAreaCode = phoneNumber[1];
        initialThreeDigit = phoneNumber[2];
        initialFourDigit = phoneNumber[3];
      }
    }
  }

  return (
    <AntDesignPopover
      content={
        <ErrorMessage>
          {invalidMessage === undefined
            ? 'Invalid phone number'
            : invalidMessage}
        </ErrorMessage>
      }
      placement={invalidMessagePlacement}
      visible={showErrorMessageWhenInvalid === true && !isValid}
    >
      <Container highlightInput={!isValid}>
        <SeparatedRowLayout marginRight={0}>
          <AreaCode>
            (
            <ThreeDigitInput
              initialValue={initialAreaCode}
              inputRef={areaCodeInputRef}
              isDisabled={isDisabled}
              maxLength={3}
              onChange={handleAreaCodeChange}
              value={areaCode}
            />
            )
          </AreaCode>
          <ThreeDigitInput
            initialValue={initialThreeDigit}
            inputRef={threeDigitInputRef}
            isDisabled={isDisabled}
            maxLength={3}
            onChange={handleThreeDigitChange}
            value={threeDigit}
          />
          <Dash>-</Dash>
          <FourDigitInput
            initialValue={initialFourDigit}
            inputRef={fourDigitInputRef}
            isDisabled={isDisabled}
            maxLength={4}
            onChange={handleFourDigitChange}
            value={fourDigit}
          />
        </SeparatedRowLayout>
      </Container>
    </AntDesignPopover>
  );
};

export default InputPhoneNumber;
