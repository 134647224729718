import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { IMiscInfo } from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { alternatingTableRowBackground } from 'utils/styles';
import { getMiscInfoColumns } from 'utils/views';

const ViewDataTable = styled(
  (
    props: IDataTableProps<IViewDataTableColumn<IMiscInfo>, IMiscInfo> &
      IThemedProps,
  ) => DataTable<IViewDataTableColumn<IMiscInfo>, IMiscInfo>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}
`;

interface IMiscInfoDataTable {
  data: IMiscInfo[];
  isUnconstrained: boolean;
}

const MiscInfoDataTable = ({
  data,
  isUnconstrained,
}: IMiscInfoDataTable): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  const miscInfoColumns: IViewDataTableColumn<IMiscInfo>[] = useMemo(
    () => getMiscInfoColumns(isUnconstrained),
    [isUnconstrained],
  );

  return (
    <ViewDataTable
      columns={miscInfoColumns}
      currentTheme={currentTheme!}
      data={data}
      pagination={false}
    />
  );
};

export default MiscInfoDataTable;
