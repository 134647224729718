import { InputNumber } from 'antd';
import Select from 'components/molecules/Select/Select';
import { timeFrequencyUnitOptions } from 'components/molecules/ToEntityValidationReportCreate/constants';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
  width: 40px;
`;

interface IProps {
  defaultInputValue?: number;
  defaultUnit?: 'hour' | 'minute';
  isDisabled: boolean;
  onChange: (
    unit: 'hour' | 'minute' | undefined,
    value: number | undefined,
  ) => void;
}

const UnitSelectWrapper = styled.div`
  width: 83px;
`;

const CustomTimeFrequencySelector = ({
  defaultInputValue,
  defaultUnit,
  isDisabled,
  onChange,
}: IProps): JSX.Element => {
  const [unit, setUnit] = useState<'hour' | 'minute' | undefined>();
  const [value, setValue] = useState<number | undefined>();

  // defaultInputValue can be passed in from a previously created report
  useEffect(() => {
    if (defaultInputValue) {
      setValue(defaultInputValue);
    }
  }, [defaultInputValue]);

  // defaultUnit can be passed in from a previously created report
  // if that exists, use that value, otherwise default to 'hour'
  useEffect(() => {
    if (defaultUnit) {
      setUnit(defaultUnit);
    } else {
      setUnit('hour');
    }
  }, [defaultUnit]);

  useEffect(() => {
    // only inputs with both unit and value are valid
    if (!value || !unit) {
      onChange(undefined, undefined);
      return;
    }
    onChange(unit, value);
  }, [value, unit, onChange]);

  const handleUnitChange = (value: string | undefined) => {
    if (value === undefined || value === 'minute' || value === 'hour') {
      setUnit(value);
    }
  };

  const handleValueChange = (value: number | string | undefined) => {
    if (typeof value === 'string') {
      return;
    }
    setValue(value);
  };

  return (
    <>
      <div>±</div>
      <StyledInputNumber
        disabled={isDisabled}
        min={1}
        onChange={handleValueChange}
        value={value}
      />
      <UnitSelectWrapper>
        <Select
          isDisabled={isDisabled}
          onChange={handleUnitChange}
          options={timeFrequencyUnitOptions}
          placeholder='Unit'
          valueToUid={(value: string) => value}
          value={unit}
        />
      </UnitSelectWrapper>
    </>
  );
};

export default CustomTimeFrequencySelector;
