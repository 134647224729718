import { HistoryOutlined } from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import { AxiosResponse } from 'axios';
import IconButton from 'components/atoms/IconButton/IconButton';
import DataTable from 'components/molecules/DataTable/DataTable';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  APPROVAL_HISTORY_DATA_TABLE_ID,
  PANEL_MAXIMUM_HEIGHT,
  TITLE,
} from 'components/organisms/ApprovalHistory/constants';
import { getApprovalHistoryColumns } from 'components/organisms/ApprovalHistory/helpers';
import { IApprovalHistoryDataSet } from 'components/organisms/ApprovalHistory/types';
import {
  BUTTON_ICON_DIMENSIONS,
  STANDARD_SPACING_VALUE,
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import {
  IApprovalHistoryRecord,
  IApprovalHistoryResponse,
} from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { retrieveApprovalHistory } from 'services/approval/approval';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';
import { alternatingTableRowBackground } from 'utils/styles';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ApprovalHistoryIcon = styled(HistoryOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ViewDataTable = styled((props) => <DataTable {...props} />)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}
`;

interface IProps {
  encodedPermissionsId: string;
  id: string;
  isDisabled?: boolean;
  tagPrimaryKey: TETagTagPrimaryKey | undefined;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ApprovalHistory = (props: IProps): JSX.Element => {
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const { currentTheme } = useThemeSwitcher();
  const {
    encodedPermissionsId,
    id,
    isDisabled,
    tagPrimaryKey,
    timeZone,
    toEntityId,
  } = props;
  const [data, setData] = useState<IApprovalHistoryDataSet[]>([]);
  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;

  useAsyncEffect(async () => {
    if (tagPrimaryKey !== undefined && refreshData) {
      setIsLoading(true);
      try {
        const historyResponse: AxiosResponse = await retrieveApprovalHistory(
          toEntityId,
          tagPrimaryKey,
        );

        const approvalHistorytResponse: IApprovalHistoryResponse =
          historyResponse.data;

        if (!isSuccessStatus(historyResponse.status)) {
          throw new Error(approvalHistorytResponse.errorMessage!);
        }
        const responseData: IApprovalHistoryRecord[] =
          approvalHistorytResponse.response;
        const dataSet: IApprovalHistoryDataSet[] = responseData.map(
          (record: IApprovalHistoryRecord): IApprovalHistoryDataSet => {
            return {
              actionTaken: record.extra_meta_data.action,
              actionTakenBy: record.created_by,
              actionTakenTime: ZonedDateTime.parseIso(
                record.creation_time,
                timeZone,
              ),
              reason: record.extra_meta_data.notes,
              requestId: record.extra_meta_data.request_id,
            };
          },
        );
        setData(dataSet);
      } catch (error: any) {
        captureError(error, 'Failed to load approval history');
      } finally {
        setIsLoading(false);
        setRefreshData(false);
      }
    }
  }, [toEntityId, refreshData]);

  const approvalHistoryColumns: IViewDataTableColumn<IApprovalHistoryDataSet>[] =
    useMemo(() => getApprovalHistoryColumns(timeZone), [timeZone]);

  const FloatOverContent: JSX.Element = useMemo(
    () => (
      <AntDesignCard key={APPROVAL_HISTORY_DATA_TABLE_ID} title={TITLE}>
        <ViewDataTable
          columns={approvalHistoryColumns}
          data={data}
          id={APPROVAL_HISTORY_DATA_TABLE_ID}
          isLoading={isLoading}
          currentTheme={currentTheme!}
          pagination={false}
          rowKey='actionTakenTime'
          tableTitle='Approval History Details'
          title='Approval History'
        />
      </AntDesignCard>
    ),
    [approvalHistoryColumns, currentTheme, data, isLoading],
  );

  useEffect(() => {
    if (!isEmptyValue(floatOverContent) && floatOverId === id) {
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
    }
  }, [
    floatOverContent,
    FloatOverContent,
    floatOverId,
    id,
    setFloatOverContent,
    setFloatOverUseDragPanel,
  ]);

  const handleClick = () => {
    if (isEmptyValue(floatOverContent) || floatOverId !== id) {
      const { height, x, y } = buttonRef.current.getBoundingClientRect();
      setFloatOverId(id);
      setFloatOverDefaultPosition({
        placement: EFloatOverPlacement.Right,
        x,
        y: y + height + STANDARD_SPACING_VALUE,
      });
      setFloatOverMaximumHeight(PANEL_MAXIMUM_HEIGHT);
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
      setRefreshData(true);
    } else if (floatOverId === id) {
      setFloatOverContent(null);
    }
  };

  return (
    <Tooltip title='Show Approval History'>
      <IconButton
        buttonRef={buttonRef}
        encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
        icon={<ApprovalHistoryIcon />}
        isDisabled={isDisabled}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default ApprovalHistory;
