import {
  CURRENT_PENDING_REQUEST_KEY,
  CURRENT_REQUEST_KEY,
} from 'constants/Detail';
import { EProfileType } from 'enums/ETag';
import { IETagTemplate } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { timeZoneParams } from 'services/helpers';
import { TTimeZone } from 'types/DateTime';
import { TETagDraftId, TETagTagPrimaryKey, TETagTemplateId } from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const TAGS_SUMMARY_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  filterId?: TFilterId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/summary?start=${encodeURIComponent(
    start.isoFormat(),
  )}&end=${encodeURIComponent(end.isoFormat())}${
    filterId ? `&filterId=${encodeURIComponent(filterId)}` : ''
  }`;

export const TAGS_SUMMARY_FILTERSV2_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/tags/summary/filtersV2`;

export const TAGS_SUMMARY_FILTER_ROUTE = (
  toEntityId: TToEntityId,
  filterId: TFilterId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/summary/filters/${encodeURIComponent(filterId)}`;

export const TAGS_SUMMARY_LOADER_IDS_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  filterId?: TFilterId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/summaryLoader/ids?start=${encodeURIComponent(
    start.isoFormat(),
  )}&end=${encodeURIComponent(end.isoFormat())}${
    filterId ? `&filterId=${encodeURIComponent(filterId)}` : ''
  }`;

export const TAGS_SUMMARY_LOADER_SUMMARIES_ROUTE = (
  toEntityId: TToEntityId,
  idList: string[],
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/summaryLoader/summaries?idList=${idList
    .map((id: string): string => encodeURIComponent(id))
    .join(',')}`;

export const TAGS_SUMMARY_LOADER_PROFILES_ROUTE = (
  toEntityId: TToEntityId,
  idList: string[],
  timeZone: string,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/summaryLoader/profiles?idList=${idList
    .map((id: string): string => encodeURIComponent(id))
    .join(',')}&timeZone=${encodeURIComponent(
    timeZone,
  )}&start=${encodeURIComponent(start.isoFormat())}&end=${encodeURIComponent(
    end.isoFormat(),
  )}&profileSegment=${encodeURIComponent(profileSegment)}`;

export const TAGS_DRAFT_SUMMARY_ROUTE = (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  filterId?: TFilterId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/drafts/${encodeURIComponent(
    draftId as string,
  )}/summary?start=${encodeURIComponent(
    start.isoFormat(),
  )}&end=${encodeURIComponent(end.isoFormat())}${
    filterId ? `&filterId=${encodeURIComponent(filterId)}` : ''
  }`;

export const TAGS_DRAFT_SUMMARY_PROFILES_ROUTE = (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  timeZone: string,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/drafts/${encodeURIComponent(
    draftId as string,
  )}/summaryProfiles?timeZone=${encodeURIComponent(
    timeZone,
  )}&start=${encodeURIComponent(start.isoFormat())}&end=${encodeURIComponent(
    end.isoFormat(),
  )}&profileSegment=${encodeURIComponent(profileSegment)}`;

export const TAGS_DRAFTS_FROM_DISTRIBUTED_ROUTE = (
  toEntityId: TToEntityId,
  tag_primary_key: TETagTagPrimaryKey,
  profileType: EProfileType[],
  start: ZonedDateTime,
  updateContact?: IContactInfo,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/drafts/fromDistributed?tagPrimaryKey=${tag_primary_key}${
    profileType.length > 0
      ? `&profileType=${encodeURIComponent(profileType.join(','))}`
      : ''
  }&start=${encodeURIComponent(start.isoFormat())}${
    isEmptyValue(updateContact?.contact)
      ? ''
      : `&updateContact=${encodeURIComponent(updateContact?.contact!)}`
  }${
    isEmptyValue(updateContact?.phone)
      ? ''
      : `&updatePhone=${encodeURIComponent(updateContact?.phone!)}`
  }${
    isEmptyValue(updateContact?.fax)
      ? ''
      : `&updateFax=${encodeURIComponent(updateContact?.fax!)}`
  }`;

export const TAGS_DRAFTS_FROM_TEMPLATE_ROUTE = (
  toEntityId: TToEntityId,
  templateId: string,
  start: ZonedDateTime,
  timeZone?: TTimeZone,
  updateContact?: IContactInfo,
) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/drafts/fromTemplate?templateId=${encodeURIComponent(
    templateId,
  )}&start=${encodeURIComponent(start.isoFormat())}${timeZoneParams(
    '&',
    timeZone,
  )}${
    isEmptyValue(updateContact?.contact)
      ? ''
      : `&updateContact=${encodeURIComponent(updateContact?.contact!)}`
  }${
    isEmptyValue(updateContact?.phone)
      ? ''
      : `&updatePhone=${encodeURIComponent(updateContact?.phone!)}`
  }${
    isEmptyValue(updateContact?.fax)
      ? ''
      : `&updateFax=${encodeURIComponent(updateContact?.fax!)}`
  }`;

export const TAGS_DRAFTS_ROUTE = (
  toEntityId: TToEntityId,
  start?: ZonedDateTime,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/tags/drafts${
    start === undefined ? '' : `?start=${encodeURIComponent(start.isoFormat())}`
  }`;

export const TAGS_DRAFT_ROUTE = (
  toEntityId: TToEntityId,
  draft_id: TETagDraftId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/drafts/${encodeURIComponent(draft_id!)}`;

export const TEMPLATES_TEMPLATE_NAMES_ROUTE = (
  toEntityId: TToEntityId,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/templates/templateNames`;

export const TEMPLATES_TEMPLATE_GROUPS_ROUTE = (
  toEntityId: TToEntityId,
): string => `/toEntities/${encodeURIComponent(toEntityId)}/templates/groups`;

export const TEMPLATES_FROM_DISTRIBUTED_ROUTE = (
  toEntityId: TToEntityId,
  tag_primary_key: string,
  name: string,
  profileType: EProfileType[],
  groupName?: string,
  description?: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/templates/fromDistributed?tagPrimaryKey=${encodeURIComponent(
    tag_primary_key,
  )}&name=${encodeURIComponent(name)}${
    profileType.length > 0
      ? `&profileType=${encodeURIComponent(profileType.join(','))}`
      : ''
  }${
    isEmptyValue(groupName)
      ? ''
      : `&groupName=${encodeURIComponent(groupName!)}`
  }${
    isEmptyValue(description)
      ? ''
      : `&description=${encodeURIComponent(description!)}`
  }`;

export const TEMPLATES_FROM_DRAFT_ROUTE = (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  name: string,
  groupName?: string,
  description?: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/templates/fromDraft/${encodeURIComponent(
    draftId,
  )}?name=${encodeURIComponent(name)}${
    isEmptyValue(groupName)
      ? ''
      : `&groupName=${encodeURIComponent(groupName!)}`
  }${
    isEmptyValue(description)
      ? ''
      : `&description=${encodeURIComponent(description!)}`
  }`;

export const TEMPLATES_TEMPLATE_ROUTE = (
  toEntityId: TToEntityId,
  templateId: TETagTemplateId,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/templates/${templateId}`;

export const TEMPLATES_SUMMARY_ROUTE = (
  toEntityId: TToEntityId,
  templateIds: TETagTemplateId[],
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/templates/summary?idList=${templateIds.join(',')}`;

export const TEMPLATES_FROM_TEMPLATE_ROUTE = (
  toEntityId: TToEntityId,
  template: IETagTemplate,
  name: string,
  groupName?: string,
  description?: string,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/templates/fromTemplate/${
    template.id
  }?name=${encodeURIComponent(name)}${
    isEmptyValue(groupName)
      ? ''
      : `&groupName=${encodeURIComponent(groupName!)}`
  }${
    isEmptyValue(description)
      ? ''
      : `&description=${encodeURIComponent(description!)}`
  }`;

export const TAGS_DISTRIBUTED_EDIT_MODE_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(
    tagPrimaryKey,
  )}/editMode${timeZoneParams('?', timeZone)}`;

export const TEMPLATES_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/templates`;

export const TAGS_DISTRIBUTED_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}`;

export const TAGS_DISTRIBUTED_SEARCH_ROUTE = (
  toEntityId: TToEntityId,
  tagCode?: string,
  uiTagId?: string,
) =>
  `/toEntities/${encodeURIComponent(toEntityId)}/tags/distributed/${
    tagCode === undefined
      ? uiTagId === undefined
        ? ''
        : `search?uiTagId=${encodeURIComponent(uiTagId)}`
      : `search?tagCode=${encodeURIComponent(tagCode)}`
  }`;

export const TAGS_DISTRIBUTED_SUMMARY_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  start?: ZonedDateTime,
  end?: ZonedDateTime,
  filterId?: TFilterId,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}/summary${
    start || end || filterId ? '?' : ''
  }${start ? `start=${encodeURIComponent(start.isoFormat())}` : ''}${
    start && end ? '&' : ''
  }${end ? `end=${encodeURIComponent(end.isoFormat())}` : ''}${
    (start || end) && filterId ? '&' : ''
  }${filterId ? `filterId=${encodeURIComponent(filterId)}` : ''}`;

export const TAGS_DISTRIBUTED_SUMMARY_PROFILES_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
  timeZone: string,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(
    tagPrimaryKey,
  )}/summaryProfiles?timeZone=${encodeURIComponent(
    timeZone,
  )}&start=${encodeURIComponent(start.isoFormat())}&end=${encodeURIComponent(
    end.isoFormat(),
  )}&profileSegment=${encodeURIComponent(profileSegment)}
`;

export const TAGS_DISTRIBUTED_MARKET_SEGMENTS = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
  miscInfoTokens?: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}/marketSegments${
    isEmptyValue(miscInfoTokens) ? '' : `?miscInfoTokens=${miscInfoTokens}`
  }`;

export const TAGS_DISTRIBUTED_PHYSICAL_SEGMENTS = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}/physicalSegments`;

export const TAGS_DISTRIBUTED_LOSS_ACCOUNTINGS = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}/lossAccountings`;

export const TAGS_DISTRIBUTED_TRANSMISSION_ALLOCATIONS = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
  retrieveAll?: boolean,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}/transAllocs${
    retrieveAll ? '?includeStatuses=*' : ''
  }`;

export const TAGS_DISTRIBUTED_TRANSACTION_STATUSES = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(tagPrimaryKey)}/transactionStatuses`;

export const TAGS_DISTRIBUTED_PHYSICAL_SEGMENTS_PROFILES = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
  highlightRevision: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(
    tagPrimaryKey,
  )}/physicalSegmentsProfiles${
    highlightRevision === CURRENT_REQUEST_KEY
      ? ''
      : highlightRevision === CURRENT_PENDING_REQUEST_KEY
      ? '?includePending=true'
      : `?highlightRevision=${highlightRevision}`
  }`;

export const TAGS_DISTRIBUTED_ENERGY_PROFILE_SNAPSHOTS = (
  toEntityId: TToEntityId,
  tagPrimaryKey: string,
  minRequestId: number,
  maxRequestId: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(
    tagPrimaryKey,
  )}/energyProfileSnapshots?minRequestId=${encodeURIComponent(
    minRequestId,
  )}&maxRequestId=${encodeURIComponent(maxRequestId)}`;

export const TAG_CODE_SCHEME_TEST_ROUTE = (
  toEntityId: TToEntityId,
  tagCodeScheme: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tagCodeSchemes/${encodeURIComponent(tagCodeScheme)}`;

export const TAG_CODE_SCHEME_SET_ENTITY_DEFAULT_ROUTE = (
  toEntityId: TToEntityId,
): string => `/toEntities/${encodeURIComponent(toEntityId)}/tagCodeSchemes`;

export const TAGS_REQUESTS_AUDIT = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestId?: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/audit?tagPrimaryKey=${encodeURIComponent(tagPrimaryKey)}${
    isEmptyValue(requestId)
      ? ''
      : `&requestId=${encodeURIComponent(requestId!)}`
  }`;

export const TAGS_REQUESTS_TERMINATE_TAG_ROUTE = (
  toEntityId: TToEntityId,
  skipValidation: boolean,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/terminateTag?skipValidation=${skipValidation}${timeZoneParams(
    '&',
    timeZone,
  )}`;

export const TAGS_REQUESTS_VALIDATION_TERMINATION_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/validation/termination${timeZoneParams('?', timeZone)}`;

export const TAGS_REQUESTS_NEW_TAG_ROUTE = (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  skipValidation: boolean,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/newTag?draftId=${encodeURIComponent(
    draftId,
  )}&skipValidation=${skipValidation}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_VALIDATION_NEW_TAG_ROUTE = (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/validation/newTag?draftId=${encodeURIComponent(
    draftId,
  )}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_SAVE_NEW_TAG_ROUTE = (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  skipValidation: boolean,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/save/newTag?draftId=${encodeURIComponent(
    draftId,
  )}&skipValidation=${skipValidation}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_SAVE_VALIDATION_NEW_TAG_ROUTE = (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/save/validation/newTag?draftId=${encodeURIComponent(
    draftId,
  )}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_WITHDRAW_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  skipValidation: boolean,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/withdraw?skipValidation=${encodeURIComponent(
    skipValidation,
  )}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_VALIDATION_WITHDRAW_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/validation/withdraw${timeZoneParams('?', timeZone)}`;

export const TAGS_REQUESTS_CORRECTION_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  skipValidation: boolean,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/correction?skipValidation=${encodeURIComponent(
    skipValidation,
  )}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_VALIDATION_CORRECTION_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/validation/correction${timeZoneParams('?', timeZone)}`;

export const TAGS_REQUESTS_PROFILE_CHANGE_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  skipValidation: boolean,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/profileChange?skipValidation=${encodeURIComponent(
    skipValidation,
  )}${timeZoneParams('&', timeZone)}`;

export const TAGS_REQUESTS_VALIDATION_PROFILE_CHANGE_ROUTE = (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/requests/validation/profileChange${timeZoneParams('?', timeZone)}`;

export const TAGS_REQUESTS_NEW_TAG_SCHEDULE_ROUTE = (
  toEntityId: TToEntityId,
  draftId?: TETagDraftId,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/tags/requests/newTag/schedule${
    isEmptyValue(draftId) ? '' : `?draftId=${encodeURIComponent(draftId!)}`
  }`;

export const MARKET_INFOS_ROUTE = (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  tagPrimaryKey: TETagTagPrimaryKey | undefined,
  templateId: TETagTemplateId | undefined,
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/marketInfos?${
    tagPrimaryKey === undefined
      ? draftId === null
        ? templateId === undefined
          ? ''
          : `templateId=${templateId}`
        : `draftId=${draftId}`
      : `tagPrimaryKey=${tagPrimaryKey}`
  }`;

export const REPORTS_SUMMARY_CHECKOUT_REPORT = (
  toEntityId: TToEntityId,
  start: string,
  end: string,
  timeZone: TTimeZone,
  filterIds: string[],
  attributeNames: string[],
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/reports/summaryCheckout/report?start=${encodeURIComponent(
    start,
  )}&end=${encodeURIComponent(end)}${timeZoneParams(
    '&',
    timeZone,
  )}&filterIds=${filterIds
    .map((id: string): string => encodeURIComponent(id))
    .join(',')}&attributeNames=${attributeNames
    .map((name: string): string => encodeURIComponent(name))
    .join(',')}`;

export const REPORTS_SUMMARY_CHECKOUT_REQUESTS = (toEntityId: TToEntityId) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/reports/summaryCheckout/requests`;

export const TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_ALL_ROUTE = (
  toEntityId: TToEntityId,
  start: string,
  end: string,
) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/transmissionCapacity/availabilityReport?start=${encodeURIComponent(
    start,
  )}&end=${encodeURIComponent(end)}`;

export const TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_UNASSOCIATED_TSR_ROUTE =
  (toEntityId: TToEntityId, start: string, end: string) =>
    `/toEntities/${encodeURIComponent(
      toEntityId,
    )}/transmissionCapacity/availabilityReport?start=${encodeURIComponent(
      start,
    )}&end=${encodeURIComponent(end)}
    &onlyUnassociatedTsrs=true`;

export const TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_TSN_ROUTE = (
  toEntityId: TToEntityId,
  start: string,
  end: string,
) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/transmissionCapacity/availabilityReport?start=${encodeURIComponent(
    start,
  )}&end=${encodeURIComponent(end)}
  &allTsns=true`;

export const TRANSMISSION_CAPACITY_NETWORK_ROUTE = (toEntityId: TToEntityId) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/transmissionCapacity/transmissionCapacityNetwork`;

export const TRANSMISSION_CUSTOM_CAPACITY_ROUTE = (toEntityId: TToEntityId) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/transmissionCapacity/customCapacity`;

export const TAG_TO_DEAL_LINKAGE_REPORT_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: TTimeZone,
) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tagToDealLinkage/report?start=${encodeURIComponent(
    start.isoFormat(),
  )}&end=${encodeURIComponent(end.isoFormat())}${timeZoneParams(
    '&',
    timeZone,
  )}`;

export const TRANSMISSION_PRIORITY_SEGMENT = (
  toEntityId: TToEntityId,
  templateId: TETagTemplateId,
) =>
  `/toEntities/${toEntityId}/transmissionPriority?template_id=${templateId}
`;

export const REPORTS_AFT_ACTUAL_ENERGY_FLOW_REPORT = (
  toEntityId: TToEntityId,
  start: string,
  end: string,
  timeZone: TTimeZone,
  filterIds: string[],
  attributeNames: string[],
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/reports/aftActualEnergyFlow/report?start=${encodeURIComponent(
    start,
  )}&end=${encodeURIComponent(end)}${timeZoneParams(
    '&',
    timeZone,
  )}&filterIds=${filterIds
    .map((id: string): string => encodeURIComponent(id))
    .join(',')}&attributeNames=${attributeNames
    .map((name: string): string => encodeURIComponent(name))
    .join(',')}`;

export const REPORTS_AFT_ACTUAL_ENERGY_FLOW_REQUESTS = (
  toEntityId: TToEntityId,
) =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/reports/aftActualEnergyFlow/requests`;
