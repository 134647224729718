import styled from 'styled-components';
import {
  PAGE_LAYOUT_STYLES,
  STANDARD_SPACING,
} from '../../../constants/styles';
import ToEntityAlertHistoryUI from '../../molecules/ToEntityAlertHistoryUI/ToEntityAlertHistoryUI';
import { IPageContentProps } from '../../organisms/Page/Page';
import { IToEntity } from '../../../interfaces/ToEntity';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  > :first-child {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface IAlertPageContentProps extends IPageContentProps<undefined> {}

const AlertPageContent = (props: IAlertPageContentProps): JSX.Element => {
  const { userInfo } = props;

  const search = window.location.search;
  const urlParams = new URLSearchParams(search);
  const toEntityId = urlParams.get('toEntityId');

  const toEntity =
    userInfo.toEntities.find(
      (entity: IToEntity) => entity.to_entity === toEntityId,
    ) || undefined;

  return (
    <Layout>
      <ToEntity>
        <ToEntityAlertHistoryUI
          toEntityId={toEntityId}
          userInfo={userInfo}
          toEntity={toEntity}
        />
      </ToEntity>
    </Layout>
  );
};

export default AlertPageContent;
