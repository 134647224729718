import { FilterOutlined } from '@ant-design/icons';
import ETagTemplateFilter from 'components/molecules/ETagTemplateFilter/ETagTemplateFilter';
import {
  copyFilter,
  generateNewFilterConfigurationGroups,
} from 'components/molecules/TemplateFilterConfigurator/helpers';
import { IToEntityConfiguratorProps } from 'components/molecules/ToEntityConfiguration/ToEntityConfiguration';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  INPUT_HEIGHT_VALUE,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { EFilterType } from 'enums/Filter';
import {
  IFilterConfiguration,
  IFilterConfigurationGroup,
  IFilterMisc,
  IFilterString,
  ITemplateFilter,
} from 'interfaces/Filter';
import { IIndexable } from 'interfaces/General';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TETagTemplateFilterConfiguration,
  TETagTemplateFilterEnumeration,
} from 'types/ETag';
import { TFilterValues } from 'types/Filter';

export const TemplatesFilterConfiguratorIcon = styled(FilterOutlined)`
  height: ${INPUT_HEIGHT_VALUE / 2}px;
  width: ${INPUT_HEIGHT_VALUE / 2}px;
`;

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

const FilterConfigurationGroups = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 50%;
  }
`;

const FilterConfigurationGroup = styled.div`
  > * {
    margin-bottom: ${STANDARD_SPACING_VALUE / 2}px;
  }

  > :first-child,
  > :last-child {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

export interface ITemplateFilterConfigurationProps {
  currentFilter: ITemplateFilter;
  setFilter: (filter: ITemplateFilter) => void;
}

const TemplateFilterConfigurator = (
  props: ITemplateFilterConfigurationProps & IToEntityConfiguratorProps,
): JSX.Element => {
  const { currentFilter, setFilter } = props;

  const [internalFilter, setInternalFilter] = useState<ITemplateFilter>();

  const [filterConfigurationGroups, setFilterConfigurationGroups] = useState<
    IFilterConfigurationGroup<TETagTemplateFilterEnumeration>[]
  >(generateNewFilterConfigurationGroups());

  useEffect(() => {
    setInternalFilter(currentFilter);
  }, [currentFilter]);

  useEffect(() => {
    if (internalFilter !== undefined) {
      setFilter(internalFilter);
    }
  }, [internalFilter, setFilter]);

  useEffect(() => {
    let updatedFilterConfigurationGroups: IFilterConfigurationGroup<TETagTemplateFilterEnumeration>[] =
      generateNewFilterConfigurationGroups();

    updatedFilterConfigurationGroups = updatedFilterConfigurationGroups.map(
      (group: IFilterConfigurationGroup<TETagTemplateFilterEnumeration>) => {
        group.filterConfigurations = group.filterConfigurations.map(
          (
            filterConfiguration: IFilterConfiguration<TETagTemplateFilterEnumeration>,
          ) => {
            const filterValue: TFilterValues | null = (
              currentFilter as IIndexable
            )[filterConfiguration.dataIndex];

            if (filterValue !== null) {
              switch (filterConfiguration.filterType) {
                case EFilterType.Boolean:
                  filterConfiguration.filterBoolean =
                    filterValue as unknown as boolean;
                  break;
                case EFilterType.Enumeration:
                  filterConfiguration.filterEnumerations =
                    filterValue as unknown as TETagTemplateFilterEnumeration[];
                  break;
                case EFilterType.Misc:
                  filterConfiguration.filterMiscs =
                    filterValue as unknown as IFilterMisc[];
                  break;
                default:
                  filterConfiguration.filterStrings =
                    filterValue as unknown as IFilterString[];
                  break;
              }
            }

            return filterConfiguration;
          },
        );
        return group;
      },
    );

    setFilterConfigurationGroups(updatedFilterConfigurationGroups);
  }, [currentFilter]);

  const handleFilterConfigurationChange = (
    filterConfiguration: TETagTemplateFilterConfiguration,
  ) => {
    const { dataIndex } = filterConfiguration;

    const updatedFilter: ITemplateFilter & IIndexable =
      copyFilter(currentFilter);

    switch (filterConfiguration.filterType) {
      case EFilterType.Boolean:
        updatedFilter[dataIndex] =
          filterConfiguration.filterBoolean === undefined
            ? null
            : filterConfiguration.filterBoolean;
        break;
      case EFilterType.Enumeration:
        // When type is Enumeration we will always have an array in
        // filterEnumerations
        updatedFilter[dataIndex] =
          filterConfiguration.filterEnumerations!.length === 0
            ? null
            : filterConfiguration.filterEnumerations;
        break;
      case EFilterType.Misc:
        // When type is Misc we will always have an array in filterMiscs
        const updatedFilterMiscs: IFilterMisc[] =
          filterConfiguration.filterMiscs!.filter(
            (filterMisc: IFilterMisc): boolean =>
              filterMisc.token !== '' && filterMisc.token !== null,
          );

        updatedFilter[dataIndex] =
          updatedFilterMiscs.length === 0 ? null : updatedFilterMiscs;
        break;
      default:
        // Assumed type is String, with this type we will always have an
        // array in filterStrings
        const updatedFilterStrings: IFilterString[] =
          filterConfiguration.filterStrings!.filter(
            (filterString: IFilterString): boolean =>
              filterString.value !== '' && filterString.value !== null,
          );

        updatedFilter[dataIndex] =
          updatedFilterStrings.length === 0 ? null : updatedFilterStrings;
        break;
    }

    setInternalFilter(updatedFilter);
  };

  return (
    <Layout>
      <FilterConfigurationGroups>
        {filterConfigurationGroups.map(
          (
            filterConfigurationGroup: IFilterConfigurationGroup<TETagTemplateFilterEnumeration>,
          ): JSX.Element => (
            <FilterConfigurationGroup key={filterConfigurationGroup.label}>
              <h3>{filterConfigurationGroup.label}</h3>
              {filterConfigurationGroup.filterConfigurations.map(
                (
                  filterConfiguration: TETagTemplateFilterConfiguration,
                ): JSX.Element => (
                  <ETagTemplateFilter
                    filterConfiguration={filterConfiguration}
                    key={filterConfiguration.dataIndex}
                    onChange={handleFilterConfigurationChange}
                  />
                ),
              )}
            </FilterConfigurationGroup>
          ),
        )}
      </FilterConfigurationGroups>
    </Layout>
  );
};

export default TemplateFilterConfigurator;
