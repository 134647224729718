import UserTenantToEntitySelect from 'components/molecules/UserTenantToEntitySelect/UserTenantToEntitySelect';
import { ERetreiveState } from 'enums/General';
import { IToEntity } from 'interfaces/ToEntity';
import { IUserInfo } from 'interfaces/User';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setToEntitiesAndRemoveOthers,
  userRetrieveToEntityUiConfig,
  userRetrieveUserInfo,
} from 'reduxes/User/actions';
import { TRootState } from 'types/Redux';
import useAsyncEffect from 'use-async-effect';
import { shallowObjectCompare } from 'utils/general';

const retreiveUseUserInfoState = (state: TRootState) => state.user;

const useUserInfo = (): IUserInfo => {
  const dispatch = useDispatch();
  const {
    errorMessage,
    retrievingUserInfo,
    selectedToEntity,
    tenantToEntities,
    toEntities,
    toEntityUserStates,
  } = useSelector(retreiveUseUserInfoState, shallowObjectCompare);

  const isLoading: boolean = useMemo(
    () =>
      retrievingUserInfo === ERetreiveState.RetrievingStarted ||
      retrievingUserInfo === ERetreiveState.RetrievingMore,
    [retrievingUserInfo],
  );

  // check if we tried to load already but failed
  // we don't want an infinite loop of retrying
  const hasError: boolean = useMemo(
    () => errorMessage !== undefined && errorMessage !== null,
    [errorMessage],
  );

  useAsyncEffect(async () => {
    if (!isLoading && !hasError) {
      dispatch(userRetrieveUserInfo());
    }
  }, [isLoading]);

  useAsyncEffect(async () => {
    if (toEntities.length > 0 && tenantToEntities.length === 0) {
      for (const toEntity of toEntities) {
        dispatch(userRetrieveToEntityUiConfig(toEntity.to_entity));
      }
    }
  }, [toEntities]);

  const setSelectedToEntities = (selectedToEntities: string[]) => {
    const userInfoToEntities: IToEntity[] = [];
    selectedToEntities.forEach((selectedToEntity: string) => {
      const userInfoToEntity = toEntities.filter(
        (toEntityInfo: IToEntity) =>
          toEntityInfo.to_entity === selectedToEntity,
      );
      userInfoToEntities.push(...userInfoToEntity);
    });
    localStorage.setItem(
      'pciSupportToEntities',
      JSON.stringify(selectedToEntities),
    );
    dispatch(setToEntitiesAndRemoveOthers(userInfoToEntities));
  };

  const selectTenantToEntity: (() => JSX.Element) | undefined =
    tenantToEntities.length === 0
      ? undefined
      : () => {
          return (
            <UserTenantToEntitySelect
              tenantToEntities={tenantToEntities}
              toEntities={toEntities}
              onSelectToEntities={setSelectedToEntities}
            ></UserTenantToEntitySelect>
          );
        };

  return {
    selectedToEntity,
    isLoading,
    errorMessage,
    toEntities,
    toEntityUserStates,
    selectTenantToEntity,
  };
};

export default useUserInfo;
