import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import styled from 'styled-components';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  COMPONENT_BACKGROUND_DARK,
  PUSH_RIGHT_VALUE,
  TEXT_DARK,
  TEXT_LIGHT,
} from '../../../constants/styles';
import { useCallback, useEffect, useState } from 'react';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';
import { ETheme } from '../../../enums/Style';
import Button from 'components/atoms/Button/Button';
import Modal from '../Modal/Modal';
import PciLogo from '../../pages/LandingPage/PciLogo';
import { IThemedProps } from '../../../interfaces/Component';
import { bgColorForTheme } from '../../pages/LandingPage/helpers';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { TThemeValue } from '../../../types/Style';
import { Card, Input } from 'antd';
import videoListData from 'data/eTagTutorialVideos.json';
import { IToEntity } from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { retrieveEtagAssestsUrl } from 'services/agent/assets';
import useAsyncEffect from 'use-async-effect';
import NavigationActions from '../../atoms/NavigationActions/NavigationActions';
import DownloadTagButton from '../DownloadTagButton/DownloadTagButton';
interface IHelpUIProps {
  encodedPermissionsId?: string;
  timeZone?: TTimeZone;
  toEntity: IToEntity;
}

interface IVideoItem {
  name: string;
  order: number;
  duration: string;
}

interface IStyledItemsContainer {
  currentTheme?: string;
}

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

const ItemsContainer = styled.div<IStyledItemsContainer>`
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: auto;
  gap: 60px;
  margin: 40px 25px 25px 25px;
  padding: 10px 0 10px 0;
  width: 100%;
  .ant-card-body {
    background: ${({ currentTheme }) =>
      currentTheme === ETheme.Light ? '#d3d3d3' : COMPONENT_BACKGROUND_DARK};
    color: ${({ currentTheme }) =>
      currentTheme === ETheme.Light ? TEXT_LIGHT : TEXT_DARK};
  }
`;

const VideoPlayer = styled.video`
  padding: 10px;
`;

const Comments = styled.div`
  height: 100px;
  textarea {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
  }
`;

const Title = styled.div`
  font-size: 18px;
  margin-top: 5px;
`;

const Banner = styled.div<IThemedProps>`
  align-items: center;
  background-color: ${bgColorForTheme};
  display: flex;
  flex-direction: row;
  height: 40px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const SearchBar = styled.div`
  margin: 10px 20px 0 0;
  width: 700px;
  input {
    width: inherit;
    padding: 5px;
    height: 30px;
    outline: none;
  }
`;

const { Meta } = Card;

const HelpUI = (props: IHelpUIProps): JSX.Element => {
  const themeSwitcher = useThemeSwitcher();
  const currentTheme: TThemeValue = themeSwitcher.currentTheme as TThemeValue;
  const { encodedPermissionsId, timeZone, toEntity } = props;

  const [src, setSrc] = useState<string>();
  const [currentVideoTitle, setCurrentVideoTitle] = useState<string | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const [videos, setVideos] = useState<JSX.Element[]>([]);
  const [filteredVideoList, setFilteredVideoList] = useState<IVideoItem[]>([]);
  const [assetsSrc, setAssetsSrc] = useState<string>();

  // debounce the search bar input
  const debounce = (func: any) => {
    let timer: any;
    return (...args: any[]) => {
      const context = this;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  useAsyncEffect(async () => {
    const src = await retrieveEtagAssestsUrl();
    setAssetsSrc(src);
  }, []);

  useEffect(() => {
    if (src) {
      setOpen(true);
    }
  }, [src, currentVideoTitle]);

  const handleModalClose = () => {
    setOpen(false);
    setSrc('');
  };

  const handleVideoItemClick = useCallback(
    (video: number) => {
      const videoSrc = `${assetsSrc}/videos/${videoListData.videoList[video].name}`;
      if (src === videoSrc) {
        setOpen(!open);
      } else {
        setSrc(videoSrc);
        const sortedVideoList = videoListData.videoList.sort(
          (a, b) => a.order - b.order,
        );
        const title = `${sortedVideoList[video].order}. ${sortedVideoList[
          video
        ].name
          .replaceAll('+', ' ')
          .replace('.mp4', '')}`;
        setCurrentVideoTitle(title);
      }
    },
    [assetsSrc, open, src],
  );

  useEffect(() => {
    if (filteredVideoList.length > 0) {
      const array = [];
      const sortedVideoList = filteredVideoList.sort(
        (a: IVideoItem, b: IVideoItem) => a.order - b.order,
      );
      for (let i = 0; i < filteredVideoList.length; i++) {
        const title = `${sortedVideoList[i].order}. ${sortedVideoList[i].name
          .replaceAll('+', ' ')
          .replace('.mp4', '')} (${sortedVideoList[i].duration})`;
        const thumbnailTitle = `${sortedVideoList[i].name.replace('.mp4', '')}`;
        array.push(
          <Card
            onClick={() => handleVideoItemClick(i)}
            key={i}
            id={`descriptionContainer-${sortedVideoList[i].name}`}
            hoverable
            cover={
              <img
                alt={title}
                src={`${assetsSrc}/videos/thumbnails/${thumbnailTitle}.jpg`}
              />
            }
          >
            <Meta title={title} description='' />
          </Card>,
        );
      }
      setVideos(array);
    } else {
      setVideos([]);
    }
  }, [assetsSrc, filteredVideoList, handleVideoItemClick]);

  useEffect(() => {
    setFilteredVideoList(videoListData.videoList);
  }, [setFilteredVideoList]);

  const searchInputHandler = (e: string) => {
    const lowerCase = e.toLowerCase();
    const filteredVideoList = videoListData.videoList.filter((item) =>
      item.name.toLowerCase().includes(lowerCase),
    );
    setFilteredVideoList(filteredVideoList);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(searchInputHandler), []);

  return (
    <Layout>
      <SeparatedRowLayout style={{ height: 25 }}>
        <Banner currentTheme={currentTheme}>
          <PciLogo currentTheme={currentTheme} />
        </Banner>
        <NavigationActions right={PUSH_RIGHT_VALUE}>
          <DownloadTagButton
            currentTheme={currentTheme}
            toEntityId={toEntity.to_entity}
          />
        </NavigationActions>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <TopBarMenu
          encodedPermissionsId={encodedPermissionsId}
          toEntity={toEntity}
          timeZone={timeZone}
        />
        <Title>Help</Title>
        <SearchBarContainer>
          <SearchBar>
            <Input
              allowClear={true}
              placeholder='What are you looking for?'
              onChange={(e) => optimizedFn(e.target.value)}
            />
          </SearchBar>
        </SearchBarContainer>
      </SeparatedRowLayout>
      <MainContainer>
        <SeparatedRowLayout>
          <Modal
            key={currentVideoTitle}
            closable={true}
            isVisible={open}
            title={currentVideoTitle}
            width={1450}
            footer={[
              <Button
                key={`${currentVideoTitle}ModalCloseBtn`}
                onClick={handleModalClose}
                label={'Close'}
              />,
            ]}
            onCancel={handleModalClose}
          >
            <VideoPlayer
              controls
              controlsList='nodownload'
              disablePictureInPicture
              autoPlay={true}
              preload={'metadata'}
              height={800}
              width={1400}
            >
              <source src={src} type='video/mp4' />
              Sorry, your browser doesn't support videos.
            </VideoPlayer>
            <Comments>
              <textarea></textarea>
            </Comments>
          </Modal>
          <ItemsContainer currentTheme={currentTheme}>{videos}</ItemsContainer>
        </SeparatedRowLayout>
      </MainContainer>
    </Layout>
  );
};

export default HelpUI;
