import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { EAlertAcknowledged } from 'enums/Alert';
import { ESeverity } from 'enums/General';
import {
  ALERTS_ACKNOWLEDGE_ROUTE,
  ALERTS_ROUTE,
} from 'services/alert/constants';
import { ALERT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TAlertId, TAlertRuleId } from 'types/Alert';
import { TNextToken } from 'types/Summary';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const acknowledgeAlerts = async (
  toEntityId: TToEntityId,
  alertIds?: TAlertId[],
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: {
      alertIds,
    },
  };

  return await API.put(
    ALERT_SERVICE_API_NAME,
    ALERTS_ACKNOWLEDGE_ROUTE(toEntityId),
    options,
  );
};

export const retrieveAlerts = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  alertSeverities: ESeverity[],
  acknowledgeStatus: EAlertAcknowledged,
  alertRuleIds: TAlertRuleId[],
  cutOffTimeInMinutes: number[],
  next_token: TNextToken | undefined,
): Promise<AxiosResponse> => {
  return await API.get(
    ALERT_SERVICE_API_NAME,
    ALERTS_ROUTE(
      toEntityId,
      start,
      end,
      alertSeverities,
      acknowledgeStatus,
      alertRuleIds,
      cutOffTimeInMinutes,
      next_token,
    ),
    DEFAULT_OPTIONS,
  );
};
