import { Input as AntDesignInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import {
  EXPANDED_INPUT_HEIGHT_VALUE,
  EXPANSION_TRANSITION_IN_SECONDS_VALUE,
  FLOAT_BOX_SHADOW,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import { IExpandableProps, IValueChanged } from 'interfaces/Component';
import { ChangeEvent, forwardRef, KeyboardEvent, Ref } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import {
  inputBackground,
  inputBackgroundExpand,
  inputValueChanged,
} from 'utils/styles';

// styled always forwards every received prop by default. This causes warnings
// due to html elements receiving non-html attributes. Here we capture just
// the shouldExpand prop by explicitly listing it within the destructure and
// forward the other props (via rest) to the AntDesignInput. We then use the
// shouldExpand prop within the template literal.
const StyledInput = styled(
  forwardRef(
    (
      {
        currentTheme,
        shouldExpand,
        valueChanged,
        ...rest
      }: IExpandableProps & InputProps & IValueChanged,
      ref: Ref<AntDesignInput>,
    ) => <AntDesignInput {...rest} ref={ref} />,
  ),
)<IExpandableProps & InputProps & IValueChanged>`
  ${(props) => inputBackground(props)}
  height: ${INPUT_HEIGHT_VALUE}px;
  width: 100%;

  ${(props) => inputValueChanged(props)}

  ${(props) =>
    props.shouldExpand
      ? `
    :focus {
      box-shadow: ${FLOAT_BOX_SHADOW};
      height: ${EXPANDED_INPUT_HEIGHT_VALUE}px;
      width: 200%;
    }

    ${inputBackgroundExpand(props)}
    transition: ${EXPANSION_TRANSITION_IN_SECONDS_VALUE}s;
    transition-timing-function: ease;
  `
      : ''}
`;

export interface IInputConfig {
  allowClear?: boolean;
  initialValue?: string | null;
  isDisabled?: boolean;
  maxLength?: number;
  onKeyDown?: (keyboardEvent: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  shouldExpand?: boolean;
  value?: string;
}

export interface IInputProps extends IInputConfig {
  className?: string;
  inputRef?: Ref<AntDesignInput>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input = (props: IInputProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    allowClear,
    className,
    initialValue,
    inputRef,
    isDisabled,
    maxLength,
    onChange,
    onKeyDown,
    placeholder,
    shouldExpand,
    value,
  } = props;

  return (
    <StyledInput
      allowClear={allowClear}
      className={className}
      currentTheme={currentTheme!}
      disabled={isDisabled}
      maxLength={maxLength}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      ref={inputRef}
      shouldExpand={shouldExpand}
      value={value}
      valueChanged={initialValue !== undefined && initialValue !== value}
    />
  );
};

export default Input;
