import { NO_OP_HANDLER } from 'constants/General';
import useArraySorters, { IuseArraySorters } from 'hooks/useArraySorters';
import { Context, createContext, ReactNode } from 'react';
import { TETagTemplateDataArraySorter } from 'types/ETag';

export interface IETagTemplateSortingContext
  extends IuseArraySorters<TETagTemplateDataArraySorter> {}

export const ETagTemplateSortingContext: Context<IETagTemplateSortingContext> =
  createContext<IETagTemplateSortingContext>({
    arraySorters: [],
    addArraySorter: NO_OP_HANDLER,
    clearArraySorters: NO_OP_HANDLER,
    removeArraySorter: NO_OP_HANDLER,
    replaceArraySorter: NO_OP_HANDLER,
    setDefaultArraySorter: NO_OP_HANDLER,
  });

interface IETagTemplateSortingProviderProps {
  children: ReactNode;
}

const ETagTemplateSortingProvider = (
  props: IETagTemplateSortingProviderProps,
) => {
  const { children } = props;
  const {
    arraySorters,
    addArraySorter,
    clearArraySorters,
    removeArraySorter,
    replaceArraySorter,
    setDefaultArraySorter,
  } = useArraySorters<TETagTemplateDataArraySorter>();

  return (
    <ETagTemplateSortingContext.Provider
      value={{
        arraySorters,
        addArraySorter,
        clearArraySorters,
        removeArraySorter,
        replaceArraySorter,
        setDefaultArraySorter,
      }}
    >
      {children}
    </ETagTemplateSortingContext.Provider>
  );
};

export default ETagTemplateSortingProvider;
