import { CloudSyncOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  ERROR_RED,
  HIGHLIGHT_BLUE,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import { useState } from 'react';
import { queryMissingETags } from 'services/query/query';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import { captureError } from 'utils/error';
import { encodeIds } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const MAXIMUM_CHECK_RANGE_IN_HOURS = 25;
const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 7500;
const TOOLTIP_TITLE = 'Check for missing tags';

const CheckIcon = styled(CloudSyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const Message = styled.div`
  color: ${ERROR_RED};
  max-width: 290px;
  text-align: center;

  > span {
    color: ${HIGHLIGHT_BLUE};
  }
`;

interface IProps {
  encodedPermissionsId: string;
  end: ZonedDateTime | null;
  start: ZonedDateTime | null;
  toEntityId: TToEntityId;
}

const ToEntityETagsCheck = (props: IProps): JSX.Element => {
  const { encodedPermissionsId, end, start, toEntityId } = props;
  const { displayTimedMessage, timedMessage, showTimedMessage } =
    useDisplayTimedMessage();
  const [tooltipTitle, setTooltipTitle] = useState<string>(TOOLTIP_TITLE);

  const handleCheck = async () => {
    if (end === null || start === null) {
      displayTimedMessage(
        <ErrorMessage>Currently selected date range is invalid</ErrorMessage>,
        MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
      );
    } else if (end.diff(start, 'hours') > MAXIMUM_CHECK_RANGE_IN_HOURS) {
      displayTimedMessage(
        <ErrorMessage>
          Currently selected date range is greater than the maximum range of{' '}
          {MAXIMUM_CHECK_RANGE_IN_HOURS} hours.
        </ErrorMessage>,
        MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
      );
    } else {
      setTooltipTitle('Currently checking for missing tags...');
      displayTimedMessage(
        <Message>
          Check for missing tags process is running for{' '}
          <span>start: {start.format(DATE_TIME_FORMAT)}</span> -{' '}
          <span>end: {end.format(DATE_TIME_FORMAT)}</span> in the background.
          Please monitor summary screen normally for data updates.
        </Message>,
        MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
      );

      try {
        await queryMissingETags(toEntityId, start, end);
      } catch (error: any) {
        captureError(error);

        displayTimedMessage(
          <ErrorMessage>
            An error occurred during missing tags check. Please try again later.
          </ErrorMessage>,
          MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
        );
      } finally {
        setTooltipTitle(TOOLTIP_TITLE);
      }
    }
  };

  return (
    <Tooltip isDisabled={showTimedMessage} title={tooltipTitle}>
      <AntDesignPopover content={timedMessage} visible={showTimedMessage}>
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<CheckIcon />}
          onClick={handleCheck}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagsCheck;
