import { DATE_RANGE_LABEL_MAP } from 'constants/Summary';
import { EDefaultDateRange } from 'enums/Summary';
import { IDateRange } from 'interfaces/Summary';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const defaultDateRangeToDateRange = (
  defaultDateRange: EDefaultDateRange,
  timeZone: TTimeZone,
): IDateRange => {
  switch (defaultDateRange) {
    case EDefaultDateRange.Tomorrow:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.Tomorrow],
        start: ZonedDateTime.now(timeZone).startOf('day').add(1, 'days'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(2, 'days'),
      };
    case EDefaultDateRange.Yesterday:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.Yesterday],
        start: ZonedDateTime.now(timeZone).startOf('day').subtract(1, 'days'),
        end: ZonedDateTime.now(timeZone).startOf('day'),
      };
    case EDefaultDateRange.TodayTomorrow:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayTomorrow],
        start: ZonedDateTime.now(timeZone).startOf('day'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(2, 'days'),
      };
    case EDefaultDateRange.YesterdayToday:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.YesterdayToday],
        start: ZonedDateTime.now(timeZone).startOf('day').subtract(1, 'days'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(1, 'days'),
      };
    case EDefaultDateRange.TodayPlus2Days:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayPlus2Days],
        start: ZonedDateTime.now(timeZone).startOf('day'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(3, 'days'),
      };
    case EDefaultDateRange.TodayPlus3Days:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayPlus3Days],
        start: ZonedDateTime.now(timeZone).startOf('day'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(4, 'days'),
      };
    case EDefaultDateRange.TodayPlus4Days:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayPlus4Days],
        start: ZonedDateTime.now(timeZone).startOf('day'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(5, 'days'),
      };
    case EDefaultDateRange.LastWeek:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.LastWeek],
        start: ZonedDateTime.now(timeZone).startOf('week').subtract(1, 'weeks'),
        end: ZonedDateTime.now(timeZone).startOf('week'),
      };
    case EDefaultDateRange.ThisWeek:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.ThisWeek],
        start: ZonedDateTime.now(timeZone).startOf('week'),
        end: ZonedDateTime.now(timeZone).startOf('week').add(1, 'weeks'),
      };
    case EDefaultDateRange.NextWeek:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.NextWeek],
        start: ZonedDateTime.now(timeZone).startOf('week').add(1, 'weeks'),
        end: ZonedDateTime.now(timeZone).startOf('week').add(2, 'weeks'),
      };
    case EDefaultDateRange.LastMonth:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.LastMonth],
        start: ZonedDateTime.now(timeZone)
          .startOf('month')
          .subtract(1, 'months'),
        end: ZonedDateTime.now(timeZone).startOf('month'),
      };
    case EDefaultDateRange.ThisMonth:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.ThisMonth],
        start: ZonedDateTime.now(timeZone).startOf('month'),
        end: ZonedDateTime.now(timeZone).startOf('month').add(1, 'months'),
      };
    case EDefaultDateRange.NextMonth:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.NextMonth],
        start: ZonedDateTime.now(timeZone).startOf('month').add(1, 'months'),
        end: ZonedDateTime.now(timeZone).startOf('month').add(2, 'months'),
      };
    default:
      return {
        label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.Today],
        start: ZonedDateTime.now(timeZone).startOf('day'),
        end: ZonedDateTime.now(timeZone).startOf('day').add(1, 'days'),
      };
  }
};
