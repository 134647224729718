import {
  generatePricesForDate,
  getMarketInfoPrices,
  getMarketInfoPricesDataGridColumns,
  getMarketInfoPricesDataGridRows,
  marketInfoPricesDataGridRowsToMarketInfoPrices,
} from 'components/organisms/MarketInformation/MarketInfoPrices/helpers';
import MarketInfoPricesDataGrid from 'components/organisms/MarketInformation/MarketInfoPricesDataGrid/MarketInfoPricesDataGrid';
import { INPUT_HEIGHT } from 'constants/styles';
import { EMarketInfoMarket } from 'enums/ETag';
import { IETagMarketInfo, IETagMarketInfoPrice } from 'interfaces/ETag';
import { RefObject, useCallback, useMemo, useRef } from 'react';
import { DataGridHandle } from 'react-data-grid';
import styled from 'styled-components';
import {
  TMarketInfoPricesDataGridColumn,
  TMarketInfoPricesDataGridRow,
} from 'types/Detail';
import { getMarketDetailOptionForMarket } from 'utils/eTag';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ButtonLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${INPUT_HEIGHT};
  justify-content: center;
  width: 100%;
`;

interface IMarketInfoPricesProps {
  initialMarketInfos?: IETagMarketInfo[];
  isDisabled?: boolean;
  isEditable: boolean;
  market: EMarketInfoMarket;
  marketDate: ZonedDateTime;
  marketInfoPrices: IETagMarketInfoPrice[];
  onChange: (marketInfoPrices: IETagMarketInfoPrice[]) => void;
}

const MarketInfoPrices = ({
  initialMarketInfos,
  isDisabled,
  isEditable,
  market,
  marketDate,
  marketInfoPrices,
  onChange,
}: IMarketInfoPricesProps): JSX.Element => {
  const dataGridRef = useRef<DataGridHandle>() as RefObject<DataGridHandle>;

  const initialPrices: IETagMarketInfoPrice[] = useMemo(
    (): IETagMarketInfoPrice[] =>
      generatePricesForDate(
        marketDate,
        getMarketInfoPrices(market, initialMarketInfos),
      ),
    [initialMarketInfos, market, marketDate],
  );

  const initialRows: TMarketInfoPricesDataGridRow[] = useMemo(
    (): TMarketInfoPricesDataGridRow[] =>
      getMarketInfoPricesDataGridRows(initialPrices),
    [initialPrices],
  );

  const columns: TMarketInfoPricesDataGridColumn[] = useMemo(
    (): TMarketInfoPricesDataGridColumn[] =>
      getMarketInfoPricesDataGridColumns(initialRows),
    [initialRows],
  );

  const prices: IETagMarketInfoPrice[] = useMemo(
    (): IETagMarketInfoPrice[] =>
      generatePricesForDate(marketDate, marketInfoPrices),
    [marketDate, marketInfoPrices],
  );

  const rows: TMarketInfoPricesDataGridRow[] = useMemo(
    (): TMarketInfoPricesDataGridRow[] =>
      getMarketInfoPricesDataGridRows(prices),
    [prices],
  );

  const title: string = useMemo(
    () => `${getMarketDetailOptionForMarket(market).label} Price Info`,
    [market],
  );

  const handleRowsChange = useCallback(
    (updatedRows: TMarketInfoPricesDataGridRow[]) => {
      onChange(marketInfoPricesDataGridRowsToMarketInfoPrices(updatedRows));
    },
    [onChange],
  );

  return (
    <ButtonLayout>
      <MarketInfoPricesDataGrid
        columns={columns}
        dataGridRef={dataGridRef}
        isEditable={!isDisabled && isEditable}
        market={market}
        onRowsChange={handleRowsChange}
        rows={rows}
        title={title}
      />
    </ButtonLayout>
  );
};

export default MarketInfoPrices;
