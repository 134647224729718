import { EDraftScheduleStatus } from 'enums/ETag';
import { IETagScheduledDraftSubmission } from 'interfaces/ETag';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const scheduledDraftSubmissionsSorter = (
  a: IETagScheduledDraftSubmission,
  b: IETagScheduledDraftSubmission,
): number => {
  // can't pass a timezone to this sorter, but all items should have
  //the same timezone, so we use UTC to compare them to each other
  if (
    a.status === EDraftScheduleStatus.Failed &&
    b.status !== EDraftScheduleStatus.Failed
  ) {
    return -1;
  }

  if (
    a.status !== EDraftScheduleStatus.Failed &&
    b.status === EDraftScheduleStatus.Failed
  ) {
    return 1;
  }

  if (
    ZonedDateTime.parseIso(a.execution_time, 'UTC').isBefore(
      ZonedDateTime.parseIso(b.execution_time, 'UTC'),
    )
  ) {
    return -1;
  }
  return ZonedDateTime.parseIso(a.execution_time, 'UTC').isAfter(
    ZonedDateTime.parseIso(b.execution_time, 'UTC'),
  )
    ? 1
    : 0;
};
