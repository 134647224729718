import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  ITagDateTimeDataSet,
  ITemplateDataSet,
} from 'interfaces/SummaryInformation';
import { IViewDataTableColumn } from 'interfaces/View';
import { getColumnEntityInfoRender, getColumnRender } from 'utils/views';

export const getContactColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IContactInfo>[] => [
  {
    dataIndex: 'contact',
    render: getColumnRender(isUnconstrained),
    title: 'Contact',
    width: 'calc(100% / 3)',
  },
  {
    dataIndex: 'phone',
    render: getColumnRender(isUnconstrained),
    title: 'Phone',
    width: 'calc(100% / 3)',
  },
  {
    dataIndex: 'fax',
    render: getColumnRender(isUnconstrained),
    title: 'Fax',
    width: 'calc(100% / 3)',
  },
];

export const getDateTimeColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<ITagDateTimeDataSet>[] => [
  {
    dataIndex: 'startDate',
    render: getColumnRender(isUnconstrained),
    title: 'Tag Start',
    width: 'calc(100% / 3)',
  },
  {
    dataIndex: 'endDate',
    render: getColumnRender(isUnconstrained),
    title: 'Tag End',
    width: 'calc(100% / 3)',
  },
  {
    dataIndex: 'approvedTerminationTime',
    render: getColumnRender(isUnconstrained),
    title: 'Termination Time',
    width: 'calc(100% / 3)',
  },
];

export const getTagTemplateColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IETagTagId>[] => [
  {
    dataIndex: 'gca',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'GCA',
    width: 'calc(100% / 3)',
  },
  {
    dataIndex: 'pse',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'CPSE',
    width: 'calc(100% / 3)',
  },
  {
    dataIndex: 'lca',
    render: getColumnEntityInfoRender(isUnconstrained),
    title: 'LCA',
    width: 'calc(100% / 3)',
  },
];

export const getTemplateColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<ITemplateDataSet>[] => [
  {
    dataIndex: 'group_name',
    render: getColumnRender(isUnconstrained),
    title: 'Template Group',
    width: '25%',
  },
  {
    dataIndex: 'creator',
    render: getColumnRender(isUnconstrained),
    title: 'Template Owner',
    width: '25%',
  },
  {
    dataIndex: 'lastUpdateTime',
    render: getColumnRender(isUnconstrained),
    title: 'Last Update Time',
    width: '25%',
  },
  {
    dataIndex: 'last_update_user',
    render: getColumnRender(isUnconstrained),
    title: 'Last Updated By',
    width: '25%',
  },
];
