import {
  CURRENT_REQUEST_KEY,
  ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX,
} from 'constants/Detail';
import { useCallback } from 'react';
import { HeaderRendererProps } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetETagDetailSelectedRequestKey } from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';
import { TRootState } from 'types/Redux';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import { ZonedDateTime } from '../../../utils/zonedDateTime';

interface IRequestTransactionStatusDate {
  approvalTimestamp: Date;
  approvalStatus: string | null;
}

interface IRequestTransactionLatestStatusAndDate {
  latestApprovalTime: string;
  latestApprovalStatus: string;
}

const Container = styled.div`
  cursor: pointer;
  padding: 3px 4px;
`;

const retrieveEnergyProfileSnapshotsRequestColumnHeaderState = (
  state: TRootState,
) => ({
  selectedRequestKey: state.detail.present.selectedRequestKey,
});

const retrieveTransactionStatuses = (state: TRootState) => ({
  transactionStatuses: state.detail.present.transactionStatuses,
});

const retrieveSelectedTimezone = (state: TRootState) => {
  const { selectedToEntity, toEntityUserStates } = state.user;

  return {
    selectedTimezone: selectedToEntity
      ? toEntityUserStates[selectedToEntity.to_entity]?.selectedTimeZone
      : null,
  };
};

const EnergyProfileSnapshotsRequestColumnHeader = (
  props: HeaderRendererProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedRequestKey } = useSelector(
    retrieveEnergyProfileSnapshotsRequestColumnHeaderState,
  );
  const { transactionStatuses } = useSelector(retrieveTransactionStatuses);
  const { selectedTimezone } = useSelector(retrieveSelectedTimezone);

  const {
    column: { key, name },
  } = props;
  const requestKey: string = key.replace(
    ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX,
    '',
  );

  const isColumnSelected: boolean = requestKey === selectedRequestKey;

  const getLatestApprovalTime = (): IRequestTransactionLatestStatusAndDate => {
    let latestApprovalTime = '';
    let latestApprovalStatus = '';
    const requestTransactionStatusesDates: IRequestTransactionStatusDate[] = [];

    if (requestKey) {
      const requestTransactionStatuses = transactionStatuses.find(
        (transactionStatus) =>
          transactionStatus.request_id === parseInt(requestKey),
      );
      if (requestTransactionStatuses && requestTransactionStatuses.statuses) {
        const approvalItems = requestTransactionStatuses.statuses.map(
          (item) => {
            return {
              approvalTimestamp: item.approval_timestamp,
              approval_status: item.approval_status,
            };
          },
        );
        approvalItems.forEach((item) => {
          if (item && item.approvalTimestamp) {
            const i = {
              approvalTimestamp: new Date(item.approvalTimestamp),
              approvalStatus: item.approval_status,
            };
            requestTransactionStatusesDates.push(i);
          }
        });
        const latestRequestTransactionApprovalStatus =
          requestTransactionStatusesDates.length > 0
            ? requestTransactionStatusesDates.reduce((a, b) =>
                a.approvalTimestamp > b.approvalTimestamp ? a : b,
              )
            : null;

        latestApprovalTime = latestRequestTransactionApprovalStatus
          ? selectedTimezone
            ? ZonedDateTime.fromDate(
                latestRequestTransactionApprovalStatus.approvalTimestamp,
                selectedTimezone,
              ).format('MM/DD/YYYY HH:mm')
            : 'NA'
          : 'NA';

        latestApprovalStatus =
          requestTransactionStatuses?.resolution_status || '';
      }
    }

    return { latestApprovalTime, latestApprovalStatus };
  };

  const setSelectedRequestKey = useCallback(
    (selectedRequestKey: string) =>
      dispatch(detailSetETagDetailSelectedRequestKey(selectedRequestKey)),
    [dispatch],
  );

  const handleRequestHeaderClick = () => {
    if (setSelectedRequestKey) {
      setSelectedRequestKey(
        isColumnSelected ? CURRENT_REQUEST_KEY : requestKey,
      );
    }
  };

  const tooltipTitle = (): string => {
    const result = getLatestApprovalTime();
    return `Req ${result.latestApprovalStatus} On ${result.latestApprovalTime}`;
  };

  return (
    <Tooltip title={tooltipTitle()} isDisabled={false}>
      <Container onClick={handleRequestHeaderClick}>{name}</Container>
    </Tooltip>
  );
};

export default EnergyProfileSnapshotsRequestColumnHeader;
