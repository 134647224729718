import { Popover as AntDesignPopover } from 'antd';
import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ContainedText from 'components/molecules/ContainedText/ContainedText';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import {
  SHOW_TIME_CONFIGURATION,
  TITLE_TEXT_MAX_WIDTH,
} from 'components/molecules/ToEntityETagPopover/constants';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EActionState } from 'enums/General';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};
`;

interface IProps {
  actionState: EActionState;
  children?: ReactNode;
  childrenBefore?: boolean;
  dateTimePickerMessage?: ReactNode;
  dateTimePickerVisible?: boolean;
  errorMessage: TErrorMessage;
  errorMessageMaxWidth?: string;
  isPrimaryActionDisabled?: boolean;
  onPrimaryAction: () => void;
  onStartDateTimeChange?: (startDateTime: ZonedDateTime | null) => void;
  primaryActionLabel: string;
  startDateTime?: ZonedDateTime | null;
  timeZone: TTimeZone;
  title?: string;
  toEntityId?: TToEntityId;
}

const ToEntityETagPopover = (props: IProps) => {
  const {
    actionState,
    children,
    childrenBefore,
    dateTimePickerMessage,
    dateTimePickerVisible,
    errorMessage,
    errorMessageMaxWidth,
    isPrimaryActionDisabled,
    onPrimaryAction,
    onStartDateTimeChange,
    primaryActionLabel,
    startDateTime,
    timeZone,
    title,
  } = props;

  return (
    <Layout>
      {title ? (
        <ContainedText maxWidth={TITLE_TEXT_MAX_WIDTH} text={title} />
      ) : null}
      {childrenBefore ? children : null}
      {onStartDateTimeChange === undefined ? null : (
        <SeparatedRowLayout>
          <div>Tag Start Date Time:</div>

          <AntDesignPopover
            content={dateTimePickerMessage}
            placement='top'
            visible={dateTimePickerVisible}
          >
            <DateTimePicker
              allowClear={false}
              dateTimeShortcuts={[
                {
                  dateTime: ZonedDateTime.now(timeZone)
                    .startOf('day')
                    .subtract(1, 'days'),
                  label: 'Yesterday',
                },
                {
                  dateTime: ZonedDateTime.now(timeZone).startOf('day'),
                  label: 'Today',
                },
                {
                  dateTime: ZonedDateTime.now(timeZone)
                    .startOf('day')
                    .add(1, 'days'),
                  label: 'Tomorrow',
                },
              ]}
              format={DATE_TIME_FORMAT}
              onChange={onStartDateTimeChange}
              selectHourOnly={true}
              showTime={SHOW_TIME_CONFIGURATION}
              timeZone={timeZone}
              value={startDateTime}
            />
          </AntDesignPopover>
        </SeparatedRowLayout>
      )}
      {childrenBefore ? null : children}
      <ErrorMessage maxWidth={errorMessageMaxWidth}>
        {errorMessage}
      </ErrorMessage>
      <Actions>
        <Button
          actionState={actionState}
          isDisabled={isPrimaryActionDisabled}
          isPrimary={true}
          label={primaryActionLabel}
          onClick={onPrimaryAction}
        />
      </Actions>
    </Layout>
  );
};

export default ToEntityETagPopover;
