import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import { BUTTON_ICON_DIMENSION_VALUE } from 'constants/styles';
import { IMiscInfo } from 'interfaces/General';
import { IInputColumnConfig, IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { getInitialMiscInfo } from 'utils/detail';
import { tokenInputRender, valueInputRender } from 'utils/views';

export const MISC_INFO_ADD_BUTTON_OFFSET = {
  left: `-206px`,
  top: 'calc(100% - 2px)',
};

export const MISC_INFO_REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

const MISC_INFO_TOKEN_INPUT_WIDTH = '200px';
const MISC_INFO_VALUE_INPUT_WIDTH = '200px';

export const getEditMiscInfoColumns = (
  tokenInputColumnConfig: IInputColumnConfig<IMiscInfo>,
  valueInputColumnConfig: IInputColumnConfig<IMiscInfo>,
  hasChanged?: boolean,
): IViewDataTableColumn<IMiscInfo>[] => [
  {
    dataIndex: 'token',
    render: tokenInputRender(tokenInputColumnConfig),
    shouldCellUpdate: (record: IMiscInfo, previousRecord: IMiscInfo): boolean =>
      hasChanged || record.token !== previousRecord.token,
    title: 'Token',
    width: MISC_INFO_TOKEN_INPUT_WIDTH,
  },
  {
    dataIndex: 'value',
    render: valueInputRender(valueInputColumnConfig),
    shouldCellUpdate: (record: IMiscInfo, previousRecord: IMiscInfo): boolean =>
      hasChanged || record.value !== previousRecord.value,
    title: 'Value',
    width: MISC_INFO_VALUE_INPUT_WIDTH,
  },
];

export interface IMiscInfoEditableProps {
  data: IMiscInfo[];
  hasChanged?: boolean;
  initialEditKey: string;
  isDisabled?: boolean;
  onAdd: (value: unknown, record: IMiscInfo, index: number) => void;
  onRemove: (record: IMiscInfo, index: number) => void;
  tokenInputColumnConfig: IInputColumnConfig<IMiscInfo>;
  valueInputColumnConfig: IInputColumnConfig<IMiscInfo>;
}

const MiscInfoEditable = (props: IMiscInfoEditableProps): JSX.Element => {
  const {
    data,
    hasChanged,
    initialEditKey,
    isDisabled,
    onAdd,
    onRemove,
    tokenInputColumnConfig,
    valueInputColumnConfig,
  } = props;

  const editMiscInfoColumns = useMemo(
    () =>
      getEditMiscInfoColumns(
        tokenInputColumnConfig,
        valueInputColumnConfig,
        hasChanged,
      ),
    [hasChanged, tokenInputColumnConfig, valueInputColumnConfig],
  );

  const initialiser = useCallback(
    () => getInitialMiscInfo(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditableDataTable<IMiscInfo>
      addButtonOffset={MISC_INFO_ADD_BUTTON_OFFSET}
      columns={editMiscInfoColumns}
      data={data}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={-1}
      onAdd={onAdd}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={MISC_INFO_REMOVE_BUTTON_OFFSET}
    />
  );
};

export default MiscInfoEditable;
