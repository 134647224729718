import { Card, Collapse, Menu } from 'antd';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToEntityValidationReportCreate from 'components/molecules/ToEntityValidationReportCreate/ToEntityValidationReportCreate';
import { ConfigSectionTitle } from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import { STANDARD_SPACING } from 'constants/styles';
import usePermissions from 'hooks/usePermissions';
import { IOption, IThemedProps } from 'interfaces/Component';
import {
  IETagApprovalRule,
  IETagGetRulesResponse,
  IETagGetScheduledFailedValidationsReportsResponse,
  IETagValidationReport,
} from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import { useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import {
  retrieveApprovalRules,
  retrieveScheduledValidationReports,
} from 'services/approval/approval';
import styled from 'styled-components';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { componentBackgroundColour } from 'utils/styles';

const GroupList = styled.div`
  /* width: 30%; */
`;

const GroupContainer = styled.div<IThemedProps>`
  width: 27%;
  display: flex;
  flex-direction: column;
  padding: ${STANDARD_SPACING};
  align-items: stretch;
  background: ${(props) => componentBackgroundColour(props)};
`;

const ComponentHeading = styled(SeparatedRowLayout)`
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.25rem;
`;

interface IProps {
  encodedPermissionsId: string;
  toEntity: IToEntity;
}

const ValidationReportsConfiguration = ({
  encodedPermissionsId,
  toEntity,
}: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>('add');
  const [ruleOptions, setRuleOptions] = useState<IOption<string>[]>([]);
  const [reports, setReports] = useState<IETagValidationReport[]>([]);
  const permissions = usePermissions(encodedPermissionsId);

  const refreshReports = async () => {
    try {
      const getReportsResponse: AxiosResponse<IETagGetScheduledFailedValidationsReportsResponse> =
        await retrieveScheduledValidationReports(toEntity.to_entity);

      if (!isSuccessStatus(getReportsResponse.status)) {
        throw new Error(getReportsResponse.data.errorMessage!);
      }

      const reportsData: IETagValidationReport[] =
        getReportsResponse.data.response;
      setReports(reportsData);
    } catch (error: any) {
      captureError(error);
    }
  };

  const refreshRules = async () => {
    try {
      const getRulesResponse: AxiosResponse<IETagGetRulesResponse> =
        await retrieveApprovalRules(toEntity.to_entity);

      if (!isSuccessStatus(getRulesResponse.status)) {
        throw new Error(getRulesResponse.data.errorMessage!);
      }

      const rulesData: IETagApprovalRule[] = getRulesResponse.data.response;

      setRuleOptions(
        rulesData.map(
          (ruleDatum: IETagApprovalRule) =>
            ({
              label: ruleDatum.name,
              value: ruleDatum.name,
            } as IOption<string>),
        ),
      );
    } catch (error: any) {
      captureError(error);
    }
  };

  useAsyncEffect(async () => {
    refreshReports();
    refreshRules();
  }, []);

  return permissions.isDisplayable ? (
    <GroupContainer currentTheme={currentTheme!}>
      <Card>
        <ComponentHeading>
          <ConfigSectionTitle>
            Validation Reports Configuration
          </ConfigSectionTitle>
        </ComponentHeading>
        <Menu
          onClick={(e) => {
            setSelectedMenuKey(e.key);
          }}
          mode='horizontal'
          selectedKeys={[selectedMenuKey]}
          defaultActiveFirst={true}
        >
          <Menu.Item key='add'>{'Add Report'}</Menu.Item>
          <Menu.Item key='review'>{'View Reports'}</Menu.Item>
        </Menu>
        {/* To add a report, just display the create component */}
        {selectedMenuKey === 'add' ? (
          <ToEntityValidationReportCreate
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'createReport'],
              toEntity.to_entity,
            )}
            handleRefresh={refreshReports}
            ruleOptions={ruleOptions}
            toEntityId={toEntity.to_entity}
          />
        ) : selectedMenuKey === 'review' && reports.length > 0 ? (
          // To review reports, make a grouplist of the create component,
          // pass a report, and inputs will be disabled
          <GroupList>
            <Collapse defaultActiveKey={reports[0].report_name}>
              {reports.map((report: IETagValidationReport) => (
                <Collapse.Panel
                  header={report.report_name}
                  key={report.report_name}
                >
                  <ToEntityValidationReportCreate
                    encodedPermissionsId={encodeIds(
                      [encodedPermissionsId, 'reviewReport'],
                      toEntity.to_entity,
                    )}
                    handleRefresh={refreshReports}
                    report={report}
                    ruleOptions={ruleOptions}
                    toEntityId={toEntity.to_entity}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </GroupList>
        ) : null}
      </Card>
    </GroupContainer>
  ) : (
    <Card>
      <ComponentHeading>
        <ConfigSectionTitle>
          Validation Reports Configuration
        </ConfigSectionTitle>
      </ComponentHeading>
      <ErrorMessage>{`You do not have permissions to view the validation reports configuration for the ToEntity ${toEntity.entity_code}`}</ErrorMessage>
    </Card>
  );
};

export default ValidationReportsConfiguration;
