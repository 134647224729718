import { ESearchType } from 'enums/ETag';

export const SEARCH_TYPE_OPTIONS = [
  {
    label: 'Search by Tag ID',
    value: ESearchType.ByTagId,
  },
  {
    label: 'Search by Tag Code',
    value: ESearchType.ByTagCode,
  },
];
