import { AxiosResponse } from 'axios';
import {
  QUERY_AVAILABILITY_ERROR_MESSAGE,
  QUERY_CORRECTION_ERROR_MESSAGE,
  QUERY_REQUEST_ERROR_MESSAGE,
  QUERY_REQUEST_IDS_ERROR_MESSAGE,
  QUERY_RESOLUTION_ERROR_MESSAGE,
  QUERY_STATUS_ERROR_MESSAGE,
  QUERY_SUMMARIES_ERROR_MESSAGE as QUERY_SUMMARY_ERROR_MESSAGE,
  QUERY_TAG_ERROR_MESSAGE,
} from 'components/organisms/ToEntityQueryETagAuthority/constants';
import { ISubmitActionResponse } from 'components/organisms/ToEntityQueryETagAuthority/types';
import { ERequestStatus } from 'enums/ETag';
import { EActionState, ERegistryType, EServiceType } from 'enums/General';
import { EQueryType } from 'enums/Query';
import { IRegistryEntity } from 'interfaces/Entity';
import {
  IQueryAvailabilities,
  IQueryAvailabilityResponse,
  IQueryCorrection,
  IQueryCorrectionResponse,
  IQueryRequest,
  IQueryRequestIds,
  IQueryRequestIdsResponse,
  IQueryRequestResponse,
  IQueryResolution,
  IQueryResolutionResponse,
  IQueryResults,
  IQueryStatus,
  IQueryStatusResponse,
  IQuerySummaries,
  IQuerySummary,
  IQuerySummaryResponse,
  IQueryTag,
  IQueryTagResponse,
} from 'interfaces/Query';
import {
  queryAvailability,
  queryCorrection,
  queryRequest,
  queryRequestIds,
  queryResolution,
  queryStatus,
  querySummaries,
  queryTag,
} from 'services/query/query';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const registryTypeToUid = (registryType: ERegistryType): string =>
  registryType as string;

export const registryEntityToUid = (registryEntity: IRegistryEntity): string =>
  registryEntity.TaggingEntityID as string;

export const queryTypeToUid = (queryType: EQueryType): string =>
  queryType as string;

export const serviceTypeToUid = (serviceType: EServiceType): string =>
  serviceType as string;

export const submitQuerySummaries =
  (toEntityId: TToEntityId, start: ZonedDateTime, end: ZonedDateTime) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQuerySummaryResponse> =
        await querySummaries(toEntityId, start, end);

      const querySummaryResponse: IQuerySummaryResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          querySummaryResponse.errorMessage === null
            ? QUERY_SUMMARY_ERROR_MESSAGE
            : querySummaryResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        querySummaries: {
          QuerySummariesResponse:
            querySummaryResponse.response as IQuerySummary[],
        },
        type: EQueryType.Summary,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_SUMMARY_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryAvailability =
  (toEntityId: TToEntityId, queryEntity: string, serviceType?: EServiceType) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryAvailabilityResponse> =
        await queryAvailability(toEntityId, queryEntity, serviceType);

      const queryAvailabilityResponse: IQueryAvailabilityResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryAvailabilityResponse.errorMessage === null
            ? QUERY_AVAILABILITY_ERROR_MESSAGE
            : queryAvailabilityResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryAvailabilities: {
          QueryAvailabilitiesResponse: queryAvailabilityResponse.response,
        },
        type: EQueryType.Availability,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_AVAILABILITY_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryTag =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    queryEntity: string,
  ) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryTagResponse> = await queryTag(
        toEntityId,
        tagPrimaryKey,
        queryEntity,
      );

      const queryTagResponse: IQueryTagResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryTagResponse.errorMessage === null
            ? QUERY_TAG_ERROR_MESSAGE
            : queryTagResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryTag: queryTagResponse.response,
        type: EQueryType.Tag,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_TAG_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryRequest =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    queryEntity: string,
    requestId: number,
  ) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryRequestResponse> = await queryRequest(
        toEntityId,
        tagPrimaryKey,
        queryEntity,
        requestId,
      );

      const queryRequestResponse: IQueryRequestResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryRequestResponse.errorMessage === null
            ? QUERY_REQUEST_ERROR_MESSAGE
            : queryRequestResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryRequest: queryRequestResponse.response,
        type: EQueryType.Request,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_REQUEST_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryStatus =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    queryEntity: string,
    requestId: number,
  ) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryStatusResponse> = await queryStatus(
        toEntityId,
        tagPrimaryKey,
        queryEntity,
        requestId,
      );

      const queryStatusResponse: IQueryStatusResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryStatusResponse.errorMessage === null
            ? QUERY_STATUS_ERROR_MESSAGE
            : queryStatusResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryStatus: queryStatusResponse.response,
        type: EQueryType.Status,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_STATUS_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryResolution =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    queryEntity: string,
    requestId: number,
  ) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryResolutionResponse> =
        await queryResolution(
          toEntityId,
          tagPrimaryKey,
          queryEntity,
          requestId,
        );

      const queryResolutionResponse: IQueryResolutionResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryResolutionResponse.errorMessage === null
            ? QUERY_RESOLUTION_ERROR_MESSAGE
            : queryResolutionResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryResolution: queryResolutionResponse.response,
        type: EQueryType.Resolution,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_RESOLUTION_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryRequestIds =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    queryEntity: string,
    requestStatus: ERequestStatus,
  ) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryRequestIdsResponse> =
        await queryRequestIds(
          toEntityId,
          tagPrimaryKey,
          queryEntity,
          requestStatus,
        );

      const queryRequestIdsResponse: IQueryRequestIdsResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryRequestIdsResponse.errorMessage === null
            ? QUERY_REQUEST_IDS_ERROR_MESSAGE
            : queryRequestIdsResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryRequestIds: queryRequestIdsResponse.response,
        type: EQueryType.RequestIds,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_REQUEST_IDS_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const submitQueryCorrection =
  (
    toEntityId: TToEntityId,
    tagPrimaryKey: TETagTagPrimaryKey,
    queryEntity: string,
    correctionId: number,
  ) =>
  async (): Promise<ISubmitActionResponse> => {
    const submitActionResponse: ISubmitActionResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
    };

    try {
      const response: AxiosResponse<IQueryCorrectionResponse> =
        await queryCorrection(
          toEntityId,
          tagPrimaryKey,
          queryEntity,
          correctionId,
        );

      const queryCorrectionResponse: IQueryCorrectionResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          queryCorrectionResponse.errorMessage === null
            ? QUERY_CORRECTION_ERROR_MESSAGE
            : queryCorrectionResponse.errorMessage,
        );
      }

      submitActionResponse.actionState = EActionState.Succeeded;
      submitActionResponse.queryResults = {
        queryCorrection: queryCorrectionResponse.response,
        type: EQueryType.Correction,
      };
    } catch (error: any) {
      submitActionResponse.actionState = EActionState.Failed;
      submitActionResponse.errorMessage = isEmptyValue(error.message)
        ? QUERY_CORRECTION_ERROR_MESSAGE
        : error.message;
    }

    return submitActionResponse;
  };

export const queryTypeToDisplayString = (queryType: EQueryType): string => {
  switch (queryType) {
    case EQueryType.Availability: {
      return 'Availability';
    }
    case EQueryType.Correction: {
      return 'Correction';
    }
    case EQueryType.Request: {
      return 'Request';
    }
    case EQueryType.RequestIds: {
      return 'Request Ids';
    }
    case EQueryType.Resolution: {
      return 'Resolution';
    }
    case EQueryType.Status: {
      return 'Status';
    }
    case EQueryType.Summary: {
      return 'Summaries';
    }
    case EQueryType.Tag: {
      return 'Tag';
    }
    default: {
      return '';
    }
  }
};

export const queryResultsToJSONString = (
  queryResults: IQueryResults,
): string => {
  let query:
    | IQueryAvailabilities
    | IQueryCorrection
    | IQueryRequest
    | IQueryRequestIds
    | IQueryResolution
    | IQueryStatus
    | IQuerySummaries
    | IQueryTag
    | string
    | undefined;

  switch (queryResults.type) {
    case EQueryType.Availability: {
      query = queryResults.queryAvailabilities;
      break;
    }
    case EQueryType.Correction: {
      query = queryResults.queryCorrection;
      break;
    }
    case EQueryType.Request: {
      query = queryResults.queryRequest;
      break;
    }
    case EQueryType.RequestIds: {
      query = queryResults.queryRequestIds;
      break;
    }
    case EQueryType.Resolution: {
      query = queryResults.queryResolution;
      break;
    }
    case EQueryType.Status: {
      query = queryResults.queryStatus;
      break;
    }
    case EQueryType.Summary: {
      query = queryResults.querySummaries;
      break;
    }
    case EQueryType.Tag: {
      query = queryResults.queryTag;
      break;
    }
    default: {
      query = '';
      break;
    }
  }

  return JSON.stringify(query, undefined, 4);
};
