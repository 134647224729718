interface IPendingIconProps {
  className?: string;
}

const PendingIcon = ({ className }: IPendingIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#d48c1f'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M354.172 293.656h-20.844c-11.528 0-20.843-9.315-20.843-20.812 0-11.498 9.315-20.844 20.843-20.844h333.344c11.497 0 20.813 9.315 20.813 20.844 0 11.528-9.316 20.812-20.844 20.812h-20.813v66.184c0 33.944-14.435 66.376-39.657 89.066l-35.279 31.753c-5.561 4.998-8.629 11.872-8.629 19.341 0 7.469 3.068 14.374 8.629 19.379l35.279 31.715c25.222 22.69 39.657 55.153 39.657 89.066v68.153h20.844c11.497 0 20.843 9.346 20.843 20.843 0 11.498-9.346 20.844-20.874 20.844H333.328c-11.528 0-20.843-9.315-20.843-20.844 0-11.528 9.315-20.843 20.843-20.843h20.844V640.16c0-33.944 14.435-66.376 39.657-89.066l35.279-31.753c5.561-4.998 8.629-11.872 8.629-19.341 0-7.469-3.068-14.374-8.629-19.379l-35.279-31.715c-25.222-22.69-39.657-55.153-39.657-89.066v-66.184zm229.187 0H416.672v64.094c0 15.312 5.966 29.686 16.784 40.535l55.497 55.496c3.067 3.06 7.034 4.563 11.062 4.563 4.029 0 8.003-1.534 11.063-4.563l55.504-55.496c10.811-10.819 16.777-25.223 16.777-40.535v-64.094z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default PendingIcon;
