import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  getRequestColumns,
  rowKey,
} from 'components/organisms/RequestsInformationView/RequestTable/helpers';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { IRequestDataSet } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { alternatingTableRowBackground } from 'utils/styles';

// Specialize the DataTable component
const RequestDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<IRequestDataSet>,
      IRequestDataSet
    > &
      IThemedProps,
  ) => DataTable<IViewDataTableColumn<IRequestDataSet>, IRequestDataSet>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}

  .ant-table {
    font-size: 11px;

    &-tbody {
      > tr > td.ant-table-cell {
        padding: 8px 2px;
      }
    }
  }
`;

interface IRequestTableProps {
  data: IRequestDataSet[];
  isUnconstrained: boolean;
}

const RequestTable = ({
  data,
  isUnconstrained,
}: IRequestTableProps): JSX.Element | null => {
  const { currentTheme } = useThemeSwitcher();

  const requestColumns: IViewDataTableColumn<IRequestDataSet>[] = useMemo(
    () => getRequestColumns(isUnconstrained),
    [isUnconstrained],
  );

  return data.length > 0 ? (
    <RequestDataTable
      columns={requestColumns}
      currentTheme={currentTheme!}
      data={data}
      pagination={false}
      rowKey={rowKey}
      tableLayout='fixed'
    />
  ) : null;
};

export default RequestTable;
