import { IOption } from 'interfaces/Component';

export type TActiveOptions = IOption<EActiveOptions>[];

export enum EActiveOptions {
  ShowAll = 'show_all',
  ShowActive = 'show_active',
  ShowInactive = 'show_inactive',
}

export interface IETagTemplateId {
  id: string;
}
