import { EyeOutlined } from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  STANDARD_SPACING_VALUE,
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import usePrevious from 'hooks/usePrevious';
import { IViewDataTableColumn } from 'interfaces/View';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';
import { alternatingTableRowBackground } from 'utils/styles';

const ReviewIcon = styled(EyeOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ReviewButton = styled(IconButton)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ReviewTitle = styled.div`
  white-space: nowrap;
`;

const ViewDataTable = styled((props) => <DataTable {...props} />)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}
`;

interface IReviewDataTableContainerProps<C, D> extends IDataTableProps<C, D> {
  tableTitle?: string;
}

const ReviewDataTableContainer = <C extends any, D extends any>(
  props: IReviewDataTableContainerProps<C, D>,
): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { tableTitle, ...rest } = props;

  return (
    <AntDesignCard title={tableTitle}>
      <ViewDataTable currentTheme={currentTheme!} {...rest} />
    </AntDesignCard>
  );
};

interface IReviewDataTableProps<T>
  extends IDataTableProps<IViewDataTableColumn<T>, T> {
  id: string;
  maximumHeight?: string;
  showTitle?: boolean;
  tableTitle?: string;
  title?: string;
}

const ReviewDataTable = <T extends any>({
  data,
  id,
  maximumHeight,
  showTitle,
  title,
  ...rest
}: IReviewDataTableProps<T>): JSX.Element => {
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const reviewButtonRef =
    useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const previousData = usePrevious(data);

  const FloatOverContent: JSX.Element = useMemo(
    () => <ReviewDataTableContainer data={data} {...rest} />,
    [data, rest],
  );

  useEffect(() => {
    if (
      !isEmptyValue(floatOverContent) &&
      floatOverId === id &&
      previousData !== data
    ) {
      setFloatOverUseDragPanel(false);
      setFloatOverContent(FloatOverContent);
    }
  }, [
    data,
    FloatOverContent,
    floatOverContent,
    floatOverId,
    id,
    previousData,
    rest,
    setFloatOverContent,
    setFloatOverUseDragPanel,
  ]);

  const handleClick = () => {
    if (isEmptyValue(floatOverContent) || floatOverId !== id) {
      const { height, width, x, y } =
        reviewButtonRef.current.getBoundingClientRect();

      setFloatOverId(id);
      setFloatOverDefaultPosition({
        placement: EFloatOverPlacement.Right,
        x: x + width,
        y: y + height + STANDARD_SPACING_VALUE,
      });
      setFloatOverMaximumHeight(maximumHeight);
      setFloatOverUseDragPanel(false);
      setFloatOverContent(FloatOverContent);
    } else if (floatOverId === id) {
      setFloatOverContent(null);
    }
  };

  return (
    <SeparatedRowLayout
      centered={true}
      marginRight={STANDARD_SPACING_VALUE / 2}
    >
      {showTitle === false ? null : <ReviewTitle>{title}</ReviewTitle>}
      <Tooltip title={`Review All ${title}`}>
        <ReviewButton
          buttonRef={reviewButtonRef}
          icon={<ReviewIcon />}
          isContained={true}
          noBorder={true}
          onClick={handleClick}
          transparentBackground={true}
        />
      </Tooltip>
    </SeparatedRowLayout>
  );
};

export default ReviewDataTable;
