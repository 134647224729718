import { SaveOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { MESSAGE_DISPLAY_TIME_IN_MILLISECONDS } from 'constants/Component';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { EErrorType } from 'enums/Error';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import usePrevious from 'hooks/usePrevious';
import { useEffect } from 'react';
import styled from 'styled-components';
import { TErrorMessage, TErrorType } from 'types/Error';

const SaveIcon = styled(SaveOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface ISaveDetailsButtonProps {
  encodedPermissionsId: string;
  errorMessage: TErrorMessage;
  errorType: TErrorType;
  isDisabled?: boolean;
  onSave: () => void;
}

const SaveDetailsButton = ({
  encodedPermissionsId,
  errorMessage,
  errorType,
  isDisabled,
  onSave,
}: ISaveDetailsButtonProps): JSX.Element => {
  const { displayTimedMessage, timedMessage, showTimedMessage } =
    useDisplayTimedMessage();
  const previousErrorMessage: TErrorMessage = usePrevious(errorMessage);

  useEffect(() => {
    if (
      previousErrorMessage !== errorMessage &&
      errorMessage !== null &&
      errorType === EErrorType.Update
    ) {
      displayTimedMessage(
        <ErrorMessage>{errorMessage}</ErrorMessage>,
        MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
      );
    }
  }, [displayTimedMessage, errorMessage, errorType, previousErrorMessage]);

  return (
    <Tooltip isDisabled={showTimedMessage} title='Save Details'>
      <AntDesignPopover
        content={timedMessage}
        placement='topLeft'
        visible={showTimedMessage}
      >
        <IconButton
          encodedPermissionsId={encodedPermissionsId}
          icon={<SaveIcon />}
          isDisabled={isDisabled}
          onClick={onSave}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default SaveDetailsButton;
