import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import Link from 'components/atoms/Link/Link';
import {
  BooleanCell,
  DateCell,
  DateTimeCell,
  StringCell,
} from 'components/molecules/Cell/SimpleCell';
import { DEFAULT_ALTERNATING_ROW_STYLE } from 'components/molecules/RowStylingConfigurator/constants';
import { ITemplateHeaderCellProps } from 'components/molecules/TemplateHeaderCell/TemplateHeaderCell';
import { yesNoFromBool } from 'components/molecules/ToEntityDataTable/helpers';
import {
  ETAG_BOOLEAN_OPTIONS,
  ETAG_TEMPLATE_ENUM_OPTIONS_MAP,
} from 'constants/ETag';
import { DATE_FORMAT } from 'constants/time';
import { EAdHocFilter } from 'enums/AdHocFilter';
import { EColumnType } from 'enums/ETag';
import { ERequestFilterType } from 'enums/Filter';
import { ETheme } from 'enums/Style';
import {
  IETagAdHocFilters,
  IETagTemplate,
  IETagTemplateColumnData,
  IETagTemplateColumnDataAgGrid,
  IETagTemplateDataSet,
} from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import {
  IColumnSort,
  ISummaryStyles,
  ISummaryThemedStyles,
} from 'interfaces/Summary';
import { IToEntity } from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { TETagTemplateDataArrayDirectionalSorter } from 'types/ETag';
import { TRequestBodyFilter } from 'types/Filter';
import { eTagTemplateSort, getKeyForETagTemplateDataSet } from 'utils/eTag';
import {
  booleanSortWithEmpties,
  dateTimeStringSortWithEmpties,
  stringSortWithEmpties,
} from 'utils/sort';
import { backgroundColour } from 'utils/styles';
import {
  toFormattedDateStringHandleNull,
  toFormattedDateTimeStringHandleNull,
} from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const transformRetrievedTemplates = (
  templates: IETagTemplate[],
): IETagTemplateDataSet[] => {
  const mappedColumns: IETagTemplateDataSet[] = templates.map(
    (template: IETagTemplate): IETagTemplateDataSet =>
      ({
        active: template.active,
        id: template.id,
        name: template.name,
        description: template.description,
        cpse: template.tag?.tag_id?.pse?.entity_code,
        gca: template.tag?.tag_id?.gca?.entity_code,
        lca: template.tag?.tag_id?.lca?.entity_code,
        source:
          template.tag?.path?.physical_segments?.generation_physical_segment
            ?.generation_source?.point_name,
        sink: template.tag?.path?.physical_segments?.load_physical_segment
          ?.load_sink?.point_name,
        tag_type: template.tag?.tag_meta_attribute?.transaction_type,
        tag_note: template.tag?.tag_meta_attribute?.notes,
        creator: template.creator,
        last_update_time: template.last_update_time,
        last_update_user: template.last_update_user,
      } as IETagTemplateDataSet),
  );
  return mappedColumns;
};

interface IStringIndexColumnData extends IETagTemplateColumnData {
  dataIndex: string;
}

const STRING_FILTERS = [
  EAdHocFilter.Contains,
  EAdHocFilter.DoesNotContain,
  EAdHocFilter.StartsWith,
  EAdHocFilter.DoesNotStartWith,
  EAdHocFilter.EndsWith,
  EAdHocFilter.DoesNotEndWith,
  EAdHocFilter.Equals,
  EAdHocFilter.DoesNotEqual,
  EAdHocFilter.CaseInsensitiveEquals,
  EAdHocFilter.CaseInsensitiveDoesNotEqual,
  EAdHocFilter.IsBlank,
  EAdHocFilter.IsNotBlank,
];

const getThemedStyles = (): ISummaryThemedStyles => {
  const summaryThemedStyles: Record<string, ISummaryStyles> = {};

  Object.values(ETheme).forEach((currentTheme: string) => {
    let summaryStyles: ISummaryStyles = {
      'background-color': backgroundColour({ currentTheme }),
    };
    summaryThemedStyles[currentTheme] = summaryStyles;
  });

  return summaryThemedStyles as ISummaryThemedStyles & IIndexable;
};

export const transformColumnInformation = (
  columnData: IETagTemplateColumnData[],
  encodedPermissionsId: string,
  toEntity: IToEntity,
  timeZone: TTimeZone,
): IETagTemplateColumnData[] => {
  const defaultSort: Record<string, IColumnSort> = {};

  const transformed: IETagTemplateColumnData[] = [];

  columnData.forEach((column: IETagTemplateColumnData, index: number) => {
    const columnCopy: IStringIndexColumnData = {
      ...column,
      dataIndex: Array.isArray(column.dataIndex)
        ? column.dataIndex.join('.')
        : column.dataIndex,
    };

    const adHocFilters: IETagAdHocFilters = {
      filters: [],
    };
    let sort: TETagTemplateDataArrayDirectionalSorter | undefined = undefined;

    switch (columnCopy.type) {
      case EColumnType.Boolean: {
        columnCopy.render = (
          value: boolean,
          _record: IETagTemplate,
        ): JSX.Element => (
          <BooleanCell
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={getThemedStyles()}
            value={value}
          />
        );

        adHocFilters.enumOptions = ETAG_BOOLEAN_OPTIONS;
        adHocFilters.filters = [
          EAdHocFilter.BooleanIsOneOf,
          EAdHocFilter.BooleanIsNotOneOf,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagTemplateSort<boolean>(booleanSortWithEmpties)(
          columnCopy.dataIndex,
        );
        break;
      }
      case EColumnType.Date: {
        columnCopy.render = (
          value: string,
          _record: IETagTemplate,
        ): JSX.Element => (
          <DateCell
            minWidth={columnCopy.minColumnWidth}
            timeZone={timeZone}
            summaryThemedStyles={getThemedStyles()}
            value={value}
          />
        );

        adHocFilters.filters = [
          EAdHocFilter.IsSame,
          EAdHocFilter.IsBefore,
          EAdHocFilter.IsSameOrBefore,
          EAdHocFilter.IsAfter,
          EAdHocFilter.IsSameOrAfter,
          EAdHocFilter.IsWithin,
          EAdHocFilter.InclusiveIsWithin,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagTemplateSort<string>(
          dateTimeStringSortWithEmpties(timeZone, false),
        )(columnCopy.dataIndex);
        break;
      }
      case EColumnType.DateTime: {
        columnCopy.render = (
          value: string,
          _record: IETagTemplate,
        ): JSX.Element => (
          <DateTimeCell
            minWidth={columnCopy.minColumnWidth}
            timeZone={timeZone}
            summaryThemedStyles={getThemedStyles()}
            value={value}
          />
        );

        adHocFilters.filters = [
          EAdHocFilter.IsBefore,
          EAdHocFilter.IsSameOrBefore,
          EAdHocFilter.IsAfter,
          EAdHocFilter.IsSameOrAfter,
          EAdHocFilter.IsWithin,
          EAdHocFilter.InclusiveIsWithin,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagTemplateSort<string>(
          dateTimeStringSortWithEmpties(timeZone, false),
        )(columnCopy.dataIndex);
        break;
      }
      case EColumnType.Enumeration: {
        columnCopy.render = (
          value: string,
          _record: IETagTemplate,
        ): JSX.Element => (
          <StringCell
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={getThemedStyles()}
            value={value}
          />
        );

        adHocFilters.enumOptions =
          ETAG_TEMPLATE_ENUM_OPTIONS_MAP[columnCopy.dataIndex];
        adHocFilters.filters = [
          EAdHocFilter.IsOneOf,
          EAdHocFilter.IsNotOneOf,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagTemplateSort<string>(stringSortWithEmpties)(
          columnCopy.dataIndex,
        );
        break;
      }
      case EColumnType.DetailLink: {
        columnCopy.render = (
          value: string,
          record: IETagTemplate,
        ): JSX.Element =>
          record.id !== null ? (
            <Link
              to={`/detail?mode=review&toEntity=${toEntity.to_entity}&templateId=${record.id}&defaultTimeZone=${timeZone}`}
              target='_blank'
            >
              <StringCell
                minWidth={columnCopy.minColumnWidth}
                summaryThemedStyles={getThemedStyles()}
                value={value}
              />
            </Link>
          ) : (
            <StringCell
              minWidth={columnCopy.minColumnWidth}
              summaryThemedStyles={getThemedStyles()}
              value={value}
            />
          );

        adHocFilters.filters = STRING_FILTERS;

        sort = eTagTemplateSort<string>(stringSortWithEmpties)(
          columnCopy.dataIndex,
        );
        break;
      }
      case EColumnType.String: {
        columnCopy.render = (
          value: string,
          _record: IETagTemplate,
        ): JSX.Element => (
          <StringCell
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={getThemedStyles()}
            value={value}
          />
        );

        adHocFilters.filters = STRING_FILTERS;

        sort = eTagTemplateSort<string>(stringSortWithEmpties)(
          columnCopy.dataIndex,
        );
        break;
      }
      default: {
        throw new Error(`Invalid column type: ${columnCopy.type}`);
      }
    }

    const headerCellProps: ITemplateHeaderCellProps = {
      ...columnCopy,
      adHocFilters,
      // We use a doubled index in order to allow for a gap between each
      // column's z-index value which allows us to later insert the
      // extra dst column in between existing z-indexes and thus retain
      // the correct descending z-index values needed to ensure that we
      // always render input expansions from left to right and on top.
      columnIndex: index * 2,
      defaultSort: defaultSort[columnCopy.dataIndex],
      encodedPermissionsId,
      isPrimaryColumn: false,
      sort,
      timeZone,
    };

    // Forward column data to the header cell component
    columnCopy.onHeaderCell = (): ITemplateHeaderCellProps => headerCellProps;

    transformed.push(columnCopy);
  });

  const firstColumn: IETagTemplateColumnData | undefined = transformed[0];

  if (firstColumn !== undefined && firstColumn.onHeaderCell !== undefined) {
    const headerCellProps: ITemplateHeaderCellProps = {
      ...firstColumn.onHeaderCell(firstColumn),
      isPrimaryColumn: true,
    };

    firstColumn.onHeaderCell = (): ITemplateHeaderCellProps => headerCellProps;
  }

  return transformed;
};

const caseInsensitiveCompare = (
  a: string | undefined,
  b: string | undefined,
) => {
  return (a ?? '').toLowerCase().localeCompare((b ?? '').toLowerCase());
};

export const transformGridColumnInformation = (
  columnData: IETagTemplateColumnDataAgGrid[],
  toEntity: IToEntity,
  timeZone: TTimeZone,
  currentTheme: string | undefined = ETheme.Light,
): ColDef[] => {
  const transformed: ColDef[] = [];

  columnData.forEach((column: IETagTemplateColumnDataAgGrid) => {
    const columnDefinition: ColDef = {
      cellStyle: (params: CellClassParams) => ({
        'background-color': backgroundColour({ currentTheme }),
        'text-align': 'center',
        'padding-left': '4px',
        'padding-right': '4px',
        ...(params.rowIndex && params.rowIndex % 2 === 1
          ? DEFAULT_ALTERNATING_ROW_STYLE[currentTheme]
          : null),
      }),
      comparator: caseInsensitiveCompare,
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
      menuTabs: [],
      tooltipValueGetter: (params: ITooltipParams) =>
        params.valueFormatted ?? params.value,
      field: column.field,
      filterParams: {
        newRowsAction: 'keep',
      },
      // TODO: Apply AG Grid recommended fix, if any
      // Disabled this to adress the column filter not being clickable in certain browsers
      // floatingFilterComponentParams: { suppressFilterButton: true },
      headerName: column.headerName,
      maxWidth: column.maxWidth,
      minWidth: column.minWidth,
    };

    switch (column.type) {
      case EColumnType.Boolean: {
        columnDefinition.field = undefined;
        columnDefinition.valueGetter = (params: ValueGetterParams) =>
          yesNoFromBool(params.data[column.field]);
        columnDefinition.cellRenderer = (props: ICellRendererParams) => (
          <input
            type='checkbox'
            checked={props.value === 'Yes' ? true : false}
            disabled
          />
        );

        break;
      }
      case EColumnType.Date:
      case EColumnType.DateTime: {
        if (column.type === EColumnType.Date) {
          columnDefinition.valueGetter = (params: ValueGetterParams) =>
            toFormattedDateStringHandleNull(
              params.data[column.field],
              timeZone,
            );
        }
        if (column.type === EColumnType.DateTime) {
          columnDefinition.valueGetter = (params: ValueGetterParams) =>
            toFormattedDateTimeStringHandleNull(
              params.data[column.field],
              timeZone,
            );
        }
        columnDefinition.chartDataType = 'time';
        columnDefinition.filter = 'agMultiColumnFilter';
        columnDefinition.filterParams = {
          ...columnDefinition.filterParams,
          filters: [
            {
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: function (filterDate: Date, cellValue: string) {
                  if (cellValue === null) {
                    return -1;
                  }
                  return (
                    new Date(
                      ZonedDateTime.parse(cellValue, 'UTC', DATE_FORMAT).format(
                        'M/D/YYYY',
                      ),
                    ).getTime() -
                    new Date(
                      ZonedDateTime.fromDate(filterDate, 'UTC').format(
                        'M/D/YYYY',
                      ),
                    ).getTime()
                  );
                },
                newRowsAction: 'keep',
              },
            },
            {
              filter: 'agSetColumnFilter',
            },
          ],
        };
        columnDefinition.floatingFilterComponentParams = {
          suppressFilterButton: false,
        };
        break;
      }
      case EColumnType.DetailLink: {
        columnDefinition.cellRenderer = (params: ICellRendererParams) => {
          return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
              href={
                (process.env.REACT_APP_BASEDIR ?? '') +
                `/detail?mode=review&toEntity=${toEntity.to_entity}&templateId=${params.data.id}&defaultTimeZone=${timeZone}`
              }
              target='_blank'
            >
              {params.value}
            </a>
          );
        };
        break;
      }
      case EColumnType.Enumeration:
      case EColumnType.String: {
        break;
      }
      default: {
        throw new Error(`Invalid column type: ${columnDefinition.type}`);
      }
    }

    transformed.push(columnDefinition);
  });

  return transformed;
};

export const generateRequestBodyFiltersFromUiFilters = (
  showActive: boolean | undefined,
  selectedTemplateGroup: string | null | undefined,
): TRequestBodyFilter => {
  const filters: (TRequestBodyFilter | undefined)[] = [];

  switch (showActive) {
    case undefined:
      filters.push({
        type: ERequestFilterType.Or,
        filters: [
          { attribute: 'active', type: ERequestFilterType.Equals, value: true },
          {
            attribute: 'active',
            type: ERequestFilterType.Equals,
            value: false,
          },
        ],
      });
      break;
    case true:
      filters.push({
        attribute: 'active',
        type: ERequestFilterType.Equals,
        value: true,
      });
      break;
    case false:
      filters.push({
        attribute: 'active',
        type: ERequestFilterType.Equals,
        value: false,
      });
      break;
  }

  if (selectedTemplateGroup !== undefined) {
    filters.push({
      attribute: 'group_name',
      type: ERequestFilterType.Equals,
      value: selectedTemplateGroup,
    });
  }

  const filtersOnly: TRequestBodyFilter[] = [];
  filters.forEach((item: TRequestBodyFilter | undefined) => {
    if (item !== undefined) {
      filtersOnly.push(item);
    }
  });

  return filtersOnly.length > 1
    ? { type: ERequestFilterType.And, filters: filtersOnly }
    : filtersOnly[0];
};

export const checkStringEquality = (
  a: string | null | undefined,
  b: string | null | undefined,
): boolean =>
  a === undefined && b === undefined
    ? true
    : a === undefined || b === undefined
    ? false
    : a === null && b === null
    ? true
    : a === null || b === null
    ? false
    : a === b
    ? true
    : false;

export const connectByRecordKey = (eTagDataSet: IETagTemplateDataSet[]) => {
  let index: number = 0;
  let rowIndex: number = -1;

  while (index < eTagDataSet.length) {
    const record = eTagDataSet[index];
    const recordKey = getKeyForETagTemplateDataSet(record);

    rowIndex += 1;
    record.rowIndex = rowIndex;

    index += 1;

    if (index === eTagDataSet.length) {
      break;
    }

    let nextRecord = eTagDataSet[index];
    while (recordKey === getKeyForETagTemplateDataSet(nextRecord)) {
      nextRecord.rowIndex = rowIndex;
      index += 1;

      if (index === eTagDataSet.length) {
        break;
      }

      nextRecord = eTagDataSet[index];
    }
  }
};
