import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import { getRequestAuditColumns } from 'components/organisms/RequestsInformationView/RequestAuditTable/helpers';
import { rowKey } from 'components/organisms/RequestsInformationView/RequestAuditTable/helpers';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import usePermissions from 'hooks/usePermissions';
import { IThemedProps } from 'interfaces/Component';
import { IRequestAuditDataSet } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';
import { alternatingTableRowBackground } from 'utils/styles';

// Specialize the DataTable component
const RequestAuditDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<IRequestAuditDataSet>,
      IRequestAuditDataSet
    > &
      IThemedProps,
  ) =>
    DataTable<IViewDataTableColumn<IRequestAuditDataSet>, IRequestAuditDataSet>(
      props,
    ),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
    ${(props) => alternatingTableRowBackground(props)}

    .ant-table {
    font-size: 11px;

    &-tbody {
      > tr > td.ant-table-cell {
        padding: 8px 2px;
      }
    }
  }
`;

interface IRequestAuditTableProps {
  data: IRequestAuditDataSet[];
  isUnconstrained: boolean;
  toEntityId?: string;
}

const RequestAuditTable = ({
  data,
  isUnconstrained,
  toEntityId,
}: IRequestAuditTableProps): JSX.Element | null => {
  const { currentTheme } = useThemeSwitcher();
  const permissions = usePermissions(
    encodeIds(['auditDataTable', 'approvalColumns'], toEntityId),
  );
  const isSubmitted = data[0].submitted_by !== null;

  const requestAuditColumns: IViewDataTableColumn<IRequestAuditDataSet>[] =
    useMemo(
      () =>
        getRequestAuditColumns(
          isUnconstrained,
          permissions.isDisplayable,
          isSubmitted,
        ),
      [isUnconstrained, isSubmitted, permissions],
    );

  return data.length > 0 ? (
    <RequestAuditDataTable
      columns={requestAuditColumns}
      currentTheme={currentTheme!}
      data={data}
      pagination={false}
      rowKey={rowKey}
      tableLayout='fixed'
    />
  ) : null;
};

export default RequestAuditTable;
