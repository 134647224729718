import EditDataTable, {
  IEditDataTableProps,
} from 'components/molecules/EditDataTable/EditDataTable';
import {
  EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY,
  EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY,
} from 'constants/Detail';
import {
  BUTTON_ICON_DIMENSION,
  BUTTON_ICON_DIMENSION_VALUE,
  DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT,
  STANDARD_SPACING,
  VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHOD_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_TAG_IDS_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { ECompositeState, EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EViewMode } from 'enums/View';
import useExpandableDataTable from 'hooks/useExpandableDataTable';
import usePrevious from 'hooks/usePrevious';
import ExpandedContractsEditable from 'hooks/useTransmissionEditColumns/LossMethodsEdit/ExpandedContractsEditable';
import ExpandedETagTagIdsEditable from 'hooks/useTransmissionEditColumns/LossMethodsEdit/ExpandedETagTagIdsEditable';
import { editLossMethodsInformationToDetailState } from 'hooks/useTransmissionEditColumns/LossMethodsEdit/helpers';
import {
  ELossMethodsExpandedColumn,
  IEditLossMethodsInformation,
} from 'hooks/useTransmissionEditColumns/LossMethodsEdit/types';
import useLossMethodsEditColumns from 'hooks/useTransmissionEditColumns/LossMethodsEdit/useLossMethodsEditColumns';
import { IOption, IValueChanged } from 'interfaces/Component';
import { IEntityInfo } from 'interfaces/Entity';
import { IETagTagId } from 'interfaces/ETag';
import { IContract } from 'interfaces/General';
import { IInputColumnConfig } from 'interfaces/View';
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailEditETagDetail } from 'reduxes/Detail/actions';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import {
  adjustmentDisabledDate,
  getEditInfoKey,
  getInitialLossAccounting,
  getInitialLossAccountingKey,
  getSplitEditInfoKey,
} from 'utils/detail';
import { eventToStringOrNull } from 'utils/general';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { inputValueChanged } from 'utils/styles';

const EDIT_BUTTON_WIDTH = `calc(${VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH} - ${STANDARD_SPACING})`;

const Wrapper = styled.div<IValueChanged>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(${EDIT_BUTTON_WIDTH} - ${STANDARD_SPACING});

  ${(props) => inputValueChanged(props)}
`;

const ADD_BUTTON_OFFSET = {
  left: `calc(-1 * (6px + ${VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_LOSS_METHOD_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_LOSS_TAG_IDS_WIDTH}))`,
  top: 'calc(100% - 2px)',
};

const REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

interface IExpandedRowLayoutProps {
  rightOffset?: string;
}

const ExpandedRowLayout = styled.div<IExpandedRowLayoutProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: ${STANDARD_SPACING};

  ${(props) =>
    props.rightOffset === undefined
      ? ''
      : `padding-right: ${props.rightOffset};`}
`;

interface ILossMethodsEditProps {
  isDisabled?: boolean;
  isUnconstrained?: boolean;
  lossMethods?: string;
  physicalSegmentId?: number | null;
}

const retrieveLossMethodsEditState = (state: TRootState) => {
  const {
    composite_state,
    focusKey,
    isDetailDeleted,
    lossAccountings,
    registryEntities,
    retrievingDistributedTagItems,
    selectedRequestType,
    start_date,
    transmission_physical_segments,
    updatingDetail,
    viewMode,
  } = state.detail.present;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;
  const isLossAccountingsLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isLossAccountingsLoading,
    lossAccountings,
    registryEntities,
    selectedRequestType,
    start_date,
    timeZone,
    transmission_physical_segments,
    viewMode,
  };
};

const LossMethodsEdit = (props: ILossMethodsEditProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isLossAccountingsLoading,
    lossAccountings,
    registryEntities,
    selectedRequestType,
    start_date,
    timeZone,
    transmission_physical_segments,
    viewMode,
  } = useSelector(retrieveLossMethodsEditState);
  const {
    isDisabled,
    isUnconstrained = false,
    lossMethods,
    physicalSegmentId,
  } = props;
  const [pidOptions, setPidOptions] = useState<IOption<number>[]>([]);
  const previousLossAccountings: MutableRefObject<IDetailLossAccounting[]> =
    useRef<IDetailLossAccounting[]>(lossAccountings);
  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);
  const previousIsLossAccountingsLoading: boolean | undefined = usePrevious(
    isLossAccountingsLoading,
  );
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;

  const initialLossAccountings: IDetailLossAccounting[] = useMemo(() => {
    if (
      (isDetailUpdating === false && previousIsDetailUpdating === true) ||
      (isLossAccountingsLoading === false &&
        previousIsLossAccountingsLoading === true)
    ) {
      previousLossAccountings.current = lossAccountings;
    }

    return previousLossAccountings.current;
  }, [
    isDetailUpdating,
    isLossAccountingsLoading,
    lossAccountings,
    previousIsDetailUpdating,
    previousIsLossAccountingsLoading,
    previousLossAccountings,
  ]);

  const getInitialDetailLossAccountingForKey = useCallback(
    (key: string): IDetailLossAccounting | undefined =>
      initialLossAccountings === undefined
        ? undefined
        : initialLossAccountings.find(
            (detailLossAccounting: IDetailLossAccounting): boolean =>
              detailLossAccounting.key === key,
          ),
    [initialLossAccountings],
  );

  useEffect(() => {
    if (transmission_physical_segments === null) {
      if (pidOptions.length > 0) {
        setPidOptions([]);
      }
    } else if (pidOptions.length !== transmission_physical_segments.length) {
      const newPidOptions: IOption<number>[] = [];

      for (
        let i: number = 0;
        i < transmission_physical_segments.length;
        i += 1
      ) {
        // Transmission physical segment ids start from 2
        newPidOptions.push({ label: `${i + 2}`, value: i + 2 });
      }

      setPidOptions(newPidOptions);
    }
  }, [pidOptions, transmission_physical_segments]);

  const handleChange = useCallback(
    (editLossAccountingInformation: IEditLossMethodsInformation) =>
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editLossMethodsInformationToDetailState(
            editLossAccountingInformation,
            timeZone,
          ),
        }),
      ),
    [dispatch, timeZone],
  );

  const getStartDisabledDate = useCallback(
    () =>
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection
        ? adjustmentDisabledDate(
            start_date,
            viewMode === EViewMode.EditETagCorrection
              ? undefined
              : selectedRequestType,
          )
        : undefined,
    [selectedRequestType, start_date, viewMode],
  );

  const initialiser = useCallback(
    () =>
      getInitialLossAccounting(
        getInitialLossAccountingKey(physicalSegmentId || undefined),
        physicalSegmentId,
      ),
    [physicalSegmentId],
  );

  const handleAddLossAccounting = useCallback(
    (value: unknown, record: IDetailLossAccounting) => {
      handleChange({
        addAfterLossAccounting: record.key,
        withPhysicalSegmentRef:
          physicalSegmentId === null || physicalSegmentId === undefined
            ? undefined
            : physicalSegmentId,
      });
    },
    [handleChange, physicalSegmentId],
  );

  const handleRemoveLossAccounting = useCallback(
    (record: IDetailLossAccounting) => {
      handleChange({ removeLossAccounting: record.key });
    },
    [handleChange],
  );

  const handleAddContract = useCallback(
    (value: unknown, record: IContract) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editContractNumbers: {
                addAfterContract: record.key,
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleRemoveContract = useCallback(
    (record: IContract) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editContractNumbers: {
                removeContract: record.key,
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const getInitialContractInputValue = useCallback(
    (contractRecord: IContract): string | null => {
      const { editIndex, label: lossAccountingKey } = getSplitEditInfoKey(
        contractRecord.key,
      );
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        getInitialDetailLossAccountingForKey(lossAccountingKey);

      if (
        initialDetailLossAccounting === undefined ||
        initialDetailLossAccounting.lossMethod === null ||
        initialDetailLossAccounting.lossMethod.contractNumbers === null
      ) {
        return null;
      }

      const initialContract: IContract | undefined =
        initialDetailLossAccounting.lossMethod.contractNumbers[editIndex];

      return initialContract === undefined ? null : initialContract.contract;
    },
    [getInitialDetailLossAccountingForKey],
  );

  const contractInputColumnConfig: IInputColumnConfig<IContract> = useMemo(
    () => ({
      getInitialValue: getInitialContractInputValue,
      getKey: (record: IContract) => record.key,
      isDisabled: isDetailDeleted || isDetailUpdating,
      onChange: (event: ChangeEvent<HTMLInputElement>, record: IContract) => {
        const { label: detailLossAccountingKey } = getSplitEditInfoKey(
          record.key,
        );

        handleChange({
          editLossAccountingMap: {
            [detailLossAccountingKey]: {
              editLossMethod: {
                editContractNumbers: {
                  editContractMap: {
                    [record.key]: {
                      contract: eventToStringOrNull(event),
                    },
                  },
                },
              },
            },
          },
        });
      },
      shouldFocusOn: (record: IContract): boolean => record.key === focusKey,
    }),
    [
      focusKey,
      getInitialContractInputValue,
      handleChange,
      isDetailDeleted,
      isDetailUpdating,
    ],
  );

  const handleAddETagTagId = useCallback(
    (value: unknown, record: IETagTagId) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editTagIds: {
                addAfterETagTagId: record.key,
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleRemoveETagTagId = useCallback(
    (record: IETagTagId) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editTagIds: {
                removeETagTagId: record.key,
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleCpseChange = useCallback(
    (value: IEntityInfo | null | undefined, record: IETagTagId) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editTagIds: {
                editETagTagIdMap: {
                  [record.key]: {
                    pse: value === undefined ? null : value,
                  },
                },
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleGcaChange = useCallback(
    (value: IEntityInfo | null | undefined, record: IETagTagId) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editTagIds: {
                editETagTagIdMap: {
                  [record.key]: {
                    gca: value === undefined ? null : value,
                  },
                },
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleLcaChange = useCallback(
    (value: IEntityInfo | null | undefined, record: IETagTagId) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editTagIds: {
                editETagTagIdMap: {
                  [record.key]: {
                    lca: value === undefined ? null : value,
                  },
                },
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleTagCodeChange = useCallback(
    (tagCode: string, record: IETagTagId) => {
      const { label: detailLossAccountingKey } = getSplitEditInfoKey(
        record.key,
      );

      handleChange({
        editLossAccountingMap: {
          [detailLossAccountingKey]: {
            editLossMethod: {
              editTagIds: {
                editETagTagIdMap: {
                  [record.key]: {
                    tag_code: tagCode,
                  },
                },
              },
            },
          },
        },
      });
    },
    [handleChange],
  );

  const getExpandedRowRender = useCallback(
    (
        getRowExpandedKey: (
          record: IDetailLossAccounting,
        ) => ELossMethodsExpandedColumn | undefined,
      ) =>
      (record: IDetailLossAccounting): JSX.Element => {
        const lossMethodsExpandedColumn:
          | ELossMethodsExpandedColumn
          | undefined = getRowExpandedKey(record);
        const initialDetailLossAccounting: IDetailLossAccounting | undefined =
          getInitialDetailLossAccountingForKey(record.key);

        if (lossMethodsExpandedColumn !== undefined) {
          if (
            lossMethodsExpandedColumn === ELossMethodsExpandedColumn.Contracts
          ) {
            return (
              <ExpandedRowLayout>
                <ExpandedContractsEditable
                  contractInputColumnConfig={contractInputColumnConfig}
                  hasChanged={hasDetailChanged}
                  initialEditKey={getEditInfoKey(
                    record.key,
                    EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY,
                    0,
                  )}
                  isDisabled={isDisabled}
                  lossAccounting={record}
                  onAdd={handleAddContract}
                  onRemove={handleRemoveContract}
                />
              </ExpandedRowLayout>
            );
          }

          if (
            lossMethodsExpandedColumn === ELossMethodsExpandedColumn.ETagTagIds
          ) {
            return (
              <ExpandedRowLayout
                rightOffset={VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH}
              >
                <ExpandedETagTagIdsEditable
                  initialETagTagIds={
                    initialDetailLossAccounting === undefined ||
                    initialDetailLossAccounting.lossMethod === null ||
                    initialDetailLossAccounting.lossMethod.tag_ids === null
                      ? undefined
                      : initialDetailLossAccounting.lossMethod.tag_ids
                  }
                  initialEditKey={getEditInfoKey(
                    record.key,
                    EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY,
                    0,
                  )}
                  isDetailDeleted={isDetailDeleted}
                  isDetailUpdating={isDetailUpdating}
                  isDisabled={isDisabled}
                  lossAccounting={record}
                  maximumAllowableAdds={-1}
                  onAdd={handleAddETagTagId}
                  onCpseChange={handleCpseChange}
                  onGcaChange={handleGcaChange}
                  onLcaChange={handleLcaChange}
                  onRemove={handleRemoveETagTagId}
                  onTagCodeChange={handleTagCodeChange}
                  registryEntities={registryEntities}
                />
              </ExpandedRowLayout>
            );
          }
        }

        return <></>;
      },
    [
      contractInputColumnConfig,
      getInitialDetailLossAccountingForKey,
      hasDetailChanged,
      handleAddContract,
      handleAddETagTagId,
      handleCpseChange,
      handleGcaChange,
      handleLcaChange,
      handleRemoveContract,
      handleRemoveETagTagId,
      handleTagCodeChange,
      isDetailDeleted,
      isDetailUpdating,
      isDisabled,
      registryEntities,
    ],
  );

  const getRowKey = useCallback(
    (record: IDetailLossAccounting): string => record.key,
    [],
  );

  const { expandableConfig, updateRowExpandedKey } = useExpandableDataTable<
    IDetailLossAccounting,
    ELossMethodsExpandedColumn
  >(ELossMethodsExpandedColumn.None, getExpandedRowRender, getRowKey, false);

  const handleClickExpand = useCallback(
    (
      lossMethodsExpandedColumn: ELossMethodsExpandedColumn,
      record: IDetailLossAccounting,
    ) => {
      updateRowExpandedKey(lossMethodsExpandedColumn, record);
    },
    [updateRowExpandedKey],
  );

  const initialLossAccountingsIds: number[] = useMemo(
    () =>
      initialLossAccountings === null
        ? []
        : physicalSegmentId
        ? initialLossAccountings
            .filter((i) => i.physical_segment_ref === physicalSegmentId)
            .map(
              (detailLossAccounting: IDetailLossAccounting): number =>
                detailLossAccounting.physical_segment_ref || 0,
            )
        : initialLossAccountings
            .filter(
              (i) =>
                i.physical_segment_ref !== undefined &&
                i.physical_segment_ref !== null,
            )
            .map(
              (detailLossAccounting: IDetailLossAccounting): number =>
                detailLossAccounting.physical_segment_ref || 0,
            ),
    [initialLossAccountings, physicalSegmentId],
  );

  const initialLossAccountingsKeys: string[] = useMemo(
    () =>
      initialLossAccountings === null
        ? []
        : physicalSegmentId
        ? initialLossAccountings
            .filter((i) => i.physical_segment_ref === physicalSegmentId)
            .map(
              (detailLossAccounting: IDetailLossAccounting): string =>
                detailLossAccounting.key,
            )
        : initialLossAccountings
            .filter(
              (i) =>
                i.physical_segment_ref !== undefined &&
                i.physical_segment_ref !== null,
            )
            .map(
              (detailLossAccounting: IDetailLossAccounting): string =>
                detailLossAccounting.key,
            ),
    [initialLossAccountings, physicalSegmentId],
  );

  const lossMethodsColumns = useLossMethodsEditColumns(
    getInitialDetailLossAccountingForKey,
    getStartDisabledDate,
    initialLossAccountings,
    initialLossAccountingsIds,
    initialLossAccountingsKeys,
    isDetailDeleted,
    isDetailUpdating,
    isUnconstrained,
    lossAccountings,
    handleChange,
    handleClickExpand,
    pidOptions,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    selectedRequestType,
    timeZone,
    viewMode,
  );

  const shouldFloatOverUpdate = useCallback(
    (
      props: IEditDataTableProps<IDetailLossAccounting>,
      previousProps: IEditDataTableProps<IDetailLossAccounting> | undefined,
    ): boolean =>
      previousProps === undefined ||
      props.columns !== previousProps.columns ||
      props.data !== previousProps.data ||
      props.expandable !== previousProps.expandable,
    [],
  );

  const filteredData = useMemo(() => {
    if (physicalSegmentId === undefined) {
      return lossAccountings;
    }

    return lossAccountings.filter(
      (lossAccounting: IDetailLossAccounting) =>
        lossAccounting.physical_segment_ref === physicalSegmentId ||
        lossAccounting.physical_segment_ref === null,
    );
  }, [lossAccountings, physicalSegmentId]);

  const allowRowAdd = useCallback(
    (
      value: unknown,
      record: IDetailLossAccounting,
      data: IDetailLossAccounting[],
      index: number,
    ): boolean => {
      return (
        lossAccountings.findIndex(
          (detailLossAccounting: IDetailLossAccounting) =>
            detailLossAccounting.key === record.key,
        ) >=
          initialLossAccountingsIds.length - 1 ||
        (lossAccountings.length > 0 &&
          lossAccountings[initialLossAccountings.length - 1] &&
          record.physical_segment_ref! >=
            lossAccountings[initialLossAccountings.length - 1]
              .physical_segment_ref!)
      );
    },
    [lossAccountings, initialLossAccountings, initialLossAccountingsIds.length],
  );

  const allowRowRemove = (): boolean => {
    const isTagImplemented = composite_state !== ECompositeState.Implemented;
    const isViewModeAllowed =
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection ||
      viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate;

    return isTagImplemented && isViewModeAllowed;
  };

  return (
    <EditDataTable<IDetailLossAccounting>
      addButtonOffset={ADD_BUTTON_OFFSET}
      allowRowAdd={
        composite_state !== ECompositeState.Implemented &&
        (viewMode === EViewMode.EditETagAdjustment ||
          viewMode === EViewMode.EditETagAdjustmentWithATF ||
          viewMode === EViewMode.EditETagCorrection ||
          viewMode === EViewMode.EditETagDraft ||
          viewMode === EViewMode.EditETagTemplate)
          ? allowRowAdd
          : undefined
      }
      allowRowRemove={allowRowRemove}
      columns={lossMethodsColumns}
      data={filteredData}
      editButtonWidth={
        physicalSegmentId ? EDIT_BUTTON_WIDTH : BUTTON_ICON_DIMENSION
      }
      expandable={expandableConfig}
      hideExpandIconColumn={true}
      id={`lossmethods-${physicalSegmentId}`}
      initialiser={initialiser}
      isDisabled={isDisabled}
      indicatorContent={
        physicalSegmentId !== undefined && physicalSegmentId !== null ? (
          <Wrapper>{lossMethods}</Wrapper>
        ) : undefined
      }
      label={
        physicalSegmentId === null || physicalSegmentId === undefined
          ? 'Loss Methods'
          : undefined
      }
      maximumAllowableAdds={-1}
      maximumHeight={DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT}
      onAdd={
        viewMode === EViewMode.EditETagCorrection ||
        composite_state === ECompositeState.Implemented
          ? undefined
          : handleAddLossAccounting
      }
      onRemove={
        viewMode === EViewMode.EditETagCorrection ||
        composite_state === ECompositeState.Implemented
          ? undefined
          : handleRemoveLossAccounting
      }
      pagination={false}
      removeButtonOffset={REMOVE_BUTTON_OFFSET}
      rowKey='key'
      shouldFloatOverUpdate={shouldFloatOverUpdate}
      showDataCount={
        physicalSegmentId !== undefined && physicalSegmentId !== null
      }
      tableTitle={`Loss Methods Details${
        physicalSegmentId ? ' ' + physicalSegmentId : ''
      }`}
      tooltipTitle={lossMethods}
    />
  );
};

export default LossMethodsEdit;
