interface IDeniedIconProps {
  className?: string;
}

const DeniedIcon = ({ className }: IDeniedIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#c33333'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M324.904 750V250.245h132.4c11.48 0 23.726 0 35.97.766 11.48.765 23.726 2.296 35.205 3.826 11.48.766 22.195 3.062 32.91 5.358 10.714 1.53 20.663 4.591 29.847 7.653 26.786 8.418 49.746 19.898 70.41 34.44 20.663 13.775 37.5 31.377 51.276 50.51 13.776 19.899 24.49 42.093 31.378 66.583 6.888 23.725 10.714 50.512 10.714 78.828 0 26.787-3.06 52.042-9.183 75.767-5.358 23.725-14.542 45.154-26.787 64.287C706.8 657.396 691.493 675 672.36 689.54c-18.368 15.306-40.562 26.786-65.818 36.735-20.664 7.653-45.154 13.776-71.94 17.602-26.786 3.827-57.4 6.123-91.073 6.123H324.904zm129.34-76.532c53.572 0 95.664-7.653 125.512-22.194 26.786-13.01 46.685-32.144 60.46-57.4 13.776-25.255 20.664-57.399 20.664-96.43 0-20.664-2.296-39.031-6.888-55.103-4.592-16.837-10.714-31.378-19.133-44.389-8.418-12.245-19.133-23.725-30.613-32.908-12.245-9.184-26.02-16.838-41.327-22.96-13.776-5.357-29.848-9.184-47.45-11.48-17.602-3.061-36.735-3.827-58.93-3.827h-39.796v346.69h37.5z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default DeniedIcon;
