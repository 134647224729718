import Button from 'components/atoms/Button/Button';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import TimePicker from 'components/molecules/TimePicker/TimePicker';
import { COLUMN_LAYOUT_SHARED_STYLES } from 'constants/styles';
import { TIME_FORMAT } from 'constants/time';
import { ICustomInterval } from 'interfaces/Detail';
import { useCallback } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

const CUSTOM_INTERVAL_WIDTH = '69px';

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 2px;
  }
`;

const Values = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  width: ${CUSTOM_INTERVAL_WIDTH};

  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number {
    width: ${CUSTOM_INTERVAL_WIDTH};

    > .ant-input-number-input-wrap input {
      text-align: right;
    }
  }
`;

const AndButtonWrapper = styled.span`
  > button {
    font-size: 9px;
    padding: 0;
    width: 28px;
  }
`;

interface IProps {
  customInterval: ICustomInterval;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  isAddDisabled?: boolean;
  isDisabled?: boolean;
  onAdd: () => void;
  onChange: (customInterval: ICustomInterval) => void;
  timeZone: TTimeZone;
}

const CustomInterval = (props: IProps): JSX.Element => {
  const {
    customInterval,
    disabledHours,
    disabledMinutes,
    isAddDisabled,
    isDisabled,
    onAdd,
    onChange,
    timeZone,
  } = props;
  const hasEmptyValue: boolean = customInterval === null;

  const handleTimeChange = useCallback(
    (time: ZonedDateTime | null) => {
      onChange({
        ...customInterval,
        time,
      });
    },
    [customInterval, onChange],
  );

  const handleGenRequestChange = useCallback(
    (genRequest: number | undefined) => {
      onChange({
        ...customInterval,
        genRequest: genRequest === undefined ? null : genRequest,
      });
    },
    [customInterval, onChange],
  );

  return (
    <Layout>
      <Values>
        <TimePicker
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          format={TIME_FORMAT}
          isDisabled={isDisabled || timeZone === undefined}
          onChange={handleTimeChange}
          showNow={false}
          timeZone={timeZone}
          value={customInterval.time}
        />
        <StyledInputNumber
          hideArrows={true}
          min={0}
          onChange={handleGenRequestChange}
          placeholder='Gen Value'
          value={customInterval.genRequest}
        />
      </Values>
      {!isAddDisabled && hasEmptyValue ? null : (
        <AndButtonWrapper>
          <Button
            isDisabled={isDisabled || isAddDisabled || hasEmptyValue}
            label='and'
            onClick={onAdd}
          />
        </AndButtonWrapper>
      )}
    </Layout>
  );
};

export default CustomInterval;
