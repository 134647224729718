import { IIndexable } from 'interfaces/General';

export const getItemKey = <T extends IIndexable>(
  item: T,
  itemKey?: ((item: T) => string) | string,
): string =>
  itemKey === undefined
    ? item['key']
    : typeof itemKey === 'function'
    ? itemKey(item)
    : item[itemKey];

export const isAllSelected = <T>(
  data: T[],
  selectedKeys: string[],
  itemKey?: ((item: T) => string) | string,
  itemDisabled?: (item: T) => boolean,
): boolean =>
  data.length > 0 &&
  data
    .map(
      (item: T): boolean =>
        selectedKeys.includes(getItemKey(item, itemKey)) ||
        (itemDisabled !== undefined && itemDisabled(item)),
    )
    .reduce((previous: boolean, current: boolean) => previous && current, true);

export const getAllDataItemKeys = <T>(
  data: T[],
  itemKey?: ((item: T) => string) | string,
  itemDisabled?: (item: T) => boolean,
): string[] =>
  data
    .filter(
      (item: T): boolean => itemDisabled === undefined || !itemDisabled(item),
    )
    .map((item: T): string => getItemKey(item, itemKey));
