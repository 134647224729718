import { EditOutlined } from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import DataIndicator from 'components/atoms/DataIndicator/DataIndicator';
import IconButton, {
  IIconButtonProps,
} from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import EditableDataTable, {
  IEditableDataTableProps,
} from 'components/molecules/EditableDataTable/EditableDataTable';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  ERROR_BORDER,
  INPUT_HEIGHT,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import usePrevious from 'hooks/usePrevious';
import { IValueChanged } from 'interfaces/Component';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';
import { inputValueChanged } from 'utils/styles';

interface IStyledTooltipProps {
  width?: string;
}

const StyledTooltip = styled(Tooltip)<IStyledTooltipProps>`
  ${(props) =>
    props.width === undefined ? 'width: 100%;' : `width: ${props.width};`}
`;

const Wrapper = styled.div<IValueChanged>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${INPUT_HEIGHT};

  ${(props) => inputValueChanged(props)}
`;

const EditIcon = styled(EditOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IEditButtonProps extends IIconButtonProps {
  highlightButton?: boolean;
  width?: string;
}

const EditButton = styled(IconButton)<IEditButtonProps>`
  height: ${INPUT_HEIGHT};
  width: ${(props) => (props.width === undefined ? '100%' : props.width)};

  ${(props) => (props.highlightButton ? ERROR_BORDER : '')}
`;

const EditLabel = styled.div`
  white-space: nowrap;
`;

export interface IEditDataTableProps<T> extends IEditableDataTableProps<T> {
  editButtonWidth?: string;
  equalityChecker?: (a: T[], b: T[]) => boolean;
  highlightButton?: boolean;
  indicatorContent?: JSX.Element;
  id: string;
  initialData?: T[] | null;
  label?: string;
  maximumHeight?: string;
  shouldFloatOverUpdate?: (
    props: IEditDataTableProps<T>,
    previousProps: IEditDataTableProps<T> | undefined,
  ) => boolean;
  showDataCount?: boolean;
  tableTitle?: string;
  tooltipTitle?: string;
}

const EditDataTable = <T extends any>(
  props: IEditDataTableProps<T>,
): JSX.Element => {
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const {
    columns,
    data,
    editButtonWidth,
    equalityChecker,
    expandable,
    highlightButton,
    indicatorContent,
    id,
    initialData,
    label,
    maximumHeight,
    showDataCount,
    shouldFloatOverUpdate,
    tableTitle,
    tooltipTitle,
    ...rest
  } = props;
  const editButtonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const previousProps = usePrevious(props);
  const previousColumns = usePrevious(columns);
  const previousData = usePrevious(data);

  useEffect(() => {
    if (
      !isEmptyValue(floatOverContent) &&
      floatOverId === id &&
      ((shouldFloatOverUpdate === undefined &&
        (previousColumns !== columns || previousData !== data)) ||
        (shouldFloatOverUpdate && shouldFloatOverUpdate(props, previousProps)))
    ) {
      setFloatOverUseDragPanel(true);
      setFloatOverContent(
        <AntDesignCard key={id} title={tableTitle}>
          <EditableDataTable
            {...rest}
            columns={columns}
            data={data}
            expandable={expandable}
            key={id}
          />
        </AntDesignCard>,
      );
    }
  }, [
    columns,
    data,
    expandable,
    floatOverContent,
    floatOverId,
    id,
    previousColumns,
    previousData,
    previousProps,
    props,
    rest,
    setFloatOverContent,
    setFloatOverUseDragPanel,
    shouldFloatOverUpdate,
    tableTitle,
  ]);

  const dataChanged: boolean = useMemo(() => {
    if (initialData !== undefined) {
      if (equalityChecker === undefined) {
        throw new Error(
          'Missing dependent prop: equalityChecker due to inclusion of prop: initialData',
        );
      }

      return initialData === null
        ? data.length > 0
        : !equalityChecker(initialData, data);
    }

    return false;
  }, [equalityChecker, data, initialData]);

  const handleClick = () => {
    if (isEmptyValue(floatOverContent) || floatOverId !== id) {
      const { height, width, x, y } =
        editButtonRef.current.getBoundingClientRect();

      setFloatOverId(id);
      setFloatOverDefaultPosition({
        placement: EFloatOverPlacement.Right,
        x: x + width,
        y: y + height + STANDARD_SPACING_VALUE,
      });
      setFloatOverMaximumHeight(maximumHeight);
      setFloatOverUseDragPanel(true);
      setFloatOverContent(
        <AntDesignCard key={id} title={tableTitle}>
          <EditableDataTable
            {...rest}
            columns={columns}
            data={data}
            expandable={expandable}
            key={id}
          />
        </AntDesignCard>,
      );
    } else if (floatOverId === id) {
      setFloatOverContent(null);
    }
  };

  const showingIcon: boolean = !showDataCount;

  const shouldTitleTextBeWrapped: boolean = useMemo(() => {
    return !!tooltipTitle && tooltipTitle.split(',').length > 11;
  }, [tooltipTitle]);

  return (
    <SeparatedRowLayout
      centered={true}
      marginRight={STANDARD_SPACING_VALUE / 2}
    >
      {isEmptyValue(label) ? null : <EditLabel>{label}</EditLabel>}
      <StyledTooltip
        title={tooltipTitle}
        width={editButtonWidth}
        wrapTextTitle={shouldTitleTextBeWrapped}
      >
        <EditButton
          buttonRef={editButtonRef}
          highlightButton={highlightButton}
          icon={
            indicatorContent ? (
              <Wrapper valueChanged={dataChanged}>{indicatorContent}</Wrapper>
            ) : showDataCount ? (
              <DataIndicator value={data.length} valueChanged={dataChanged} />
            ) : (
              <EditIcon />
            )
          }
          isContained={showingIcon}
          noBorder={showingIcon}
          onClick={handleClick}
          transparentBackground={showingIcon}
          width={editButtonWidth}
        />
      </StyledTooltip>
    </SeparatedRowLayout>
  );
};

export default EditDataTable;
