import { IContainerSelectable } from 'interfaces/Component';
import styled from 'styled-components';
import {
  getHighlightedStyle,
  getSelectedStyle,
  inputValueChanged,
} from 'utils/styles';

const DateTimeContainer = styled.div<IContainerSelectable>`
  height: 100%;
  padding: 0 7px;
  text-align: left;
  width: 100%;

  ${(props) => inputValueChanged(props)}

  ${(props) => getHighlightedStyle(props)}

  ${(props) => getSelectedStyle(props)}
`;

export default DateTimeContainer;
