import { Wrapper } from 'components/molecules/DateTimePicker/DateTimePicker';
import ZonedDateTimeRangePicker from 'components/molecules/DateTimeRangePicker/ZonedDateTimeRangePicker';
import { IShowTime } from 'interfaces/Component';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { TDisabledDateHandler } from 'types/General';

export interface IDateTimeRangePickerProps {
  allowClear?: boolean;
  className?: string;
  disabledDate?: TDisabledDateHandler;
  format?: string;
  isDisabled?: boolean;
  onChange?: (dateTimeRange: TZonedDateTimeRange) => void;
  placeholder?: [string, string];
  showTime?: IShowTime;
  shouldExpand?: boolean;
  suffixIcon?: JSX.Element | null;
  timeZone: TTimeZone;
  value?: TZonedDateTimeRange;
}

const DateTimeRangePicker = ({
  allowClear,
  className,
  disabledDate,
  format,
  isDisabled,
  onChange,
  placeholder,
  showTime,
  shouldExpand,
  suffixIcon,
  timeZone,
  value,
}: IDateTimeRangePickerProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();

  return (
    <Wrapper
      className={className}
      currentTheme={currentTheme!}
      shouldExpand={shouldExpand}
    >
      <ZonedDateTimeRangePicker
        allowClear={allowClear}
        disabledDate={disabledDate}
        format={format}
        isDisabled={isDisabled}
        onChange={onChange}
        placeholder={placeholder}
        showTime={showTime}
        suffixIcon={suffixIcon}
        timeZone={timeZone}
        value={value ?? null}
      />
    </Wrapper>
  );
};

export default DateTimeRangePicker;
