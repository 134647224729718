import Button from 'components/atoms/Button/Button';
import Modal from 'components/molecules/Modal/Modal';
import { IPasteData } from 'components/organisms/DataGrid/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface IPasteModalProps {
  onCancel: () => void;
  pasteData?: IPasteData;
}

const PasteModal = ({ onCancel, pasteData }: IPasteModalProps): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsModalVisible(pasteData !== undefined);
  }, [pasteData]);

  const handleCancel = useCallback(() => {
    onCancel();

    setIsModalVisible(false);
  }, [onCancel]);

  const handleAddAndPaste = useCallback(() => {
    pasteData?.commitPaste(true);
  }, [pasteData]);

  const handlePasteOnly = useCallback(() => {
    pasteData?.commitPaste();
  }, [pasteData]);

  const footer = useMemo(
    () => (
      <>
        <Button label='Cancel' onClick={handleCancel} />
        {pasteData?.hasTooManyRows ? (
          <Button label='Add New Rows and Paste' onClick={handleAddAndPaste} />
        ) : null}
        <Button
          label='Paste Into Available Cells Only'
          onClick={handlePasteOnly}
        />
      </>
    ),
    [handleAddAndPaste, handleCancel, handlePasteOnly, pasteData],
  );

  return (
    <Modal
      closable={false}
      footer={footer}
      isVisible={isModalVisible}
      title='How do you want to continue with pasting?'
    >
      The content you are pasting does not fit inside the available data grid
      area. If you continue some content will NOT be pasted.
    </Modal>
  );
};

export default PasteModal;
