import ApprovedIcon from 'components/atoms/ApprovedIcon/ApprovedIcon';
import DeniedIcon from 'components/atoms/DeniedIcon/DeniedIcon';
import ExpiredIcon from 'components/atoms/ExpiredIcon/ExpiredIcon';
import PendingIcon from 'components/atoms/PendingIcon/PendingIcon';
import WithdrawnIcon from 'components/atoms/WithdrawnIcon/WithdrawnIcon';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ICON_FONT_SIZE } from 'constants/styles';
import { ERequestStatus } from 'enums/ETag';
import styled from 'styled-components';

const Container = styled.div`
  height: 20px;
  width: 20px;
`;

const Approved = styled(ApprovedIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Denied = styled(DeniedIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Expired = styled(ExpiredIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Pending = styled(PendingIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

const Withdrawn = styled(WithdrawnIcon)`
  font-size: ${ICON_FONT_SIZE};
`;

interface IRequestStatusIconProps {
  hideTooltip?: boolean;
  requestStatus: ERequestStatus | null;
}

const RequestStatusIcon = ({
  hideTooltip,
  requestStatus,
}: IRequestStatusIconProps): JSX.Element => {
  let icon: JSX.Element | null = null;

  switch (requestStatus) {
    case ERequestStatus.Approved: {
      icon = <Approved />;
      break;
    }
    case ERequestStatus.Denied: {
      icon = <Denied />;
      break;
    }
    case ERequestStatus.Expired: {
      icon = <Expired />;
      break;
    }
    case ERequestStatus.Pending: {
      icon = <Pending />;
      break;
    }
    case ERequestStatus.Withdrawn: {
      icon = <Withdrawn />;
      break;
    }
    default: {
      return <></>;
    }
  }

  return (
    <Container>
      {hideTooltip === true ? (
        icon
      ) : (
        <Tooltip title={requestStatus}>{icon}</Tooltip>
      )}
    </Container>
  );
};

export default RequestStatusIcon;
