import { Form, FormInstance } from 'antd';
import { createContext, FC } from 'react';

export interface EditableRowProps {
  index: number;
}

export const ReasonsEditableContext = createContext<FormInstance<any> | null>(
  null,
);

export const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <ReasonsEditableContext.Provider value={form}>
        <tr {...props} />
      </ReasonsEditableContext.Provider>
    </Form>
  );
};
