import { useEffect, useState } from 'react';

const useIsDocumentVisible = (): boolean => {
  const [isDocumentVisible, setIsDocumentVisible] = useState<boolean>(
    document.visibilityState === 'visible',
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsDocumentVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isDocumentVisible;
};

export default useIsDocumentVisible;
