import { Spin as AntDesignSpin } from 'antd';
import { STANDARD_SPACING } from 'constants/styles';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

interface ISpinnerProps {
  className?: string;
  message?: string;
}

const Spinner = ({ className, message }: ISpinnerProps): JSX.Element => {
  return (
    <Container className={className}>
      {message === undefined ? null : <div>{message}</div>}
      <AntDesignSpin />
    </Container>
  );
};

export default Spinner;
