import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import TransmissionCapacityAvailabilityExportPanel from 'components/molecules/TransmissionCapacityAvailabilityCsvExport/TransmissionCapacityAvailabilityCsvExportPanel';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import {
  ITransmissionAvailabilityColumnDataAgGrid,
  ITransmissionAvailabilityDataSet,
} from 'interfaces/ETag';
import { useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TPlacement } from 'types/General';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ExportCsvIcon = styled(DeliveredProcedureOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface ITransmissionCapacityAvailabilityCsvExportProps {
  allOptionalColumns: string[];
  isDisabled?: boolean;
  panelPlacement?: TPlacement;
  selectedEndDate: ZonedDateTime | null;
  selectedOptionalColumns: string[] | undefined;
  selectedStartDate: ZonedDateTime | null;
  showExtraHourColumn: boolean;
  timeZone: TTimeZone;
  toEntityUiName: string;
  tooltipPlacement?: TPlacement;
  transmissionAvailabilityColumnDatas: ITransmissionAvailabilityColumnDataAgGrid[];
  transmissionAvailabilityDataSets: ITransmissionAvailabilityDataSet[];
}

const TransmissionCapacityAvailabilityCsvExport = (
  props: ITransmissionCapacityAvailabilityCsvExportProps,
): JSX.Element => {
  const {
    allOptionalColumns,
    isDisabled,
    panelPlacement,
    selectedEndDate,
    selectedOptionalColumns,
    selectedStartDate,
    showExtraHourColumn,
    timeZone,
    toEntityUiName,
    tooltipPlacement,
    transmissionAvailabilityColumnDatas,
    transmissionAvailabilityDataSets,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };
  const handleSuccessfulExport = () => {
    setIsVisible(false);
  };

  const adjustedIsDisabled: boolean =
    isDisabled || transmissionAvailabilityDataSets.length === 0;

  return (
    <Tooltip
      isDisabled={adjustedIsDisabled || isVisible}
      placement={tooltipPlacement}
      title='Export Table to CSV'
    >
      <AntDesignPopover
        content={
          <TransmissionCapacityAvailabilityExportPanel
            allOptionalColumns={allOptionalColumns}
            transmissionAvailabilityColumnDatas={
              transmissionAvailabilityColumnDatas
            }
            transmissionAvailabilityDataSets={transmissionAvailabilityDataSets}
            onSuccessfulExport={handleSuccessfulExport}
            selectedEndDate={selectedEndDate}
            selectedStartDate={selectedStartDate}
            timeZone={timeZone}
            toEntityUiName={toEntityUiName}
            selectedOptionalColumns={selectedOptionalColumns}
            showExtraHourColumn={showExtraHourColumn}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={handleVisibleChange}
        placement={panelPlacement}
        trigger='click'
        visible={isVisible}
      >
        <IconButton icon={<ExportCsvIcon />} isDisabled={adjustedIsDisabled} />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default TransmissionCapacityAvailabilityCsvExport;
