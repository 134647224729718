import {
  IETagData,
  IETagIdentifier,
  IETagSummaryAttributeRecord,
  IETagSummaryProfilesRecord,
} from 'interfaces/ETag';
import { IToEntity, IToEntityRecord } from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import {
  TETagDraftId,
  TETagRecordKey,
  TETagSummaryProfilesMap,
  TETagTagPrimaryKey,
} from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export enum ESummaryAction {
  RetrieveToEntityETagSummaryAttributeStart = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE_START',
  RetrieveToEntityETagSummaryAttributeSuccess = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE_SUCCESS',
  RetrieveToEntityETagSummaryAttributeFailure = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE_FAILURE',
  RemoveToEntityETagSummaryAttribute = 'REMOVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE',
  RetrieveToEntityETagsSummaryAttributeStart = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_ATTRIBUTE_START',
  RetrieveToEntityETagsSummaryAttributeSuccess = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_ATTRIBUTE_SUCCESS',
  RetrieveToEntityETagsSummaryAttributeFailure = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_ATTRIBUTE_FAILURE',
  RetrieveToEntityETagsSummaryDealLinkageStart = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_DEAL_LINKAGE_START',
  RetrieveToEntityETagsSummaryDealLinkageSuccess = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_DEAL_LINKAGE_SUCCESS',
  RetrieveToEntityETagsSummaryDealLinkageFailure = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_DEAL_LINKAGE_FAILURE',
  RetrieveToEntityETagSummaryProfilesStart = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_START',
  RetrieveToEntityETagSummaryProfilesSuccess = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_SUCCESS',
  RetrieveToEntityETagSummaryProfilesFailure = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_FAILURE',
  RetrieveToEntityETagSummaryProfilesBatchStart = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_BATCH_START',
  RetrieveToEntityETagSummaryProfilesBatchSuccess = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_BATCH_SUCCESS',
  RetrieveToEntityETagSummaryProfilesBatchFailure = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_BATCH_FAILURE',
  RetrieveToEntityETagsSummaryProfilesStart = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_PROFILES_START',
  RetrieveToEntityETagsSummaryProfilesSuccess = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_PROFILES_SUCCESS',
  RetrieveToEntityETagsSummaryProfilesFailure = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_PROFILES_FAILURE',
  RemoveToEntityETagSummaryProfiles = 'REMOVE_TO_ENTITY_ETAG_SUMMARY_PROFILES',
  UpdateToEntityETagDrafts = 'UPDATE_TO_ENTITY_ETAG_DRAFTS',
  UpdateToEntityETags = 'UPDATE_TO_ENTITY_ETAGS',
  UpdateToEntityPermissionExclusions = 'UPDATE_TO_ENTITY_PERMISSION_EXCLUSIONS',
}

export interface ISummaryState {
  toEntities: Record<TToEntityId, IToEntityRecord>;
}

export interface ISummaryActionPayload {
  toEntity: IToEntity;
}

// ETag Attribute

interface IETagSummaryAttributeRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

export type TETagSummaryAttributeRequest = IETagIdentifier &
  IETagSummaryAttributeRequest;

interface IRetrieveToEntityETagSummaryAttributeStartAction {
  payload: TETagSummaryAttributeRequest;
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeStart;
}

export interface IETagSummaryAttributeReply extends ISummaryActionPayload {
  recordKey: TETagRecordKey;
  summaryAttributeRecord: IETagSummaryAttributeRecord;
  timeZone: TTimeZone;
}

interface IRetrieveToEntityETagSummaryAttributeSuccessAction {
  payload: IETagSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeSuccess;
}

interface IRetrieveToEntityETagSummaryAttributeFailureAction {
  payload: IETagSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeFailure;
}

interface IRemoveToEntityETagSummaryAttributeAction {
  payload: TETagSummaryAttributeRequest;
  type: ESummaryAction.RemoveToEntityETagSummaryAttribute;
}

// ETags Attribute

export interface IETagsSummaryAttributeRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

interface IRetrieveToEntityETagsSummaryAttributeStartAction {
  payload: IETagsSummaryAttributeRequest;
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeStart;
}

export interface IRetrieveToEntityETagsSummaryAttributeReply
  extends ISummaryActionPayload {
  toEntityRecord: IToEntityRecord;
}

interface IRetrieveToEntityETagsSummaryAttributeSuccessAction {
  payload: IRetrieveToEntityETagsSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeSuccess;
}

interface IRetrieveToEntityETagsSummaryAttributeFailureAction {
  payload: IRetrieveToEntityETagsSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeFailure;
}

// ETags Deal Linkage

export interface IETagsSummaryDealLinkageRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

interface IRetrieveToEntityETagsSummaryDealLinkageStartAction {
  payload: IETagsSummaryDealLinkageRequest;
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageStart;
}

export interface IRetrieveToEntityETagsSummaryDealLinkageReply
  extends ISummaryActionPayload {
  toEntityRecord: IToEntityRecord;
  isCompleted?: true;
}

interface IRetrieveToEntityETagsSummaryDealLinkageSuccessAction {
  payload: IRetrieveToEntityETagsSummaryDealLinkageReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageSuccess;
}

interface IRetrieveToEntityETagsSummaryDealLinkageFailureAction {
  payload: IRetrieveToEntityETagsSummaryDealLinkageReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageFailure;
}
// ETag Profiles

interface IETagSummaryProfilesRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

export type TETagSummaryProfilesRequest = IETagIdentifier &
  IETagSummaryProfilesRequest;

interface IRetrieveToEntityETagSummaryProfilesStartAction {
  payload: TETagSummaryProfilesRequest;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesStart;
}

export interface IETagSummaryProfilesReply extends ISummaryActionPayload {
  recordKey: TETagRecordKey;
  summaryProfileRecord: IETagSummaryProfilesRecord;
}

interface IRetrieveToEntityETagSummaryProfilesSuccessAction {
  payload: IETagSummaryProfilesReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesSuccess;
}

interface IRetrieveToEntityETagSummaryProfilesFailureAction {
  payload: IETagSummaryProfilesReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesFailure;
}

interface IRemoveToEntityETagSummaryProfilesAction {
  payload: TETagSummaryProfilesRequest;
  type: ESummaryAction.RemoveToEntityETagSummaryProfiles;
}

// ETag Profiles Batch

export interface IETagSummaryProfilesBatchRequest
  extends ISummaryActionPayload {
  eTagIdentifiers: IETagIdentifier[];
  requestedAt: ZonedDateTime;
}

interface IRetrieveToEntityETagSummaryProfilesBatchStartAction {
  payload: IETagSummaryProfilesBatchRequest;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchStart;
}

export interface IETagSummaryProfilesBatchReply extends ISummaryActionPayload {
  error?: TErrorMessage;
  eTagSummaryProfilesMap: TETagSummaryProfilesMap;
}

interface IRetrieveToEntityETagSummaryProfilesBatchSuccessAction {
  payload: IETagSummaryProfilesBatchReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchSuccess;
}

interface IRetrieveToEntityETagSummaryProfilesBatchFailureAction {
  payload: IETagSummaryProfilesBatchReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchFailure;
}

// ETags Profiles

export interface IETagsSummaryProfilesRequest extends ISummaryActionPayload {
  loadCount: number;
}

interface IRetrieveToEntityETagsSummaryProfilesStartAction {
  payload: IETagsSummaryProfilesRequest;
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesStart;
}

export interface IETagsSummaryProfilesReply extends ISummaryActionPayload {
  isCompleted?: true;
}

export interface IETagsSummaryDealLinkageReply extends ISummaryActionPayload {
  isCompleted?: true;
}

interface IRetrieveToEntityETagsSummaryProfilesSuccessAction {
  payload: IETagsSummaryProfilesReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesSuccess;
}

export interface IRetrieveToEntityETagsSummaryProfilesError
  extends ISummaryActionPayload {
  error: string;
}

interface IRetrieveToEntityETagsSummaryProfilesFailureAction {
  payload: IRetrieveToEntityETagsSummaryProfilesError;
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesFailure;
}

// ETag

export interface IUpdateToEntityETagDraftsRequest
  extends ISummaryActionPayload {
  draft_id: TETagDraftId;
  tag_primary_key: TETagTagPrimaryKey;
}

interface IUpdateToEntityETagDrafts {
  payload: IUpdateToEntityETagDraftsRequest;
  type: ESummaryAction.UpdateToEntityETagDrafts;
}

export interface IUpdateToEntityETagsRequest extends ISummaryActionPayload {
  eTagsData: IETagData[];
  timeZone: TTimeZone;
}

interface IUpdateToEntityETags {
  payload: IUpdateToEntityETagsRequest;
  type: ESummaryAction.UpdateToEntityETags;
}

// Actions

export type TSummaryAction =
  | IRetrieveToEntityETagSummaryAttributeStartAction
  | IRetrieveToEntityETagSummaryAttributeSuccessAction
  | IRetrieveToEntityETagSummaryAttributeFailureAction
  | IRemoveToEntityETagSummaryAttributeAction
  | IRetrieveToEntityETagsSummaryAttributeStartAction
  | IRetrieveToEntityETagsSummaryAttributeSuccessAction
  | IRetrieveToEntityETagsSummaryAttributeFailureAction
  | IRetrieveToEntityETagsSummaryDealLinkageStartAction
  | IRetrieveToEntityETagsSummaryDealLinkageSuccessAction
  | IRetrieveToEntityETagsSummaryDealLinkageFailureAction
  | IRetrieveToEntityETagSummaryProfilesStartAction
  | IRetrieveToEntityETagSummaryProfilesSuccessAction
  | IRetrieveToEntityETagSummaryProfilesFailureAction
  | IRemoveToEntityETagSummaryProfilesAction
  | IRetrieveToEntityETagSummaryProfilesBatchStartAction
  | IRetrieveToEntityETagSummaryProfilesBatchSuccessAction
  | IRetrieveToEntityETagSummaryProfilesBatchFailureAction
  | IRetrieveToEntityETagsSummaryProfilesStartAction
  | IRetrieveToEntityETagsSummaryProfilesSuccessAction
  | IRetrieveToEntityETagsSummaryProfilesFailureAction
  | IUpdateToEntityETagDrafts
  | IUpdateToEntityETags;
