import { SpritePointerTypeEvent } from '@amcharts/amcharts4/.internal/core/SpriteEvents';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { CloseCircleOutlined } from '@ant-design/icons';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  CURRENT_LEVEL_COLOUR,
  CURRENT_LEVEL_WITH_RELIABILITY_LIMIT_COLOUR,
  CURRENT_LEVEL_WITH_RELOAD_COLOUR,
  PROFILE_SEGMENT_GEN_OPTION,
  PROFILE_SEGMENT_LOAD_OPTION,
  RANGE_HIGHLIGHT_BLUE_COLOR,
  STATE_BLUE_COLOR,
  STATE_GREEN_COLOR,
  STATE_GREY_COLOR,
  STATE_ORANGE_COLOR,
  STATE_RED_COLOR,
  TEXT_DARK_COLOR,
  USER_TIME_COLOR,
  WHITE_COLOR,
} from 'components/organisms/ProfileGraphView/constants';
import {
  IChartData,
  IProfileSegment,
} from 'components/organisms/ProfileGraphView/types';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import { EProfileSegment, ERequestType } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IETagPhysicalSegmentProfile,
  IETagPhysicalSegmentsProfile,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegmentProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { ICurtailment, IEnergyProfile } from 'interfaces/General';
import { IPointInfo } from 'interfaces/Point';
import { MutableRefObject } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import {
  getProfileMwForTransAllocId,
  transmissionAllocationSorter,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const DESIRED_CURRENT_TIME_LINE_UPDATE_INTERVAL_IN_MS = 60000;
const MARKET_LEVEL_KEY_SUFFIX = 'marketLevel';
const RELIABILITY_LEVEL_KEY_SUFFIX = 'reliabilityLevel';
const CURTAILMENTS_KEY_SUFFIX = 'curtailmentsLevel';
const RELOADERS_KEY_SUFFIX = 'reloadersLevel';
const MOST_LIMITING_KEY_SUFFIX = 'mostLimiting';
const TOOLTIP_Y_OFFSET_VALUE = 31;

const CloseTooltipIcon = styled(CloseCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

export const am4themes_dark = (target: any) => {
  if (target instanceof am4core.InterfaceColorSet) {
    target.setFor('alternativeBackground', WHITE_COLOR);
    target.setFor('grid', TEXT_DARK_COLOR);
    target.setFor('text', TEXT_DARK_COLOR);
  }
};

const getMinSumForTransmissionAllocations = (
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  transmission: IETagTransmissionPhysicalSegmentProfile | null,
) => {
  let currentSum: number | null = null;
  let minValue: number = Number.MAX_SAFE_INTEGER;
  let mostLimiting: string = '';

  if (transmissionAllocations !== null) {
    [...transmissionAllocations]
      .sort((a: IETagTransmissionAllocation, b: IETagTransmissionAllocation) =>
        transmissionAllocationSorter(a, b),
      ) // the following forEach relies on the transmission allocations being sorted by physical segment id
      .forEach(
        (
          eTagTransmissionAllocation: IETagTransmissionAllocation,
          index: number,
        ) => {
          const { physical_segment_ref } = eTagTransmissionAllocation;
          const profileMw: number | null = getProfileMwForTransAllocId(
            transmission,
            eTagTransmissionAllocation.trans_alloc_id,
          );
          const nextETagTransmissionAllocation:
            | IETagTransmissionAllocation
            | undefined = transmissionAllocations[index + 1];

          if (profileMw !== null) {
            if (currentSum === null) {
              currentSum = 0;
            }

            currentSum += profileMw;
          }

          if (
            physical_segment_ref !==
            nextETagTransmissionAllocation?.physical_segment_ref
          ) {
            if (currentSum !== null && currentSum < minValue) {
              minValue = currentSum;
              mostLimiting = `T${physical_segment_ref}`;
            }

            currentSum = null;
          }
        },
      );
  }

  return {
    mostLimiting,
    transmissionLimit:
      minValue === Number.MAX_SAFE_INTEGER ? undefined : minValue,
  };
};

export const getProfileDateOptions = (
  start: ZonedDateTime | undefined,
  stop: ZonedDateTime | undefined,
): IOption<ZonedDateTime>[] => {
  const profileDateOptions: IOption<ZonedDateTime>[] = [];

  if (start !== undefined && stop !== undefined) {
    let day: ZonedDateTime = start.startOf('day');

    while (day.isBefore(stop)) {
      profileDateOptions.push({
        label: day.format(DATE_FORMAT),
        value: day,
      });

      day = day.add(1, 'day');
    }
  }

  return profileDateOptions;
};

export const getCurrentLevelSeriesKey = (
  index: number,
  suffix?: string,
): string => `currentLevel${isEmptyValue(suffix) ? '' : `_${suffix}`}_${index}`;

export const getTransmissionLimitSeriesKey = (
  index: number,
  suffix?: string,
): string =>
  `transmissionLimit_${isEmptyValue(suffix) ? '' : `_${suffix}`}_${index}`;

const transformCurtailments = (curtailments: ICurtailment[]): string =>
  curtailments
    .map(
      (curtailment: ICurtailment): string =>
        `${curtailment.entity.entity_code}: ${curtailment.mw}`,
    )
    .join('\n');

const transformReloaders = (reloaders: IEntityInfo[]): string =>
  reloaders
    .map((entityInfo: IEntityInfo): string => entityInfo.entity_code)
    .join(', ');

export const getChartData = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  selectedProfileDate: ZonedDateTime | undefined,
  selectedProfileSegment: IProfileSegment | undefined,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  timeZone: TTimeZone,
) => {
  const chartData: IChartData[] = [];
  let chartStart: ZonedDateTime | undefined = undefined;
  let chartStop: ZonedDateTime | undefined = undefined;
  let currentLevelSeriesIndex: number = -1;
  let transmissionLimitSeriesIndex: number = -1;
  let valueMax: number = -1;

  if (selectedProfileDate !== undefined) {
    chartStart = selectedProfileDate.subtract(1, 'hours');
    chartStop = chartStart.add(26, 'hours');

    if (selectedProfileSegment !== undefined) {
      let previousStopWithCurrentLevel: number = 0;
      let previousStopWithTransmissionLimit: number = 0;

      if (physicalSegmentsProfiles !== null) {
        physicalSegmentsProfiles.forEach(
          (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile) => {
            const { physical_segments_profiles, start, stop } =
              eTagPhysicalSegmentsProfile;
            const generation: IETagPhysicalSegmentProfile | null =
              physical_segments_profiles === null
                ? null
                : physical_segments_profiles.generation;
            const load: IETagPhysicalSegmentProfile | null =
              physical_segments_profiles === null
                ? null
                : physical_segments_profiles.load;
            const transmission: IETagTransmissionPhysicalSegmentProfile | null =
              physical_segments_profiles === null
                ? null
                : physical_segments_profiles.transmission;

            if (start !== null && stop !== null) {
              const startMoment: ZonedDateTime = ZonedDateTime.parseIso(
                start,
                timeZone,
              );
              const stopMoment: ZonedDateTime = ZonedDateTime.parseIso(
                stop,
                timeZone,
              );

              if (
                chartStart !== undefined &&
                chartStop !== undefined &&
                stopMoment.isSameOrAfter(chartStart) &&
                startMoment.isSameOrBefore(chartStop)
              ) {
                const adjustedStart: number = (
                  startMoment.isBefore(chartStart) ? chartStart! : startMoment
                ).epochMillis();
                const adjustedStop: number = (
                  stopMoment.isAfter(chartStop) ? chartStop! : stopMoment
                ).epochMillis();

                let energyProfile: IEnergyProfile | undefined = undefined;

                if (
                  selectedProfileSegment.profileSegment === EProfileSegment.Gen
                ) {
                  energyProfile =
                    generation === null || generation.profile === null
                      ? undefined
                      : generation.profile;
                } else if (
                  selectedProfileSegment.profileSegment === EProfileSegment.Load
                ) {
                  energyProfile =
                    load === null || load.profile === null
                      ? undefined
                      : load.profile;
                } else if (
                  selectedProfileSegment.profileSegment === EProfileSegment.Pod
                ) {
                  const eTagTransmissionSegment:
                    | IETagTransmissionSegment
                    | undefined = transmission?.transmission_segments?.find(
                    (
                      eTagTransmissionSegment: IETagTransmissionSegment,
                    ): boolean =>
                      eTagTransmissionSegment.physical_segment_id ===
                      selectedProfileSegment.physicalSegmentRef,
                  );

                  energyProfile =
                    eTagTransmissionSegment === undefined ||
                    eTagTransmissionSegment.pod_energy_profile === null
                      ? undefined
                      : eTagTransmissionSegment.pod_energy_profile;
                }

                const currentLevel: number | undefined =
                  energyProfile === undefined || energyProfile.mw === null
                    ? undefined
                    : energyProfile.mw;
                const colour: string | undefined =
                  energyProfile?.reliability_limit !== null
                    ? CURRENT_LEVEL_WITH_RELIABILITY_LIMIT_COLOUR
                    : energyProfile.reloaders !== null &&
                      energyProfile.reloaders.length > 0
                    ? CURRENT_LEVEL_WITH_RELOAD_COLOUR
                    : CURRENT_LEVEL_COLOUR;
                const { mostLimiting, transmissionLimit } =
                  getMinSumForTransmissionAllocations(
                    transmissionAllocations,
                    transmission,
                  );

                if (previousStopWithCurrentLevel !== adjustedStart) {
                  currentLevelSeriesIndex += 1;
                }

                if (previousStopWithTransmissionLimit !== adjustedStart) {
                  transmissionLimitSeriesIndex += 1;
                }

                if (
                  currentLevel !== undefined ||
                  transmissionLimit !== undefined
                ) {
                  const currentLevelKey: string = getCurrentLevelSeriesKey(
                    currentLevelSeriesIndex,
                  );
                  const marketLevelKey: string = getCurrentLevelSeriesKey(
                    currentLevelSeriesIndex,
                    MARKET_LEVEL_KEY_SUFFIX,
                  );
                  const reliabilityLevelKey: string = getCurrentLevelSeriesKey(
                    currentLevelSeriesIndex,
                    RELIABILITY_LEVEL_KEY_SUFFIX,
                  );
                  const curtailmentsKey: string = getCurrentLevelSeriesKey(
                    currentLevelSeriesIndex,
                    CURTAILMENTS_KEY_SUFFIX,
                  );
                  const reloadersKey: string = getCurrentLevelSeriesKey(
                    currentLevelSeriesIndex,
                    RELOADERS_KEY_SUFFIX,
                  );
                  const transmissionLimitKey: string =
                    getTransmissionLimitSeriesKey(transmissionLimitSeriesIndex);
                  const mostLimitingKey: string = getTransmissionLimitSeriesKey(
                    transmissionLimitSeriesIndex,
                    MOST_LIMITING_KEY_SUFFIX,
                  );

                  const marketLevel: number | undefined =
                    energyProfile !== undefined &&
                    energyProfile.market_level !== null
                      ? energyProfile.market_level
                      : undefined;

                  const reliabilityLevel: number | undefined =
                    energyProfile !== undefined &&
                    energyProfile.reliability_limit !== null
                      ? energyProfile.reliability_limit
                      : undefined;

                  const chartDataPoint = {
                    bulletLocationX: 0,
                    bulletColour: colour,
                    dateTime: adjustedStart,
                    lineColour: colour,
                    start: startMoment.format(DATE_TIME_FORMAT),
                    stop: stopMoment.format(DATE_TIME_FORMAT),
                    [curtailmentsKey]:
                      energyProfile !== undefined &&
                      energyProfile.curtailments.length > 0
                        ? transformCurtailments(energyProfile.curtailments)
                        : undefined,
                    [currentLevelKey]: currentLevel,
                    [marketLevelKey]: marketLevel,
                    [mostLimitingKey]: mostLimiting,
                    [reliabilityLevelKey]: reliabilityLevel,
                    [reloadersKey]:
                      energyProfile !== undefined &&
                      energyProfile.reloaders.length > 0
                        ? transformReloaders(energyProfile.reloaders)
                        : undefined,
                    [transmissionLimitKey]: transmissionLimit,
                  };

                  const previousChartDataPoint: IChartData | undefined =
                    chartData[chartData.length - 1];

                  // Use the current colour on the previous data point stroke.
                  // The previous data point will always be the end segment of the
                  // previous start/stop line segment as mentioned below.
                  if (previousChartDataPoint !== undefined) {
                    previousChartDataPoint.lineColour = colour;
                  }

                  // Add our new start data point
                  chartData.push(chartDataPoint);

                  // In order to display a bullet for the stop date time, we use
                  // an adjustment of 1 second so that it doesn't overlap with
                  // the next start date time and thus allow tooltips to be able
                  // to distinguish between start and stop date times for each
                  // data point. We also shift the bullet location to the end of
                  // the line segment so that it renders at the correct position
                  // for the stop date time.
                  chartData.push({
                    ...chartDataPoint,
                    bulletLocationX: 1,
                    dateTime: adjustedStop - 1000,
                  });

                  if (currentLevel !== undefined && currentLevel > valueMax) {
                    valueMax = currentLevel;
                  }

                  if (marketLevel !== undefined && marketLevel > valueMax) {
                    valueMax = marketLevel;
                  }

                  if (
                    reliabilityLevel !== undefined &&
                    reliabilityLevel > valueMax
                  ) {
                    valueMax = reliabilityLevel;
                  }

                  if (
                    transmissionLimit !== undefined &&
                    transmissionLimit > valueMax
                  ) {
                    valueMax = transmissionLimit;
                  }
                }

                if (currentLevel !== undefined) {
                  previousStopWithCurrentLevel = adjustedStop;
                }

                if (transmissionLimit !== undefined) {
                  previousStopWithTransmissionLimit = adjustedStop;
                }
              }
            }
          },
        );
      }
    }
  }

  return {
    chartData,
    chartStart,
    chartStop,
    numberOfCurrentLevelSeries: currentLevelSeriesIndex + 1,
    numberOfTransmissionLimitSeries: transmissionLimitSeriesIndex + 1,
    valueMax,
  };
};

export const updateCurrentTimeIndicator = (
  dayStart: ZonedDateTime | undefined,
  dayStop: ZonedDateTime | undefined,
  eTagStart: ZonedDateTime | undefined,
  eTagStop: ZonedDateTime | undefined,
  chart: am4charts.XYChart,
  currentTimeLine: am4core.Line | undefined,
  dateAxis: am4charts.DateAxis,
  valueAxis: am4charts.ValueAxis,
  setSelectedPlotTime: (selectedPlotTime: Date) => void,
  userSelectedTimeRef: MutableRefObject<Date | undefined>,
  currentTimeIndicatorTimeoutRef: MutableRefObject<number | undefined>,
  timeZone: TTimeZone,
) => {
  if (currentTimeIndicatorTimeoutRef.current) {
    clearTimeout(currentTimeIndicatorTimeoutRef.current);

    currentTimeIndicatorTimeoutRef.current = undefined;
  }

  if (
    currentTimeLine !== undefined &&
    dayStart !== undefined &&
    dayStop !== undefined &&
    eTagStart !== undefined &&
    eTagStop !== undefined
  ) {
    let currentTime: ZonedDateTime = ZonedDateTime.now(timeZone);
    const updateIntervalInMS: number =
      DESIRED_CURRENT_TIME_LINE_UPDATE_INTERVAL_IN_MS -
      (currentTime.epochMillis() %
        DESIRED_CURRENT_TIME_LINE_UPDATE_INTERVAL_IN_MS);
    let shouldUpdate: boolean = true;

    if (currentTime.isBefore(eTagStart)) {
      currentTime = eTagStart;
    } else if (currentTime.isAfter(eTagStop)) {
      currentTime = eTagStop;
      shouldUpdate = false;
    }

    if (currentTime.isBefore(dayStart)) {
      currentTime = dayStart;
    } else if (currentTime.isAfter(dayStop)) {
      currentTime = dayStop;
      shouldUpdate = false;
    }

    // Current Time Indicator
    currentTimeLine.y1 = 0;
    currentTimeLine.y2 = valueAxis.pixelHeight;

    const currentTimeAsDate: Date = currentTime.asDate();
    const point = dateAxis.dateToPoint(currentTimeAsDate);
    currentTimeLine.x1 = point.x;
    currentTimeLine.x2 = point.x;

    if (userSelectedTimeRef.current === undefined) {
      setSelectedPlotTime(currentTimeAsDate);
    }

    if (shouldUpdate) {
      currentTimeIndicatorTimeoutRef.current = window.setTimeout(
        () =>
          updateCurrentTimeIndicator(
            dayStart,
            dayStop,
            eTagStart,
            eTagStop,
            chart,
            currentTimeLine,
            dateAxis,
            valueAxis,
            setSelectedPlotTime,
            userSelectedTimeRef,
            currentTimeIndicatorTimeoutRef,
            timeZone,
          ),
        updateIntervalInMS,
      );
    }
  }
};

const getUserTimeIndicatorHtml = (date: Date, timeZone: TTimeZone): string =>
  renderToString(
    <SeparatedRowLayout>
      <div>{ZonedDateTime.fromDate(date, timeZone).format(TIME_FORMAT)}</div>
      <CloseTooltipIcon />
    </SeparatedRowLayout>,
  );

export const updateUserTimeIndicator = (
  event:
    | ({
        type: 'hit';
        target: am4core.Container;
      } & SpritePointerTypeEvent &
        am4core.SpritePointEvent &
        am4core.SpriteMouseTouchEvent)
    | undefined,
  chartStart: ZonedDateTime | undefined,
  chartStop: ZonedDateTime | undefined,
  eTagStart: ZonedDateTime | undefined,
  eTagStop: ZonedDateTime | undefined,
  chart: am4charts.XYChart,
  dateAxis: am4charts.DateAxis,
  valueAxis: am4charts.ValueAxis,
  setSelectedPlotTime: ((selectedPlotTime: Date) => void) | undefined,
  userSelectedTimeRef: MutableRefObject<Date | undefined>,
  userTimeIndicatorRef: MutableRefObject<am4core.Line | undefined>,
  zoomOutButtonHitRef: MutableRefObject<boolean>,
  timeZone: TTimeZone,
  onClose?: () => void,
) => {
  if (zoomOutButtonHitRef.current === true) {
    zoomOutButtonHitRef.current = false;
  } else {
    let lineX: number | undefined = undefined;

    if (userTimeIndicatorRef.current) {
      userTimeIndicatorRef.current.tooltip?.dispose();
      userTimeIndicatorRef.current.dispose();
    }

    if (
      event !== undefined &&
      eTagStart !== undefined &&
      eTagStop !== undefined
    ) {
      userSelectedTimeRef.current = dateAxis.positionToDate(
        dateAxis.pointToPosition({ x: event.spritePoint.x, y: 0 }),
      );

      const userSelectedTime: ZonedDateTime = ZonedDateTime.fromDate(
        userSelectedTimeRef.current,
        timeZone,
      );

      if (userSelectedTime.isBefore(eTagStart)) {
        userSelectedTimeRef.current = eTagStart.asDate();
      } else if (userSelectedTime.isAfter(eTagStop)) {
        userSelectedTimeRef.current = eTagStop.asDate();
      }

      if (setSelectedPlotTime !== undefined) {
        setSelectedPlotTime(userSelectedTimeRef.current);
      }
    }

    if (
      userSelectedTimeRef.current &&
      chartStart !== undefined &&
      chartStop !== undefined &&
      chartStart.isSameOrBefore(
        ZonedDateTime.fromDate(userSelectedTimeRef.current, timeZone),
      ) &&
      chartStop.isSameOrAfter(
        ZonedDateTime.fromDate(userSelectedTimeRef.current, timeZone),
      )
    ) {
      lineX = dateAxis.dateToPoint(userSelectedTimeRef.current).x;

      userTimeIndicatorRef.current = chart.plotContainer.createChild(
        am4core.Line,
      );

      userTimeIndicatorRef.current.isMeasured = false;
      userTimeIndicatorRef.current.stroke = USER_TIME_COLOR;
      userTimeIndicatorRef.current.x1 = lineX;
      userTimeIndicatorRef.current.x2 = lineX;
      userTimeIndicatorRef.current.y1 = 0;
      userTimeIndicatorRef.current.y2 = chart.plotContainer.pixelHeight;

      // Tooltip
      userTimeIndicatorRef.current.tooltip = new am4core.Tooltip();
      userTimeIndicatorRef.current.tooltip.align = 'center';
      userTimeIndicatorRef.current.tooltip.background.interactionsEnabled =
        true;
      userTimeIndicatorRef.current.tooltip.background.fill = USER_TIME_COLOR;
      userTimeIndicatorRef.current.tooltip.background.stroke = USER_TIME_COLOR;
      userTimeIndicatorRef.current.tooltip.pointerOrientation = 'down';
      userTimeIndicatorRef.current.tooltip.showTooltipOn = 'always';

      userTimeIndicatorRef.current.tooltip.moveTo({
        x: lineX + valueAxis.measuredWidth + chart.pixelPaddingLeft,
        y: TOOLTIP_Y_OFFSET_VALUE,
      });

      userTimeIndicatorRef.current.tooltip.show();

      userTimeIndicatorRef.current.tooltip.html = getUserTimeIndicatorHtml(
        userSelectedTimeRef.current,
        timeZone,
      );

      // Events
      userTimeIndicatorRef.current.tooltip.background.events.on('hit', () => {
        if (userTimeIndicatorRef.current) {
          userTimeIndicatorRef.current.tooltip?.dispose();
          userTimeIndicatorRef.current.dispose();
          userTimeIndicatorRef.current = undefined;
        }

        userSelectedTimeRef.current = undefined;

        if (onClose) {
          onClose();
        }
      });
    }
  }
};

export const getCurrentLevelHtml = (index: number): string => `
<div class="profile-graph-view-tooltip" data-curtailments="{${getCurrentLevelSeriesKey(
  index,
  CURTAILMENTS_KEY_SUFFIX,
)}}" data-reliability="{${getCurrentLevelSeriesKey(
  index,
  RELIABILITY_LEVEL_KEY_SUFFIX,
)}}" data-reloaders="{${getCurrentLevelSeriesKey(
  index,
  RELOADERS_KEY_SUFFIX,
)}}">
  <div>Current Level MW: {${getCurrentLevelSeriesKey(index)}}</div>
  <div>Market Level MW: {${getCurrentLevelSeriesKey(
    index,
    MARKET_LEVEL_KEY_SUFFIX,
  )}}</div>
  <div class="reliability">Reliability Level MW: {${getCurrentLevelSeriesKey(
    index,
    RELIABILITY_LEVEL_KEY_SUFFIX,
  )}}</div>
  <div>Start Time: {start}</div>
  <div>Stop Time: {stop}</div>
  <div class="curtailments">
    <div>Curtailments:</div>
    <div class="values">{${getCurrentLevelSeriesKey(
      index,
      CURTAILMENTS_KEY_SUFFIX,
    )}}</div>
  </div>
  <div class="reloaders">Reloaders: {${getCurrentLevelSeriesKey(
    index,
    RELOADERS_KEY_SUFFIX,
  )}}</div>
</div>`;

export const getTransmissionLimitHtml = (index: number): string => `
<div class="profile-graph-view-tooltip">
  <div>Transmission Limit MW: {${getTransmissionLimitSeriesKey(index)}}</div>
  <div>Most Limiting Physical Segment Header: {${getTransmissionLimitSeriesKey(
    index,
    MOST_LIMITING_KEY_SUFFIX,
  )}}</div>
  <div>Start Time: {start}</div>
  <div>Stop Time: {stop}</div>
</div>`;

export const lastRequestTypeToColor = (
  lastRequestType: ERequestType | null,
): am4core.Color => {
  switch (lastRequestType) {
    case ERequestType.AtfAdjustment:
      return STATE_BLUE_COLOR;
    case ERequestType.BtfAdjustment:
      return STATE_ORANGE_COLOR;
    case ERequestType.Correction:
      return STATE_GREY_COLOR;
    case ERequestType.CurrentLevel:
      return STATE_GREEN_COLOR;
    case ERequestType.CurrentPendingLevel:
      return STATE_ORANGE_COLOR;
    case ERequestType.Curtailment:
      return STATE_RED_COLOR;
    case ERequestType.Extension:
      return STATE_ORANGE_COLOR;
    case ERequestType.NewTag:
      return STATE_GREY_COLOR;
    case ERequestType.Reload:
      return STATE_GREEN_COLOR;
    case ERequestType.Termination:
      return STATE_GREY_COLOR;
    default:
      return RANGE_HIGHLIGHT_BLUE_COLOR;
  }
};

const getPODForPhysicalSegmentRef = (
  physicalSegmentRef: number | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
): IPointInfo | null => {
  if (physicalSegmentRef !== null && physicalSegmentsProfiles !== null) {
    for (let i: number = 0; i < physicalSegmentsProfiles.length; i += 1) {
      const physicalSegmentsProfile: IETagPhysicalSegmentsProfile =
        physicalSegmentsProfiles[i];

      if (
        physicalSegmentsProfile.physical_segments_profiles !== null &&
        physicalSegmentsProfile.physical_segments_profiles.transmission !== null
      ) {
        const eTagTransmissionSegment: IETagTransmissionSegment | undefined =
          physicalSegmentsProfile.physical_segments_profiles.transmission.transmission_segments?.find(
            (eTagTransmissionSegment: IETagTransmissionSegment): boolean =>
              eTagTransmissionSegment.physical_segment_id ===
              physicalSegmentRef,
          );

        if (eTagTransmissionSegment !== undefined) {
          return eTagTransmissionSegment.pod;
        }
      }
    }
  }

  return null;
};

export const getProfileSegmentOptions = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IOption<IProfileSegment>[] => {
  const profileSegmentOptions: IOption<IProfileSegment>[] = [];
  let previousPhysicalSegmentRef: number | null = null;

  profileSegmentOptions.push(PROFILE_SEGMENT_GEN_OPTION);

  transmissionAllocations?.forEach(
    (transmissionAllocation: IETagTransmissionAllocation) => {
      const { physical_segment_ref } = transmissionAllocation;

      if (physical_segment_ref !== previousPhysicalSegmentRef) {
        const pod: IPointInfo | null = getPODForPhysicalSegmentRef(
          physical_segment_ref,
          physicalSegmentsProfiles,
        );

        profileSegmentOptions.push({
          label: `${pod?.point_name}`,
          value: {
            physicalSegmentRef:
              physical_segment_ref === null ? undefined : physical_segment_ref,
            profileSegment: EProfileSegment.Pod,
          },
        });

        previousPhysicalSegmentRef = physical_segment_ref;
      }
    },
  );

  profileSegmentOptions.push(PROFILE_SEGMENT_LOAD_OPTION);

  return profileSegmentOptions;
};

export const profileSegmentToUid = (
  profileSegment: IProfileSegment,
): string => {
  if (
    profileSegment.profileSegment === EProfileSegment.Gen ||
    profileSegment.profileSegment === EProfileSegment.Load
  ) {
    return profileSegment.profileSegment as string;
  } else if (profileSegment.profileSegment === EProfileSegment.Pod) {
    if (profileSegment.physicalSegmentRef === undefined) {
      throw new Error('Missing profileSegment.physicalSegmentRef');
    }

    return `POD-${profileSegment.physicalSegmentRef}`;
  }

  throw new Error(
    `Invalid profileSegment.profileSegment: ${profileSegment.profileSegment}`,
  );
};
