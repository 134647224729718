import { IMiscInfoEditableProps } from 'components/molecules/MiscInfoEditable/MiscInfoEditable';
import { IMiscInfo } from 'interfaces/General';
import { IInputColumnConfig } from 'interfaces/View';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  getEditInfoKey,
  getInitialMiscInfo,
  getSplitEditInfoKey,
} from 'utils/detail';
import { eventToStringOrNull } from 'utils/general';

interface IuseMiscInfoEditableReturn {
  miscInfoEditableProps: IMiscInfoEditableProps;
  miscInfos: IMiscInfo[];
}

const useMiscInfoEditable = (
  baseKey: string,
  isDisabled?: boolean,
  hasChanged?: boolean,
): IuseMiscInfoEditableReturn => {
  const initialEditKey: string = getEditInfoKey(baseKey, 0, 0);
  const [miscInfos, setMiscInfos] = useState<IMiscInfo[]>([]);

  const handleTokenInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, record: IMiscInfo) => {
      setMiscInfos((previousMiscInfos: IMiscInfo[]): IMiscInfo[] => {
        let miscInfoIndex: number = previousMiscInfos.findIndex(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === record.key,
        );
        let updatedMiscInfo: IMiscInfo;

        if (miscInfoIndex === -1) {
          updatedMiscInfo = getInitialMiscInfo(record.key);
          miscInfoIndex = previousMiscInfos.length;
        } else {
          updatedMiscInfo = previousMiscInfos[miscInfoIndex];
        }

        const updatedMiscInfos: IMiscInfo[] = [...previousMiscInfos];

        updatedMiscInfos[miscInfoIndex] = {
          ...updatedMiscInfo,
          token: eventToStringOrNull(event),
        };

        return updatedMiscInfos;
      });
    },
    [setMiscInfos],
  );

  const tokenInputColumnConfig: IInputColumnConfig<IMiscInfo> = useMemo(
    () => ({
      getKey: (record: IMiscInfo) => record.key,
      isDisabled,
      onChange: handleTokenInputChange,
    }),
    [handleTokenInputChange, isDisabled],
  );

  const handleValueInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, record: IMiscInfo) => {
      setMiscInfos((previousMiscInfos: IMiscInfo[]): IMiscInfo[] => {
        let miscInfoIndex: number = previousMiscInfos.findIndex(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === record.key,
        );
        let updatedMiscInfo: IMiscInfo;

        if (miscInfoIndex === -1) {
          updatedMiscInfo = getInitialMiscInfo(record.key);
          miscInfoIndex = previousMiscInfos.length;
        } else {
          updatedMiscInfo = previousMiscInfos[miscInfoIndex];
        }

        const updatedMiscInfos: IMiscInfo[] = [...previousMiscInfos];

        updatedMiscInfos[miscInfoIndex] = {
          ...updatedMiscInfo,
          value: eventToStringOrNull(event),
        };

        return updatedMiscInfos;
      });
    },
    [setMiscInfos],
  );

  const valueInputColumnConfig: IInputColumnConfig<IMiscInfo> = useMemo(
    () => ({
      getKey: (record: IMiscInfo) => record.key,
      isDisabled,
      onChange: handleValueInputChange,
    }),
    [handleValueInputChange, isDisabled],
  );

  const handleAddMiscInfo = useCallback(
    (value: unknown, record: IMiscInfo) => {
      setMiscInfos((previousMiscInfos: IMiscInfo[]): IMiscInfo[] => {
        let foundIndex: number = -1;
        let highestIndex: number = -1;

        previousMiscInfos.forEach((miscInfo: IMiscInfo, index: number) => {
          const { editIndex } = getSplitEditInfoKey(miscInfo.key);

          if (editIndex === undefined) {
            throw new Error(`Invalid miscInfo.key: ${miscInfo.key}`);
          }

          if (editIndex > highestIndex) {
            highestIndex = editIndex;
          }

          if (miscInfo.key === record.key) {
            foundIndex = index;
          }
        });

        if (foundIndex === -1) {
          return previousMiscInfos;
        }

        const updatedMiscInfos: IMiscInfo[] = [...previousMiscInfos];
        updatedMiscInfos.splice(
          foundIndex + 1,
          0,
          getInitialMiscInfo(getEditInfoKey(baseKey, 0, highestIndex + 1)),
        );

        return updatedMiscInfos;
      });
    },
    [baseKey, setMiscInfos],
  );

  const handleRemoveMiscInfo = useCallback(
    (record: IMiscInfo) => {
      setMiscInfos((previousMiscInfos: IMiscInfo[]): IMiscInfo[] => {
        const foundIndex: number = previousMiscInfos.findIndex(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === record.key,
        );

        if (foundIndex === -1) {
          return previousMiscInfos;
        }

        const updatedMiscInfos: IMiscInfo[] = [...previousMiscInfos];

        updatedMiscInfos.splice(foundIndex, 1);

        return updatedMiscInfos;
      });
    },
    [setMiscInfos],
  );

  return useMemo(
    () => ({
      miscInfoEditableProps: {
        data: miscInfos,
        hasChanged,
        initialEditKey,
        isDisabled,
        onAdd: handleAddMiscInfo,
        onRemove: handleRemoveMiscInfo,
        tokenInputColumnConfig: tokenInputColumnConfig,
        valueInputColumnConfig: valueInputColumnConfig,
      },
      miscInfos,
    }),
    [
      handleAddMiscInfo,
      handleRemoveMiscInfo,
      hasChanged,
      initialEditKey,
      isDisabled,
      miscInfos,
      tokenInputColumnConfig,
      valueInputColumnConfig,
    ],
  );
};

export default useMiscInfoEditable;
