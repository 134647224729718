import { TIME_FORMAT } from 'constants/time';
import { IShowTime } from 'interfaces/Component';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getDisabledHours = (currentHour: number) => (): number[] => {
  const disabledHours: number[] = [];

  for (let i: number = 0; i < currentHour; i += 1) {
    disabledHours.push(i);
  }

  return disabledHours;
};

export const getDisabledMinutes =
  (currentHour: number, currentMinute: number) =>
  (hour: number): number[] => {
    const disabledMinutes: number[] = [];

    if (hour === currentHour) {
      for (let i: number = 0; i < currentMinute; i += 1) {
        disabledMinutes.push(i);
      }
    }

    return disabledMinutes;
  };

export const getShowTime = (timeZone: TTimeZone): IShowTime => ({
  defaultValue: [
    ZonedDateTime.now(timeZone).startOf('hour'),
    ZonedDateTime.now(timeZone).startOf('hour'),
  ],
  format: TIME_FORMAT,
});
