import { Tag as AntDesignTag, TagProps } from 'antd';
import {
  INPUT_HEIGHT,
  STANDARD_SPACING_VALUE,
  TO_ENTITY_TAG_WIDTH_VALUE as TO_ENTITY_TAG_MIN_WIDTH_VALUE,
} from 'constants/styles';
import { IToEntity } from 'interfaces/ToEntity';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';

const StyledTag = styled(AntDesignTag)<TagProps>`
  height: ${INPUT_HEIGHT};
  min-width: ${TO_ENTITY_TAG_MIN_WIDTH_VALUE}px;
  padding: ${STANDARD_SPACING_VALUE / 2}px;
  text-align: center;
`;

interface IToEntityIndicatorProps {
  toEntity: IToEntity;
}

const ToEntityIndicator = ({
  toEntity,
}: IToEntityIndicatorProps): JSX.Element => {
  const { entity_code } = toEntity;

  return (
    <Tooltip title={`${entity_code} (${toEntity.to_entity})`}>
      <StyledTag color='blue'>{entity_code}</StyledTag>
    </Tooltip>
  );
};

export default ToEntityIndicator;
