const ColourPaletteIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
    <path
      d='M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm0 336.842c-44.648 0-80.842-36.194-80.842-80.842s36.194-80.842 80.842-80.842 80.842 36.194 80.842 80.842-36.194 80.842-80.842 80.842z'
      fill='#d8d8da'
    />
    <path
      d='M282.947 188.632h220.076C485.09 122.726 441.507 67.394 383.64 34.044L229.053 188.632h53.894z'
      fill='#d4b6e6'
    />
    <path
      d='M229.053 188.632L383.639 34.044C346.068 12.39 302.482 0 256 0c-23.319 0-45.899 3.135-67.368 8.978v220.075l40.421-40.421z'
      fill='#ebafd1'
    />
    <path
      d='M188.632 229.053V8.978C122.726 26.91 67.394 70.493 34.045 128.36l154.586 154.588v-53.895z'
      fill='#e07188'
    />
    <g fill='#d8d8da'>
      <path d='M188.632 229.053l40.421-40.42h53.894v-.001h-53.894zM229.053 323.367l-40.421-40.42 40.421 40.421h53.894l40.421-40.421-40.421 40.42z' />
    </g>
    <path
      d='M503.024 188.632H282.947v.001h.958l39.463 40.42L477.955 383.64C499.611 346.068 512 302.482 512 256c0-23.319-3.135-45.901-8.976-67.368z'
      fill='#b4d8f1'
    />
    <path
      d='M323.368 282.947v220.075c65.905-17.932 121.238-61.517 154.586-119.382L323.368 229.053v53.894z'
      fill='#acfff4'
    />
    <path
      d='M282.947 323.368L128.361 477.956C165.932 499.61 209.518 512 256 512c23.319 0 45.899-3.135 67.368-8.977V282.947l-40.421 40.421z'
      fill='#95d5a7'
    />
    <path
      d='M229.053 323.368H8.976C26.91 389.274 70.493 444.606 128.36 477.956l154.588-154.588h-53.895z'
      fill='#f8e99b'
    />
    <path
      d='M188.632 282.947L34.045 128.36C12.389 165.932 0 209.518 0 256c0 23.319 3.135 45.901 8.976 67.368h220.076l-40.42-40.421z'
      fill='#efc27b'
    />
    <path fill='#d8d8da' d='M283.905 188.633h-.958l40.421 40.42z' />
    <path
      d='M503.024 188.632C485.09 122.726 441.507 67.394 383.64 34.044L256 161.684v26.947h247.024z'
      fill='#b681d5'
    />
    <path
      d='M383.639 34.044C346.068 12.39 302.482 0 256 0v161.684l127.639-127.64z'
      fill='#e592bf'
    />
    <path
      d='M256 350.316V512c23.319 0 45.899-3.135 67.368-8.977V282.947l-40.421 40.421L256 350.316z'
      fill='#80cb93'
    />
    <path fill='#f6e27d' d='M282.947 323.368H256v26.948z' />
  </svg>
);

export default ColourPaletteIcon;
