import Button from 'components/atoms/Button/Button';
import IconButton from 'components/atoms/IconButton/IconButton';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { EActionState } from 'enums/General';
import { IActionResponse, IModalHandlers } from 'interfaces/Component';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useEffect, useState } from 'react';
import { TErrorMessage } from 'types/Error';
import { TPlacement } from 'types/General';
import { captureError } from 'utils/error';
import { encodeIds } from 'utils/general';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';
import { EMenuActions, TMenuAction } from '../../../reduxes/Menu/actions';

export interface IToEntityConfiguratorProps {
  isDisabled?: boolean;
  setHandlers: (handlers: IModalHandlers) => void;
}

// Props
interface IToEntityConfigurationProps<T> {
  Configurator: (props: T & IToEntityConfiguratorProps) => JSX.Element;
  configuratorProps: T;
  encodedPermissionsId: string;
  icon: JSX.Element;
  isDisabled?: boolean;
  label: string;
  modalWidth: number;
  title?: string;
  toEntity: IToEntity;
  tooltipPlacement?: TPlacement;
  ref?: any;
}

const ToEntityConfiguration = <T extends any>({
  Configurator,
  configuratorProps,
  encodedPermissionsId,
  icon,
  isDisabled,
  label,
  modalWidth,
  title,
  toEntity,
  tooltipPlacement,
  ref,
}: IToEntityConfigurationProps<T>): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [handlers, setHandlers] = useState<IModalHandlers>({});
  const [disableActions, setDisableActions] = useState<boolean>(false);
  const [deleteActionState, setDeleteActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [saveActionState, setSaveActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  const menuState = useSelector((state: TRootState) => state.menu);
  const dispatch = useDispatch();

  const setShowConfigurationMenuItemClicked = useCallback(
    (showConfiguration: boolean): TMenuAction => ({
      payload: showConfiguration,
      type: EMenuActions.ShowConfigurationItemClicked,
    }),
    [],
  );

  useEffect(() => {
    if (!showModal) {
      setDeleteActionState(EActionState.NoAction);

      setSaveActionState(EActionState.NoAction);

      setErrorMessage(null);
    }
  }, [showModal]);

  useEffect(() => {
    setErrorMessage(null);
  }, [handlers]);

  const handleShow = useCallback(() => {
    dispatch(setShowConfigurationMenuItemClicked(false));
    setShowModal(true);

    setHandlers({});
  }, [
    dispatch,
    setShowConfigurationMenuItemClicked,
    setShowModal,
    setHandlers,
  ]);

  useEffect(() => {
    if (menuState.showConfiguration && !label) {
      handleShow();
    }
  }, [handleShow, label, menuState]);

  const handleCancel = useCallback(async () => {
    let handlerResponse: boolean | void;
    if (handlers.cancelHandler !== undefined) {
      setDisableActions(true);

      handlerResponse = await handlers.cancelHandler();

      setDisableActions(false);
    }

    if (!handlerResponse) {
      setShowModal(false);
    }
  }, [handlers]);

  const handleDelete = useCallback(async () => {
    if (handlers.deleteHandler !== undefined) {
      try {
        setDisableActions(true);

        setDeleteActionState(EActionState.Actioning);

        setErrorMessage(null);

        const actionResponse: IActionResponse = await handlers.deleteHandler();

        setDeleteActionState(actionResponse.actionState);
        setErrorMessage(actionResponse.errorMessage);
      } catch (error: any) {
        captureError(error);

        setErrorMessage(error);
      } finally {
        setDisableActions(false);
      }
    }
  }, [handlers]);

  const handleSave = useCallback(async () => {
    if (handlers.saveHandler !== undefined) {
      try {
        setDisableActions(true);

        setSaveActionState(EActionState.Actioning);

        setErrorMessage(null);

        const actionResponse: IActionResponse = await handlers.saveHandler();

        setSaveActionState(actionResponse.actionState);

        setErrorMessage(actionResponse.errorMessage);
      } catch (error: any) {
        captureError(error);

        setErrorMessage(error.message);
      } finally {
        setDisableActions(false);
      }
    }
  }, [handlers]);

  const adjustedTitle: string =
    title === undefined
      ? `${toEntity.entity_code} ${label} configurator`
      : title;

  return (
    <>
      <Tooltip
        isDisabled={showModal}
        placement={tooltipPlacement}
        title={adjustedTitle}
      >
        <IconButton
          buttonRef={ref}
          className={'configuration-toolbar-btn'}
          icon={icon}
          isDisabled={isDisabled}
          onClick={handleShow}
        />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            additionalActions={
              <Button
                actionState={deleteActionState}
                encodedPermissionsId={encodeIds([
                  encodedPermissionsId,
                  `deleteConfiguration`,
                ])}
                isDisabled={
                  disableActions || handlers.deleteHandler === undefined
                }
                label={`Delete ${label} Configuration`}
                onClick={handleDelete}
              />
            }
            confirmActionState={saveActionState}
            confirmLabel='Save'
            errorMessage={errorMessage}
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            isCancelDisabled={disableActions}
            isConfirmDisabled={
              disableActions || handlers.saveHandler === undefined
            }
            onCancel={handleCancel}
            onConfirm={handleSave}
          />
        }
        isVisible={showModal}
        onCancel={disableActions ? undefined : handleCancel}
        title={title}
        width={modalWidth}
      >
        <Configurator
          {...configuratorProps}
          isDisabled={isDisabled || disableActions}
          setHandlers={setHandlers}
        />
      </Modal>
    </>
  );
};

export default ToEntityConfiguration;
