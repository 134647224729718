import WrappedDataGrid, {
  IWrappedDataGridProps,
} from 'components/organisms/DataGrid/WrappedDataGrid';
import DataGridSelectionProvider from 'components/organisms/DataGridSelection/DataGridSelection';
import {
  IDataGridHandle,
  IDataGridSelectionContext,
} from 'interfaces/Component';
import { IIndexable } from 'interfaces/General';
import { Context, ForwardedRef, forwardRef, useMemo } from 'react';
import { Column } from 'react-data-grid';
import { getDataGridSelectionContext } from 'utils/component';

// Redecalare forwardRef
// See https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null,
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

export interface IDataGridProps<
  C extends Column<R, S>,
  R extends IIndexable,
  S extends IIndexable,
  T,
> extends Omit<IWrappedDataGridProps<C, R, S, T>, 'DataGridSelectionContext'> {
  DataGridSelectionContext?: Context<IDataGridSelectionContext>;
}

const DataGrid = <
  C extends Column<R, S>,
  R extends IIndexable,
  S extends IIndexable,
  T,
>(
  { DataGridSelectionContext, ...rest }: IDataGridProps<C, R, S, T>,
  ref: ForwardedRef<IDataGridHandle>,
): JSX.Element => {
  const CurrentDataGridSelectionContext: Context<IDataGridSelectionContext> =
    useMemo(
      (): Context<IDataGridSelectionContext> =>
        DataGridSelectionContext === undefined
          ? getDataGridSelectionContext()
          : DataGridSelectionContext,
      [DataGridSelectionContext],
    );

  return (
    <DataGridSelectionProvider
      DataGridSelectionContext={CurrentDataGridSelectionContext}
    >
      <WrappedDataGrid
        {...rest}
        DataGridSelectionContext={CurrentDataGridSelectionContext}
        ref={ref}
      />
    </DataGridSelectionProvider>
  );
};

export default forwardRef(DataGrid);
