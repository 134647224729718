import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import AlertRulesConfiguration from 'components/organisms/AlertRulesConfiguration/AlertRulesConfiguration';
import { IPageContentProps } from 'components/organisms/Page/Page';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING } from 'constants/styles';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  > :not(:first-child) {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

interface IAlertRulesPageContentProps extends IPageContentProps<undefined> {}

const AlertRulesPageContent = (
  props: IAlertRulesPageContentProps,
): JSX.Element => {
  const { userInfo } = props;
  const { selectedToEntity } = userInfo;

  return (
    <Layout>
      <AlertRulesConfiguration
        encodedPermissionsId={
          selectedToEntity !== undefined
            ? encodeIds(['alertRulesConfiguration'], selectedToEntity.to_entity)
            : undefined
        }
        toEntity={selectedToEntity}
      />
    </Layout>
  );
};

export default withFloatOver(AlertRulesPageContent);
