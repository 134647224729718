import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Spinner from 'components/atoms/Spinner/Spinner';
import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import ETagManager from 'components/organisms/ETagManager/ETagManager';
import { IPageContentProps } from 'components/organisms/Page/Page';
import { PAGE_LAYOUT_STYLES } from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import { EDetailIdType } from 'enums/Detail';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import usePageModeChange from 'hooks/usePageModeChange';
import usePrevious from 'hooks/usePrevious';
import { IETagConfig, IETagConfigResponse } from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { detailSetETagDetailInitialParameters } from 'reduxes/Detail/actions';
import { userSetSelectedToEntity } from 'reduxes/User/actions';
import { IToEntityUserState } from 'reduxes/User/types';
import { retrieveToEntityConfig } from 'services/configclient/config';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TETagDraftId, TETagTagPrimaryKey, TETagTemplateId } from 'types/ETag';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { useAsyncMemo } from 'use-async-memo';
import {
  detailIdTypeToDisplayString,
  getViewModeForTagPrimaryKey,
  isAutoNavigateToTag,
} from 'utils/detail';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}
`;

interface IDetailPageContentProps extends IPageContentProps<undefined> {}

const retrieveDetailPageContentState = (state: TRootState) => {
  const { isDetailDeleted, latestErroredDetailIdType, toEntity } =
    state.detail.present;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    isDetailDeleted,
    latestErroredDetailIdType,
    timeZone,
    toEntity,
  };
};

const DetailPageContent = ({
  userInfo,
}: IDetailPageContentProps): JSX.Element => {
  const { setFloatOverContent, setFloatOverId } =
    useContext<IFloatOverContext>(FloatOverContext);
  const dispatch = useDispatch();
  const { isDetailDeleted, latestErroredDetailIdType, timeZone, toEntity } =
    useSelector(retrieveDetailPageContentState);
  const { search } = useLocation();
  const pageModeChange = usePageModeChange(search);
  const { toEntities, toEntityUserStates } = userInfo;
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [viewModeErrorMessage, setViewModeErrorMessage] =
    useState<TErrorMessage>(null);
  const detailIdTypeRef = useRef<EDetailIdType>(
    null,
  ) as MutableRefObject<EDetailIdType>;
  const previousErroredDetailIdType = usePrevious(latestErroredDetailIdType);

  const setETagDetailInitialParameters = useCallback(
    (
      pageMode: EPageMode,
      viewMode: EViewMode,
      toEntity: IToEntity,
      draft_id: TETagDraftId,
      tag_primary_key?: TETagTagPrimaryKey,
      template_id?: TETagTemplateId,
      config?: IETagConfig,
    ) =>
      dispatch(
        detailSetETagDetailInitialParameters({
          config,
          draft_id,
          pageMode,
          tag_primary_key,
          template_id,
          toEntity,
          viewMode,
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    if (toEntity !== null) {
      const foundToEntity = toEntities.find(
        (searchToEntity: IToEntity): boolean =>
          searchToEntity.to_entity === toEntity.to_entity,
      );

      if (foundToEntity !== undefined) {
        dispatch(userSetSelectedToEntity({ selectedToEntity: foundToEntity }));
      }
    }
  }, [dispatch, toEntities, toEntity]);

  const config: IETagConfig | undefined = useAsyncMemo(async () => {
    if (toEntity !== null) {
      try {
        const retrieveToEntityConfigResponse: AxiosResponse<IETagConfigResponse> =
          await retrieveToEntityConfig(toEntity.to_entity);

        const eTagConfigResponse: IETagConfigResponse =
          retrieveToEntityConfigResponse.data;

        if (!isSuccessStatus(retrieveToEntityConfigResponse.status)) {
          throw new Error(eTagConfigResponse.errorMessage!);
        }

        return eTagConfigResponse.response;
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }

    return undefined;
  }, [toEntity]);

  useAsyncEffect(async () => {
    if (toEntities.length > 0) {
      const query: URLSearchParams = new URLSearchParams(search);
      const pageMode: EPageMode = query.get('mode') as EPageMode;
      let viewMode: EViewMode = EViewMode.None;

      setViewModeErrorMessage(null);

      setFloatOverContent(null);
      setFloatOverId('');

      if (!Object.values(EPageMode).includes(pageMode)) {
        setViewModeErrorMessage(
          `Invalid mode query parameter of "${pageMode}".`,
        );
      } else {
        const toEntityId: TToEntityId | null = query.get('toEntity');

        if (isEmptyValue(toEntityId)) {
          setViewModeErrorMessage('Missing query parameter "toEntity".');
        } else {
          const toEntity: IToEntity | undefined = toEntities.find(
            (toEntity: IToEntity): boolean => toEntity.to_entity === toEntityId,
          );

          if (toEntity === undefined) {
            setErrorMessage(
              `The current user does not have permission to access toEntity: ${toEntityId}`,
            );
          } else {
            const draftId: TETagDraftId = query.get(EDetailIdType.DraftId);
            const tagPrimaryKey: TETagTagPrimaryKey | null = query.get(
              EDetailIdType.TagPrimaryKey,
            );
            // If given both a draftId and a tagPrimaryKey we usually ignore the tagPrimaryKey
            // and just use the draftId. There is one case where we do not: this is the case
            // where we have autoNavigateToTag set to true. In this case we will try to load
            // the details for the given tagPrimaryKey. However, this may fail (e.g. if the
            // tagPrimaryKey is invalid). When it fails we want to fall back to the draftId.
            // In the failure case, the erroredDetailIdType will be set so we can keep from
            // trying to load the tag details again.
            const tagPrimaryKeyHasPriority =
              !isEmptyValue(tagPrimaryKey) &&
              isAutoNavigateToTag(query) &&
              latestErroredDetailIdType !== EDetailIdType.TagPrimaryKey;

            if (isEmptyValue(draftId) || tagPrimaryKeyHasPriority) {
              if (isEmptyValue(tagPrimaryKey)) {
                const templateId: TETagTemplateId | null = query.get(
                  EDetailIdType.TemplateId,
                );

                if (isEmptyValue(templateId)) {
                  setViewModeErrorMessage(
                    `Missing query parameter. "${EDetailIdType.DraftId}" or "${EDetailIdType.TagPrimaryKey}" or "${EDetailIdType.TemplateId}" must be included.`,
                  );
                } else {
                  if (pageMode === EPageMode.Review) {
                    viewMode = EViewMode.ReviewETagTemplate;
                  } else if (pageMode === EPageMode.Edit) {
                    viewMode = EViewMode.EditETagTemplate;
                  }

                  detailIdTypeRef.current = EDetailIdType.TemplateId;

                  setETagDetailInitialParameters(
                    pageMode,
                    viewMode,
                    toEntity!,
                    null,
                    undefined,
                    templateId!,
                    config,
                  );
                }
              } else {
                let shouldGoToReview: boolean = false;

                if (pageMode === EPageMode.Review) {
                  viewMode = EViewMode.ReviewETag;
                } else if (pageMode === EPageMode.Edit) {
                  try {
                    viewMode = await getViewModeForTagPrimaryKey(
                      tagPrimaryKey!,
                      toEntity.to_entity,
                      timeZone,
                    );
                  } catch (error: any) {
                    setViewModeErrorMessage(error.message);

                    shouldGoToReview = true;
                  }
                }

                detailIdTypeRef.current = EDetailIdType.TagPrimaryKey;

                setETagDetailInitialParameters(
                  pageMode,
                  viewMode,
                  toEntity!,
                  null,
                  tagPrimaryKey!,
                  undefined,
                  config,
                );

                // Must make sure to only switch pageMode AFTER calling
                // setETagDetailInitialParameters in order to allow correctly
                // updating the pageMode in the redux store
                if (shouldGoToReview) {
                  pageModeChange(EPageMode.Review);
                }
              }
            } else {
              // Given draftId and either autoNavigateToTag is false
              // or not given tagPrimaryKey
              if (pageMode === EPageMode.Review) {
                viewMode = EViewMode.ReviewETagDraft;
              } else if (pageMode === EPageMode.Edit) {
                viewMode = EViewMode.EditETagDraft;
              }

              detailIdTypeRef.current = EDetailIdType.DraftId;

              setETagDetailInitialParameters(
                pageMode,
                viewMode,
                toEntity!,
                draftId,
                undefined,
                undefined,
                config,
              );
            }
          }
        }
      }
    }
  }, [
    config,
    latestErroredDetailIdType,
    pageModeChange,
    search,
    timeZone,
    toEntities,
  ]);

  useEffect(() => {
    if (
      previousErroredDetailIdType !== latestErroredDetailIdType &&
      latestErroredDetailIdType !== null
    ) {
      const msg: string = isDetailDeleted
        ? `Could not load details for ${detailIdTypeToDisplayString(
            latestErroredDetailIdType,
          )}`
        : `Could not load details for given "${latestErroredDetailIdType}" parameter`;

      if (
        detailIdTypeRef.current === null ||
        detailIdTypeRef.current === latestErroredDetailIdType
      ) {
        setViewModeErrorMessage(msg);
      } else {
        setViewModeErrorMessage(
          `${msg}. Using "${detailIdTypeRef.current}" instead.`,
        );
      }
    }
  }, [isDetailDeleted, latestErroredDetailIdType, previousErroredDetailIdType]);

  const toEntityUserState: IToEntityUserState | undefined = useMemo(
    () =>
      toEntity === null ? undefined : toEntityUserStates[toEntity.to_entity],
    [toEntity, toEntityUserStates],
  );

  return (
    <Layout>
      {errorMessage === null ? (
        toEntity === null ? (
          viewModeErrorMessage === null ? (
            <Spinner />
          ) : (
            <ErrorMessage maxWidth='100%'>{viewModeErrorMessage}</ErrorMessage>
          )
        ) : (
          <ETagManager
            encodedPermissionsId={encodeIds(
              ['eTagManager'],
              toEntity.to_entity,
            )}
            onPageModeChange={pageModeChange}
            toEntityUserState={toEntityUserState}
            viewModeErrorMessage={viewModeErrorMessage}
          />
        )
      ) : (
        <ErrorMessage maxWidth='100%'>{errorMessage}</ErrorMessage>
      )}
    </Layout>
  );
};

export default withFloatOver(DetailPageContent);
