import {
  EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
  EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
} from 'constants/Detail';
import {
  EDIT_MARKET_SEGMENT_CONTACT_INFO_FAX_LABEL,
  EDIT_MARKET_SEGMENT_CONTACT_INFO_PHONE_LABEL,
} from 'hooks/useMarketEditColumns/constants';
import { IContactInfo } from 'interfaces/General';
import { IDetailMarketSegment } from 'reduxes/Detail/types';
import { getEditInfoKey, getSplitEditInfoKey } from 'utils/detail';

export const getContactInfoEditId = (record: IDetailMarketSegment): string =>
  getEditInfoKey(
    EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
    record.market_segment_id,
    0,
  );

export const getContactInfoInitialEditKey = (
  record: IDetailMarketSegment,
): string =>
  getEditInfoKey(
    EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
    record.market_segment_id,
    0,
  );

export const getContactInfoPhoneEditId = (record: IContactInfo): string => {
  const { primaryId } = getSplitEditInfoKey(record.key);

  return getEditInfoKey(
    EDIT_MARKET_SEGMENT_CONTACT_INFO_PHONE_LABEL,
    primaryId,
    0,
  );
};

export const getContactInfoFaxEditId = (record: IContactInfo): string => {
  const { primaryId } = getSplitEditInfoKey(record.key);

  return getEditInfoKey(
    EDIT_MARKET_SEGMENT_CONTACT_INFO_FAX_LABEL,
    primaryId,
    0,
  );
};

export const getContractsEditId = (record: IDetailMarketSegment): string =>
  getEditInfoKey(
    EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
    record.market_segment_id,
    0,
  );

export const getContractsInitialEditKey = (
  record: IDetailMarketSegment,
): string =>
  getEditInfoKey(
    EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
    record.market_segment_id,
    0,
  );

export const getMiscInfoEditId = (record: IDetailMarketSegment): string =>
  getEditInfoKey(
    EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
    record.market_segment_id,
    0,
  );

export const getMiscInfoInitialEditKey = (
  record: IDetailMarketSegment,
): string =>
  getEditInfoKey(
    EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
    record.market_segment_id,
    0,
  );
