import { Dropdown as AntDesignDropdown, Menu as AntDesignMenu } from 'antd';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ETAG_FILTER_DETAIL_MAP } from 'constants/ETag';
import {
  FILTER_BUTTON_WIDTH_VALUE,
  FILTER_LAYOUT_RIGHT_MARGIN_VALUE,
  HIGHLIGHT_BLUE,
} from 'constants/styles';
import { EFilterStringType } from 'enums/Filter';
import { IFilterString } from 'interfaces/Filter';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ChangeEvent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  > :not(:last-child) {
    margin-right: ${FILTER_LAYOUT_RIGHT_MARGIN_VALUE}px;
  }
`;

interface IStyledInputProps {
  width: number;
}

const StyledInput = styled(Input)<IStyledInputProps>`
  width: ${(props) => props.width}px;
`;

interface IFilterButtonWrapperProps extends HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const FilterButtonWrapper = styled.span<IFilterButtonWrapperProps>`
  > button {
    font-size: 9px;
    padding: 0;
    width: ${FILTER_BUTTON_WIDTH_VALUE}px;

    ${(props) =>
      props.active
        ? `
        color: ${HIGHLIGHT_BLUE};
        font-weight: bold;
      `
        : ''};
  }
`;

const OrButtonWrapper = styled.span`
  > button {
    font-size: 9px;
    padding: 0;
    width: ${FILTER_BUTTON_WIDTH_VALUE}px;
  }
`;

interface IProps {
  filterString: IFilterString;
  inputWidth: number;
  isAddDisabled?: boolean;
  isDisabled?: boolean;
  onAdd: () => void;
  onChange: (filterString: IFilterString) => void;
  onRemove: () => void;
}

const ETagFilterString = (props: IProps): JSX.Element => {
  const {
    filterString,
    inputWidth,
    isAddDisabled,
    isDisabled,
    onAdd,
    onChange,
    onRemove,
  } = props;

  const handleFilterSelect = (menuInfo: MenuInfo) => {
    onChange({
      ...filterString,
      filter_type: menuInfo.key as EFilterStringType,
    });
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    // NOTE: Since there is no clear handler on the Ant Design Input component
    // we have to rely on the internal workings of the component. There is a
    // slight distinction between the events that are passed to this handler.
    // If the user clicks the clear icon then the type of the event is 'click',
    // while if the user changes the text value then the type of the event is
    // 'change'. We use this distinction to determine if we should trigger an
    // onRemove call or an onChange call.
    if (event.type === 'click') {
      onRemove();
    } else {
      onChange({ ...filterString, value: event.target.value });
    }
  };

  const FilterMenu: JSX.Element = (
    <AntDesignMenu onClick={handleFilterSelect}>
      {Object.keys(ETAG_FILTER_DETAIL_MAP)
        .filter((key: string): boolean => key in EFilterStringType)
        .map(
          (key: string): JSX.Element => (
            <AntDesignMenu.Item key={key}>
              {ETAG_FILTER_DETAIL_MAP[key].description}
            </AntDesignMenu.Item>
          ),
        )}
    </AntDesignMenu>
  );

  const hasEmptyValue: boolean = isEmptyValue(filterString.value);

  return (
    <Layout>
      <Tooltip
        isDisabled={isDisabled}
        title={ETAG_FILTER_DETAIL_MAP[filterString.filter_type].description}
      >
        <AntDesignDropdown
          disabled={isDisabled}
          overlay={FilterMenu}
          trigger={['click']}
        >
          <FilterButtonWrapper active={true}>
            <Button
              isDisabled={isDisabled}
              label={ETAG_FILTER_DETAIL_MAP[filterString.filter_type].label}
            />
          </FilterButtonWrapper>
        </AntDesignDropdown>
      </Tooltip>
      <StyledInput
        allowClear={true}
        isDisabled={isDisabled}
        onChange={handleInput}
        value={filterString.value === null ? undefined : filterString.value}
        width={inputWidth}
      />
      {!isAddDisabled && hasEmptyValue ? null : (
        <OrButtonWrapper>
          <Button
            isDisabled={isDisabled || isAddDisabled || hasEmptyValue}
            label='or'
            onClick={onAdd}
          />
        </OrButtonWrapper>
      )}
    </Layout>
  );
};

export default ETagFilterString;
