import ContainedText from 'components/molecules/ContainedText/ContainedText';
import { DESCRIPTION_WIDTH } from 'components/pages/LandingPage/constants';
import styled from 'styled-components';

const StyledLi = styled.li`
  font-size: 80%;
  font-weight: lighter;
  font-style: italic;
`;

interface INameAndDescriptionProps {
  description: string[];
  name: string;
}

const NameAndDescription = ({
  description,
  name,
}: INameAndDescriptionProps): JSX.Element => {
  return (
    <div>
      <div>{name}</div>
      <ul>
        {description.map((message: string, index: number) => (
          <StyledLi key={`description-${index}`}>
            <ContainedText maxWidth={`${DESCRIPTION_WIDTH}px`} text={message} />
          </StyledLi>
        ))}
      </ul>
    </div>
  );
};

export default NameAndDescription;
