import { IETagTemplate } from 'interfaces/ETag';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import { getETagTemplate } from 'utils/detail';
import { retrieveToEntityTimeZone } from 'utils/user';

const retrieveCurrentETagTemplateState = (state: TRootState) => {
  const {
    active,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    generationPhysicalSegment,
    group_name,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    message_meta_data,
    name,
    notes,
    physicalSegmentsProfiles,
    security_key,
    tag_id,
    template_id,
    test_flag,
    toEntity,
    transaction_type,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    physical_segment_loss_percentages,
    show_losses,
  } = state.detail.present;
  const timeZone: TTimeZone = retrieveToEntityTimeZone(toEntity)(state);

  return {
    active,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    generationPhysicalSegment,
    group_name,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    message_meta_data,
    name,
    notes,
    physicalSegmentsProfiles,
    security_key,
    tag_id,
    timeZone,
    template_id,
    test_flag,
    toEntity,
    transaction_type,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    physical_segment_loss_percentages,
    show_losses,
  };
};

const useGetCurrentETagTemplate = () => {
  const {
    active,
    cc_list,
    composite_state,
    contact_info,
    creator,
    description,
    generationPhysicalSegment,
    group_name,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    message_meta_data,
    name,
    notes,
    physicalSegmentsProfiles,
    security_key,
    tag_id,
    timeZone,
    template_id,
    test_flag,
    toEntity,
    transaction_type,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    physical_segment_loss_percentages,
    show_losses,
  } = useSelector(retrieveCurrentETagTemplateState);

  const getCurrentETagTemplate = useCallback(
    (): IETagTemplate =>
      getETagTemplate(
        active,
        cc_list,
        composite_state,
        contact_info,
        creator,
        description,
        generationPhysicalSegment,
        group_name,
        loadPhysicalSegment,
        lossAccountings,
        marketSegments,
        message_meta_data,
        name,
        notes,
        physicalSegmentsProfiles,
        security_key,
        tag_id,
        timeZone,
        template_id,
        test_flag,
        toEntity,
        transaction_type,
        transmission_physical_segments,
        transmissionAllocations,
        useUniqueProfiles,
        physical_segment_loss_percentages,
        show_losses,
      ),
    [
      active,
      cc_list,
      composite_state,
      contact_info,
      creator,
      description,
      generationPhysicalSegment,
      group_name,
      loadPhysicalSegment,
      lossAccountings,
      marketSegments,
      message_meta_data,
      name,
      notes,
      physicalSegmentsProfiles,
      security_key,
      tag_id,
      template_id,
      test_flag,
      timeZone,
      toEntity,
      transaction_type,
      transmission_physical_segments,
      transmissionAllocations,
      useUniqueProfiles,
      physical_segment_loss_percentages,
      show_losses,
    ],
  );

  return getCurrentETagTemplate;
};

export default useGetCurrentETagTemplate;
