import { EAlertAcknowledged } from 'enums/Alert';
import { ENoticeSeverity } from 'enums/General';
import { TNoticeId } from 'types/Notices';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { TToEntityId } from '../../types/ToEntity';

export const NOTICES_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  noticeSeverities: ENoticeSeverity[],
  acknowledgeStatus: EAlertAcknowledged,
  includeExpired: boolean,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/notices?start=${encodeURIComponent(
    start.toIsoString(),
  )}&end=${encodeURIComponent(end.toIsoString())}${
    noticeSeverities.length === 0
      ? ''
      : `&severity=${noticeSeverities.join(',')}`
  }&includeUserAcked=${encodeURIComponent(
    acknowledgeStatus === 'Y' ? true : false,
  )}&includeExpired=${encodeURIComponent(includeExpired)}`;

export const NOTICES_ACKNOWLEDGE_ROUTE = (
  toEntityId: TToEntityId,
  noticesIds: TNoticeId[],
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/notices/ack?noticeId=${noticesIds.join(',')}`;
