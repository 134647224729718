import { NO_OP_HANDLER } from 'constants/General';
import useArrayFilters, { IuseArrayFilters } from 'hooks/useArrayFilters';
import { Context, createContext, ReactNode } from 'react';
import { TETagTemplateDataSetFilter } from 'types/ETag';

export interface IETagTemplateFilteringContext
  extends IuseArrayFilters<TETagTemplateDataSetFilter> {}

export const ETagTemplateFilteringContext: Context<IETagTemplateFilteringContext> =
  createContext<IETagTemplateFilteringContext>({
    arrayFilters: {},
    clearArrayFilters: NO_OP_HANDLER,
    setFilter: NO_OP_HANDLER,
    unSetFilter: NO_OP_HANDLER,
  });

interface IETagTemplateFilteringProviderProps {
  children: ReactNode;
}

const ETagTemplateFilteringProvider = (
  props: IETagTemplateFilteringProviderProps,
) => {
  const { children } = props;
  const { arrayFilters, clearArrayFilters, setFilter, unSetFilter } =
    useArrayFilters<TETagTemplateDataSetFilter>();

  return (
    <ETagTemplateFilteringContext.Provider
      value={{ arrayFilters, clearArrayFilters, setFilter, unSetFilter }}
    >
      {children}
    </ETagTemplateFilteringContext.Provider>
  );
};

export default ETagTemplateFilteringProvider;
