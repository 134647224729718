import { IProfileIntervalGridData } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import { getColumnRender } from 'utils/views';

export const getProfileIntervalColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IProfileIntervalGridData>[] => [
  {
    dataIndex: 'startDateTime',
    render: getColumnRender(isUnconstrained),
    title: 'Start',
    width: '40%',
  },
  {
    dataIndex: 'stopDateTime',
    render: getColumnRender(isUnconstrained),
    title: 'Stop',
    width: '40%',
  },
  {
    dataIndex: 'genRequest',
    render: getColumnRender(isUnconstrained),
    title: `Gen\n \nRequest`,
    width: '20%',
  },
];
