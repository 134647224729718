import { Input as AntDesignInput } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { ISummaryStyles } from 'interfaces/Summary';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { unpackSummaryStyles } from 'utils/styles';

const InputLabel = styled.div`
  width: 15em;
`;
const StyledInput = styled(AntDesignInput)`
  width: 100%;
`;

interface ISampleStyleExhibitProps {
  summaryStyles?: ISummaryStyles;
}

const SampleStyleExhibit = styled.div<ISampleStyleExhibitProps>`
  ${(props) => unpackSummaryStyles(props.summaryStyles)}
  min-width: 8em;
  text-align: center;
`;

export interface IProps {
  isDisabled: boolean;
  onChange: (summaryStyles: ISummaryStyles) => void;
  summaryStyles?: ISummaryStyles;
}

const SummaryStylePicker = (props: IProps): JSX.Element => {
  const { isDisabled, onChange, summaryStyles } = props;
  const [summaryStylesInternal, setSummaryStylesInternal] = useState<
    ISummaryStyles | undefined
  >(undefined);
  useEffect(() => {
    setSummaryStylesInternal(summaryStyles);
  }, [summaryStyles]);
  const propagateSummaryStylesInternalChange = (
    summaryStylesChange: ISummaryStyles,
  ): void => {
    setSummaryStylesInternal(summaryStylesChange);
    onChange(summaryStylesChange);
  };
  const handleBackgroundColorChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    propagateSummaryStylesInternalChange({
      ...summaryStylesInternal,
      'background-color': event.target.value,
    });
  };
  const handleBorderChange = (event: ChangeEvent<HTMLInputElement>) => {
    propagateSummaryStylesInternalChange({
      ...summaryStylesInternal,
      border: event.target.value,
    });
  };
  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    propagateSummaryStylesInternalChange({
      ...summaryStylesInternal,
      color: event.target.value,
    });
  };
  const handleFontWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    propagateSummaryStylesInternalChange({
      ...summaryStylesInternal,
      'font-weight': event.target.value,
    });
  };
  return (
    <>
      <div>
        <SeparatedRowLayout>
          <InputLabel>Background color</InputLabel>
          <StyledInput
            disabled={isDisabled}
            placeholder='Select background color'
            value={summaryStylesInternal?.['background-color']}
            onChange={handleBackgroundColorChange}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <InputLabel>Border</InputLabel>
          <StyledInput
            disabled={isDisabled}
            placeholder='Select border'
            value={summaryStylesInternal?.border}
            onChange={handleBorderChange}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <InputLabel>Color</InputLabel>
          <StyledInput
            disabled={isDisabled}
            placeholder='Select color'
            value={summaryStylesInternal?.color}
            onChange={handleColorChange}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <InputLabel>Font weight</InputLabel>
          <StyledInput
            disabled={isDisabled}
            placeholder='Select font weight'
            value={summaryStylesInternal?.['font-weight']}
            onChange={handleFontWeightChange}
          />
        </SeparatedRowLayout>
      </div>
      <SampleStyleExhibit summaryStyles={summaryStylesInternal}>
        Sample
      </SampleStyleExhibit>
    </>
  );
};
export default SummaryStylePicker;
