import ContainedText from 'components/molecules/ContainedText/ContainedText';
import { NODE_LABEL_MAX_WIDTH_VALUE } from 'components/organisms/PathDiagramView/constants';
import { ENodePosition } from 'components/organisms/PathDiagramView/types';
import { IThemedProps } from 'interfaces/Component';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';
import { textColour } from 'utils/styles';

const BOX_OFFSET_Y_VALUE = 26;

interface ILayoutProps {
  nodeSize: number;
}

const Layout = styled.div<ILayoutProps>`
  flex-shrink: 0;
  height: ${(props) => props.nodeSize + 2 * BOX_OFFSET_Y_VALUE}px;
  position: relative;
  width: ${(props) => props.nodeSize}px;
`;

interface ILabelProps {
  nodePosition?: ENodePosition;
}

const Label = styled.div<ILabelProps>`
  position: absolute;
  top: 0px;

  ${(props) =>
    props.nodePosition === ENodePosition.Right ? 'right: 0px;' : ''}
`;

interface IBoxProps extends IThemedProps {
  nodePosition?: ENodePosition;
  nodeSize: number;
}

const Box = styled.div<IBoxProps>`
  border: 2px solid ${(props) => textColour(props)};
  height: ${(props) => props.nodeSize}px;
  position: absolute;
  top: ${BOX_OFFSET_Y_VALUE}px;
  width: ${(props) => props.nodeSize}px;

  ${(props) =>
    props.nodePosition === ENodePosition.Right ? 'right: 0px;' : ''}
`;

interface IProps {
  nodeLabel?: string | null;
  nodeLabelMaxWidth?: number;
  nodePosition: ENodePosition;
  nodeSize: number;
}

const Node = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { nodeLabel, nodeLabelMaxWidth, nodePosition, nodeSize } = props;

  return (
    <Layout nodeSize={nodeSize}>
      {isEmptyValue(nodeLabel) ? null : (
        <Label nodePosition={nodePosition}>
          <ContainedText
            maxWidth={`${
              nodeLabelMaxWidth === undefined
                ? NODE_LABEL_MAX_WIDTH_VALUE
                : nodeLabelMaxWidth
            }px`}
            text={nodeLabel!}
          />
        </Label>
      )}
      <Box
        currentTheme={currentTheme!}
        nodePosition={nodePosition}
        nodeSize={nodeSize}
      />
    </Layout>
  );
};

export default Node;
