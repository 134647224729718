import { AxiosResponse } from 'axios';
import {
  DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE,
  DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE,
} from 'constants/Summary';
import { ECompositeState, EProfileSegment } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import {
  IETagIdentifier,
  IETagPhysicalSegmentResponse,
  IETagsSummaryAttributeBatchResponse,
  IETagsSummaryAttributeIdListResponse,
  IETagsSummaryDealLinkageResponse,
  IETagSummaryAttribute,
  IETagSummaryAttributeDataSet,
  IETagSummaryAttributeRecord,
  IETagSummaryAttributeResponse,
  IETagSummaryDealLinkageDataSet,
  IETagSummaryDealLinkageResponseRecord,
  IETagSummaryProfile,
  IETagSummaryProfilesBatch,
  IETagSummaryProfilesBatchResponse,
  IETagSummaryProfilesRecord,
  IETagSummaryProfilesResponse,
} from 'interfaces/ETag';
import { IToEntity, IToEntityRecord } from 'interfaces/ToEntity';
import Redux from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  cleanUpETagsDraftTagPrimaryKeys,
  eTagIdentifiersToIdList,
  idToETagIdentifier,
  transformRetrievedETagSummaryProfiles,
} from 'reduxes/Summary/helpers';
import {
  ESummaryAction,
  IETagsSummaryAttributeRequest,
  IETagsSummaryDealLinkageRequest,
  IETagsSummaryProfilesReply,
  IETagsSummaryProfilesRequest,
  IETagSummaryAttributeReply,
  IETagSummaryProfilesBatchReply,
  IETagSummaryProfilesBatchRequest,
  IETagSummaryProfilesReply,
  IRetrieveToEntityETagsSummaryAttributeReply,
  IRetrieveToEntityETagsSummaryDealLinkageReply,
  IRetrieveToEntityETagsSummaryProfilesError,
  ISummaryActionPayload,
  IUpdateToEntityETagDraftsRequest,
  IUpdateToEntityETagsRequest,
  TETagSummaryAttributeRequest,
  TETagSummaryProfilesRequest,
  TSummaryAction,
} from 'reduxes/Summary/types';
import {
  retrieveETagDistributedPhysicalSegment,
  retrieveETagDistributedSummaryAttribute,
  retrieveETagDistributedSummaryProfiles,
} from 'services/agent/tags/distributed';
import {
  retrieveETagDraftSummaryAttribute,
  retrieveETagDraftSummaryProfiles,
} from 'services/agent/tags/drafts';
import {
  retrieveETagsSummaryAttributeBatch,
  retrieveETagsSummaryAttributeIdList,
  retrieveETagSummaryDealLinkageList,
  retrieveETagSummaryProfilesBatch,
} from 'services/agent/tags/summaryLoader';
import { TTimeZone } from 'types/DateTime';
import {
  TETagRecordKey,
  TETagSummaryAttributeMap,
  TETagSummaryDealLinkageMap,
  TETagSummaryDealLinkageRecord,
  TETagSummaryProfilesMap,
} from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TRootState } from 'types/Redux';
import { captureError } from 'utils/error';
import {
  eTagSummaryAttributeToDataSet,
  eTagSummaryDealLinkageToDataSet,
  getRecordKeyForETagIdentifier,
} from 'utils/eTag';
import { isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

// ETag Attribute

export const summaryRetrieveToEntityETagSummaryAttributeStart = (
  eTagSummaryAttributeRequest: TETagSummaryAttributeRequest,
): TSummaryAction => ({
  payload: eTagSummaryAttributeRequest,
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeStart,
});

export const summaryRetrieveToEntityETagSummaryAttributeSuccess = (
  eTagSummaryAttributeResponse: IETagSummaryAttributeReply,
): TSummaryAction => ({
  payload: eTagSummaryAttributeResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeSuccess,
});

export const summaryRetrieveToEntityETagSummaryAttributeFailure = (
  eTagSummaryAttributeResponse: IETagSummaryAttributeReply,
): TSummaryAction => ({
  payload: eTagSummaryAttributeResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeFailure,
});

export const summaryRetrieveToEntityETagSummaryAttribute = (
  eTagSummaryAttributeRequest: TETagSummaryAttributeRequest,
  start: ZonedDateTime,
  end: ZonedDateTime,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntityRecord =
      rootState.summary.toEntities[
        eTagSummaryAttributeRequest.toEntity.to_entity
      ];

    if (toEntityRecord) {
      const recordKey: TETagRecordKey = getRecordKeyForETagIdentifier(
        eTagSummaryAttributeRequest,
      );

      dispatch(
        summaryRetrieveToEntityETagSummaryAttributeStart(
          eTagSummaryAttributeRequest,
        ),
      );

      try {
        const response: AxiosResponse<IETagSummaryAttributeResponse> =
          eTagSummaryAttributeRequest.draft_id === null
            ? await retrieveETagDistributedSummaryAttribute(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryAttributeRequest.tag_primary_key,
                start,
                end,
              )
            : await retrieveETagDraftSummaryAttribute(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryAttributeRequest.draft_id,
                start,
                end,
              );

        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryAttributeRequest.toEntity.to_entity
          ];

        const eTagSummaryAttributeRecord: IETagSummaryAttributeRecord =
          toEntityRecord.eTagsSummaryAttributeMap[recordKey];

        if (
          eTagSummaryAttributeRequest.requestedAt >=
          eTagSummaryAttributeRecord.eTagSummaryAttributeLastRequestedAt
        ) {
          const eTagSummaryAttributeResponse: IETagSummaryAttributeResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagSummaryAttributeResponse.errorMessage!);
          }

          dispatch(
            summaryRetrieveToEntityETagSummaryAttributeSuccess({
              toEntity: eTagSummaryAttributeRequest.toEntity,
              recordKey,
              summaryAttributeRecord: {
                eTagSummaryAttributeDataSet: eTagSummaryAttributeToDataSet(
                  eTagSummaryAttributeResponse.response,
                ),
                eTagSummaryAttributeError: null,
                eTagSummaryAttributeRetrieving:
                  ERetreiveState.RetrievingCompleted,
                eTagSummaryAttributeLastRequestedAt:
                  eTagSummaryAttributeRequest.requestedAt,
              },
              timeZone: start.timeZone(),
            }),
          );
        }
      } catch (error: any) {
        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryAttributeRequest.toEntity.to_entity
          ];

        const eTagSummaryAttributeRecord: IETagSummaryAttributeRecord =
          toEntityRecord.eTagsSummaryAttributeMap[recordKey];

        captureError(
          error,
          `Failed to retrieve ${
            eTagSummaryAttributeRequest.draft_id === null
              ? `E-Tag with tag_primary_key: ${eTagSummaryAttributeRequest.tag_primary_key}`
              : `Draft with draft_id: ${eTagSummaryAttributeRequest.draft_id}`
          }`,
        );

        dispatch(
          summaryRetrieveToEntityETagSummaryAttributeFailure({
            toEntity: eTagSummaryAttributeRequest.toEntity,
            recordKey,
            summaryAttributeRecord: {
              ...eTagSummaryAttributeRecord,
              eTagSummaryAttributeError: error,
              eTagSummaryAttributeRetrieving: ERetreiveState.NotRetrieving,
            },
            timeZone: start.timeZone(),
          }),
        );
      }
    }
  };
};

export const summaryRemoveToEntityETagSummaryAttribute = (
  eTagSummaryAttributeRequest: TETagSummaryAttributeRequest,
): TSummaryAction => ({
  payload: eTagSummaryAttributeRequest,
  type: ESummaryAction.RemoveToEntityETagSummaryAttribute,
});

// ETags Attribute

export const summaryRetrieveToEntityETagsSummaryAttributeStart = (
  eTagsSummaryAttributeRequest: IETagsSummaryAttributeRequest,
): TSummaryAction => ({
  payload: eTagsSummaryAttributeRequest,
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeStart,
});

export const summaryRetrieveToEntityETagsSummaryAttributeSuccess = (
  retrieveToEntityETagsSummaryAttributeReply: IRetrieveToEntityETagsSummaryAttributeReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryAttributeReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeSuccess,
});

export const summaryRetrieveToEntityETagsSummaryAttributeFailure = (
  retrieveToEntityETagsSummaryAttributeReply: IRetrieveToEntityETagsSummaryAttributeReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryAttributeReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeFailure,
});

export const summaryRetrieveToEntityETagsSummaryAttribute = (
  toEntity: IToEntity,
  start: ZonedDateTime,
  end: ZonedDateTime,
  batchSize?: number,
  filterId?: TFilterId,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: Redux.Dispatch<TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const eTagsSummaryAttributeRequest: IETagsSummaryAttributeRequest = {
      requestedAt: ZonedDateTime.now(start.timeZone()),
      toEntity,
    };
    dispatch(
      summaryRetrieveToEntityETagsSummaryAttributeStart(
        eTagsSummaryAttributeRequest,
      ),
    );

    try {
      const retrieveETagsSummaryAttributeIdListResponse: AxiosResponse<IETagsSummaryAttributeIdListResponse> =
        await retrieveETagsSummaryAttributeIdList(
          toEntity.to_entity,
          start,
          end,
          filterId,
        );

      let rootState: TRootState = getState();
      let toEntityRecord: IToEntityRecord = {
        ...rootState.summary.toEntities[toEntity.to_entity],
      };

      if (
        eTagsSummaryAttributeRequest.requestedAt >=
        toEntityRecord.eTagsSummaryAttributeLastRequestedAt
      ) {
        const eTagsSummaryAttributeIdListResponse: IETagsSummaryAttributeIdListResponse =
          retrieveETagsSummaryAttributeIdListResponse.data;

        if (
          !isSuccessStatus(retrieveETagsSummaryAttributeIdListResponse.status)
        ) {
          throw new Error(eTagsSummaryAttributeIdListResponse.errorMessage!);
        }

        const newETagsSummaryAttributeMap: TETagSummaryAttributeMap = {};
        const { id_list } = eTagsSummaryAttributeIdListResponse.response;
        const loadBatchSize: number =
          batchSize === undefined
            ? DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE
            : batchSize;
        const numBatches: number = Math.ceil(id_list.length / loadBatchSize);

        const idBatchPromises: Promise<
          AxiosResponse<IETagsSummaryAttributeBatchResponse>
        >[] = [];
        for (let i: number = 0; i < numBatches; i += 1) {
          const idBatch: string[] = [];

          for (let j: number = 0; j < loadBatchSize; j += 1) {
            const idListIndex: number = i * loadBatchSize + j;

            if (idListIndex < id_list.length) {
              idBatch.push(id_list[idListIndex]);
            } else {
              break;
            }
          }
          idBatchPromises.push(
            retrieveETagsSummaryAttributeBatch(toEntity.to_entity, idBatch),
          );
        }

        const idBatches: AxiosResponse<IETagsSummaryAttributeBatchResponse>[] =
          await Promise.all(idBatchPromises);

        rootState = getState();
        toEntityRecord = {
          ...rootState.summary.toEntities[toEntity.to_entity],
        };

        if (
          eTagsSummaryAttributeRequest.requestedAt >=
          toEntityRecord.eTagsSummaryAttributeLastRequestedAt
        ) {
          for (let retrieveETagsSummaryAttributeBatchResponse of idBatches) {
            const eTagsSummaryAttributeBatchResponse: IETagsSummaryAttributeBatchResponse =
              retrieveETagsSummaryAttributeBatchResponse.data;

            if (
              !isSuccessStatus(
                retrieveETagsSummaryAttributeBatchResponse.status,
              )
            ) {
              throw new Error(eTagsSummaryAttributeBatchResponse.errorMessage!);
            }

            for (let etagSummaryAttribute of eTagsSummaryAttributeBatchResponse.response) {
              let miscInfo: string = '';
              const response: AxiosResponse<IETagPhysicalSegmentResponse> =
                await retrieveETagDistributedPhysicalSegment(
                  toEntity.to_entity,
                  etagSummaryAttribute.tag_primary_key,
                );
              const eTagPhysicalSegmentResponse: IETagPhysicalSegmentResponse =
                response.data;

              if (
                eTagPhysicalSegmentResponse.response
                  .generation_physical_segment &&
                eTagPhysicalSegmentResponse.response.generation_physical_segment
                  .misc_infos
              ) {
                const physicalSegmentMiscInfo =
                  eTagPhysicalSegmentResponse.response
                    .generation_physical_segment.misc_infos;
                miscInfo =
                  miscInfo +
                  physicalSegmentMiscInfo
                    .map((miscInfos) =>
                      miscInfos ? `${miscInfos.token}: ${miscInfos.value}` : '',
                    )
                    ?.join('|');
              }

              if (
                eTagPhysicalSegmentResponse.response.load_physical_segment &&
                eTagPhysicalSegmentResponse.response.load_physical_segment
                  .misc_infos
              ) {
                const loadSegmentMiscInfo =
                  eTagPhysicalSegmentResponse.response.load_physical_segment
                    .misc_infos;
                miscInfo =
                  miscInfo +
                  loadSegmentMiscInfo
                    .map((miscInfos) =>
                      miscInfos ? `${miscInfos.token}: ${miscInfos.value}` : '',
                    )
                    ?.join('|');
              }

              if (
                eTagPhysicalSegmentResponse.response
                  .transmission_physical_segments &&
                eTagPhysicalSegmentResponse.response
                  .transmission_physical_segments.length > 0
              ) {
                eTagPhysicalSegmentResponse.response.transmission_physical_segments.forEach(
                  (transmissionSegment) => {
                    const misc_infos = transmissionSegment.misc_infos?.map(
                      (miscInfos) =>
                        miscInfos
                          ? `${miscInfos.token}: ${miscInfos.value}`
                          : '',
                    );
                    miscInfo = misc_infos
                      ? miscInfo + misc_infos?.join('|')
                      : miscInfo;
                  },
                );
              }
              etagSummaryAttribute.misc_infos = miscInfo;
            }

            eTagsSummaryAttributeBatchResponse.response.forEach(
              (eTagSummaryAttribute: IETagSummaryAttribute) => {
                const recordKey: TETagRecordKey =
                  getRecordKeyForETagIdentifier(eTagSummaryAttribute);
                newETagsSummaryAttributeMap[recordKey] = {
                  eTagSummaryAttributeDataSet:
                    eTagSummaryAttributeToDataSet(eTagSummaryAttribute),
                  eTagSummaryAttributeError: null,
                  eTagSummaryAttributeRetrieving:
                    ERetreiveState.RetrievingCompleted,
                  eTagSummaryAttributeLastRequestedAt:
                    eTagsSummaryAttributeRequest.requestedAt,
                };

                // Track all draft ETags, so that we can remove them if we find a
                // corresponding non-draft ETag later on.
                if (
                  eTagSummaryAttribute.composite_state === ECompositeState.Draft
                ) {
                  toEntityRecord.eTagsDraftTagPrimaryKeys =
                    cleanUpETagsDraftTagPrimaryKeys(
                      recordKey,
                      toEntityRecord.eTagsDraftTagPrimaryKeys,
                    );
                  toEntityRecord.eTagsDraftTagPrimaryKeys[
                    eTagSummaryAttribute.tag_primary_key
                  ] = recordKey;
                }
              },
            );
          }
          toEntityRecord.eTagsSummaryAttributeMap = newETagsSummaryAttributeMap;
        }

        toEntityRecord.eTagsSummaryAttributeRetrieving =
          ERetreiveState.RetrievingCompleted;

        dispatch(
          summaryRetrieveToEntityETagsSummaryAttributeSuccess({
            toEntity,
            toEntityRecord,
          }),
        );
      }
    } catch (error: any) {
      captureError(error);

      const rootState: TRootState = getState();

      dispatch(
        summaryRetrieveToEntityETagsSummaryAttributeFailure({
          toEntity,
          toEntityRecord: {
            ...rootState.summary.toEntities[toEntity.to_entity],
            eTagsSummaryAttributeError: error,
            eTagsSummaryAttributeRetrieving: ERetreiveState.NotRetrieving,
          },
        }),
      );
    }
  };
};

// ETags Deal Linkage

export const summaryRetrieveToEntityETagsSummaryDealLinkageStart = (
  eTagsSummaryDealLinkageRequest: IETagsSummaryDealLinkageRequest,
): TSummaryAction => ({
  payload: eTagsSummaryDealLinkageRequest,
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageStart,
});

export const summaryRetrieveToEntityETagsSummaryDealLinkageSuccess = (
  retrieveToEntityETagsSummaryDealLinkageReply: IRetrieveToEntityETagsSummaryDealLinkageReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryDealLinkageReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageSuccess,
});

export const summaryRetrieveToEntityETagsSummaryDealLinkageFailure = (
  retrieveToEntityETagsSummaryDealLinkageReply: IRetrieveToEntityETagsSummaryDealLinkageReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryDealLinkageReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageFailure,
});

// Deals
export const summaryRetrieveToEntityETagsSummaryDealLinkage = (
  toEntity: IToEntity,
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: TTimeZone,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: Redux.Dispatch<TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const eTagsSummaryDealLinkageRequest: IETagsSummaryDealLinkageRequest = {
      requestedAt: ZonedDateTime.now(start.timeZone()),
      toEntity,
    };

    dispatch(
      summaryRetrieveToEntityETagsSummaryDealLinkageStart(
        eTagsSummaryDealLinkageRequest,
      ),
    );

    try {
      let rootState: TRootState = getState();
      let toEntityRecord: IToEntityRecord = {
        ...rootState.summary.toEntities[toEntity.to_entity],
      };

      if (
        eTagsSummaryDealLinkageRequest.requestedAt >=
        toEntityRecord.eTagsSummaryDealLinkageLastRequestedAt
      ) {
        const retrieveETagSummaryDealLinkageListResponse: AxiosResponse<IETagsSummaryDealLinkageResponse> =
          await retrieveETagSummaryDealLinkageList(
            toEntity.to_entity,
            start,
            end,
            timeZone,
          );
        const eTagsSummaryDealLinkageListResponse: IETagsSummaryDealLinkageResponse =
          retrieveETagSummaryDealLinkageListResponse.data;

        if (
          !isSuccessStatus(retrieveETagSummaryDealLinkageListResponse.status)
        ) {
          throw new Error(eTagsSummaryDealLinkageListResponse.errorMessage!);
        }

        const eTagsSummaryDealLinkageData: TETagSummaryDealLinkageRecord =
          eTagsSummaryDealLinkageListResponse.response;

        const updatedETagsSummaryDealLinkageMap: TETagSummaryDealLinkageMap =
          {};

        Object.keys(eTagsSummaryDealLinkageData).forEach(
          (recordKey: string): void => {
            const linkageRecords: IETagSummaryDealLinkageResponseRecord[] =
              eTagsSummaryDealLinkageData[recordKey];
            if (linkageRecords) {
              const linkageDataSets: IETagSummaryDealLinkageDataSet[] =
                linkageRecords.map(
                  (linkageRecord: IETagSummaryDealLinkageResponseRecord) =>
                    eTagSummaryDealLinkageToDataSet(linkageRecord),
                );
              updatedETagsSummaryDealLinkageMap[recordKey] = {
                eTagSummaryDealLinkageDataSets: linkageDataSets,
                eTagSummaryDealLinkageError: null,
                eTagSummaryDealLinkageRetrieving:
                  ERetreiveState.RetrievingCompleted,
                eTagSummaryDealLinkageLastRequestedAt:
                  eTagsSummaryDealLinkageRequest.requestedAt,
              };
            }
          },
        );

        rootState = getState();
        toEntityRecord = {
          ...rootState.summary.toEntities[toEntity.to_entity],
        };
        toEntityRecord.eTagsSummaryDealLinkageMap =
          updatedETagsSummaryDealLinkageMap;

        toEntityRecord.eTagsSummaryDealLinkageRetrieving =
          ERetreiveState.RetrievingCompleted;

        dispatch(
          summaryRetrieveToEntityETagsSummaryDealLinkageSuccess({
            toEntity,
            toEntityRecord,
          }),
        );
      }
    } catch (error: any) {
      captureError(error);

      const rootState: TRootState = getState();

      dispatch(
        summaryRetrieveToEntityETagsSummaryDealLinkageFailure({
          toEntity,
          toEntityRecord: {
            ...rootState.summary.toEntities[toEntity.to_entity],
            eTagsSummaryDealLinkageError: error,
            eTagsSummaryDealLinkageRetrieving: ERetreiveState.NotRetrieving,
          },
        }),
      );
    }
  };
};

// ETag Profiles

export const summaryRetrieveToEntityETagSummaryProfilesStart = (
  eTagSummaryProfileRequest: TETagSummaryProfilesRequest,
): TSummaryAction => ({
  payload: eTagSummaryProfileRequest,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesStart,
});

export const summaryRetrieveToEntityETagSummaryProfilesSuccess = (
  eTagSummaryProfileResponse: IETagSummaryProfilesReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesSuccess,
});

export const summaryRetrieveToEntityETagSummaryProfilesFailure = (
  eTagSummaryProfileResponse: IETagSummaryProfilesReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesFailure,
});

export const summaryRetrieveToEntityETagSummaryProfiles = (
  eTagSummaryProfilesRequest: TETagSummaryProfilesRequest,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntityRecord =
      rootState.summary.toEntities[
        eTagSummaryProfilesRequest.toEntity.to_entity
      ];

    if (toEntityRecord) {
      const recordKey: TETagRecordKey = getRecordKeyForETagIdentifier(
        eTagSummaryProfilesRequest,
      );
      let eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
        toEntityRecord.eTagsSummaryProfilesMap[recordKey];

      dispatch(
        summaryRetrieveToEntityETagSummaryProfilesStart(
          eTagSummaryProfilesRequest,
        ),
      );

      try {
        const response: AxiosResponse<IETagSummaryProfilesResponse> =
          eTagSummaryProfilesRequest.draft_id === null
            ? await retrieveETagDistributedSummaryProfiles(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryProfilesRequest.tag_primary_key,
                timeZone,
                start,
                end,
                profileSegment,
              )
            : await retrieveETagDraftSummaryProfiles(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryProfilesRequest.draft_id,
                timeZone,
                start,
                end,
                profileSegment,
              );

        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryProfilesRequest.toEntity.to_entity
          ];

        eTagProfileRecord = toEntityRecord.eTagsSummaryProfilesMap[recordKey];

        if (eTagProfileRecord === undefined) {
          throw new Error(
            `Missing eTagProfileRecord for recordKey: ${recordKey}`,
          );
        } else if (
          eTagProfileRecord.eTagSummaryProfilesLastRequestedAt === undefined ||
          eTagSummaryProfilesRequest.requestedAt >=
            eTagProfileRecord.eTagSummaryProfilesLastRequestedAt
        ) {
          const eTagSummaryProfilesResponse: IETagSummaryProfilesResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagSummaryProfilesResponse.errorMessage!);
          }

          dispatch(
            summaryRetrieveToEntityETagSummaryProfilesSuccess({
              toEntity: eTagSummaryProfilesRequest.toEntity,
              recordKey,
              summaryProfileRecord: {
                eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
                  eTagSummaryProfilesResponse.response,
                  eTagProfileRecord.eTagSummaryProfiles,
                  timeZone,
                ),
                eTagSummaryProfilesError: null,
                eTagSummaryProfilesRetrieving:
                  ERetreiveState.RetrievingCompleted,
                eTagSummaryProfilesLastRequestedAt:
                  eTagSummaryProfilesRequest.requestedAt,
              },
            }),
          );
        }
      } catch (error: any) {
        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryProfilesRequest.toEntity.to_entity
          ];

        const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
          toEntityRecord.eTagsSummaryProfilesMap[recordKey];

        captureError(
          error,
          `Failed to retrieve Summary Profiles for ${
            eTagSummaryProfilesRequest.draft_id === null
              ? `tag_primary_key: ${eTagSummaryProfilesRequest.tag_primary_key}`
              : `draft_id: ${eTagSummaryProfilesRequest.draft_id}`
          }`,
        );

        dispatch(
          summaryRetrieveToEntityETagSummaryProfilesFailure({
            toEntity: eTagSummaryProfilesRequest.toEntity,
            recordKey,
            summaryProfileRecord:
              eTagProfileRecord === undefined
                ? {
                    eTagSummaryProfiles: [],
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  }
                : {
                    ...eTagProfileRecord,
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  },
          }),
        );
      }
    }
  };
};

export const summaryRemoveToEntityETagSummaryProfiles = (
  eTagSummaryProfilesRequest: TETagSummaryProfilesRequest,
): TSummaryAction => ({
  payload: eTagSummaryProfilesRequest,
  type: ESummaryAction.RemoveToEntityETagSummaryProfiles,
});

// ETag Profiles Batch

export const summaryRetrieveToEntityETagSummaryProfilesBatchStart = (
  eTagSummaryProfilesBatchRequest: IETagSummaryProfilesBatchRequest,
): TSummaryAction => ({
  payload: eTagSummaryProfilesBatchRequest,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchStart,
});

export const summaryRetrieveToEntityETagSummaryProfilesBatchSuccess = (
  eTagSummaryProfileBatchResponse: IETagSummaryProfilesBatchReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileBatchResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchSuccess,
});

export const summaryRetrieveToEntityETagSummaryProfilesBatchFailure = (
  eTagSummaryProfileBatchResponse: IETagSummaryProfilesBatchReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileBatchResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchFailure,
});

export const summaryRetrieveToEntityETagSummaryProfilesBatch = (
  eTagSummaryProfilesBatchRequest: IETagSummaryProfilesBatchRequest,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const { eTagIdentifiers, requestedAt, toEntity } =
      eTagSummaryProfilesBatchRequest;
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntityRecord | undefined =
      rootState.summary.toEntities[toEntity.to_entity];

    if (toEntityRecord === undefined) {
      throw new Error(
        `Missing toEntityRecord for toEntityId: ${toEntity.to_entity}`,
      );
    }

    if (eTagIdentifiers.length > 0) {
      dispatch(
        summaryRetrieveToEntityETagSummaryProfilesBatchStart(
          eTagSummaryProfilesBatchRequest,
        ),
      );

      try {
        const retrieveETagSummaryProfilesBatchResponse: AxiosResponse<IETagSummaryProfilesBatchResponse> =
          await retrieveETagSummaryProfilesBatch(
            toEntity.to_entity,
            eTagIdentifiersToIdList(eTagIdentifiers),
            timeZone,
            start,
            end,
            profileSegment,
          );

        rootState = getState();
        toEntityRecord = rootState.summary.toEntities[toEntity.to_entity];

        if (toEntityRecord === undefined) {
          throw new Error(
            `Missing toEntityRecord for toEntityId: ${toEntity.to_entity}`,
          );
        } else {
          // Only need to grab the first record of a batch to see when it was
          // last requested
          const eTagSummaryProfilesRecord:
            | IETagSummaryProfilesRecord
            | undefined =
            toEntityRecord.eTagsSummaryProfilesMap[
              getRecordKeyForETagIdentifier(eTagIdentifiers[0])
            ];

          if (
            eTagSummaryProfilesRecord === undefined ||
            eTagSummaryProfilesRecord.eTagSummaryProfilesLastRequestedAt ===
              undefined ||
            requestedAt >=
              eTagSummaryProfilesRecord.eTagSummaryProfilesLastRequestedAt
          ) {
            const eTagSummaryProfilesBatchResponse: IETagSummaryProfilesBatchResponse =
              retrieveETagSummaryProfilesBatchResponse.data;

            if (
              !isSuccessStatus(retrieveETagSummaryProfilesBatchResponse.status)
            ) {
              throw new Error(eTagSummaryProfilesBatchResponse.errorMessage!);
            }

            const eTagSummaryProfilesMap: TETagSummaryProfilesMap = {};
            const eTagSummaryProfilesBatch: IETagSummaryProfilesBatch =
              eTagSummaryProfilesBatchResponse.response;
            const { erroredIds, notFoundIds, summaryProfiles } =
              eTagSummaryProfilesBatch;

            Object.keys(summaryProfiles).forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];
              const eTagSummaryProfiles: IETagSummaryProfile[] =
                summaryProfiles[id];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
                  eTagSummaryProfiles,
                  eTagProfileRecord === undefined
                    ? []
                    : eTagProfileRecord.eTagSummaryProfiles,
                  timeZone,
                ),
                eTagSummaryProfilesError: null,
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving:
                  ERetreiveState.RetrievingCompleted,
              };
            });

            notFoundIds.forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: [],
                eTagSummaryProfilesError: 'Not Found',
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
              };

              captureError(
                new Error(
                  `Summary profiles not found for ${
                    eTagIdentifier.draft_id === null
                      ? `tag_primary_key: ${eTagIdentifier.tag_primary_key}`
                      : `draft_id: ${eTagIdentifier.draft_id}`
                  }`,
                ),
              );
            });

            erroredIds.forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: [],
                eTagSummaryProfilesError: 'Errored',
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
              };

              captureError(
                new Error(
                  `Failed to load summary profiles for ${
                    eTagIdentifier.draft_id === null
                      ? `tag_primary_key: ${eTagIdentifier.tag_primary_key}`
                      : `draft_id: ${eTagIdentifier.draft_id}`
                  }`,
                ),
              );
            });

            dispatch(
              summaryRetrieveToEntityETagSummaryProfilesBatchSuccess({
                eTagSummaryProfilesMap,
                toEntity,
              }),
            );
          }
        }
      } catch (error: any) {
        captureError(
          error,
          `Failed to retrieve summary profiles batch: ${JSON.stringify(
            eTagSummaryProfilesBatchRequest,
          )}`,
        );

        rootState = getState();
        toEntityRecord = rootState.summary.toEntities[toEntity.to_entity];

        if (toEntityRecord !== undefined) {
          const eTagSummaryProfilesMap: TETagSummaryProfilesMap = {};

          eTagIdentifiers.forEach((eTagIdentifier: IETagIdentifier) => {
            const recordKey: TETagRecordKey =
              getRecordKeyForETagIdentifier(eTagIdentifier);
            const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
              toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

            eTagSummaryProfilesMap[recordKey] =
              eTagProfileRecord === undefined
                ? {
                    eTagSummaryProfiles: [],
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  }
                : {
                    ...eTagProfileRecord,
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  };
          });

          dispatch(
            summaryRetrieveToEntityETagSummaryProfilesBatchFailure({
              error,
              eTagSummaryProfilesMap,
              toEntity,
            }),
          );
        }
      }
    }
  };
};

// ETags Profiles

export const summaryRetrieveToEntityETagsSummaryProfilesStart = (
  eTagsSummaryProfilesRequest: IETagsSummaryProfilesRequest,
): TSummaryAction => ({
  payload: eTagsSummaryProfilesRequest,
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesStart,
});

export const summaryRetrieveToEntityETagsSummaryProfilesSuccess = (
  summaryActionPayload: ISummaryActionPayload,
): TSummaryAction => ({
  payload: summaryActionPayload,
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesSuccess,
});

export const summaryRetrieveToEntityETagsSummaryProfilesFailure = (
  eTagsProfilesError: IRetrieveToEntityETagsSummaryProfilesError,
): TSummaryAction => ({
  payload: eTagsProfilesError,
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesFailure,
});

export const summaryRetrieveToEntityETagsSummaryProfiles = (
  toEntity: IToEntity,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
  batchSize?: number,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const rootState: TRootState = getState();
    const toEntityRecord: IToEntityRecord =
      rootState.summary.toEntities[toEntity.to_entity];

    if (toEntityRecord !== undefined) {
      const eTagsSummaryProfilesRequest: IETagsSummaryProfilesRequest = {
        toEntity,
        loadCount: Object.keys(toEntityRecord.eTagsSummaryAttributeMap).length,
      };

      dispatch(
        summaryRetrieveToEntityETagsSummaryProfilesStart(
          eTagsSummaryProfilesRequest,
        ),
      );

      try {
        const eTagsSummaryProfilesReply: IETagsSummaryProfilesReply = {
          toEntity,
        };

        const eTagSummaryAttributeRecords: IETagSummaryAttributeRecord[] =
          Object.values(toEntityRecord.eTagsSummaryAttributeMap).filter(
            (
              eTagSummaryAttributeRecord: IETagSummaryAttributeRecord,
            ): boolean =>
              eTagSummaryAttributeRecord.eTagSummaryAttributeDataSet !==
              undefined,
          );

        if (eTagSummaryAttributeRecords.length === 0) {
          eTagsSummaryProfilesReply.isCompleted = true;
        } else {
          const eTagSummaryAttributeDataSets: IETagSummaryAttributeDataSet[] =
            eTagSummaryAttributeRecords.map(
              (
                eTagSummaryAttributeRecord: IETagSummaryAttributeRecord,
              ): IETagSummaryAttributeDataSet =>
                eTagSummaryAttributeRecord.eTagSummaryAttributeDataSet!,
            );
          const loadBatchSize: number =
            batchSize === undefined
              ? DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE
              : batchSize;
          const numBatches: number = Math.ceil(
            eTagSummaryAttributeDataSets.length / loadBatchSize,
          );

          for (let i: number = 0; i < numBatches; i += 1) {
            const eTagIdentifiers: IETagIdentifier[] = [];

            for (let j: number = 0; j < loadBatchSize; j += 1) {
              const eTagIdentifiersIndex: number = i * loadBatchSize + j;

              if (eTagIdentifiersIndex < eTagSummaryAttributeDataSets.length) {
                eTagIdentifiers.push(
                  eTagSummaryAttributeDataSets[eTagIdentifiersIndex],
                );
              } else {
                break;
              }
            }

            const summaryProfilesBatchRequest: IETagSummaryProfilesBatchRequest =
              {
                eTagIdentifiers,
                requestedAt: ZonedDateTime.now(timeZone),
                toEntity,
              };

            dispatch(
              summaryRetrieveToEntityETagSummaryProfilesBatch(
                summaryProfilesBatchRequest,
                timeZone,
                start,
                end,
                profileSegment,
              ),
            );
          }
        }

        dispatch(
          summaryRetrieveToEntityETagsSummaryProfilesSuccess(
            eTagsSummaryProfilesReply,
          ),
        );
      } catch (error: any) {
        captureError(error);

        const eTagsProfilesError: IRetrieveToEntityETagsSummaryProfilesError = {
          toEntity,
          error,
        };

        dispatch(
          summaryRetrieveToEntityETagsSummaryProfilesFailure(
            eTagsProfilesError,
          ),
        );
      }
    }
  };
};

// ETag

export const summaryUpdateToEntityETags = (
  updateToEntityETagsRequest: IUpdateToEntityETagsRequest,
): TSummaryAction => ({
  payload: updateToEntityETagsRequest,
  type: ESummaryAction.UpdateToEntityETags,
});

export const summaryUpdateToEntityETagDrafts = (
  updateToEntityETagDraftsRequest: IUpdateToEntityETagDraftsRequest,
): TSummaryAction => ({
  payload: updateToEntityETagDraftsRequest,
  type: ESummaryAction.UpdateToEntityETagDrafts,
});
