import { AxiosResponse } from 'axios';
import DetailView from 'components/molecules/DetailView/DetailView';
import RequestActionSetState from 'components/molecules/RequestActionSetState/RequestActionSetState';
import RequestActionWithdraw from 'components/molecules/RequestActionWithdraw/RequestActionWithdraw';
import ApprovalHistory from 'components/organisms/ApprovalHistory/ApprovalHistory';
import RequestsInformationDataTable from 'components/organisms/RequestsInformationDataTable/RequestsInformationDataTable';
import { getNoteOptionsByStatus } from 'components/organisms/RequestsInformationView/helpers';
import { DEFAULT_REASONS, STATUS_KEYS } from 'constants/Approval';
import { EApprovalStatus } from 'enums/Approval';
import { EDistributedTagItem, ERequestType } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EViewMode, EViewResize } from 'enums/View';
import usePermissions from 'hooks/usePermissions';
import { IETagReasons, IETagReasonsResponse } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { IViewProps } from 'interfaces/View';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IDetailTransactionStatuses } from 'reduxes/Detail/types';
import { retrieveReasons } from 'services/approval/approval';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import useAsyncEffect from 'use-async-effect';
import { getRequestIdFromRequestKey, getRequestKey } from 'utils/detail';
import { parseSecurityKeyFromFullTagPrimaryKey } from 'utils/eTag';
import {
  encodeIds,
  isSuccessStatus,
  shallowObjectCompare,
} from 'utils/general';
import {
  getDetailToEntityUserSelectedTimeZone,
  getUserContactInfo,
} from 'utils/user';

const retrieveRequestsInformationViewState = (state: TRootState) => {
  const {
    approval_right,
    isDetailDeleted,
    retrievingDetail,
    retrievingDistributedTagItems,
    security_key,
    selectedRequestKey,
    tag_id,
    tag_primary_key,
    toEntity,
    transactionStatuses,
    updatingDetail,
    viewMode,
  } = state.detail.present;
  const contactInfo: IContactInfo | undefined = getUserContactInfo(state);
  const isTransactionStatusesLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.TransactionStatuses]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.TransactionStatuses]
      .retrieveState !== ERetreiveState.RetrievingCompleted;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    isTransactionStatusesLoading;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    approval_right,
    contactInfo,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    security_key,
    selectedRequestKey,
    tag_id,
    tag_primary_key,
    timeZone,
    toEntity,
    transactionStatuses,
    viewMode,
  };
};

const RequestsInformationView = ({
  encodedPermissionsId,
  layoutGrid,
  resize,
  viewId,
}: IViewProps): JSX.Element => {
  const {
    approval_right,
    contactInfo,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    security_key,
    selectedRequestKey,
    tag_id,
    tag_primary_key,
    timeZone,
    toEntity,
    transactionStatuses,
    viewMode,
  } = useSelector(retrieveRequestsInformationViewState, shallowObjectCompare);

  const newTagTransactionStatus =
    transactionStatuses &&
    transactionStatuses.length > 0 &&
    transactionStatuses.find(
      (item) => item.ui_transaction_message_type === ERequestType.NewTag,
    );
  const newTagApprovalRights =
    newTagTransactionStatus && newTagTransactionStatus.approval_rights;

  const [noteOptions, setNoteOptions] = useState<IETagReasons>(DEFAULT_REASONS);

  const securityKey: string | undefined = useMemo(
    () =>
      security_key ?? parseSecurityKeyFromFullTagPrimaryKey(tag_primary_key),
    [tag_primary_key, security_key],
  );

  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);
  const [tagHasCorrections, setTagHasCorrections] = useState<boolean>(false);

  const showApprovalHistoryButton = (
    toEntityParam: IToEntity | null,
  ): toEntityParam is IToEntity =>
    (viewMode === EViewMode.ReviewETag ||
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection) &&
    toEntity !== null;

  const showWithdrawRequestButton = (
    toEntityParam: IToEntity | null,
  ): toEntityParam is IToEntity =>
    (viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection) &&
    toEntity !== null;

  const showSetStateButtons = (
    toEntityParam: IToEntity | null,
  ): toEntityParam is IToEntity =>
    (viewMode === EViewMode.ReviewETag ||
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF ||
      viewMode === EViewMode.EditETagCorrection) &&
    toEntity !== null;

  const selectedTransactionStatus: IDetailTransactionStatuses | undefined =
    transactionStatuses.find(
      (transactionStatus: IDetailTransactionStatuses): boolean =>
        getRequestKey(transactionStatus) === selectedRequestKey,
    );

  useEffect(() => {
    if (transactionStatuses && transactionStatuses.length > 0) {
      const tagCorrection = transactionStatuses.find(
        (item) => item.ui_transaction_message_type === ERequestType.Correction,
      );
      setTagHasCorrections(!!tagCorrection);
    }
  }, [transactionStatuses]);

  const requestId: number = getRequestIdFromRequestKey(selectedRequestKey);

  const reasonsPermissions = usePermissions(
    encodeIds(
      [encodedPermissionsId, 'requestActionSetState'],
      toEntity?.to_entity,
    ),
  );

  useAsyncEffect(async () => {
    if (
      toEntity !== null &&
      showSetStateButtons(toEntity) &&
      reasonsPermissions.isExecutable
    ) {
      try {
        const retrieveReasonsResponse: AxiosResponse<IETagReasonsResponse> =
          await retrieveReasons(toEntity.to_entity);

        const eTagReasonsResponse: IETagReasonsResponse =
          retrieveReasonsResponse.data;

        if (!isSuccessStatus(retrieveReasonsResponse.status)) {
          throw new Error(eTagReasonsResponse.errorMessage!);
        }

        setNoteOptions(eTagReasonsResponse.response as IETagReasons);
      } catch (error: any) {
        setNoteOptions(DEFAULT_REASONS);
      }
    }
  }, [toEntity, isPanelVisible]);

  return (
    <DetailView
      className='requests-information-view'
      isLoading={isDetailLoading}
      layoutGrid={layoutGrid}
      resize={resize}
      rightActions={
        <>
          {showWithdrawRequestButton(toEntity) &&
          securityKey !== undefined &&
          tag_id !== null ? (
            <RequestActionWithdraw
              defaultContactInfo={contactInfo}
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'eTagWithdrawRequest',
              ])}
              isDisabled={
                isDetailDeleted || isDetailLoading || isDetailUpdating
              }
              requestId={requestId}
              securityKey={securityKey}
              tagId={tag_id}
              timeZone={timeZone}
              toEntityId={toEntity.to_entity}
              transactionStatus={selectedTransactionStatus}
            />
          ) : undefined}
          {showSetStateButtons(toEntity) ? (
            <>
              <RequestActionSetState
                approvalRight={
                  tagHasCorrections
                    ? newTagApprovalRights || null
                    : approval_right
                }
                approvalStatus={EApprovalStatus.Approved}
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'requestActionSetState'],
                  toEntity.to_entity,
                )}
                noteOptions={getNoteOptionsByStatus(
                  noteOptions[STATUS_KEYS[EApprovalStatus.Approved]],
                )}
                onPanelVisibleChange={setIsPanelVisible}
                requestId={requestId}
                tagPrimaryKey={tag_primary_key}
                timeZone={timeZone}
                title={`Approve Request ${requestId}`}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
              <RequestActionSetState
                approvalRight={
                  tagHasCorrections
                    ? newTagApprovalRights || null
                    : approval_right
                }
                approvalStatus={EApprovalStatus.Denied}
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'requestActionSetState'],
                  toEntity.to_entity,
                )}
                noteOptions={getNoteOptionsByStatus(
                  noteOptions[STATUS_KEYS[EApprovalStatus.Denied]],
                )}
                onPanelVisibleChange={setIsPanelVisible}
                requestId={requestId}
                tagPrimaryKey={tag_primary_key}
                timeZone={timeZone}
                title={`Deny Request ${requestId}`}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
              <RequestActionSetState
                approvalRight={
                  tagHasCorrections
                    ? newTagApprovalRights || null
                    : approval_right
                }
                approvalStatus={EApprovalStatus.Study}
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'requestActionSetState'],
                  toEntity.to_entity,
                )}
                noteOptions={getNoteOptionsByStatus(
                  noteOptions[STATUS_KEYS[EApprovalStatus.Study]],
                )}
                onPanelVisibleChange={setIsPanelVisible}
                requestId={requestId}
                tagPrimaryKey={tag_primary_key}
                timeZone={timeZone}
                title={`Study Request ${requestId}`}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
            </>
          ) : null}
          {showApprovalHistoryButton(toEntity) ? (
            <ApprovalHistory
              encodedPermissionsId={encodeIds(
                [encodedPermissionsId, 'eTagApprovalHistory'],
                toEntity.to_entity,
              )}
              id='approvalHistory'
              tagPrimaryKey={tag_primary_key}
              timeZone={timeZone}
              toEntityId={toEntity.to_entity}
            />
          ) : null}
        </>
      }
      title='Requests Information'
      viewId={viewId}
      viewResizeSetting={EViewResize.Initial}
    >
      <RequestsInformationDataTable
        isUnconstrained={false}
        selectedRequestKey={selectedRequestKey}
        tag_primary_key={tag_primary_key}
        toEntity={toEntity}
        timeZone={timeZone}
        transactionStatuses={transactionStatuses}
      />
    </DetailView>
  );
};

export default RequestsInformationView;
