import { EDatetimeFormat } from 'enums/Time';
import { TTimeZone } from 'types/DateTime';

export const timeZoneParams = (
  prefix: string,
  timeZone: TTimeZone | undefined,
): string =>
  timeZone === undefined
    ? ''
    : `${prefix}timeZone=${encodeURIComponent(
        timeZone,
      )}&datetimeFormat=${encodeURIComponent(
        EDatetimeFormat.GeneralDateTime24Hr,
      )}`;
