import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

const HiddenDiv = styled.div`
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  visibility: hidden;
  z-index: -1;
`;

interface IContainerProps {
  children?: ReactNode;
  onResize: (width?: number, height?: number) => void;
}

const Container = ({ children, onResize }: IContainerProps): JSX.Element => {
  return (
    <HiddenDiv>
      <ResizeDetector onResize={onResize} />
      {children}
    </HiddenDiv>
  );
};

interface IMeasureContentProps {
  children?: ReactNode;
  onResize: (width?: number, height?: number) => void;
}

const MeasureContent = (props: IMeasureContentProps): JSX.Element | null => {
  const { children, onResize } = props;
  const measureRootRef = useRef<HTMLElement | null>(
    document.getElementById('measure-root'),
  );
  const divElementRef = useRef<HTMLDivElement | null>(null);
  const [, setRenderToggle] = useState<boolean>(false);

  useEffect(
    () => () => {
      if (measureRootRef.current !== null) {
        if (divElementRef.current !== null) {
          measureRootRef.current.removeChild(divElementRef.current);
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (measureRootRef.current !== null) {
      if (children !== null) {
        if (divElementRef.current !== null) {
          measureRootRef.current.removeChild(divElementRef.current);
        }
        divElementRef.current = document.createElement('div');
        measureRootRef.current.appendChild(divElementRef.current);
        setRenderToggle(
          (previousRenderToggle: boolean) => !previousRenderToggle,
        );
      }
    }
  }, [children]);

  if (measureRootRef.current === null || divElementRef.current === null) {
    return null;
  }

  return createPortal(
    <Container onResize={onResize}>{children}</Container>,
    divElementRef.current,
  );
};

export default MeasureContent;
