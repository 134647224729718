export enum ERetreiveState {
  NotRetrieving = 'NOT_RETRIEVING',
  RetrievingStarted = 'RETRIEVING_STARTED',
  RetrievingMore = 'RETRIEVING_MORE',
  RetrievingCompleted = 'RETRIEVING_COMPLETED',
}

export enum EUpdateState {
  NotUpdating = 'NOT_UPDATING',
  UpdateStarted = 'UPDATE_STARTED',
  UpdateCompleted = 'UPDATE_COMPLETED',
}

export enum EHttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum EActionState {
  NoAction = 'NO_ACTION',
  Actioning = 'ACTIONING',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
}

export enum ERegistryType {
  BA = 'BA',
  MO = 'MO',
  PORPOD = 'PORPOD',
  PSE = 'PSE',
  RC = 'RC',
  SourceSink = 'SourceSink',
  TSP = 'TSP',
}

export enum ESeverity {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export enum ENoticeSeverity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum EServiceType {
  Agent = 'Agent',
  Approval = 'Approval',
  Authority = 'Authority',
}

export enum EMiscInfosExpandedColumn {
  MiscInfos = 'miscInfos',
  None = 'none',
}

export enum EExternalLinks {
  UserGuide = 'userGuide',
  SummaryGuide = 'summaryGuide',
  TemplateGuide = 'templateGuide',
  DetailGuide = 'detailGuide',
}
