import { RightCircleOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { useEffect } from 'react';
import styled from 'styled-components';
import { isControlShortcut } from 'utils/general';

const RedoIcon = styled(RightCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IRedoButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
}

const RedoButton = ({ isDisabled, onClick }: IRedoButtonProps): JSX.Element => {
  useEffect(() => {
    const keydownHandler = (keyboardEvent: KeyboardEvent) => {
      if (isControlShortcut(keyboardEvent, 'y')) {
        keyboardEvent.preventDefault();

        onClick?.();
      }
    };

    window.addEventListener('keydown', keydownHandler);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [onClick]);

  return (
    <Tooltip isDisabled={isDisabled} title='Redo'>
      <IconButton
        icon={<RedoIcon />}
        isDisabled={isDisabled}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default RedoButton;
