import { Table as AntDesignTable } from 'antd';
import {
  ExpandableConfig,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { TableComponents } from 'rc-table/lib/interface';
import { HTMLAttributes, ReactNode } from 'react';

export type TDataTableKey = number | string;

export interface IRowSelection<D> {
  onChange?: (selectedRowKeys: TDataTableKey[], selectedRows: D[]) => void;
  selectedRowKeys: TDataTableKey[];
}

export interface IScrollConfig {
  x?: string | number | true;
  y?: string | number;
}

export interface IDataTableProps<C, D> {
  className?: string;
  columns: C[];
  components?: TableComponents<D>;
  data: D[];
  expandable?: ExpandableConfig<D>;
  hasBorders?: boolean;
  isLoading?: boolean;
  onRow?: (record: D, index?: number) => HTMLAttributes<HTMLElement>;
  pagination?: false | TablePaginationConfig;
  rowClassName?: (record: D, index?: number) => string;
  rowKey?: string | ((record: D) => string);
  rowSelection?: IRowSelection<D>;
  scrollConfig?: IScrollConfig;
  summary?: (data: readonly D[]) => ReactNode;
  tableLayout?: 'auto' | 'fixed';
}

const DataTable = <C extends object, D extends object>(
  props: IDataTableProps<C, D>,
): JSX.Element => {
  const {
    className,
    columns,
    components,
    data,
    expandable,
    hasBorders,
    isLoading,
    onRow,
    pagination,
    rowClassName,
    rowKey,
    rowSelection,
    scrollConfig,
    summary,
    tableLayout,
  } = props;

  return (
    <AntDesignTable
      bordered={hasBorders}
      className={className}
      columns={columns}
      components={components}
      dataSource={data}
      expandable={expandable}
      loading={isLoading}
      onRow={onRow}
      pagination={pagination}
      rowKey={rowKey}
      rowClassName={rowClassName}
      rowSelection={rowSelection}
      scroll={scrollConfig}
      summary={summary}
      tableLayout={tableLayout}
    />
  );
};

export default DataTable;
