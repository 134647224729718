import {
  BOOLEAN_CUSTOM_FILTER_ATTRIBUTES,
  ENUMERATION_CUSTOM_FILTER_ATTRIBUTES,
  ENUMERATION_LIST_CUSTOM_FILTER_ATTRIBUTES,
  MISC_INFO_CUSTOM_FILTER_ATTRIBUTES,
  MISC_INFO_LIST_CUSTOM_FILTER_ATTRIBUTES,
  STRING_CUSTOM_FILTER_ATTRIBUTES,
  STRING_LIST_CUSTOM_FILTER_ATTRIBUTES,
  STRING_LIST_ENHANCED_CUSTOM_FILTER_ATTRIBUTES,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/constants';
import { IAttributeValues } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterCriteria/types';
import {
  isMiscInfoMarketSegmentAttribute,
  isMiscInfoPhysicalSegmentAttribute,
  isMiscInfoTransAllocAttribute,
  isOperand,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/helpers';
import {
  EAttribute,
  EList,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { ETAG_FILTER_ENUM_OPTIONS_MAP } from 'constants/ETag';
import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import { ECustomFilterAttribute, ECustomFilterType } from 'enums/Filter';
import { IOption } from 'interfaces/Component';
import { ICustomFilter } from 'interfaces/Filter';
import { TETagCustomFilterEnumeration } from 'types/ETag';

export const isBooleanCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean => BOOLEAN_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isEnumerationCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean =>
  ENUMERATION_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isEnumerationListCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean =>
  ENUMERATION_LIST_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isMiscInfoCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean =>
  MISC_INFO_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isMiscInfoListCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean =>
  MISC_INFO_LIST_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isStringCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean => STRING_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isStringListCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean =>
  STRING_LIST_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

export const isStringListEnhancedCustomFilterAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): boolean =>
  STRING_LIST_ENHANCED_CUSTOM_FILTER_ATTRIBUTES.includes(customFilterAttribute);

const getListForCustomFilter = (
  customFilter: ICustomFilter,
): EList | undefined => {
  const { attribute, type } = customFilter;

  if (type === ECustomFilterType.AllMatch) {
    return EList.All;
  } else if (type === ECustomFilterType.AnyMatch) {
    return EList.Any;
  } else if (
    attribute !== null &&
    isStringListEnhancedCustomFilterAttribute(attribute)
  ) {
    if (type === ECustomFilterType.FirstInList) {
      return EList.First;
    } else if (type === ECustomFilterType.LastInList) {
      return EList.Last;
    }
  }

  return undefined;
};

export const getCustomFilterTypeForList = (
  list: EList,
  customFilter: ICustomFilter,
): ECustomFilterType => {
  if (list === EList.All) {
    return ECustomFilterType.AllMatch;
  } else if (list === EList.Any) {
    return ECustomFilterType.AnyMatch;
  } else if (
    customFilter.attribute !== null &&
    isStringListEnhancedCustomFilterAttribute(customFilter.attribute)
  ) {
    if (list === EList.First) {
      return ECustomFilterType.FirstInList;
    } else if (list === EList.Last) {
      return ECustomFilterType.LastInList;
    }
  }

  throw new Error(`Invalid list: ${list}`);
};

export const getAttributeValues = (
  customFilter: ICustomFilter,
  attributeRootCustomFilter?: ICustomFilter,
): IAttributeValues => {
  const { attribute, type, value } = customFilter;
  const attributeValues: IAttributeValues = {};

  if (attribute !== null) {
    let attributeValue: EAttribute | undefined =
      customFilterAttributeToAttribute(attribute);

    if (attributeValue === undefined) {
      if (attributeRootCustomFilter !== undefined) {
        if (attributeRootCustomFilter.attribute === null) {
          throw new Error(
            `Missing attribute for attributeRootCustomFilter: ${JSON.stringify(
              attributeRootCustomFilter,
            )}`,
          );
        }

        if (
          attributeRootCustomFilter.attribute ===
          ECustomFilterAttribute.MarketSegmentMiscInfo
        ) {
          if (attribute === ECustomFilterAttribute.Token) {
            attributeValue = EAttribute.MarketSegmentMiscInfoToken;
          } else if (attribute === ECustomFilterAttribute.Value) {
            attributeValue = EAttribute.MarketSegmentMiscInfoValue;
          } else {
            throw new Error(
              `Invalid attribute for customFilter: ${JSON.stringify(
                customFilter,
              )} and attributeRootCustomFilter: ${JSON.stringify(
                attributeRootCustomFilter,
              )}`,
            );
          }
        } else if (
          attributeRootCustomFilter.attribute ===
          ECustomFilterAttribute.PhysicalSegmentMiscInfo
        ) {
          if (attribute === ECustomFilterAttribute.Token) {
            attributeValue = EAttribute.PhysicalSegmentMiscInfoToken;
          } else if (attribute === ECustomFilterAttribute.Value) {
            attributeValue = EAttribute.PhysicalSegmentMiscInfoValue;
          } else {
            throw new Error(
              `Invalid attribute for customFilter: ${JSON.stringify(
                customFilter,
              )} and attributeRootCustomFilter: ${JSON.stringify(
                attributeRootCustomFilter,
              )}`,
            );
          }
        } else if (
          attributeRootCustomFilter.attribute ===
          ECustomFilterAttribute.TransAllocMiscInfo
        ) {
          if (attribute === ECustomFilterAttribute.Token) {
            attributeValue = EAttribute.TransAllocMiscInfoToken;
          } else if (attribute === ECustomFilterAttribute.Value) {
            attributeValue = EAttribute.TransAllocMiscInfoValue;
          } else {
            throw new Error(
              `Invalid attribute for customFilter: ${JSON.stringify(
                customFilter,
              )} and attributeRootCustomFilter: ${JSON.stringify(
                attributeRootCustomFilter,
              )}`,
            );
          }
        }
      }
    }

    attributeValues.attributeValue = attributeValue;

    const isEnumeration: boolean =
      isEnumerationCustomFilterAttribute(attribute);
    const isEnumerationList: boolean =
      isEnumerationListCustomFilterAttribute(attribute);

    if (isEnumeration || isEnumerationList) {
      if (isEnumerationList) {
        if (attributeRootCustomFilter === undefined) {
          throw new Error(
            `Missing attributeRootCustomFilter for customFilter: ${JSON.stringify(
              customFilter,
            )}`,
          );
        }

        attributeValues.listValue = getListForCustomFilter(
          attributeRootCustomFilter,
        );
      }

      const { sub_filter_list } = customFilter;
      const enumerationValues: TETagCustomFilterEnumeration[] = [];

      if (sub_filter_list === null) {
        if (value !== null) {
          enumerationValues.push(value as TETagCustomFilterEnumeration);
        }
      } else {
        for (let i: number = 0; i < sub_filter_list.length; i += 1) {
          const enumerationCustomFilter: ICustomFilter = sub_filter_list[i];

          if (enumerationCustomFilter.attribute !== attribute) {
            throw new Error(
              `Invalid attribute for enumerationCustomFilter: ${JSON.stringify(
                enumerationCustomFilter,
              )}`,
            );
          } else if (
            enumerationCustomFilter.type !== ECustomFilterType.Equals
          ) {
            throw new Error(
              `Invalid type for enumerationCustomFilter: ${JSON.stringify(
                enumerationCustomFilter,
              )}`,
            );
          }

          if (enumerationCustomFilter.value !== null) {
            enumerationValues.push(
              enumerationCustomFilter.value as TETagCustomFilterEnumeration,
            );
          }
        }
      }

      attributeValues.enumerationValues = enumerationValues;
      attributeValues.showEnumerationSelect = true;
    } else {
      if (isBooleanCustomFilterAttribute(attribute)) {
        attributeValues.booleanValue =
          value === null ? undefined : (value as boolean);
        attributeValues.showBooleanSelect = true;
      } else {
        const isString: boolean = isStringCustomFilterAttribute(attribute);
        const isStringList: boolean =
          isStringListCustomFilterAttribute(attribute);
        const isMiscInfo: boolean = isMiscInfoCustomFilterAttribute(attribute);
        const isMiscInfoList: boolean =
          isMiscInfoListCustomFilterAttribute(attribute);

        if (isString || isStringList || isMiscInfo || isMiscInfoList) {
          if (isStringList || isMiscInfo || isMiscInfoList) {
            if (attributeRootCustomFilter === undefined) {
              throw new Error(
                `Missing attributeRootCustomFilter for customFilter: ${JSON.stringify(
                  customFilter,
                )}`,
              );
            }

            const { sub_filter } = attributeRootCustomFilter;

            if (sub_filter === null) {
              attributeValues.stringValue =
                value === null ? undefined : (value as string);
              attributeValues.filterType = type === null ? undefined : type;
            } else {
              const { value } = sub_filter;

              attributeValues.stringValue =
                value === null ? undefined : (value as string);
              attributeValues.filterType = type === null ? undefined : type;
            }

            attributeValues.listValue = getListForCustomFilter(
              attributeRootCustomFilter,
            );
          } else {
            attributeValues.filterType = type === null ? undefined : type;
            attributeValues.stringValue =
              value === null ? undefined : (value as string);
            attributeValues.listValue = getListForCustomFilter(customFilter);
          }

          attributeValues.showStringInput = true;
        }
      }
    }
  }

  return attributeValues;
};

export const booleanEnumerationToUid = (booleanEnumeration: boolean): string =>
  booleanEnumeration ? BOOLEAN_TRUE_LABEL : BOOLEAN_FALSE_LABEL;

export const enumerationToUid = (
  enumeration: TETagCustomFilterEnumeration,
): string => enumeration as string;

export const getEnumerationOptions = (
  customFilterAttribute: ECustomFilterAttribute | null,
): IOption<TETagCustomFilterEnumeration>[] => {
  if (customFilterAttribute === ECustomFilterAttribute.CompositeState) {
    return ETAG_FILTER_ENUM_OPTIONS_MAP.composite_state_filter as IOption<TETagCustomFilterEnumeration>[];
  } else if (
    customFilterAttribute === ECustomFilterAttribute.MarketSegmentEnergyProduct
  ) {
    return ETAG_FILTER_ENUM_OPTIONS_MAP.market_segment_energy_product_filter as IOption<TETagCustomFilterEnumeration>[];
  } else if (customFilterAttribute === ECustomFilterAttribute.TagType) {
    return ETAG_FILTER_ENUM_OPTIONS_MAP.tag_type_filter as IOption<TETagCustomFilterEnumeration>[];
  } else if (
    customFilterAttribute === ECustomFilterAttribute.TimeClassification
  ) {
    return ETAG_FILTER_ENUM_OPTIONS_MAP.time_classification_filter as IOption<TETagCustomFilterEnumeration>[];
  } else if (
    customFilterAttribute === ECustomFilterAttribute.TransAllocProduct
  ) {
    return ETAG_FILTER_ENUM_OPTIONS_MAP.trans_alloc_product_filter as IOption<TETagCustomFilterEnumeration>[];
  }

  return [];
};

export const attributeToCustomFilterAttribute = (
  attribute: EAttribute,
): ECustomFilterAttribute => {
  if (isMiscInfoMarketSegmentAttribute(attribute)) {
    return ECustomFilterAttribute.MarketSegmentMiscInfo;
  } else if (isMiscInfoPhysicalSegmentAttribute(attribute)) {
    return ECustomFilterAttribute.PhysicalSegmentMiscInfo;
  } else if (isMiscInfoTransAllocAttribute(attribute)) {
    return ECustomFilterAttribute.TransAllocMiscInfo;
  }

  return attribute as unknown as ECustomFilterAttribute;
};

export const customFilterAttributeToAttribute = (
  customFilterAttribute: ECustomFilterAttribute,
): EAttribute | undefined => {
  if (
    isMiscInfoCustomFilterAttribute(customFilterAttribute) ||
    isMiscInfoListCustomFilterAttribute(customFilterAttribute)
  ) {
    return undefined;
  }

  return customFilterAttribute as unknown as EAttribute;
};

export const getCustomFilterForList = (
  customFilter: ICustomFilter,
): ICustomFilter => {
  const { sub_filter } = customFilter;

  if (sub_filter === null) {
    throw new Error(
      `Missing sub_filter for customFilter: ${JSON.stringify(customFilter)}`,
    );
  } else {
    const { attribute, type } = sub_filter;

    if (type === null) {
      throw new Error(
        `Invalid type for sub_filter: ${JSON.stringify(
          sub_filter,
        )} for customFilter: ${JSON.stringify(customFilter)}`,
      );
    }

    if (attribute === null) {
      throw new Error(
        `Invalid attribute: ${attribute} for sub_filter: ${JSON.stringify(
          sub_filter,
        )} for customFilter: ${JSON.stringify(customFilter)}`,
      );
    } else if (
      isMiscInfoCustomFilterAttribute(attribute) ||
      isMiscInfoListCustomFilterAttribute(attribute)
    ) {
      if (!isOperand(type)) {
        throw new Error(
          `Invalid attribute: ${attribute} for sub_filter: ${JSON.stringify(
            sub_filter,
          )} for customFilter: ${JSON.stringify(customFilter)}`,
        );
      }

      return sub_filter;
    } else if (isEnumerationListCustomFilterAttribute(attribute)) {
      if (type !== ECustomFilterType.Or && type !== ECustomFilterType.Equals) {
        throw new Error(
          `Invalid attribute: ${attribute} for sub_filter: ${JSON.stringify(
            sub_filter,
          )} for customFilter: ${JSON.stringify(customFilter)}`,
        );
      }

      return sub_filter;
    } else if (isStringListCustomFilterAttribute(attribute)) {
      if (!isOperand(type)) {
        throw new Error(
          `Invalid attribute: ${attribute} for sub_filter: ${JSON.stringify(
            sub_filter,
          )} for customFilter: ${JSON.stringify(customFilter)}`,
        );
      }

      return sub_filter;
    } else {
      throw new Error(
        `Invalid attribute: ${attribute} for sub_filter: ${JSON.stringify(
          sub_filter,
        )} for customFilter: ${JSON.stringify(customFilter)}`,
      );
    }
  }
};
