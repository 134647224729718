import { ScheduleOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { TO_ENTITY_REVIEW_PENDING_REQUESTS_MODAL_WIDTH_VALUE } from 'components/organisms/ToEntityETagReviewPendingRequests/constants';
import ReviewPendingRequests from 'components/organisms/ToEntityETagReviewPendingRequests/ReviewPendingRequests';
import ReviewPendingRequestsHeader from 'components/organisms/ToEntityETagReviewPendingRequests/ReviewPendingRequestsHeader';
import {
  IModalHandlers,
  ISendResponse,
} from 'components/organisms/ToEntityETagReviewPendingRequests/types';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { ECompositeState } from 'enums/ETag';
import { EActionState } from 'enums/General';
import { IOption } from 'interfaces/Component';
import { IETagDataSet, IETagExtendedIdentifier } from 'interfaces/ETag';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { sortByOptionLabel } from 'utils/component';
import { encodeIds, isEmptyValue } from 'utils/general';

const ReviewPendingRequestsIcon = styled(ScheduleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagReviewPendingRequestsProps {
  encodedPermissionsId: string;
  eTagDataSets: IETagDataSet[];
  eTagExtendedIdentifier: IETagExtendedIdentifier | undefined;
  isDisabled?: boolean;
  isUnconstrained: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagReviewPendingRequests = ({
  encodedPermissionsId,
  eTagDataSets,
  eTagExtendedIdentifier,
  isDisabled,
  isUnconstrained,
  timeZone,
  toEntityId,
}: IToEntityETagReviewPendingRequestsProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [handlers, setHandlers] = useState<IModalHandlers>({});
  const [disableActions, setDisableActions] = useState<boolean>(false);
  const [sendActionState, setSendActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [shouldCloseAfterSend, setShouldCloseAfterSend] =
    useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  const getAllPendingRequests: boolean = useMemo(
    () =>
      eTagExtendedIdentifier === undefined ||
      eTagExtendedIdentifier.composite_state === ECompositeState.Draft ||
      eTagExtendedIdentifier.tag_primary_key === undefined,
    [eTagExtendedIdentifier],
  );

  const [selectedTagPrimaryKeys, setSelectedTagPrimaryKeys] = useState<
    TETagTagPrimaryKey[]
  >([]);
  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);

  useEffect(() => {
    if (eTagExtendedIdentifier?.tag_primary_key) {
      setSelectedTagPrimaryKeys([eTagExtendedIdentifier.tag_primary_key]);
    } else {
      setSelectedTagPrimaryKeys([]);
    }
  }, [eTagExtendedIdentifier?.tag_primary_key]);

  const handleRefresh = () => {
    setRefreshFlag((previousRefreshFlag: boolean) => !previousRefreshFlag);
  };

  const handleShow = useCallback(() => {
    setSendActionState(EActionState.NoAction);

    setErrorMessage(null);

    setShowModal(true);
  }, []);

  const handleCancel = useCallback(async () => {
    setShowModal(false);
  }, []);

  const handleSend = useCallback(async () => {
    if (handlers.sendHandler !== undefined) {
      try {
        setDisableActions(true);

        setSendActionState(EActionState.Actioning);

        setErrorMessage(null);

        const sendResponse: ISendResponse = await handlers.sendHandler();

        setSendActionState(sendResponse.actionState);

        setShouldCloseAfterSend(sendResponse.shouldClose);

        if (sendResponse.actionState === EActionState.Failed) {
          throw new Error(
            isEmptyValue(sendResponse.errorMessage)
              ? 'An error occurred during send. Please try again later.'
              : sendResponse.errorMessage!,
          );
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setDisableActions(false);
      }
    }
  }, [handlers]);

  const handleSendActionIconRemoved = useCallback(() => {
    if (shouldCloseAfterSend) {
      setShowModal(false);
    }
  }, [shouldCloseAfterSend]);

  const title: string = useMemo(
    () =>
      isEmptyValue(eTagExtendedIdentifier?.ui_tag_id) || getAllPendingRequests
        ? 'Review Pending Requests for All Tags'
        : `Review Pending Requests for ${eTagExtendedIdentifier?.ui_tag_id!}`,
    [eTagExtendedIdentifier?.ui_tag_id, getAllPendingRequests],
  );

  const tagOptions: IOption<string>[] = useMemo((): IOption<string>[] => {
    return eTagDataSets
      .map((dataSet: IETagDataSet): IOption<string> | undefined => {
        if (dataSet.tag_primary_key && dataSet.ui_tag_id) {
          return { label: dataSet.ui_tag_id, value: dataSet.tag_primary_key };
        }
        return undefined;
      })
      .filter(
        (option: IOption<string> | undefined) => option !== undefined,
      ) as IOption<string>[];
  }, [eTagDataSets]);

  return (
    <>
      <Tooltip isDisabled={showModal} title={title}>
        <IconButton
          icon={<ReviewPendingRequestsIcon />}
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          onClick={handleShow}
        />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            confirmActionState={sendActionState}
            confirmLabel='Send to Authority'
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            errorMessage={errorMessage}
            isCancelDisabled={disableActions}
            isConfirmDisabled={
              disableActions ||
              handlers.sendHandler === undefined ||
              isConfirmDisabled
            }
            onConfirmActionIconRemoved={handleSendActionIconRemoved}
            onCancel={handleCancel}
            onConfirm={handleSend}
          />
        }
        isVisible={showModal}
        onCancel={disableActions ? undefined : handleCancel}
        title={
          <ReviewPendingRequestsHeader
            setSelectedTagPrimaryKeys={setSelectedTagPrimaryKeys}
            selectedTagPrimaryKeys={selectedTagPrimaryKeys}
            summaryTagOptions={tagOptions.sort(sortByOptionLabel)}
            handleRefresh={handleRefresh}
          />
        }
        width={TO_ENTITY_REVIEW_PENDING_REQUESTS_MODAL_WIDTH_VALUE}
      >
        <ReviewPendingRequests
          isDisabled={isDisabled}
          isUnconstrained={isUnconstrained}
          setErrorMessage={setErrorMessage}
          setHandlers={setHandlers}
          setIsConfirmDisabled={setIsConfirmDisabled}
          selectedTagPrimaryKeys={selectedTagPrimaryKeys}
          refreshFlag={refreshFlag}
          timeZone={timeZone}
          toEntityId={toEntityId}
        />
      </Modal>
    </>
  );
};

export default ToEntityETagReviewPendingRequests;
