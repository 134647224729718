import styled from 'styled-components';
import {
  PAGE_LAYOUT_STYLES,
  STANDARD_SPACING,
} from '../../../constants/styles';
import { IPageContentProps } from '../../organisms/Page/Page';
import ToEntityHelpUI from '../../molecules/ToEntityHelpUI/ToEntityHelpUI';
import { useEffect, useState } from 'react';
import { IToEntity } from '../../../interfaces/ToEntity';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  > :first-child {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface IAlertPageContentProps extends IPageContentProps<undefined> {}

const HelpTutorialsPageContent = (
  props: IAlertPageContentProps,
): JSX.Element => {
  const { userInfo } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toEntityId, setToEntityId] = useState<number | undefined>(undefined);
  const [toEntity, setToEntity] = useState<IToEntity | undefined>(undefined);

  useEffect(() => {
    if (userInfo.toEntities.length > 0) {
      setToEntityId(
        (userInfo.toEntities &&
          userInfo.toEntities[0] &&
          userInfo.toEntities[0].tagging_entity_id) ||
          undefined,
      );
      setToEntity(userInfo.toEntities[0] || undefined);
      setIsLoading(false);
    }
  }, [userInfo]);

  return (
    <Layout>
      {isLoading ? null : (
        <ToEntity>
          <ToEntityHelpUI
            toEntityId={toEntityId}
            userInfo={userInfo}
            toEntity={toEntity}
          />
        </ToEntity>
      )}
    </Layout>
  );
};

export default HelpTutorialsPageContent;
