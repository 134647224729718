import { TEMPLATES_SHOW_ALL_GROUPS } from 'constants/Template';
import { ETransactionType } from 'enums/ETag';
import {
  EFilterStringType,
  EFilterType,
  ERequestFilterType,
} from 'enums/Filter';
import { IEditableOption, IOption } from 'interfaces/Component';
import {
  IBaseFilter,
  IFilterConfigurationGroup,
  IFilterMisc,
  IFilterString,
  INegatedFilter,
  INestedFilter,
  ITemplateFilter,
} from 'interfaces/Filter';
import { IIndexable } from 'interfaces/General';
import { TETagTemplateFilterEnumeration } from 'types/ETag';
import { TFilterId, TRequestBodyFilter } from 'types/Filter';

type TTemplateFilterValueTypes =
  | TFilterId
  | string
  | null
  | TETagTemplateFilterEnumeration[]
  | IFilterString[]
  | IFilterMisc;

export const copyFilter = (
  filter: ITemplateFilter & IIndexable,
): ITemplateFilter => {
  const copyOfFilter: Record<string, TTemplateFilterValueTypes> = {};

  Object.keys(filter).forEach((key: string) => {
    const filterData: any = filter[key];

    if (Array.isArray(filterData)) {
      if (
        typeof filterData[0] === 'number' ||
        typeof filterData[0] === 'string'
      ) {
        // This should be TETagFilterEnumeration[]
        copyOfFilter[key] = [...filterData];
      } else if (typeof filterData[0] === 'object') {
        // This should be IFilterString[]
        copyOfFilter[key] = filterData.map(
          (value: IFilterString): IFilterString => ({
            ...value,
          }),
        );
      }
    } else {
      copyOfFilter[key] = filterData;
    }
  });

  return copyOfFilter as ITemplateFilter & IIndexable;
};

export const generateEditableFilterOption = (
  filterOption: IOption<ITemplateFilter>,
): IEditableOption<ITemplateFilter> => ({
  isUnsaved: false,
  label: filterOption.label,
  value: copyFilter(filterOption.value),
});

export const copyEditableFilterOption = (
  editableFilterOption: IEditableOption<ITemplateFilter>,
): IEditableOption<ITemplateFilter> => ({
  isUnsaved: editableFilterOption.isUnsaved,
  label: editableFilterOption.label,
  value: copyFilter(editableFilterOption.value),
});

export const generateNewTemplateFilter = (
  filterName: string,
): ITemplateFilter => ({
  filter_id: '',
  filter_name: filterName && filterName.trim(),
  gen_product_filter: null,
  load_product_filter: null,
  misc_infos_filter: null,
  notes_filter: null,
  trans_alloc_number_filter: null,
  trans_product_filter: null,
  transaction_type_filter: null,
  ui_cpse_filter: null,
  ui_gca_filter: null,
  ui_lca_filter: null,
  ui_generation_source_filter: null,
  ui_load_sink_filter: null,
  ui_market_segment_pse_filter: null,
  ui_transmission_pod_filter: null,
  ui_transmission_por_filter: null,
  ui_trans_provider_filter: null,
});

const getSingleStringRequestFilter = (
  attribute: string,
  value: string | null,
  filterType?: EFilterStringType,
): IBaseFilter | INegatedFilter | undefined => {
  if (value === null) {
    return undefined;
  }

  if (filterType?.valueOf().toLocaleLowerCase().includes('not')) {
    return {
      type: ERequestFilterType.Not,
      negated_filter: {
        attribute: attribute,
        type: filterType.valueOf().slice(3).toLocaleLowerCase(),
        value: value,
      },
    } as INegatedFilter;
  }

  return {
    attribute: attribute,
    type: filterType !== undefined ? filterType.toLocaleLowerCase() : 'equals',
    value: value,
  } as IBaseFilter;
};

const getStringFilter = (
  filter: IFilterString[],
  attribute: string,
): TRequestBodyFilter | undefined => {
  return filter.length > 1
    ? ({
        type: ERequestFilterType.Or,
        filters: filter?.map((filterString): TRequestBodyFilter | undefined =>
          getSingleStringRequestFilter(
            attribute,
            filterString.value,
            filterString.filter_type,
          ),
        ),
      } as INestedFilter)
    : getSingleStringRequestFilter(
        attribute,
        filter[0].value,
        filter[0].filter_type,
      );
};

export const generateTemplateIdsRequestBodyFiltersFromUiFilters = (
  filter: (ITemplateFilter & IIndexable) | undefined,
  showActive: boolean | undefined,
  selectedTemplateGroup: string | null | undefined,
): TRequestBodyFilter => {
  const filters: (TRequestBodyFilter | undefined)[] = [];

  switch (showActive) {
    case undefined:
      filters.push({
        type: ERequestFilterType.True,
        value: true,
      });
      break;
    case true:
      filters.push({
        attribute: 'active',
        type: ERequestFilterType.Equals,
        value: true,
      });
      break;
    case false:
      filters.push({
        attribute: 'active',
        type: ERequestFilterType.Equals,
        value: false,
      });
      break;
  }

  if (filter !== undefined) {
    if (filter.gen_product_filter !== null) {
      filters.push(getStringFilter(filter.filter, 'gen_product'));
    }

    if (filter.load_product_filter !== null) {
      filters.push(getStringFilter(filter.load_product_filter, 'load_product'));
    }

    if (filter.misc_infos_filter !== null) {
      filters.push(
        filter.misc_infos_filter.length > 1
          ? ({
              type: ERequestFilterType.Or,
              filters: filter.misc_infos_filter?.map(
                (filterMisc: IFilterMisc): IBaseFilter => ({
                  attribute: filterMisc.token,
                  type: ERequestFilterType.Equals,
                  value: filterMisc.value,
                }),
              ),
            } as INestedFilter)
          : ({
              attribute: filter.misc_infos_filter[0].token,
              type: filter.misc_infos_filter[0].filter_type.toLocaleLowerCase(),
              value: filter.misc_infos_filter[0].value,
            } as IBaseFilter),
      );
    }

    if (filter.notes_filter !== null) {
      filters.push(getStringFilter(filter.notes_filter, 'notes'));
    }

    if (filter.trans_alloc_number_filter !== null) {
      filters.push(
        getStringFilter(filter.trans_alloc_number_filter, 'trans_alloc_number'),
      );
    }

    if (filter.trans_product_filter !== null) {
      filters.push(
        getStringFilter(filter.trans_product_filter, 'trans_product'),
      );
    }

    if (filter.transaction_type_filter !== null) {
      filters.push(
        filter.transaction_type_filter.length > 1
          ? ({
              type: ERequestFilterType.Or,
              filters: filter.transaction_type_filter?.map(
                (transtype: ETransactionType): IBaseFilter => ({
                  attribute: 'transaction_type',
                  type: ERequestFilterType.Equals,
                  value: transtype,
                }),
              ),
            } as INestedFilter)
          : ({
              attribute: 'transaction_type',
              type: ERequestFilterType.Equals,
              value: filter.transaction_type_filter[0],
            } as IBaseFilter),
      );
    }

    if (filter.ui_cpse_filter !== null) {
      filters.push(getStringFilter(filter.ui_cpse_filter, 'ui_cpse'));
    }

    if (filter.ui_gca_filter !== null) {
      filters.push(getStringFilter(filter.ui_gca_filter, 'ui_gca'));
    }

    if (filter.ui_lca_filter !== null) {
      filters.push(getStringFilter(filter.ui_lca_filter, 'ui_lca'));
    }

    if (filter.ui_generation_source_filter !== null) {
      filters.push(
        getStringFilter(
          filter.ui_generation_source_filter,
          'ui_generation_source',
        ),
      );
    }

    if (filter.ui_load_sink_filter !== null) {
      filters.push(getStringFilter(filter.ui_load_sink_filter, 'ui_load_sink'));
    }

    if (filter.ui_market_segment_pse_filter !== null) {
      filters.push(
        getStringFilter(
          filter.ui_market_segment_pse_filter,
          'ui_market_segment_pse',
        ),
      );
    }

    if (filter.ui_transmission_pod_filter !== null) {
      filters.push(
        getStringFilter(
          filter.ui_transmission_pod_filter,
          'ui_transmission_pod',
        ),
      );
    }

    if (filter.ui_transmission_por_filter !== null) {
      filters.push(
        getStringFilter(
          filter.ui_transmission_por_filter,
          'ui_transmission_por',
        ),
      );
    }

    if (filter.ui_trans_provider_filter !== null) {
      filters.push(
        getStringFilter(filter.ui_trans_provider_filter, 'ui_trans_provider'),
      );
    }
  }

  const filtersOnly: TRequestBodyFilter[] = [];
  filters.forEach((item: TRequestBodyFilter | undefined) => {
    if (item !== undefined) {
      filtersOnly.push(item);
    }
  });

  if (selectedTemplateGroup !== undefined) {
    if (selectedTemplateGroup === null) {
      filtersOnly.push({
        type: ERequestFilterType.Or,
        filters: [
          {
            attribute: 'group_name',
            type: ERequestFilterType.Equals,
            value: null,
          },
          {
            attribute: 'group_name',
            type: ERequestFilterType.Equals,
            value: '',
          },
        ],
      });
    } else if (selectedTemplateGroup !== TEMPLATES_SHOW_ALL_GROUPS) {
      filtersOnly.push({
        attribute: 'group_name',
        type: ERequestFilterType.Equals,
        value: selectedTemplateGroup,
      });
    }
  }

  return filtersOnly.length > 1
    ? { type: ERequestFilterType.And, filters: filtersOnly }
    : filtersOnly[0];
};

export const generateNewFilterConfigurationGroups =
  (): IFilterConfigurationGroup<TETagTemplateFilterEnumeration>[] => [
    {
      filterConfigurations: [
        {
          dataIndex: 'ui_gca_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'GCA',
        },
        {
          dataIndex: 'ui_cpse_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'CPSE',
        },
        {
          dataIndex: 'ui_lca_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'LCA',
        },
        {
          dataIndex: 'transaction_type_filter',
          filterEnumerations: [],
          filterType: EFilterType.Enumeration,
          inputWidth: 373,
          label: 'Tag Type',
        },
        {
          dataIndex: 'notes_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'Tag Notes',
        },
      ],
      label: 'Tag Attribute',
    },
    {
      filterConfigurations: [
        {
          dataIndex: 'ui_trans_provider_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'TSP',
        },
        {
          dataIndex: 'ui_generation_source_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'SOURCE',
        },
        {
          dataIndex: 'ui_transmission_por_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'POR',
        },
        {
          dataIndex: 'ui_transmission_pod_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'POD',
        },
        {
          dataIndex: 'ui_load_sink_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'SINK',
        },
      ],
      label: 'Physical Segment',
    },
    {
      filterConfigurations: [
        {
          dataIndex: 'ui_market_segment_pse_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'PSE',
        },
        {
          dataIndex: 'gen_product_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'Generation Product',
        },
        {
          dataIndex: 'load_product_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'Load Product',
        },
        {
          dataIndex: 'misc_infos_filter',
          filterMiscs: null,
          filterType: EFilterType.Misc,
          inputWidth: 123,
          label: 'Misc Info',
        },
      ],
      label: 'Market Segment',
    },
    {
      filterConfigurations: [
        {
          dataIndex: 'trans_product_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'Transmission Product',
        },
        {
          dataIndex: 'trans_alloc_number_filter',
          filterStrings: null,
          filterType: EFilterType.String,
          inputWidth: 123,
          label: 'AREF/TSR/OASIS',
        },
      ],
      label: 'Transmission Allocation',
    },
  ];

export const isFilterConfigured = (
  filter: ITemplateFilter & IIndexable,
): boolean =>
  Object.keys(filter).find(
    (key: string): boolean =>
      key !== 'filter_id' && key !== 'filter_name' && filter[key] !== null,
  ) !== undefined;

export const generateNewPlaceholderFilterId = (value: number): string =>
  `_${value}`;

export const isPlaceholderFilterId = (filter_id: string): boolean =>
  filter_id.startsWith('_');
