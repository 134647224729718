import { Popover as AntDesignPopover } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToEntityETagSearchPanel from 'components/molecules/ToEntityETagSearchPanel/ToEntityETagSearchPanel';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import { checkPermissionsAndThen } from 'components/pages/LandingPage/helpers';
import { IToEntitiesPermissionId } from 'components/pages/LandingPage/types';
import { permissionsForIdAndToEntity } from 'hooks/usePermissions';
import { IToEntity } from 'interfaces/ToEntity';
import { useState } from 'react';
import { TTimeZone } from 'types/DateTime';

interface ISearchTagPopoverProps extends IToEntitiesPermissionId {
  selectedToEntity: IToEntity | undefined;
  timeZone: TTimeZone;
}

const SearchTagPopover = checkPermissionsAndThen(
  (props: ISearchTagPopoverProps): JSX.Element => {
    const { children, permissionId, selectedToEntity, timeZone, toEntities } =
      props;
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const toEntitiesInWhichUserCanCreateTags = toEntities.filter(
      (toEntity: IToEntity): boolean =>
        permissionsForIdAndToEntity(permissionId, toEntity).isExecutable,
    );

    return (
      <AntDesignPopover
        content={
          <ToEntityETagSearchPanel
            timeZone={timeZone}
            toEntityId={selectedToEntity?.to_entity}
          >
            <UserToEntitySelection
              filteredToEntities={toEntitiesInWhichUserCanCreateTags}
            />
            <SeparatedRowLayout>
              <div>Time Zone:</div>
              <UserTimeZoneSelector />
            </SeparatedRowLayout>
          </ToEntityETagSearchPanel>
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        title='Search Tag'
        trigger='click'
        visible={isVisible}
      >
        {children}
      </AntDesignPopover>
    );
  },
);

export default SearchTagPopover;
