import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal as AntDesignModal } from 'antd';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { ECompositeState } from 'enums/ETag';
import {
  IETagDeleteResponse,
  IETagDraftDeleteResponse,
  IETagExtendedIdentifier,
} from 'interfaces/ETag';
import { useCallback, useState } from 'react';
import { removeETag } from 'services/agent/tags/distributed';
import { removeETagDraft } from 'services/agent/tags/drafts';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';

const { confirm } = AntDesignModal;

const DeleteDraftIcon = styled(DeleteOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IProps {
  encodedPermissionsId: string;
  eTagExtendedIdentifier: IETagExtendedIdentifier | undefined;
  isDisabled?: boolean;
  onSuccess?: (eTagDraftDeleteResponse: IETagDeleteResponse) => void;
  toEntityId: TToEntityId;
}

const ToEntityETagDelete = (props: IProps): JSX.Element => {
  const {
    encodedPermissionsId,
    eTagExtendedIdentifier,
    isDisabled,
    onSuccess,
    toEntityId,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleDeleteTag = useCallback(() => {
    setIsVisible(true);

    const modalRef = confirm({
      title: `Are you sure you want to permanently delete tag ${
        isEmptyValue(eTagExtendedIdentifier!.ui_tag_id)
          ? 'with no Tag ID'
          : `"${eTagExtendedIdentifier!.ui_tag_id}"`
      }?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          modalRef.update({ content: undefined });

          if (
            eTagExtendedIdentifier === undefined ||
            isEmptyValue(eTagExtendedIdentifier.tag_primary_key)
          ) {
            throw new Error('Invalid eTagExtendedIdentifier');
          }

          const response: AxiosResponse<IETagDeleteResponse> = await removeETag(
            toEntityId,
            eTagExtendedIdentifier.tag_primary_key!,
          );
          const eTagDeleteResponse: IETagDeleteResponse = response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagDeleteResponse.errorMessage!);
          }

          if (onSuccess) {
            onSuccess(eTagDeleteResponse);
          }
        } catch (error: any) {
          modalRef.update({
            content: (
              <ErrorMessage>
                An error occurred during tag deletion. Please try again later.
              </ErrorMessage>
            ),
          });

          // Propagate the error to prevent the modal from closing
          throw error;
        } finally {
          setIsVisible(false);
        }
      },
      onCancel: () => {
        setIsVisible(false);
      },
    });
  }, [eTagExtendedIdentifier, onSuccess, toEntityId]);

  const handleDeleteDraft = useCallback(() => {
    setIsVisible(true);

    const modalRef = confirm({
      title: `Are you sure you want to permanently delete draft ${
        isEmptyValue(eTagExtendedIdentifier!.ui_tag_id)
          ? 'with no Tag ID'
          : `"${eTagExtendedIdentifier!.ui_tag_id}"`
      }?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          modalRef.update({ content: undefined });

          if (
            eTagExtendedIdentifier === undefined ||
            isEmptyValue(eTagExtendedIdentifier.draft_id)
          ) {
            throw new Error('Invalid eTagExtendedIdentifier');
          }

          const response: AxiosResponse<IETagDraftDeleteResponse> =
            await removeETagDraft(toEntityId, eTagExtendedIdentifier.draft_id!);
          const eTagDraftDeleteResponse: IETagDraftDeleteResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagDraftDeleteResponse.errorMessage!);
          }

          if (onSuccess) {
            onSuccess(eTagDraftDeleteResponse);
          }
        } catch (error: any) {
          modalRef.update({
            content: (
              <ErrorMessage>
                An error occurred during draft tag deletion. Please try again
                later.
              </ErrorMessage>
            ),
          });

          // Propagate the error to prevent the modal from closing
          throw error;
        } finally {
          setIsVisible(false);
        }
      },
      onCancel: () => {
        setIsVisible(false);
      },
    });
  }, [eTagExtendedIdentifier, onSuccess, toEntityId]);

  const handleDelete =
    eTagExtendedIdentifier?.composite_state === ECompositeState.Draft
      ? handleDeleteDraft
      : handleDeleteTag;

  const asjustedIsDisabled: boolean =
    isDisabled || eTagExtendedIdentifier === undefined;

  return (
    <Tooltip
      isDisabled={isDisabled || isVisible}
      title={`Delete ${
        eTagExtendedIdentifier?.composite_state === ECompositeState.Draft
          ? 'Draft'
          : ''
      } Tag`}
    >
      <IconButton
        icon={<DeleteDraftIcon />}
        encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
        isDisabled={asjustedIsDisabled}
        onClick={handleDelete}
      />
    </Tooltip>
  );
};

export default ToEntityETagDelete;
