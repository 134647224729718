import { TToEntityId } from '../../types/ToEntity';
import { TETagTagPrimaryKey } from '../../types/ETag';
import { TTimeZone } from '../../types/DateTime';

export const DOWNLOAD_TAGS_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestId?: string,
  timeZone?: TTimeZone | undefined,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/tags/distributed/${encodeURIComponent(
    tagPrimaryKey,
  )}/download/distributeXmls${requestId ? `?requestId=${requestId}` : ''}${
    timeZone
      ? `${requestId ? '&' : '?'}timeZone=${encodeURIComponent(timeZone)}`
      : ''
  }`;
