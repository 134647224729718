import ContainedText from 'components/molecules/ContainedText/ContainedText';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { HeaderRendererProps } from 'react-data-grid';
import styled from 'styled-components';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';

const ProfileDataGridHeaderCell = styled.div`
  padding: 3px 4px;
  > div {
    height: 20px;
  }
`;

const PrimaryColumnHeader = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

export interface IProfileDataGridColumnHeaderProps
  extends HeaderRendererProps<
    TProfileDataGridRow,
    TProfileDataGridSummaryRow
  > {}

const ProfileDataGridColumnHeader = ({
  column,
}: IProfileDataGridColumnHeaderProps): JSX.Element => {
  const titles: string[] = (column.name as string).split('\n');
  const renderTextAsHtml = (column as any)['renderTextAsHtml'];
  const tooltipText = (column as any)['tooltipText'];
  const hideTooltip = (column as any)['hideTooltip'];
  const splitTooltipText = tooltipText && tooltipText.split('\n');

  return (
    <ProfileDataGridHeaderCell>
      <Tooltip title={titles[1]}>
        <PrimaryColumnHeader>{titles[0]}</PrimaryColumnHeader>
      </Tooltip>
      <ContainedText
        maxWidth='100%'
        text={titles[2]}
        tooltipText={
          !hideTooltip
            ? (splitTooltipText === undefined
                ? undefined
                : `${splitTooltipText[2]} (${splitTooltipText[3]})`) ||
              (titles[3] === undefined
                ? undefined
                : `${titles[2]} (${titles[3]})`)
            : ''
        }
        renderTextAsHtml={renderTextAsHtml}
      />
    </ProfileDataGridHeaderCell>
  );
};

export default ProfileDataGridColumnHeader;
