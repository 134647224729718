export const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 7500;
export const START_DATE_PLACEHOLDER = 'Start date';
export const END_DATE_PLACEHOLDER = 'End date';
export const FOCUS_DELAY_IN_MILLISECONDS = 125;

export const RANGES_COLUMN_MAXIMUM_ITEMS = 7;
export const RANGE_BUTTON_WIDTH_VALUE = 126;

export const CALENDAR_PANEL_HEIGHT_VALUE = 242;
export const ACTIONS_MINIMUM_HEIGHT_VALUE = 62;
