import { EProfileFormat } from 'enums/Detail';
import { EDisplayView } from 'enums/View';
import { IOption } from 'interfaces/Component';

export const CURRENT_REQUEST_ID = -1;
export const CURRENT_REQUEST_KEY = 'C';

export const CURRENT_PENDING_REQUEST_ID = -2;
export const CURRENT_PENDING_REQUEST_KEY = 'CP';

export const MAXIMUM_CC_ENTITIES = 5;

export const ATF_OFFSET_HOURS = 168;
export const BTF_OFFSET_HOURS = 1;

export const INITIAL_RECORD_ID = 0;

export const EDIT_GENERATION_PHYSICAL_SEGMENT_LABEL =
  'generationPhysicalSegment';
export const EDIT_TRANSMISSION_PHYSICAL_SEGMENT_LABEL =
  'transmissionPhysicalSegment';
export const EDIT_LOAD_PHYSICAL_SEGMENT_LABEL = 'loadPhysicalSegment';

export const EDIT_ETAG_TAG_ID_LABEL = 'eTagTagId';

export const EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL =
  'transmissionPhysicalSegment-miscInfo';

export const EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY = 0;

export const EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY = 1;

export const EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY = 0;

export const EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL = 'physicalSegmentsProfiles';
export const EDIT_MARKET_INFOS_LABEL = 'marketInfos';

export const EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL =
  'eTagPhysicalSegmentsProfile';

export const EDIT_KEY_SEPARATOR = ':';

export const TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY = '-';
export const TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY = '~';

export const EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL =
  'summaryInformation-contactInfo';

export const EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL =
  'marketSegment-contactInfo';
export const EDIT_MARKET_SEGMENT_CONTRACTS_LABEL = 'marketSegment-contracts';
export const EDIT_MARKET_SEGMENT_MISC_INFO_LABEL = 'marketSegment-miscInfo';
export const EDIT_MARKET_SEGMENT_PSE_CONTACT_INFO_LABEL =
  'marketSegment-pse-contactInfo';

export const EDIT_GENERATION_PHYSICAL_SEGMENT_CONTRACTS_LABEL =
  'generationPhysicalSegment-contracts';
export const EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL =
  'generationPhysicalSegment-contactInfo';
export const EDIT_GENERATION_PHYSICAL_SEGMENT_MISC_INFO_LABEL =
  'generationPhysicalSegment-miscInfo';

export const EDIT_TRANSMISSION_ALLOCATION_LABEL = 'transmissionAllocation';
export const EDIT_TRANSACTION_STATUSES_CONTACT_INFO_LABEL =
  'transactionStatuses-contactInfo';

export const EDIT_LOAD_PHYSICAL_SEGMENT_CONTRACTS_LABEL =
  'loadPhysicalSegment-contracts';
export const EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_LABEL =
  'loadPhysicalSegment-contactInfo';
export const EDIT_LOAD_PHYSICAL_SEGMENT_MISC_INFO_LABEL =
  'loadPhysicalSegment-miscInfo';

export const EDIT_LOSS_ACCOUNTING_LABEL = 'lossAccounting';

export const EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_FAX_LABEL =
  'generationPhysicalSegment-contactInfo-fax';
export const EDIT_GENERATION_PHYSICAL_SEGMENT_CONTACT_INFO_PHONE_LABEL =
  'generationPhysicalSegment-contactInfo-phone';

export const EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_FAX_LABEL =
  'loadPhysicalSegment-contactInfo-fax';
export const EDIT_LOAD_PHYSICAL_SEGMENT_CONTACT_INFO_PHONE_LABEL =
  'loadPhysicalSegment-contactInfo-phone';

export const ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX = 'request_';
export const ENERGY_PROFILE_SNAPSHOT_EDIT_KEY_LABEL = 'energyProfileSnapshot';

export const NOTES_MAX_LENGTH = 128;

export const PROFILE_PATH_START_KEY = 'path-start';
export const PROFILE_PATH_STOP_KEY = 'path-stop';
export const PROFILE_PATH_RAMP_START_KEY = 'path:rampStart';
export const PROFILE_PATH_RAMP_STOP_KEY = 'path:rampStop';

export const GENERATION_CURRENT_LEVEL_KEY_REG_EXP = /gen:.*:current/;
export const LOAD_CURRENT_LEVEL_KEY_REG_EXP = /load:.*:current/;

export const GENERATION_MARKET_LEVEL_KEY_REG_EXP = /gen:.*:marketLevel/;
export const LOAD_MARKET_LEVEL_KEY_REG_EXP = /load:.*:marketLevel/;

export const GENERATION_RELIABILITY_KEY_REG_EXP = /gen:.*:reliability/;
export const LOAD_RELIABILITY_KEY_REG_EXP = /load:.*:reliability/;

export const TRANSMISSION_KEY_REG_EXP = new RegExp(
  `t(\\d+):(\\d+|${TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY}]):(.*):(.*)`,
);
export const TRANSMISSION_POD_KEY_SUFFIX = 'pod-current';
export const TRANSMISSION_POD_KEY_REG_EXP = new RegExp(
  `t(\\d+):(.*):${TRANSMISSION_POD_KEY_SUFFIX}`,
);
export const TRANSMISSION_POR_KEY_SUFFIX = 'por-current';
export const TRANSMISSION_POR_KEY_REG_EXP = new RegExp(
  `t(\\d+):(.*):${TRANSMISSION_POR_KEY_SUFFIX}`,
);
export const TRANSMISSION_LOSS_KEY_SUFFIX = 'loss-current';
export const TRANSMISSION_LOSS_KEY_REG_EXP = new RegExp(
  `t(\\d+):(.*):${TRANSMISSION_LOSS_KEY_SUFFIX}`,
);
export const TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX = 'alloc-totals';
export const TRANSMISSION_ALLOC_TOTALS_KEY_REG_EXP = new RegExp(
  `t(\\d+):(.*):${TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX}`,
);

export const DEFAULT_AUTO_REFRESH = 15;

export const AUTO_REFRESH_OPTIONS: IOption<number>[] = [
  {
    label: '10 seconds',
    value: 10,
  },
  {
    label: '15 seconds',
    value: 15,
  },
  {
    label: '30 seconds',
    value: 30,
  },
  {
    label: '60 seconds',
    value: 60,
  },
  {
    label: 'Never',
    value: 0,
  },
];

export const DEFAULT_DISPLAY_VIEW = EDisplayView.Grid;

export const DISPLAY_VIEW_OPTIONS: IOption<EDisplayView>[] = [
  {
    label: 'Graph',
    value: EDisplayView.Graph,
  },
  {
    label: 'Grid',
    value: EDisplayView.Grid,
  },
];

export const DEFAULT_PROFILE_FORMAT: EProfileFormat = EProfileFormat.StartStop;

export const DATA_GRID_CONTEXT_MENU_ID = 'dataGridContextMenu';

export const EDIT_MISO_MARKET_DATA_LABEL = 'miso-market-data';
export const EDIT_SPP_MARKET_DATA_LABEL = 'spp-market-data';
