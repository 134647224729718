import { IRouteProps } from '../../../interfaces/Component';
import Page from '../../organisms/Page/Page';
import AlertPageContent from './AlertPageContent';

const AlertPage = ({ appActions }: IRouteProps): JSX.Element => (
  <Page
    appActions={appActions}
    PageContent={AlertPageContent}
    pageTitle='Alert - PCI e-Tag+'
  />
);

export default AlertPage;
