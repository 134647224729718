import { notification as antDesignNotification } from 'antd';
import ScrollableContent from 'components/molecules/ScrollableContent/ScrollableContent';
import {
  ERROR_NOTIFICATION_CONTENT_MAX_WIDTH,
  ERROR_NOTIFICATION_MESSAGE_MAX_HEIGHT,
} from 'constants/styles';
import styled from 'styled-components';
import { getIsProduction, isEmptyValue } from 'utils/general';

const Content = styled.div`
  min-height: 59px;
  width: ${ERROR_NOTIFICATION_CONTENT_MAX_WIDTH};
  white-space: pre-wrap;
`;

export class NotFoundError extends Error {}

export const captureError = (error: Error, title?: string) => {
  const errorMessage: string = isEmptyValue(error.message)
    ? error.name
    : error.message;
  const message: string =
    title === undefined ? errorMessage : `${title}\n${errorMessage}`;
  const isProduction: boolean = getIsProduction();

  console.error(error);

  if (!isProduction) {
    antDesignNotification.error({
      duration: 0,
      message: (
        <ScrollableContent
          maximumHeight={ERROR_NOTIFICATION_MESSAGE_MAX_HEIGHT}
          maximumWidth={ERROR_NOTIFICATION_CONTENT_MAX_WIDTH}
        >
          <Content>{message}</Content>
        </ScrollableContent>
      ),
      placement: 'bottomLeft',
    });
  }
};
