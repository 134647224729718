interface IExpiredIconProps {
  className?: string;
}

const ExpiredIcon = ({ className }: IExpiredIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#c33333'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M423.02 673.468h245.67V750H331.182V250.245h323.73v76.532h-231.89v129.34h198.983v76.532H423.021v140.819z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default ExpiredIcon;
