import {
  VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
} from 'constants/styles';
import { getColumnDetailMarketSegmentPseRender } from 'hooks/useMarketEditColumns/useMarketEditColumns';
import { IViewDataTableColumn } from 'interfaces/View';
import { IDetailMarketSegment } from 'reduxes/Detail/types';
import {
  getColumnContactInfoRender,
  getColumnContractsRender,
  getColumnEnergyProductRender,
  getColumnMiscInfosRender,
  getColumnRender,
} from 'utils/views';

const getMarketSegmentTitle = (record: IDetailMarketSegment): string =>
  `Market Path ${record.market_segment_id} Misc Info`;

export const generateMarketSegmentColumns = (
  isUnconstrained: boolean,
  handleExpandMiscInfos?: (record: IDetailMarketSegment) => void,
): IViewDataTableColumn<IDetailMarketSegment>[] => [
  {
    dataIndex: 'market_segment_id',
    render: getColumnRender(isUnconstrained),
    title: 'MID',
    width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  },
  {
    dataIndex: 'pse',
    render: getColumnDetailMarketSegmentPseRender(isUnconstrained),
    title: 'PSE',
    width: '90px',
  },
  {
    dataIndex: 'energy_product_ref',
    render: getColumnEnergyProductRender(),
    title: 'Product',
    width: '55px',
  },
  {
    dataIndex: 'contracts',
    render: getColumnContractsRender(isUnconstrained),
    title: 'Contracts',
    width: '98px',
  },
  {
    dataIndex: 'contactInfos',
    render: getColumnContactInfoRender(
      isUnconstrained,
      undefined,
      getMarketSegmentTitle,
    ),
    title: 'Contact',
    width: isUnconstrained
      ? '33%'
      : VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH,
  },
  {
    dataIndex: 'misc_infos',
    render: getColumnMiscInfosRender(
      isUnconstrained,
      getMarketSegmentTitle,
      handleExpandMiscInfos,
    ),
    title: 'Misc Info',
    width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  },
];
