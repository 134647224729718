import { TToEntityId } from '../../types/ToEntity';
import { AxiosResponse } from 'axios';
import { API } from 'aws-amplify';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from '../constants';
import { TRANSMISSION_PRIORITY_SEGMENT } from './constants';
import { TETagTemplateId } from '../../types/ETag';
import {
  IEtagTransmissionPriorityConfiguration,
  IEtagTransmissionPriorityConfigurationResponse,
} from '../../interfaces/ETag';

export const retrieveTransmissionPrioritySegment = async (
  toEntityId: TToEntityId,
  templateId: TETagTemplateId,
): Promise<AxiosResponse<any>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TRANSMISSION_PRIORITY_SEGMENT(toEntityId, templateId),
    DEFAULT_OPTIONS,
  );
};

export const saveTransmissionPrioritySegment = async (
  toEntityId: TToEntityId,
  templateId: TETagTemplateId,
  data: IEtagTransmissionPriorityConfiguration[],
): Promise<AxiosResponse<IEtagTransmissionPriorityConfigurationResponse>> => {
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TRANSMISSION_PRIORITY_SEGMENT(toEntityId, templateId),
    {
      ...DEFAULT_OPTIONS,
      body: {
        data_rows: data,
      },
    },
  );
};
