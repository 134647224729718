import ETagFilterMisc from 'components/molecules/ETagFilterMisc/ETagFilterMisc';
import ETagFilterString from 'components/molecules/ETagFilterString/ETagFilterString';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import {
  ETAG_BOOLEAN_OPTIONS,
  ETAG_TEMPLATE_FILTER_ENUM_OPTIONS_MAP,
} from 'constants/ETag';
import {
  ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE,
  STANDARD_SPACING,
} from 'constants/styles';
import { EFilterType } from 'enums/Filter';
import { IFilterMisc, IFilterString } from 'interfaces/Filter';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TETagTemplateFilterConfiguration,
  TETagTemplateFilterEnumeration,
} from 'types/ETag';
import {
  generateNewFilterMisc,
  generateNewFilterString,
  getUpdatedInternalFilterMiscs,
  getUpdatedInternalFilterStrings,
} from 'utils/filter';

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-right: ${STANDARD_SPACING};
  }
`;

const Label = styled.div`
  flex-shrink: 0;
`;

const FilterRowLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin-bottom: 4px;
  }

  > :not(:last-child) {
    margin-right: 4px;
  }
`;

// Specialize the Select component
interface IEnumerationSelectProps {
  width: number;
}

const EnumerationSelect = styled(
  (props: ISelectProps<TETagTemplateFilterEnumeration>) =>
    Select<TETagTemplateFilterEnumeration>(props),
)<IEnumerationSelectProps>`
  margin-bottom: 4px;
  width: ${(props) => props.width}px;
`;

interface IProps {
  filterConfiguration: TETagTemplateFilterConfiguration;
  isDisabled?: boolean;
  onChange: (filterConfiguration: TETagTemplateFilterConfiguration) => void;
}

const ETagTemplateFilter = (props: IProps): JSX.Element => {
  const { filterConfiguration, isDisabled, onChange } = props;
  const [internalFilterMiscs, setInternalFilterMiscs] = useState<IFilterMisc[]>(
    [],
  );
  const [internalFilterStrings, setInternalFilterStrings] = useState<
    IFilterString[]
  >([]);

  useEffect(
    () => {
      if (filterConfiguration.filterMiscs !== undefined) {
        setInternalFilterMiscs(
          getUpdatedInternalFilterMiscs(
            internalFilterMiscs,
            filterConfiguration.filterMiscs,
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConfiguration.filterMiscs],
  );

  useEffect(
    () => {
      if (filterConfiguration.filterStrings !== undefined) {
        setInternalFilterStrings(
          getUpdatedInternalFilterStrings(
            internalFilterStrings,
            filterConfiguration.filterStrings,
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConfiguration.filterStrings],
  );

  const handleBooleanEnumerationChange = (
    value: TETagTemplateFilterEnumeration | undefined,
  ) => {
    onChange({ ...filterConfiguration, filterBoolean: value as boolean });
  };

  const handleEnumerationChange = (
    values: TETagTemplateFilterEnumeration[],
  ) => {
    onChange({
      ...filterConfiguration,
      filterEnumerations: values,
    });
  };

  const updateFilterConfigurationForFilterMiscs = (
    filterMiscs: IFilterMisc[],
  ) => {
    setInternalFilterMiscs(filterMiscs);

    onChange({ ...filterConfiguration, filterMiscs });
  };

  const handleFilterMiscChange = (index: number) => (value: IFilterMisc) => {
    const updatedInternalFilterMiscs: IFilterMisc[] = [...internalFilterMiscs];

    updatedInternalFilterMiscs[index] = value;

    updateFilterConfigurationForFilterMiscs(updatedInternalFilterMiscs);
  };

  const handleFilterMiscAdd = () => {
    const updatedInternalFilterMiscs: IFilterMisc[] = [...internalFilterMiscs];

    updatedInternalFilterMiscs.push(generateNewFilterMisc());

    updateFilterConfigurationForFilterMiscs(updatedInternalFilterMiscs);
  };

  const handleFilterMiscRemove = (index: number) => () => {
    const updatedInternalFilterMiscs: IFilterMisc[] = [...internalFilterMiscs];

    updatedInternalFilterMiscs.splice(index, 1);

    updateFilterConfigurationForFilterMiscs(updatedInternalFilterMiscs);
  };

  const updateFilterConfigurationForFilterStrings = (
    filterStrings: IFilterString[],
  ) => {
    setInternalFilterStrings(filterStrings);

    onChange({ ...filterConfiguration, filterStrings });
  };

  const handleFilterStringChange =
    (index: number) => (value: IFilterString) => {
      const updatedInternalFilterStrings: IFilterString[] = [
        ...internalFilterStrings,
      ];

      updatedInternalFilterStrings[index] = value;

      updateFilterConfigurationForFilterStrings(updatedInternalFilterStrings);
    };

  const handleFilterStringAdd = () => {
    const updatedInternalFilterStrings: IFilterString[] = [
      ...internalFilterStrings,
    ];

    updatedInternalFilterStrings.push(generateNewFilterString());

    updateFilterConfigurationForFilterStrings(updatedInternalFilterStrings);
  };

  const handleFilterStringRemove = (index: number) => () => {
    const updatedInternalFilterStrings: IFilterString[] = [
      ...internalFilterStrings,
    ];

    updatedInternalFilterStrings.splice(index, 1);

    updateFilterConfigurationForFilterStrings(updatedInternalFilterStrings);
  };

  const getFilterInputs = (): JSX.Element => {
    switch (filterConfiguration.filterType) {
      case EFilterType.Boolean:
        return (
          <EnumerationSelect
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleBooleanEnumerationChange}
            options={ETAG_BOOLEAN_OPTIONS}
            placeholder=''
            showSearch={true}
            value={filterConfiguration.filterBoolean!}
            valueToUid={(value: TETagTemplateFilterEnumeration): string =>
              value as string
            }
            width={filterConfiguration.inputWidth}
          />
        );
      case EFilterType.Enumeration:
        return (
          <EnumerationSelect
            allowClear={true}
            allowMultiple={true}
            isDisabled={isDisabled}
            listHeight={ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE}
            maxTagCount='responsive'
            onChangeMultiple={handleEnumerationChange}
            options={
              ETAG_TEMPLATE_FILTER_ENUM_OPTIONS_MAP[
                filterConfiguration.dataIndex
              ]
            }
            placeholder=''
            showSearch={true}
            values={filterConfiguration.filterEnumerations!}
            valueToUid={(value: TETagTemplateFilterEnumeration): string =>
              value as string
            }
            width={filterConfiguration.inputWidth}
          />
        );
      case EFilterType.Misc:
        return (
          <FilterRowLayout>
            {internalFilterMiscs.map(
              (filterMisc: IFilterMisc, index: number): JSX.Element => (
                <ETagFilterMisc
                  filterMisc={filterMisc}
                  inputWidth={filterConfiguration.inputWidth}
                  isAddDisabled={index < internalFilterMiscs.length - 1}
                  isDisabled={isDisabled}
                  key={index.toString()}
                  onAdd={handleFilterMiscAdd}
                  onChange={handleFilterMiscChange(index)}
                  onRemove={handleFilterMiscRemove(index)}
                />
              ),
            )}
          </FilterRowLayout>
        );
      default:
        return (
          <FilterRowLayout>
            {internalFilterStrings.map(
              (filterString: IFilterString, index: number): JSX.Element => (
                <ETagFilterString
                  isAddDisabled={index < internalFilterStrings.length - 1}
                  isDisabled={isDisabled}
                  filterString={filterString}
                  inputWidth={filterConfiguration.inputWidth}
                  key={index.toString()}
                  onAdd={handleFilterStringAdd}
                  onChange={handleFilterStringChange(index)}
                  onRemove={handleFilterStringRemove(index)}
                />
              ),
            )}
          </FilterRowLayout>
        );
    }
  };

  return (
    <Layout>
      <Label>{filterConfiguration.label}:</Label>
      {getFilterInputs()}
    </Layout>
  );
};

export default ETagTemplateFilter;
