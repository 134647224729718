import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import useETagTagIdsEditColumns from 'components/organisms/ETagTagIdsEditable/hooks/useETagTagIdsEditColumns';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import { IETagTagId } from 'interfaces/ETag';
import { IOffset } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback } from 'react';
import { getInitialETagTagId } from 'utils/detail';

export type TETagTagIdEntityInfoChangeHandler = (
  value: IEntityInfo | null | undefined,
  record: IETagTagId,
) => void;

export type TETagTagIdTagCodeChangeHandler = (
  tagCode: string,
  record: IETagTagId,
) => void;

export interface IETagTagIdsEditableProps {
  addButtonOffset?: IOffset;
  data: IETagTagId[];
  initialEditKey: string;
  initialETagTagIds: IETagTagId[] | undefined;
  isDetailDeleted: boolean;
  isDetailUpdating: boolean;
  isDisabled?: boolean;
  maximumAllowableAdds: number;
  onAdd?: (value: unknown, record: IETagTagId) => void;
  onCpseChange: TETagTagIdEntityInfoChangeHandler;
  onGcaChange: TETagTagIdEntityInfoChangeHandler;
  onLcaChange: TETagTagIdEntityInfoChangeHandler;
  onRemove?: (record: IETagTagId) => void;
  onTagCodeChange?: TETagTagIdTagCodeChangeHandler;
  registryEntities: IRegistryEntity[];
  removeButtonOffset?: IOffset;
  toEntities?: IToEntity[];
}

const ETagTagIdsEditable = (props: IETagTagIdsEditableProps): JSX.Element => {
  const {
    addButtonOffset,
    data,
    initialEditKey,
    initialETagTagIds,
    isDetailDeleted,
    isDetailUpdating,
    isDisabled,
    maximumAllowableAdds,
    onAdd,
    onCpseChange,
    onGcaChange,
    onLcaChange,
    onRemove,
    onTagCodeChange,
    registryEntities,
    removeButtonOffset,
    toEntities,
  } = props;

  const eTagTagIdsColumns = useETagTagIdsEditColumns(
    onRemove !== undefined,
    initialETagTagIds,
    isDetailDeleted,
    isDetailUpdating,
    onCpseChange,
    onGcaChange,
    onLcaChange,
    registryEntities,
    onTagCodeChange,
    toEntities,
  );

  const initialiser = useCallback(
    (): IETagTagId => getInitialETagTagId(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditableDataTable<IETagTagId>
      addButtonOffset={addButtonOffset}
      columns={eTagTagIdsColumns}
      data={data}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={maximumAllowableAdds}
      onAdd={onAdd}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={removeButtonOffset}
    />
  );
};

export default ETagTagIdsEditable;
