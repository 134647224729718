import { IDateTimeShortcut } from 'components/molecules/DateTimePicker/types';

export const injectContainer =
  (setContainer: (element: HTMLElement) => void) => (): HTMLElement => {
    const container: HTMLElement = window.document.createElement('div');

    window.document.body.appendChild(container);

    setContainer(container);

    return container;
  };

export const injectDateTimeShortcuts = (
  container: HTMLElement,
  onDateTimeShortcutClick: (dateTimeShortcut: IDateTimeShortcut) => void,
  dateTimeShortcuts?: IDateTimeShortcut[],
) => {
  if (dateTimeShortcuts === undefined) {
    return container;
  }

  const pickerRangersElements: HTMLCollectionOf<Element> =
    container.getElementsByClassName('ant-picker-ranges');

  if (pickerRangersElements.length === 1) {
    const ranges: HTMLElement = pickerRangersElements[0] as HTMLElement;

    dateTimeShortcuts.forEach((dateTimeShortcut: IDateTimeShortcut) => {
      const li: HTMLElement = window.document.createElement('li');
      const a: HTMLElement = window.document.createElement('a');

      // Use the same class as now for consistent button styles
      a.className = 'ant-picker-now-btn';
      a.onclick = () => {
        onDateTimeShortcutClick(dateTimeShortcut);
      };
      a.textContent = dateTimeShortcut.label;

      li.appendChild(a);
      ranges.appendChild(li);
    });
  } else {
    throw new Error('Invalid number of picker ranges elements found');
  }
};
