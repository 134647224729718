import {
  ANT_COLOUR_BLANK,
  ANT_SIZE_VALUE,
  ANT_WIDTH_VALUE,
  MARCHING_ANTS_DURATION,
} from 'components/organisms/ProfileDataGrid/ProfileDataGridEditRow/constants';
import { DATA_GRID_CONTEXT_MENU_ID } from 'constants/Detail';
import {
  MARCHING_ANTS_Z_INDEX,
  PROFILE_DATA_GRID_ROW_HEIGHT_VALUE,
} from 'constants/styles';
import {
  IDataGridSelectionContext,
  ISelectedCellsMapDimensions,
  IThemedProps,
} from 'interfaces/Component';
import { Context, MouseEvent, useCallback, useContext, useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Row, RowRendererProps } from 'react-data-grid';
import styled, { css, keyframes } from 'styled-components';
import {
  TMarketInfoPricesDataGridRow,
  TMarketInfoPricesDataGridSummaryRow,
} from 'types/Detail';
import { getSelectedCellsMapDimensions } from 'utils/component';
import { getCellBorderColour } from 'utils/styles';
import { IMarketInfoDataGridMenuItemProps } from '../../../MarketInformationView/types';

const marching = keyframes`
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }
  100% {
    background-position: ${2 * ANT_SIZE_VALUE}px 0,
      ${-2 * ANT_SIZE_VALUE}px 100%,
      0 ${-2 * ANT_SIZE_VALUE}px,
      100% ${2 * ANT_SIZE_VALUE}px;
  }
`;

interface IRowContainerProps extends IThemedProps {
  isSelected?: boolean;
  selectedRegionHeight: number;
  selectedRegionLeft: number;
  selectedRegionTop: number;
  selectedRegionWidth: number;
}

const marchingAnts = (props: IRowContainerProps, speed: string) => css`
  ::before {
    animation-duration: ${speed};
    animation-iteration-count: infinite;
    animation-name: ${marching};
    animation-play-state: running;
    animation-timing-function: linear;
    background-image: linear-gradient(
        to right,
        ${getCellBorderColour(props)} 50%,
        ${ANT_COLOUR_BLANK} 50%
      ),
      linear-gradient(
        to right,
        ${getCellBorderColour(props)} 50%,
        ${ANT_COLOUR_BLANK} 50%
      ),
      linear-gradient(
        to bottom,
        ${getCellBorderColour(props)} 50%,
        ${ANT_COLOUR_BLANK} 50%
      ),
      linear-gradient(
        to bottom,
        ${getCellBorderColour(props)} 50%,
        ${ANT_COLOUR_BLANK} 50%
      );
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: ${ANT_SIZE_VALUE}px ${ANT_WIDTH_VALUE}px,
      ${ANT_SIZE_VALUE}px ${ANT_WIDTH_VALUE}px,
      ${ANT_WIDTH_VALUE}px ${ANT_SIZE_VALUE}px,
      ${ANT_WIDTH_VALUE}px ${ANT_SIZE_VALUE}px;
    content: '';
    height: ${props.selectedRegionHeight - 6}px;
    left: ${props.selectedRegionLeft + 3}px;
    pointer-events: none;
    position: absolute;
    top: ${props.selectedRegionTop + 3}px;
    width: ${props.selectedRegionWidth - 6}px;
    z-index: ${MARCHING_ANTS_Z_INDEX};
  }
`;

const RowContainer = styled.div<IRowContainerProps>`
  ${(props) =>
    props.isSelected
      ? css`
          ${marchingAnts(props, MARCHING_ANTS_DURATION)}
        `
      : ''}
`;

interface IMarketInfoDataGridEditRowProps
  extends RowRendererProps<
    TMarketInfoPricesDataGridRow,
    TMarketInfoPricesDataGridSummaryRow
  > {
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
  initialDataSet?: any[];
}

const MarketInfoDataGridEditRow = ({
  DataGridSelectionContext,
  initialDataSet,
  row,
  rowIdx,
  top,
  viewportColumns,
  ...rest
}: IMarketInfoDataGridEditRowProps): JSX.Element => {
  const { selectedCells, selectedSourceCells } =
    useContext<IDataGridSelectionContext>(DataGridSelectionContext);
  const { currentTheme } = useThemeSwitcher();
  const { show } = useContextMenu({ id: DATA_GRID_CONTEXT_MENU_ID });

  const {
    isSelected,
    selectedRegionHeight,
    selectedRegionLeft,
    selectedRegionWidth,
  } = useMemo(() => {
    const {
      numSelectedColumns,
      numSelectedRows,
      startPosition,
    }: ISelectedCellsMapDimensions =
      getSelectedCellsMapDimensions(selectedSourceCells);

    let selectedRegionLeft: number = 0;
    let selectedRegionWidth: number = 0;
    let index: number = 0;

    while (index < startPosition.idx && index < viewportColumns.length) {
      selectedRegionLeft += viewportColumns[index].width as number;
      index += 1;
    }

    while (
      index < startPosition.idx + numSelectedColumns &&
      index < viewportColumns.length
    ) {
      selectedRegionWidth += viewportColumns[index].width as number;
      index += 1;
    }

    return {
      isSelected: startPosition.rowIdx === rowIdx,
      selectedRegionHeight:
        numSelectedRows * PROFILE_DATA_GRID_ROW_HEIGHT_VALUE,
      selectedRegionLeft,
      selectedRegionWidth,
    };
  }, [rowIdx, selectedSourceCells, viewportColumns]);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      const dataGridMenuItemProps: IMarketInfoDataGridMenuItemProps = {
        marketInfoPricesDataGridRow: row,
        selectedCells,
      };

      show(event, { props: dataGridMenuItemProps });
    },
    [row, selectedCells, show],
  );

  return (
    <RowContainer
      currentTheme={currentTheme!}
      isSelected={isSelected}
      selectedRegionHeight={selectedRegionHeight}
      selectedRegionLeft={selectedRegionLeft}
      selectedRegionTop={top}
      selectedRegionWidth={selectedRegionWidth}
    >
      <Row
        {...rest}
        onContextMenu={handleContextMenu}
        row={row}
        rowIdx={rowIdx}
        top={top}
        viewportColumns={viewportColumns}
      />
    </RowContainer>
  );
};

export default MarketInfoDataGridEditRow;
