import DateTimeContainer from 'components/molecules/DateTimeContainer/DateTimeContainer';
import PopoverDataTable from 'components/molecules/PopoverDataTable/PopoverDataTable';
import {
  getInitialRowForId,
  isInitialProfileDataGridRow,
} from 'components/organisms/ProfileInformationView/helpers';
import {
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import { ID_KEY } from 'constants/General';
import {
  DATA_GRID_CELL_CONTAINER,
  ERROR_RED,
  WARNING_ORANGE,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { ESelectedCellType } from 'enums/Component';
import { EProfileDataGridCellType, EProfileFormat } from 'enums/Detail';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import {
  IContainerSelectable,
  IDataGridCellProps,
  IDataGridSelectionContext,
} from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { ICurtailmentData, IEnergyProfile } from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { Context, useCallback, useContext, useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';
import { TRootState } from 'types/Redux';
import { getProfileInformationRowCellData } from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import {
  getHighlightedStyle,
  getSelectedStyle,
  inputValueChanged,
} from 'utils/styles';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface IContainerProps extends IContainerSelectable {
  backgroundColour?: string;
}

const Container = styled.div<IContainerProps>`
  ${DATA_GRID_CELL_CONTAINER}

  ${(props) =>
    props.backgroundColour === undefined
      ? ''
      : `background-color: ${props.backgroundColour};`}

  ${(props) => inputValueChanged(props)}

  ${(props) => getHighlightedStyle(props)}

  ${(props) => getSelectedStyle(props)}
`;

export interface IProfileDataGridCellReviewProps
  extends IDataGridCellProps<TProfileDataGridRow, TProfileDataGridSummaryRow> {
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
  getBackgroundColour?: (
    energyProfile: IEnergyProfile,
    selectedRequestKey: string,
  ) => string | undefined;
  initialDataSet?: TProfileDataGridRow[];
  isAlwaysChanged?: boolean;
  isUnconstrained?: boolean;
}

const retrievePhysicalSegmentsProfilesRowCellState = (state: TRootState) => {
  const {
    pageMode,
    selectedProfileFormat,
    selectedRequestKey,
    selectedRequestType,
    viewMode,
  } = state.detail.present;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    pageMode,
    selectedProfileFormat,
    selectedRequestKey,
    selectedRequestType,
    timeZone,
    viewMode,
  };
};

const ProfileDataGridCellReview = ({
  column: { idx, key },
  DataGridSelectionContext,
  getBackgroundColour,
  initialDataSet,
  isUnconstrained = false,
  isAlwaysChanged = false,
  onMouseDown,
  onMouseOver,
  onMouseUp,
  row,
  rowIdx,
}: IProfileDataGridCellReviewProps): JSX.Element => {
  const { dataGridHasFocus, selectedCells, selectedPrimaryCell } =
    useContext<IDataGridSelectionContext>(DataGridSelectionContext);
  const { currentTheme } = useThemeSwitcher();
  const {
    pageMode,
    selectedProfileFormat,
    selectedRequestKey,
    selectedRequestType,
    timeZone,
    viewMode,
  } = useSelector(retrievePhysicalSegmentsProfilesRowCellState);
  const isAdjustmentViewMode: boolean =
    viewMode === EViewMode.EditETagAdjustment ||
    viewMode === EViewMode.EditETagAdjustmentWithATF;
  const pathStart: IProfileDataGridCell | null | undefined =
    row[PROFILE_PATH_START_KEY];
  const pathStop: IProfileDataGridCell | null | undefined =
    row[PROFILE_PATH_STOP_KEY];
  const start: string = isEmptyValue(pathStart)
    ? ''
    : (pathStart!.value as string);
  const stop: string = isEmptyValue(pathStop)
    ? ''
    : (pathStop!.value as string);
  let containerBackgroundColour: string | undefined = undefined;

  const { cellType: initialCellType, cellValue: initialCellValue } =
    useMemo(() => {
      const rowId: IProfileDataGridCell | null | undefined = row[ID_KEY];

      if (rowId === undefined) {
        throw new Error(`Missing key: id for row: ${JSON.stringify(row)}`);
      }

      const initialRow: TProfileDataGridRow | undefined =
        rowId === null
          ? undefined
          : getInitialRowForId(rowId.value as string, initialDataSet);
      const initialCell: IProfileDataGridCell | null | undefined =
        initialRow === undefined ? null : initialRow[key];

      return getProfileInformationRowCellData(
        initialCell === undefined ? null : initialCell,
        viewMode,
        selectedRequestKey,
        start,
        stop,
        isUnconstrained,
      );
    }, [
      key,
      initialDataSet,
      isUnconstrained,
      row,
      selectedRequestKey,
      start,
      stop,
      viewMode,
    ]);

  const {
    additionalContent,
    cellBackgroundColour,
    cellType,
    cellValue,
    columns,
    data,
  } = useMemo(() => {
    const cell: IProfileDataGridCell | null | undefined = row[key];

    if (cell === undefined) {
      throw new Error(`Invalid key: ${key} for row: ${JSON.stringify(row)}`);
    }

    return getProfileInformationRowCellData(
      cell,
      viewMode,
      selectedRequestKey,
      start,
      stop,
      isUnconstrained,
      getBackgroundColour,
    );
  }, [
    getBackgroundColour,
    isUnconstrained,
    key,
    row,
    selectedRequestKey,
    start,
    stop,
    viewMode,
  ]);

  if (viewMode === EViewMode.ReviewETag || isAdjustmentViewMode) {
    containerBackgroundColour = cellBackgroundColour;
  }

  const adjustedInitialCellValue = useMemo(
    () =>
      initialCellType === EProfileDataGridCellType.DateTimeString &&
      !isEmptyValue(initialCellValue)
        ? ZonedDateTime.parseIso(initialCellValue as string, timeZone).format(
            DATE_TIME_FORMAT,
          )
        : initialCellValue,
    [initialCellType, initialCellValue, timeZone],
  );

  const adjustedCellValue = useMemo(
    () =>
      cellType === EProfileDataGridCellType.DateTimeString &&
      !isEmptyValue(cellValue)
        ? ZonedDateTime.parseIso(cellValue as string, timeZone).format(
            DATE_TIME_FORMAT,
          )
        : cellValue,
    [cellType, cellValue, timeZone],
  );

  const highlightColour: string | undefined = useMemo(() => {
    if (key === PROFILE_PATH_START_KEY || key === PROFILE_PATH_STOP_KEY) {
      if (
        (key === PROFILE_PATH_START_KEY && isEmptyValue(start)) ||
        (key === PROFILE_PATH_STOP_KEY && isEmptyValue(stop))
      ) {
        if (
          !isAdjustmentViewMode ||
          (isAdjustmentViewMode && selectedRequestType !== undefined)
        ) {
          return ERROR_RED;
        }
      }
    } else if (isInitialProfileDataGridRow(row)) {
      return WARNING_ORANGE;
    }

    return undefined;
  }, [isAdjustmentViewMode, key, row, selectedRequestType, start, stop]);

  const handleMouseDown = useCallback(() => {
    onMouseDown?.({
      idx,
      rowIdx,
    });
  }, [idx, onMouseDown, rowIdx]);

  const handleMouseOver = useCallback(() => {
    onMouseOver?.({
      idx,
      rowIdx,
    });
  }, [idx, onMouseOver, rowIdx]);

  const handleMouseUp = useCallback(() => {
    onMouseUp?.({
      idx,
      rowIdx,
    });
  }, [idx, onMouseUp, rowIdx]);

  const selectedCellType: ESelectedCellType = useMemo(() => {
    const isPrimaryCell: boolean =
      selectedPrimaryCell !== undefined &&
      selectedPrimaryCell.idx === idx &&
      selectedPrimaryCell.rowIdx === rowIdx;
    const isSelectedCell: boolean =
      selectedCells[idx] !== undefined &&
      selectedCells[idx][rowIdx] !== undefined &&
      selectedCells[idx][rowIdx] === true;

    if (isPrimaryCell) {
      return ESelectedCellType.Primary;
    }

    if (isSelectedCell) {
      return ESelectedCellType.Selected;
    }

    return ESelectedCellType.None;
  }, [idx, rowIdx, selectedCells, selectedPrimaryCell]);

  const content = useMemo(() => {
    const valueChanged: boolean =
      pageMode === EPageMode.Edit &&
      viewMode !== EViewMode.EditETagCorrection &&
      (selectedProfileFormat === EProfileFormat.StartStop || isAlwaysChanged) &&
      ((cellType === EProfileDataGridCellType.DateTimeString &&
        ((isEmptyValue(initialCellValue) && !isEmptyValue(cellValue)) ||
          (!isEmptyValue(initialCellValue) &&
            !isEmptyValue(cellValue) &&
            !ZonedDateTime.parseIso(
              initialCellValue as string,
              timeZone,
            ).isSame(
              ZonedDateTime.parseIso(cellValue as string, timeZone),
            )))) ||
        (cellType !== EProfileDataGridCellType.DateTimeString &&
          adjustedInitialCellValue !== adjustedCellValue));

    return cellType === EProfileDataGridCellType.DateTimeString ? (
      <DateTimeContainer
        currentTheme={currentTheme!}
        hasFocus={dataGridHasFocus}
        highlightColour={highlightColour}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
        onMouseUp={handleMouseUp}
        selectedCellType={selectedCellType}
        valueChanged={valueChanged}
      >
        {adjustedCellValue}
      </DateTimeContainer>
    ) : (
      <Container
        backgroundColour={containerBackgroundColour}
        currentTheme={currentTheme!}
        hasFocus={dataGridHasFocus}
        highlightColour={highlightColour}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
        onMouseUp={handleMouseUp}
        selectedCellType={selectedCellType}
        valueChanged={valueChanged}
      >
        {adjustedCellValue}
      </Container>
    );
  }, [
    adjustedCellValue,
    adjustedInitialCellValue,
    cellType,
    cellValue,
    containerBackgroundColour,
    currentTheme,
    dataGridHasFocus,
    handleMouseDown,
    handleMouseOver,
    handleMouseUp,
    highlightColour,
    initialCellValue,
    isAlwaysChanged,
    pageMode,
    selectedCellType,
    selectedProfileFormat,
    timeZone,
    viewMode,
  ]);

  return (
    <PopoverDataTable<IViewDataTableColumn<ICurtailmentData>, ICurtailmentData>
      additionalContent={additionalContent}
      columns={columns}
      data={data}
      isDisabled={selectedProfileFormat !== EProfileFormat.StartStop}
      pagination={false}
      rowKey={'code'}
      title={`Curtailment(s) for:\n${start} - ${stop}`}
    >
      {content}
    </PopoverDataTable>
  );
};

export default ProfileDataGridCellReview;
