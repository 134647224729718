import { EApprovalReasons, EApprovalStatus } from 'enums/Approval';
import { IETagReasons } from 'interfaces/ETag';

export const STATUS_KEYS = {
  [EApprovalStatus.Approved]: EApprovalReasons.Approve,
  [EApprovalStatus.Denied]: EApprovalReasons.Denied,
  [EApprovalStatus.Study]: EApprovalReasons.Study,
};

export const DEFAULT_REASONS: IETagReasons = {
  approve_reasons: [],
  denied_reasons: [],
  study_reasons: [],
};
