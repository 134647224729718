import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal as AntDesignModal } from 'antd';
import { AxiosResponse } from 'axios';
import AlertSoundIcon from 'components/atoms/AlertSoundIcon/AlertSoundIcon';
import Input, { IInputProps } from 'components/atoms/Input/Input';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import SelectEnum, {
  ISelectEnumProps,
} from 'components/molecules/Select/SelectEnum';
import SeverityIcon from 'components/molecules/SeverityIcon/SeverityIcon';
import {
  alertMessateTemplateAttributesEqual,
  alertRulesEqual,
  CORE_ATTRIBUTES,
  ensureCoreAttributes,
  filteredAttributes,
} from 'components/organisms/AlertRulesConfiguration/helpers';
import { ALERT_MESSAGE_ATTRIBUTE_MAP } from 'constants/Alert';
import { COLUMN_LAYOUT_SHARED_STYLES } from 'constants/styles';
import {
  EAlertMessageAttribute,
  EAlertRuleType,
  EAlertSound,
  ENoteType,
  ETransactionApprovalStatus,
} from 'enums/Alert';
import {
  ECompositeState,
  EMessageType,
  ERequestStatus,
  ERequestType,
} from 'enums/ETag';
import { EActionState, ESeverity } from 'enums/General';
import { ETheme } from 'enums/Style';
import usePermissions from 'hooks/usePermissions';
import {
  IAlertColourEffect,
  IAlertRule,
  IAlertRuleByApprovalStatus,
  IAlertRuleByApprovalStatusAndUser,
  IAlertRuleByCompositeStateChange,
  IAlertRuleByMessageType,
  IAlertRuleByNotesAddition,
  IAlertRuleByRequestStatus,
  IAlertRuleByRequestStatusAndUser,
  IAlertRuleSaveResponse,
} from 'interfaces/Alert';
import { IOption } from 'interfaces/Component';
import { IToEntity } from 'interfaces/ToEntity';
import { ChangeEvent, useEffect, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { saveAlertRule } from 'services/alert/rules';
import styled from 'styled-components';
import { TEditableAlertRule } from 'types/Alert';
import { TThemeValue } from 'types/Style';
import { captureError } from 'utils/error';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';
import { replaceDanglingParenWithUndefined } from 'utils/styles';

const { confirm } = AntDesignModal;

export const WIDTH = 500;
export const HEIGHT = 980;
const LABEL_WIDTH_VALUE = 167;
const INPUT_ITEM_STYLE = `
  flex-grow: 100;
  width: 100px;
`;

const Wrapper = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

interface IStyledLabelProps {
  width?: string;
}

const StyledLabel = styled.div<IStyledLabelProps>`
  font-weight: bold;
  ${(props) =>
    `width: ${
      props.width === undefined ? `${LABEL_WIDTH_VALUE}px` : props.width
    }`};
  padding: 3px;
`;

const StyledInput = styled((props: IInputProps) => Input(props))`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectAlertRuleType = styled(
  (props: ISelectEnumProps<EAlertRuleType>) =>
    SelectEnum<EAlertRuleType>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectAlertSeverity = styled((props: ISelectEnumProps<ESeverity>) =>
  SelectEnum<ESeverity>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const ICON_STYLE = `
  > .anticon {
    font-size: 18px;
  }
  margin-top: 1px;
`;

const IconWrapper = styled.div`
  ${ICON_STYLE}
`;

export const DEFAULTS_BY_SEVERITY: Record<
  ESeverity,
  { color_effect: IAlertColourEffect; sound_effect: EAlertSound }
> = {
  [ESeverity.Info]: {
    color_effect: {
      [ETheme.Light]: '#e6fefe',
      [ETheme.Dark]: '#057a7a',
    },
    sound_effect: EAlertSound.None,
  },
  [ESeverity.Warning]: {
    color_effect: {
      [ETheme.Light]: '#f9ffe5',
      [ETheme.Dark]: '#628000',
    },
    sound_effect: EAlertSound.Beep,
  },
  [ESeverity.Error]: {
    color_effect: {
      [ETheme.Light]: '#fee7e7',
      [ETheme.Dark]: '#7a0505',
    },
    sound_effect: EAlertSound.Alarm,
  },
};

const COLOR_EFFECT_SAMPLE_WIDTH = 82;

interface IColorEffectSampleProps {
  backgroundColor?: string;
}

const ColorEffectSample = styled.div<IColorEffectSampleProps>`
  padding: 3px;
  width: ${COLOR_EFFECT_SAMPLE_WIDTH}px;
  ${(props) =>
    props.backgroundColor === undefined
      ? ''
      : `background-color: ${props.backgroundColor};`}
  text-align: center;
`;

const StyledSelectAlertSound = styled((props: ISelectEnumProps<EAlertSound>) =>
  SelectEnum<EAlertSound>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledInputNumber = styled(InputNumber)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectAlertMessageAttributes = styled(
  (props: ISelectProps<EAlertMessageAttribute>) =>
    Select<EAlertMessageAttribute>(props),
)`
  width: 100%;
`;

const ALERT_MESSAGE_TEMPLATE_OPTIONS: IOption<EAlertMessageAttribute>[] =
  Object.keys(EAlertMessageAttribute)
    .map(
      (attr: string): EAlertMessageAttribute =>
        EAlertMessageAttribute[attr as keyof typeof EAlertMessageAttribute],
    )
    .filter(
      (attribute: EAlertMessageAttribute) =>
        CORE_ATTRIBUTES.indexOf(attribute) < 0,
    )
    .map(
      (attribute: EAlertMessageAttribute): IOption<EAlertMessageAttribute> => {
        return {
          label: ALERT_MESSAGE_ATTRIBUTE_MAP[attribute],
          value: attribute,
        };
      },
    );

const StyledSelectMessageType = styled(
  (props: ISelectEnumProps<EMessageType>) => SelectEnum<EMessageType>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectCompositeState = styled(
  (props: ISelectEnumProps<ECompositeState>) =>
    SelectEnum<ECompositeState>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectTransactionApprovalStatus = styled(
  (props: ISelectEnumProps<ETransactionApprovalStatus>) =>
    SelectEnum<ETransactionApprovalStatus>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectRequestStatus = styled(
  (props: ISelectEnumProps<ERequestStatus>) =>
    SelectEnum<ERequestStatus>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectRequestType = styled(
  (props: ISelectEnumProps<ERequestType>) => SelectEnum<ERequestType>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const StyledSelectNoteType = styled((props: ISelectEnumProps<ENoteType>) =>
  SelectEnum<ENoteType>(props),
)`
  ${INPUT_ITEM_STYLE};
`;

const computeTitlePrefix = (
  initialAlertRule: TEditableAlertRule | undefined,
  alertRule: TEditableAlertRule,
): string | null => {
  if (initialAlertRule?.alert_rule_id === undefined) {
    return `The alert rule has not been saved.`;
  } else if (!alertRulesEqual(initialAlertRule, alertRule)) {
    return `The alert rule has been changed.`;
  } else {
    return null;
  }
};

export interface IAlertRuleEditorProps {
  alertRule: TEditableAlertRule;
  encodedPermissionsId: string;
  onClose: () => any;
  onSave: (alertRule: TEditableAlertRule) => void;
  toEntity: IToEntity;
}

const AlertRuleEditor = (props: IAlertRuleEditorProps): JSX.Element => {
  const {
    alertRule: alertRuleFromProps,
    encodedPermissionsId,
    onClose,
    onSave,
    toEntity,
  } = props;
  const permissions = usePermissions(encodedPermissionsId);
  const themeSwitcher = useThemeSwitcher();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [alertRuleSaveErrorMessage, setAlertRuleSaveErrorMessage] = useState<
    string | null
  >(null);
  const [alertRule, setAlertRule] = useState<TEditableAlertRule>({});
  const [initialAlertRule, setInitialAlertRule] = useState<
    TEditableAlertRule | undefined
  >(undefined);

  useEffect(() => {
    setAlertRule(alertRuleFromProps);
    setInitialAlertRule(alertRuleFromProps);
  }, [alertRuleFromProps]);

  useEffect(() => {
    if (!showModal) {
      setActionState(EActionState.NoAction);
      setAlertRuleSaveErrorMessage(null);
    }
  }, [showModal]);

  const handleSaveAlertRule = async () => {
    setActionState(EActionState.Actioning);
    try {
      const response: AxiosResponse<IAlertRuleSaveResponse> =
        await saveAlertRule(toEntity.to_entity, alertRule as IAlertRule);
      const alertRuleSaveResponse: IAlertRuleSaveResponse = response.data;

      if (response.status === 400) {
        setActionState(EActionState.Failed);
        setAlertRuleSaveErrorMessage(
          String(alertRuleSaveResponse.errorMessage),
        );
      } else if (!isSuccessStatus(response.status)) {
        throw new Error(alertRuleSaveResponse.errorMessage!);
      } else {
        setActionState(EActionState.Succeeded);
        setAlertRuleSaveErrorMessage(null);
        const newAlertRule = {
          ...alertRule,
          alert_rule_id: alertRuleSaveResponse.response.alert_rule_id,
        };
        onSave(newAlertRule);
        // alertRule diffed against initialAlertRule is how we track current edits
        // after save, these must be the same so we show no diffs in the input forms
        setAlertRule(newAlertRule);
        setInitialAlertRule(newAlertRule);
        closeModal();
      }
    } catch (error: any) {
      captureError(
        error,
        `Failed saving alert rule: ${JSON.stringify(alertRule)}`,
      );

      setActionState(EActionState.Failed);

      setAlertRuleSaveErrorMessage(
        'Failed to save alert rule. Please try again later.',
      );
    }
  };

  const closeModalWithConfirmation = async () => {
    const titlePrefix: string | null = computeTitlePrefix(
      initialAlertRule,
      alertRule,
    );
    if (titlePrefix !== null) {
      const modalRef = confirm({
        title: `${titlePrefix} Would you like to discard your changes?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Go back to editing',
        cancelText: 'Discard changes',
        onOk: () => modalRef.update({ content: undefined }),
        onCancel: () => {
          modalRef.update({ content: undefined });
          closeModal();
        },
      });
    } else {
      // alert rule not changed. close without confirmation
      closeModal();
    }
  };

  const closeModal = () => {
    setShowModal(false);
    onClose();
  };

  return (
    <Modal
      footer={
        <EditorFooter
          confirmActionState={actionState}
          confirmLabel='Save'
          encodedPermissionsId={encodeIds(
            [encodedPermissionsId, 'footer'],
            toEntity.to_entity,
          )}
          errorMessage={alertRuleSaveErrorMessage}
          onCancel={closeModalWithConfirmation}
          onConfirm={handleSaveAlertRule}
        />
      }
      isVisible={showModal}
      onCancel={closeModalWithConfirmation}
      title={`Editing Alert Rule for Entity ${toEntity.entity_code}`}
      width={WIDTH}
    >
      <Wrapper>
        <SeparatedRowLayout>
          <StyledLabel>Rule Id:</StyledLabel>
          <StyledInput
            // when alert rule id exists: not having an onChange handler prevents
            // edits, but allows user to select and copy the rule id
            // when alert rule id does not exist: we have to disable editing.
            // For some reason, the editor responds in this case
            isDisabled={
              isEmptyValue(alertRule.alert_rule_id) || !permissions.isExecutable
            }
            value={alertRule.alert_rule_id}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>Type:</StyledLabel>
          <StyledSelectAlertRuleType
            enumType={EAlertRuleType}
            equalityChecker={(a: EAlertRuleType, b: EAlertRuleType): boolean =>
              a === b
            }
            initialValue={initialAlertRule?.type}
            isDisabled={!permissions.isExecutable}
            onChange={(alertRuleType: EAlertRuleType | undefined) =>
              alertRuleType !== undefined &&
              setAlertRule({ ...alertRule, type: alertRuleType })
            }
            value={alertRule.type}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>Name:</StyledLabel>
          <StyledInput
            initialValue={initialAlertRule?.name}
            isDisabled={!permissions.isExecutable}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAlertRule({
                ...alertRule,
                name: e.currentTarget.value,
              })
            }
            value={alertRule.name}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>Group:</StyledLabel>
          <StyledInput
            initialValue={initialAlertRule?.group}
            isDisabled={!permissions.isExecutable}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAlertRule({
                ...alertRule,
                group: e.currentTarget.value,
              })
            }
            value={alertRule.group}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>Severity:</StyledLabel>
          <StyledSelectAlertSeverity
            enumType={ESeverity}
            equalityChecker={(a: ESeverity, b: ESeverity): boolean => a === b}
            initialValue={initialAlertRule?.severity}
            isDisabled={!permissions.isExecutable}
            // a change to severity will trigger a reset of color and sound
            // to default values for that severity
            onChange={(alertSeverity: ESeverity | undefined) =>
              alertSeverity &&
              setAlertRule({
                ...alertRule,
                severity: alertSeverity,
                color_effect: DEFAULTS_BY_SEVERITY[alertSeverity].color_effect,
                sound_effect: DEFAULTS_BY_SEVERITY[alertSeverity].sound_effect,
              })
            }
            optionMapper={(option: IOption<ESeverity>): IOption<ESeverity> => {
              return {
                ...option,
                icon: (
                  <IconWrapper>
                    <SeverityIcon severity={option.value} />
                  </IconWrapper>
                ),
              };
            }}
            value={alertRule.severity}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>{`Color Effect (${themeSwitcher.currentTheme} mode):`}</StyledLabel>
          <StyledInput
            initialValue={
              initialAlertRule?.color_effect?.[
                themeSwitcher.currentTheme! as TThemeValue
              ]
            }
            isDisabled={!permissions.isExecutable}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setAlertRule({
                ...alertRule,
                color_effect: {
                  ...DEFAULTS_BY_SEVERITY[alertRule.severity ?? ESeverity.Info]
                    .color_effect,
                  ...alertRule.color_effect,
                  [themeSwitcher.currentTheme! as TThemeValue]:
                    event.currentTarget.value,
                },
              })
            }
            value={
              alertRule.color_effect?.[
                themeSwitcher.currentTheme! as TThemeValue
              ]
            }
          />
          <ColorEffectSample
            backgroundColor={replaceDanglingParenWithUndefined(
              alertRule.color_effect?.[
                themeSwitcher.currentTheme! as TThemeValue
              ],
            )}
          >
            Sample
          </ColorEffectSample>
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>Sound:</StyledLabel>
          <StyledSelectAlertSound
            enumType={EAlertSound}
            equalityChecker={(a: EAlertSound, b: EAlertSound): boolean =>
              a === b
            }
            initialValue={initialAlertRule?.sound_effect}
            isDisabled={!permissions.isExecutable}
            onChange={(alertSound: EAlertSound | undefined) =>
              alertSound &&
              setAlertRule({ ...alertRule, sound_effect: alertSound })
            }
            optionMapper={(
              option: IOption<EAlertSound>,
            ): IOption<EAlertSound> => {
              return {
                ...option,
                icon: (
                  <IconWrapper>
                    <AlertSoundIcon sound={option.value} />
                  </IconWrapper>
                ),
              };
            }}
            value={alertRule.sound_effect}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <StyledLabel>Duration (hours):</StyledLabel>
          <StyledInputNumber
            hideArrows={true}
            initialValue={initialAlertRule?.alert_duration_hours}
            isDisabled={!permissions.isExecutable}
            max={168}
            min={1}
            onChange={(value: unknown) =>
              typeof value === 'number' &&
              setAlertRule({
                ...alertRule,
                alert_duration_hours: value,
              })
            }
            value={alertRule.alert_duration_hours}
          />
        </SeparatedRowLayout>
        {alertRule.type === EAlertRuleType.AlertRuleByMessageType && (
          <SeparatedRowLayout>
            <StyledLabel>Message Type:</StyledLabel>
            <StyledSelectMessageType
              enumType={EMessageType}
              equalityChecker={(a: EMessageType, b: EMessageType): boolean =>
                a === b
              }
              initialValue={
                (initialAlertRule as IAlertRuleByMessageType).message_type
              }
              isDisabled={!permissions.isExecutable}
              onChange={(messageType: EMessageType | undefined) =>
                messageType &&
                setAlertRule({
                  ...alertRule,
                  message_type: messageType,
                })
              }
              value={(alertRule as IAlertRuleByMessageType).message_type}
            />
          </SeparatedRowLayout>
        )}
        {alertRule.type === EAlertRuleType.AlertRuleByCompositeStateChange && (
          <SeparatedRowLayout>
            <StyledLabel>Composite State:</StyledLabel>
            <StyledSelectCompositeState
              enumType={ECompositeState}
              equalityChecker={(
                a: ECompositeState,
                b: ECompositeState,
              ): boolean => a === b}
              initialValue={
                (initialAlertRule as IAlertRuleByCompositeStateChange)
                  .composite_state
              }
              isDisabled={!permissions.isExecutable}
              onChange={(compositeState: ECompositeState | undefined) =>
                compositeState &&
                setAlertRule({
                  ...alertRule,
                  composite_state: compositeState,
                })
              }
              value={
                (alertRule as IAlertRuleByCompositeStateChange).composite_state
              }
            />
          </SeparatedRowLayout>
        )}
        {alertRule.type === EAlertRuleType.AlertRuleByApprovalStatus && (
          <>
            <SeparatedRowLayout>
              <StyledLabel>Approval Status:</StyledLabel>
              <StyledSelectTransactionApprovalStatus
                enumType={ETransactionApprovalStatus}
                equalityChecker={(
                  a: ETransactionApprovalStatus,
                  b: ETransactionApprovalStatus,
                ): boolean => a === b}
                initialValue={
                  (initialAlertRule as IAlertRuleByApprovalStatus)
                    .approval_status
                }
                isDisabled={!permissions.isExecutable}
                onChange={(
                  transactionApprovalStatus:
                    | ETransactionApprovalStatus
                    | undefined,
                ) =>
                  transactionApprovalStatus &&
                  setAlertRule({
                    ...alertRule,
                    approval_status: transactionApprovalStatus,
                  })
                }
                optionMapper={(
                  option: IOption<ETransactionApprovalStatus>,
                ): IOption<ETransactionApprovalStatus> | null => {
                  if (
                    option.value !== ETransactionApprovalStatus.Study &&
                    option.value !== ETransactionApprovalStatus.Denied
                  ) {
                    return null;
                  } else {
                    return option;
                  }
                }}
                value={
                  (alertRule as IAlertRuleByApprovalStatus).approval_status
                }
              />
            </SeparatedRowLayout>
            <SeparatedRowLayout>
              <StyledLabel>Entity:</StyledLabel>
              <StyledInput
                initialValue={
                  (initialAlertRule as IAlertRuleByApprovalStatus)?.entity
                }
                isDisabled={!permissions.isExecutable}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAlertRule({
                    ...alertRule,
                    entity: event.currentTarget.value,
                  })
                }
                value={(alertRule as IAlertRuleByApprovalStatus).entity}
              />
            </SeparatedRowLayout>
          </>
        )}
        {alertRule.type === EAlertRuleType.AlertRuleByApprovalStatusAndUser && (
          <>
            <SeparatedRowLayout>
              <StyledLabel>Approval Status:</StyledLabel>
              <StyledSelectTransactionApprovalStatus
                enumType={ETransactionApprovalStatus}
                equalityChecker={(
                  a: ETransactionApprovalStatus,
                  b: ETransactionApprovalStatus,
                ): boolean => a === b}
                initialValue={
                  (initialAlertRule as IAlertRuleByApprovalStatusAndUser)
                    .approval_status
                }
                isDisabled={!permissions.isExecutable}
                onChange={(
                  transactionApprovalStatus:
                    | ETransactionApprovalStatus
                    | undefined,
                ) =>
                  transactionApprovalStatus &&
                  setAlertRule({
                    ...alertRule,
                    approval_status: transactionApprovalStatus,
                  })
                }
                optionMapper={(
                  option: IOption<ETransactionApprovalStatus>,
                ): IOption<ETransactionApprovalStatus> | null => {
                  if (
                    option.value !== ETransactionApprovalStatus.Study &&
                    option.value !== ETransactionApprovalStatus.Denied
                  ) {
                    return null;
                  } else {
                    return option;
                  }
                }}
                value={
                  (alertRule as IAlertRuleByApprovalStatusAndUser)
                    .approval_status
                }
              />
            </SeparatedRowLayout>
            <SeparatedRowLayout>
              <StyledLabel>Entity:</StyledLabel>
              <StyledInput
                initialValue={
                  (initialAlertRule as IAlertRuleByApprovalStatusAndUser)
                    ?.entity
                }
                isDisabled={!permissions.isExecutable}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAlertRule({
                    ...alertRule,
                    entity: event.currentTarget.value,
                  })
                }
                value={(alertRule as IAlertRuleByApprovalStatusAndUser).entity}
              />
            </SeparatedRowLayout>
          </>
        )}
        {alertRule.type === EAlertRuleType.AlertRuleByRequestStatus && (
          <>
            <SeparatedRowLayout>
              <StyledLabel>Request Status:</StyledLabel>
              <StyledSelectRequestStatus
                enumType={ERequestStatus}
                equalityChecker={(
                  a: ERequestStatus,
                  b: ERequestStatus,
                ): boolean => a === b}
                initialValue={
                  (initialAlertRule as IAlertRuleByRequestStatus).request_status
                }
                isDisabled={!permissions.isExecutable}
                onChange={(requestStatus: ERequestStatus | undefined) =>
                  requestStatus &&
                  setAlertRule({
                    ...alertRule,
                    request_status: requestStatus,
                  })
                }
                value={(alertRule as IAlertRuleByRequestStatus).request_status}
              />
            </SeparatedRowLayout>
            <SeparatedRowLayout>
              <StyledLabel>Request Type:</StyledLabel>
              <StyledSelectRequestType
                enumType={ERequestType}
                equalityChecker={(a: ERequestType, b: ERequestType): boolean =>
                  a === b
                }
                initialValue={
                  (initialAlertRule as IAlertRuleByRequestStatus).request_type
                }
                isDisabled={!permissions.isExecutable}
                onChange={(requestType: ERequestType | undefined) =>
                  requestType &&
                  setAlertRule({
                    ...alertRule,
                    request_type: requestType,
                  })
                }
                optionMapper={(
                  option: IOption<ERequestType>,
                ): IOption<ERequestType> | null => {
                  if (
                    option.value === ERequestType.CurrentLevel ||
                    option.value === ERequestType.CurrentPendingLevel ||
                    option.value === ERequestType.None
                  ) {
                    return null;
                  } else {
                    return option;
                  }
                }}
                value={(alertRule as IAlertRuleByRequestStatus).request_type}
              />
            </SeparatedRowLayout>
          </>
        )}
        {alertRule.type === EAlertRuleType.AlertRuleByRequestStatusAndUser && (
          <>
            <SeparatedRowLayout>
              <StyledLabel>Request Status:</StyledLabel>
              <StyledSelectRequestStatus
                enumType={ERequestStatus}
                equalityChecker={(
                  a: ERequestStatus,
                  b: ERequestStatus,
                ): boolean => a === b}
                initialValue={
                  (initialAlertRule as IAlertRuleByRequestStatusAndUser)
                    .request_status
                }
                isDisabled={!permissions.isExecutable}
                onChange={(requestStatus: ERequestStatus | undefined) =>
                  requestStatus &&
                  setAlertRule({
                    ...alertRule,
                    request_status: requestStatus,
                  })
                }
                value={
                  (alertRule as IAlertRuleByRequestStatusAndUser).request_status
                }
              />
            </SeparatedRowLayout>
            <SeparatedRowLayout>
              <StyledLabel>Request Type:</StyledLabel>
              <StyledSelectRequestType
                enumType={ERequestType}
                equalityChecker={(a: ERequestType, b: ERequestType): boolean =>
                  a === b
                }
                initialValue={
                  (initialAlertRule as IAlertRuleByRequestStatusAndUser)
                    .request_type === null ||
                  (initialAlertRule as IAlertRuleByRequestStatusAndUser)
                    .request_type === undefined
                    ? ERequestType.None
                    : (initialAlertRule as IAlertRuleByRequestStatusAndUser)
                        .request_type
                }
                isDisabled={!permissions.isExecutable}
                onChange={(requestType: ERequestType | undefined) =>
                  requestType &&
                  setAlertRule({
                    ...alertRule,
                    request_type:
                      requestType === ERequestType.None
                        ? undefined
                        : requestType,
                  })
                }
                optionMapper={(
                  option: IOption<ERequestType>,
                ): IOption<ERequestType> | null => {
                  if (
                    option.value === ERequestType.CurrentLevel ||
                    option.value === ERequestType.CurrentPendingLevel
                  ) {
                    return null;
                  } else if (option.value === ERequestType.None) {
                    const matchAllOption: IOption<ERequestType> = {
                      label: 'MatchAll',
                      value: ERequestType.None,
                    };
                    return matchAllOption;
                  } else {
                    return option;
                  }
                }}
                value={
                  (alertRule as IAlertRuleByRequestStatusAndUser)
                    .request_type === null ||
                  (alertRule as IAlertRuleByRequestStatusAndUser)
                    .request_type === undefined
                    ? ERequestType.None
                    : (alertRule as IAlertRuleByRequestStatusAndUser)
                        .request_type
                }
              />
            </SeparatedRowLayout>
          </>
        )}
        {alertRule.type === EAlertRuleType.AlertRuleByNotesAddition && (
          <SeparatedRowLayout>
            <StyledLabel>Note Type:</StyledLabel>
            <StyledSelectNoteType
              enumType={ENoteType}
              equalityChecker={(a: ENoteType, b: ENoteType): boolean => a === b}
              initialValue={
                (initialAlertRule as IAlertRuleByNotesAddition).note_type
              }
              isDisabled={!permissions.isExecutable}
              onChange={(noteType: ENoteType | undefined) =>
                noteType &&
                setAlertRule({
                  ...alertRule,
                  note_type: noteType,
                })
              }
              value={(alertRule as IAlertRuleByNotesAddition).note_type}
            />
          </SeparatedRowLayout>
        )}
        <StyledLabel width='100%'>Message Template:</StyledLabel>
        <StyledSelectAlertMessageAttributes
          allowMultiple={true}
          equalityCheckerMultiple={alertMessateTemplateAttributesEqual}
          initialValues={filteredAttributes(
            initialAlertRule?.alert_message_template,
          )}
          isDisabled={!permissions.isExecutable}
          onChangeMultiple={(attributes: EAlertMessageAttribute[]) =>
            setAlertRule({
              ...alertRule,
              alert_message_template: {
                ...alertRule?.alert_message_template,
                attributes: ensureCoreAttributes(attributes),
              },
            })
          }
          options={ALERT_MESSAGE_TEMPLATE_OPTIONS}
          values={filteredAttributes(alertRule.alert_message_template)}
          valueToUid={(value: EAlertMessageAttribute): string => String(value)}
        />
      </Wrapper>
    </Modal>
  );
};

export default AlertRuleEditor;
