import { AlertOutlined, BlockOutlined } from '@ant-design/icons';
import { Drawer as AntDesignDrawer } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import AlertHistory from 'components/molecules/ToEntityAlertHistory/AlertHistory';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS, HIGHLIGHT_BLUE } from 'constants/styles';
import { IAlertConfiguration, IConfiguredAlert } from 'interfaces/Alert';
import { useState } from 'react';
import styled from 'styled-components';
import { TAlertRulesMap } from 'types/Alert';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import Link from '../../atoms/Link/Link';
import { ALERT_ROUTE } from '../../../constants/routes';

const DRAWER_WIDTH_VALUE = 600;

const AlertHistoryIcon = styled(AlertOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const AlertHistoryFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AlertHistoryUILinkSpan = styled.span`
  color: ${HIGHLIGHT_BLUE};
  font-weight: bold;
`;

const AlertHistoryUILinkIconButton = styled(IconButton)`
  color: ${HIGHLIGHT_BLUE};
`;

const AlertHistoryUILinkDiv = styled.div`
  padding-right: 20px;
`;

interface IProps {
  alertConfigurations?: IAlertConfiguration[];
  alertRulesMap: TAlertRulesMap;
  closeAllAlerts: (closedCallback: () => void) => void;
  overrideOnAlert: (
    onAlert: ((configuredAlert: IConfiguredAlert) => void) | undefined,
  ) => void;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityAlertHistory = (props: IProps): JSX.Element => {
  const {
    alertConfigurations,
    alertRulesMap,
    closeAllAlerts,
    overrideOnAlert,
    timeZone,
    toEntityId,
  } = props;

  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [liveAlerts, setLiveAlerts] = useState<IConfiguredAlert[]>([]);

  const handleClose = () => {
    setShowHistory(false);

    overrideOnAlert(undefined);
  };

  const handleHistory = () => {
    overrideOnAlert(handleAlert);

    closeAllAlerts(() => {
      setShowHistory(true);
    });
  };

  const handleAlert = (configuredAlert: IConfiguredAlert) => {
    setLiveAlerts(
      (previousLiveAlerts: IConfiguredAlert[]): IConfiguredAlert[] =>
        previousLiveAlerts.concat({ ...configuredAlert }),
    );
  };

  const handleSetLiveAlerts = (configuredAlerts: IConfiguredAlert[]) => {
    setLiveAlerts((): IConfiguredAlert[] => configuredAlerts);
  };

  return (
    <Tooltip isDisabled={showHistory} title='Alerts History'>
      <IconButton icon={<AlertHistoryIcon />} onClick={handleHistory} />
      <AntDesignDrawer
        destroyOnClose={true}
        maskClosable={false}
        onClose={handleClose}
        title={
          <AlertHistoryFlexDiv>
            <span>Alerts History</span>
            <AlertHistoryUILinkDiv>
              <Tooltip title='Open Alerts History'>
                <SeparatedRowLayout>
                  <AlertHistoryUILinkSpan>Learn more</AlertHistoryUILinkSpan>
                  <Link
                    to={`${ALERT_ROUTE}?toEntityId=${toEntityId}`}
                    external={false}
                    target={'_blank'}
                  >
                    <AlertHistoryUILinkIconButton icon={<BlockOutlined />} />
                  </Link>
                </SeparatedRowLayout>
              </Tooltip>
            </AlertHistoryUILinkDiv>
          </AlertHistoryFlexDiv>
        }
        width={DRAWER_WIDTH_VALUE}
        visible={showHistory}
      >
        <AlertHistory
          alertConfigurations={alertConfigurations}
          alertRulesMap={alertRulesMap}
          liveAlerts={liveAlerts}
          setLiveAlerts={handleSetLiveAlerts}
          timeZone={timeZone}
          toEntityId={toEntityId}
        />
      </AntDesignDrawer>
    </Tooltip>
  );
};

export default ToEntityAlertHistory;
