import { API } from 'aws-amplify';
import { TToEntityId } from '../../types/ToEntity';
import { AxiosResponse } from 'axios';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from '../constants';
import { DOWNLOAD_TAGS_ROUTE } from './constants';
import { TETagTagPrimaryKey } from '../../types/ETag';
import { TTimeZone } from '../../types/DateTime';

export const retrieveTagFiles = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestId?: string,
  timeZone?: TTimeZone | undefined,
): Promise<AxiosResponse<any>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    DOWNLOAD_TAGS_ROUTE(toEntityId, tagPrimaryKey, requestId, timeZone),
    DEFAULT_OPTIONS,
  );
};
