import { LeftCircleOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { useEffect } from 'react';
import styled from 'styled-components';
import { isControlShortcut } from 'utils/general';

const UndoIcon = styled(LeftCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IUndoButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
}

const UndoButton = ({ isDisabled, onClick }: IUndoButtonProps): JSX.Element => {
  useEffect(() => {
    const keydownHandler = (keyboardEvent: KeyboardEvent) => {
      if (isControlShortcut(keyboardEvent, 'z')) {
        keyboardEvent.preventDefault();

        onClick?.();
      }
    };

    window.addEventListener('keydown', keydownHandler);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [onClick]);

  return (
    <Tooltip isDisabled={isDisabled} title='Undo'>
      <IconButton
        icon={<UndoIcon />}
        isDisabled={isDisabled}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default UndoButton;
