import React from 'react';
import { StyledMenu } from './Menu.styled';
import PropTypes from 'prop-types';
import {
  AlertOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FormatPainterOutlined,
  HomeOutlined,
  SettingOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { TTimeZone } from '../../../../types/DateTime';
import { IToEntity } from '../../../../interfaces/ToEntity';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { EMenuActions, TMenuAction } from '../../../../reduxes/Menu/actions';
import SearchTagPopover from '../../../pages/LandingPage/SearchTagPopover';
import { encodeIds } from '../../../../utils/general';
import ToEntityQueryETagAuthoriy from '../../ToEntityQueryETagAuthority/ToEntityQueryETagAuthority';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import usePermissions from '../../../../hooks/usePermissions';

interface IMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  timeZone?: TTimeZone | undefined;
  encodedPermissionsId?: string | undefined;
  toEntity: IToEntity | undefined;
  toEntities: IToEntity[];
}

const Menu = (props: IMenuProps): JSX.Element => {
  const {
    encodedPermissionsId,
    open,
    setOpen,
    timeZone,
    toEntity,
    toEntities,
  } = props;

  const approvalConfigurationPermissionId = encodedPermissionsId
    ? encodeIds(
        [encodedPermissionsId, 'menu:eTagApprovalConfiguration'],
        toEntity?.to_entity,
      )
    : '';
  const approvalConfigurationPermission = usePermissions(
    approvalConfigurationPermissionId,
  );

  const history = useHistory();

  const handleSignOut = () => {
    localStorage.setItem('pciSupportToEntities', JSON.stringify([]));
    Auth.signOut();
  };
  const { currentTheme } = useThemeSwitcher();
  const dispatch = useDispatch();

  const setShowConfigurationMenuItemClicked = (
    showConfiguration: boolean,
  ): TMenuAction => ({
    payload: showConfiguration,
    type: EMenuActions.ShowConfigurationItemClicked,
  });

  const setShowReportsMenuItemClicked = (
    showReports: boolean,
  ): TMenuAction => ({
    payload: showReports,
    type: EMenuActions.ShowReportsItemClicked,
  });

  return (
    <StyledMenu
      open={open}
      onMouseLeave={() => setOpen(!open)}
      currentTheme={currentTheme}
    >
      <div className={'menu-option'} onClick={() => history.push('/')}>
        <HomeOutlined />
        Home
      </div>
      <div className={'menu-option'} onClick={() => history.push('/summary')}>
        <TableOutlined />
        Tag Summaries
      </div>
      <div className={'menu-option'} onClick={() => history.push('/templates')}>
        <FormatPainterOutlined />
        Tag Templates
      </div>
      <div
        className={'menu-option'}
        onClick={() => history.push('/transmission-availability')}
      >
        <DashboardOutlined />
        Transmission Availability
      </div>
      <Link
        to={{
          pathname: `/alert`,
          search: `?toEntityId=${toEntity?.to_entity}`,
        }}
        target={'_blank'}
      >
        <div className={'menu-option'}>
          <AlertOutlined />
          Alerts History
        </div>
      </Link>
      {approvalConfigurationPermission.isDisplayable ? (
        <div
          className={'menu-option'}
          onClick={() => history.push('/approval-configs')}
        >
          <FileDoneOutlined />
          Approval Configuration
        </div>
      ) : null}
      {encodedPermissionsId?.includes('toEntityMonitor') ? (
        <div
          className={'menu-option'}
          onClick={() => dispatch(setShowReportsMenuItemClicked(true))}
        >
          <SettingOutlined />
          Reports
        </div>
      ) : null}
      {toEntity && timeZone ? (
        <>
          {' '}
          <hr />
          <div className={'menu-option'}>
            <SearchTagPopover
              permissionId='landingLink:searchTag'
              selectedToEntity={toEntity}
              timeZone={timeZone}
              toEntities={toEntities}
            >
              <FileSearchOutlined />
              Search Tag
            </SearchTagPopover>
          </div>
        </>
      ) : null}
      <div className={'menu-option-icon'}>
        {timeZone && toEntity ? (
          <ToEntityQueryETagAuthoriy
            encodedPermissionsId={encodeIds([
              encodedPermissionsId!,
              'actionBar:queryETagAuthority',
            ])}
            eTagExtendedIdentifier={undefined}
            timeZone={timeZone}
            toEntity={toEntity}
            isMenuItem={true}
          />
        ) : null}
      </div>
      <hr />
      {encodedPermissionsId?.includes('toEntityMonitor') ? (
        <div
          className={'menu-option'}
          onClick={() => dispatch(setShowConfigurationMenuItemClicked(true))}
        >
          <SettingOutlined />
          Configuration
        </div>
      ) : null}
      <div className={'footer'}>
        <div className={'menu-option'} onClick={() => history.push('/help')}>
          Help
        </div>
        <span>|</span>
        <span onClick={handleSignOut}>Sign out</span>
      </div>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Menu;
