import { IEditSummaryInformation } from 'components/organisms/SummaryInformationView/types';
import { MAXIMUM_CC_ENTITIES } from 'constants/Detail';
import { ETAG_BOOLEAN_OPTIONS, ETAG_ENUM_OPTIONS_MAP } from 'constants/ETag';
import { ETransactionType } from 'enums/ETag';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IEntityInfo } from 'interfaces/Entity';
import { ITagHeaderDataSet } from 'interfaces/SummaryInformation';
import { useCallback, useMemo } from 'react';
import { entityInfoToUid, pseEntityInfoEqual } from 'utils/entity';
import {
  booleanOrNullToUid,
  isEmptyValue,
  listEqual,
  selectOptionLabelFilter,
  simpleEqualityChecker,
} from 'utils/general';
import { getColumnSelectRender } from 'utils/views';

const transactionTypeSelectRender = getColumnSelectRender<
  ETransactionType | null,
  ITagHeaderDataSet
>('100%');
const testFlagSelectRender = getColumnSelectRender<
  boolean | null,
  ITagHeaderDataSet
>('100%');
const ccListSelectRender = getColumnSelectRender<
  IEntityInfo,
  ITagHeaderDataSet
>('100%', undefined, (value: IEntityInfo[] | IEntityInfo | undefined): string =>
  isEmptyValue(value)
    ? ''
    : (value as IEntityInfo[]).length > 3
    ? (value as IEntityInfo[])
        .map(
          (entityInfo: IEntityInfo): string =>
            `${entityInfo.entity_code}-${entityInfo.entity_type[0]}`,
        )
        .join(', ')
    : '',
);

const ccListEqual = listEqual(pseEntityInfoEqual);

const useTagHeaderEditColumns = (
  ccEntitiesOptions: IOption<IEntityInfo>[],
  initialTagHeaderData: ITagHeaderDataSet[] | undefined,
  isDetailDeleted: boolean,
  isDetailDeletedChanged: boolean,
  isDetailUpdating: boolean,
  isDetailUpdatingChanged: boolean,
  onChange: (editSummaryInformation: IEditSummaryInformation) => void,
) => {
  const getTransactionTypeOptions = useCallback(
    async () =>
      ETAG_ENUM_OPTIONS_MAP.transaction_type as IOption<ETransactionType>[],
    [],
  );

  const getInitiailTransactionTypeSelectValue = useCallback(
    (): ETransactionType | null =>
      initialTagHeaderData?.[0] === undefined
        ? null
        : initialTagHeaderData[0].transaction_type,
    [initialTagHeaderData],
  );

  const getInitialTestFlagSelectValue = useCallback(
    (): boolean | null =>
      initialTagHeaderData?.[0] === undefined
        ? null
        : initialTagHeaderData[0].test_flag,
    [initialTagHeaderData],
  );

  const getBooleanOptions = useCallback(async () => ETAG_BOOLEAN_OPTIONS, []);

  const previousCcEntitiesOptions: IOption<IEntityInfo>[] | undefined =
    usePrevious(ccEntitiesOptions);

  const ccEntitiesOptionsChanged: boolean = useMemo(
    () => ccEntitiesOptions !== previousCcEntitiesOptions,
    [ccEntitiesOptions, previousCcEntitiesOptions],
  );

  const getInitialSchedulingEntities = useCallback(
    (): IEntityInfo[] | null =>
      initialTagHeaderData?.[0] === undefined
        ? null
        : initialTagHeaderData[0].ccList,
    [initialTagHeaderData],
  );

  const getCcEntitiesOptions = useCallback(
    async () => ccEntitiesOptions,
    [ccEntitiesOptions],
  );

  return useMemo(
    () => [
      {
        dataIndex: 'transaction_type',
        render: transactionTypeSelectRender({
          equalityChecker: simpleEqualityChecker,
          getInitialValue: getInitiailTransactionTypeSelectValue,
          getOptions: getTransactionTypeOptions,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChange: (value: ETransactionType | null | undefined) => {
            onChange({ tagHeaderData: [{ transaction_type: value }] });
          },
          valueToUid: (value: ETransactionType | null): string =>
            value as string,
        }),
        shouldCellUpdate: (
          record: ITagHeaderDataSet,
          previousRecord: ITagHeaderDataSet,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.transaction_type !== previousRecord.transaction_type,
        title: 'Tag Type',
        width: 'calc(100% * (6 / 24))',
      },
      {
        dataIndex: 'test_flag',
        render: testFlagSelectRender({
          equalityChecker: simpleEqualityChecker,
          getInitialValue: getInitialTestFlagSelectValue,
          getOptions: getBooleanOptions,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChange: (value: boolean | null | undefined) => {
            onChange({ tagHeaderData: [{ test_flag: value }] });
          },
          valueToUid: booleanOrNullToUid,
        }),
        shouldCellUpdate: (
          record: ITagHeaderDataSet,
          previousRecord: ITagHeaderDataSet,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.test_flag !== previousRecord.test_flag,
        title: 'Test Flag',
        width: 'calc(100% * (3 / 24))',
      },
      {
        dataIndex: 'ccList',
        render: ccListSelectRender({
          allowMultiple: true,
          equalityCheckerMultiple: ccListEqual,
          filter: selectOptionLabelFilter,
          getInitialValues: getInitialSchedulingEntities,
          getOptions: getCcEntitiesOptions,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChangeMultiple: (values: IEntityInfo[]) => {
            if (values.length <= MAXIMUM_CC_ENTITIES) {
              onChange({ tagHeaderData: [{ ccList: values }] });
            }
          },
          showSearch: true,
          valueToUid: entityInfoToUid,
        }),
        shouldCellUpdate: (
          record: ITagHeaderDataSet,
          previousRecord: ITagHeaderDataSet,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          ccEntitiesOptionsChanged ||
          !ccListEqual(record.ccList, previousRecord.ccList),
        title: 'CC Entities',
        width: 'calc(100% * (15 / 24))',
      },
    ],
    [
      ccEntitiesOptionsChanged,
      getBooleanOptions,
      getCcEntitiesOptions,
      getInitialSchedulingEntities,
      getInitialTestFlagSelectValue,
      getInitiailTransactionTypeSelectValue,
      getTransactionTypeOptions,
      isDetailDeleted,
      isDetailDeletedChanged,
      isDetailUpdating,
      isDetailUpdatingChanged,
      onChange,
    ],
  );
};

export default useTagHeaderEditColumns;
