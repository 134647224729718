import { IMAGE_SIZES } from 'components/atoms/IndustryBackground/constants';

export const dayBasedImageNumber = () => new Date().getDay();

export const chooseBestSize = (width: number, height: number): string => {
  let bestSize = null;
  for (let [size, dimensions] of Object.entries(IMAGE_SIZES)) {
    if (dimensions.width >= width && dimensions.height >= height) {
      bestSize = size;
    }
  }
  return bestSize!;
};
